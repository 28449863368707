import React from "react";
import { Form } from "react-bootstrap";

const Textarea = ({ onChange, rows,  ...restProps }) => {
  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };
  return (
    <Form.Group>
      <Form.Control
        rows={rows || 5}
        as="textarea"
        onChange={handleChange}
        {...restProps}
      />
      <div className="mb-4"></div>
    </Form.Group>
  );
};

export default Textarea;
