import { Button, Loader, OverlayTooltip, SearchField } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import css from "./scss/subjects-menu.module.scss";
import * as config from "config";
import classNames from "classnames";

const StyledShelfMenu = styled.div`
  .${css.subject_section_title} {
    color: ${({ theme }) => theme.primary};
  }
  .${css.featured_item}, .${css.subject_section_list} {
    color: ${({ theme }) => theme.color2};
  }
  .${css.image}, .${css.image_placeholder} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );

    > .${css.placeholder_text} {
      color: ${({ theme }) => theme.button.primary.text};
    }
  }
`;

class ShelfMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredList: this.props.shelfList[0] ? this.props.shelfList[0].channel_details : [],
      showMore: false,
    };

    this.showMore = this.showMore.bind(this);
    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.shelfComplete)
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = (_prevProps) => {
    if(_prevProps.shelfList[0] !== null){
    if (
      _prevProps.shelfList[0] !==
      this.props.shelfList[0]
    ) {
      
      this.setState(() => ({
        filteredList: this.props.shelfList[0].channel_details,
      }));
    }}
  };

  setMenuRef(node) {
    this.menuRef = node;
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      if (this.state.showMore && event.clientX > 707) {
        this.props.toggleShelfMenu();
      }
      if (!this.state.showMore) {
        this.props.toggleShelfMenu();
      }
    }
  }

  filterList(text) {
    if (text !== "" && text.length !== 0) {
      let dataList = [];
      let allList = this.props.shelfList[0].channel_details;

      allList.forEach((channel_detail) => {
        // let dataList = [];
        // console.log(channel_detail.channel_name);
        if (
          channel_detail.channel_name
            .toLowerCase()
            .indexOf(text.toLowerCase()) !== -1
        ) {
          dataList.push(channel_detail);
        }
      });
      this.setState({
        filteredList: dataList,
      });
    } else {
      // set all list
      this.setState({
        filteredList: this.props.shelfList[0].channel_details,
      });
    }
  }
  showMore() {
    this.setState({
      showMore: true,
    });
  }
  handleMouseEnter=(value)=>{
    this.props.viewshelfHoverEnter(true)
    console.log(value,"viewshelf enter");
  }
  handleMouseLeave=(value)=>{
   
    this.props.viewShelfHoverLeave(false)
    console.log(value,"viewShelf leave");
  }
  handleshelfMenu=()=>{
    this.props.handleshelfMenu(true)
  }
  handleshelfMenu2=()=>{
    this.props.handleshelfMenu2(false)
  }
  render() {
    const shelfDetails = this.props.shelfList[0] ? this.state.filteredList : [];
    const list = shelfDetails && shelfDetails.map((channel) => (
      <Link
        // key={index}
        to={{
          pathname: config.ROUTES.viewShelf,
          shelfData:
            channel.type === "text"
              ? channel
              : {
                  channelId: channel.channel_id,
                  channel_name: channel.channel_name,
                },
        }}
        onClick={this.props.toggleShelfMenu}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={css.subject_section}>
          <div className={classNames(css.subject_section_list)}>
            {channel.channel_name}
          </div>
        </div>
      </Link>
    ));
    if (shelfDetails && shelfDetails.length === 0 && !this.props.shelfComplete) {
      return <Loader active />;
    }
      if (!this.state.filteredList) {
      return(
        <div className={classNames(css.shelf_Completed)}>
          Sorry, there are no shelves allocated to you 
        </div>
        )
    }

    return (
      <StyledShelfMenu
        ref={this.setMenuRef}
        id="subject_more"
        className={!this.state.showMore ? css.wrapper : css.wrapper_more}
      >
        {/* {(shelfDetails && shelfDetails.length === 0 && this.props.shelfComplete) &&
                    <div className={classNames(css.shelf_Completed)}>
                    Sorry, there are no shelves allocated to you
                  </div>} */}

        {/* featured subjects */}
        {!this.state.showMore && (
          <div className={css.featured}>
            {shelfDetails && shelfDetails.slice(0, 7).map((channel) => {
              return (
                <Link
                  // key={index}
                  to={{
                    pathname: config.ROUTES.viewShelf,
                    shelfData:
                      channel.type === "text"
                        ? channel
                        : {
                            channelId: channel.channel_id,
                            channel_name: channel.channel_name,
                          },
                  }}
                  className={classNames(css.featured_item)}
                  onClick={this.props.toggleShelfMenu}
                >
                  {channel.channel_logo && (
                    <img
                      className={css.image}
                      src={
                        channel.channel_logo
                          ? channel.channel_logo.match(
                              /^data:image\/\w+;base64,/
                            ) !== null
                            ? channel.channel_logo
                            : `data:image/png;base64,${channel.channel_logo}`
                          : ""
                      }
                      alt=""
                    />
                  )}
                  {!channel.channel_logo && (
                    <div className={css.image_placeholder}>
                      <div className={css.placeholder_text}>
                        {channel.channel_name.charAt(0).toUpperCase()}
                      </div>
                    </div>
                  )}
                  <div>{channel.channel_name}</div>
                </Link>
              );
            })}
            {7 <  shelfDetails.length && (
              <div className="text-center">
                <Button variant="link" size="sm" onClick={this.showMore}>
                  Show More
                </Button>
              </div>
            )}
          </div>
        )}

        {/* all shelf */}
        {this.state.showMore && (
          <div className={css.all}>
            <div className={css.search_field}>
              <SearchField
                placeholder={`Search for ${this.props.ctx_labels.plural.shelf}`}
                onKeyUp={(e) => this.filterList(e.currentTarget.value)}
                overLightBg
              />
            </div>
            {shelfDetails === null && this.props.shelfComplete ? 
                    <div className={classNames(css.shelf_Completed)}>
                    Sorry, there are no shelves allocated to you
                  </div>
            :
            <div className={css.subject_list}>{list}</div>
            }
          </div>
        )}
      </StyledShelfMenu>
    );
  }
}

export default withLabelsContext(ShelfMenu);
