import {
  BlockUpload,
  Button,
  Checkbox,
  InputField,
  OverlayTooltip,
  Select,
  Textarea,
  ToggleButtonGroup,
} from "components";
import { withApiContext, withLabelsContext, withUserContext, withWindowContext } from "context";
import {
  IconAudioCircle,
  IconChevronLeft,
  IconChevronRight,
  IconHider,
  IconImageCircle,
  IconMenuDot,
  IconPdfCircle,
  IconPublish,
  IconReorder,
  IconSaveNew,
  IconSectionDelete,
  IconSectionEdit,
  IconTestCancel,
  IconTestEdit,
  IconTestUpdate,
  IconVideoCircle,
  IconTestFeedback,
  IconReorderHeader,
  IconImageUpload,
  IconDeleteMinus,
  IconCancelCreateTest,
  IconSaveAssessment,
  IconSaveAndBuild,
  IconAssessmentSaveNew
} from "Icons";
import classNames from "classnames";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { Card, Col, Dropdown, Row, ModalBody, Modal } from "react-bootstrap";
import { styled, useTheme } from "styled-components";
import css from "./testCreation.module.scss";
import FormField from "components/FormField";
import * as config from "config";
import "jodit";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import { rgba } from "polished";
import VideoInput from "pages/ContentBuilder/VideoInput";
import { useContext } from "react";
import { TestContext, withTestContext } from "./Context/TestContex";
import SectionCard from "./SectionCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import FeedbackTest from "./FeedbackTest";
import AddQuestion from "./AddQuestion";
import FeedbackCard from "./FeedbackCard";
import SectionEdit from "./SectionEdit";
import QuestionReorder from "./QuestionReorder";
import SectionReorder from "./SectionReorder";
import TestEdit from "./TestEdit";
import CreateTestPreview from "pages/Test/Components/CreateTestPreview";
import Pdf from "components/PlayerNew/Pdf";
import Video from "components/PlayerNew/Video";
import * as messages from "messages";
import SwitchButton from "components/Button/SwitchButton";
import MathInput from "components/MathInput/MathInput";
import { withRouter } from "react-router-dom";
const StyledMenu = styled.div`
  position: fixed;
  z-index: 1;
`;

const StyledDropdownMenu = styled.div`
  width: 176px;
  padding-left: 10px;
  padding-right: 10px;
  color: #001b4a;
  text-align: initial;
  .dropdown-item {
    padding: 8px 5px;
    font-size: 12px;
    border-bottom: 1px dashed #979797;

    a {
      color: #001b4a;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: #aeafad;
    }

    &:active,
    &:hover {
      background-color: #e9ecef;
      color: inherit;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
border-radius: 5px;


`;
const BtnIconGrp = styled.div`
  margin: 5px;
  cursor: pointer;
  svg {
    fill: darkgrey;
  }

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    color: ${({ theme }) => rgba(theme.primary, 0.5)};

    svg {
      fill: ${({ theme }) => rgba(theme.primary, 0.5)};
    }
  }

  &:focus,
  &.focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }
`;

const ActiveBtnIcon = styled.div`
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const AnswerInput = styled.input`
  border: none;
  outline: none;
`;

const AnswerField = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  // padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    color: ${({ theme }) => rgba(theme.primary, 0.5)};
  }
  &.focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
  }
`;

const MathAnswerField = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  // padding: 1px 10px 1px 10px;
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    color: ${({ theme }) => rgba(theme.primary, 0.5)};
  }
  &.focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
  }
`;

const MathAnswerBox = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 8px 2px 8px;
  justify-content: space-between;
  align-items:center;
  height:35px
`;

const AnswerBox = styled.div`
  display: flex;
  width: 100%;
  padding: 2px 8px 2px 8px;
  justify-content: space-between;
`;

function TestCreation(props) {
  const theme = useTheme();
  const scrollRef = useRef(null);
  const editor = useRef(null);
  const {
    testData,
    setTestData,
    question,
    setQuestion,
    sections,
    responseSetter,
    sectionSetter,
    restQuestion,
    setSections,
    setTestFeedback,
    setFeedBackEdit,
    reFactorData,
    resSection,
    resSectionSetter,
    enableUpload,
    updateEnable,
    sectionVisible,
    setSectionVisible
  } = useContext(TestContext);
  const configs = useMemo(
    () => ({
      height: 300,
      allowResizeY: false,
      hidePoweredByJodit: true,
      readonly: false,
      buttons: "bold,italic,eraser,fontsize,\n,brush,paragraph,left,copyformat",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      toolbarButtonSize: "small",
      maxHeight: 300,
      useSearch: false,
      removeButtons: [
        "source",
        "superscript",
        "subscript",
        "outdent",
        "indent",
        "image",
        "file",
        "video",
        "table",
        "link",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "copyformat",
        "selectall",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
        "preview",
        "find",
        "replace",
        "inserthorizontalrule",
      ],
    }),
    []
  );
  const scrollableDivRef = useRef(null);

  const location = useLocation();

  const history = useHistory();

  const [difficultyLevels, setDifficultyLevels] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [selectedSection, setSelectedSection] = useState(1);

  const [selectedSectionName, setSelectedSectionName] = useState("");

  const [redirect, setRedirect] = useState(false);

  const [visibleIndex, setVisibleIndex] = useState(null);

  const [modfiedAnswer, setModfiedAnswer] = useState([]);

  const [deletedOption, setDeletedOption] = useState([]);

  const [pubDetails, setPubDetails] = useState([]);

  const [tabButton, setTabButton] = useState({
    questionnaire: true,
    feedback: false,
    preview: false,
  });

  const [contentButton, setContentButton] = useState({
    createQuestion: true,
    addLibrary: false,
    sectionEdit: false,
    feedback: false,
    questionReorder: false,
    sectionReorder: false,
    testEdit: false,
  });
  const [rightTab, setRightTab] = useState({
    feedBack: false,
    section: true,
  });
  const [hideDescMediaRow, setHideDescMediaRow] = useState(false);

  const [deletedSection, setDeletedSection] = useState({});

  const [isDeleted, setIsDeleted] = useState(false);

  const [tooltipMessages, setTooltipMessages] = useState([]);

  const [previewDisable, setPreviewDisable] = useState(false);

  const [allSectionsValid, setAllSectionsValid] = useState(true);

  const [taggedCourses, setTaggedCourses] = useState([]);

  const [action, setAction] = useState("add");

  const [editdivSub, setEditdivSub] = useState(false);

  const [testModify, setTestModify] = useState(false);

  const [inValidURL, setInValidURL] = useState(false);

  const mathSymbols = [
    { label: "#", value: 0 },
    { label: "%", value: 1 },
  ];

  const typeOptions = [
    {
      label: props.ctx_labels.singular.multipleChoice,
      value: config.testQuestionTypeQBE.MULTIPLE_CHOICE,
    },
    {
      label: props.ctx_labels.singular.fillInTheBlanks,
      value: config.testQuestionTypeQBE.FILL_IN_THE_BLANK,
    },
    {
      label: props.ctx_labels.singular.assignment,
      value: config.testQuestionTypeQBE.ASSIGNMENT,
      isDisabled: question.id !== 0
    },
    {
      label: props.ctx_labels.singular.shortAnswer,
      value: config.testQuestionTypeQBE.COMMENT,
    },
    {
      label: props.ctx_labels.singular.multipleChoiceSingleOption,
      value: config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE,
    },
  ];
  const assignmentType = [
    {
      label: "Audio",
      value: 0,
    },
    {
      label: "Video",
      value: 1,
    },
    {
      label: "Pdf",
      value: 2,
    },
  ];

  const inlineBackground = {
    "--bg-color-1": theme.primary,
  };

  useEffect(() => {
    if (pubDetails.length > 0 && pubDetails[0].can_delete === 1) {
      setEditdivSub(false);
    }
    else {
      setEditdivSub(true);
    }
  }, [pubDetails]);

  useEffect(() => {
    if (location.state) {

      const state = location.state;
      setTestData((prev) => {
        return {
          ...prev,
          name: state.name,
          short: state.short,
          curriculumId: state.curriculumId,
          categoryId: state.categoryId,
          description: state.description,
          courseTag: state.courseTag === null ? [] : state.courseTag,
        }
      });
    }
  }, [location.state]);

  useEffect(() => {
    setQuestion((prev) => {
      return {
        ...prev,
        section_order: selectedSection,
        section_name: selectedSectionName,
      };
    });
  }, [selectedSection]);

  useEffect(() => {
    const validate = () => {
      let messages = [];

      if (!question.question) messages.push('Question is required.');
      if (!question.points) messages.push('Points are required.');
      if (inValidURL === true) messages.push('Invalid media URL');
      // if (question.points === "0") messages.push('Points cannot be zero.');
      if (question.type === null || question.type === undefined) messages.push('Question type is required.');

      if (question.type === 0 || question.type === 6) {
        if (!question.answer.length) messages.push('At least one answer is required.');
        if (question.answer.length < 2) messages.push('At least two answers are required.');
        if (question.answer.filter((answer) => answer.answer !== "").length < 2) messages.push('At least two non-empty answers are required.');
        if (!question.answer.some((ans) => ans.is_correct_ans && ans.answer !== "")) messages.push('At least one correct answer is required.');

        // Check for duplicate answers
        const hasDuplicateAnswers = (answers) => {
          const seenAnswers = new Set();
          return answers.some(answer => {
            if (seenAnswers.has(answer.answer)) {
              return true; // Duplicate found
            }
            seenAnswers.add(answer.answer);
            return false;
          });
        };

        if (hasDuplicateAnswers(question.answer)) {
          messages.push('Duplicate answers are not allowed.');
        }
      }


      if (question.type === 1 || question.type === 6) {
        if (!question.answer.length) messages.push('At least one answer is required.');
        if (question.answer.length < 1) messages.push('At least one answer is required.');
        if (!question.answer.some((ans) => ans.answer?.trim() !== "")) messages.push('At least one non-empty answer is required.');
      }

      return messages;
    };


    const messages = validate();
    setIsSaveDisabled(messages.length > 0);
    setTooltipMessages(messages);
  }, [question]);

  useEffect(() => {
    if (
      question.content !== "" &&
      question.content !== null &&
      question.content_format !== "" &&
      question.content_format !== null &&
      question.content_format !== undefined &&
      question.content_source === "aws"
    ) {
      getMediaPlayable(question.content_format, question.content);
    }

  }, [question.content]);

  useEffect(() => {
    if (typeof question.content_format !== "string" && question.content_format !== "" && question.content_format !== null) {
      const format = question.content_format?.toString();
      setQuestion((prev) => {
        return {
          ...prev,
          content_format: format,
          asgnmt_content_format: format
        }
      })
    }
    if (question.content_format !== "" && question.content_format !== null &&
      question.content !== "" && question.content !== null &&
      question.content_source !== "" && question.content_source !== null
    ) {
      setQuestion((prev) => {
        return {
          ...prev,
          media: 1
        }
      })
    }
  }, [question.content_format]);

  useEffect(() => {
    if (question.action === "update") {
      setQuestion((prev) => {
        return {
          ...prev,
        };
      });
    }
    else {
      setQuestion((prev) => {
        return {
          ...prev,
          content: "",
          content_source: "",
          content_format: null,
          is_record: 0,
          assignment_content: "",
          asgnmt_content_format: null,
          asgnmt_content_source: "",
          std_assignment_type: 0,
          media_link: "",
          answer: []
        };
      });
    }

  }, [question.type]);

  useEffect(() => {
    if (isDeleted) {
      const updateSection = sections
        .filter(
          (section) => section.section_order !== deletedSection.section_order
        )
        .map((data, index) => {
          return {
            ...data,
            section_order: index + 1,
            condition:
              (index === 1) === selectedSection - 1
                ? true
                : selectedSection + 1
                  ? true
                  : false,
            // section_name: `Section ${index + 1}`,
          };
        });
      const selectedSec = updateSection.filter(
        (section) => section.condition === true
      );
      handleSectionData(...selectedSec);
      setSections(updateSection);
      setIsDeleted(false);
    }
  }, [isDeleted]);

  useEffect(() => {
    const fetchTaggedCourses = async () => {
      try {
        if (testData.categoryId && testData.curriculumId) {
          const { rsp } = await props.ctx_api.privateRequest({
            cmd: "get_tagged_courses",
            args: {
              categoryId: testData.categoryId,
              curriculumId: testData.curriculumId,
              Id: testData.testId,
            },
          });

          const courses = rsp.map(({ course_id, course_name }) => ({
            label: course_name,
            value: course_id,
          }));

          setTaggedCourses(courses);
        }
      } catch (error) {
        console.error("Error fetching tagged courses:", error);
      }
    };
    fetchTaggedCourses();
  }, [testData.categoryId, testData.curriculumId]);

  useEffect(() => {
    if (props.isContentBuilder) {
      if (props.testId === null) {
        setTestData((prev) => {
          return {
            ...prev,
            categoryId: props.chapterSection.categoryId,
            curriculumId: props.chapterSection.curriculumId,
            subject: props.chapterSection.curriculumId,
            standard: props.chapterSection.categoryId,
          }
        });
        setContentButton((prev) => {
          return {
            createQuestion: false,
            addLibrary: false,
            sectionEdit: false,
            feedback: false,
            questionReorder: false,
            sectionReorder: false,
            testEdit: true,
          }
        });
        setSectionVisible(false);
      } else {
        setContentButton((prev) => {
          return {
            createQuestion: true,
            addLibrary: false,
            sectionEdit: false,
            feedback: false,
            questionReorder: false,
            sectionReorder: false,
            testEdit: false,
          }
        })
        setAction("modify")
      }
    }
    const editReset = () => {
      setFeedBackEdit({ isEdit: false, index: 0 });
    };

    const getDifficultyLevel = async () => {
      try {
        const { rsp } = await props.ctx_api.privateRequest({
          cmd: "get_difficulty_level",
          args: {},
        });

        const difficultyLevels = rsp.map(({ level_id, level_name }) => ({
          label: level_name,
          value: level_id,
        }));

        setDifficultyLevels(difficultyLevels);
      } catch (error) {
        console.error("Error fetching difficulty levels:", error);
      }
    };

    shortCodeRandomGenerator();
    getDifficultyLevel();

    return () => {
      editReset();
    };
  }, []);

  const getMediaPlayable = async (contentFormat, content) => {
    const contentType =
      contentFormat === "0" ? "audio" :
        contentFormat === "1" ? "video" :
          contentFormat === "2" ? "pdf" :
            "video";

    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_module_url",
        args: {
          contentType,
          fileName: content,
        },
      });
      if (rsp.success === true) {
        setQuestion((prev) => {
          return {
            ...prev,
            media_link: rsp.url
          }
        })
      }
      return rsp;
    } catch (error) {
      console.error("Error fetching media URL:", error);
      throw error; // Propagate the error to be handled later if needed
    }
  };

  const fetchTestDetails = async (id) => {
    let testId;
    if (location.testId || (props.isContentBuilder && props.testId !== null)) {
      testId = location.testId ? location.testId : props.testId;
      setTestData((prev) => {
        return {
          ...prev,
          testId: testId,
        };
      });
    }
    else if (id) {
      testId = id;
      setTestData((prev) => {
        return {
          ...prev,
          testId: id,
        };
      });
    }
    try {
      const response = await props.ctx_api.privateRequestRow({
        cmd: "get_test_dtl",
        args: { testId: testId },
      });
      resSectionSetter(response.rsp, testId)
      responseSetter(response.rsp); // Set the response to state
      sectionSetter(response.rsp); // Set the response to state
      setPubDetails(response.rsp.publish_test_dtl);
    } catch (error) {
      history.push(config.ROUTES.createTest)
      console.error("Failed to fetch test details:", error);
    }
  };

  useEffect(() => {
    if (location.testId || (props.testId !== null && props.isContentBuilder)) {
      setAction("modify");
      fetchTestDetails();
    }
    else if (!location.testId && !location.state && !props.isContentBuilder) {
      console.log('redirect to test');
      setRedirect(true);
    }
  }, [location.testId]);

  useEffect(() => {
    restAnswerField();
  }, [question.type]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    resetQuestion();
  }, [contentButton.createQuestion]);



  useEffect(() => {
    const checkSections = () => {
      for (const section of sections) {
        if (section.questions.length === 0) {
          setAllSectionsValid(false);
          return;
        }
        if (sections.some(section =>
          section.questions.some(question =>
            question.points === "" || question.points === null
          ))) {
          setAllSectionsValid(false);
          return;
        }
      }
      setAllSectionsValid(true);
    };
    checkSections();

    const calculateSum = (sections) => {
      return sections.map((section) => {
        const { is_limit, max_questions, questions } = section;
        const maxQuestionsNumber = Number(max_questions);

        if (is_limit === 1) {
          // All questions have the same points
          const pointsPerQuestion = Number(questions[0]?.points) || 0;
          return maxQuestionsNumber * pointsPerQuestion;
        } else {
          // Sum all points in the section
          return questions.reduce((sum, question) => sum + Number(question.points), 0);
        }
      });
    };

    const sums = calculateSum(sections);
    const total = sums.reduce((sum, value) => sum + value, 0);
    setTestData((prev) => {
      return {
        ...prev,
        totmrk: total
      }
    });

    enableUpload(sections);
  }, [sections]);

  useEffect(() => {
    enableUpload(sections);
  }, [testData]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  const insertUpdateAnswers = async (modified, answers, queId, deletedOption) => {
    const modifyKeys = (array) => {
      return array.map(({ option, ...rest }) => ({ answer: option, ...rest }));
    };
    const modifiedArray = modifyKeys(modified);
    const updated = [];
    const defaultMap = new Map(
      modifiedArray.map((answer) => [answer.answer_id, answer])
    );

    answers.forEach((modAnswer) => {
      const defaultAnswer = defaultMap.get(modAnswer.answer_id);

      if (!defaultAnswer) {
        if (modAnswer.answer !== "" && modAnswer.answer !== undefined && modAnswer.answer !== null) {
          updated.push(modAnswer);
        }
      } else {
        let isDifferent = false;
        for (const key in modAnswer) {
          if (modAnswer[key] !== defaultAnswer[key]) {
            isDifferent = true;
            break;
          }
        }
        if (isDifferent && modAnswer.answer !== "") {
          updated.push(modAnswer);
        }
      }
    });

    // Function to handle deletion of answers
    const handleDeletions = async () => {
      if (deletedOption.length > 0) {
        const deletePromises = deletedOption.map(async (data) => {
          if (data.answer_id !== "0") {
            const id = data.answer_id;
            try {
              const { rsp } = await props.ctx_api.privateRequestRow({
                cmd: "insert_update_question_answer",
                args: {
                  answerId: id.toString(),
                  status: 0,
                },
              });
              return rsp;
            } catch (error) {
              console.error("Error deleting answer:", error);
            }
          }

        });

        // Wait for all delete operations to complete
        await Promise.all(deletePromises);
      }
    };

    // Function to handle updating of answers
    const handleUpdates = async () => {
      const updatePromises = updated.map(async (data) => {
        const id = data.answer_id;
        try {
          const { rsp } = await props.ctx_api.privateRequestRow({
            cmd: "insert_update_question_answer",
            args: {
              questionId: queId,
              question: data.question ? data.question : "",
              answerId: id.toString(),
              status: 1,
              answer: data.answer,
              image: data.image ? data.image : "",
              isCorrectAnswer: data.is_correct_ans,
              comment: "",
              answerCode: data.answer_code ? data.answer_code.toString() : "",
              questionCode: "",
              origImage: data.origImage ?? "",
            },
          });
          return rsp.answer_id; // Return the answer_id after successful update
        } catch (error) {
          console.error("Error updating answer:", error);
          return null; // Handle error case if needed
        }
      });

      // Wait for all update operations to complete
      const updatedAnswerIds = await Promise.all(updatePromises);

      return updatedAnswerIds.filter(id => id !== null); // Filter out null values if any error occurred
    };

    const submitAnswerReorder = async (answerIds) => {
      try {
        const { rsp } = await props.ctx_api.privateRequestRow({
          cmd: "update_question_answers_sequence",
          args: {
            answerId: answerIds.join(","),
            sequenceId: answerIds.map((id, index) => index).join(","),
          },
        });
        return rsp;
      } catch (error) {
        console.error("Error reordering answers:", error);
      }
    };

    try {
      // First handle deletions
      await handleDeletions();

      // Then handle updates
      const updatedAnswerIds = await handleUpdates();

      // Call submitAnswerReorder after all updates are completed
      if (updatedAnswerIds.length > 0) {
        const currentAns = modifiedArray.map(item => item.answer_id);
        const seqUpdate = [...new Set([...currentAns, ...updatedAnswerIds])];
        await submitAnswerReorder(seqUpdate);
      }
    } catch (error) {
      console.error("Error in insertUpdateAnswers:", error);
      // Handle errors as needed
    }
  };



  // Define the function to call another API
  const insertUpdateQuestion = async () => {
    setIsSaveDisabled(true);
    const questionNegativeCredit = () => {
      const value = question.negative_points;
      if (typeof value === "number") {
        return parseFloat(value);
      }
      const trimmedInput = typeof value === "string" ? value.trim() : value;
      const floatValue = parseFloat(trimmedInput);

      // Step 3: Convert to an integer
      if (!isNaN(floatValue)) {
        return Math.floor(floatValue);
      }
    };
    try {
      const response = await props.ctx_api.privateRequest({
        cmd: "insert_update_question",
        args: {
          questionId: question.id.toString(),
          status: 1,
          categoryId: testData.categoryId,
          curriculumId: testData.curriculumId,
          questionName: question.question,
          content: question.content === null ? "" : question.content,
          // file_name: question.content === null ? "" : question.content,
          content_source: question.content_source ?? "",
          content_format: question.content_format === "" ? null : question.content_format,
          description: question.question_content === null ? "" : question.question_content,
          questionType: question.type,
          questionImage: "",
          questionCredit: parseInt(question.points, 10),
          questionNegativeCredit: questionNegativeCredit(),
          negativeCreditPercent: +question.negativeCreditPercent,
          recorder: question.is_record === null ? 0 : question.is_record,
          origImage: question.origImage,
          difficultylevel:
            question.difficulty_level === "" ? null : question.difficulty_level,
          shortCodes: question.shortCodes ?? "",
          mathSymbol: parseInt(question.math_symbol, 10),
          solution: question.solution === null ? "" : question.solution,
        },
      });
      const queId = response.rsp[0].questionid;
      const selectedSec = sections.filter(
        (section) => section.section_order === selectedSection
      );
      const updatedQuestion = {
        ...question,
        id: +queId,
        section_name: selectedSec[0].section_name,
      };
      await insertUpdateAnswers(modfiedAnswer, question.answer, queId, deletedOption);
      if (response.rsp[0].StatusValue === 1) {
        const message = question.id.toString() !== "0" ? "updated" : "added"
        props.ctx_window.alert(
          messages.mkTestAddSuccess(
            props.ctx_labels.singular.question + " " + message
          )
        );
      }
      setIsSaveDisabled(false);
      handleUpdateSection(updatedQuestion, selectedSec);

    } catch (error) {
      console.error("Error inserting/updating question:", error);
    }
  };

  const shortCodeRandomGenerator = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const length = 6; // Length of the generated code
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    setQuestion((prevState) => ({
      ...prevState,
      shortCodes: result,
    }));
  };

  const handlePaste = (event) => {
    if (question.type === 0 || question.type === 6) {
      event.preventDefault();
      const text = event.clipboardData.getData('Text');
      const { questionTxt, qnAnswers } = processCopiedText(text);
      setQuestion((prevState) => ({
        ...prevState,
        question: questionTxt,
        answer: qnAnswers,
      }));
    }

  };
  const processCopiedText = (text) => {
    const lines = text.split('\n').map(line => line.trim()).filter(line => line !== '');
    const questionTxt = lines[0];
    let qnAnswers = lines.slice(1).map((answer, index) => ({
      answer: answer,
      answer_code: null,
      answer_comment: null,
      answer_id: "0",
      image: "",
      is_correct_ans: 0,
      question: null,
      question_code: null,
      question_id: question.id.toString(),
    }));

    while (qnAnswers.length < 2) {
      qnAnswers.push({
        answer: "",
        answer_code: null,
        answer_comment: null,
        answer_id: "0",
        image: "",
        is_correct_ans: 0,
        question: null,
        question_code: null,
        question_id: question.id.toString(),
      });
    }

    return { questionTxt, qnAnswers };
  };


  const restAnswerField = () => {
    setQuestion((prevState) => {
      let newAnswer = [...prevState.answer];

      if (question.type === 0 || question.type === 6 || question.type === 1) {
        while (newAnswer.length < 2) {
          newAnswer.push({
            answer: "",
            answer_code: null,
            answer_comment: null,
            answer_id: "0",
            image: "",
            is_correct_ans: 0,
            question: null,
            question_code: null,
            question_id: "",
          });
        }
      } else if (question.type === 1) {
        if (newAnswer.length === 0) {
          newAnswer.push({
            answer: "",
            answer_code: null,
            answer_comment: null,
            answer_id: "0",
            image: "",
            is_correct_ans: 0,
            question: null,
            question_code: null,
            question_id: "",
          });
        } else {
          newAnswer[0].is_correct_ans = 0;
        }
      } else {
        // For other types, do nothing or handle as needed
      }
      return {
        ...prevState,
        answer: newAnswer,
      };
    });
  };

  const resetQuestion = () => {
    restQuestion();
    restAnswerField();
    shortCodeRandomGenerator();
  };

  const handleCheckboxChange = (e, option) => {
    const isChecked = e.target.checked;

    setQuestion((prevState) => {
      const newState = { ...prevState };

      if (option === "media") {
        newState.media = isChecked ? 1 : 0;

        if (!isChecked) {
          newState.content_format = "";
          newState.asgnmt_content_format = "";
          newState.media_link = "";
          newState.content_source = "";
        }
      } else if (option === "recorder") {
        newState.is_record = isChecked ? 1 : 0;
      }

      return newState;
    });
  };

  const handleMediaSelect = (data) => {
    const media = data === "AUDIO" ? 0 : data === "VIDEO" ? 1 : data === "PDF" ? 2 : 3
    // setQuestion((prevState) => ({
    //   ...prevState,
    //   content_format: media.toString(),
    //   asgnmt_content_format: media.toString(),
    // }));

    setQuestion((prev) => {
      return {
        ...prev,
        content_format: media.toString(),
        asgnmt_content_format: media.toString(),
      }
    })
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const addSections = () => {
    const newSection = {
      section_order: sections.length + 1,
      section_name: `Section ${sections.length + 1}`,
      content_format: null,
      content: null,
      is_av: 0,
      content_source: null,
      description: "",
      file_name: null,
      is_limit: 0,
      max_questions: "",
      media_link: null,
      questions: [],
      feedBacks: [],
      condition: false,
    };
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleTabClick = (key) => {
    switch (key) {
      case "question":
        setTabButton({
          questionnaire: true,
          feedback: false,
          preview: false,
        });
        break;

      case "feedback":
        setTabButton({
          questionnaire: true,
          feedback: true,
          preview: false,
        });
        break;

      case "preview":
        setTabButton({
          questionnaire: false,
          feedback: false,
          preview: true,
        });
        break;
    }
  };

  const handleContentTabClick = (key) => {
    switch (key) {
      case "createQuestion":
        setContentButton({
          createQuestion: true,
          addLibrary: false,
          preview: false,
          sectionEdit: false,
          feedback: false,
          questionReorder: false,
          sectionReorder: false,
          testEdit: false,
        });
        break;

      case "addLibrary":
        setContentButton({
          createQuestion: false,
          addLibrary: true,
          sectionEdit: false,
          feedback: false,
          questionReorder: false,
          sectionReorder: false,
          testEdit: false,
        });
        break;
    }
  };

  const handleSectionData = (data) => {
    setDeletedSection(data);
    setSelectedSection(data.section_order);
    setSelectedSectionName(data.section_name);

    setSections(
      sections.map((div) =>
        div.section_order === data.section_order
          ? {
            ...div,
            condition: true,
          }
          : {
            ...div,
            condition: false,
          }
      )
    );
  };

  const deleteSection = () => {
    setIsDeleted(true);
  };

  const editSection = () => {
    setContentButton({
      createQuestion: false,
      addLibrary: false,
      sectionEdit: true,
      feedback: false,
      questionReorder: false,
      sectionReorder: false,
    });
    setTabButton({
      questionnaire: true,
      feedback: false,
      preview: false,
    });
  };

  const handleTabs = (data) => {
    if (data === "feedback") {
      handleTabClick(data);
      setContentButton({
        createQuestion: false,
        addLibrary: false,
        sectionEdit: false,
        feedback: true,
        questionReorder: false,
        sectionReorder: false,
        testEdit: false,
      });
      // setTabButton({
      //   questionnaire: false,
      //   feedback: true,
      //   preview: false,
      // })
      setRightTab({
        feedBack: true,
        section: false,
      });
    } else if (data === "section") {
      setContentButton({
        createQuestion: true,
        addLibrary: false,
        sectionEdit: false,
        feedback: false,
        questionReorder: false,
        sectionReorder: false,
        testEdit: false,
      });
      setRightTab({
        feedBack: false,
        section: true,
      });
    } else if (data === "questionReorder") {
      setContentButton({
        createQuestion: false,
        addLibrary: false,
        sectionEdit: false,
        feedback: false,
        questionReorder: true,
        sectionReorder: false,
        testEdit: false,
      });
      setRightTab({
        feedBack: false,
        section: true,
      });
    } else if (data === "sectionReorder") {
      setContentButton({
        createQuestion: false,
        addLibrary: false,
        sectionEdit: false,
        feedback: false,
        questionReorder: false,
        sectionReorder: true,
        testEdit: false,
      });
      setRightTab({
        feedBack: false,
        section: true,
      });
    } else if (data === "testEdit") {
      setContentButton({
        createQuestion: false,
        addLibrary: false,
        sectionEdit: false,
        feedback: false,
        questionReorder: false,
        sectionReorder: false,
        testEdit: true,
      });
      setRightTab({
        feedBack: false,
        section: true,
      });
      setTestModify(true);
    }
  };

  const renderSectionDropdown = (div) => {
    const toggleStyle = {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      margin: 0,
      boxShadow: "none",
      display: "flex",
    };

    return (
      <Dropdown drop="right">
        <Dropdown.Toggle id="dropdown-basic" style={toggleStyle}>
          <div>
            <IconMenuDot />
          </div>
        </Dropdown.Toggle>
        <StyledMenu>
          <Dropdown.Menu>
            <StyledDropdownMenu>
              <Dropdown.Item
                className={css.dropdownMenu}
                onClick={() => editSection()}
              >
                <IconSectionEdit /> {props.ctx_labels.singular.edit}
              </Dropdown.Item>
              <Dropdown.Item
                className={css.dropdownMenu}
                onClick={() => deleteSection()}
                disabled={sections.length <= 1}
              >
                <IconSectionDelete fill="none" /> {props.ctx_labels.singular.delete}
              </Dropdown.Item>
              <Dropdown.Item
                className={css.dropdownMenu}
                onClick={() => handleTabs("questionReorder")}
              >
                <IconReorder /> {props.ctx_labels.singular.questionReorder}
              </Dropdown.Item>
              <Dropdown.Item
                className={css.dropdownMenu}
                onClick={() => handleTabs("sectionReorder")}
              >
                <IconReorder /> {props.ctx_labels.singular.sectionReorder}
              </Dropdown.Item>
              <Dropdown.Item
                className={css.dropdownMenu}
                onClick={() => handleTabs("feedback")}
              >
                <IconTestFeedback /> {props.ctx_labels.singular.feedback}
              </Dropdown.Item>
            </StyledDropdownMenu>
          </Dropdown.Menu>
        </StyledMenu>
      </Dropdown>
    );
  };
  const backToSearch = () => {
    setRedirect(true);
  };
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/create-test',
        }}
      />
    );
  }
  const handleUpdate = () => {
    const doUpdate = (allow) => {
      if (allow) {
        reFactorData(sections, props.ctx_api, props.isContentBuilder).then((rsp) => {
          if (rsp[0].StatusValue === 1) {
            if (props.isContentBuilder) {
              insertUpdateTestDetails(rsp);
              return;
            }
            if (rsp[0].test_id) {
              fetchTestDetails(rsp[0].test_id)
            }

            const message = testData.testId !== "0" ? "updated" : "added"

            props.ctx_window.alert(
              messages.mkTestAddSuccess(
                props.ctx_labels.singular.test + " " + message
              ), () => setRedirect(true)
            )
          } else if (rsp[0].StatusValue === 2) {
            props.ctx_window.alert(
              messages.mkAlreadyExistError(
                testData.name
              )
            )
          }
        }).catch((error) => {
          console.error("Error updating data:", error);
        });
      }
    };

    props.ctx_window.simpleConfirm(
      "Do you want to update? Unsaved changes will be lost.",
      doUpdate
    );
  };

  const insertUpdateTestDetails = (rsp) => {
    const contentIds = [];
    contentIds.push(rsp[0].test_id.toString());
    props.ctx_api
      .privateRequest({
        cmd: "insert_update_del_master_course_content_hier",
        args: {
          name: testData.name,
          description: testData.description,
          contentIds: contentIds,
          courseId: props.chapterSection.courseId,
          type: "T",
          courseContentIds: action === "add" ? ['0'] : [props.activeTestDetails.courseContentId.toString()],
          action: action,
          courseTag: [props.chapterSection.courseId],
          parentId: props.parentId,
          addMode: action === "add" ? "new" : action,
          ...(action === "add" && { chapterId: props.selectedChapter.id, chapterStatus: null }),
        },
      }).then((rsp) => {
        props.handleTestResponse(rsp);
      })
  }

  const handleTestCancel = () => {
    const doCancel = (allow) => {
      if (allow) {
        props.isContentBuilder ? props.modalClose() : backToSearch();
      }
    };
    props.ctx_window.simpleConfirm(
      "Do you want to cancel? Unsaved changes will be lost.",
      doCancel
    );
  }
  // const handlePublish = () => {
  //   const subjects = props.ctx_user.user.campusSubj;
  //   const standards = props.ctx_user.user.campusCategories;
  //   const subjectId = subjects.find((item) => item.value === testData.curriculumId);
  //   const standardId = standards.find((item) => item.value === testData.categoryId);
  //   const entry = {
  //     ...pubDetails[0],
  //     coursePublishId: null,
  //     courseId: null,
  //     status: "Yes",
  //     subject: subjectId.label,
  //     subjectId: subjectId.value,
  //     standard: standardId.label,
  //     standardId: standardId.value,
  //     id: pubDetails[0].test_id,
  //     name: pubDetails[0].name,
  //     shortName: pubDetails[0].short_name,
  //     totQuestions: pubDetails[0].tot_questions,
  //     testId: pubDetails[0].test_id,
  //     fromClassView: 0,
  //     sectionSame: pubDetails[0].section_same,
  //     creditSame: pubDetails[0].credit_same,
  //   }
  //   try {

  //     if (entry) {
  //       props.location.handleRedirect(entry);
  //       const url = props.location.redirectUrl;
  //       if (url) {
  //         props.history.push(url);
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const renderHeader = () => {
    return (
      <div className={css.header}>
        <div className={css.testName} style={{ color: theme.color2 }}>
          <IconTestEdit
            style={{ cursor: "pointer" }}
            onClick={() => handleTabs("testEdit")}
          />{" "}
          {testData.name ? testData.name : props.ctx_labels.singular.assessment + props.ctx_labels.singular.name}
        </div>
        <div className={css.headerButtons}>
          {props.isContentBuilder ?
            <>
              <Button
                className={css.rectangleButton}
                style={{ backgroundColor: theme.color4, borderColor: theme.color4 }}
                onClick={handleUpdate}
                disabled={!allSectionsValid || !updateEnable}
              // disabled={!allSectionsValid}
              >
                <IconAssessmentSaveNew />
                {props.ctx_labels.singular.save}
              </Button>
              <Button
                className={css.rectangleButton}
                style={{ backgroundColor: "#FF5454", borderColor: "#FF5454" }}
                onClick={handleTestCancel}
              >
                <IconTestCancel />
                {props.ctx_labels.singular.cancel}
              </Button>
            </>
            :
            <>
              <Button
                className={css.rectangleButton}
                style={{ backgroundColor: theme.color4, borderColor: theme.color4 }}
                onClick={handleUpdate}
                disabled={!allSectionsValid || !updateEnable}
              // disabled={!allSectionsValid}
              >
                <IconTestUpdate />
                {props.ctx_labels.singular.update}
              </Button>
              {/* <OverlayTooltip
                tip="Please update the test"
                placement="top"
                fromTestSec
                disabled={testData.testId !== "0"}
              >
                <Button
                  className={css.rectangleButton}
                  style={{ backgroundColor: theme.color2, borderColor: theme.color2 }}
                  onClick={handlePublish}
                  disabled={testData.testId === "0" || updateEnable}
                >
                  <IconPublish />
                  {props.ctx_labels.singular.publish}
                </Button>
              </OverlayTooltip> */}
              <Button
                className={css.rectangleButton}
                style={{ backgroundColor: "#FF5454", borderColor: "#FF5454" }}
                onClick={handleTestCancel}
              >
                <IconTestCancel />
                {props.ctx_labels.singular.cancel}
              </Button>
            </>}
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className={css.renderTabs}>
        <div className={css.tabButtonWrapper}>
          <div
            className={css.tabButton}
            onClick={() => handleTabClick("question")}
            id={tabButton.questionnaire ? css.active : ""}
            style={inlineBackground}
          >
            {props.ctx_labels.singular.questionnaire}
          </div>
          {/* <div
            className={css.tabButton}
            onClick={() => handleTabClick("feedback")}
            id={tabButton.feedback ? css.active : ""}
            style={inlineBackground}
          >
            Feedback
          </div> */}
          {
            !allSectionsValid ?
              <div
                className={css.tabButton}
                id={css.tabdisable}
                style={inlineBackground}
              >
                {props.ctx_labels.singular.preview}
              </div>
              :
              <div
                className={css.tabButton}
                onClick={() => handleTabClick("preview")}
                id={tabButton.preview ? css.active : ""}
                style={inlineBackground}
              >
                {props.ctx_labels.singular.preview}
              </div>
          }
        </div>
      </div>
    );
  };

  const handleMathSymbolChange = (e) => {
    const intConvert = parseInt(e.value)
    setQuestion((prev) => {
      return { ...prev, negativeCreditPercent: intConvert }

    })
  }

  const handleQuestionChange = ({ name, value }) => {
    if (
      name === "shortCodes"
    ) {
      setQuestion((prev) => {
        const question = { ...prev, [name]: value.target.value };
        return question;
      });
    }
    else if (
      name === "points" ||
      name === "negative_points"
    ) {
      setQuestion((prev) => {
        const question = { ...prev, [name]: value.target.value };
        return question;
      });
    } else if (name === "type") {
      setQuestion((prev) => {
        if (prev.type !== value) {
          setDeletedOption((prevState) => {
            return prev.answer
          })
          const question = { ...prev, answer: [], [name]: value };
          return question;
        }
        else {
          const question = { ...prev, [name]: value };
          return question
        }
      });
    }
    else {
      setQuestion((prev) => {
        const question = { ...prev, [name]: value };
        return question;
      });
    }
  };

  const handleSectionChange = ({ name, value }) => {
    if (name === "section_name") {
      setSections((prev) => {
        const section = { ...prev, [name]: value.target.value };
        return section;
      });
    } else {
      setSections((prev) => {
        const section = { ...prev, [name]: value };
        return section;
      });
    }
  };

  const handleQuestionFile = (fileData) => {
    if (fileData.isInvalidUrl === true) {
      setInValidURL(true);
    }
    else {
      setInValidURL(false);
    }
    setQuestion((prevState) => ({
      ...prevState,
      content: fileData.url,
      content_source: fileData.contentSource,
      file_name: fileData.url,
      media_link: fileData.contentUrl,
    }));

  };

  const handleInputChange = (index, value) => {
    const newAnswers = [...question.answer];
    newAnswers[index].answer = value;
    if (index === question.answer.length - 1 && (value && value !== "")) {
      newAnswers.push({
        answer: "",
        answer_code: null,
        answer_comment: null,
        answer_id: "0",
        image: "",
        is_correct_ans: 0,
        question: null,
        question_code: null,
        question_id: question.id.toString(),
      });
    }

    setQuestion({ ...question, answer: newAnswers });
  };

  const handleAnswerImageUpload = (index, value) => {
    const newAnswers = [...question.answer];
    newAnswers[index].image = value;
    setQuestion({ ...question, answer: newAnswers });


  }

  const handleAnswerOptions = (index, checked, type) => {
    if (type === 6) {
      const newAnswers = question.answer.map((answer, i) => ({
        ...answer,
        is_correct_ans: i === index ? (checked ? 1 : 0) : 0,
        answer_id: answer.answer_id
      }));
      setQuestion((prev) => {
        return {
          ...prev, answer: newAnswers
        }
      });
    }
    else if (type === 1) {
      const newAnswers = [...question.answer];
      newAnswers[index].is_correct_ans = 0;
      setQuestion((prev) => {
        return {
          ...prev, answer: newAnswers
        }
      });

    }
    else {
      const newAnswers = [...question.answer];
      newAnswers[index].is_correct_ans = checked ? 1 : 0;
      setQuestion((prev) => {
        return {
          ...prev, answer: newAnswers
        }
      });

    }
  };

  const handleVisibleAnswersImage = (index) => {
    setVisibleIndex(index === visibleIndex ? null : index);
  };
  const handleDelete = (field, index) => {
    // setQuestion(prev => prev.answer.filter((_, i) => i !== index));
    setQuestion((prev) => {
      const removedAnswer = prev.answer[index];
      setDeletedOption((prevRemoved) => [...prevRemoved, removedAnswer]);

      return {
        ...prev,
        answer: prev.answer.filter((_, i) => i !== index),
      };
    });
    if (visibleIndex === index) {
      setVisibleIndex(null);
    } else if (visibleIndex > index) {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  const mcqAnswerFields = () => {
    return (
      <>
        {question.answer?.map((field, index) => (
          <Row key={index} style={{ marginBottom: "30px" }}>
            <Col md={12}>
              {question.math_symbol === 1 ?
                <MathAnswerField>
                  <MathAnswerBox>
                    <Checkbox
                      checked={field.is_correct_ans}
                      onChange={(e) =>
                        handleAnswerOptions(
                          index,
                          e.target.checked,
                          question.type
                        )
                      }
                      choiceTest
                      className={css.queradioButton}
                    />
                    <div style={{ width: "70%", maxWidth: "270px", marginBottom: "3px" }}>
                      <MathInput
                        value={field.answer}
                        placeholder={`Option ${index + 1}`}
                        style={{ height: "30px" }}
                        onChange={(e) => handleInputChange(index, e)}
                        id={"form_input"}
                        fromCreateTest
                        maxLength={config.maxAnswerTextLength}
                        onClick={stopPropagation}
                        onMouseDown={stopPropagation}
                      />
                    </div>
                    <IconImageUpload
                      fill={theme.primary}
                      onClick={() => handleVisibleAnswersImage(index)}
                      style={{ cursor: "pointer" }}
                    />
                    <IconDeleteMinus
                      fill={
                        question.answer.length <= 2 ? "#767676" : theme.primary
                      }
                      style={
                        question.answer.length <= 2
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                      onClick={
                        question.answer.length <= 2
                          ? null
                          : () => handleDelete(field, index)
                      }
                    />
                  </MathAnswerBox>
                  {visibleIndex === index && (
                    <BlockUpload
                      fromTest
                      uploadType="image"
                      value={field.image}
                      onChange={(e) => handleAnswerImageUpload(index, e)}
                      // onClick={this.stopPropagation}
                      // onMouseDown={this.stopPropagation}
                      maxSize={256000}
                      noCompression
                      isRemove

                    />
                  )}
                </MathAnswerField>
                :
                <AnswerField>
                  <AnswerBox>
                    <div className={css.center}>
                      <Checkbox
                        checked={field.is_correct_ans}
                        onChange={(e) =>
                          handleAnswerOptions(
                            index,
                            e.target.checked,
                            question.type
                          )
                        }
                        choiceTest
                        className={css.queradioButton}
                      />
                      <AnswerInput
                        value={field.answer}
                        placeholder={`Option ${index + 1}`}
                        style={{ height: "30px" }}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "center",
                      }}
                    >
                      <IconImageUpload
                        fill={theme.primary}
                        onClick={() => handleVisibleAnswersImage(index)}
                        style={{ cursor: "pointer" }}
                      />
                      <IconDeleteMinus
                        fill={
                          question.answer.length <= 2 ? "#767676" : theme.primary
                        }
                        style={
                          question.answer.length <= 2
                            ? { cursor: "not-allowed" }
                            : { cursor: "pointer" }
                        }
                        onClick={
                          question.answer.length <= 2
                            ? null
                            : () => handleDelete(field, index)
                        }
                      />
                    </div>
                  </AnswerBox>
                  {visibleIndex === index && (
                    <BlockUpload
                      fromTest
                      uploadType="image"
                      value={field.image}
                      onChange={(e) => handleAnswerImageUpload(index, e)}
                      // onClick={this.stopPropagation}
                      // onMouseDown={this.stopPropagation}
                      maxSize={256000}
                      noCompression
                      isRemove

                    />
                  )}
                </AnswerField>}
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const fillAnswerFields = () => {
    return (
      <>
        {question.answer.map((field, index) => (
          <Row key={index} style={question.math_symbol === 1 ? { marginBottom: "15px" } : { marginBottom: "30px" }}>
            <Col md={10} style={question.math_symbol === 1 ? { width: "270px" } : {}}>
              {question.math_symbol === 1 &&
                <label className="score-label">
                  {`Option ${index + 1}`}
                </label>}
              {question.math_symbol === 1 ?


                <MathInput
                  className="mcqOptions"
                  id={"form_input"}
                  value={field.answer}
                  placeholder={`Option ${index + 1}`}
                  style={{ height: "30px" }}
                  maxLength={config.maxAnswerTextLength}
                  onClick={stopPropagation}
                  onMouseDown={stopPropagation}
                  onChange={(e) => {
                    handleInputChange(index, e);
                    handleAnswerOptions(index, true, question.type);
                  }}
                />

                :
                <InputField
                  className="mcqOptions"
                  value={field.answer}
                  placeholder={`Option ${index + 1}`}
                  style={{ height: "30px" }}
                  onChange={(e) => {
                    handleInputChange(index, e.target.value);
                    handleAnswerOptions(index, true, question.type);
                  }}
                />}
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderAnswerFields = () => {
    switch (question.type) {
      case 0:
      case 6:
        return mcqAnswerFields();

      case 1:
        return fillAnswerFields();

      default:
        return null;
    }
  };

  const handleUpdateSection = (updatedQuestion, selectedSec) => {
    setSections((prevSections) => {
      const sectionIndex = prevSections.findIndex(
        (section) => section.section_order === selectedSection
      );

      let updatedSections = [...prevSections];

      if (sectionIndex === -1) {
        // If the section doesn't exist, create a new section with the selectedSection
        const newSection = {
          section_order: selectedSection,
          questions: [updatedQuestion],
        };
        updatedSections = [...updatedSections, newSection];
      } else {
        const section = { ...updatedSections[sectionIndex] };
        const questionIndex = section.questions.findIndex(
          (q) => q.id === question.id
        );

        if (questionIndex === -1) {
          // If the question doesn't exist, add it
          section.questions = [
            ...section.questions,
            {
              ...updatedQuestion,
              order: selectedSec[0].questions.length + 1,
            },
          ];
        } else {
          // If the question exists, update it in place
          section.questions[questionIndex] = updatedQuestion;
        }

        // Check if all questions in the section have the same points
        const firstQuestionPoints = section.questions[0].points;
        const allPointsEqual = section.questions.every(
          (q) => q.points === firstQuestionPoints
        );

        // Update the section with the new information
        if (!allPointsEqual) {
          section.is_limit = 0;
          section.max_questions = section.questions.length.toString();
        }

        updatedSections[sectionIndex] = section;
      }

      return updatedSections;
    });

    // Reset question and answer fields
    resetQuestion();
    restAnswerField();
    setDeletedOption([]);
    setModfiedAnswer([]);
    setVisibleIndex(null);
  }

  const handleSave = async () => {
    if (question.type !== 4) {
      insertUpdateQuestion();
    } else {
      let minId = 0;
      const selectedSec = sections.filter(
        (section) => section.section_order === selectedSection
      );
      if (question.type === 4 && question.id > 0) {
        const args = {
          questionId: question.id.toString(),
          mathSymbol: question.math_symbol,
          content_format: question.content_format?.toString(),
          file_name: question.content,
          content_source: question.content_source,
          assignment: 1,
          description: question.question_content,
          categoryId: testData.categoryId,
          curriculumId: testData.categoryId,
          tags: [],
          questionName: question.question,
          questionType: question.std_assignment_type,
          questionImage: "",
          content: question.content,
          questionCredit: question.points,
          recorder: 0,
          shortCodes: question.shortCodes,
          solution: String(question.solution),
          assignmentType: question.std_assignment_type,
          sectionOrder: question.section_order,
        };
        const { rsp } = await props.ctx_api.privateRequest({
          cmd: "insert_update_assignment",
          args: args,
        });
        const qData = rsp;

      }
      const getMinId = (array) => {
        return Math.min(...array?.map(obj => obj.id));
      };

      minId = getMinId(selectedSec[0].questions);
      let newId;

      if (minId < 0) {
        newId = minId - 1;
      } else {
        newId = -1;
      }
      const updatedQuestion =
        question.action === "update"
          ? {
            ...question,
            section_name: selectedSec[0].section_name,
          }
          : {
            ...question,
            id: newId,
            section_name: selectedSec[0].section_name,
          };

      handleUpdateSection(updatedQuestion, selectedSec);
    }
  };


  const handleCancel = () => {
    resetQuestion();
    setModfiedAnswer([]);
    restAnswerField();
    setVisibleIndex(null)
  };

  const renderSectionEdit = () => {
    const sectionData = sections.find(
      (section) => section.section_order === selectedSection
    );
    return (
      <SectionEdit
        sectionOrder={selectedSection}
        sectionData={sectionData}
        handleReset={handleReset}
      />
    );
  };

  const questionnaireTab = () => {
    if (contentButton.createQuestion || contentButton.addLibrary) {
      return (
        <div className={css.leftColumnHeader}>
          <div className={css.contentButtonWrapper}>
            <div
              className={css.createTab}
              id={contentButton.createQuestion ? css.contentActive : ""}
              onClick={() => handleContentTabClick("createQuestion")}
              style={inlineBackground}
            >
              Create Question
            </div>
            <div
              className={css.addTab}
              id={contentButton.addLibrary ? css.contentActive : ""}
              onClick={() => handleContentTabClick("addLibrary")}
              style={inlineBackground}
            >
              Add from library
            </div>
          </div>
        </div>
      );
    }
  };

  const leftColumnHeader = () => {
    if (
      tabButton.questionnaire &&
      !tabButton.feedback &&
      !contentButton.sectionEdit &&
      !contentButton.sectionReorder &&
      !contentButton.questionReorder &&
      !contentButton.testEdit
    ) {
      return questionnaireTab();
    } else if (tabButton.questionnaire && tabButton.feedback) {
      const currentSection = sections.find(
        (section) => section.section_order === selectedSection
      );
      return (
        <div className={css.sectionAlign}>
          {currentSection ? currentSection.section_name : "No section selected"}
        </div>
      );
    } else if (tabButton.questionnaire && contentButton.sectionEdit) {
      return <div className={css.sectionEditHeader}>Create Section</div>;
    } else if (tabButton.questionnaire && contentButton.sectionReorder) {
      return (
        <div className={css.reorderHeader}>
          <IconReorderHeader /> &nbsp; Section Reorder
        </div>
      );
    } else if (tabButton.questionnaire && contentButton.questionReorder) {
      const currentSection = sections.find(
        (section) => section.section_order === selectedSection
      );
      return (
        <div className={css.reorderHeader}>
          <IconReorderHeader />
          &nbsp; {currentSection.section_name}
        </div>
      );
    } else if (tabButton.questionnaire && contentButton.testEdit) {
      return (
        <div className={css.reorderHeader} >
          {props.isContentBuilder ? props.ctx_labels.singular.create + props.ctx_labels.singular.assessment :
            props.ctx_labels.singular.update + props.ctx_labels.singular.assessment}
        </div>
      );
    } else if (tabButton.preview) {
      return <div>{props.ctx_labels.singular.preview}</div>;
    } else {
      return null;
    }
  };

  const renderQuestionnaireTab = () => {
    return (
      <>
        {contentButton.createQuestion && renderCreateTest()}
        {contentButton.addLibrary && renderAddQuestion()}
        {contentButton.sectionEdit && renderSectionEdit()}
        {contentButton.feedback && renderFeedbackTab()}
        {contentButton.questionReorder && renderQuestionReorder()}
        {contentButton.sectionReorder && renderSectionReorder()}
        {contentButton.testEdit && renderTestEdit()}
      </>
    );
  };
  const handleReset = () => {
    setContentButton({
      createQuestion: true,
      addLibrary: false,
      preview: false,
      sectionEdit: false,
      feedback: false,
      questionReorder: false,
      sectionReorder: false,
      testEdit: false,
    });
  };

  const handleMathQuestionChange = (data) => {
    setQuestion((prev) => {
      return {
        ...prev,
        math_symbol: data === true ? 1 : 0
      }
    })
  }
  const renderTestEdit = () => {
    return <TestEdit
      isContentBuilder={props.isContentBuilder}
      handleReset={handleReset}
      testModify={testModify}
      editdivSub={editdivSub}
    />;
  };

  const renderFeedbackTab = () => {
    return (
      <FeedbackTest
        sectionOrder={selectedSection}
        setTabButton={setTabButton}
        setRightTab={setRightTab}
        handleReset={handleReset}
      />
    );
  };

  const renderQuestionReorder = () => {
    return (
      <QuestionReorder
        sectionOrder={selectedSection}
        handleReset={handleReset}
      />
    );
  };
  const renderSectionReorder = () => {
    return (
      <SectionReorder
        sectionOrder={selectedSection}
        setSelectedSection={setSelectedSection}
        handleSectionData={handleSectionData}
        handleReset={handleReset}
      />
    );
  };
  const handledirection = (direction) => {
    // this.setState({direction:direction})
  };
  const renderPreviewTab = () => {
    return (
      <div className={css.preview}>
        <CreateTestPreview
          preview={sections}
          action="update"
          direction={handledirection}
          // clear = {this.handleClear}
          fromCreateTest
          fromContentBuilder={props.isContentBuilder}
        />
      </div>
    );
  };

  const renderLeftColumnContent = () => {
    if (!tabButton.preview) {
      return renderQuestionnaireTab();
    } else if (tabButton.preview) {
      return renderPreviewTab();
    } else {
      return null;
    }
  };

  const renderLeftColumn = () => {
    return (
      <div className={css.leftColumn}>
        {leftColumnHeader()}
        <div
          className={css.leftColumnContent}
          ref={scrollableDivRef}
          style={ { overflowY: "auto" }}
        >
          {renderLeftColumnContent()}
        </div>
      </div>
    );
  };

  const handleEdit = (index) => {
    const newDescription = prompt(
      "Edit the feedback description:",
      setTestFeedback[index].feedbackdescription
    );
    if (newDescription !== null) {
      setTestFeedback((prevData) =>
        prevData.map((item, i) =>
          i === index ? { ...item, feedbackdescription: newDescription } : item
        )
      );
    }
  };

  const renderRightColumn = () => {
    return (
      <div className={css.rightColumn}>
        {sectionVisible && <div className={css.rightColumnHeader}>
          <div
            className={css.sectionButtonWrapper}
          // id={tabButton.feedback ? css.wrapperId : ""}
          >
            {sections.length > 4 && (
              <div
                className={classNames(css.scrollButton, css.scrollLeft)}
                onClick={scrollLeft}
              >
                <IconChevronLeft style={{ width: "10px" }} />
              </div>
            )}

            <div
              className={css.sectionDivWrapper}
              style={sections.length > 5 ? { left: "1.2rem" } : { left: "2px" }}
              ref={scrollRef}
            >
              {sections.map((div, index) => (
                <OverlayTooltip
                  tip={div.section_name}
                  placement="top"
                  fromTestSec
                  disabled={div.section_name.length <= 20}
                >

                  <div
                    key={div.section_order}
                    id={div.condition ? css.selctedScetion : ""}
                    onClick={() => handleSectionData(div)}
                    className={css.sectionDiv}
                    style={inlineBackground}
                  >
                    {div.section_name.length > 20 ?
                      div.section_name.slice(0, 20) + "..."
                      : div.section_name
                    }
                    <div className={css.sectionMenu}>
                      {renderSectionDropdown(div)}
                    </div>
                  </div>
                </OverlayTooltip>
              ))}
            </div>
            {sections.length > 4 && (
              <div
                className={classNames(css.scrollButton, css.scrollRight)}
                onClick={scrollRight}
              >
                <IconChevronRight style={{ width: "10px" }} />
              </div>
            )}
          </div>

          {tabButton.questionnaire && (
            <div className={css.sectionAddButton}>
              <div
                className={css.addsectionButton}
                onClick={addSections}
                style={inlineBackground}
              >
                +
              </div>
            </div>
          )}
        </div>}
        <div className={css.rightColumnContent}>
          {rightTab.section === true && (
            <SectionCard
              sectionOrder={selectedSection}
              contentButton={contentButton}
              setContentButton={setContentButton}
              setModfiedAnswer={setModfiedAnswer}
              difficultyLevels={difficultyLevels}
            />
          )}

          {/* {rightTab.feedBack === true &&
              <FeedbackCard/>
          } */}
          {rightTab.feedBack === true && (
            <FeedbackCard sectionOrder={selectedSection} taggedCourses={taggedCourses} />
          )}
        </div>
      </div>
    );
  };

  const renderCreateTest = () => {
    const contentFormat =
      question.content_format === "0" || question.content_format === 0
        ? "AUDIO"
        : question.content_format === "1" || question.content_format === 1
          ? "VIDEO"
          : question.content_format === "2" || question.content_format === 2
            ? "PDF"
            : "IMAGE";
    const hideDescMedia = () => {
      setHideDescMediaRow(!hideDescMediaRow);
    };

    return (

      <>
        <Modal
          size="lg"
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          centered
        >
          <ModalBody>
            {(question.content_format === "0" ||
              question.content_format === "1") && (
                <Video
                  url={question.media_link}
                  // videoUrl={enteredUrl}
                  onLoaded={() => { }}
                  onError={() => { }}
                  className={"video-player"}
                />
              )}
            {question.content_format === "2" && (
              <Pdf
                pdfUrl={question.media_link}
                onLoaded={() => { }}
                onError={() => { }}
              // fromCreateTest
              />
            )}
            {question.content_format === "3" && (
              <img
                src={question.content}
                // style={{ height: "15.8rem", width: "auto" }}
                className={css.imageAlign}
              />
            )}
          </ModalBody>
        </Modal>
        <Row>
          <Col md={6}>
            <OverlayTooltip
              tip="Cannot change the question type for assignment questions."
              placement="top"
              fromTestSec
              disabled={!(question.type === 4 && (question.id < 0 || question.id > 0))}
            >
              <div>
                <label className="score-label">
                  {props.ctx_labels.singular.question} type
                </label>
                <span style={{ color: "red" }}>*</span>

                <FormField
                  type={Select}
                  name="type"
                  options={typeOptions}
                  onChange={handleQuestionChange}
                  value={question.type}
                  placeholder="Question Type"
                  required
                  disabled={question.type === 4 && (question.id < 0 || question.id > 0)}
                />
              </div>
            </OverlayTooltip>
          </Col>
          <Col md={6}>
            <div>
              <label className="score-label">
                {props.ctx_labels.singular.shortCodes}
              </label>
              <FormField
                type={InputField}
                name="shortCodes"
                onChange={handleQuestionChange}
                value={question.shortCodes}
                placeholder={props.ctx_labels.singular.shortCodes}
              />
            </div>
          </Col>
        </Row>
        {question.type === 4 && (
          <Row>
            <Col md={12}>
              <div>
                <label className="score-label">
                  {props.ctx_labels.singular.assignment} type
                </label>
                <span style={{ color: "red" }}>*</span>
                <FormField
                  type={Select}
                  name="std_assignment_type"
                  options={assignmentType}
                  onChange={handleQuestionChange}
                  value={question.std_assignment_type}
                  placeholder="Assignment Type"
                  required
                />
              </div>
            </Col>
          </Row>
        )}
        <Row className={css.questionRow}>
          <Col md={10} style={{ position: "relative" }}>
            <div style={{ maxWidth: "336px" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <label className="score-label">
                    {props.ctx_labels.singular.question}
                  </label>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className={css.switchPosition}>
                  <SwitchButton
                    onChange={handleMathQuestionChange}
                    value={question.math_symbol}
                  />{props.ctx_labels.singular.mathQuestion}
                </div>
              </div>
              <FormField
                name="question"
                type={question.math_symbol === 1 ? MathInput : Textarea}
                id={"form_input"}
                onPaste={handlePaste}
                value={question.question}
                placeholder="Question"
                onChange={handleQuestionChange}
                required
                maxLength={1000}
                rows={3}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className={css.hideDesc} onClick={hideDescMedia}>
              <IconHider />
            </div>
          </Col>
        </Row>

        <Row
          className={css.mediaDescRow}
          id={hideDescMediaRow ? css.mediaDescId : ""}
        >
          <Col md={12}>
            <div className={css.customEditor}>
              <FormField
                componentClass="textarea"
                className="input-full"
                type={JoditEditor}
                name="question_content"
                value={question.question_content}
                config={configs}
                editorRef={editor}
                onChange={handleQuestionChange}
                placeholder="Question Description"
              />
            </div>
          </Col>
          {question.type === 3 && (
            <Col md={12} style={{ paddingBottom: "15px" }}>
              <Checkbox
                checked={question.is_record === 1 ? true : false}
                fromTest
                onChange={(e) => {
                  handleCheckboxChange(e, "recorder");
                }}
              >
                Recorder
              </Checkbox>
            </Col>
          )}
          <Col md={12} className={css.btnGroupCol}>
            <div>
              <Checkbox
                checked={question.media === 1}
                fromTest
                onChange={(e) => {
                  handleCheckboxChange(e, "media");
                }}
              >
                Media
              </Checkbox>
            </div>
            {question.media === 1 && (
              <div className={css.questionMediaPreview}>
                <div className={css.btnIconGroup}>
                  <BtnIconGrp onClick={() => handleMediaSelect("AUDIO")}>
                    {question.content_format === "0" ? (
                      <ActiveBtnIcon>
                        <IconAudioCircle />
                      </ActiveBtnIcon>
                    ) : (
                      <IconAudioCircle />
                    )}
                  </BtnIconGrp>
                  <BtnIconGrp onClick={() => handleMediaSelect("VIDEO")}>
                    {question.content_format === "1" ? (
                      <ActiveBtnIcon>
                        <IconVideoCircle />
                      </ActiveBtnIcon>
                    ) : (
                      <IconVideoCircle />
                    )}
                  </BtnIconGrp>
                  <BtnIconGrp onClick={() => handleMediaSelect("PDF")}>
                    {question.content_format === "2" ? (
                      <ActiveBtnIcon>
                        <IconPdfCircle />
                      </ActiveBtnIcon>
                    ) : (
                      <IconPdfCircle />
                    )}
                  </BtnIconGrp>
                  <BtnIconGrp onClick={() => handleMediaSelect("IMAGE")}>
                    {question.content_format === "3" ? (
                      <ActiveBtnIcon>
                        <IconImageCircle />
                      </ActiveBtnIcon>
                    ) : (
                      <IconImageCircle />
                    )}
                  </BtnIconGrp>
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <Button
                    className={css.previewButton}
                    onClick={() => setIsModalOpen(!isModalOpen)}
                    disabled={!question.media_link || previewDisable}
                  >
                    {/* <IconPreviewEye /> */}
                    {props.ctx_labels.singular.preview}
                  </Button>
                </div>
              </div>
            )}
          </Col>

          {question.media === 1 &&
            question.content_format &&
            question.content_format !== "" && (
              <Col md={12} style={{ paddingTop: "10px" }}>
                <div>
                  <VideoInput
                    // className="input-options"
                    onChange={handleQuestionFile}
                    // module={this.props.activeEntityDetails}
                    format={contentFormat}
                    // updateFileUploading={this.props.updateFileUploading}
                    fromTestCreation
                    setPreviewDisable={setPreviewDisable}
                  />
                </div>
              </Col>
            )}
          <Col
            md={12}
            style={
              question.media === 1 && question.content_format !== ""
                ? {}
                : { paddingTop: "10px" }
            }
          >
            <FormField
              name="solution"
              type={Textarea}
              value={question.solution}
              placeholder="Solution"
              onChange={handleQuestionChange}
              maxLength={config.maxDescriptionLength}
            />
          </Col>
        </Row>

        {renderAnswerFields()}

        <Row style={{ justifyContent: "space-between" }}>
          <Col md={4}>
            <div>
              <label className="score-label">Complexity</label>
              <FormField
                type={Select}
                name="difficulty_level"
                options={difficultyLevels}
                onChange={handleQuestionChange}
                value={question.difficulty_level}
                disabled={question.type === 4}
              />
            </div>
          </Col>
          <Col md={3}>
            <div>
              <label className="score-label">
                {props.ctx_labels.singular.mark}
              </label>
              <span style={{ color: "red" }}>*</span>
              <FormField
                type={InputField}
                name="points"
                className={css.noArrows}
                style={{ width: "65px" }}
                onChange={handleQuestionChange}
                value={question?.points}
                required
              />
            </div>
          </Col>
          <Col md={5}>
            <div>
              <label className="score-label">
                {props.ctx_labels.singular.negative}
              </label>
              <div className={`${css.negativeMark} ${css.noArrows}`}>
                <FormField
                  type={InputField}
                  name="negative_points"
                  style={{ width: "65px" }}
                  onChange={handleQuestionChange}
                  value={question.negative_points}
                  disabled={question.type === 4 || question.type === 3}
                />
                <FormField
                  className={css.toggleForm}
                  type={StyledToggleButtonGroup}
                  name="mathSymbol"
                  options={mathSymbols}
                  value={question.negativeCreditPercent}
                  onChange={(e) => { handleMathSymbolChange(e) }}
                  disabled={question.type === 4 || question.type === 3}
                  inline
                />

              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={css.questionButton}>
            <OverlayTooltip
              tip={
                <div>
                  <ul style={{ textAlign: "left" }}>
                    {tooltipMessages.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              }
              placement="top"
              fromTestSec
              disabled={!isSaveDisabled && tooltipMessages.length <= 0}
            >
              <Button
                className={css.rectangleButton}
                style={{
                  backgroundColor: theme.primary,
                  borderColor: theme.primary,
                }}
                disabled={isSaveDisabled}
                onClick={handleSave}
              >
                <IconSaveNew /> Save
              </Button>
            </OverlayTooltip>
            <Button
              className={css.rectangleButton}
              variant="btn-outline-cancel"
              onClick={handleCancel}
            >
              <IconCancelCreateTest style={{ height: "10px", width: "10px" }} />
              Cancel
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const renderAddQuestion = () => {
    return (
      <AddQuestion sectionOrder={selectedSection} handleReset={handleReset} />
    );
  };

  const renderBody = () => {
    return (
      <div className={css.bodyRow} style={tabButton.preview ? { overflow: "auto" } : {}}>
        {!tabButton.preview && renderLeftColumn()}
        {!tabButton.preview && renderRightColumn()}
        {tabButton.preview && renderPreviewTab()}
      </div>
    );
  };

  return (
    <>
      <div className={css.mainWrapContainer}>
        {renderHeader()}
        {renderTabs()}
        {renderBody()}
      </div>
    </>
  );
}

export default withRouter(withTestContext(
  withWindowContext(withUserContext(withApiContext(withLabelsContext(TestCreation))))
));
