
import { Button, OverlayTooltip, PageTitle } from "components";
import React, { Component } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import SectionNumber from "./Section/SectionNumber";
import css from "./student-assessments.module.scss";
import { withRouter } from "react-router-dom";
import { withApiContext, withLabelsContext, withWindowContext, withUserContext, withAnalyticsContext } from "context";
import { initBot, startChatBot, stopChatBot } from "../../../services/chatBot";
import Timer from "react-compound-timerv2/build";
import Question from "../InternalItems/Question";
import Loader from "components/Spinners/Loader";
import * as config from "config";
import { Spin as SpinIcon } from "components/Spinners/Spin";
import { Redirect } from "react-router";
// import OverlayTooltip from 'components/OverlayTooltip/OverlayTooltip';
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import { Link } from "react-router-dom";
import * as util from "services/util";
import { IconCancelAssessment, IconClockAssessment, IconSaveAssessment, IconSubmitAssessment, IconNextNew, IconQuestionGrid } from "Icons";
import styled from "styled-components";
import Grid from "./Section/Grid";
import ResponsiveTabsAssessment from "components/ResponsiveTabs/ResponsiveTabAssessment";
// import { Link } from "react-router-dom";
import TabTails from "components/ResponsiveTabs/TabTails";
import AssessmentTest from "pages/Test/Components/AssessmentTest";

const StyledPlayerButton = styled.span`
  && {
    .${css.grid}{
      color: ${({ theme }) => theme.color2};
    }
    .grid{
      fill: ${({ theme }) => theme.color2};
    }
  }
`;

const StyledButton = styled.div`
    .${css.previous}{
      background-color: ${({ theme }) => theme.color2};
    }
    .${css.next}{
      background-color: ${({ theme }) => theme.color2};
    }
`;

const StyledScrollbar = styled(Col)`
  /* Your scrollbar styles here */
  scrollbar-color: rgb(10, 119, 227) #7bbbe0;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color2};
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color2};
  }
    
  /* Customize scrollbar track when dragging */
  &::-webkit-scrollbar-thumb:active {
    background-color: #333;
  }
`;

const StyledScrollbarDiv = styled.div`
  /* Your scrollbar styles here */
  scrollbar-color: rgb(10, 119, 227) #7bbbe0;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color2};
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color2};
  }
    
  /* Customize scrollbar track when dragging */
  &::-webkit-scrollbar-thumb:active {
    background-color: #333;
  }
`;

class StudentAssessments extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirect: false,
      onExit: null,
      onSave: null,
      onSubmit: null,
      onClickBackButton: false,
      tests: null,
      questionSection: [this.initSectionItem()],
      questionAnswer: [],
      testQuestions: {},
      testAnswers: {},
      selectedItem: null,
      assessmentId: null,
      index: 0,
      prevIndex: 0,
      direction: "prev",
      startBtn: null,
      btnActive: false,
      allSaved: true,
      updateElapsedTimeOrNot: true,
      alertTime: false,
      duration: 0,
      action: 0,
      totalQnsAttended: 0,
      testState: 1,
      testClosed: false,
      load: false,
      count: 0,
      gridOpen: false,
      COLORS: [
        "#0e4f69",
        "#FFBB28",
        "#00C49F",
        "#08a822",
        "#00A49F",
        "#FFDB48",
        "#FG8012",
      ],
      status: 0,
      summaryData: null,
      summaryError: null,
      testAssessmentId: null,
      loading: true,
      pdfUrl: "",
      modalOpen: false,
      img: "",
      rand_question: 0,
      moduleTest: false,
      isConfirmationModalOpen: false,
      confirmationMessage: "",
      allowConfirmation: false,
      isFullScreen: null,
      isAssignment: false,
      isFullScreenChange: false,
      isIphone: false
    };
    this.checkFullScreen = this.checkFullScreen.bind(this);
    this.initialTime = new Date();
    this.initiallyAttended = 0;
    this.tabsRef = React.createRef();

  }
  // initState = {
  //   show: false,
  //   image: "",
  // }




  disableRightClick = (event) => {
    event.preventDefault();
  };

  initSectionItem = () => ({
    section_order: 0,
    section_name: "",
    questions: [],
  });

  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };

  isIphone = () => {
    return /iPhone/i.test(navigator.userAgent);
  };

  toggleFullscreen = () => {
    const element = document.documentElement;
    if (this.isIphone()) {
      this.setState({ isIphone: true })
      return;
    }
    const handleFullscreenChange = () => {

      if (!document.fullscreenElement) {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      }
    };

    const handleFullscreenError = (err) => {
      this.setState({ isFullScreen: false });
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('fullscreenerror', handleFullscreenError);
    };

    if (document.fullscreenElement === null) {
      // Request fullscreen
      element.requestFullscreen().then(() => {
        this.setState({ isFullScreen: true, isFullScreenChange: true });
        document.addEventListener('fullscreenchange', handleFullscreenChange);
      }).catch((err) => {
        handleFullscreenError(err);
      });

      document.addEventListener('fullscreenerror', handleFullscreenError);
    } else {
      // Exit fullscreen
      document.exitFullscreen();
    }
  };



  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyDown);
    // this.handleKeyDown()
    this.toggleFullscreen()
    if (this.state.isFullScreen === false) {
      this.setState({
        isConfirmationModalOpen: true
      });
    }
    this.intervalId = setInterval(this.checkFullScreen, 100);
    if (this.props.location.assessmentData !== undefined) {
      this.handleGetTestResponse();
      document.body.classList.add(css.disable_text_selection);
      document.addEventListener("contextmenu", this.disableRightClick);
      this.summaryInterval = setInterval(
        this.handleSummary,
        parseInt(this.props.ctx_user.user.analytics.studyMapTriggerInterval)
      );
    }
    stopChatBot(null)
  };

  toggleAssignment = () => {
    this.setState((prevState) => ({
      isAssignment: !prevState.isAssignment,
    }))
  }


  checkFullScreen = () => {
    const isFullScreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
    if (this.isIphone()) {
      return
    }
    if (!isFullScreen) {
      this.setState({ isFullScreen: false, isFullScreenChange: true })
      // setTimeout(() => {
      //   this.handleConfirmationSubmit(true);
      // }, 3000); 
      if (!this.state.isAssignment) {
        this.onSelectSubmit();
      }
      // If not in full screen, switch to full screen
      this.toggleFullscreen()
    } else {
      if (this.state.isFullScreenChange) {
        this.setState({ isConfirmationModalOpen: false })
      }
      this.setState({ isFullScreen: true, isFullScreenChange: false });
    }
  }



  handleKeyDown = (event) => {
    const pressedKey = event.key;
    const pressedKeyCode = event.keyCode;
    const disabledKeys = pressedKeyCode >= 112 && pressedKeyCode <= 123;
    const isF11 = event.keyCode === 176
    if (pressedKey === 'Alt' || pressedKey === 'Meta' || pressedKey === 'AltGraph' || pressedKey === 'Control' || pressedKey === 'Esc' || disabledKeys || isF11 || pressedKeyCode === 27) {
      this.onSelectSubmit()
      // Prevent the default behavior of the Alt key
      event.preventDefault();
    }
    if (event.key === "F11" && event.metaKey) {
      event.preventDefault();
    } else {
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
    this.setState({ isAssignment: false })
    if (!this.isIphone()) {
      document.exitFullscreen();
    }
    if (this.state.updateElapsedTimeOrNot === true) {
      this.updateElapsedTime();
    }
    document.body.classList.remove(css.disable_text_selection);
    document.removeEventListener('contextmenu', this.disableRightClick);
    clearInterval(this.summaryInterval)
    this.props.ctx_user.handleChatBot(true);
  };

  handleSummary = (prevModule = null) => {
    var current = new Date();
    let classId = parseInt(this.state.tests.classId)
    const summaryData = {
      event_id: parseInt(this.props.location.assessmentData.data.detailsData.assessmentId),
      event_name: this.props.location.assessmentData.data.detailsData.name,
      eventactive_time: Math.round((current.getTime() - this.initialTime.getTime()) / 1000),
      eventstart_at: this.initialTime,
      eventend_at: new Date(),
      intent: "assessment",
      details: {
        publish_test_id: +this.state.assessmentId,
        // "course_id": "<course id>",
        // "course_name": "<course name>",
        // "course_publish_id": "<course publish id>",
        // "course_publish_name": "<course publish name>",
        // "curriculum_id": "<curriculum id>",
        // "curriculum_name": "<curriculum name>",
        // "topic_id": "<chapter id>",
        // "topic_name": "<chapter name>",
        questions_attended: this.state.totalQnsAttended,
        class_id: classId
      }
    };
    this.initialTime = current;
    this.updateAnalytics(summaryData);
  };

  updateAnalytics = (r) => {
    this.props.ctx_analytics.update("player")({ ...r });
  };
  getStudentAssessmentQuestions = () => {
    const publish_id = this.props.location.assessmentData.data.detailsData
      .assessmentId;

    // alert(this.props.publishId)
    this.props.ctx_api
      .privateRequestRow({
        cmd: "get_student_assessment_details",
        args: { publishId: publish_id.toString() },
      })
      .then(this.handleGetTestResponse)
      .catch(this.handleGetTestFailure);

  };

  handleImageClick = (img) => {
    this.setState({ modalOpen: true, img: img })
  }

  handleGetTestResponse = () => {
    // console.log(rsp.test_id);
    console.log(this.props.location.assessmentData.completeDetails[0].test_id);
    const completeDetails=this.props.location.assessmentData.completeDetails[0];
    console.log(completeDetails);
    const tests = {
      publishId: this.props.location.assessmentData.data.detailsData
        .assessmentId,
      id: completeDetails.test_id,
      title: completeDetails.name,
      identifier: completeDetails.identifier,
      description: completeDetails.description,
      category: completeDetails.standard,
      subject: completeDetails.subject,
      dateFrom: completeDetails.from_date,
      dateTo: completeDetails.to_date,
      timerFrom: completeDetails.timer_from,
      timerTo: completeDetails.timer_to,
      duration: completeDetails.duration,
      status: completeDetails.status,
      action: completeDetails.action,
      elapsedTime: completeDetails.elapsed_time,
      lattestAssessmentId: completeDetails.latest_assessment_id,
      totMarks: completeDetails.totmarks,
      obtMarks: completeDetails.obtmarks,
      totQuestions: completeDetails.totquestions,
      correct: completeDetails.correct,
      incorrect: completeDetails.incorrect,
      missed: completeDetails.missed,
      attempted: completeDetails.attempted,
      unattempted: completeDetails.unattempted,
      testState: completeDetails.test_state,
      testStateName: completeDetails.test_state_name,
      testType: completeDetails.test_type,
      testTypeName: completeDetails.test_type_name,
      multiAttempt: completeDetails.multi_attempt,
      totDuration: completeDetails.total_duration,
      attemptCount: completeDetails.attempt_count,
      coursePublishId: completeDetails.course_publish_id,
      classId: completeDetails.classbatch_id,
      groupedTest: completeDetails.grouped_test,
      certificate_template: completeDetails.certificate_template,
      certificate_status: completeDetails.certificate_status,
      has_certificate:completeDetails.has_certificate,
      section_hide:completeDetails.section_hide

    };
    this.setState({ tests, rand_question: completeDetails.rand_question }, () => {
      this.createStudentAssessment();
      this.setState((s0) => ({
        prevIndex: s0.index,
        index: s0.index + 1,
        status: 1,
        action: 1,
      }));
    });
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  onSelectExit = (e) => {
    this.setState({ onExit: true })
    const extitTest = (allow) => {
      e.stopPropagation();
      if (allow) {
        this.updateElapsedTime();
        this.goToLanding();
        this.updateTraineeMilestones("In Progress");
      }
      else {
        this.setState({ onExit: false })
      }
    };

    this.props.ctx_window.simpleConfirm(
      "Are you sure you want to exit this test ?",
      extitTest
    );
  };
  updateElapsedTime = (interval) => {
    // const subjectData = this.props.location.assessmentData.subjectData
    const { assessmentId } = this.state;
    const { tests } = this.state;
    const subjectData = this.props.location.assessmentData.subjectData
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_test_assessment_stub",
        args: {
          publishId: tests.publishId.toString(),
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          interval: interval || "0",
          endTime: this.dateToday(),
          status: interval ? 4 : 5,
          coursePublishId: tests.coursePublishId
            ? tests.coursePublishId.toString()
            : null,
          courseId: subjectData.course_id
            ? subjectData.course_id.toString()
            : null,
          groupedTest: tests.groupedTest,
        },
      })
      .then(({ rsp }) => this.handleUpdateElapsedTimeResponse(rsp, interval))
      .catch(this.handleUpdateElapsedTimeFailure);
  };




  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  };

  handleClear = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      // assessmentId: null,
      testQuestions: {},
      testAnswers: {},
    });
  };

  handleExit = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      assessmentId: null,
      testQuestions: {},
      testAnswers: {},
      loading: true,
    });
  };

  renderAnswerFooter = () => {
    {
      const { index, selectedItem, testQuestions, questionIds } = this.state;
      const Disabled = true;
      const selectedIndex = questionIds.indexOf(selectedItem);
      let isFlag = testQuestions[selectedItem].flagged;

      return (
        index !== 0 && !this.state.testClosed && (
          <Card className={`${css.question_card} border-0`}>
            <Card.Footer className={css.footer}>
              <div>
                {this.props.ctx_labels.singular.wantToRevisit} ?
                <Button onClick={this.onSelectFlag} className="ml-2 mr-0">
                  {/* flsg */}
                  {!isFlag ? (this.props.ctx_labels.singular.flag) : (this.props.ctx_labels.singular.unFlag)}
                </Button>
              </div>
              {/* <Pagination /> */}
              <div className={css.pagination}>
                <Button
                  onClick={() => this.handleArrowAction("left")}
                  variant="gray"
                  pill
                  wide
                  disabled={selectedIndex === 0}
                >
                  {this.props.ctx_labels.singular.previous}
                </Button>
                <div className={css.pagination_number}>
                  <span>{selectedIndex + 1}</span> of {questionIds.length}
                </div>
                &nbsp;
                <Button
                  disabled={selectedIndex === questionIds.length - 1}
                  onClick={() => this.handleArrowAction("right")}
                  variant="primary"
                  pill
                  wide
                >
                  {this.props.ctx_labels.singular.next}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        )
      );
      // else return null
    }
  };

  msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  startTestTimer = () => {
    const binkStyle = ["blink", this.state.alertTime ? "alert" : ""].join(" ");

    const createTestCheckPoints = (duration, interval) => {
      let checkpoints = [];

      checkpoints.push({
        time: 60000,
        callback: () => this.onTimerCheckpoint(duration / 4, 0),
        // callback: () => this.setState({alertTime : true})
      });

      checkpoints.push({
        time: 0,
        callback: () => this.onTimerCheckpoint(0, 1),
      });
      checkpoints.push(
        ...new Array(Math.floor(duration / interval))
          .fill(undefined)
          .map((_, i) => {
            return {
              time: i * interval,
              callback: () => this.updateElapsedTime(interval),
            };
          })
      );

      return checkpoints;
    };

    const { status, duration } = this.state.tests;
    return (
      <Timer
        initialTime={duration}
        // initialTime= {90000}    
        direction="backward"
        // checkpoints={createTestCheckPoints(90000 , 30000)}
        checkpoints={createTestCheckPoints(duration, 30000)}
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      >
        {(start, resume, pause, stop, reset) => (
          <React.Fragment>
            <span class={binkStyle}>
              <Timer.Hours />:
              <Timer.Minutes />:
              <Timer.Seconds />
            </span>
          </React.Fragment>
        )}
      </Timer>
    )
    // : (
    //   <span class="glyphicon glyphicon-time">
    //     &nbsp;{this.msToTime(duration)}
    //   </span>
    // );
  };

  handleUpdateElapsedTimeResponse = (rsp, interval) => {
    !interval &&
      this.setState({
        duration: rsp.duration,
        //  load: rsp.StatusValue === 1 ? false : true
      });
  };
  goToLanding = () => {
    this.setState({ redirect: true });
  };

  handleActionBtn = (timerStop) => {

    this.setState((s0) => ({ startBtn: !s0.startBtn }));
    if (timerStop) {
      this.setState({
        testClosed: true,
        updateElapsedTimeOrNot: false
      });
      if (this.state.action) {
        this.onTimerCheckpoint(0, 1);
      }
    }
  };
  onTimerCheckpoint = (rem, completed) => {
    if (completed) {
      this.setState({ updateElapsedTimeOrNot: false });

      this.handleSaveTest(2, () => {
        // this.goToSummary(() => {});
      });
    } else {
      this.setState({ alertTime: true });
    }
  };

  handleSaveTest = (flag, callback) => {
    this.createStudentAssessment(flag, callback);
    // the below line commented for the purpose of doubling issue
    // this.handleActionBtn();
    // callback()
  };

  onSelectItem = (selectedItemId) => () => {
    this.setState({
      selectedItem: selectedItemId,
    });
  };
  onSelectSave = () => {
    this.setState({ onSave: true });
    this.createStudentAssessment(1);
  };
  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  onTestClosed = () => {
    this.setState({ updateElapsedTimeOrNot: false });
    // this.createStudentAssessment(3);
    // this.handleSaveTest(3, () => {
    //   this.setState({ updateElapsedTimeOrNot: false });
    // });
    // this.setState({
    //   updateElapsedTimeOrNot: false });
    // // this.showSuccessPopupAndThen("Saved");
    // this.createStudentAssessment(1);
    // this.props.ctx_window.alert(`Test is closed`, () =>
    //   this.setState({
    //     redirect: true,updateElapsedTimeOrNot: false })

    // );
  };
  createStudentAssessment = (flag, callback) => {
    const subjectData = this.props.location.assessmentData.subjectData
    const { tests } = this.state;
    const { assessmentId, testQuestions, testAnswers } = this.state;
    this.setState({ loading: true, onSubmit: true });

    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_test_assessment_stub",
        args: {
          publishId: tests.publishId.toString(),
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          testQuestions: testQuestions,
          testAnswers: testAnswers,
          endTime: this.dateToday(),
          status: flag || 0,
          coursePublishId: tests.coursePublishId
            ? tests.coursePublishId.toString()
            : null,
          courseId: subjectData.course_id
            ? subjectData.course_id.toString()
            : null,
          groupedTest: tests.groupedTest,
        },
      })

      .then(({ rsp }) =>
        this.handleStudentAssessmentResponse(rsp, callback, flag)
      )
      .catch(this.handleStudentAssessmentFailure);

    if (this.props.location.assessmentData.fromChapterInner) {

      this.props.ctx_api
        .privateRequest({
          cmd: "insert_update_trainee_test_time",
          args: this.state.moduleTest ? {
            courseId: subjectData.course_id.toString(),
            coursePublishId: subjectData.course_publish_id,
            curriculumId: subjectData.curriculum_id,
            inProgress: 0,
            lastViewedPos: +this.state.tests.totQuestions,
            maxViewedPos: +this.state.tests.totQuestions,
            memberId: subjectData.memberId,
            status: 1,
            timeId: "1",
            videoUnitId: subjectData.videoUnitId,
            testCompleted: "completed",
          } : {
            courseId: subjectData.course_id.toString(),
            coursePublishId: subjectData.course_publish_id,
            curriculumId: subjectData.curriculum_id,
            inProgress: 1,
            memberId: subjectData.memberId,
            status: 0,
            timeId: "0",
            videoUnitId: subjectData.videoUnitId,
          },
        });
      if (this.state.moduleTest) {
        this.props.ctx_api
          .privateRequest({
            cmd: "insert_update_trainee_points",
            args: {
              acadYearId: subjectData.acadYearId,
              chapterId: subjectData.chapterId,
              classId: subjectData.class_id,
              courseId: subjectData.course_id.toString(),
              coursePublishId: subjectData.course_publish_id,
              curriculumId: subjectData.curriculum_id,
              memberId: subjectData.memberId,
              type: "T",
              videoUnitId: subjectData.videoUnitId,
            },
          });
      }

    }
  };

  handleStudentAssessmentFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
    this.setState({ loading: false });
  };

  handleStudentAssessmentResponse = (rsp, callback, flag) => {
    const action = flag === 1 ? "Saved" : "Submitted";
    if (!this.state.assessmentId) {
      this.setState({ onSubmit: false })
      this.getTestQuestions(rsp.testassessmentid);
    } else {

      this.setState({ load: rsp.StatusValue === 1 ? false : true, onSubmit: false });
      if (this.state.question_pool === 1) {
        this.updatePoolQuestions();
      }
      this.showSuccessPopupAndThen(action);
    }
    callback && callback();
  };
  showSuccessPopupAndThen = (action) => {
    if (action === "Saved") {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
          onSave: false
        })

      );

    }
    else {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
          redirect: true,

        })

      );

    }

  };
  updatePoolQuestions = () => {
    const { assessmentId, testQuestions } = this.state;
    const questionIds = Object.values(testQuestions).map(question => question.id);
    const qPoolSequence = this.state.questionIds.map((id, index) => {
      return {
        qid: id,
        seq: index + 1
      };
    });
    const idObject = {};
    questionIds.forEach(id => {
      idObject[id] = true;
    });

    this.props.ctx_api
      .privateRequestRow({
        cmd: "update_pool_questions",
        args: {
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          testQuestions: questionIds,
          qPoolSequence: qPoolSequence,
        },
      })
  }
  dateToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var time =
      String(today.getHours()).padStart(2, "0") +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      String(today.getSeconds()).padStart(2, "0");

    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd + " " + time;
    return today;
  };
  onSelectFlag = () => {
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    let flag = testQuestions[selectedItem].flagged;
    testQuestions[selectedItem].flagged = flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = !flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  };

  getTestQuestions = (testassessmentid) => {
    const apiCalls = [
      {
        cmd: "get_student_assessment_questions",
        args: { studentAssessmentId: testassessmentid },
      },
      {
        cmd: "get_student_assessment_question_answers",
        args: { studentAssessmentId: testassessmentid },
      },
    ];
    Promise.all(apiCalls.map(this.props.ctx_api.privateRequest))
      .then((rs) => {
        const pre_test = rs[0].rsp;
        const pre_test_ans = rs[1].rsp;
        this.setState({
          questionSection: pre_test[0].questions,
          questionAnswer: pre_test_ans,
          assessmentId: testassessmentid,
          question_pool: pre_test[0].question_pool

        });
        this.findTotalAttended(pre_test[0].questions, "started")
        this.handleTestAnswers();
      })
      .catch(this.handleGetQuestionFailure);

  };

  findTotalAttended = () => {
    let total = 0;
    this.state.questionSection.forEach(section => {
      total += section.questions_attempted
    })
    this.setState({ totalQnsAttended: total });
  }



  handleTestAnswers = () => {
    const { questionAnswer, questionSection } = this.state;
    const testQuestions = {};
    const testAnswers = {};
    let selectedItem = null;
    let questionIds = [];

    questionSection.forEach((se) => {
      console.log("se", se);
      const sortedQuestions = Object.values(se.questions).sort((a, b) => {
        // return this.state.rand_question === 0 ? a.sequence - b.sequence : 0

        if (this.state.question_pool === 1) {
          return se.questions.indexOf(a) - se.questions.indexOf(b);
        } else if (this.state.rand_question === 0) {
          return a.sequence - b.sequence;
        } else {
          return 0;
        }
      })
      sortedQuestions.forEach((qu) => {
        testQuestions[qu.id] = qu;
        questionIds.push(qu.id);
        if (!selectedItem) {
          selectedItem = qu.id;
        }
        testAnswers[qu.id] = {
          choices: [],
          answers: [],
          comments: [],
          reviewStarts: [],
          reviewEnds: [],
          questions: [],
        };
      });
    });
    questionAnswer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if (
          q.type === typeE.FILL_IN_THE_BLANK ||
          (q.type === typeE.MULTIPLE_CHOICE && r.is_correct_ans)
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    });

    this.setState({
      testQuestions,
      testAnswers,
      selectedItem,
      questionIds,
      loading: false,
    });
    // this.startTestTimer()
  };
  handleClick = (answerImage) => {
    this.setState({ show: true, image: answerImage })
  }

  handleClose = () => {
    this.setState({ modalOpen: false, img: "" });
  };



  handleFlaged = (flag) => {
    this.setState({ flagState: flag })
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].flagged = !flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  }

  mkTestQuestionOverlay = (description, content, testQuestions) => {
    const { questionIds, selectedItem, questionSection, rand_question } = this.state;
    if (selectedItem) {
      const qNumber = this.state.questionIds.indexOf(selectedItem)
      // const sectioN = this.state.questionSection.indexOf()
      let question = this.state.testQuestions[selectedItem];
      const select = this.state.questionSection.filter(sec => question.section_name === sec.section_name ? sec.section_order : "")
      const answers = this.state.testAnswers[selectedItem];
      const qSection = rand_question === 0 ? question.section_order : select[0].section_order;
      const currQuestionSection = questionSection && questionSection.filter((section) => section.section_order === qSection)
      const screenSize = window.screen.width
      const currQuestion =
        currQuestionSection &&
        currQuestionSection[0].questions.filter((quest) => quest.id === question.id);
      const disabled =
        (currQuestionSection[0].questions_attempted <= +currQuestionSection[0].max_questions &&
          currQuestion[0]?.attempted === 1)
          ||
          (currQuestionSection[0].questions_attempted < +currQuestionSection[0].max_questions &&
            currQuestion[0]?.attempted === 0 || currQuestion[0]?.questions_attempted === 0)
          ? false
          : true
      let isFlag = testQuestions[selectedItem]?.flagged;

      return (
        // <div style={screenSize > 822 ? { marginLeft: "-15px" } : {}}>
        <div className={`${css.question_card} border-0`}>
          <div className={(description || content) ? css.body_question_description : css.body_question}>
            <Question
              section_order={select}
              qNumber={qNumber + 1}
              question={question}
              Ids={questionIds}
              answers={answers}
              disabled={disabled}
              onAnswered={this.handleQuestionAnswered}
              onReview={this.handleReview}
              bgColor="#fff" //{this.state.module.type === 'pdf' ? '#fff' : '#000'}
              isFlaged={this.handleFlaged}
              flag={isFlag}
            />
          </div>
        </div>

        // </div>
      );
    } else return null;
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };




  onSelectSubmit = () => {
    this.setState({ moduleTest: true });
    const msg = "Would you like to submit the test?";

    this.setState({
      isConfirmationModalOpen: true,
      confirmationMessage: msg,
    });
    // 10 seconds in milliseconds
  };

  handleConfirmationSubmit = (allow) => {
    if (allow) {
      this.updateTraineeMilestones("Completed");
      this.setState({
        updateElapsedTimeOrNot: false,
        load: true,
      });
      this.handleSaveTest(3, () => {
        // Handle what you need to do after confirmation here
      });
    }

    this.setState({
      isConfirmationModalOpen: false,
      confirmationMessage: "",
      allowConfirmation: allow,
    });
  };

  // Trainee milestone call for Assessment
  updateTraineeMilestones = (status) => {
    const mileStone = {
      event_id: parseInt(this.state.assessmentId),
      event_subject: "Assessment",
      event_status: status,
      event_name: this.state.tests.title,
      event_date: new Date().toISOString(),
      ple_score: +(this.props.ctx_user.user.credits)
    };
    this.props.ctx_analytics.update("traineeMilestones")(mileStone);
  }

  handleCount = () => {
    const { testQuestions, questionSection } = this.state;
    const objTestQuestion = Object.values(testQuestions);
    const objQuestionSection = Object.values(questionSection);
    const delta = {
      unanswered: 0,
      flag: 0,
      unansweredAssignment: 0,
      answered: 0,
      maxQuestion: 0

    };
    objQuestionSection.forEach((m0) => {
      const points = util.toInt(m0.max_questions)
      if (m0.max_questions !== 0) {
        delta.maxQuestion = delta.maxQuestion + points;
      }
    });
    objTestQuestion.forEach((s0) => {
      if (s0.attempted === 1) {
        delta.answered = delta.answered + 1;
      }
      if (s0.type === 4 && s0.attempted === 0) {
        delta.unansweredAssignment = delta.unansweredAssignment + 1;
      }
      if (s0.flagged === 1) {
        delta.flag = delta.flag + 1;
      }
      if (s0.attempted === 0) {
        if (delta.answered >= delta.maxQuestion) {
          delta.unanswered = 0
        }
        else {
          delta.unanswered = delta.maxQuestion - this.state.totalQnsAttended;
        }
      }
    });
    return delta;
  };

  renderMedia = (description, content, format) => {
    const content_format = content ? format : ""
    let MediaPlayer;
    switch (content_format) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }
    return (
      <>
        <div className={css.section_media}>
          {content === null
            ? ""
            : format === "3"
              ? <img src={content} style={{ cursor: "pointer" }} onClick={() => this.handleImageClick(content)} />
              : <MediaPlayer
                className={css.media}
                url={content}
                pdfUrl={content}
                fromStudentAssessment
                style={format === 0 && { marginLeft: "-15px" }}
              />
          }
          <div className={css.section_detail}>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              className={css.section_description}
            />

          </div>
        </div>
      </>
    )
  }





  renderQuestionTest = () => {
    const {
      questionSection,
      selectedItem,
      testQuestions,
      testAnswers,
      questionIds
    } = this.state;
    const currQuestion = testQuestions[selectedItem]
    const currSection = questionSection.filter(section => section.section_order === currQuestion.section_order)
    const index = questionSection.findIndex((section) => {
      return section.questions.some((item) => item.id === selectedItem);
    });
    let sectionDescription = questionSection[index].description
    let sectionContent = questionSection[index].content
    let sectionContentFormat = questionSection[index].content_format

    let MediaPlayer;
    switch (sectionContentFormat) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }


    const select = questionSection.map((section) => {
      return section.section_order
    })
    if (questionSection[0].questions.length > 0) {
      return (
        <>
          {(sectionDescription !== "<p><br></p>" && sectionDescription) ||
            sectionContent ? (
            <>
              <StyledScrollbar md={5} className={css.section_details}>
                {this.renderMedia(
                  sectionDescription,
                  sectionContent,
                  sectionContentFormat
                )}
              </StyledScrollbar>
              <StyledScrollbar md={7} className={css.question_style}>
                {selectedItem &&
                  this.mkTestQuestionOverlay(
                    sectionDescription,
                    sectionContent,
                    testQuestions
                  )}
              </StyledScrollbar>
            </>
          ) : (
            <StyledScrollbar md={12} className={css.section_details_full}>
              {selectedItem &&
                this.mkTestQuestionOverlay(
                  sectionDescription,
                  sectionContent,
                  testQuestions
                )}
            </StyledScrollbar>
          )}
        </>
      );
    }
    if (this.state.selectedItem !== null || questionSection[0].questions.length < 0) {
      return this.renderLoadingSpinner();
    }
  };
  renderLoadingSpinner = () => (
    <div className={`loading-indicator-wrapper ${css.center}`}>
      <div className="loading-indicator">
        <SpinIcon width={35} />
      </div>
    </div>
  );


  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  };

  handleClear = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      // assessmentId: null,
      testQuestions: {},
      testAnswers: {},
    });
  };

  handleExit = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      assessmentId: null,
      testQuestions: {},
      testAnswers: {},
      loading: true,
    });
  };

  renderAnswerFooter = () => {

    const { index, selectedItem, questionIds } = this.state;
    const Disabled = true;
    const selectedIndex = questionIds?.indexOf(selectedItem);
    const length = questionIds?.length
    const tip = "Previous"
    const tip1 = "Next"
    return (
      index !== 0 && !this.state.testClosed && (
        <Col md={12} lg={12} sm={12} xl={12}>

          <div className={css.pagination_footer}>
            <StyledButton>
              <OverlayTooltip
                tip={tip}
                placement="top"

              >
                <Button
                  iconOnly
                  onClick={() => this.handleArrowAction("left")}
                  size="sm"
                  className={css.previous}
                  disabled={selectedIndex === 0}
                >
                  <IconNextNew className={css.iconrotate} fill="white" height={18} width={14} />
                </Button>
              </OverlayTooltip>
              <OverlayTooltip
                tip={tip1}
                placement="top"
              >
                <Button
                  size="sm"
                  className={css.next}
                  onClick={() => this.handleArrowAction("right")}
                  disabled={selectedIndex + 1 === length}
                >
                  <IconNextNew fill="white" height={18} width={15} />
                </Button>
              </OverlayTooltip>
            </StyledButton>
          </div>
        </Col>

      )
    );
    // else return null

  };

  msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  startTestTimer = () => {
    const binkStyle = ["blink", this.state.alertTime ? "alert" : ""].join(" ");

    const createTestCheckPoints = (duration, interval) => {
      let checkpoints = [];

      checkpoints.push({
        time: 60000,
        callback: () => this.onTimerCheckpoint(duration / 4, 0),
        // callback: () => this.setState({alertTime : true})
      });

      checkpoints.push({
        time: 0,
        callback: () => this.onTimerCheckpoint(0, 1),
      });
      checkpoints.push(
        ...new Array(Math.floor(duration / interval))
          .fill(undefined)
          .map((_, i) => {
            return {
              time: i * interval,
              callback: () => this.updateElapsedTime(interval),
            };
          })
      );

      return checkpoints;
    };

    const { status, duration } = this.state.tests;
    return (
      <Timer
        initialTime={duration}
        // initialTime= {90000}    
        direction="backward"
        // checkpoints={createTestCheckPoints(90000 , 30000)}
        checkpoints={createTestCheckPoints(duration, 30000)}
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      >
        {(start, resume, pause, stop, reset) => (
          <React.Fragment>
            <span class={binkStyle}>
              <Timer.Hours />:
              <Timer.Minutes />:
              <Timer.Seconds />
            </span>
          </React.Fragment>
        )}
      </Timer>
    )
    // : (
    //   <span class="glyphicon glyphicon-time">
    //     &nbsp;{this.msToTime(duration)}
    //   </span>
    // );
  };

  renderModalBody = (content) => {
    this.setState({ modalOpen: true, img: content })
  }

  handleTabChange = (selectedTabKey) => {
    // Update the selected tab index in the state
    this.setState({ selectedTabIndex: selectedTabKey }, () => {
      const selectedSection = this.state.questionSection.find(
        (section, sectionIndex) => {
          return section.section_order - 1 === this.state.selectedTabIndex;
        }
      );

      if (selectedSection) {
        // Assuming that each section has at least one question
        const selectedItemId = selectedSection.questions.find((item) => {
          return item.order === 1;
        });

        if (selectedItemId) {
          // Update the selected item in the state
          this.setState({ selectedItem: selectedItemId.id });

          // Focus on the selected tab using the ref
          if (this.tabsRef.current) {
            const tabElement = this.tabsRef.current.querySelector(
              `[data-tab-key="${this.state.selectedTabIndex}"]`
            );

            if (tabElement) {
              tabElement.focus();
            }
          }
        }
      }
    });
  };

  renderSectionName = () => {
    const { questionSection, selectedItem } = this.state;
    const index = questionSection.findIndex((section) => {
      return section.questions.some((item) => item.id === selectedItem);
    });

    const itemDataLoop = questionSection.map((section, sectionIndex) => {
      return {
        title: section.section_name,
        content: (
          <>
            <SectionNumber
              rand={this.state.rand_question}
              // qpool={this.state.question_pool}
              question={section.questions.length !== 0 && section.questions}
              selectedItemId={selectedItem}
              questionIds={this.state.questionIds}
              selected={(selectedItemId) => {
                this.handleSelectedItem(selectedItemId, section.questions);
              }}
            />
          </>
        ),
      };
    });

    const handleTabChange = (selectedTabKey) => {
      if (this.state.rand_question === 0) {
        const selectedSection = questionSection.find((section, sectionIndex) => {
          return section.section_order - 1 === selectedTabKey;
        });

        if (selectedSection) {
          // Assuming that each section has at least one question
          const selectedItemId = selectedSection.questions.find((item) => {
            return item.order === 1;
          });

          if (selectedItemId) {
            this.setState({ selectedItem: selectedItemId.id });
          }
          if (this.tabsRef.current) {
            if (index) {
              index.focus();
            }
          }
        }
      }
      else {
        const selectedItemId = questionSection[selectedTabKey].questions[0].id
        this.setState({ selectedItem: selectedItemId });
      }

    };

    return (
      <ResponsiveTabsAssessment
        ref={this.tabsRef}
        tight
        items={itemDataLoop}
        showMore={false}
        selectedTabKey={index}
        onChange={handleTabChange}
        fromStudentAssessment
        showTails={true}
      />
    );
  };







  render = () => {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: config.ROUTES.assessmentsLanding,
        data: { filterdata: this.props.location.assessmentData.filterdata },
        assessmentData: this.props.location.assessmentData.subjectData,
        fromChapterInner: this.props.location.assessmentData.fromChapterInner,
      }} />;
    }
    const question = {
      title: "Section A - Question 4",
      question: "What’s the one thing that doesn’t belong to a to-do list?",
      options: [
        { text: "The movie you want to watch", value: "1" },
        { text: "A detailed list of all tasks for the day", value: "2" },
      ],
    };


    const screenSize = window.screen.width

    return (

      <div onKeyDown={this.handleKeyDown} tabIndex="0">
        {this.state.selectedItem &&
          <AssessmentTest
            findTotalAttended={this.findTotalAttended}
            startTestTimer={this.startTestTimer}
            onAttempted={this.handleOnAttempted}
            handleConfirmationSubmit={this.handleConfirmationSubmit}
            questionSection={this.state.questionSection}
            selectedItem={this.state.selectedItem}
            testQuestions={this.state.testQuestions}
            testClosed={this.state.testClosed}
            testCloseds={this.testClosed}
            onReview={this.handleReview}
            questionIds={this.state.questionIds}
            testAnswers={this.state.testAnswers}
            onAnswered={this.handleQuestionAnswered}
            tests={this.state.tests}
            isConfirmationModalOpen={this.state.isConfirmationModalOpen}
            index={this.state.index}
            handleArrowAction={this.handleArrowAction}
            selected={this.handleSelectedItem}
            isFlaged={this.handleFlaged}
            rand_question={this.state.rand_question}
            onSave={this.state.onSave}
            onExit={this.state.onExit}
            load={this.state.load}
            onSelectSave={this.onSelectSave}
            onSelectExit={this.onSelectExit}
            onSelectSubmit={this.onSelectSubmit}
            duration={this.state.duration}
            confirmationMessage={this.state.confirmationMessage}
            isFullScreen={this.state.isFullScreen}
            fromChapterInner
            isAssessment
            isIphone
            toggleAssignment={this.toggleAssignment}
            qpool={this.state.question_pool}
          />

        }
      </div >
    );
  };
}
export default withWindowContext(
  withLabelsContext(withApiContext(withRouter(withUserContext(withAnalyticsContext(StudentAssessments)))))
);
