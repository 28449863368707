import React, { Component } from 'react';
import { withLabelsContext, withApiContext, withWindowContext, withUserContext } from 'context';
import { Col, Row } from "react-bootstrap";
import EntityForm from "../Entity/Form";
import "./TestGeneration.css";
import { Textarea } from "components";
import CategorySelect from "components/Select/CategorySelect";
import CurriculumSelect from "components/CurriculumSelect/CurriculumSelect";
import * as config from "config";
import FormCheckbox from 'pages/Login/FormCheckbox';
import * as messages from "messages";
class TestGeneration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fields = this.mkFields(props);
  }

  mkFields = (props) => {
    const fields = {
      AssessmentName: { init: "", label: this.props.ctx_labels.singular.AssessmentName, optional: false },
      sub: { init: "", label: this.props.ctx_labels.singular.subject, optional: false },
      std: { init: "", label: this.props.ctx_labels.singular.standard, optional: false },
      topic: { init: "", label: this.props.ctx_labels.singular.ntrtopic, optional: false },
      mcqNoqs: { init: "0", label: "", optional: true },
      shortNoqs: { init: "0", label: "", optional: true },
      fillNoqs: { init: "0", label: "", optional: true },
      mcqMrk: { init: "0", label: "" },
      shortMrk: { init: "0", label: "" },
      fillMrk: { init: "0", label: "" },
      checkbx1: { init: false, label: "" },
      checkbx2: { init: false, label: "" },
      checkbx3: { init: false, label: "" },
      totmrk: { init: 0, label: this.props.ctx_labels.singular.totlMrk, optional: true },
      sectionName: { init: "AI Test", label: "", optional: true },
      negCredit: { init: "0", label: "", optional: true },
    };
    return fields;
  };

  insertAiTest = (s) => {
    const subjectName = this.props.ctx_user.user.campusSubj.filter((sub) => {
      if (s.sub === sub.value) {
        return sub.label
      }
    })
    const stdName = this.props.ctx_user.user.campusCategories.filter((std) => {
      if (s.std === std.value) {
        return std.label
      }
    })
    const goiId = this.props.ctx_user.user.goiId;
    this.props.ctx_api.privateRequestRow({
      cmd: 'insert_ai_generated_test_details',
      args: {
        assessmentName: s.AssessmentName,
        subjectId: s.sub,
        subjectName: subjectName[0].label,
        standardId: s.std,
        standardName: stdName[0].label,
        totalMark: s.totmrk,
        goiId:goiId,
        topicName: s.topic,
        mcqNoqs: s.mcqNoqs,
        shortNoqs: s.shortNoqs,
        fillNoqs: s.fillNoqs,
        mcqMrk: s.mcqMrk,
        shortMrk: s.shortMrk,
        fillMrk: s.fillMrk,
        sectionName: s.sectionName,
        negCredit: s.negCredit
      },
    }).then((rsp) => this.handleSaveTestresponse(rsp))
      .catch(this.handleSaveSectionFailure);
    this.props.modalClose()
    this.props.ctx_window.alert(messages.mkAiTestGeneration());
  }
  
  handleSaveTestresponse = ({ rsp }) => {
    console.log(rsp);
    // if (rsp.StatusValue === 0) {
    //   this.props.ctx_window.alert(rsp.StatusText);
    // }else if (rsp.StatusValue === 1) {
    // }else if (rsp.StatusValue === 2) {
    //   this.props.ctx_window.alert(messages.mkTestExistError());
    // }else {
    //   this.props.ctx_window.alert(messages.mkFailureErrorAlert());
    // }
  }
 
  handleSaveSectionFailure = (err) => {
    console.log(err);
    // this.props.ctx_window.alert(messages.mkFailureErrorAlert());
  };

  handleActionDone = (reason) => {
    if (reason === 'canceled') {
      this.props.modalClose()
    }
  };

  renderFields = ({ ValidatedField, onChange, s }) => {
    const rendermark = ({ name, value }) => {
      const updater = () => {
        const deltaS = { [name]: value };

        const parsedmcqMrk = +s.mcqMrk * +s.mcqNoqs;
        const parsedshortMrk = +s.shortMrk * +s.shortNoqs;
        const parsedfillMrk = +s.fillMrk * +s.fillNoqs;
        if (value != '') {
          switch (name) {
            case 'mcqMrk':
              deltaS.totmrk = (+value * s.mcqNoqs) + parsedshortMrk + parsedfillMrk;
              break;
            case 'shortMrk':
              deltaS.totmrk = parsedmcqMrk + (+value * s.shortNoqs) + parsedfillMrk;
              break;
            case 'fillMrk':
              deltaS.totmrk = parsedmcqMrk + parsedshortMrk + (+value * s.fillNoqs);
              break;
            case 'mcqNoqs':
              deltaS.totmrk = (+value * s.mcqMrk) + parsedshortMrk + parsedfillMrk;
              break;
            case 'shortNoqs':
              deltaS.totmrk = parsedmcqMrk + (+value * s.shortMrk) + parsedfillMrk;
              break;
            case 'fillNoqs':
              deltaS.totmrk = parsedmcqMrk + parsedshortMrk + (+value * s.fillMrk);
              break;
            default:
              break;
          }
        }
        return deltaS;
      };
      onChange({ name, updater });
    };
    const handleFieldChng = ({ name, value }) => {
      const updater = () => {
        const deltaS = { [name]: value };
        return deltaS;
      };
      onChange({ name, updater });
    };
    return (
      <Row>
        <Col md={6}>
          <ValidatedField name="AssessmentName" onChange={handleFieldChng} />
          <ValidatedField name="sub" type={CurriculumSelect} onChange={handleFieldChng} testGeneration />
          <ValidatedField name="std" type={CategorySelect} onChange={handleFieldChng} testGenerate />
          <ValidatedField name="totmrk"
            className="totlmrk-style left-side-totmrk" readOnly />
        </Col>
        <Col md={6}>
          <ValidatedField name="topic" type={Textarea} rows="2" maxLength={config.maxDescriptionLength} onChange={handleFieldChng} />
          <span>
            {this.props.ctx_labels.singular.questionType}
          </span>

          <Row>
            <Col className='qtype-span'>
              <ValidatedField
                name="checkbx1"
                type={FormCheckbox}
                className="checkbxstyle"
                title={this.props.ctx_labels.singular.multipleChoice}
                onChange={handleFieldChng}
              />
              <ValidatedField
                name="checkbx2"
                type={FormCheckbox}
                className="checkbxstyle"
                title={this.props.ctx_labels.singular.srtessay}
                onChange={handleFieldChng}
              />
              <ValidatedField
                name="checkbx3"
                type={FormCheckbox}
                className="checkbxstyle"
                title={this.props.ctx_labels.singular.fillInTheBlanks}
                onChange={handleFieldChng}
              />

            </Col>
            <Col className='no-of-qs-col'>
            <span>
            {this.props.ctx_labels.singular.noofqs}
          </span>
              <ValidatedField name="mcqNoqs" placeholder={`${this.props.ctx_labels.singular.noofqs}`} disabled={!s.checkbx1} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
              <ValidatedField name="shortNoqs" placeholder={`${this.props.ctx_labels.singular.noofqs}`} disabled={!s.checkbx2} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
              <ValidatedField name="fillNoqs" placeholder={`${this.props.ctx_labels.singular.noofqs}`} disabled={!s.checkbx3} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
            </Col>
            <Col className='no-of-qs-col'>
            <span>
            {this.props.ctx_labels.plural.mark}
          </span>
              <ValidatedField name="mcqMrk" placeholder={`${this.props.ctx_labels.singular.mark}`} disabled={!s.checkbx1} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
              <ValidatedField name="shortMrk" placeholder={`${this.props.ctx_labels.singular.mark}`} disabled={!s.checkbx2} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
              <ValidatedField name="fillMrk" placeholder={`${this.props.ctx_labels.singular.mark}`} disabled={!s.checkbx3} onChange={rendermark}
                type="number"
                min="0.00"
                max="999" />
              <ValidatedField name="totmrk"
                className="totlmrk-style right-side-totmrk" readOnly />
            </Col>
          </Row>
          {/* <Row className='btn-row'>
            <Col className='extra-col'></Col>
            <Col><Button className="clsebtn" onClick={this.props.modalClose}><span><IconCircleCancelRed/>{this.props.ctx_labels.singular.cancel}</span></Button></Col>
            <Col><Button variant="primary" className="genbtn"><span><IconGenerate/>{this.props.ctx_labels.singular.generate}</span></Button></Col>
          </Row> */}
        </Col>
      </Row>
    );
  }

  render = () => {
    return (
      <EntityForm
        {...this.props}
        fields={this.fields}
        submit={this.insertAiTest}
        fieldsRenderer={this.renderFields}
        isTestGeneration
        hideBreadcrumb={true}
        customHeader=" "
        onActionDone={this.handleActionDone}
      />
    );
  };
}

export default withUserContext(withApiContext(withLabelsContext(withWindowContext(TestGeneration))));
