import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactPlayer from "react-player";
import labels from "./labels";
import "./Video.css";
import Captions from "./Video/Captions";
import {Button,Select} from "components"
import Overlay from "./Video/Overlay";
import PlayPause from "./Video/PlayPause";
import Seek from "./Video/Seek";
import Time from "components/Time/Time";
import Volume from "./Video/Volume";
import { withUserContext } from "context";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import { Glyphicon , DropdownButton , Dropdown } from 'react-bootstrap';
import { vimeoPlayerConfig } from './PlayerConfig';
import {QualitySelect , IconPlayBackSpeed, TickIcon} from "Icons";
import audioPlaceholder from '../../assets/images/audio-placeholder.gif';
import pausedImage from '../../assets/images/paused-image.png';

const defaultControlsSpec = ["PlayPause", "Seek", "Time", "Volume", "Captions","QualitySelect","PlaybackSpeedButton"];

class Video extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxMaxPos: props.maxPos,
      sectionDone: false,

      pip: false,
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      selectedVideoQuality:"auto",
      qualitySelect: 0,
      // isVimeo:false,
      videoConfig:vimeoPlayerConfig.vimeo,
      videoPlayerQuality:[],
      overlay:false

    };
    this.pausePending = false;
    this.ref = this.ref.bind(this);
  }

  static propTypes = {
    module: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    lastViewedPos: PropTypes.number.isRequired,
    maxViewedPos: PropTypes.number.isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    reviewing: PropTypes.bool.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
    controlsSpec: PropTypes.arrayOf(PropTypes.oneOf(defaultControlsSpec)),
    video: PropTypes.object.isRequired,
  };

  static defaultProps = {
    controlsSpec: defaultControlsSpec,
    hidden: false,
    video: {},
  };

  componentDidMount() {
    this.seekToTimeout = setTimeout(this.seek, 1000) //Timeout to prevent player seek to error on mount
    if (0 < this.props.ctx_user.user.playerPollingInterval * 1000) {
      this.initiateModulePolling();
    }
    if(this.props.module.streamType == 'video' && this.props.module.contentSource == 'mediaConvert'){
      let videoPlayerQuality = [{label: 'auto', value: 'auto'},...this.props.module.videoAvailabeResolutions.map((item)=> {return ({label: item, value: item})})]
      const defaultVideoQuality = Object.values(videoPlayerQuality).map((item)=> item.value).includes(540) ? 540 : 'auto';
     
      let selectedVideoQuality = localStorage.getItem('selectedVideoQuality') 
      ? Object.values(videoPlayerQuality).map((item)=> item.value).includes(localStorage.getItem('selectedVideoQuality')) 
      ? localStorage.getItem('selectedVideoQuality')
      : this.getNearestRes(videoPlayerQuality)
      : defaultVideoQuality
      let qualitySelect = 0
      Object.values(videoPlayerQuality).forEach((item,i)=> {
        if(item.value == selectedVideoQuality){
          qualitySelect = i
        }
      })
      this.setState({videoPlayerQuality, selectedVideoQuality, qualitySelect})
    }
  }
 
  getNearestRes = (qualitys) =>{
    let nerarestRes = 0;
    Object.values(qualitys).forEach((item) => {
      if(item.value <= localStorage.getItem('selectedVideoQuality')){
        nerarestRes = item.value
      }
    })
    if(nerarestRes == 0){
      nerarestRes = 'auto'
    }
    return nerarestRes
  }

  componentWillReceiveProps = (nextProps, nextState) => {
    if (nextProps.module.url !== this.props.module.url) {
      this.setState({ maxMaxPos: nextProps.maxPos , playedSeconds: 0});
      this.pausePending = false
    }

    if (
      (this.props.maxPos <= Math.ceil(this.state.playedSeconds) &&
      this.state.playing && 
      !this.pausePending) || (this.props.maxPos <= Math.ceil(this.state.playedSeconds) && this.state.played === 1 && !this.pausePending)
    ) {
      this.pausePending = true;
      nextProps.onAllViewed(true);
      // } else if (nextProps.video.paused && !this.props.video.paused) {
      // } else if (!nextState.playing && this.state.playing) {
      //   this.pausePending = false;
    }

    if (this.props.maxPos < nextProps.maxPos) {
      this.setState((s0) => ({
        maxMaxPos: Math.max(s0.maxMaxPos, nextProps.maxPos),
      }));
    }

    if (
      nextProps.lastViewedPos !== this.props.lastViewedPos ||
      nextProps.maxPos !== this.props.maxPos
    ) {
      const sectionDone = nextProps.lastViewedPos === nextProps.maxPos;
      const f = sectionDone ? this.pauseAt : this.resumeAt;
      this.setState({ sectionDone }, () => {
        // f(nextProps.lastViewedPos);
         nextProps.lastViewedPos !== 0 ? f(nextProps.lastViewedPos) : setTimeout(this.setState({playing: true}),1000);
      });
    }

    if (nextProps.hidden && !this.props.hidden) {
      this.onPause();
    } else if (!nextProps.hidden && this.props.hidden) {
      if (nextProps.minPos < nextProps.maxPos) {
        this.onPlay();
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.sectionDone) {
      this.setState({ playing: false });
    }
    if (this.props.module.url !== prevProps.module.url) {
      let videoPlayerQuality = [];
      if(this.props.module.streamType == 'video' && this.props.module.contentSource == 'mediaConvert'){
      videoPlayerQuality = [{label: 'auto', value: 'auto'},...this.props.module.videoAvailabeResolutions.map((item)=> {return ({label: item, value: item})})];
      }
      this.setState({
        videoPlayerQuality,
        played: 0,
        loaded: 0,
        pip: false,
      });
    } else if (
      (this.props.maxPos <= Math.ceil(this.state.playedSeconds) &&
      this.state.playing && 
      !this.pausePending) || (this.props.maxPos <= Math.ceil(this.state.playedSeconds) && this.state.played === 1 && !this.pausePending)
    ) {
      this.pausePending = true;
      this.props.onAllViewed(true);
    } else if (!this.state.playing && prevState.playing) {
      this.pausePending = false;
    }
    if (prevState.playing === true && this.state.playing === false) {
      clearInterval(this.moduleTimePolling);
    } else if (prevState.playing === false && this.state.playing === true) {
      this.initiateModulePolling();
    }
    else if(this.props.overlay !== null){
      this.setState({overlay:true})
    }
    else if (this.props.overlay === null){
      this.setState({overlay:false})
    }
    if(this.props.playerState === "ready" && this.state.playbackRate !==  1)
    {
      this.setState({playbackRate:1.0})
    }
  };

  componentWillUnmount = () => {
    if (this.props.message === null) {
      // this will stop unwanted module updation
      this.reportModuleProgress();
    }
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
    clearInterval(this.moduleTimePolling);
    clearTimeout(this.seekToTimeout)
  };

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: Math.ceil(this.state.playedSeconds) || 0,
        maxViewedPos: this.getMaxViewedPos() || 0,
      });
    }
  };

  initiateModulePolling = () => {
    this.moduleTimePolling = setInterval(
      this.reportModuleProgress,
      this.props.ctx_user.user.playerPollingInterval * 1000
    ); //polling to update module time
  };

  reportModuleEnd = () => {
    return null;
  };

  seek = () => {
    this.player.seekTo(this.props.lastViewedPos ? this.props.lastViewedPos : 0);
  };

  onReady = () => {
    this.errorVisible = false;
  };
  onDuration = (duration) => {
    this.setState({ duration });
    if (this.props.onReady) {
      this.errorVisible = false;
      this.props.onReady(
        duration,
        null // TODO: find a way to find out the aspect ratio of video
      );
    }
  };
  playPause = () => {
    this.setState({ playing: !this.state.playing });
    if (this.props.onPause) {
      this.props.onPause(this.state.playing);
    }
  };
  stop = () => {
    this.setState({ url: null, playing: false });
  };
  pauseAt = (val) => {
    this.setState((s0) => {
      return {
        played: val / s0.duration,
        playing: false,
      };
    }, this.player.seekTo(val / this.state.duration));
  };
  resumeAt = (val) => {
    console.log(this.player); //to check the seekTO issue;
    this.player ? this.setState((s0) => {
      return {
        played: val / s0.duration,
        playing: true,
      };
    }, 
    this.player ? this.player.seekTo(val / this.state.duration) : this.props.onError()) 
    : this.props.onError();
  };
  setVolume = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };
  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };
  setPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };
  onPlay = () => {
    this.setState({ playing: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  togglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };
  onEnablePIP = () => {
    this.setState({ pip: true });
  };
  onDisablePIP = () => {
    this.setState({ pip: false });
  };
  onSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };
  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) / 100 });
  };
  onSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value) / 100);
  };
  onProgress = (state) => {
    if (!this.state.seeking) {
      // We only want to update time slider if we are not currently seeking
      this.setState(state);
    }
  };

  onError = (e) => {
    console.log(`${e.target.error.message}---/---${e}`)
    let message =
      e.target && e.target.error ? e.target.error.message : "Invalid video URL";
    if (this.props.onError) {
      this.props.onError({ message });
    }
    this.errorVisible = true;
  };
  onClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player))
  };
  ref = (player) => {
    this.player = player;
  };

  stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onQualityToggle = (pane) => {
    this.setState((s0) => {
      this.seekToTimeout = setTimeout(()=>{this.player.seekTo(Math.ceil(s0.playedSeconds))}, 100);
      localStorage.setItem('selectedVideoQuality', pane);
      return { selectedVideoQuality: pane};
    });
    let config = vimeoPlayerConfig.vimeo;
    config.playerOptions["quality"] = pane;
    this.setState({ videoConfig: config });
  };

  getMaxViewedPos = () =>
    Math.max(this.props.maxViewedPos, Math.ceil(this.state.playedSeconds));

  renderSeek = (key, hidden) => {
    const { seekable } = this.props;
    const duration = this.props.module.contentLength; 
    const minSeekPos = this.props.minPos;
    const maxViewedPos = this.getMaxViewedPos();
    const maxSeekPos = Math.min(maxViewedPos, this.props.maxPos);
    const maxMaxSeekPos = Math.min(maxViewedPos, this.state.maxMaxPos);
    const playedPerc = this.state.played > 1 ? 0 : this.state.played * 100;

    const asPercent = (time) => (duration ? (100 * time) / duration : 0);
    return (
      <Seek
        currTimePercent={playedPerc}
        minSeekPercent={seekable ? 0 : asPercent(minSeekPos)}
        maxSeekPercent={seekable ? 100 : asPercent(maxSeekPos)}
        maxMaxSeekPercent={seekable ? 100 : asPercent(maxMaxSeekPos)}
        highlightSeekable={this.props.reviewing}
        onMouseDown={this.onSeekMouseDown}
        onChange={this.onSeekChange}
        onMouseUp={this.onSeekMouseUp}
        hidden={hidden}
        ariaLabel={labels.seek}
        key={key}
      />
    );
  };

  renderPlayPause = (key, hidden) => (
    <PlayPause
      onClick={this.playPause}
      hidden={hidden}
      ariaLabelPlay={labels.play}
      ariaLabelPause={labels.pause}
      key={key}
      paused={!this.state.playing}
      {...this.props.video}
    />
  );

  renderTime = (key, hidden) =>
    isNaN(this.state.duration) ? null : (
      <Time
        hidden={hidden}
        key={key}
        currentTime={this.props.module.contentLength * this.state.played}
        duration={this.props.module.contentLength}
        // currentTime={this.state.duration * this.state.played}
        // duration={this.state.duration}
        {...this.props.video}
      />
    );

    renderVolume = (key, hidden) => 
    isNaN(this.state.volume) ? null : ( 
      <>    
      <div className="volume-speed-button"> 
        
        
      <Volume   
        onClick={this.toggleMuted}  
        onChange={this.setVolume}   
        hidden={hidden} 
        volume={this.state.volume}  
        muted={this.state.muted}    
        ariaLabelMute={labels.mute} 
        ariaLabelUnmute={labels.unmute} 
        key={key}   
        {...this.props.video}   
      />    
      </div>    
      </>   
    );


  renderCaptions = (key) =>
    !this.props.video.textTracks ||
    this.props.video.textTracks.length === 0 ? null : (
      <Captions
        onClick={this.props.onCaptionsClick}
        ariaLabel={labels.captions}
        onItemClick={this.props.onCaptionsItemClick}
        key={key}
        {...this.props.video}
      />
    );

   renderQualitySelect = (key, hidden) => {    
    const currentQuality =  
      this.props.module.streamType == "vimeo"   
        ? vimeoPlayerConfig.qualityItems    
        : this.state.videoPlayerQuality;    
    if (    
      this.props.module.streamType == "vimeo" ||    
      (this.props.module.streamType == "video" &&   
        this.props.module.contentSource == "mediaConvert")  
    ) { 
      if (!hidden) {
        return (    
          <Dropdown 
            key={"up"}  
            drop={"up"} 
            // title={<QualitySelect className="video-qualityIC"/>} 
            bsStyle="player-control"    
            onSelect={this.onQualityToggle} 
          > 
            <div style={{paddingTop: "10px"}}>  
            <Dropdown.Toggle variant="Secondary" id="dropdown-basic" style={{ color: "white" }}>    
             <QualitySelect style={{ width: "24px", height: "24px" }} />    
            </Dropdown.Toggle>  
            </div>  
            <Dropdown.Menu> 
              {currentQuality.map((item, i) => {    
                const style = { 
                  visibility:   
                    this.state.qualitySelect === i ? "visible" : "hidden",  
                };  
                return (    
                  <Dropdown.Item    
                    key={i} 
                    eventKey={item.value}   
                    onClick={() => {    
                      this.setState({ qualitySelect: i });  
                    }}  
                  > 
                    <span style={style}>    
                      <TickIcon 
                        style={{ width: "24px", height: "24px", fill: "black" }}    
                      />    
                    </span> 
                    <span>{item.label}</span>   
                  </Dropdown.Item>  
                );  
              })}   
            </Dropdown.Menu>    
          </Dropdown>   
        );  
      } 
    }   
  };


  handleTypeChange = (value) => {
    // console.log(value)
    this.setState({ playbackRate: value });
  };

  renderPlayBackSpeedButton = (key, hidden) => {
    const {playbackRate} = this.state
    // console.log(this.state.playbackRate)
    let options = [
      { value: 0.5, label: "0.5x" },
      { value: 1.0, label: "1.0x" },
      { value: 1.25, label: "1.25x" },
      { value: 1.5, label: "1.5x" },
      { value: 2.0, label: "2.0x" },
    ];
    if (this.props.module.testQuestions.pos !== this.state.maxMaxPos ) {
    return (
      <Dropdown
        key={"up"}
        drop={"up"}
        bsStyle="player-control"
        // onSelect={this.onQualityToggle}
      >
        <div style={{paddingTop: "10px"}}>
       { this.state.playing === true && <Dropdown.Toggle variant="Secondary" id="dropdown-basic" style={{color: "white"}}>
         {this.state.playbackRate === 1 ? <IconPlayBackSpeed style={{ width: "24px", height: "24px" }}/> : `${this.state.playbackRate} x` }
        </Dropdown.Toggle>}
        </div>
        <Dropdown.Menu>
          {options.map((item, i) => {
            const style = {
              visibility: playbackRate === item.value ? "visible" : "hidden",
            };
            return (
              <Dropdown.Item
                key={i}
                eventKey={item.value}
                onClick={() => {
                  this.setState({ playbackRate: item.value });
                }}
              >
                <span style={style}>
                  <TickIcon
                    style={{ width: "24px", height: "24px", fill: "black" }}
                  />
                </span>
                <span>{item.label}</span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
        
      </Dropdown>
      )
        };
  };

  renderControls = (hidden) =>  
  this.props.controlsSpec.map((name, i) =>  
    name === "Seek" ? ( 
      this.renderSeek(i, hidden)    
    ) : name === "PlayPause" ? (    
      this.renderPlayPause(i, hidden)   
    ) : name === "Time" ? ( 
      this.renderTime(i, hidden)    
    ) : name === "Volume" ? (   
      this.renderVolume(i, hidden)  
    ) : name === "Captions" ? ( 
      this.renderCaptions(i, hidden)    
    ) : name === "PlaybackSpeedButton" ? ( // Add this line to render the PlaybackSpeedButton   
    ((this.props.seekable === true && 
      this.state.overlay === false && this.props.module.testQuestions.pos !== this.state.maxMaxPos ) ?
      this.renderPlayBackSpeedButton(i, hidden):null)
    ) : name === "QualitySelect" ? (    
      this.renderQualitySelect(i, hidden)
    )
      : null    
  );

  
  getBackgroundStyles = () => {
    const { module } = this.props;
    const { screenSize, active } = this.state;
  
    const isAudioContent = module.type === "audio";
    const isVideoContent = module.type === "video";
  
    const audioStyle = {
      backgroundImage: `url(${this.state.playing ? audioPlaceholder : pausedImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100%',
    };
  
    const videoStyle = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      backgroundColor: this.state.playing ? '#000' : '#fff', // black ('#000') when video is playing and white ('#fff') when video is paused or stopped
    };
  
    if (screenSize > 821 && !this.props.isShowSidebar) {
      videoStyle.height = '73vh';
      videoStyle.animationPlayState = active && this.state.playing ? 'running' : 'paused';
    }
  
    return isAudioContent ? audioStyle : isVideoContent ? videoStyle : {};
  };

  render = () => {
    const {
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      playbackRate,
      pip,
    } = this.state;

    const {
      module,
      // lastViewedPos,
      minPos,
      maxPos,
      video,
      overlay,
      message,
      className,
      isFullscreen,
      baseControls,
      // onPlayPauseClick,
      // ...restProps
    } = this.props;
    
    const active = this.props.fromTrainingZone
      ? module.url
        ? true
        : false
      : minPos < maxPos;

    const screenSize = window.screen.width;
    let style = {};

    if (!(screenSize === 768 || screenSize === 820)) {
      style = this.getBackgroundStyles();
    }
   
    return (
      <div
        className={[
          // "video",
          isFullscreen ? "video-fullscreen" : "",
          active ? "video-active" : null,
          className,
        ].join(" ")}
        style={style}
      >
        <div className="player-wrapper">
        {/* <video
            className="video-video"
            autoPlay={active}
            playsInline
            {...restProps}
          >
            <source src={src} type={type} />
          </video> */}

<ReactPlayer    
            ref={this.ref}  
            className="react-player"    
            width="100%"    
            height="100%"   
            url={   
              module.contentSource === "mediaConvert" &&    
              module.type == "video" && 
              module.availableVideoLinks    
                ? module.availableVideoLinks[this.state.selectedVideoQuality]   
                : module.url    
            }   
            // url={module.url} 
            pip={pip}   
            playing={playing}   
            controls={controls} 
            light={light}   
            loop={loop} 
            playbackRate={this.state.playbackRate}  
            volume={volume} 
            muted={muted}   
            onReady={this.onReady}  
            // onStart={() => console.log('onStart')}   
            onPlay={this.onPlay}    
            onPause={this.onPause}  
            onEnablePIP={this.onEnablePIP}  
            onDisablePIP={this.onDisablePIP}    
            // onBuffer={() => console.log('onBuffer')} 
            // onSeek={e => console.log('onSeek', e)}   
            onEnded={this.reportModuleEnd}  
            onError={this.onError}  
            onProgress={this.onProgress}    
            onDuration={this.onDuration}    
            config={this.props.ctx_user.user.videoPlayerConfig} 
            // config={this.state.videoConfig}  
          />    
          {!active && <div className="player-blank" />} 
          {overlay && <div className="player-overlay">{overlay.elt}</div>}  
          {message && <div className="player-overlay">{message}</div>}  
          {active && !module.video360 && (  
            <Overlay    
              error={video.error}   
              paused={video.paused} 
              loading={video.loading}   
              onClick={this.playPause}  
            />  
          )}    
          {/* </div> */}    
          <div className="video-controls-hover-region"> 
            {active && (    
              <div  
                className="video-controls-top-padding"  
                onClick={this.playPause}    
              />    
            )}  
            <div id ="hover_to_view" className="player-controls video-controls">    
              {/* <div className={`${cmCss.toolbar} bg-color2 text-white`}> */} 
              {this.renderControls(!active || video.error)} 
              <div className={cmCss.toolbar_right} >  
                {baseControls}  
              </div>
          </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withUserContext(Video);