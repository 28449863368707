 import { Button, OverlayTooltip, PageTitle } from "components";
import React, { Component } from "react";
import { MainContainer } from "pages/Main";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import css from "pages/Assessments/StudentAssessments/student-assessments.module.scss"
import { withRouter } from "react-router-dom";

import {
  withApiContext,
  withLabelsContext,
  withWindowContext,
  withUserContext,
  withAnalyticsContext,
} from "context";
import Timer from "react-compound-timerv2/build";
import Question from "pages/Assessments/InternalItems/Question";
import Loader from "components/Spinners/Loader";
import * as config from "config";

import { Redirect } from "react-router";

import Media from "pages/Components/Meet/config/media";
import MediaQuery from "react-responsive";
import * as util from "services/util";
import styled from "styled-components";
import ResponsiveTabsAssessment from "components/ResponsiveTabs/ResponsiveTabAssessment";
import AssessmentTest from "pages/Test/Components/AssessmentTest"


const StyledScrollbarDiv = styled.div`
  /* Your scrollbar styles here */
  scrollbar-color: rgb(10, 119, 227) #7bbbe0;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color2};
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color2};
  }
    
  /* Customize scrollbar track when dragging */
  &::-webkit-scrollbar-thumb:active {
    background-color: #333;
  }
`;




class CreateTestPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirect: false,
      onClickBackButton: false,
      tests: null,
      questionSection: [],
      questionAnswer: [],
      testQuestions: {},
      testAnswers: {},
      selectedItem: null,
      assessmentId: null,
      index: 0,
      prevIndex: 0,
      direction: "prev",
      startBtn: null,
      btnActive: false,
      allSaved: true,
      updateElapsedTimeOrNot: true,
      alertTime: false,
      duration: 0,
      action: 0,
      totalQnsAttended: 0,
      testState: 1,
      testClosed: false,
      load: false,
      COLORS: [
        "#0e4f69",
        "#FFBB28",
        "#00C49F",
        "#08a822",
        "#00A49F",
        "#FFDB48",
        "#FG8012",
      ],
      status: 0,
      summaryData: null,
      summaryError: null,
      testAssessmentId: null,
      loading: true,
      pdfUrl: "",
      modalOpen: false,
      img: "",
      answers: [],
      isLoaded: false,
      fromPreview : true,
    };
    this.initialTime = new Date();
    this.initiallyAttended = 0;
  }

  initSectionItem = () => ({
    section_order: 0,
    section_name: "",
    questions: [],
  });

  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  componentDidMount = () => {

    const questionSection = this.props.preview && this.props.preview;
    const questionAnswer = questionSection.flatMap((q) =>
      q.questions.flatMap((a) => {
        return a.answer !== null && a.answer;
      })
    );
  this.setState({ questionSection, questionAnswer, isLoaded: true });
  this.handleTestAnswers(questionSection);
    if (this.props.location.assessmentData != undefined) {
    
      this.summaryInterval = setInterval(
        this.handleSummary,
        parseInt(this.props.ctx_user.user.analytics.studyMapTriggerInterval)
      );
    }
  };

componentDidUpdate = (prevProps, prevState) => {
   if(!this.props.preview ==  prevProps.preview) {
    this.setState({questionSection: []})
   }
 
}

  handleSummary = (prevModule = null) => {
    var current = new Date();
    const summaryData = {
      event_id: parseInt(
        this.props.location.assessmentData.data.detailsData.assessmentId
      ),
      event_name: this.props.location.assessmentData.data.detailsData.name,
      eventactive_time: Math.round(
        (current.getTime() - this.initialTime.getTime()) / 1000
      ),
      eventstart_at: this.initialTime,
      eventend_at: new Date(),
      intent: "assessment",
      details: {
        publish_test_id: parseInt(
          this.state.tests?.lattestAssessmentId
            ? this.state.tests?.lattestAssessmentId
            : ""
        ),
        questions_attended: this.state.totalQnsAttended,
      },
    };
    this.initialTime = current;
    this.updateAnalytics(summaryData);
  };

  updateAnalytics = (r) => {
    this.props.ctx_analytics.update("player")({ ...r });
  };
 

  handleImageClick = (img) => {
    this.setState({ modalOpen: true, img });
  };



  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };

  handleUpdateElapsedTimeResponse = (rsp, interval) => {
    !interval &&
      this.setState({
        duration: rsp.duration,
        //  load: rsp.StatusValue === 1 ? false : true
      });
  };
  goToLanding = () => {
    this.setState({ redirect: true });
  };

  handleActionBtn = (timerStop) => {
    this.setState((s0) => ({ startBtn: !s0.startBtn }));
    if (timerStop) {
      this.setState({ testClosed: true, updateElapsedTimeOrNot: false });
      if (this.state.action) {
        this.onTimerCheckpoint(0, 1);
      }
    }
  };
  onTimerCheckpoint = (rem, completed) => {
    if (completed) {
      this.setState({ updateElapsedTimeOrNot: false });

      this.handleSaveTest(2, () => {
        // this.goToSummary(() => {});
      });
    } else {
      this.setState({ alertTime: true });
    }
  };

 

  onSelectItem = (selectedItemId) => () => {
    this.setState({
      selectedItem: selectedItemId,
    });
  };
 
  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
 
 
  handleStudentAssessmentFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
    this.setState({ loading: false });
  };

  handleStudentAssessmentResponse = (rsp, callback, flag) => {
    const action = flag === 1 ? "Saved" : "Submitted";

      this.setState({ load: rsp.StatusValue === 1 ? false : true });
      this.showSuccessPopupAndThen(action);
    
    callback && callback();
  };
  showSuccessPopupAndThen = (action) => {
    if (action == "Saved") {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
        })
      );
    } else {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
          redirect: true,
        })
      );
    }
  };
  dateToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var time =
      String(today.getHours()).padStart(2, "0") +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      String(today.getSeconds()).padStart(2, "0");

    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd + " " + time;
    return today;
  };
  onSelectFlag = () => {
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    let flag = testQuestions[selectedItem].flagged;
    testQuestions[selectedItem].flagged = flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = !flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  };

  
  findTotalAttended = (questionSectionWise, condition) => {
    if (condition == "started") {
      let total = 0;
      questionSectionWise.forEach((item, i) => {
        total = total + item.questions_attempted;
        return total;
      });
      this.initiallyAttended = total;
      this.setState({ totalQnsAttended: 0 });
    } else {
      let total = 0;
      questionSectionWise.forEach((item, i) => {
        total = total + item.questions_attempted;
        return total;
      });
      this.setState({ totalQnsAttended: total - this.initiallyAttended });
    }
  };
  handleTestAnswers = (questionSection, questionAnswer) => {
    const testQuestions = {};
    const testAnswers = {};
    let selectedItem = null;
    let questionIds = [];
    questionSection.forEach((se) => {
      const sortedQuestions = Object.values(se.questions).sort(
        (a, b) => a.sequence - b.sequence
      );
      const addOrderValue = sortedQuestions.map( (s,i) => {
        return {
          ...s,
          order:i,
        }
      })
      addOrderValue.forEach((qu) => {
        testQuestions[qu.id] = qu;
        questionIds.push(qu.id);
        if (!selectedItem) {
          selectedItem = qu.id;
        }
        testAnswers[qu.id] = {
          choices: [],
          answers: [],
          comments: [],
          reviewStarts: [],
          reviewEnds: [],
          questions: [],
        };
      });
    });
    questionAnswer && questionAnswer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if(q.type === typeE.FILL_IN_THE_BLANK){
            a.answers.push(r.answer);
        }
        if (
          (q.type === typeE.FILL_IN_THE_BLANK ||
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE) &&
            r.is_correct_ans
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    });
    this.setState({
      testQuestions,
      testAnswers,
      selectedItem,
      questionIds,
      loading: false,
    });
    
    this.props.fromCreateTest && this.props.direction(this.state.direction)
    // this.startTestTimer()
  };
  
  // andleClick = (answerImage) => {
  //   this.setState({ show: true, image: answerImage });
  // };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };





  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  

  fetchPreviewAnswers = (selectedItemId) => {
    if(selectedItemId > 0 ){
    this.props.ctx_api.privateRequest({
      cmd: "get_question_answers",
      args: {
        questionId: selectedItemId.toString(),
      }
    })
    .then(({rsp}) => this.handlePreviewAnswers(rsp,selectedItemId))
    .catch(this.handlePreviewAnswerError)
  }
  }

  handlePreviewAnswers = (rsp, selectedItemId) => {
    this.mkPreviewAnswers(rsp, selectedItemId)
    this.setState({isLoaded:false})
  }

  mkPreviewAnswers = (answer, selectedItemId) => {
    const { answers, testQuestions } = this.state;
    const testAnswers = {};
    testAnswers[selectedItemId] = {
      choices: [],
      answers: [],
      comments: [],
      reviewStarts: [],
      reviewEnds: [],
      questions: [],
    };
    answer && answer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if(q.type === typeE.FILL_IN_THE_BLANK){
            a.answers.push(r.answer);
        }
        if (
          ( q.type === typeE.FILL_IN_THE_BLANK  ||
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE) &&
            r.is_correct_ans
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    
    });
    
    this.setState((s0) => {
      const updatedTestAnswers = {
        ...s0.testAnswers,
        [selectedItemId]: testAnswers[selectedItemId]
      }
      return {
        testAnswers: updatedTestAnswers,
        selectedItem: selectedItemId,
        isLoaded: false
      }
    })
  }



  handlePreviewAnswerError = (err) => {
    console.log(err);
  }

 
  handleOnAttempted = (value) => {
    const { selectedItem, questionSection } = this.state;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].attempted = value ? 1 : 0;
    const qSection = testQuestions[selectedItem].section_order;

    let newQuestionSection = questionSection.map((section, i) => {
      if (section.section_order === qSection) {
        const currentAttempted = section.questions.filter(
          (quest) => quest.attempted === 1
        ).length;
        return {
          ...section,
          questions_attempted: currentAttempted,
        };
      } else return section;
    });
    this.setState({
      testQuestions: testQuestions,
      questionSection: newQuestionSection,
    });
    this.findTotalAttended(newQuestionSection, "updated");
  };


 

  render = () => {
    return (
      <>
     {this.state.selectedItem&& <AssessmentTest
      tests={this.state.tests}
      selectedItem={this.state.selectedItem}
      questionSection={this.state.questionSection}
      testQuestions={this.state.testQuestions}
      testAnswers={this.state.testAnswers}
      questionIds={this.state.questionIds}
      fetchPreviewAnswers={(selectedItemId) => {this.fetchPreviewAnswers(selectedItemId)}}
      nextId={this.state.nextId}
      isLoaded={this.state.isLoaded}
       onAttempted={this.handleOnAttempted}
      fromCreateTest
      fromContentBuilder={this.props.fromContentBuilder}
      />}</>
    );
  };
}
export default withWindowContext(
  withLabelsContext(
    withApiContext(
      withRouter(withUserContext(withAnalyticsContext(CreateTestPreview)))
    )
  )
);
