import * as config from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Form } from "react-bootstrap";
// import css from 
// import css from "../../../pages/StudentAssessments/Question/question.module.scss";


class Comment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }
  

  static propTypes = {
    question: PropTypes.string.isRequired,
    onAnswered: PropTypes.func.isRequired,
  };

  initState = {
    comment: "",
    submitted: false,
  };

  componentWillReceiveProps = (newProps) => {

    if (newProps.question !== this.props.question) {
      this.setState(this.initState);
    }
  };

  componentDidMount = () => {
    this.props.comments && this.setState({comment:this.props.comments [0]})
    this.props.response && this.props.comment && this.setState({comment:this.props.comment})
  }

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.submitted && !prevState.submitted) {
      this.props.onAnswered({ correct: [this.state.comment] });
    }
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
    // if(this.props.survey){
      const updatedSurveyDetails = {
        ...this.props.surveyDetails,
        questions: this.props.surveyDetails.questions.map(sec => {
          if (sec.question_order === this.props.questionOrder) {
            return {
              ...sec,
              user_selection: 
                { selected_id: 0, comment: e.target.value}
              
            };
          }
          return sec;
        })
      };
      // this.props.survey && this.props.onAnswered([e.target.value]);
      this.props.onAnswered(updatedSurveyDetails);
    // }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
  };

  render = () => (
    <div>
      <div className="test-question">{this.props.questionOrder}. {this.props.question}</div>
      {this.props.questionImage && (
        <div className={this.props.isPlayerTest ? "player-image" :"test-question-image" }>
          <img
            src={this.props.questionImage}
            alt=""
          />
        </div>
      )}
      {/* {this.props.from !== "preview" && */}
        <form id="test-answer-form" >
        <Form.Group style={{marginBottom:"4px"}}>
          <Form.Control
            as="textarea"
            rows="7"
            value={this.state.comment}
            maxLength={config.maxTestCommentLength}
            placeholder={config.testCommentPlaceholder}
            disabled={this.state.submitted}
            onChange={this.handleChange}
            readOnly={this.props.from === "preview"}
            // form="test-answer-form"
            // autoFocus
          />
        </Form.Group>
        <div className="test-comment-box-footer">
          <span className="footer-note">
            {`(maximum of ${config.maxTestCommentLength} characters)`}
          </span>
          {/* {!this.state.submitted && !this.props.survey &&(
            <Button
              bsStyle="primary"
              disabled={this.state.comment.length === 0}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          )} */}
        </div>
        {this.state.submitted && (
          <div className="test-comment-response">
            Thank you for your comment.
          </div>
        )}
      </form>
      {/* }  */}
    </div>
  );
}

export default Comment;
