import classNames from "classnames";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";
import css from "./radio-button.module.scss";

// theme.
const StyledRadio = styled.label`
  > input {
    + span {
      &::before {
        border-color: ${({ theme }) => theme.primary};
      }
      &::after {
        background-color: ${({ theme }) => theme.primary};
      }
    }
  }
  &:hover {
    > input {
      + span {
        &::before {
          background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
        }
      }
    }
  }
`;

export default function RadioButton({ children, className, ...props }) {
const correct = {
  '--correct':props.summaryDetails && props.colour[0].correct
}
const incorrect = {
  '--correct':props.summaryDetails && props.colour[0].incorrect
}
const unattempted = {
  '--correct':props.summaryDetails && props.colour[0].unattempted
}
const colors =
  props.attempted === 1 && props.iscorrect === 1
    ?props.summaryDetails &&  correct
    : props.attempted === null  && props.iscorrect === 1
    ? props.summaryDetails && unattempted
    : props.attempted === 1 && props.iscorrect === 0
    ?props.summaryDetails && incorrect
    : props.attempted === 0 && props.iscorrect === 1
    ? props.summaryDetails && correct
    : props.summaryDetails && unattempted;

  return (
    props.summaryDetails ? 
    <div  className={classNames(css.radioLabel)} style = {props.summaryDetails && colors}>
    <input type="radio" {...props} />
      <span></span>
      {children}
      </div> :
    <StyledRadio className={classNames(css.label, className ?? "")}>
      <input type="radio" {...props} />
      <span></span>
      {children}
    </StyledRadio>
  );
 
}
