import React from 'react';

import { VolumeOff as VolumeOffIcon } from '../icons/VolumeOff';
import { VolumeUp  as VolumeUpIcon  } from '../icons/VolumeUp';

import './Volume.css';


export default ({ onChange, onClick, volume, muted, hidden, className, ariaLabelMute, ariaLabelUnmute }) => {
    const volumeValue = muted
        ? 0
        : +volume;
    const isSilent = muted || volume <= 0;
    return (
        <div
          className={[
            'video-volume',
            className
          ].join(' ')}
          style={hidden ? { visibility: 'hidden' } : null}
        >
            <button
                aria-label={isSilent
                    ? ariaLabelUnmute
                    : ariaLabelMute}
                className="video-volume-button"
                onClick={onClick}
                type="button"
                disabled={hidden}
            >
                { isSilent
                    ? <VolumeOffIcon
                        className="video-volume-icon"
                        height={34}
                        fill="#fff" />
                    : <VolumeUpIcon
                        className="video-volume-icon"
                        height={34}
                        fill="#fff"/> }
            </button>
            <div className="video-volume-slider">
                <div className="video-volume-track">
                    <div
                        className="video-volume-fill"
                        style={{
                            height: `${volumeValue * 100}%`
                        }} />
                    <input
                        min="0"
                        step="any"
                        max="1"
                        type="range"
                        orient="vertical"
                        disabled={hidden}
                        onChange={onChange}
                        className="video-volume-input"
                        value={volumeValue} />
                </div>
            </div>
        </div>
    );
};
