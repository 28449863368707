import React, { Component } from "react";
import {
  withWindowContext,
  withApiContext,
  withLabelsContext,
  withUserContext,
} from "context";
import { DashboardPanel } from "components";
import { Card } from "react-bootstrap";
//import  "./ShortAnswer.css";
import MediaQuery from 'react-responsive';
import styled from "styled-components";



const questionStyle = {
  display: "inline-block",
  marginLeft: "14px",
  color: "#3DAEDA",
  fontWeight: "700",
  fontSize: "14px",
  fontFamily: "Poppins"
};

const questionDataStyle = {
  display: "inline-block",
  marginLeft: "15px",
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "400",
  color: "#000000"
};

const answerDataStyle = {
  display: "inline-block",
  marginLeft: "65px",
  color: "black",
  fontSize: "14px",
  paddingTop: "30px",
};

const ResponseStyle = {
  display: "inline-block",
  marginLeft: "62px",
  color: "#D9E6EE",
  marginTop: "2px",
  marginBottom: "20px",
  fontSize: "15px",
};

const StyledWrappers = styled.span`
  color: ${({ theme }) => theme.primary};
 
  }
`;
const StyledWrapper = styled.h5`
  color: ${({ theme }) => theme.primary};
 
  }
`;

class ShortQuestionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { surveyData } = this.props;
    const currentQuestionIndex = 0;

    //const currentQuestion = surveyData[currentQuestionIndex];
    const currentQuestion = surveyData
    const currentQuestionId = currentQuestion ? currentQuestion.survey_question_id : null;
    const currentQuestionAnswers = currentQuestion ? currentQuestion.answers : [];

    return (
      <>

        <MediaQuery maxWidth={575}>
          <Card style={{ marginLeft: "-25px", marginTop: "0px", height: "auto", width: "370px", border: "none" }}>
            <div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h5
                  style={{
                    ...questionStyle,
                    marginLeft: "5px",
                    marginBottom: "8px",
                    marginTop: "0px",
                    paddingLeft: "10px",
                    border: "none",
                    flex: "0 0 auto",
                  }}
                >
                  <StyledWrapper>
                    QN{" "}{currentQuestionId}
                  </StyledWrapper>
                </h5>
                <h5
                  style={{
                    ...questionDataStyle,
                    marginLeft: "15px",
                    flex: "1",
                  }}
                >
                  {surveyData && surveyData.survey_question_name}
                </h5>
              </div>
              
              <h5>
                <div
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    paddingLeft: "50px",
                    paddingTop: "20px"
                  }}
                >
                  {surveyData &&
                    surveyData.answers.map((answer2, answer2Index) => (
                      <div
                        key={answer2Index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          padding: "20px",
                          border: "0.3px solid #c7cccc",
                          margin: "5px",
                          paddingRight: "10px",
                          borderRadius: "5px",
                          marginLeft: "-35px"
                        }}
                      >
                        <div style={{ marginRight: "40px" }}>
                          <h5 style={{ ...questionDataStyle, paddingLeft: "0px", marginLeft: "0px",width: "70px" }}>{answer2.student_name}</h5>
                        </div>
                        <div style={{ ...questionDataStyle, textAlign: "left" }}>{answer2.survey_short_answer}</div>
                      </div>

                    ))}
                </div>
              </h5>
            </div>
          </Card>
        </MediaQuery>



        <MediaQuery minWidth={576}>
          <React.Fragment>
            <div>
              <h5 style={{ display: "flex", alignItems: "flex-start",width:"88%" }}>
                <span style={{ ...questionStyle, marginRight: "5px", marginTop: "15px", flex: "0 0 auto" }}>
                  <StyledWrappers>
                    QN{" "}{currentQuestionId}
                  </StyledWrappers>
                </span>
                <span style={{ ...questionDataStyle, marginTop: "15px" }}>
                  {surveyData && surveyData.survey_question_name}
                </span>
              </h5>
              
              <h5>
                <div
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    paddingLeft: "50px",
                    marginTop: "20px"
                  }}
                >
                  {surveyData &&
                    surveyData.answers.map((answer2, answer2Index) => (
                      <div
                        key={answer2Index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "15px",
                          padding: "20px",
                          border: "0.3px solid #c7cccc",
                          margin: "5px",
                          paddingRight: "10px",
                          borderRadius: "5px",
                          marginLeft: "-35px"
                        }}
                      >
                        <div style={{ marginRight: "40px",  }}>
                          <div style={{ ...questionDataStyle, paddingRight: "0px", marginLeft: "0px",width: "100px" }}>{answer2.student_name}</div>
                        </div>
                        <div style={{ ...questionDataStyle, textAlign: "left" }}>{answer2.survey_short_answer}</div>
                      </div>

                    ))}
                </div>
              </h5>
            </div>
          </React.Fragment>
          {/* </Card> */}
        </MediaQuery>
      </>
    );
  };
}

export default withLabelsContext(
  withApiContext(withUserContext(ShortQuestionnaire))
);
