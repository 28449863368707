import { backgroundImages } from "polished";
import React, { useState } from "react";
import styled from "styled-components";

const Wrap = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  background-color: transparent;
  width: 16px;
  z-index: 100;
  transition: all 0.3s;
  cursor: pointer;
  .trapezium {
    
    fill: ${({ theme }) => theme.color3};
    width: 18px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

function SlideBack({ handleSidebarToggle }) {
  const [open, setOpen] = useState(true);

  const styles = {
    openStyle: {
      transform: open ? "rotate(180deg)" : "rotate(360deg)",
      position: "absolute",
      marginTop: "16px",
      fill: "white",
      width:"32px",
    },
  };
  const divStyle ={
    openStyle:{
      marginLeft: open ? "221px":"0px"
    }
  }
 

  const handleClick = () => {
    if (open) {
      setOpen(false);
      //console.log('false');
      return false;
    } else {
      setOpen(true);
      //console.log('true');
      return true;
    }
  };
  return (
    <Wrap style={divStyle.openStyle} onClick={() => handleSidebarToggle(handleClick())}>
      <svg
        className="trapezium" 
        xmlns="http://www.w3.org/2000/svg"
        //  width="105"
        //  height="418"
        viewBox="0 0 105 418"
        fill="none"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M100 153.152C100 135.622 94.2425 118.578 83.6128 104.64L5.38548 2.06187C3.64459 -0.220922 0 1.01022 0 3.88108V404.119C0 406.99 3.64459 408.221 5.38548 405.938L83.6128 303.36C94.2425 289.422 100 272.378 100 254.848V153.152Z"
        />
      </svg>
      
      <svg
        style={styles.openStyle}
        xmlns="http://www.w3.org/2000/svg"
        id="Outline"
        viewBox="0 0 24 24"
      >
        <path d="M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z" />
      </svg>
    </Wrap>
  );
}

export default SlideBack;
