import {OverlayTooltip} from 'components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as util from 'services/util';




class ThreadTimestamp extends PureComponent {
  static propTypes = {
    timestamp: PropTypes.instanceOf(Date).isRequired,
    now:       PropTypes.instanceOf(Date).isRequired,
    id:        PropTypes.oneOfType([
                 PropTypes.number,
                 PropTypes.string,
               ]).isRequired,
  };

  mkTimestampString = () => {
    const { timestamp, now } = this.props;
    if (util.sameDates(timestamp, now)) {
      let amPm = 'am';
      let h = timestamp.getHours();
      if (11 < h) {
        h = h - 12;
        amPm = 'pm';
      }
      if (h === 0) {
        h = 12;
      }
      const m = timestamp.getMinutes();
      return `${h}:${m < 10 ? '0' : ''}${m}${amPm}`;
    }
    const sameYear = timestamp.getYear() === now.getYear();
    return timestamp.toLocaleString(
      'en-us',
      {
        month: sameYear ? 'short' : 'numeric',
        day:   'numeric',
        year:  sameYear ? undefined : '2-digit',
      },
    );
  }

  mkTipString = () => this.props.timestamp.toLocaleString(
    'en-GB',
    {
      weekday: 'short',
      day:     'numeric',
      month:   'short',
      // year:    'numeric',
      hour:    'numeric',
      minute:  'numeric',
      // second:  'numeric',
    },
  );

  render = () => (
    <OverlayTooltip
      tip={this.mkTipString()}
      id={`timestamp-${this.props.id}`}
      placement="right"
    >
      <span>
        {this.mkTimestampString()}
      </span>
    </OverlayTooltip>
  );
}


export default ThreadTimestamp
