// import * as config from "config";
import PropTypes from "prop-types";
import React from "react";
import "./ItemMiniCard.css";
import classNames from "classnames";
import css from "../StudentAssessments/Section/section.module.scss";




const ItemMiniCard = ({
  item,
  sequence,
  selectable,
  selected,
  // deletable,
  reorderable,
  onSelect,
  // onDelete
}) => {
  if(!item){
    return null;
  }
  const selectedClass = [
    'card',
    'ques-mini-card',
    reorderable ? "reorderable" : "",
    selectable ? "selectable" : "",
    selected ? "ques-mini-selected" : ""
  ].join(' ');

  // const cardColor = config.testQuestionColors[item.type] ? 
  // config.testQuestionColors[item.type] : null;

  const cardColor = item.attempted === 1 ? '#ffbb29':item.attempted === 2 ? 'red': null ;

  // const questionType = config.testQuestionTypeNames[item.type]
  // ? config.testQuestionTypeNames[item.type]
  // : null;
  
  return (
    <div className={css.section} onClick={onSelect}>
      <div className={selectedClass} 
      //   style={{
      //   backgroundColor: cardColor
      // }}
      >
          { sequence &&
          <div className="card-sequence-number"
          style={{
            backgroundColor: cardColor
          }}
          >
            <span className="sequence-number-icon">{sequence}</span>
          </div>
          }
          { item.flagged === 1 &&
          <div className="ribbon card-answer-status">
          <span ></span>
          </div>
          }
      </div>
    </div>
  );
};

ItemMiniCard.propTypes = {
  item: PropTypes.object.isRequired,
  renderedOn: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  deletable: PropTypes.bool,
  reorderable: PropTypes.bool,
  sequence: PropTypes.number
};

ItemMiniCard.defaultProps = {
  renderedOn: 'form', // 'form' or 'modal'
  deletable: false,
  selectable: false,
  selected: false,
  reorderable: false,
};

export default ItemMiniCard;
