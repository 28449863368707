import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DashboardParent from "pages/DashboardParent/DashboardParent";
import { withApiContext, withUserContext, withLabelsContext } from "context";
import { Breadcrumb } from "components";
import * as config from "config";
import MainContainer from "pages/Main/MainContainer";
import styled from "styled-components";
import * as util from "services/util";

function StudentDashboard(props) {
  const location = useLocation();
  //const member_id = location.state?.memberId;
  const member_id = location.userId || location.data.student_id;
  const [dependantLeaderboardData, setDependantLeaderboardData] = useState([]);
  const [dependantIntentData, setDependantIntentData] = useState([]);
  const [dependantCourseData, setDependantCourseData] = useState([]);
  const [dependantAssessmentData, setDependantAssessmentData] = useState([]);
  const [dependantSessionData, setDependantSessionData] = useState([]);

  const StyledMainContainer = styled(MainContainer)`
    padding-bottom: 0;
  `;

  const getParentLeaderboardData = async () => {
    try {
      const args = {
        memberId: member_id,
      };
      const response = await props.ctx_api.privateRequest({
        cmd: "get_trainee_studytime",
        args,
      });
      setDependantLeaderboardData(response.rsp);
    } catch (error) {
      console.error("Error fetching parent leaderboard data:", error);
    }
  };

  const getIntentData = async () => {
    try {
      const args = {
        memberId: member_id,
      };
      const response = await props.ctx_api.privateRequest({
        cmd: "get_intent_count",
        args,
      });
      setDependantIntentData(response.rsp);
    } catch (error) {
      console.error("Error fetching intent data:", error);
    }
  };

  const getParentDashboardData = () => {
    getParentCourse();
    getParentAssessment();
    getParentSession();
  };

  const getParentCourse = async () => {
    try {
      const args = {
        acadYearId: props.ctx_user.user.acadYearId,
        dependantId: member_id,
      };
      const response = await props.ctx_api.privateRequest({
        cmd: "get_trainee_course_progress_dtl",
        args,
      });
      setDependantCourseData(response.rsp);
    } catch (error) {
      console.error("Error fetching parent course data:", error);
    }
  };

  const getParentAssessment = async () => {
    try {
      const args = {
        acadYearId: props.ctx_user.user.acadYearId,
        dependantId: member_id,
      };
      const response = await props.ctx_api.privateRequest({
        cmd: "get_trainee_assessment_dtl",
        args,
      });
      setDependantAssessmentData(response.rsp);
    } catch (error) {
      console.error("Error fetching parent assessment data:", error);
    }
  };

  const getParentSession = async () => {
    try {
      const args = {
        acadYearId: props.ctx_user.user.acadYearId,
        dependantId: member_id,
      };
      const response = await props.ctx_api.privateRequest({
        cmd: "get_trainee_live_session_dtl",
        args,
      });
      setDependantSessionData(response.rsp);
    } catch (error) {
      console.error("Error fetching parent session data:", error);
    }
  };

  useEffect(() => {
    console.log(location);
    getParentLeaderboardData();
    getIntentData();
    getParentDashboardData();
  }, [member_id]);

  return (
    <>
      <StyledMainContainer>
        <Breadcrumb
          list={[
            {
              title: util.capitalizeWord(props.ctx_labels.singular.user),
              current: true, 
            },
            {
              title: props.ctx_labels.singular.student,
              link: config.ROUTES.trainees,
              state: {
                adminHome: props.adminHome
            },
            },
            { title: props.ctx_labels.singular.studentProgress, 
              current: (props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) 
            }, 
          ]}
        />

      </StyledMainContainer>
      <DashboardParent
        studentDetail
        memberId={member_id}
        userData={location.data.name || location.name}
        dependantLeaderboardData={dependantLeaderboardData}
        dependantIntentData={dependantIntentData}
        dependantCourseData={dependantCourseData}
        dependantAssessmentData={dependantAssessmentData}
        dependantSessionData={dependantSessionData}
        getParentLeaderboardData={getParentLeaderboardData}
        getIntentData={getIntentData}
        getParentDashboardData={getParentDashboardData}
        studentDashboard
        addRedirection={location.addRedirection}
        adminHome= {props.adminHome}
        // ... other props ...
      />
    </>
  );
}

export default withApiContext(
  withUserContext(withLabelsContext(StudentDashboard))
);
