import React, { PureComponent } from "react";
import ReactPlayer from "./index";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
// import Overlay from "./Overlay";
import PropTypes from "prop-types";
import './LoomPlayer.css';
// import pausedImage from '../../assets/images/paused-image.png';

const MATCH_URL_LOOM = /loom\.com\/(share|embed)\/([a-zA-Z0-9_-]+)(\?[^#]*)?/;

const fetchOEmbedData = async (url) => {
  const oEmbedUrl = `https://www.loom.com/v1/oembed?url=${encodeURIComponent(url)}`;

  try {
    const response = await fetch(oEmbedUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch oEmbed data: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching oEmbed data:", error);
    return null;
  }
};
const defaultControlsSpec = ["PlayPause", "Seek", "Time", "Volume", "Captions","QualitySelect","PlaybackSpeedButton"];

class LoomPlayer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            url: this.props.url || this.props.module.url,
            pip: this.props.pip || false,
            playing: this.props.playing || true,
            controls: this.props.controls || false,
            light: this.props.light || false,
            volume: this.props.volume || 0.8,
            muted: this.props.muted || false,
            played: this.props.played || 0,
            loaded: this.props.loaded || 0,
            duration: this.props.duration || 0,
            playbackRate: this.props.playbackRate || 1.0,
            loop: this.props.loop || false,
            oEmbedData: null,  // New state for oEmbedData
            overlay:false,
            sectionDone: false,
            ovrly:false,
        }
        this.pausePending = false;
    }

    static propTypes = {
      module: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      lastViewedPos: PropTypes.number.isRequired,
      maxViewedPos: PropTypes.number.isRequired,
      minPos: PropTypes.number.isRequired,
      maxPos: PropTypes.number.isRequired,
      reviewing: PropTypes.bool.isRequired,
      onModuleProgress: PropTypes.func,
      onAllViewed: PropTypes.func.isRequired,
      onUnmounted: PropTypes.func,
      overlay: PropTypes.shape({
        elt: PropTypes.element,
      }),
      message: PropTypes.element,
      isFullscreen: PropTypes.bool.isRequired,
      hidden: PropTypes.bool.isRequired,
      baseControls: PropTypes.element.isRequired,
      controlsSpec: PropTypes.arrayOf(PropTypes.oneOf(defaultControlsSpec)),
      video: PropTypes.object.isRequired,
    };

    componentWillReceiveProps = (nextProps, nextState) => {

      if ((nextProps.module?.url || nextProps.module?.fileUrl) !== this.props.module?.url) {
        
        this.setState({ maxMaxPos: nextProps.maxPos , playedSeconds: 0});
        this.setState({url : this.props.module.fileUrl});
        this.pausePending = false
      }

      if(nextProps.module?.url || this.props.module?.url){
        this.loadOEmbedData(nextProps.module?.url);
        // this.setState({maxMaxPos: nextProps.maxPos});
        // this.pausePending= false;
        this.setState({url: nextProps.module.url})
      }
  
      if (
        (this.props.maxPos <= Math.ceil(this.state.playedSeconds) &&
        this.state.playing && 
        !this.pausePending) || (this.props.maxPos <= Math.ceil(this.state.playedSeconds) && this.state.played === 1 && !this.pausePending)
      ) {
        this.pausePending = true;
        nextProps.onAllViewed(true);
      }
  
      if (this.props.maxPos < nextProps.maxPos) {
        this.setState((s0) => ({
          maxMaxPos: Math.max(s0.maxMaxPos, nextProps.maxPos),
        }));
      }
      
    if (
      nextProps.lastViewedPos !== this.props.lastViewedPos ||
      nextProps.maxPos !== this.props.maxPos
    ) {
      const sectionDone = nextProps.lastViewedPos === nextProps.maxPos;
      const f = sectionDone ? this.pauseAt : this.resumeAt;
      this.setState({ sectionDone }, () => {
        // f(nextProps.lastViewedPos);
         nextProps.lastViewedPos !== 0 ? f(nextProps.lastViewedPos) : setTimeout(this.setState({playing: true}),1000);
      });
    }
  
      if (nextProps.hidden && !this.props.hidden) {
        this.onPause();
      } else if (!nextProps.hidden && this.props.hidden) {
        if (nextProps.minPos < nextProps.maxPos) {
          this.onPlay();
        }
      }
    };
  

    componentDidUpdate = (prevProps, prevState) => {
      if (this.props?.baseControls?.props?.continueLabel === "RESUME MODULE"){
        this.setState({ playing: false })
      }

      if (Math.floor(this.props.maxPos) === Math.floor(this.state.played))
      {
        this.setState({ ovrly: true })
      }
      if (this.state.sectionDone) {
        this.setState({ playing: false });
      }
      if (this.props.module?.url !== prevProps.module?.url) {
        let videoPlayerQuality = [];
        if(this.props.module.streamType == 'video' && this.props.module.contentSource == 'mediaConvert'){
        videoPlayerQuality = [{label: 'auto', value: 'auto'},...this.props.module.videoAvailabeResolutions.map((item)=> {return ({label: item, value: item})})];
        }
        this.setState({
          videoPlayerQuality,
          played: 0,
          loaded: 0,
          pip: false,
        });
      } else if (
        (this.props.maxPos <= Math.ceil(this.state.playedSeconds) &&
        this.state.playing && 
        !this.pausePending) || (this.props.maxPos <= Math.ceil(this.state.playedSeconds) && this.state.played === 1 && !this.pausePending)
      ) {
        this.pausePending = true;
        this.props.onAllViewed(true);
      } else if (!this.state.playing && prevState.playing) {
        this.pausePending = false;
      }
      if (prevState.playing === true && this.state.playing === false) {
        clearInterval(this.moduleTimePolling);
      } else if (prevState.playing === false && this.state.playing === true) {
        // this.initiateModulePolling();
      }
      else if(this.props.overlay !== null){
        this.setState({overlay:true})
      }
      else if (this.props.overlay === null){
        this.setState({overlay:false})
      }
      if(this.props.playerState === "ready" && this.state.playbackRate !==  1)
      {
        this.setState({playbackRate:1.0})
      }
    };

    componentDidMount() {
      this.loadOEmbedData(this.state.url);
      this.seekToTimeout = setTimeout(this.seek, 1000) //Timeout to prevent player seek to error on mount
    }

    componentWillUnmount = () => {
      if (this.props.message === null) {
        // this will stop unwanted module updation
        this.reportModuleProgress();
      }
    };

    ref = (player) => {
      this.player = player;
    };

    initiateModulePolling = () => {
      this.moduleTimePolling = setInterval(
        this.reportModuleProgress,
        this.props.ctx_user?.user?.playerPollingInterval * 1000
      ); //polling to update module time
    };
  
    async loadOEmbedData(url) {
      if (MATCH_URL_LOOM.test(url)) {
        const oEmbedData = await fetchOEmbedData(url);
        if (oEmbedData) {
          this.setState({ oEmbedData }, () => {
            if (oEmbedData.duration && this.props.onDurationChange) {
              this.props.onDurationChange(oEmbedData.duration);  // Call the callback with the duration
            }
          });
        }
      }
    }

    pauseAt = (val) => {
      this.setState((s0) => {
        return {
          played: val / s0.duration,
          playing: false,
        };
      }, this.player?.seekTo(val / this.state.duration));
    };

    resumeAt = (val) => {
      this.player ? this.setState((s0) => {
        return {
          played: val / s0.duration,
          playing: true,
        };
      }, 
      this.player ? this.player.seekTo(val / this.state.duration) : this.props.onError()) 
      : this.props.onError();
    };

    handlePlayPause = () => {
      this.setState({ playing: !this.state.playing })
    }

    handleProgress = state => {
      // We only want to update time slider if we are not currently seeking
      // if (!this.state.seeking) {
        this.setState(state)
      // }
    }

    reportModuleProgress = () => {
      if (this.props.onModuleProgress) {
        this.props.onModuleProgress({
          lastViewedPos: Math.floor(this.state.duration * this.state.played),
          maxViewedPos: Math.max(this.props.maxViewedPos, Math.ceil(this.state.playedSeconds)) || 0
        });
      }
    };

    handleDuration = (duration) => {
      this.setState({ duration })
    }
    playPause = () => {
      this.setState({ playing: !this.state.playing });
      if (this.props.onPause) {
        this.props.onPause(this.state.playing);
      }
    };
    render () {
        const { url, playing, controls, light, volume, muted, loop, playbackRate, pip, sectionDone } = this.state; 
        const {
          module,
          // lastViewedPos,
          minPos,
          maxPos,
          video,
          overlay,
          message,
          className,
          isFullscreen,
          baseControls,
        } = this.props;
        
        const active = this.props.fromTrainingZone
          ? module.url
            ? true
            : false
          : minPos < maxPos;
    
        return(
          <div
          id="mgL"
          className={this.props.cp ? "crseprv-plyr" : "nrm-plyr"}
        >
          {!overlay && <div className="hideLoom" onClick={(e)=>{e.preventDefault()}}></div>}
          <ReactPlayer
            ref={this.ref}
            style={{background:'black'}}
            width='100%'
            height={this.props.cp ? '195px' : '370px'}
            url={url}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => console.log('onReady')}
            onStart={() => console.log('onStart')}
            onPlay={this.handlePlay}
            onEnablePIP={this.handleEnablePIP}
            onDisablePIP={this.handleDisablePIP}
            onPause={this.handlePause}
            onBuffer={() => console.log('onBuffer')}
            onPlaybackRateChange={this.handleOnPlaybackRateChange}
            onSeek={e => console.log('onSeek', e)}
            onEnded={this.handleEnded}
            onError={e => console.log('onError', e)}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
            onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
          />
          {/* {!active && <div className="player-blank" />}  */}
          {overlay && <div className="player-overlay">{overlay.elt}</div>}  
          {message && <div className="player-overlay">{message}</div>}  
          {sectionDone && <div className={cmCss.toolbar_right} >  
                {baseControls}  
              </div>}
        </div>
    );
    }
}

export default LoomPlayer;