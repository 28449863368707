import React from 'react';


export const WindowContext = React.createContext({
  confirm:       () => {},
  simpleConfirm: () => {},
  alert:         () => {},
});

export const FrameContext = React.createContext({
  setSideMenuSelectGate:   () => {},
  allowSideMenuSelect:     () => {},
  onSideMenuToggle:        () => {},
  onSideMenuTransitionEnd: () => {},
});

export const ApiContext = React.createContext({
  publicRequest:     () => {},
  privateRequest:    () => {},
  publicRequestRow:  () => {},
  privateRequestRow: () => {},
});

export const AccessContext = React.createContext({
  logIn:  () => {},
  logOut: () => {},
});

export const SiteContext = React.createContext({
  site:         null,
  fetch: () => {},
});

export const UserContext = React.createContext({
  user:         null,
  update: () => {},
});

export const SocketContext = React.createContext({
  socket: null
});

export const AnalyticsContext = React.createContext({
  update: () => {},
});

export const SessionContext = React.createContext({
  join:   () => {},
  leave:  () => {},
  hide:   () => {},
})

export const ThemeContext = React.createContext({
  theme:        {},
  update: () => {},
  refreshOrgTheme: () => {},
});

export const LabelsContext = React.createContext({
  singular: {},
  plural:   {},
  visible:  {}
});

export const AIBotContext = React.createContext({
  settings: {}
});

const mkWithContext = (Context, ctxPropName) => (Component) =>
  React.forwardRef((props, ref) => {
    const wrappedComponent = (ctx) => {
      const newProps = {
        ...props,
        [`ctx_${ctxPropName}`]: ctx,
        ref: ref,
      };
      return React.createElement(Component, newProps);
    };
    return (
      <Context.Consumer>
        {wrappedComponent}
      </Context.Consumer>
    );
  });

export const withWindowContext = mkWithContext(WindowContext, 'window');
export const withFrameContext  = mkWithContext(FrameContext,  'frame');
export const withApiContext    = mkWithContext(ApiContext,    'api');
export const withAccessContext = mkWithContext(AccessContext, 'access');
export const withSiteContext   = mkWithContext(SiteContext,   'site');
export const withUserContext   = mkWithContext(UserContext,   'user');
export const withThemeContext  = mkWithContext(ThemeContext,  'theme');
export const withLabelsContext = mkWithContext(LabelsContext, 'labels');
export const withAIBotContext = mkWithContext(AIBotContext, 'AIBot');
export const withAnalyticsContext = mkWithContext(AnalyticsContext, 'analytics');
export const withSessionContext = mkWithContext(SessionContext, 'session');
export const withSocketContext = mkWithContext(SocketContext, 'socket');
