import React from "react";
import PropTypes from "prop-types";
import css from "./spinner.module.scss";
import styled from "styled-components";

const StyledSpinner = styled.svg`
.${css.path}{
  stroke: ${({ theme }) => theme.primary};
}
`;

function Spinner(props) {
  return (
    <StyledSpinner
      className={css.spinner}
      width={props.width}
      height={props.height}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={css.path}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Spinner.defaultProps = {
  width: 45,
  height: 45,
};

export default Spinner;
