import React, { Component } from "react";
import {
  withWindowContext,
  withApiContext,
  withLabelsContext,
  withUserContext,
} from "context";
import { DashboardPanel } from "components";
import { Card } from "react-bootstrap";
import { IconDownload } from "Icons";
import {
  Button,
  OverlayTooltip,
  JustifyBetween,

} from "components";
import { CSVLink } from "react-csv";
import "./SurveyComment.css";
import MediaQuery from "react-responsive";


const questionStyle = {
  display: "inline-block",
  marginLeft: "15px",
  color: "#33A8F5",
};

const questionDataStyle = {
  display: "inline-block",
  marginLeft: "15px",
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "400",
  color: "#000000"
};

const answerDataStyle = {
  display: "inline-block",
  marginLeft: "65px",
  color: "black",
  fontSize: "14px",
  paddingTop: "30px",
};

const ResponseStyle = {
  display: "inline-block",
  marginLeft: "62px",
  color: "#D9E6EE",
  marginTop: "2px",
  marginBottom: "20px",
  fontSize: "15px",
};

class SurveyComment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { surveyData, surveyCommentss } = this.props;
    const { surveyComments } = this.props

    const currentQuestionIndex = 0;

    //const currentQuestion = surveyData[currentQuestionIndex];
    const currentQuestion = surveyData
    const currentQuestionId = currentQuestion ? currentQuestion.survey_question_id : null;
    const currentQuestionAnswers = currentQuestion ? currentQuestion.answers : [];

    const csvComment = surveyCommentss.survey_comment.map((comment) => {
      return (
        {studentName:comment.student_name, studentComment:comment.survey_comment} )
    })

    // const csvComment2 = csvComment.map((comment2) => {
    //   return {studentName2:comment2.student_name, studentComment2:comment2.survey_comment }
    // })



    const columns = [{ name: "Student Name", selector: row => row.name,key:"name"},
    { name: "Survey Comment", selector: row => row.survey_comment ,key:"survey_comment"}
    ]

    const getCSVheaders = (a) => {
      return columns.map((field) => {

        return {
          label: field.name.toUpperCase(),
          key: field.key,
        };
      });
    };

    const filteredData = surveyCommentss.survey_comment.map((item) => {
      
      return {
        name: item?.student_name || "", 
        survey_comment: item?.survey_comment || "" 
      };
    });
    

    return (

      <Card className="comment-card">
        <div className="comment-div">
          <OverlayTooltip
            tip="Download as CSV"
            id="download-as-csv"
            placement="top"
          >
            <CSVLink
              headers={getCSVheaders()}
              filename={"StudentResult.csv"}
              data={filteredData}
              // data={this.props.downloadData.filter((s) => {
              //   return surveyData.map(itm => itm.id).includes(s.id)
              // })}
            >
              <div className="comment-csv">
                <Button
                  variant="link"
                  className="p-0 mb-3 pt-0 mr-2"
                  style={{
                    // marginTop: "-10px",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  Download <IconDownload />
                </Button></div>

            </CSVLink>
          </OverlayTooltip>
        </div>
        <div>
          {/* <h5 style={{ display: "flex", alignItems: "center" }}>
            <span style={{ ...questionStyle, marginRight: "5px",marginTop: "15px"}}>QN{currentQuestionId}</span>
            <span style={{ ...questionDataStyle, fontSize: "16px",marginTop: "15px"}}>
              {surveyData && surveyData.survey_question_name}
            </span>
          </h5> */}
          {/* <h5>
            <span style={{ ...ResponseStyle, fontSize: "14px", marginTop: "10px",marginBottom: "5px"}}>
              {surveyData &&
                surveyData.answers[0] &&
                surveyData.answers[0].response_count}
              {" "}
              Responses
            </span>
          </h5> */}
          <h5>
            <div className="comment-inner"
              style={{
                maxHeight: "250px",
                overflowY: "auto",
                paddingLeft: "31px",
                paddingTop: "20px"

              }}
            >


              {
                surveyCommentss !== null
                  ? surveyCommentss.survey_comment.map((answer2, answer2Index) => {
                    if (answer2 !== null) {
                      return (
                        <div
                          key={answer2Index}
                          style={{
                            display: "block",
                            fontSize: "15px",
                            padding: "25px",
                            border: "1px solid #c7cccc",
                            //backgroundColor: "#F5FAFA",
                            margin: "5px",
                            paddingRight: "10px",
                            borderRadius: "5px",
                            marginLeft: "-28px",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "40px",
                              textAlign: "left",
                            }}
                          >
                            <MediaQuery minDeviceWidth={576}>
                            <div
                              style={{
                                ...questionDataStyle,
                                paddingRight: "10px",
                                margin: "0",
                                width: "15%"
                              }}
                            >
                              {answer2.student_name}
                            </div>
                            </MediaQuery>

                            <MediaQuery maxDeviceWidth={575}>
                            <div
                              style={{
                                ...questionDataStyle,
                                marginLeft: "50px",
                                paddingRight: "0px",
                                margin: "0",
                                width: "28%"
                              }}
                            >
                              {answer2.student_name}
                            </div>
                            </MediaQuery>

                            <div
                              style={{
                                ...questionDataStyle,
                                textAlign: "left",
                                marginLeft: "80px",
                                justifyContent: "space-between",
                                //alignItems: "right", 
                              }}
                            >
                              {answer2.survey_comment ? answer2.survey_comment : "No Comments"}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                  : null
              }




            </div>
          </h5>
        </div>
      </Card>
    );
  };
}

export default withLabelsContext(
  withApiContext(withUserContext(SurveyComment))
);
