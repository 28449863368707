import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button as BsButton } from "react-bootstrap";
import "./button.scss";

// Extend bootstrap button
export default function Button({ pill, wide, iconOnly, ...props }) {
	return (
		<BsButton
			{...props}
			className={classNames([
				"white" === props.variant && "btn-white text-primary",
				"outline" === props.variant && "btn-outline",
				"outline-border" === props.variant && "btn-outline-radius",
				"no-border" === props.variant && "btn-noborder",
				"rectangle-btn" === props.variant && "btn-rectangle ",
				"icon-only" === props.variant && "btn-icon-only ",
				"btn-outline-cancel" === props.variant && "btn-outline-cancel",
				pill && "btn--pill",
				wide && "btn--wide",
				iconOnly && "btn--icon",
				props.multiButton && "btn-multi border-primary-multi",
				props.previewButton && "btn-preview border-primary-multi",
				props.className,
				props.fromHome && "btn-home",
				props.fromHomeWhite && "btn-home-white",
			])}
		>
			{props.children}
		</BsButton>
	);
}

Button.propTypes = {
	pill: PropTypes.bool,
	wide: PropTypes.bool,
	iconOnly: PropTypes.bool,
};

Button.defaultProps = {
	pill: false,
	wide: false,
	iconOnly: false,
};
