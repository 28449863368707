export const startChatBot = () => {
  return new Promise((resolve, reject) => {
    const startBotInTimer = setInterval(() => {
      if (window.NLPbot) {
        // if (window.userBot) {
        //   stopChatBot();
        // }
        var bot = new window.NLPbot();

        window.userBot = bot;

        clearInterval(startBotInTimer);

        resolve(bot);
      }
    }, 100);
  });
};

export const stopChatBot = (bot) => {
  try {
  console.log("stopChatbot");
  window.serverUrl = ""; // Server URL
  window.apiUrl = ""; // Server URL
  window.url_extension = "";
  window.api_extension = "";
  window.train_publish = "";
  // window.user_type =
  window.bot_id = 0;
  window.account_id = 0;
  // return null;
  window.user_id = 0;
  window.user_type = 0;
  window.paramsObject = null;
  window.userBot.destroy();
  }
  catch(err) {
    console.log(err)
  }
};

export const initBot = (botSettings, botParams) => {
  return new Promise(function (resolve, reject) {
    window
      .loadBot(
        document,
        "script",
        "nlpbot" + botSettings.botId + "main",
        botSettings,
        botParams
      )
      .then(resolve)
      .catch((err) => console.log(err));
  });
};
