import React, { PureComponent } from 'react';
import { PlayArrow as PlayArrowIcon } from '../icons/PlayArrow';
import { Report as ReportIcon } from '../icons/Report';
import { Spin as SpinIcon } from '../icons/Spin';
import './Overlay.css';




export default class Overlay extends PureComponent {
  renderContent = () => {
    const iconProps = {
      className: 'video-overlay-icon',
      width:     40,
      fill:      '#fff',
    };
    const type =
      this.props.error   ? ReportIcon    :
      this.props.loading ? SpinIcon      :
      this.props.paused  ? PlayArrowIcon :
                           null;
    return !type ? null : (
      <span className="video-overlay-inner">
         {React.createElement(type, iconProps)}
      </span>);
  }

  render = () => {
    return (
      <div
        className={['video-overlay', this.props.className].join(' ')}
        onClick={this.props.onClick}
      >
        {this.renderContent()}
      </div>);
  }
}
