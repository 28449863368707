import React, { PureComponent } from 'react';
import { Modal, Badge } from "react-bootstrap";
import { Button, OverlayTooltip } from 'components';
import { withApiContext, withLabelsContext, withUserContext } from 'context';
import StarRatings from 'react-star-ratings'
import { IconStarFill } from 'Icons';
// import MediaQuery from 'react-responsive/dist/Component';


class Rate extends PureComponent {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      rating: 0
    };
  }

  changeRating = (value) => {
    this.setState({
      rating: value
    })
  }

  onRatingButtonClick = () => {
    this.setState({
      isModalOpen: true,
    });
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  insertRating = async (s) => {
    this.setState({isModalOpen: false})
    const { rsp } = await this.props.ctx_api.privateRequestRow({
      cmd: 'insert_update_course_rating',
      args: {
        coursePublishId: this.props.data.courses[0].course_publish_id,
        courseId: this.props.data.courses[0].course_id,
        courseRating: this.state.rating,
        memberId: this.props.ctx_user.user.id,
      },
    });
  }

  render() {
    const { isModalOpen, rating } = this.state;
    const { average_rating, number_of_users, name } = this.props.data.courses[0];
    const screenSize = window.innerWidth<768? false:true;
    return (
      <div>
        <div>
        <OverlayTooltip tip={"Rate the course"} placement="top">
        { screenSize ?
        <Badge pill variant="secondary" style={{ height: "24px", minWidth: "25px", backgroundColor: "#f2f2f2" }}>
                    <div style={{ display: "flex" }}>
                      {(rating !== 0 || this.props.data.course_rating) && <p style={{ display: "inline-flex", paddingRight: "3px", color: "#001B4A" ,
    fontSize: "12px", marginTop: "0", fontWeight: "600"}}>{rating ? rating : this.props.data.course_rating}</p> }
                     
                    <IconStarFill onClick={this.onRatingButtonClick} style={{ position: "relative", width: "12px", height: "11px", cursor:"pointer"}} />
                    </div>
        </Badge> :
        <Badge pill variant="secondary" style={{ height: "20px", minWidth: "25px", backgroundColor: "#f2f2f2" }}>
        <div style={{ display: "flex" }}>
          {(rating !== 0 || this.props.data.course_rating) && <p style={{ display: "inline-flex", paddingRight: "3px", color: "#001B4A" ,
fontSize: "11px", marginTop: "-2px", fontWeight: "600"}}>{rating ? rating : this.props.data.course_rating}</p> }
         
        <IconStarFill onClick={this.onRatingButtonClick} style={{ position: "relative", top: "-2px", width: "11px", height: "10px"}} />
        </div>
</Badge>}
        </OverlayTooltip>
        </div>
        <Modal
          size="sm"
          scrollable={true}
          show={isModalOpen}
          onHide={this.handleCloseModal}
          dialogClassName="modal-dialog-centered modal-dialog-square modal-rounded"
        >
          <Modal.Body>
            <h2 className="text-color3 text-center fw-600 fs-18 mb-2">{this.props.ctx_labels.singular.rate} {name}</h2>
            <h6 className="text-color3"> </h6>
            <p></p>
            <p className="text-center mb-2">{number_of_users && `${this.props.ctx_labels.singular.total} ${this.props.ctx_labels.plural.rating}: ${number_of_users}`}</p>
            <p className="text-center mb-2">{average_rating && `${this.props.ctx_labels.singular.average} ${this.props.ctx_labels.plural.rating}: ${average_rating}`}</p>
            <p></p>
            <div className="d-flex justify-content-center">
            <StarRatings
              rating={rating !== 0 ? rating : this.props.data.course_rating ? this.props.data.course_rating:0 }
              starRatedColor="#FFC107"
              starEmptyColor="#CCCCCC"
              starHoverColor = "#FFC107"
              changeRating={this.changeRating}
              numberOfStars={5}
              name='rating'
              starDimension	= "30px"
              starSpacing = "2px"
              />
            </div>
            <p></p>
            <div className="d-flex justify-content-center">
              <Button onClick={this.insertRating}>{this.props.ctx_labels.singular.submit}</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withApiContext(withUserContext(withLabelsContext(Rate)));