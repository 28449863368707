import React from 'react';


export const Spin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="uil-ring-alt" {...props}>
    <circle cx="50" cy="50" r="45" stroke="#757575" fill="none" strokeWidth="10" />
    <circle cx="50" cy="50" r="45" stroke="#ffffff" fill="none" strokeWidth="10" strokeDasharray="282.74">
      <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="287.74" to="-287.74" />
    </circle>
  </svg>);
