import classNames from "classnames";
import { OverlayTooltip } from "components";
import React from "react";
import "./page-title.scss";

export default function PageTitle(props) {
  return (
    <>
      {props.sub ? (
        <>
          <h3
            className={classNames(
              "page-subtitle text-primary-dark",
              props.className
            )}
          >
            {props.children}
          </h3>
        </>
      ) : props.isAssessment ? (

        props.isDesktop ? (
          
            props.isAssessment.length > 80 ? 
            <OverlayTooltip tip={props.isAssessment} placement="top">
          <h2
            className={classNames(
              "page-title text-primary-dark",
              props.className
            )}
          >
            {props.children}
          </h2>
        </OverlayTooltip>  
        :
        (
          <h2
            className={classNames(
              "page-title text-primary-dark",
              props.className
            )}
          >
            {props.children}
          </h2>
        )
        )
        :
        props.isMobile && (
          
          props.isAssessment.length > 15 ? 
          <OverlayTooltip tip={props.isAssessment} placement="top">
        <h2
          className={classNames(
            "page-title text-primary-dark",
            props.className
          )}
        >
          {props.children}
        </h2>
      </OverlayTooltip>  
      :
      (
        <h2
          className={classNames(
            "page-title text-primary-dark",
            props.className
          )}
        >
          {props.children}
        </h2>
      )
      )
      ) : (
        <h2
          className={classNames(
            "page-title text-primary-dark",
            props.className
          )}
        >
          {props.children}
        </h2>
      )}
    </>
  );
}


