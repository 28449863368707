import { rgba } from "polished";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// import { default as ReactSelect } from "react-select";
import styled from "styled-components";
import "./StyledSelect.scss";
import Select from 'react-select';
// import 'react-select/dist/react-select.css';

// Theme
const StyledReactSelect = styled(Select)`

.Select > .is-focused  {
  border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
  background-color: ${({ theme }) => theme.primary};
}
  .Select-control {
       background-color: ${({ theme }) => theme.primary} !important;
       min-height: 40px;
       border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
       border-radius: 8px;
       font-size: 12px;

        & is-focused {
          border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
          background-color: ${({ theme }) => theme.primary};
        }
      }
    
    
      svg {
        fill: ${({ theme }) => theme.primary};
      
    
    &__option {
      &:hover {
        background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.primary};

        &:hover {
          background-color: ${({ theme }) => theme.primary};
        }
      }
    }
  }
`;

// export default function Select(props) {
// 	return (
// 		<StyledReactSelect
// 			className="react-select-container"
// 			classNamePrefix="react-select"
// 			isSearchable={false}
// 			{...props}
// 		/>
// 	);
// }

class NewSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.mkOptions(props),
    };
    this.searchString = "";
  }

  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    ),
    onChange: PropTypes.func.isRequired,
    unselectedValue: PropTypes.any,
    renderOption: PropTypes.func,
    required: PropTypes.bool.isRequired,
    allOptionsVisible: PropTypes.bool.isRequired,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    required: false,
    allOptionsVisible: false,
    unselectedValue: null,
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.options !== this.props.options) {
      const options = this.mkOptions(nextProps);
      this.setState({ options });
    }
  };

  mkOptions = ({ options }) =>
    !options
      ? null
      : options.map((o) => ({
          ...o,
          __lcLabel: o.label.toLowerCase(),
        }));

  handleInputChange = (str) => {
    this.searchString = str.toLowerCase() || "";
  };

  handleChange = (option) => {
    const value = option
      ? option.value === null || option.value === undefined
        ? option
        : option.value
      : this.props.unselectedValue;
    this.props.onChange(value);
  };

  renderSelectedOption = (option) => {
    if (this.props.renderOption) {
      return this.props.renderOption(option.label, option);
    }
    return option.label;
  };

  renderMenuOption = (option) => {
    const { value, label, __lcLabel } = option;
    let keyIdx = 0;
    const labelFrags = [];
    const addLabelFrag = (i, j, className) => {
      labelFrags.push(
        <span className={className} key={`${value}-${keyIdx}`}>
          {label.substring(i, j)}
        </span>
      );
      keyIdx = keyIdx + 1;
    };
    let i = 0;
    const matchLen = this.searchString.length;
    for (;;) {
      const j = __lcLabel.indexOf(this.searchString, i);
      if (matchLen === 0 || j === -1) {
        addLabelFrag(i);
        break;
      }
      addLabelFrag(i, j);
      i = j + matchLen;
      addLabelFrag(j, i, "select-label-substring-match");
    }
    if (this.props.renderOption) {
      return this.props.renderOption(labelFrags, option);
    }
    return labelFrags;
  };

  render = () => {
    const {
      className,
      options,
      onChange,
      renderOption,
      required,
      allOptionsVisible,
      inputRef,
      ...restProps
    } = this.props;
    const selectClassName = ["select", className].join(" ");
    const wrapperStyle =
      !allOptionsVisible || !this.state.options
        ? {}
        : {
            "--select-max-visible-options": this.state.options.length,
          };
          const value = this.props.async
          ? this.state.options?.filter(
              (o) =>
                this.props?.value !== null &&
                this.props?.value?.length > 0 &&
                (typeof this.props?.value === "string"
                  ? this.props?.value == o.value
                  : this.props?.value.includes(o.value))
            )
          : this.state.options?.filter((o) => o.value === this.props.value);
    return (
    <div className="styled_select_wrapper_div">
      <StyledReactSelect
        {...restProps}
        isSearchable={false}
        className={"styled_select_multi"}
        classNamePrefix="react-select"
        wrapperStyle={wrapperStyle}
        isLoading={this.state.options === null}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={this.state.options}
        optionRenderer={this.renderMenuOption}
        valueRenderer={this.renderSelectedOption}
        trimFilter={false}
        matchProp="label"
        clearable={!required}
        backspaceRemoves={!required}
        deleteRemoves={!required}
        escapeClearsValue={!required}
        ref={inputRef}
        value={value}
      />
      </div>
    );
  };
}

export default NewSelect;
