import * as config from 'config';
import { withLabelsContext } from 'context';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import * as util from 'services/util';
import './ThreadTable.css';
import ThreadTimestamp from '../ThreadMessages/ThreadTimestamp';
import styled from "styled-components";
import css from './ThreadTable.css';
import { rgba } from "polished";

const StyledTrWrapper = styled.div`
> .chat_row_bgactive {
background-color: ${({ theme }) => rgba(theme.color4, 0.109)};
}
`;




class ThreadTable extends Component {

  constructor(props) {
    super(props);
    this.state={
      styleState: false
    };
  }

  threadWindowElt = null;
  threadWindowTop = null;

  activeThreadElt = null;

  everRendered = false;

  static propTypes = {
    className:            PropTypes.string,
    threads:              PropTypes.object.isRequired,
    activeTid:            PropTypes.string,
    unviewedCount:        PropTypes.number.isRequired,
    phase:                PropTypes.string.isRequired,
    onThreadSelect:       PropTypes.func,
    now:                  PropTypes.instanceOf(Date).isRequired,
    narrow:               PropTypes.bool.isRequired,
    showMember:           PropTypes.bool.isRequired,
    showPreview:          PropTypes.bool.isRequired,
  };

  static defaultProps = {
    narrow:      false,
    showMember:  false,
    showPreview: false,
  };

  componentWillUpdate = () => {
    this.threadWindowTop = this.getThreadWindowTop();
  }

  shouldComponentUpdate = (nextProps) =>
    nextProps.threads       !== this.props.threads       ||
    nextProps.activeTid     !== this.props.activeTid     ||
    nextProps.unviewedCount !== this.props.unviewedCount ||
    nextProps.phase         !== this.props.phase         ||
    nextProps.narrow        !== this.props.narrow        ||
    !util.sameDates(nextProps.now, this.props.now);

  componentDidUpdate = (prevProps) => {
    this.scrollToTopOnActiveThreadMove(prevProps);

    if (!this.everRendered) {
      this.everRendered = true;
      util.scrollComponentToTop(this.activeThreadElt);
    }
  }

  scrollToTopOnActiveThreadMove = (prevProps) => {
    const tid = this.props.activeTid;
    if (
      tid !== prevProps.activeTid ||
      this.getThreadWindowTop() !== this.threadWindowTop
    ) {
      return;
    }
    const t = this.props.threads[tid];
    const n = t.messages.length;
    const prevT = prevProps.threads[tid];
    const prevN = prevT.messages.length;
    if (
      0 < n && (
        prevN === 0 ||
        prevT.messages[prevN - 1].timestamp < t.messages[n - 1].timestamp
      )
    ) {
      this.scrollThreadWindowToTop();
    }
  }

  getThreadWindowTop = () =>
    this.threadWindowElt ? this.threadWindowElt.scrollTop : null;

  scrollThreadWindowToTop = () => {
    if (this.threadWindowElt) {
      this.threadWindowElt.scrollTop = 0;
    }
  }

  annotateThreads = () => {
    const zeroTimestamp = new Date(0);
    const allTids = Object.getOwnPropertyNames(this.props.threads);
    const annotThreads = allTids.map((tid) => {
      const isActive = tid === this.props.activeTid;
      const t = this.props.threads[tid];
      const n = t.messages.length;
      // Using a timestamp of 0 (midnight, Jan 1, 1970) ensures all threads
      // possessing only drafts are displayed last
      const timestamp = 0 < n ? t.messages[n - 1].timestamp : zeroTimestamp;
      const unviewedCount =
        isActive ? this.props.unviewedCount : t.unviewedCount;
      const hasDraft = t.draft !== null;
        // isActive ? this.props.phase === 'inputting' : t.draft !== null;
      return {
        isActive,
        timestamp,
        unviewedCount,
        hasDraft,
        id:     tid,
        thread: t,
      };
    });
    annotThreads.sort((t1, t2) =>
      // Sort in reverse order
      t1.timestamp < t2.timestamp ?  1 :
      t1.timestamp > t2.timestamp ? -1 :
      0
    );
    return annotThreads;
  }

  setThreadWindowRef = (r) => {
    this.threadWindowElt = ReactDOM.findDOMNode(r);
  }

  setActiveThreadRef = (r) => {
    this.activeThreadElt = ReactDOM.findDOMNode(r);
  }

  mkThreadClassName = (name) => {
    return `${name} ${this.props.showMember ? 'with-member' : ''}`;
  }

  mkMessagePreview = (msgStr) => {
    let frag = msgStr.substr(0, config.maxChatPreviewLength);
    const eolIdx = frag.search(/\r?\n|\r/);
    if (eolIdx !== -1) {
      frag = frag.substr(0, eolIdx);
    } else if (msgStr.length <= config.maxChatPreviewLength) {
      return msgStr;
    } else {
      const lastSpcIdx = frag.lastIndexOf(' ');
      if (lastSpcIdx !== -1) {
        frag = frag.substr(0, lastSpcIdx);
      }
    }
    return `${frag.trim()}...`;
  }
  capitalizeFirstLetter=(stringData)=>{
    return(
      stringData[0].toUpperCase() + stringData.substring(1)
    )
  }
  
  renderHeader = () => !this.props.narrow && (
    
    <thead style={{backgroundColor:this.props.theme.color2}}>
      <tr>
        {this.props.showMember && (
          <th className="chat-table-member">
            {this.props.ctx_labels.singular.member}
          </th>
        )}
        <th className={this.mkThreadClassName('chat-table-module')}>
        <span className="hower-clr">{this.capitalizeFirstLetter(this.props.ctx_labels.singular.module)}</span>
        </th>

        <th className={this.mkThreadClassName('chat-table-chapter')}>
        <div className= {css.chapterHeader}>
        <span>{this.capitalizeFirstLetter(this.props.ctx_labels.singular.chapter)}</span>
        </div>
        </th>

        <th className={this.mkThreadClassName('chat-table-curriculum')}>
        <span className="hower-clr">{this.capitalizeFirstLetter(this.props.ctx_labels.singular.curriculum)}</span>
        </th>
        <th className="chat-table-messages">
        <span className="hower-clr">{this.capitalizeFirstLetter(this.props.ctx_labels.plural.message)}</span>
        </th>
        <th className="chat-table-messages-new-draft">
        </th>
        <th className="chat-table-timestamp">
          {this.props.refreshButton}
        </th>
      </tr>
    </thead>
  );

  renderRow = (t, i, renderCells) => (
    <StyledTrWrapper>
    <tr
      className={[
        t.isActive          ? 'chat-table-active'   : '',
        0 < t.unviewedCount ? 'chat-table-unviewed' : '',
        this.state.styleState? 'chat_row_bgactive' :'chat-row-bginactive',
      ].join(' ')}
      key={`thread-${i}`}
      onClick={this.props.onThreadSelect(t.id)}
      ref={t.isActive ? this.setActiveThreadRef : null}
    >
      {this.state.styleState=!this.state.styleState}
      {renderCells()}
      <td className="chat-table-timestamp">
        {t.thread.messages.length === 0 ? '' : (
          <ThreadTimestamp
            timestamp={t.timestamp}
            now={this.props.now}
            id={i}
          />
        )}
      </td>
    </tr>
    </StyledTrWrapper>
  );

  renderMessagesNewDraft = (t) => (
    <React.Fragment>
      {0 < t.unviewedCount && (
        <span className="chat-table-messages-new">
          {` (${t.unviewedCount} new)`}
        </span>
      )}
      {t.hasDraft && (
        <React.Fragment>
          <span>
            {', '}
          </span>
          <span className="chat-table-messages-draft">
            {'Draft'}
          </span>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  renderNarrowRow = (t, i) => {
    const n = t.thread.messages.length;
    return this.renderRow(t, i, () => (
      <td className="chat-table-thread-summary">
        {this.props.showMember && (
          <div className="chat-table-member">
            {t.thread.memberName}
          </div>
        )}
        <div className="chat-table-module">
          {t.thread.moduleName}
        </div>
        <div className="chat-table-chapter">
          {t.thread.chapterName}
        </div>
        <div className="chat-table-curriculum">
          {t.thread.curriculumName}
        </div>
        <div className="chat-table-messages">
          <span className="chat-table-messages-count">
            {`${n} message${n === 1 ? '' : 's'}`}
          </span>
          {this.renderMessagesNewDraft(t)}
          {this.props.showPreview && 0 < n && (
            <div className="chat-table-messages-preview">
              {this.mkMessagePreview(t.thread.messages[n - 1].value)}
            </div>
          )}
        </div>
      </td>
    ));
  }

  renderWideRow = (t, i) => (
    this.renderRow(t, i, () => (
      <React.Fragment>
        {this.props.showMember && (
          <td className="chat-table-member">
            {t.thread.memberName}
          </td>
        )}
        <td className={this.mkThreadClassName('chat-table-module')}>
          {t.thread.moduleName}
        </td>
        <td className={this.mkThreadClassName('chat-table-chapter')}>
          {t.thread.chapterName}
        </td>
        <td className={this.mkThreadClassName('chat-table-curriculum')}>
          {t.thread.curriculumName}
        </td>
        <td className="chat-table-messages">
          {t.thread.messages.length}
        </td>
        <td className="chat-table-messages-new-draft">
          {this.renderMessagesNewDraft(t)}
        </td>
      </React.Fragment>
    )));

  render = () => {
    const { narrow } = this.props;
    const annotThreads = this.annotateThreads();
    const tableClassName = [
      'chat-table-table',
      `chat-table-${narrow ? 'narrow' : 'wide'}`,
      this.props.className,
    ].join(' ');
    const f = narrow ? this.renderNarrowRow : this.renderWideRow;
    return (
      <Table className={tableClassName}>
        {this.renderHeader()}
        <tbody ref={this.setThreadWindowRef}>
            {annotThreads.map(f)}  
        </tbody>
        {this.state.styleState=false}
      </Table>         
    );
  }
}


export default withLabelsContext(ThreadTable)
