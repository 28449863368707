import PropTypes from "prop-types";
import css from "./sectionnew.module.scss";
import classNames from "classnames";
import { withLabelsContext } from 'context';
import React, { useRef, useEffect } from 'react';
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";

function SectionNumber(props) {
  const { question, selected, selectedItemId, qpool, rand, questionIds,fromCreateTest } = props;
  const theme = useTheme();
  const tableStyles = getTableStyles(theme);
  let count = 0;

  const currentSectionRef = useRef(null);
  // useEffect to set focus on the current section when it's shown
  useEffect(() => {
    if (currentSectionRef.current) {
      currentSectionRef.current.focus();
    }
  }, [selectedItemId]);
  return (
    <>
      <div className={css.numbers}>
      {
 fromCreateTest ? props.question.map((item, j) => {
  const index = props.question.findIndex(q => q.id === item.id);
  
  const count = index !== -1 ? index + 1 : 0;

        return (
          <span
            ref={item.id === selectedItemId ? currentSectionRef : null}
            style={
              item.id === selectedItemId && count < 10
                ? { cursor: "pointer", backgroundColor: theme.color2, width: "30px", height: "32px" }
                : item.id === selectedItemId
                ? { cursor: "pointer", backgroundColor: theme.color2 }
                : item.attempted === 1 && count < 10
                ? { cursor: "pointer", backgroundColor: theme.primary, width: "30px", height: "32px" }
                : item.attempted === 1
                ? { cursor: "pointer", backgroundColor: theme.primary }
                : count < 10
                ? { cursor: "pointer", width: "30px", height: "32px" }
                : { cursor: "pointer" }
            }
            key={item.id}
            className={classNames(
              item.attempted === 1 && css.completed,
              item.attempted === 2 && css.skip,
              item.flagged === 1 && css.current,
              item.id === selectedItemId && css.current
            )}
            renderedOn="form"
            selectable
            selected={selectedItemId === item.id}
            onClick={ () => selected(item.id, item.type) }
            tabIndex={item.id === selectedItemId ? 0 : -1}
          >
            {count}
          </span>
        );
      }) :
      Object.values(question)
      // .sort((a, b) => (qpool ? question.indexOf(a) - question.indexOf(b) : rand === 0 ? a.sequence - b.sequence : 0))
      .sort((a, b) => {
        if (qpool) {
          return question.indexOf(a) - question.indexOf(b);
        } else {
          if (rand === 0) {
            return a.sequence - b.sequence;
          } else {
            return 0;
          }
        }
      })
        .map((item, j) => {
          const count = item.id ? questionIds.indexOf(item.id) + 1 : 0;
          return (
            <span
              ref={item.id === selectedItemId ? currentSectionRef : null}
              style={
                item.id === selectedItemId && count < 10
                  ? { cursor: "pointer", backgroundColor: theme.color2, width: "30px", height: "32px" }
                  : item.id === selectedItemId
                  ? { cursor: "pointer", backgroundColor: theme.color2 }
                  : item.attempted === 1 && count < 10
                  ? { cursor: "pointer", backgroundColor: theme.primary, width: "30px", height: "32px" }
                  : item.attempted === 1
                  ? { cursor: "pointer", backgroundColor: theme.primary }
                  : count < 10
                  ? { cursor: "pointer", width: "30px", height: "32px" }
                  : { cursor: "pointer" }
              }
              key={item.id}
              className={classNames(
                item.attempted === 1 && css.completed,
                item.attempted === 2 && css.skip,
                item.flagged === 1 && css.current,
                item.id === selectedItemId && css.current
              )}
              renderedOn="form"
              selectable
              selected={selectedItemId === item.id}
              onClick={() => selected(item.id)}
              tabIndex={item.id === selectedItemId ? 0 : -1}
            >
              {count}
            </span>
          );
        })
}




      </div>
    </>
  )
}

SectionNumber.propTypes = {
  // title: PropTypes.string,
  // numbers: PropTypes.array, item: PropTypes.object.isRequired,
  renderedOn: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  deletable: PropTypes.bool,
  reorderable: PropTypes.bool,
  sequence: PropTypes.number,
};

SectionNumber.defaultProps = {
  renderedOn: "form", // 'form' or 'modal'
  deletable: false,
  selectable: false,
  selected: false,
  reorderable: false,
  // title: "",
  // numbers: [
  //   {
  //     label: "1",
  //   },
  // ],
};

export default withLabelsContext(SectionNumber);
