import classNames from "classnames";
import PropTypes from "prop-types";

export default function JustifyBetween(props) {
  return (
    <div
      className={classNames(
        props.breakpoint ? `d-${props.breakpoint}-flex` : "d-flex",
        "justify-content-between",
        props.alignItems
          ? `align-items-${props.alignItems}`
          : "align-items-start",
        props.className ?? ""
      )}
    >
      {props.children}
    </div>
  );
}

JustifyBetween.propTypes = {
  breakpoint: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
};

JustifyBetween.defaultProps = {
  breakpoint: undefined,
};
