import React from 'react';
import { Spin as SpinIcon } from './Spin';
import './Loader.css';




const Loader = ({
  className,
  active,
  width = 40,
  fill = '#fff',
  children,
}) => active && (
  <div
    className={[className, 'loader'].join(' ')}
  >
    <SpinIcon width={width} fill={fill} />
    {children}
  </div>
);


export default Loader
