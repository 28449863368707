import { IconCircleAdd, IconCircleMinus, IconStack, IconChevronUpNew, IconChevronDownNew } from "Icons";
import React, { useState, useEffect } from "react";
import css from "./card-group.module.scss";
import classNames from "classnames";
import {  IconCalendar } from "Icons";
import { Collapse } from "react-bootstrap";

export default function CardGroup(props) {
  const [isOpen, setOpen] = useState(props.isOpen);
  // const [highlt, setHighlt] = useState(false);
  const changeState = (condition) => {
    if (condition) {
      // setHighlt(true)
      setOpen(true)
    } else {
      // setHighlt(false)
      setOpen(false)
    }

  }
  useEffect(() => {
    const controller = new AbortController();//to deal with memory leaks of unmounted components.
    changeState(props.highlt);
    return () => controller.abort();
  }, [props?.children?.props?.selectedKey]);
  const screenSize = window.screen.width > 822 ? false: true;
  return (
    <>
      {props.fromChapterInner || props.fromMultiPreview || props.subscriptionPreview ?
        <div className={classNames(isOpen === true ? (css.wrapper_mob) : (css.wrapper, props.preview && css.wrapper_shadows))} >
          <div className={css.header} onClick={() => setOpen(!isOpen)}>
          <div className={classNames(screenSize ? css.title_chap : css.title_mob)}>{props.number}.&nbsp;{props.title && props.title.length > 30 ? props.title.slice(0,30) + "..." : props.title}</div>
            {props.date && (
              <span className={css.number}>{props.date}</span>
            )}

              <div className={ css.title_total}>
              <span className={css.total_mob}>
                {props.total}
              </span>
              </div>

            
              <span className={css.toggle_mob}>
                {isOpen ? <IconChevronUpNew className={css.icon_up} /> : <IconChevronDownNew className={css.icon_down}  />}
              </span>            
          </div>
          {(window.screen.width > 822 && props.assignmentDate) && (<div className={css.title_assigned}><IconCalendar style={{marginLeft: "21px", fill: "#808080"}}height="15px" width="15px" className="claender_icon"/> &nbsp;{props.assignmentDate}</div>)}
          <Collapse in={isOpen}>
            {<div className={css.cards_mob}>{props.children}</div>} 
          </Collapse>         
        </div>
        :
        <div className={classNames(css.wrapper, props.preview && css.wrapper_shadow)}>
          <div className={css.header} onClick={() => setOpen(!isOpen)}>
            <span className={css.number}>{props.number}</span>
            <div className={css.title}>{props.title && props.title.length > 30 ? props.title.slice(0,30) + "..." : props.title}</div>
            {props.date && (
              <span className={css.number}>{props.date}</span>
            )}
            {props.total && (
              <span className={`${css.total} bg-color3`}>
                <IconStack />
                {props.total}
              </span>
            )}
            <span className={css.toggle}>
              {isOpen ? <IconCircleMinus /> : <IconCircleAdd />}
            </span>
          </div>
          {isOpen && <div className={css.cards}>{props.children}</div>}
        </div>
      }
    </>
  );
}
