import labels from "components/PlayerNew/labels";
import * as config from "config";
import React from "react";


//////////////////////////////////////////////////////////////////////////////
// Scene titles

export const mkLoginTitle = siteName =>
  `${siteName || config.companyName} login`;
export const mkForgotPasswordTitle = siteName =>
  `Forgot ${siteName || config.companyName} password`;
export const mkResetPasswordTitle = siteName =>
  `Reset ${siteName || config.companyName} password`;
export const mkRegisterTitle = siteName =>
  `Register for ${siteName || config.companyName}`;
export const mkContactTitle = siteName =>
  `Contact ${siteName || config.companyName}`;
export const mkConfirmRegistrationTitle = siteName =>
  "Registration confirmation";
export const mkHomeTitle = siteName => `${siteName || config.companyName}`;
export const mkHowItWorksTitle = siteName =>
  `How ${siteName || config.companyName} works`;
export const mkMemberHomeTitle = siteName =>
  `${siteName || config.companyName} `; //- ${name}
export const mkSolutionsTitle = siteName =>
  `${siteName || config.companyName} solutions`;
export const mkNotFoundTitle = siteName => "Not found";

//////////////////////////////////////////////////////////////////////////////
// Internal messages

const systemErrorMsg = (
  <p className="alert-msg-body">
    We're currently experiencing technical difficulties.
    <br />
    {/* <br /> */}
    Please try again later.
  </p>
);

//////////////////////////////////////////////////////////////////////////////
// Inline informational messages

export const mkDataModalNotReadyInfo = name => <h3>Loading {name}...</h3>;

export const mkAllFieldsRequiredInfo = () => (
  <p className="text-danger">All fields are required</p>
);

export const mkConfirmRegistrationSuccessInfo = () => (
  <p>You have successfully registered with {config.companyName}</p>
);

export const mkConfirmRegistrationErrorInfo = () => systemErrorMsg;

export const mkNotFoundInfo = () => (
  <p>The requested page or resource could not be found</p>
);
export const mkModuleExistsError = (module) => (
  <p>{`A ${module.toLowerCase()} with the same name already exists`}</p>
);

export const mkRoleExistError = () => (
  <p>{`A Role with the same name already exists`}</p>
);

export const mkTestExistError = () => (
  <p>{`A Test with the same name already exists`}</p>
);

export const mkRoomExistError = () => (
  <p>{`A Room with the same name already exists`}</p>
);

export const mkTermsOfUseUnavailableInfo = () => (
  <p>
    Our apologies...the Terms of Use are presently unavailable.
    <br />
    <br />
    Please check back later.
  </p>
);

export const mkResetPasswordErrorInfo = () => systemErrorMsg;

export const mkNotesNotFoundInfo = () => (
  <h3>Our apologies...the notes have been deleted.</h3>
);

//////////////////////////////////////////////////////////////////////////////
// Help tips

export const mkStayLoggedInHelp = () => {
  const action = config.defaultStayLoggedIn ? "Uncheck" : "Do not check";
  return `${action} the box if this device is shared with others`;
};

export const mkAddAnswerDisabledTip = name =>
  `Please complete the current ${name} before adding another`;

export const mkAddRequiredAnswerTip = (questionType, anyCorrect) => {
  let tip = "";
  switch (questionType) {
    case config.testQuestionTypeE.MULTIPLE_CHOICE:
      tip = "Multiple-choice questions require at least two choices";
      if (!anyCorrect) {
        tip = `${tip}, at least one of which must be marked "correct"`;
      }
      break;
    case config.testQuestionTypeE.FILL_IN_THE_BLANK:
      tip = "Fill-in-the-blank questions require at least one answer";
      break;
    case config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE:
      tip = "Multiple-choice survey questions require at least two choices";
      break;
    default:
      break;
  }
  return tip;
};

export const mkAddOptionalAnswerTip = name =>
  `Adding another ${name} is optional`;

//////////////////////////////////////////////////////////////////////////////
// Inline errors

export const mkPasswordErrorHeader = () => "Passwords must contain at least";
export const mkPasswordShortError = n => `${n} characters`;
export const mkPasswordNoUcError = () => "1 uppercase letter";
export const mkPasswordNoLcError = () => "1 lowercase letter";
export const mkPasswordNoNumError = () => "1 number";
export const mkPasswordNoSymError = () =>
  "1 character that is not a letter or number";

export const mkEmailMalformedError = () => "Email address is malformed";

export const mkPhoneInvalidError = () => "Phone Number is invalid";

export const mkZoomPinInvalidError = () => "Zoom PIN is invalid";

export const mkZoomPwdInvalidError = () => "Zoom Password is invalid";

export const mkMaxStudInvalidError = () => "Max. Student is invalid";

export const mkZipCodeInvalidError = label => label + " is invalid";

export const mkRegisterEmailUnavailableError = () =>
  "A user with this email address already exists";

export const mkRoleInvalidError = () => "same role type already exists";

export const mkRoleInUseError = label => "Group admin's Administrator " + label + " cannot remove";
export const mkRoleNotExistsError = () => "Role do not exists in the campus";

export const mkRoleInUseErrorAdmin = label => "Administrator " + label + " cannot remove";

export const mkNameUnavailableError = () => "name already exists";

export const mkModuleCantBeChanged = label =>
  "cannot modify as " + label + " is already in use";

export const mkSyllabusNameUnavailableError = () => "name already exists";

export const mkShortNameUnavailableError = () => "short name already exists";

export const mkStandardInUseError = label => "cannot delete " + label + " is already in use";

export const mkRegisterTeacherUnavailableError = () =>
  "A class with this teacher already exists";

export const mkHasNoGroupAdminError = () =>
  "Cannot select Group as no Administrator is assigned to it";

export const mkHasNoReleaseDateError = () =>
  "Please extend the End Date to release all chapters";

export const mkHasNoTrainerIds = () =>
  "Please add Trainers to added courses";

export const mkClassUnavailableError = () =>
  "A class with this name already exists";

export const mkRegisterRegNoUnavailableError = () =>
  "A student with this Reg. No. already exists";

export const mkOrgStdError = (standard,org) =>
  `Selected ${standard} is not associated with this ${org}`;

export const mkTemplateActiveError = () =>
  "A template with this type is already active";

  export const mkDefaultLangugageAvailiability = () =>
  "A default language is already selected";

export const mkQuestionAddSuccess = (label) =>
  `${label} Added Successfully`;

export const mkModuleUpdateSuccess = (label) =>
  `${label} Updated Successfully`;

export const mkTestAddSuccess = (label) =>
  `${label} Successfully`;

  export const mkQuestionFailed= (label) =>
  `${label} deleted successfully`;

export const mkAnswerDuplication = () =>
"Remove duplicate choices";

export const mkQuestionAddFailure = (label) =>
`Failed to add the ${label.toLowerCase()}`;
  

  export const mkAttendanceAddSuccess = () =>
  "Attendance Added Successfully";

  export const mkAttendanceAlreadyExists = () => 
  "Attendance Already Exists in the Selected Hour"

  export const mkAiTestGeneration = () =>
  "Test Generation in Progress";

  export const mkAiTestGenerationSuccess = () =>
  "AI Test Generated Successfully";

  export const mkZoneSequenceActiveError = () =>
  "A zone with this sequence already exists";

  export const mkAlreadyExistError = (labels) =>
    `${labels} already exists`
  
export const mkAdminStudentClasslistError = students => {
  return (
    <div>
      Following students are already present in teacher classlist, Hence cannot
      be removed:
      <ul>
        {students.split(",").map((student, i) => {
          return <li key={i}>{student}</li>;
        })}
      </ul>
    </div>
  );
};

export const mkTrainerCodeError = status =>
  status === 99
    ? "This sponsor code is unavailable"
    : status === 98
      ? "This sponsor code is invalid"
      : "System error";

export const mkRegisterCodeUnavailableError = () =>
  "This sponsor code is no longer available; please choose another code";

export const mkRegisterCodeInvalidError = () =>
  "This code is invalid; please enter a different code or " +
  "000 if no other code is available";

  export const mkOrganizationAsscDomError = () =>
  "Invalid Configuration "; 


export const mkOrganizationHostError = status =>
  status === 89
    ? "Assc Domain is already taken"
    : "System error";
  
export const mkOrganizationNameError = status =>
  status === 99
    ? "This name is already taken"
    : status === 98
      ? "This name is invalid"
      : "System error";

export const mkModuleIdentifierError = status =>
  status === 99
    ? "This name is already in use"
    : status === 98
      ? "This name is invalid"
      : "System error";

export const mkTestMarkError = () => "This total mark is invalid";

export const mkPublishingError = publish => {
  return (
    <div>
      &nbsp; &nbsp; &nbsp;Press DONE Button to save the publish changes
      {/* and then UPDATE TEST :- */}
      <ul>
        {publish.map((arr, i) => {
          return (
            <li key={i}>
              {arr.name} : Required fields are incomplete / invalid
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const mkQuestionError = question => {
  return (
    <div>
      &nbsp; &nbsp; &nbsp;Press DONE Button to save the publish changes
      {/* and then UPDATE TEST :- */}
      <ul>
        {question.map((arr, i) => {
          return (
            <li key={i}>
              {arr.name} : Required fields are incomplete / invalid
            </li>
          );
        })}
      </ul>
    </div>
  );
};

/* 
export const mkPublishingError = name =>
  name + " : Required fields are incomplete"; */

export const mkForgotPasswordEmailInvalidError = () => "Email address invalid";

export const mkResetPasswordExpiredTokenError = () => (
  <p>This URL is no longer valid.</p>
);

export const mkAlreadyLoggedInError = () => (
  <p>
    You are already logged in.
    <br />
    <br />
    To log in as a different user, please log out first.
  </p>
);

export const mkInvalidLoginError = () => (
  <p className="text-danger">Invalid email or password</p>
);

export const mkInvalidPasswordError = () => "Invalid password";

//////////////////////////////////////////////////////////////////////////////
// Pop-up alerts

export const mkCampusSwitchErrorAlert = () => (
  <p>
    We are unable to select the campus.
    <br />
    Please try again later.
  </p>
);

export const mkFormSubmitErrorAlert = title => (
  <p>
    We are unable to complete your {title} at this time.
    <br />
    <br />
    Please try again later.
  </p>
);

export const mkFinalizeRegisterAlert = email => (
  <p>
    Thank you for submitting your registration information.
    <br />
    <br />
    An email has been sent to {email} describing how to complete the
    registration process.
  </p>
);

export const mkFinalizeForgotPasswordAlert = email => (
  <p>
    An email has been sent to {email} describing how to reset your password.
  </p>
);

export const mkFinalizeResetPasswordAlert = () => (
  <p>Your password has been updated.</p>
);

export const mkFinalizeChangePasswordAlert = () => (
  <p>Your password has been updated.</p>
);

export const mkDashboardErrorAlert = () => systemErrorMsg;

export const mkResetPasswordErrorAlert = () => systemErrorMsg;

export const mkLoginErrorAlert = () => systemErrorMsg;

export const mkChatErrorAlert = () => systemErrorMsg;

export const mkNotesErrorAlert = () => systemErrorMsg;

export const mkPlayerErrorAlert = () => systemErrorMsg;

export const mkChangePasswordErrorAlert = () => systemErrorMsg;

export const mkUserFormErrorAlert = () => systemErrorMsg;

export const mkEntitySearchErrorAlert = () => systemErrorMsg;

export const mkEntityFormErrorAlert = () => systemErrorMsg;

export const mkPublishErrorAlert = () => systemErrorMsg;

export const mkAssessmentErrorAlert = () => systemErrorMsg;

export const mkFailureErrorAlert = () => systemErrorMsg;

export const mkUserIdleAlert = (elapsed, remaining) => {
  const appendMinutes = n => `${n} minute${n === 1 ? "" : "s"}`;
  return (
    <p>
      Your session has been idle for {appendMinutes(elapsed)} and will end in{" "}
      {appendMinutes(remaining)}.
      <br />
      <br />
      To remain logged in, please click OK.
    </p>
  );
};

export const mkAuthErrorAlert = () => (
  <p>Your session has expired, Please login again to continue</p>
);

export const mkEntityActionSuccessAlert = (name, actionNoun) => {
  const capName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;

  return (
    <p>{`${capName} ${actionNoun}${
      // actionNoun == "update" ? "d" : "ed"
      actionNoun.slice(-1) === "e" ? "d" : "ed"
      } successfully`}</p>
  );
};

export const mkUserUploadSuccessAlert = (name, n) => (
  <p>{`Uploaded ${n} ${name}${n === 1 ? "" : "s"} successfully`}</p>
);

export const mkUserUploadErrorsAlert = () =>
  "The following errors were encountered.";

export const mkLogoutAlert = () => "You are now logged out.";

export const mkQBUploadSuccessAlert = (name, n) => (
  <p>{`Uploaded ${n} ${name}${n === 1 ? "" : "s"} successfully`}</p>
);

export const mkEmailSendSuccessAlert = () =>
  "Email sent successfully";

  export const mkChangesAlert = (label) =>
  `Please save the added ${label}`;

export const mkEmailSendFailureAlert = () =>
  "Email could not be sent"; 
//////////////////////////////////////////////////////////////////////////////
// Pop-up confirmations

export const mkFormClearConfirm = () => "Clear all fields?";

export const mkFormCancelConfirm = title => `Cancel ${title}?`;

export const mkEntityFormCancelConfirm = (name, actionNoun) =>
  mkFormCancelConfirm(`${name} ${actionNoun}`);

export const mkChatCancelConfirm = () => (
  <span>Are you sure you want&nbsp;to&nbsp;cancel?</span>
);

export const mkLogoutConfirm = () => (
  <span>Are you sure you want to log&nbsp;out?</span>
);
