import ElasticTextArea from "../ElasticTextArea/ElasticTextArea";
import { OverlayTooltip, Button, Loader } from "components";
import * as config from "config";
import { withApiContext, withWindowContext, withSocketContext, withUserContext} from "context";
import * as messages from "messages";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Badge } from "react-bootstrap";
import ReactDOM from "react-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
// import backBtn from "scenes/images/back-btn.png";
import ResizeHandler from "services/ResizeHandler";
import * as util from "services/util";
// import io from "socket.io-client";
import "./Chat.css";
import ThreadMessages from "../ThreadMessages/ThreadMessages";
import ThreadTable from "../ThreadTable/ThreadTable";
import { IconRefresh, IconCross } from "Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

class Chat extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      threads: {},
      activeTid: null,
      value: "",
      phase: "inputting",
      lastRefresh: null,
      // Latest count of unviewed messages in the active thread; we update
      // the version stored with the thread when we update each message's
      // viewed flag
      unviewedCount: 0,
      displayState: this.mkInitialDisplayState(props),
      loading: false
    };
    this.socket = null;
    this.socketRefreshTimer = null;

    this.messageRefreshTimer = null;
    this.viewedMessageTimer = null;

    this.messagesDraftElt = null;
    // The position, immediately preceding a render, of the bottom of the
    // messages/draft relative to the bottom of the thread window
    this.messagesDraftBotOffset = null;
    this.messagesDraftScrollPending = false;

    this.initDraftState();
    // If the draft exists, the position, immediately preceding a render, of
    // its top relative to the top of the thread window
    this.draftTopOffset = null;

    this.unviewedMessageElts = {}; // TODO clear when switch threads
    this.unviewedMessageRegions = [];
    this.viewedMessageIds = null;

    this.resizeHandler = null;
    this.everRendered = false;

    // Specify here rather in CSS so we have ready access to it
    // TODO move to configuration, so user can specify (relaxed, normal,
    // compressed, etc.)
    this.messagePaddingBottom = 3; // px
    this.messageDescenderHeight = 3; // px (only meant as a crude estimate)
  }

  static propTypes = {
    memberId: PropTypes.string,
    chapterId: PropTypes.string,
    curriculum: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    contentId: PropTypes.string,
    hidden: PropTypes.bool.isRequired,
    onUnreadMessageChange: PropTypes.func,
    isFullscreen: PropTypes.bool.isRequired,
    // onCreditsChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    hidden: false,
    isFullscreen: false,
  };

  componentDidMount = () => {
    // Use periodic polling with the refresh timer until we can connect
    // with a socket
    this.connectSocket();
    this.getMessages({ loadAll: true });
    this.startMessageRefreshTimer();
    this.addResizeHandler();

    this.addWidthChangeListener();
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.mkThreadIdFromProps(nextProps) !==
      this.mkThreadIdFromProps(this.props)
    ) {
      this.finalizeActiveThread();
      this.getMessages({ props: nextProps, loadAll: true });
    }
    if (
      nextProps.hidden !== this.props.hidden ||
      nextProps.isFullscreen !== this.props.isFullscreen
    ) {
      this.updateActiveThreadVisibility(nextProps);
      if (!nextProps.hidden && this.draftR) {
        // Force the draft to re-render in case the fullscreen mode has changed
        this.draftR.requestRefresh();
      }
    }
  };

  componentWillUpdate = () => {
    this.saveMessagesDraftBotOffset();
    this.saveDraftTopOffset();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const d = this.state.displayState;
    const prevD = prevState.displayState;
    if (d.activeThreadVisible) {
      if (this.state.activeTid !== prevState.activeTid) {
        this.scrollNewlyVisibleThread();
      } else if (
        !prevD.activeThreadVisible ||
        this.props.isFullscreen !== prevProps.isFullscreen
      ) {
        const defaultIsNoScroll =
          prevD.activeThreadLastVisibleFS === this.props.isFullscreen;
        this.scrollNewlyVisibleThread(defaultIsNoScroll);
      } else if (
        this.messagesDraftBotHiddenByNewMsgs(prevState) ||
        this.messagesDraftScrollPending
      ) {
        util.scrollToElementBottom(this.messagesDraftElt);
        this.messagesDraftScrollPending = false;
      } else if (this.draftTopOffset !== null) {
        this.restoreDraftTopOffset();
      } else if (d !== prevD && this.isDraftActive()) {
        this.scrollToDraftEnd();
      }
    } else {
      if (prevD.activeThreadVisible) {
        // We need to flush any viewed message IDs, as the thread state (NOT
        // viewedMessageIds) is used to find the oldest unviewed message
        // if the messages become visible again.  We don't need to save
        // the draft, but it's probably a good thing to do anyway.
        this.finalizeActiveThread(prevState);
      }
      if (this.state.activeTid !== prevState.activeTid) {
        this.clearActiveThreadVisibilityHistory();
      }
    }

    const messages = this.getActiveMessages(this.state);
    const prevMessages = this.getActiveMessages(prevState);
    if (messages !== prevMessages) {
      this.addNewUnreadMessageRegions(messages, prevMessages);
    }

    const unviewedCount = this.computeTotalUnviewedCount();
    const prevUnviewedCount = this.computeTotalUnviewedCount(
      prevProps,
      prevState
    );
    // After the first render, always send an update (to overwrite any
    // stale state)
    if (unviewedCount !== prevUnviewedCount || !this.everRendered) {
      if (this.props.onUnreadMessageChange) {
        this.props.onUnreadMessageChange(unviewedCount);
      }
    }

    this.everRendered = true;
  };

  componentWillUnmount = () => {
    this.stopMessageRefreshTimer();
    if (this.viewedMessageTimer) {
      clearTimeout(this.viewedMessageTimer);
    }
    this.saveDraft();
    this.saveViewedMessageIds();
    this.disconnectSocket();
    this.removeResizeHandler();

    this.removeWidthChangeListener();
  };

  initDraftState = () => {
    this.draftR = null;
    this.draftElt = null;
    this.draftEverFocused = false;
  };

  computeLayout = (props = this.props) => {
    // const h = window.innerHeight;
    const w = window.innerWidth;
    const multiThreaded = !this.isSingleThreaded(props);
    const wideThreadTable = true; // new design always wideThread - unless in mobile -- config.wideChatThreadTableMinBodyWidth <= w;
    const splitView =
      multiThreaded && wideThreadTable && config.splitChatViewMinBodyWidth <= w;
    return { wideThreadTable, splitView };
  };

  computeInitialActiveThreadVisibility = (props, splitView) =>
    !props.hidden && (splitView || this.isSingleThreaded(props));

  updateActiveThreadVisibility = (props) => {
    this.setState((s0) => {
      const d0 = s0.displayState;
      const activeThreadVisible = this.computeInitialActiveThreadVisibility(
        props,
        d0.splitView
      );
      const activeThreadLastVisibleFS = activeThreadVisible
        ? props.isFullscreen
        : d0.activeThreadLastVisibleFS;
      const displayState = {
        ...d0,
        activeThreadVisible,
        activeThreadLastVisibleFS,
      };
      return { displayState };
    });
  };

  clearActiveThreadVisibilityHistory = () => {
    this.setState((s0) => {
      const displayState = {
        ...s0.displayState,
        activeThreadLastVisibleFS: null,
      };
      return { displayState };
    });
  };

  mkInitialDisplayState = (props) => {
    const layout = this.computeLayout(props);
    const activeThreadVisible = this.computeInitialActiveThreadVisibility(
      props,
      layout.splitView
    );
    const activeThreadLastVisibleFS = activeThreadVisible
      ? props.isFullscreen
      : null;
    return {
      ...layout,
      activeThreadVisible,
      activeThreadLastVisibleFS,
    };
  };

  // TODO use cancelable promises for the API calls...but checking in
  // callbacks whether we're still mounted would still seem to be necessary
  // in the context of multithreading
  isChatMounted = () =>
    this.messageRefreshTimer !== null || this.socketRefreshTimer !== null;

  startMessageRefreshTimer = () => {
    if (!this.messageRefreshTimer) {
      this.messageRefreshTimer = setInterval(
        this.refreshMessages,
        1000 * config.chatRefreshIntervalSecs
      );
    }
  };

  stopMessageRefreshTimer = () => {
    if (this.messageRefreshTimer) {
      clearInterval(this.messageRefreshTimer);
      // Set to null to tell any pending callbacks we've unmounted
      this.messageRefreshTimer = null;
    }
  };

  startSocketRefreshTimer = () => {
    if (!this.socketRefreshTimer) {
      this.socketRefreshTimer = setInterval(
        this.emitSocketAuthToken,
        1000 * config.refreshAuthTokenIntervalSecs
      );
    }
  };

  stopSocketRefreshTimer = () => {
    if (this.socketRefreshTimer) {
      clearInterval(this.socketRefreshTimer);
      this.socketRefreshTimer = null;
    }
  };

  connectSocket = () => {
    // const socket = io();
    // this.socket = socket;
    // socket.on("auth-challenge", this.emitSocketAuthToken);
    // socket.on("auth-success", this.initSocket);
    // this.props.ctx_socket.on("new_ask_doctor_message", this.handleSocketNewMessage);
    // socket.on("server-error", this.handleSocketError);
    // socket.on("error", this.handleSocketError);
  };

  emitSocketAuthToken = () => {
    if (this.socket) {
      this.socket.emit("auth-token", util.getAuth());
    }
  };

  initSocket = () => {
    // We have now upgraded to the socket; force a refresh to get any
    // messages sent before the socket connection was established
    this.stopMessageRefreshTimer();
    this.startSocketRefreshTimer();
    this.refreshMessages();
  };

  handleSocketNewMessage = () => {
    // console.log('New message');
    this.refreshMessages();
  };

  handleSocketError = (err) => {
    // Downgrade to periodic polling
    this.disconnectSocket();
    this.startMessageRefreshTimer();

    if (err.type === "TransportError") {
      // console.log("socket.io connection down...attempting to reconnect");
      this.connectSocket();
    } else if (typeof err === "string") {
      // console.log(`socket.io error: ${err}`);
    } else if (err.type === "auth") {
      // console.log(`socket.io auth error: ${err.msg}`);
    } else {
      // console.log(JSON.stringify(err));
    }
  };

  disconnectSocket = () => {
    this.stopSocketRefreshTimer();
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  };

  updateLayout = () => {
    this.setState((s0) => {
      const layout = this.computeLayout();
      const d0 = s0.displayState;
      if (
        layout.wideThreadTable === d0.wideThreadTable &&
        layout.splitView === d0.splitView
      ) {
        return null;
      }
      const displayState = { ...d0, ...layout };
      return { displayState };
    });
  };

  addResizeHandler = () => {
    this.resizeHandler = new ResizeHandler(this.updateLayout);
  };

  removeResizeHandler = () => {
    this.resizeHandler.cleanup();
  };

  // FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME
  // Remove this if we can ever listen for a resize event on the draft
  // textarea itself
  findChatWindowRootNode = () => {
    let node = this.messagesDraftElt;
    while (
      node &&
      node.className &&
      node.className.indexOf("player-chat-content") === -1
    ) {
      node = node.parentNode;
    }
    if (!node || !node.className) {
      return null;
    }
    node = node.parentNode;
    // Return null if we're in fullscreen mode
    return node.className.indexOf("player-chat") !== -1 ? node : null;
  };

  handleTransitionEnd = (e) => {
    if (e.propertyName === "width" && this.draftR) {
      this.draftR.updateTextAreaHeight();
    }
  };

  addWidthChangeListener = () => {
    const root = this.findChatWindowRootNode();
    if (root) {
      root.addEventListener("transitionend", this.handleTransitionEnd);
    }
  };

  removeWidthChangeListener = () => {
    const root = this.findChatWindowRootNode();
    if (root) {
      root.removeEventListener("transitionend", this.handleTransitionEnd);
    }
  };
  // FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME

  saveMessagesDraftBotOffset = () => {
    const { activeThreadVisible } = this.state.displayState;
    this.messagesDraftBotOffset = activeThreadVisible
      ? this.computeMessagesDraftBotOffset()
      : null;
  };

  saveDraftTopOffset = () => {
    this.draftTopOffset = this.isDraftActive()
      ? this.computeDraftTopOffset()
      : null;
  };

  messagesDraftBotHiddenByNewMsgs = (prevState) =>
    this.state.threads !== prevState.threads &&
    this.messagesDraftBotOffset === 0 &&
    0 < this.computeMessagesDraftBotOffset();

  isDraftActive = () => this.draftR !== null && this.draftR.isFocused();

  restoreDraftTopOffset = () => {
    const newOffset = this.computeDraftTopOffset();
    if (newOffset === null || this.draftTopOffset === null) {
      return;
    }
    const delta = newOffset - this.draftTopOffset;
    if (delta === 0) {
      return;
    }
    const newTop = this.messagesDraftElt.scrollTop + delta;
    this.messagesDraftElt.scrollTop = newTop;
  };

  computeMessagesDraftBotOffset = () => {
    const elt = this.messagesDraftElt;
    return elt ? elt.scrollHeight - elt.scrollTop - elt.clientHeight : null;
  };

  computeDraftTopOffset = () =>
    this.draftElt && this.messagesDraftElt
      ? this.draftElt.offsetTop - this.messagesDraftElt.scrollTop
      : null;

  scrollNewlyVisibleThread = (defaultIsNoScroll = false) => {
    const msg = this.findFirstUnviewedMessage();
    if (!msg || !this.scrollUnviewedMessageToTop(msg.id)) {
      if (this.draftR) {
        this.scrollToDraftEnd();
      } else if (!defaultIsNoScroll && this.messagesDraftElt) {
        util.scrollToElementBottom(this.messagesDraftElt);
      }
    }
    // There is no guarantee a scroll event will be triggered above
    this.updateUnreadMessageRegions();
  };

  findFirstUnviewedMessage = () => {
    const { activeTid } = this.state;
    if (activeTid === null) {
      return null;
    }
    const { messages } = this.state.threads[activeTid];
    let i = 0;
    while (i < messages.length) {
      const msg = messages[i];
      if (!msg.viewed) {
        return msg;
      }
      i = i + 1;
    }
    return null;
  };

  scrollUnviewedMessageToTop = (id) => {
    const elt = this.unviewedMessageElts[id];
    if (elt && this.messagesDraftElt) {
      this.messagesDraftElt.scrollTop = elt.offsetTop;
      return true;
    }
    return false;
  };

  getActiveMessages = (s) =>
    s.activeTid !== null ? s.threads[s.activeTid].messages : [];

  addNewUnreadMessageRegions = (messages, prevMessages) => {
    const winElt = this.messagesDraftElt;
    if (!winElt) {
      return;
    }
    let winTop = winElt.scrollTop;
    let winBot = winTop + winElt.clientHeight;
    if (!this.state.displayState.activeThreadVisible) {
      // Ensure no messages overlap the window
      winTop = 0;
      winBot = 0;
    }

    const newUnviewed = [];
    const prevLen = prevMessages.length;
    // If we just switched threads, the last message of the previous thread
    // will obviously not be found, and so we will iterate through all the
    // messages of the new thread
    const stopMsg = 0 < prevLen ? prevMessages[prevLen - 1] : null;
    let i = messages.length - 1;
    for (; 0 <= i && messages[i] !== stopMsg; i = i - 1) {
      const msg = messages[i];
      if (msg.viewed) {
        continue;
      }
      const elt = this.unviewedMessageElts[msg.id];
      if (!elt) {
        continue;
      }
      const regions = [];
      ////////////////////////NEED TO CHECK////////////////////////
      //Commented to change the style to that of read messages irrespective of scroll
      //
      // this.appendUnviewedMessageRegions(regions, winTop, winBot, elt);
      // if (0 < regions.length) {
      //   // Use unshift since we're traversing the messages in reverse
      //   newUnviewed.unshift({ msg, regions });
      // } else 
      // {
      //  
      ////////////////////////NEED TO CHECK////////////////////////
        this.recordMessageViewed(msg);
      // }
    }
    // If we have switched threads, i will be less than 0
    this.unviewedMessageRegions =
      i < 0 ? newUnviewed : this.unviewedMessageRegions.concat(newUnviewed);
    if (0 < newUnviewed.length || i < 0) {
      const unviewedCount = this.unviewedMessageRegions.length;
      this.setState({ unviewedCount });
    }
  };

  updateUnreadMessageRegions = () => {
    const winElt = this.messagesDraftElt;
    if (!winElt) {
      return;
    }
    let winTop = winElt.scrollTop;
    let winBot = winTop + winElt.clientHeight;

    const n0 = this.unviewedMessageRegions.length;
    let n = n0;
    let i = 0;
    while (i < n) {
      const umr = this.unviewedMessageRegions[i];
      const elt = this.unviewedMessageElts[umr.msg.id];
      if (!elt) {
        break;
      }
      const top = elt.offsetTop;
      if (winBot <= top) {
        break;
      }
      if (top + elt.clientHeight <= winTop) {
        i = i + 1;
        continue;
      }
      let newRegions = [];
      umr.regions.forEach(({ topFrac, botFrac }) => {
        this.appendUnviewedMessageRegions(
          newRegions,
          winTop,
          winBot,
          elt,
          topFrac,
          botFrac
        );
      });
      if (0 < newRegions.length) {
        umr.regions = newRegions;
        i = i + 1;
      } else {
        this.recordMessageViewed(umr.msg);
        this.unviewedMessageRegions.splice(i, 1);
        n = n - 1;
      }
    }
    if (n < n0) {
      this.setState({ unviewedCount: n });
    }
  };

  appendUnviewedMessageRegions = (
    regions,
    winTop,
    winBot,
    elt,
    topFrac = 0,
    botFrac = 1
  ) => {
    const top = elt.offsetTop;
    const bot =
      top +
      elt.clientHeight -
      this.messagePaddingBottom -
      this.messageDescenderHeight;
    const h = bot - top;
    if (top + topFrac * h < winTop) {
      regions.push({
        topFrac,
        botFrac: Math.min((winTop - top) / h, botFrac),
      });
    }
    if (winBot < top + botFrac * h) {
      regions.push({
        topFrac: Math.max(topFrac, (winBot - top) / h),
        botFrac,
      });
    }
  };

  recordMessageViewed = (msg) => {
    if (this.viewedMessageIds === null) {
      this.viewedMessageIds = {};
      this.viewedMessageTimer = setTimeout(
        this.flushViewedMessageIds,
        1000 * config.chatViewedUpdateIntervalSecs
      );
    }
    this.viewedMessageIds[msg.id] = true;
  };

  saveViewedMessageIds = () => {
    if (!this.viewedMessageIds) {
      return;
    }
    this.props.ctx_api
      .privateRequestRow({
        cmd: "mark_ask_doctor_messages_read",
        args: {
          messageIds: Object.getOwnPropertyNames(this.viewedMessageIds),
        },
      })
      .then(this.handleSaveViewedMessageIdsResponse)
      .catch(this.handleChatSystemFailure);
  };

  handleSaveViewedMessageIdsResponse = ({ rsp }) => {
    if (rsp.status_value !== 1) {
      this.handleChatSystemFailure();
    }
  };

  flushViewedMessageIds = (s = null) => {
    this.setState((s0) => {
      // if (s.unviewedCount < 1) {
      //   return null;
      // }
      if (!this.viewedMessageIds) {
        return null;
      }
      this.saveViewedMessageIds();
      const { activeTid, unviewedCount } = s || s0;
      const t0 = s0.threads[activeTid];
      const messages = t0.messages.map((msg) =>
      msg.id in this.viewedMessageIds ? { ...msg, viewed: true } : msg 
      );
      const t = {
        ...t0,
        messages,
        unviewedCount,
      };
      const threads = {
        ...s0.threads,
        [activeTid]: t,
      };
      this.viewedMessageIds = null;
      this.viewedMessageTimer = null;
      return { threads };
    });
  };

  finalizeActiveThread = (s = this.state) => {
    this.saveDraft(s);
    if (this.viewedMessageTimer) {
      clearTimeout(this.viewedMessageTimer);
    }
    this.flushViewedMessageIds(s);
  };

  mkThreadId = (
    memberId,
    curriculumId,
    chapterId,
    contentId,
    coursePublishId,
    courseId
  ) => {
    // Use long form to avoid bug in vim syntax highlighter
    if (memberId == null || curriculumId == null || contentId == null) {
      //fix-me:temporary fix done by remove chapter_id==null
      return null;
    }
    return `${memberId}#${curriculumId}#${chapterId}#${contentId}#${coursePublishId}#${courseId}`;
  };

  explodeThreadId = (tid) => {
    let memberId = null;
    let curriculumId = null;
    let chapterId = null;
    let contentId = null;
    let coursePublishId = null;
    let courseId = null;
    if (tid !== null) {
      [
        memberId,
        curriculumId,
        chapterId,
        contentId,
        coursePublishId,
        courseId,
      ] = tid.split("#");
    }
    return { memberId, curriculumId, chapterId, contentId, coursePublishId,courseId };
  };

  mkThreadIdFromProps = (props) => {
    const {
      memberId,
      curriculumId,
      chapterId,
      contentId,
      coursePublishId,
      courseId,
    } = props;
    //const curriculumId = curriculum.id || curriculum.curriculumId || props.curriculumId;
    return this.mkThreadId(
      memberId,
      curriculumId,
      chapterId,
      contentId,
      coursePublishId,
      courseId,
    );
  };

  isSingleThreaded = (props = this.props) => props.singleThreaded === true;

  computeTotalUnviewedCount = (props = this.props, s = this.state) => {
    if (this.isSingleThreaded(props)) {
      return s.unviewedCount;
    }
    let n = Object.values(s.threads).reduce(
      (acc, t) => acc + t.unviewedCount,
      0
    );
    if (s.activeTid !== null) {
      n = n - s.threads[s.activeTid].unviewedCount + s.unviewedCount;
    }
    return n;
  };

  copyThread = (t) => ({
    queryIds: { ...t.queryIds },
    replyIds: { ...t.replyIds },
    messages: [...t.messages],
    unviewedCount: t.unviewedCount,
    draft: t.draft,
    curriculumName: t.curriculumName,
    moduleName: t.moduleName,
    chapterName: t.chapterName,
    memberName: t.memberName,
  });

  newThread = (r = null) => ({
    queryIds: {},
    replyIds: {},
    messages: [],
    unviewedCount: 0,
    draft: null,
    curriculumName: r ? r.curriculum_name : null,
    moduleName: r ? r.module_name : null,
    chapterName: r ? r.chapter_name : null,
    memberName: r ? r.member_name : null,
  });

  getMessages = ({
    props = this.props,
    tid = this.mkThreadIdFromProps(props),
    loadAll = false,
  }) => {
    this.setState({loading: true})
    // Perform inside a state update to ensure we are using the latest state
    this.setState((s0) => {
      const {
        memberId,
        curriculumId,
        chapterId,
        contentId,        
        coursePublishId,
        courseId
      } = this.explodeThreadId(tid);
      const argArr = {};
      if (props.singleThreaded) {
        argArr.memberId = memberId || undefined;
        argArr.curriculumId = curriculumId || undefined;
        argArr.videoUnitId = contentId || undefined;
        argArr.chapterId = chapterId || undefined;
        argArr.coursePublishId = coursePublishId || undefined;
        argArr.courseId = courseId || undefined;
      }
      const t = s0.threads[tid];
      let fromTimestamp =
        (!loadAll && s0.lastRefresh) ||
        (loadAll &&
          t &&
          0 < t.messages.length &&
          t.messages[t.messages.length - 1].timestamp) ||
        undefined;

      props.ctx_api
        .privateRequestRow({
          cmd: "get_ask_trainer_messages",
          deps: [
            "get_ask_trainer_messages",
            "insert_update_ask_trainer_then_refresh",
            "insert_update_ask_doctor_draft",
          ],
          args: {
            //  curriculumId:curriculumId,
            // coursePublishId: coursePublishId,
            // videoUnitId : contentId,
            fromTimestamp,
            // memberId,
            // chapterId,
            ...argArr,
          },
        })
        .then(this.handleGetMessagesResponse(tid, loadAll))
        .catch(this.handleChatSystemFailure);
      return null;
    });
  };

  handleGetMessagesResponse = (reqTid, loadAll = false) => ({ rsp }) => {
    this.setState({loading: false})
    // In case we unmounted immediately after issuing the request
    if (!this.isChatMounted()) {
      return;
    }
    //  this.props.notificationdata(rsp.notification)
    // if (this.props.notificationdata){
    //   this.props.notificationdata(rsp.notification)
    // }
    
    this.setState((s0) => {
      let threads = {};
      let latestTid = null;
      let latestTimestamp = null;
      let latestUnviewedTid = null;
      let latestUnviewedTimestamp = null;
      rsp.message.forEach((r) => {
        const tid = this.mkThreadId(
          r.member_id,
          r.curriculum_id,
          r.chapter_id,
          r.video_unit_id,
          r.course_publish_id,
          r.course_id
        );
        let t = threads[tid];
        if (!t) {
          const t0 = s0.threads[tid];
          t = t0 ? this.copyThread(t0) : this.newThread(r);
          threads[tid] = t;
        }
        let timestamp = null;
        if (r.is_new === 1 && r.has_reply === 0) {
          if (t.draft === null) {
            t.draft = r.query_field;
            timestamp = new Date(r.asked_on);
          }
        } else {
          let msg = null;
          let id = r.member_query_id;
          if (id !== null && !t.queryIds[id]) {
            t.queryIds[id] = true;
            msg = this.extractQuery(r);
            t.messages.push(msg);
          }
          if (r.has_reply === 1) {
            id = r.query_reply_id;
            if (!t.replyIds[id]) {
              t.replyIds[id] = true;
              msg = this.extractReply(r);
              t.messages.push(msg);
              if (!msg.viewed) {
                t.unviewedCount = t.unviewedCount + 1;
                if (
                  !latestUnviewedTimestamp ||
                  latestUnviewedTimestamp < msg.timestamp
                ) {
                  latestUnviewedTimestamp = msg.timestamp;
                  latestUnviewedTid = tid;
                }
              }
            }
          }
          // If we have both a query and a reply, msg will be the reply,
          // which will always have a later timestamp
          if (msg) {
            timestamp = msg.timestamp;
          }
        }
        if (timestamp && (!latestTimestamp || latestTimestamp < timestamp)) {
          latestTimestamp = timestamp;
          latestTid = tid;
        }
      });
      Object.values(threads).forEach((t) => {
        t.messages.sort((a, b) =>
          a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
        );
      });

      if (rsp.message.length === 0) {
        if (reqTid && !(reqTid in s0.threads)) {
          threads[reqTid] = this.newThread();
        } else {
          threads = s0.threads;
        }
      }
      if (threads !== s0.threads) {
        Object.getOwnPropertyNames(s0.threads).forEach((tid) => {
          if (!(tid in threads)) {
            threads[tid] = s0.threads[tid];
          }
        });
      }

      let { activeTid, value, phase } = s0;
      if (loadAll) {
        activeTid = reqTid || latestUnviewedTid || latestTid;
        if (activeTid) {
          const t = threads[activeTid];
          if (t && t.draft !== null) {
            value = t.draft;
            phase = "inputting";
          } else if (t.messages.length === 0) {
            phase = "inputting";
          }
        }
      }
      const lastRefresh =
        0 < rsp.message.length ? new Date(rsp.message[0].fetch_timestamp) : s0.lastRefresh;
      return {
        threads,
        activeTid,
        value,
        phase,
        lastRefresh,
      };
    });
  };

  extractQuery = (row) => ({
    id: row.member_query_id,
    sender: row.sender_name === "" ? null : row.sender_name,
    viewed: true,
    timestamp: new Date(row.asked_on),
    value: row.query_field,
  });

  extractReply = (row) => ({
    id: row.query_reply_id,
    sender: row.sender_name,
    timestamp: new Date(row.replied_on),
    viewed: row.is_new === 0,
    value: row.reply,
  });

  refreshMessages = () => {
    if (this.isChatMounted()) {
      this.getMessages({});
    }
  };

  sendMessage = () => {
    const {
      memberId,
      curriculumId,
      chapterId,
      contentId,
      coursePublishId,
      courseId,
    } = this.explodeThreadId(this.state.activeTid);
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_ask_trainer_then_refresh",
        args: {
          memberId: memberId,
          curriculumId: curriculumId,
          videoUnitId: contentId,
          coursePublishId: coursePublishId,
          courseId: courseId,
          message: this.state.value,
          refreshFromTimestamp: this.state.lastRefresh,
          chapterId: chapterId, //this.props.chapterId
        },
      })
      .then(this.handleSendMessageSuccess(this.state.activeTid))
      .catch(this.handleSendMessageFailure);
  };

  handleSendMessageSuccess = (tid) => ({ rsp }) => {
    if (rsp.message.length === 0) {
      // We should have received at least the message we sent, so we
      // encountered an error
      this.handleSendMessageFailure();
      return;
    }

    this.setState((s0) => {
      const t = {
        ...s0.threads[tid],
        draft: null,
      };
      const threads = {
        ...s0.threads,
        [tid]: t,
      };
      return {
        threads,
        value: "",
        phase: "inputting", //'viewing',
      };
    });
    this.initDraftState();
    this.handleGetMessagesResponse(tid)({ rsp });

      const campusList = this.props.ctx_user.user.campusList;
      const organizationId = this.props.ctx_user.user.organizationId
      const roleId = this.props.ctx_user.user.roleId;
      const campus =
      campusList.find((d) => d.campus_role_id == `${organizationId} : ${roleId}`);
      campus.total_points = parseInt(campus.total_points) + parseInt(rsp.ple);
      this.props.ctx_user.update((user) => ({
        ...user,
        credits: campus.total_points + parseInt(rsp.ple),
      }));

  };

  handleSendMessageFailure = () => {
    this.setState({ phase: "inputting" });
    this.handleChatSystemFailure();
  };

  saveDraft = (s = this.state) => {
    const { activeTid, value, phase } = s;
    if (phase !== "inputting") {
      return;
    }
    if (value === "") {
      return;
    }
    const {
      memberId,
      curriculumId,
      contentId,
      coursePublishId,
      courseId
    } = this.explodeThreadId(activeTid);
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_ask_doctor_draft",
        args: {
          memberId: memberId,
          curriculumId: curriculumId,
          coursePublishId: coursePublishId,
          videoUnitId: contentId,
          courseId:courseId,
          status: 1,
          message: value,
        },
      })
      .then(this.handleUpdateDraftResponse(activeTid, value))
      .catch(this.handleChatSystemFailure);
  };

  deleteDraft = () => {
    const { threads, activeTid } = this.state;
    if (threads[activeTid].draft === null) {
      return;
    }
    const {
      memberId,
      curriculumId,
      contentId,
      coursePublishId,
      courseId
    } = this.explodeThreadId(activeTid);
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_ask_doctor_draft",
        args: {
          memberId: memberId,
          curriculumId: curriculumId,
          videoUnitId: contentId,
          coursePublishId: coursePublishId,
          courseId:courseId,
          status: 0,
        },
      })
      .then(this.handleUpdateDraftResponse(activeTid, null))
      .catch(this.handleChatSystemFailure);
  };

  handleUpdateDraftResponse = (tid, value) => ({ rsp }) => {
    if (rsp.status_value !== 1) {
      this.handleChatSystemFailure();
    }
    if (!this.isChatMounted()) {
      return;
    }
    this.setState((s0) => {
      const t = {
        ...s0.threads[tid],
        draft: value,
      };
      const threads = {
        ...s0.threads,
        [tid]: t,
      };
      return { threads };
    });
  };

  handleChatSystemFailure = () => {
    this.props.ctx_window.alert(messages.mkChatErrorAlert());
  };

  scrollToDraftEnd = () => {
    if (!this.draftEverFocused) {
      this.draftR.focus();
      const len = this.state.value.length;
      this.draftR.setSelectionRange(len, len);
      this.draftEverFocused = true;
    }
    if (this.messagesDraftElt) {
      util.scrollToElementBottom(this.messagesDraftElt);
    }

    // Hack to ensure the text area is the last element to acquire focus
    setTimeout(this.draftR.focus, 1);
  };

  handleDraftChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleDraftResize = ({ oldHeight, newHeight, cursorPos, text }) => {
    if (oldHeight < newHeight) {
      const onLastLine = !/\r?\n|\r/.test(text.substr(cursorPos));
      if (onLastLine) {
        // Sometimes the window does not scroll up completely, leaving the
        // bottom of the draft hidden
        this.messagesDraftScrollPending = true;
      }
    }
  };

  handleThreadScroll = () => {
    if (this.state.displayState.activeThreadVisible) {
      this.updateUnreadMessageRegions();
    }
  };

  handleThreadSelect = (tid) => (e) => {
    e.preventDefault();
    this.finalizeActiveThread();
    this.setState((s0) => {
      let displayState = s0.displayState;
      // if (!displayState.splitView) {
      displayState = {
        ...s0.displayState,
        activeThreadVisible: true,
        activeThreadLastVisibleFS: this.props.isFullscreen,
      };
      // }
      const t = s0.threads[tid];
      return {
        displayState,
        activeTid: tid,
        phase: "inputting", //t.draft !== null ? 'inputting' : 'viewing',
        value: t.draft || "",
      };
    });
  };

  handleHideActiveThread = (e) => {
    e.preventDefault();
    this.setState((s0) => {
      const displayState = {
        ...s0.displayState,
        activeThreadVisible: false,
      };
      return { displayState };
    });
  };

  handleNewBadgeClick = (e) => {
    e.preventDefault();
    if (0 < this.unviewedMessageRegions.length) {
      this.scrollUnviewedMessageToTop(this.unviewedMessageRegions[0].msg.id);
    }
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.refreshMessages();
  };

  handleSend = (e) => {
    e.preventDefault();
    this.setState({ phase: "inputting" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ phase: "submitting" });
    this.sendMessage();
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.ctx_window.simpleConfirm(
      messages.mkChatCancelConfirm(),
      this.handleCancelResponse
    );
  };

  handleCancelResponse = (allow) => {
    if (allow) {
      this.setState({
        value: "",
        phase: "viewing",
      });
      this.deleteDraft();
      this.initDraftState();
    }
  };

  setMessagesDraftRef = (r) => {
    this.messagesDraftElt = ReactDOM.findDOMNode(r);
  };

  setMessageRef = (msg) => (r) => {
    if (!msg.viewed) {
      this.unviewedMessageElts[msg.id] = ReactDOM.findDOMNode(r);
    }
  };

  setDraftRef = (r) => {
    this.draftR = r;
    this.draftElt = ReactDOM.findDOMNode(r);
  };

  // FIXME tooltip remains visible when switch from reply to send reply if
  // they are two separate buttons
  renderButton = ({
    glyph,
    text,
    tip,
    bsStyle,
    hidden = false,
    disabled = false,
    style,
    ...restProps
  }) => (
    <OverlayTooltip
      tip={tip}
      id={`${tip}-tip`}
      placement="left"
      disabled={hidden || disabled}
    >
      <Button
        iconOnly={glyph && true}
        variant="link"
        style={hidden ? { visibility: "hidden" } : style}
        disabled={hidden || disabled}
        {...restProps}
      >
        {glyph ? glyph: null}
        {text}
      </Button>
    </OverlayTooltip>
  );

  renderTableControls = () => (
    <div className="chat-controls">
      {this.renderButton({
        glyph: <IconRefresh />,
        tip: "Get latest messages",
        style: { fontSize: "30px" },
        onClick: this.handleRefresh,
      })}
    </div>
  );

  renderUnviewedCount = () => {
    const n = this.state.unviewedCount;
    const tip = `${n} new message${n === 1 ? "" : "s"}`;
    const style = n === 0 ? { display: "none" } : { cursor: "pointer" };
    return (
      <OverlayTooltip
        tip={tip}
        id="new-tip"
        placement="left"
        trigger={["hover"]}
        disabled={n === 0}
      >
        <Badge
          className="chat-thread-new-badge"
          style={style}
          onClick={n === 0 ? null : this.handleNewBadgeClick}
        >
          NEW
        </Badge>
      </OverlayTooltip>
    );
  };

  renderActiveThreadControls = () => {
    const inputting = true; //this.state.phase === 'inputting';
    
    return (
      <div className="chat-msg-controls">
        {this.renderUnviewedCount()}
        {this.isSingleThreaded() &&
          this.renderButton({
            glyph: <IconRefresh />,
            tip: "Get latest messages",
            onClick: this.handleRefresh,
          })}
        {this.renderButton({
          hidden: !inputting || this.state.value.length === 0,
          glyph: <IconCross />,
          tip: "Cancel",
          // variant: "danger",
          onClick: this.handleCancel,
        })}

        {this.renderButton({
          disabled: inputting && this.state.value.length === 0,
          glyph: inputting ? <FontAwesomeIcon icon={faPaperPlane} /> : "pencil",
          tip: inputting ? "Send" : "Compose",
          bsStyle: inputting ? "primary" : null,
          type: inputting ? "submit" : "button",
          style: {
            backgroundColor:
              inputting && this.state.value.length === 0 ? "white" : "",
          },
          onClick: inputting ? null : this.handleSend,
        })}
        {/*
           * FIXME return to using two buttons once OverlayTooltip is fixed
          inputting ?
            this.renderButton({
              disabled: this.state.value.length === 0,
              glyph:   'share-alt',
              tip:     'Send',
              bsStyle: 'primary',
              type:    'submit'
            }) :
            this.renderButton({
              glyph:   'pencil',
              tip:     'Compose',
              onClick: this.handleSend,
            })
            */}
      </div>
    );
  };

  renderThreadTable = (now) => {
    if (this.props.hidden || this.isSingleThreaded()) {
      return null;
    }

    const { activeThreadVisible, splitView } = this.state.displayState;
    if (!splitView && activeThreadVisible) {
      return null;
    }

    return (
      <div className="chat-table-frame">
        {/* {this.renderTableControls()} */}
        <div className="chat-table-flex-wrapper">
          <ThreadTable
            className="chat-table"
            threads={this.state.threads}
            activeTid={this.state.activeTid}
            unviewedCount={this.state.unviewedCount}
            phase={this.state.phase}
            onThreadSelect={this.handleThreadSelect}
            now={now}
            narrow={!this.state.displayState.splitView}
            showMember={this.props.memberId == null}
            showPreview={true}
            refreshButton={this.renderTableControls()}
            theme={this.props.theme}
          />
        </div>
      </div>
    );
  };

  renderActiveThreadHeader = () => {
    const { threads, activeTid } = this.state;
    const { splitView } = this.state.displayState;
    if (activeTid === null) {
      return null;
    }
    const thread = threads[activeTid];
    return (
      <div className="chat-thread-header">
        {!splitView && !this.isSingleThreaded() && (
          <div onClick={this.handleHideActiveThread} className="back-btn">
            {/* <img src={backBtn} alt="" /> &nbsp;&nbsp; */}
            <span>All Messages</span>
          </div>
        )}
        <div className="chat-thread-header-title">
          <h3>{thread.moduleName}</h3>
        </div>
        <div className="chat-thread-header-curriculum">
          {/* <h4>Curriculum</h4> */}
          <h5>{thread.curriculumName}</h5>
        </div>
      </div>
    );
  };

  renderActiveThread = (now) => {
    if (this.props.hidden || !this.state.activeTid) {
      return null;
    }
    const { activeThreadVisible } = this.state.displayState;
    const skipHeader = false; //this.isSingleThreaded() || splitView;
    const className = [
      "chat-thread-frame",
      activeThreadVisible ? null : "chat-thread-frame-hidden",
    ].join(" ");
    const inputting = this.state.phase === "inputting";
    if (activeThreadVisible && inputting) {
      this.draftEverFocused = true;
    }
    return (
      <div className={className}>
        {!skipHeader && this.renderActiveThreadHeader()}
        <div
          className="chat-thread-messages-draft"
          onScroll={this.handleThreadScroll}
          ref={this.setMessagesDraftRef}
        >
          {/* <PerfectScrollbar> */}
          <div className="chat-thread-msg-list">
            <ThreadMessages
              className="chat-thread-messages"
              threads={this.state.threads}
              activeTid={this.state.activeTid}
              now={now}
              messagePaddingBottom={this.messagePaddingBottom}
              setMessageRef={this.setMessageRef}
            />
          </div>
          {/* </PerfectScrollbar> */}
        </div>
        <form
          className="chat-thread"
          id="chat-thread-form"
          onSubmit={this.handleSubmit}
        >
          <div className="chat-thread-msg-input">
            {
              //this.state.phase === 'inputting' && (
              <ElasticTextArea
                className="chat-thread-draft"
                value={this.state.value}
                maxLength={config.maxChatMessageLength}
                placeholder={config.chatMessagePlaceholder}
                onChange={this.handleDraftChange}
                onResize={this.handleDraftResize}
                form="chat-thread-form"
                autoFocus={activeThreadVisible}
                ref={this.setDraftRef}
              />
            }
            {this.renderActiveThreadControls()}
          </div>
        </form>
      </div>
    );
  };

  render = () => {
    const className = [
      "chat",
      this.state.displayState.splitView ? "chat-split-view" : null,
    ].join(" ");
    const now = new Date();
    return (
     
      <div className="chat-main-div">
        <div className={className}>
          {this.state.loading ? 
            <div className="chat-loader-div"><div className={className}>
              <Loader active className="chat-loader"  /></div> </div>: 
            this.renderThreadTable(now)}
          {this.renderActiveThread(now)}
        </div>
      </div>
      
    );
  };
}

export default withWindowContext(withApiContext(withSocketContext(withUserContext(Chat))));
