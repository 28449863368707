import React, { Component } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import {
  withWindowContext,
  withApiContext,
  withLabelsContext,
  withUserContext,
} from "context";
import "./QuestionAnswerStats.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./StarRating.css";
import MediaQuery from 'react-responsive';
import styled from "styled-components";


const isMobile = window.innerWidth <= 768;

// const options = {
//   aspectRatio: 2,
//   responsive: true,
//   plugins: {
//     datalabels: {
//       formatter: (value, context) => {
//         const total = context.dataset.data.reduce((a, b) => a + b, 0);
//         const percentage = (value / total) * 100;
//         //return percentage + "%";
//         //return Math.round(percentage) + "%";
//         const newValue = Math.round(percentage) + "% (" + value + ")";
//         return newValue;

//         // const data = context.dataset.data;
//         // const total = data.reduce((a, b) => a + b, 0);
//         // const percentage = (value / total) * 100;
//         // const points = parseFloat(surveyData.answer_points[context.dataIndex].points);
//         // const optionCount = this.props.surveyData.answer_points[context.dataIndex].option_count;
//         // return `${Math.round(percentage)}% (${points}, ${optionCount})`;
//       },
//       color: "#FBFBFB",
//       font: {
//         size: "15px",
//         // weight: "bold",
//       },
//     },
//     legend: {
//       display: false,
//       position: "right",
//       labels: {
//         usePointStyle: true,
//         pointStyle: "circle",
//         boxWidth: 55,
//         boxHeight: 55,
//       },
//     },
//   },
// };

const divStyles = {
  width: '300px',
  height: '400px',
  backgroundColor: 'white',
};

const divStylesRsp = {
  width: '300px',
  height: '20px',
  backgroundColor: 'white',
  marginTop: "0px"
};

const questionStyle = {
  color: "#3DAEDA",
  fontWeight: "700",
  fontSize: "14px",
  fontFamily: "Poppins",
  whiteSpace: "nowrap",
  marginRight: "10px",
};

const questionDataStyle = {
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  display: "inline-block",
  maxWidth: "90%",
  fontWeight: "400"
};

const questionDataStyle1 = {
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  display: "inline-block",
  maxWidth: "40%",
  fontWeight: "400"
};

const questionStarDataStyle = {
  display: "inline-block",
  marginLeft: "15px",
  color: "black",
  fontSize: "18px",
  whiteSpace: "nowrap",
  marginTop: "16px",
};

const answerDataStyle = {
  display: "inline-block",
  marginLeft: "65px",
  color: "black",
  fontSize: "14px",
  paddingTop: "30px",
};

const ResponseStyle = {
  display: "inline-block",
  marginLeft: "62px",
  color: "#D9E6EE",
  marginTop: "2px",
  marginBottom: "20px",
  fontSize: "15px",
};

const cardStyles = {
  width: '1190px',
  height: '300px',
  position: 'relative',
  top: '70px',
  left: '20px',
  marginLeft: '-180px'
};

const cardStylesRsp = {
  width: '220px',
  height: '310px',
  position: 'relative',
  top: '-390px',
  left: '120px',
  marginLeft: '-120px',
  border: "none"
};

const StyledWrapper = styled.h5`
  color: ${({ theme }) => theme.primary};
 
  }
`;


function generateColors(numOptions) {
  const colors = [];
  for (let i = 0; i < numOptions; i++) {
    const hue = (i * 137.5) % 360;
    const color = `hsl(${hue}, 70%, 50%)`;
    colors.push(color);
  }
  return colors;
}


class QuestionnaireChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      busy: false,
      labelsData: [],
      dataItem: [],
      activePage: 0,
      showRemainingChoices: false,
    };
  }

  options = () => {
    const isMobile = window.innerWidth < 576;
    const fontSize = isMobile ? 11 : 15;
    return (
      {
        aspectRatio: 2,
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              console.log(value)
              console.log(context)
              const total = context.dataset.data.reduce((a, b) => a + b, 0);
              const percentage = (value / total) * 100;
              //return percentage + "%";
              //return Math.round(percentage) + "%";
              // const newValue = Math.round(this.props.surveyData?.answers[context?.dataIndex]?.points) + "% (" + this.props.surveyData?.answers[context?.dataIndex]?.option_count + ")";
              // return newValue;

              const points = this.props.surveyData?.answers[context?.dataIndex]?.points;
              const optionCount = this.props.surveyData?.answers[context?.dataIndex]?.option_count;

              if (points > 0 && optionCount > 0) {
                const newValue = Math.round(points) + "% (" + optionCount + ")";
                return newValue;
              } else {
                return null; // or any other value you want to return when points or optionCount is not greater than zero
              }


              // const data = context.dataset.data;
              // const total = data.reduce((a, b) => a + b, 0);
              // const percentage = (value / total) * 100;
              // const points = parseFloat(surveyData.answer_points[context.dataIndex].points);
              // const optionCount = this.props.surveyData.answer_points[context.dataIndex].option_count;
              // return `${Math.round(percentage)}% (${points}, ${optionCount})`;
            },
            color: "#FBFBFB",
            font: {
              size: fontSize + "px",
              // weight: "bold",
            },
          },
          legend: {
            display: false,
            position: "right",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              boxWidth: 55,
              boxHeight: 55,
            },
          },
        },
      }
    )
  }

  componentDidMount = () => {
    console.log(this.options());
  }

  nextPage = () => {
    this.setState((prevState) => ({
      activePage: prevState.activePage + 1,
    }));
  };

  prevPage = () => {
    this.setState((prevState) => ({
      activePage: prevState.activePage - 1,
    }));
  };

  toggleRemainingChoices = () => {
    this.setState((prevState) => ({
      showRemainingChoices: !prevState.showRemainingChoices,
    }));
  };

  render = () => {
    const { surveyData } = this.props;
    const { activePage } = this.state;
    const { showRemainingChoices } = this.state;
    const visibleChoices = showRemainingChoices
      ? surveyData.answers
      : surveyData.answers.slice(0, 4);

    // const questions = Object.values(surveyData).filter(
    //   (item) => item.survey_question_type === 7
    // );

    //const currentQuestion = questions[activePage];
    const currentQuestion = surveyData;
    const currentQuestionId = currentQuestion
      ? currentQuestion.survey_question_id
      : null;
    const Colors = ['#3498DB', '#2ECC71', '#9B59B6', '#E67E22', '#E74C3C', '#F1C40F', '#008080', '#FFC0CB']
    const data = {
      labels: Object.keys(surveyData.answer_points[0]),
      datasets: [
        {
          data:
            surveyData.survey_question_type === 7
              ? Object.values(surveyData.answer_points[0]).map((value) =>
                parseFloat(value)
              )
              : surveyData.survey_question_type === 0
                ? surveyData.answers
                  // .filter((answer) => answer.points > 0)
                  .map((answer) => answer.points)
                : null,
          backgroundColor: ["#1273DE", "#DC4C64", "#9C27B0", "#5DA609"],
          hoverBackgroundColor: ["#1273DE", "#DC4C64", "#9C27B0", "#5DA609"],
          borderColor: 'transparent',
        },


      ],
    };

    if (surveyData.survey_question_type === 0) {
      const numOptions = surveyData.answers.length;
      const predefinedColors = ['#3498DB', '#2ECC71', '#9B59B6', '#E67E22', '#E74C3C', '#F1C40F', '#008080', '#FFC0CB'];
      //const colors = generateColors(numOptions);
      data.labels = surveyData.answers.map(
        (answer) => answer.survey_multiple_choice
      );
      data.datasets[0].backgroundColor = predefinedColors;
      data.datasets[0].hoverBackgroundColor = predefinedColors;
    }

    return (
      <>
        <MediaQuery maxWidth={575}>

          {/* <Card style={cardStylesRsp}> */}
          <div >
            {surveyData.survey_question_type === 0 && (
              <React.Fragment>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <h5
                    style={{
                      ...questionStyle,
                      marginLeft: "0px",
                      marginBottom: "8px",
                      marginTop: "0px",
                      paddingLeft: "0px",
                      border: "none",
                      flex: "0 0 auto",
                    }}
                  >
                    <StyledWrapper>
                      QN{" "}{currentQuestionId}
                    </StyledWrapper>
                  </h5>
                  <h5
                    style={{
                      ...questionDataStyle,
                      marginLeft: "15px",
                      flex: "1",
                    }}
                  >
                    {surveyData && surveyData.survey_question_name}
                  </h5>
                </div>

                <h5
                  style={{ ...ResponseStyle, fontSize: "14px", marginTop: "10px" }}
                >

                  {"  "}
                </h5>

                <Row>
                  <Col>
                    <div
                      style={{
                        width: "300px",
                        height: "300px",
                        marginBottom: "-200px",
                        marginTop: "-100px",
                        marginLeft: "-68px",
                      }}
                    ><div style={{ marginTop: '80px' }}>
                        <Pie data={data} options={this.options()} plugins={[ChartDataLabels]} />
                      </div>
                    </div>
                  </Col>

                  <div style={{ marginLeft: "160px", marginTop: "-100px" }}>
                    <Col style={{ marginLeft: "40px", marginTop: "30px", borderLeft: "1px solid #0F83BC" }}>
                      <div>
                        {visibleChoices.map((point, index) => (
                          <React.Fragment key={index}>
                            <span
                              className={`circle-indicator ${Colors[index % Colors.length]}`}
                              style={{ backgroundColor: Colors[index % Colors.length] }}
                            ></span>
                            <span className="choice-text" style={{ ...questionDataStyle }}>
                              {point.survey_multiple_choice}
                            </span>
                            {(index + 1) % 1 === 0 && (
                              <>
                                <br />
                              </>
                            )}
                          </React.Fragment>
                        ))}

                        {surveyData.answers.length > 4 && (
                          <Button style={{ fontSize: '10px', padding: '3px 6px' }}
                            onClick={this.toggleRemainingChoices}>
                            {showRemainingChoices ? 'Show Less' : 'Show More'}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </div>
                </Row>
              </React.Fragment>
            )}

            {/* </Card> */}
          </div>
        </MediaQuery>

        <MediaQuery minWidth={576} maxWidth={820}>
          <div >
            {surveyData.survey_question_type === 0 && (
              <React.Fragment>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <h5
                    style={{
                      ...questionStyle,
                      marginLeft: "0px",
                      marginBottom: "8px",
                      marginTop: "0px",
                      paddingLeft: "0px",
                      border: "none",
                      flex: "0 0 auto",
                    }}
                  >
                    <StyledWrapper>
                      QN {currentQuestionId}
                    </StyledWrapper>
                  </h5>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flex: '1' }}>
                    <h5
                      style={{
                        ...questionDataStyle,
                        marginLeft: "15px",
                        flex: "1",
                      }}
                    >
                      {surveyData && surveyData.survey_question_name}
                    </h5>
                  </div>
                </div>


                <h5
                  style={{ ...ResponseStyle, fontSize: "14px", marginTop: "10px" }}
                >

                  {"  "}
                </h5>

                <Row>
                  <Col>
                    <div
                      style={{
                        width: "300px",
                        height: "300px",
                        marginBottom: "-200px",
                        marginTop: "-100px",
                        marginLeft: "-68px",
                      }}
                    ><div style={{ marginTop: '80px' }}>
                        <Pie data={data} options={this.options()} plugins={[ChartDataLabels]} />
                      </div>
                    </div>
                  </Col>

                  <div style={{ marginLeft: "160px", marginTop: "-10px", position: "absolute" }}>
                    <Col style={{ marginLeft: "40px", marginTop: "30px", borderLeft: "1px solid #0F83BC", alignItems: "center", }}>
                      <div>
                        {visibleChoices.map((point, index) => (
                          <React.Fragment key={index}>
                            <span
                              className={`circle-indicator ${Colors[index % Colors.length]}`}
                              style={{ backgroundColor: Colors[index % Colors.length] }}
                            ></span>
                            <span className="choice-text" style={{ ...questionDataStyle }}>
                              {point.survey_multiple_choice}
                            </span>
                            {(index + 1) % 1 === 0 && (
                              <>
                                <br />
                              </>
                            )}
                          </React.Fragment>
                        ))}

                        {surveyData.answers.length > 4 && (
                          <Button style={{ fontSize: '10px', padding: '3px 6px' }}
                            onClick={this.toggleRemainingChoices}>
                            {showRemainingChoices ? 'Show Less' : 'Show More'}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </div>
                </Row>
              </React.Fragment>
            )}

            {/* </Card> */}
          </div>
        </MediaQuery>


        <MediaQuery minWidth={821}>
          {/* <div style={divStyles}> */}
          {/* <Card style={cardStyles}> */}
          <div>
            {surveyData.survey_question_type === 0 && (
              <React.Fragment>
                <div style={{ display: "flex", alignItems: "flex-start",width:"222%" }}>
                  <h5
                    style={{
                      ...questionStyle,
                      fontSize: "14px",
                      marginBottom: "0px",
                      marginTop: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    <StyledWrapper>
                      QN{" "}{currentQuestionId}
                    </StyledWrapper>
                  </h5>
                  <h5
                    style={{
                      ...questionDataStyle,
                      fontSize: "14px",
                      marginLeft: "10px",
                      marginTop: "15px",
                      flex: "1", // Added this line to make the text take available space
                    }}
                  >
                    {surveyData && surveyData.survey_question_name}
                  </h5>
                </div>


                <h5
                  style={{ ...ResponseStyle, fontSize: "14px", marginTop: "10px" }}
                >

                  {"  "}
                </h5>
                <Row style={{ width: '800px', height:"auto" }}>
                  <Col style={{ marginLeft: "20px", marginTop: "10px", width: "100%", }}>
                    <div style={{
                      display: "flex", flexWrap: "wrap",
                      //justifyContent: "space-between",
                      overflowY: "auto", overflowX: "hidden", height: "200px",width:"650px"
                    }}>
                      {surveyData.answers.map((point, index) => (
                        <div key={index} style={{ width: "auto", marginRight: "-40px", marginBottom: "30px", }}>
                          <span
                            className={`circle-indicator ${Colors[index % Colors.length]}`}
                            style={{ backgroundColor: Colors[index % Colors.length] }}
                          ></span>
                          <span className="choice-text" style={{ ...questionDataStyle }}>
                            {point.survey_multiple_choice}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Col>


                  <Col className="column-ratingsPie offset-md-8" style={{ paddingTop: "20px", paddingLeft: "20px", marginTop: "-60px", position: 'absolute' }}>
                    <div style={{ ...questionDataStyle, fontSize: "16px", fontWeight: "500" }}>Progress</div>
                    <div
                      style={{
                        width: "400px",
                        height: "400px",
                        marginBottom: "-200px",
                        marginTop: "-80px"
                      }}
                    ><div style={{ marginTop: '80px', }}>
                        <Pie data={data} options={this.options()} plugins={[ChartDataLabels]} />
                      </div>
                    </div></Col>

                </Row>
              </React.Fragment>
            )}

            {surveyData.survey_question_type === 7 && (
              <React.Fragment>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5
                    style={{
                      ...questionStyle,
                      fontSize: "15px",
                      marginBottom: "8px",
                      marginTop: "16px",
                      display: "inline-block",
                    }}
                  >
                    <StyledWrapper>
                      QN{" "}{currentQuestionId}
                    </StyledWrapper>
                  </h5>
                  <h5
                    style={{
                      ...questionStarDataStyle,
                      fontSize: "16px",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                  >
                    {surveyData && surveyData.survey_question_name}
                  </h5>
                </div>

                <h5
                  style={{
                    ...ResponseStyle,
                    fontSize: "14px",
                    marginTop: "10px",
                    marginLeft: "46px",
                  }}
                >
                  {surveyData &&
                    surveyData.answers[0] &&
                    surveyData.answers[0].answers[0].response_count}{" "}
                  Responses
                </h5>
                <div
                  style={{
                    width: "400px",
                    height: "400px",
                    marginBottom: "-200px",
                  }}
                >
                  <Pie data={data} options={this.options()} plugins={[ChartDataLabels]} />
                </div>
              </React.Fragment>
            )}
          </div>
          {/* </Card> */}
          {/* </div> */}
        </MediaQuery>
      </>
    );
  };
}

export default withLabelsContext(
  withApiContext(withUserContext(QuestionnaireChart))
);
