import { rgba } from "polished";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body{
  background-color: ${({ theme }) => theme.bodyBg};
  color: ${({ theme }) => theme.color1};
}

// ========= helper
a{
  color: ${({ theme }) => theme.primary};
  &:hover{
    color: ${({ theme }) => theme.primaryDarken};
  }
}

.text-primary{
  color: ${({ theme }) => theme.primary} !important;
}
.text-primary-multi{
  color: ${({ theme }) => theme.primary} !important;
  &:hover {
    color:  ${({ theme }) => theme.button.primary.text} !important;
  }
}

.text-primary-dark{
  color: ${({ theme }) => theme.color2} !important;
}

.text-color2{
  color: ${({ theme }) => theme.color2} !important;
}

.text-color3{
  color: ${({ theme }) => theme.color3} !important;
}

.text-color4{
  color: ${({ theme }) => theme.color4} !important;
}

.fill-color2{
  fill: ${({ theme }) => theme.color2} !important;
}

.fill-color3{
  fill: ${({ theme }) => theme.color3} !important;
}

.fill-color4,
.fill-primary {
  fill: ${({ theme }) => theme.color4} !important;
}
.fill-color4,
.border-primary-multi {
  border-color: ${({ theme }) => theme.color4} !important;
  &:hover {
    background-color: ${({ theme }) => theme.color3} !important; 
  }
}
.bg-primary {
  background-color: ${({ theme }) => theme.primary} !important;
}

.bg-primary-light {
  background-color: ${({ theme }) => rgba(theme.primary, 0.1)} !important;
}

.bg-color2 {
  background-color: ${({ theme }) => theme.color2} !important;
}

.bg-color3 {
  background-color: ${({ theme }) => theme.color3} !important;
}

.bg-color4 {
  background-color: ${({ theme }) => theme.color4} !important;
}

.border-primary{
  border-color: ${({ theme }) => rgba(theme.primary, 0.5)} !important;
}

.white-container{
  flex: 1 1 auto;
  padding-top: 1.5rem; 
  padding-bottom: 1.5rem; 
  padding-left: 2.5rem; 
  padding-right: 2.5rem; 
  background-color:#FFFF;
  width: 95%;
  border-radius:7px;

  .apexcharts-svg {
    overflow: visible !important;
  }
}

// ========= Buttons 

.btn-primary {
  background-color: ${({ theme }) => theme.button.primary.background};
  border-color: ${({ theme }) => theme.button.primary.background};
  color: ${({ theme }) => theme.button.primary.text};
  
  svg{
    fill: ${({ theme }) => theme.button.primary.text};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.button.primary.backgroundHover};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${({ theme }) => theme.button.primary.backgroundHover};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
  }

  &:disabled {
    background-color: #757575;
    border-color: #757575;
  }
}
.btn-rectangle{
  font-weight:500;
  color: ${({ theme }) => theme.button.primary.text};
  border-radius: 3px;
  height: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: ${({ theme }) => theme.button.primary.background};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.button.primary.backgroundHover};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
    color: ${({ theme }) => theme.button.primary.text};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${({ theme }) => theme.button.primary.backgroundHover};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
  }

  &:disabled {
    background-color: #757575;
    border-color: #757575;
  }
}

.btn-home{
  font-weight:500;
  color: ${({ theme }) => theme.button.primary.text};
  border-color: transparent;
  border-radius: 5px;
  height: 125px;
  margin-bottom: 25px;
  width: 100%;
  display:flex;
  justify-content:center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  align-items:center;
  background-color: ${({ theme }) => theme.color3};
  transition: 0.3s;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color4};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
    color: ${({ theme }) => theme.button.primary.text};
    p{
      color: ${({ theme }) => theme.color6};
    }
    svg{
      fill: ${({ theme }) => theme.color6};
    }
  }
  
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${({ theme }) => theme.button.primary.backgroundHover};
    border-color: ${({ theme }) => theme.button.primary.backgroundHover};
  }

  &:disabled {
    background-color: #757575;
    border-color: #757575;
  }

  p{
    color: ${({ theme }) => theme.color4};
    transition: 0.3s;
  }

  svg{
    height:100%;
    width:100%;
    color: ${({ theme }) => theme.color2};
  }
}

.btn-home-white{
    font-weight:500;
    color: ${({ theme }) => theme.color2};
    border-radius: 7px;
    height: 125px;
    width:30%;
    display:flex;
    justify-content:left;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    align-items:center;
    background-color: #ffffff;
    transition: 0.3s;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.button.primary.backgroundHover};
      border-color: ${({ theme }) => theme.button.primary.backgroundHover};
      p{
          color: ${({ theme }) => theme.color6};
      }
      svg{
        fill: ${({ theme }) => theme.color6};
      }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: ${({ theme }) => theme.button.primary.backgroundHover};
      border-color: ${({ theme }) => theme.button.primary.backgroundHover};
    }
  
    &:disabled {
      background-color: #757575;
      border-color: #757575;
    }

    p{
      color: ${({ theme }) => theme.color3};
      transition: 0.3s;
    }

    svg{
      height:100%;
      width:175%;
      fill: ${({ theme }) => theme.color2};
    }
}

.btn-icon-only{
  svg{
    height: 100%;
    width: 100%;
    fill: ${({ theme }) => theme.color3};
  }
}

.btn-white {
  border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
  color: ${({ theme }) => theme.primary};
  
  svg{
    fill: ${({ theme }) => theme.primary};
  }
  
  &:hover {
    background-color: ${({ theme }) => rgba(theme.primary, 0.2)};
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
  }
  
  &:focus,
  &.focus {
    background-color: ${({ theme }) => rgba(theme.primary, 0.02)};
    border-color: ${({ theme }) => theme.primary};
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${({ theme }) => rgba(theme.primary, 0.2)};
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
  }
}

.btn-icon-grp {
  svg{
    fill: darkgrey;
  }
  
  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    svg{
      fill: ${({ theme }) => rgba(theme.primary, 0.5)};
    }
  }
  
  &:focus,
  &.focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};

    svg{
      fill: ${({ theme }) => theme.primaryDarken};
    }
    
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};

    svg{
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }
}


.btn-link{
  color: ${({ theme }) => theme.primary};
  
  svg{
    fill: ${({ theme }) => theme.primary};
  }

  &:hover{
    color: ${({ theme }) => theme.primaryDarken};
    svg{
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }
}

.btn-outline{
  background-color: transparent;
  border-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryDarken};
  
}
.btn-outline-cancel{
  background-color: transparent;
  border-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  svg{
    fill: ${({ theme }) => theme.primary};
  }
  &:hover{
    color: white;
    border-color: red;
    background-color: red;
    svg{
      fill: white;
    }
  }
}
.btn-outline-radius{
  background-color: transparent;
  border-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryDarken};
  border-radius:3px
}
.btn-noborder{
  background-color: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.primaryDarken};
  border-radius:3px
}


// ========= Card 
.card-title {
  color: ${({ theme }) => theme.color2};
}

// ====== form control
.form-control{
  border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }

  &.input-overlight {
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    background-color: ${({ theme }) => rgba(theme.primary, 0.2)};

    &:focus {
      border-color: ${({ theme }) => theme.primary};
    }
  }
}

// ====== swiper pagination
.swiper-pagination-bullet-active.swiper-pagination-bullet-active {
  background-color: ${({ theme }) => theme.primary};
}

// ====== Calendar overlay events
.rbc-overlay {
  border-radius: 10px;
  
  .rbc-event {
  background-color: ${({ theme }) => theme.primary};
}
}

// ====== Datepicker
.react-datepicker {
  font-family: 'Poppins', 'sans-serif' !important;
}

.react-datepicker-wrapper {
  display: flex;
}

.react-datepicker__day--selected, .react-datepicker__close-icon::after {
  background-color: ${({ theme }) => theme.primary};
}

.react-datepicker__day--selected:hover {
    background-color: ${({ theme }) => theme.primaryDarken};
  }

.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"] {
    border: 1px solid   ${({ theme }) => theme.primary};
    border-radius: 8px;
    padding: 2px 0 2px 2px;
`;
