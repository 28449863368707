import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import css from "./chart-doughnut.module.scss";
import classNames from "classnames";

function ChartDoughnut(props) {
  const toolTipData = props.customTooltip ? {
    // enable:false,
    // usePointStyle: true,
    displayColors: false,
    xAlign: "center",
    yAlign: "top"
  }
  :{
    // enable:false,
    // usePointStyle: true,
    displayColors: false,
  }
  const options = {
    aspectRatio: 1,
    cutout: 30,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: toolTipData
    },
    // tooltips: { bodyFontSize: 20,
    // backgroundColor:"#fff" }
  };

  return (
    <div className={classNames(css.wrapper, props.className)}>
      <div className={props.legend && css.legend_wrapper}>
        <div className={css.chart} style={{ width: props.width }}>
          <Doughnut data={props.data} options={options} />
          <div className={`${css.center} text-color4`}>
            <div>{props.center}</div>
          </div>
        </div>

        {props.legend && props.data && (
          <div className={css.legend}>
            {props.legend.map((item, ind) => (
              <div key={ind}>
                <span style={{ backgroundColor: item.color }}></span>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {props.title && (
        <div className={`${css.title} text-color3`}>{props.title}</div>
      )}
    </div>
  );
}

ChartDoughnut.propTypes = {
  center: PropTypes.any,
  cutout: PropTypes.number,
  data: PropTypes.any,
  width: PropTypes.string,
};

ChartDoughnut.defaultProps = {
  cutout: 30,
  width: "110px",
  data: {},
};

export default ChartDoughnut;
