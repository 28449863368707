import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import Recorder from "components/Player/Video/Recorder";
// import { Button } from 'react-bootstrap';
import css from "../../Question/question.module.scss";
import * as config from "config";
// import css from "../../StudentAssessments/Question/question.module.scss";
import { ButtonGroup, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import MathLive, { convertLatexToMarkup } from "mathlive";
import MathInput from "components/MathInput/MathInput"
import { Col, Row } from "react-bootstrap";
import { withApiContext, withWindowContext } from 'context';
import "jodit";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import { Button, HelpfulButton, Textarea } from "components";
import { IconAudio, IconFlag, IconPDF, IconVideo } from "Icons";
import styled from "styled-components";
import "./Comment.css";

const StyledPlayerButton = styled.span`
  && {
    .media_comment{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
    .media_comment-flag{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
  }`

class Comment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;


  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    onAnswered: PropTypes.func.isRequired,
    onAttempted: PropTypes.func.isRequired,
  };

  initState = {
    comment: "",
    submitted: false,
    hide: true,
    show: false,
    url: "",
    setupload: false,
    fileName: "",
    file: null,
    audioUrl: "",
    content_source: "",
    uploadValue: false,
    flag : this.props.flag === 1 ? true : false , 
  };



  componentDidMount = () => {
    const { user_selection } = this.props;
    if (user_selection) {
      // alert(user_selection[0])
      this.setState({
        comment: user_selection[0],
        audioUrl: user_selection[1],
        fileName: user_selection[2],
        content_source: user_selection[3],
        file: this.props.file
      });

    }
    if (user_selection && !user_selection[2]) {
      this.setState({ audioUrl: "" })
      this.props.onAnswered([
        user_selection[0],
        "",
        user_selection[2],
        user_selection[3],
      ]);
    }
    this.changeState(false)
  }

  changeState = (state) => {
    this.setState({ hide: state });
  }

  changeState = (state) => {
    this.setState({ hide: state });
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.id !== this.props.id) {
      this.setState(this.initState);
      if (newProps.user_selection) {
        this.setState({
          comment: newProps.user_selection[0], audioUrl: newProps.user_selection[1],
          fileName: newProps.user_selection[2], content_source: newProps.user_selection[3],
          file: newProps.file
        });
        if (newProps.user_selection && !newProps.user_selection[2]) {
          this.setState({ audioUrl: "" })
          newProps.onAnswered([
            newProps.user_selection[0],
            "",
            newProps.user_selection[2],
            newProps.user_selection[3],
          ]);
        }
      }

    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { onAttempted, onAnswered } = this.props;
    const response = [this.state.comment, this.state.audioUrl, this.state.fileName, this.state.content_source];
    if (
      _prevProps.id !== this.props.id &&
      this.props.math_symbol === 1
    ) {
      this.changeState(true);
      setTimeout(() => this.changeState(false), 1);
    }
    if (_prevProps.id !== this.props.id ) {
      this.setState({flag : this.props.flag === 0 ? false : true})
    }
    if (this.state.submitted !== prevState.submitted) {
     
      onAnswered(response);
      
    }
    if (this.state.comment !== prevState.comment ||this.state.fileName !== prevState.fileName ) {
    (this.state.comment.length > 0 || this.state.fileName) ? onAttempted(true) : onAttempted(false);
    }
  };

  handleChange = (data) => {
    // const {id, onAttempted} = this.props;
    this.setState({ comment: data });
    // onAttempted(id)
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    // this.setState({ submitted: true });
    this.setState((s0) => ({ submitted: !s0.submitted }));
  };

  handleClick = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  renderModalBody = () => {
    return <img src={this.props.image} />

  };

  renderImage = () => {
    const { content, media_link, content_format } = this.props;
    const questionImage =
    content ?
    content.match(/^data:image\/\w+;base64,/) !== null ?
    content 
    : media_link
    : "";
    return <img src={this.props.fromChapterInner ? this.props.content : questionImage}/>
  };
  renderMedia = () => {

    let currLink = this.props.fromChapterInner ? this.props.content : (this.props.content_source=="external" ? this.props.content: this.props.media_link);
    const currType = this.props.content ? this.props.content_format : "";
    let MediaPlayer;
    switch (currType) {
      case 1:
        MediaPlayer = Video;
        break;
      case 0:
        MediaPlayer = Audio;
        break;
      case 2:
        MediaPlayer = Pdf;
        break;
      default:
        MediaPlayer = React.Fragment;
        break;

    }
    return (
      <>
        <MediaPlayer
          className="ov"
          url={currLink}
          pdfUrl={currLink}
          mediaId="audio-comment"
        />

      </>)
  };

  handleUpload = async (acceptedFiles) => {
    const uplodingFile = acceptedFiles;
    const data = new FormData()
    data.append('file', uplodingFile);

    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "upload_file",
      args: data,
      isFileUpload: true,
    })
    if (rsp) {
      this.setState({ fileName: rsp.fileName, content_source: rsp.storageType, uploadValue: true })
    }
    const { onAnswered } = this.props;
    const response = [this.state.comment, this.state.audioUrl, this.state.fileName, this.state.content_source];
    if (response) {
      this.props.ctx_window.alert(`Uploaded successfully`)
    }
    onAnswered(response)


  };


  setRecordedData = (file, audio) => {
    this.setState({ audioUrl: audio, file, fileValue: true });
    this.setState({ submitted: true });
    const response = [this.state.comment, audio, this.state.fileName, this.state.content_source];
    this.props.onAnswered(response, file);
  };

  handleRecorderReset = () => {
    this.setState({ audioUrl: "", fileName: "" });
    const response = [this.state.comment, "", "", ""];
    this.props.onAnswered(response, null);
  }

  setUpload = (setValue) => {
    this.setState({ setupload: setValue })
    const blob = new Blob([this.state.audioUrl], {
      'type': 'audio/mp3'
    });
    const file = new File([blob], "url", { type: "audio/mpeg" });
    this.handleUpload(this.state.file);
  }

  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  joditConfig = {
    height: "250px",
    allowResizeY: false,
    hidePoweredByJodit: true,
    readonly: false,
    removeButtons:
      "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,align,size,redo-undo,clean-html,powered-by-jodit,source",// all options from https://xdsoft.net/jodit/doc/
  };

  handleMedia = () => {
    this.setState({ show: true })
  }

  handleFlagClick = () => {
    const newIsFlag = !this.state.flag;
    this.props.isFlaged(newIsFlag); // Pass data to the parent component
    this.setState({ flag: newIsFlag });
  }


  render = () => {
    const { content, media_link, content_format } = this.props;
    const questionImage =
    content ?
    content.match(/^data:image\/\w+;base64,/) !== null ?
    content 
    : media_link
    : "";
    const mediaLabel =
    this.props.content_format === 0
      ? "Audio"
      : this.props.content_format === 1
      ? "Video"
      : this.props.content_format === 2
      ? "PDF"
      : "";
    return (
    <div>
      <Modal
        animation={false}
        show={this.state.show && !this.props.isPlayerTest}
        onHide={this.handleClose}
        scrollable={true}
        centered
        size={this.props.content_format === 0 ? "sm" : "lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.content_format === 0
            ? "Audio"
            : this.props.content_format === 1
              ? "Video"
              : this.props.content_format === 2
                ? "Pdf"
                : this.props.content_format === 3
                  ? "Image"
                  : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.props.content_format === 3 && this.renderImage()}
            {this.props.content_format !== 3 && this.renderMedia()}
          </div>
        </Modal.Body>
      </Modal>

      {this.props.disabled && <div style={{ color: "red" }}>
        {this.props.disabledText}
      </div>}

      <div className={`${css.question} text-color2`}>
        <StyledPlayerButton>
          <MediaQuery minWidth={822}>
            <Row>
              {(this.props.content_format !== null && this.props.content_format !== 3) ? (
                <>
                  <Col md={10} >
                    {this.props.math_symbol === 1 ? (
                      this.state.hide ? (
                        <></>
                      ) : (
                        <>
                          QN{this.props.questionNumber}.
                          <MathInput
                            disabled={true}
                            id="commentQuestion"
                            value={this.props.question}
                          />
                        </>
                      )
                    ) : (
                      <div className='question-style'>
                        QN{this.props.questionNumber}. {this.props.question}
                      </div>
                    )}
                  </Col>
                  <Col md={2} className="d-flex justify-content-end">
                  <>
                   {   this.props.content && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_comment rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {this.props.content_format === 0 ? (
                          <IconAudio width={10} height={10} fill="white" />
                        ) : this.props.content_format === 1 ? (
                          <IconVideo width={10} height={10} fill="white" />
                        ) : this.props.content_format === 2 ? (
                          <IconPDF width={10} height={10} fill="white" />
                        ) : (
                          ""
                        )}
                      </HelpfulButton>}
                {     this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_comment-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </>
                  </Col>
                </>
              ) :
                (
                  <>
                    <Col md={11}>
                      {this.props.math_symbol === 1 ? (
                        this.state.hide ? (
                          <></>
                        ) : (
                          <>
                            QN{this.props.questionNumber}.
                            <MathInput
                              disabled={true}
                              id="commentQuestion"
                              value={this.props.question}
                            />
                          </>
                        )
                      ) : (
                        <div className='question-style'>
                          QN{this.props.questionNumber}. {this.props.question}
                        </div>
                      )}
                    </Col>
                    <Col md={1} className="d-flex justify-content-end">
                     { this.props.fromChapterInner && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_comment-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </Col>
                  </>
                )}
            </Row>

          </MediaQuery>
          <MediaQuery maxWidth={821}>
            <Row>
                <>
                  <Col md={12}>
                    {this.props.math_symbol === 1 ? (
                      this.state.hide ? (
                        <></>
                      ) : (
                        <>
                          QN{this.props.questionNumber}.
                          <MathInput
                            disabled={true}
                            id="commentQuestion"
                            value={this.props.question}
                          />
                          {(this.props.content_format !== 3 && this.props.content_format !== null && this.props.content) && (
                            <HelpfulButton
                              iconOnly
                              size="sm"
                              pill
                              className="media_comment rounded-circle" // Add the rounded-circle class
                              onClick={this.handleMedia}
                              tip = {"Click here2 to view " + mediaLabel}
                              placement = "top"
                            >
                              {this.props.content_format === 0 ? (
                                <IconAudio width={10} height={10} fill="white" />
                              ) : this.props.content_format === 1 ? (
                                <IconVideo width={10} height={10} fill="white" />
                              ) : this.props.content_format === 2 ? (
                                <IconPDF width={10} height={10} fill="white" />
                              ) : (
                                ""
                              )}
                            </HelpfulButton>)}
                           {this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_comment-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                        </>
                      )
                    ) : (
                      <>
                        <div className='question-style'>
                          QN{this.props.questionNumber}. {this.props.question}
                        </div>
                        {(this.props.content_format !== 3 && this.props.content_format !== null && this.props.content) && (
                          <HelpfulButton
                            iconOnly
                            size="sm"
                            pill
                            className="media_comment rounded-circle" // Add the rounded-circle class
                            onClick={this.handleMedia}
                            tip = {"Click here to view " + mediaLabel}
                            placement = "top"
                          >
                            {this.props.content_format === 0 ? (
                              <IconAudio width={10} height={10} fill="white" />
                            ) : this.props.content_format === 1 ? (
                              <IconVideo width={10} height={10} fill="white" />
                            ) : this.props.content_format === 2 ? (
                              <IconPDF width={10} height={10} fill="white" />
                            ) : (
                              ""
                            )}
                          </HelpfulButton>)}
                        { this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_comment-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                      </>

                    )}
                  </Col>
                </>
            </Row>
          </MediaQuery>

        </StyledPlayerButton>
      </div>
      {this.props.content_format === 3 && this.props.content && (
        <div className={this.props.isPlayerTest ? "player-image" : "test-question-image"}>
          <img src={this.props.fromChapterInner ? this.props.content : questionImage} style={{cursor:"pointer"}} alt="" onClick={this.handleClick} />
        </div>
      )}
      {(this.props.description ||this.props.question_content)&&
        <div
          dangerouslySetInnerHTML={{ __html: this.props.description || this.props.question_content }}
          className="description"
        >
        </div>}
      <Row>
        <Col lg={12} xl={12} sm={12}>
          <Form.Group>
            <JoditEditor
              value={this.state.comment}
              config={this.props.disabled ? {readonly: this.props.disabled} : this.joditConfig}
              editorRef={this.setRef}
              onBlur={this.handleSubmit}
              onChange={this.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {this.props.is_record === 1 && (
        <Row>
          <Col lg={12} xl={12} sm={12}>
            <Recorder
              onRecordingStop={this.setRecordedData}
              onReset={this.handleRecorderReset}
              value={this.state.audioUrl}
              fileValue={this.state.uploadValue}
              fileName={this.state.fileName}
              uploadingFile={this.setUpload}
              disabled = {this.props.disabled}
            />
          </Col>
        </Row>
      )}
      {!this.props.fromChapterInner && this.props.solution && (
        <Row>
                <Col md={12} >
                  <label style={{ fontWeight: 500, fontSize: "15px" }}>Solution</label>
                  <Textarea
                  className="text-area"
                    // type={Textarea}
                    readOnly={true}
                    value={this.props.solution}
                  />
                </Col>
                </Row>
              )}
        
    </div>

  );
      }
}

export default
  withApiContext(withWindowContext(Comment))