import React from "react";
import { Col, Row } from "react-bootstrap";

const Item = (props) => {
  const assessment= props.children.props.children?.props;
  let mb=''
  const screenSize = window.innerWidth<768? false:true;
  if(screenSize){
    mb='mb-4'
  }else{mb='mb-2'}
  return (
    <Col xs={12} sm={6} lg={assessment?.isAssessment ? 6 : 4 } xl={assessment?.isAssessment ? 4 : 3} className={`${props.className} ${mb}`}>
      {props.children}
    </Col>
  );
};


Item.defaultProps = {
  className: "",
  fromAssessment:""
};

const CardGrid = (props) => {
  return <Row className={props.className} style={props.isAssessmentMob && {marginLeft:"0px",marginRight:"0px", maxHeight:"450px", overflow:"auto"}}>{props.children}</Row>;
};
CardGrid.defaultProps = {
  className: ""
};
CardGrid.Item = Item;

export default CardGrid;
