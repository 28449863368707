import { withApiContext, withLabelsContext, withUserContext } from "context";
import React, { PureComponent } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { IconChevronDown, IconChevronUp } from "Icons";
// import PerfectScrollbar from 'react-perfect-scrollbar';
import "./CampusRoleSwitch.css";
import styled from 'styled-components'

const StyledDropdownMenu = styled(Dropdown.Menu)`
  background: ${({ theme }) => theme.color3};
  color: #fff;

  .dropdown-item {
    color: #fff;

    &:hover {
      background: ${({ theme }) => theme.primary};
    }
  }
`;

class CampusRoleSwitch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
    };

    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    // this.setScrollRef = this.setScrollRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  isTrainee = (props = this.props) => props.ctx_user.user.type === 1;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setMenuRef(menu) {
    this.menuRef = menu;
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      this.setState({ isSelected: false })
    }
  }

  handleSelectCampus = (orgId) => {
    const { campusList, organizationId, roleId } = this.props.ctx_user.user;
    if (`${organizationId} : ${roleId}` === orgId) {
      return null;
    }
    const campus = campusList.find((d) => d.campus_role_id === orgId);
    this.props.onOrganizationSwitch(campus);
  };

  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // this.setState({ isSelected: true });
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  render = () => {
    const { isSelected } = this.state;
    const { campusList, organizationId, roleId } = this.props.ctx_user.user;
    return (
      // <PerfectScrollbar >
      <span className="private-header-user-role-icon"  >
        <Dropdown
          ref={this.setMenuRef}
          id="userrole-dropup"
          disabled={this.isTrainee() && campusList.length < 2}
          onSelect={this.handleSelectCampus}
        >
          <Dropdown.Toggle
            as={this.CustomToggle}
            id="dropdown-custom-components"
          >
            {isSelected ? (
              <IconChevronUp
                width="12"
                height="12"
                onClick={() => this.setState({ isSelected: false })}
              />
            ) : (
              <IconChevronDown
                width="12"
                height="12"
                onClick={() => this.setState({ isSelected: true })}
              />
            )}
          </Dropdown.Toggle>

          {/* <PerfectScrollbar> */}
          <StyledDropdownMenu
            className="campusrole-scroll"
            onClick={() => this.setState({ isSelected: false })}
          >
            {[...campusList]
            .sort((a, b) => a.organization_name.localeCompare(b.organization_name))
            .map((campus, i) => {
              return (
                <Dropdown.Item
                  key={campus.campus_role_id}
                  eventKey={campus.campus_role_id}
                  onClick={() => {
                    this.setState({ isSelected: false });
                  }}
                >
                  {`${campus.organization_name} - ${campus.role_name}`}
                </Dropdown.Item>
              );
            })}
          </StyledDropdownMenu>
        </Dropdown>
      </span>
      // </PerfectScrollbar>
    );
  };

}

export default withApiContext(
  withLabelsContext(withUserContext(CampusRoleSwitch))
);
