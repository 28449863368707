import OverlayTooltip from 'components/OverlayTooltip/OverlayTooltip';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Button from './Button';
import './HelpfulButton.css';





class HelpfulButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tip:       PropTypes.node,
    tipId:     PropTypes.string.isRequired,
    placement: PropTypes.oneOf([
                 'top',
                 'bottom',
                 'left',
                 'right',
               ]),
    disabled:  PropTypes.bool.isRequired,
  };

  static defaultProps = {
    placement: 'top',
    disabled:  false,
  };

  render = () => {
    const {
      className,
      tip,
      tipId,
      placement,
      disabled,
      trigger,
      children,
      ...restProps
    } = this.props;
    const wrapperClassName = [
      'helpful-button-wrapper',
      disabled ? 'helpful-button-disabled' : null,
    ].join(' ');
    const buttonClassName = [
      className,
      disabled ? 'disabled' : null,
    ].join(' ');
    return (
      <OverlayTooltip
        tip={tip}
        id={tipId}
        placement={placement}
        trigger={trigger}
        disabled={tip == null}
      >
        <div className={wrapperClassName}>
          <Button
            className={buttonClassName}
            { ...restProps }
          >
            {children}
          </Button>
        </div>
      </OverlayTooltip>
    );
  }
}


export default HelpfulButton
