import React from "react";
import StarRating from "./StarRating";
import MultipleChoiceScale from "./MultipleChoiceScale";
import MultipleChoice from "./MultipleChoice";
// import MultipleChoice from "components/Player/TestQuestion/MultipleChoice";
import Comment from "./Comment";
import { Card, Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import css from "../../pages/Assessments/StudentAssessments/student-assessments.module.scss";
import { withApiContext, withWindowContext, withUserContext, withLabelsContext } from "context";
import { HelpfulButton } from "components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as config from "config";
import { Redirect } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { IconSurveySave, IconSurveySubmit, IconSurveyCancel } from "Icons";

const StudentResponse = (props) => {
  const [survey, setSurvey] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [surveyId, setsurveyId] = useState("")
  const history = useHistory();
  const [assessmentId, setAssessmentId] = useState(0)
  const [modified, setModified] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const [hasFetched, setHasFetched] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (props.fromSurvey && !hasFetched) {
      fetch(props);
      setHasFetched(true);
    }
  
    if (selectedSurvey.questions) {
      const isModified = selectedSurvey.questions.some(question => question.user_selection);
      setIsModified(isModified);
    }
  
    if (selectedSurvey.questions) {
      const modified = selectedSurvey.questions.every(question => {
        if (question.user_selection) {
          if (question.questionType === "0") {
            return question.user_selection.selected_id.length > 0;
          } else if (question.questionType === "1") {
              return question.user_selection.comment.length > 0;
          } else {
              return true;
        }
        } else {
          return false; 
        }
      });
      if (modified) {
        setModified(true);
      }
      if (!modified) {
        setModified(false);
      }
    }
  }, [props.fromSurvey, selectedSurvey]);
  

  const fetch = async (action) => {
   
    // setIsLoading(true);
    await props.ctx_api
      .privateRequest({
        cmd: "get_survey_attended_details",
        args: props.survey?.id?{
            publishSurveyId : props.survey.id.toString(),
            memberId : props.ctx_user.user.id
        }:{
          coursePublishId : props.survey.course_publish_id.toString(),
          memberId : props.ctx_user.user.id
      },
      })
      .then(handleFetchResponse)
      .catch(handleFetchFailure);
  };
  const handleFetchResponse = (rsp) => {
    setSurvey(rsp.rsp[0].survey_details)
    setAssessmentId(rsp.rsp[0].survey_assessment_id)
    setSurveyName(rsp.rsp[0].survey_details[0].survey_name)
    setSelectedSurvey(rsp.rsp[0].survey_details[0].survey[0]);
    setsurveyId(rsp.rsp[0].survey_id)
    return null;
  };

  const handleFetchFailure = (err) => {
      console.log(err);
  };

  const questionDataChange = (answers) => {
    setSelectedSurvey(answers);
  };

  const handleCancel = () => {
    setRedirect(true)
  }

  const handleDisable = (changedArray) => {
    const answers = changedArray.map((r) => {
      return r.user_selection;
    });
    const haveElements = answers.every((array) => array.length > 0);
    return haveElements;
  };


  const handleClick = (item) => {
    let args = {
      memberId: props.ctx_user.user.id,
      publishSurveyId: props.survey.id.toString(),
      surveyId: surveyId,
      action : item,
      surveyAssessmentId: assessmentId,
      surveyDetails : JSON.stringify(survey.map((sec, i) => {
        return {
          survey_id : sec.survey_id,
          description: sec.description,
          survey_name : sec.survey_name,
          survey_short_name : sec.survey_short_name,
          survey: sec.survey.map((s,i) => {
            return {
              questions: selectedSurvey.questions,
              sectionName: selectedSurvey.sectionName,
              sectionOrder: selectedSurvey.sectionOrder
            };
          }),
        };
      })
      )
      };
    props.ctx_api
      .privateRequestRow({
        cmd: "insert_survey_assessment",
        args: args,
      })
      .then(({ rsp }) => {
        if (rsp.statusvalue === 1) {
          const value = item == "save" ? "Saved" : "Submitted"
          props.ctx_window.alert(`Survey ${value} successfully`);
          setRedirect(true)
          // handleData()
          // props.fromSurvey ? 
          
          // redirect&&
          // <Link
          //     to={{
          //         pathname: config.ROUTES.studentSurvey,
          //         state: { survey: survey} 
          //         }}/> 
          //         : props.handleSubmitRef();  
        } else {
          props.ctx_window.alert("Failed to submit");
        }
      });
      

  };
  const redirecting = () => {
    
    const flag=props.from==="course"
    // history.push("/content");
    let redirect;
  
    if (flag||props.from===undefined) {
     


      redirect = (
        <Redirect
          to={{
            pathname: props.survey.origin?props.survey.origin:"/content",
            subjectData: props.subjectData,
            state: {
              location: props.location,
              subjectData: props.subjectData,
              courseId: props.courseId,
              coursePublishId: props.coursePublishId,
              grouped: props.grouped,
              fromSurvey: true,
            },
          }}
        />
      );
    } else if (props.from === "survey") {
      redirect = (
        <Redirect
          to={{
            pathname: "/StudentSurvey",
            state: {
              filter: props.filter,
            },
          }}
        />
      );
    } else {
      redirect = null;
    }
  
    
  
    return redirect;
  };
  const screenSize = window.screen.width
  return redirect ? (redirecting()) :(
        //   <Loader active width={20}>
        //   </Loader>
        
    selectedSurvey &&
    (
      <div>
        <Col style={{paddingTop : "1rem"}}>
          <div>
          <Card.Body className="card_button">
                <div className="survey-question-text">{surveyName}</div>
                <div>{
                  props.survey?.completed||props.survey?.status==="Attended"?null:
                  <>
                  <HelpfulButton
                     variant="primary"
                     size="sm"
                     pill
                     wide
                     style={{borderRadius:"5px", marginRight:"5px",marginBottom :"0px"}}
                     className={css.save}
                     // tip={
                     //   !disable
                     //     ? "One or more fields are incomplete"
                     //     : "Submit Survey"
                     // }
                     disabled={!isModified || props.survey.status == "Attended"}
                     onClick={() => handleClick("save")}
                   >
                     <IconSurveySave style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }}/>
                     <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.save}</span>
                     {/* {window.screen.width > 822 ? props.ctx_labels.singular.save : null} */}
                   </HelpfulButton>
                   <HelpfulButton
                     variant="success"
                     size="sm"
                     pill
                     wide
                     style={{borderRadius:"5px", marginRight:"5px",marginBottom :"0px"}}
                     className={css.submit}
                     // tip={
                     //   !disable
                     //     ? "One or more fields are incomplete"
                     //     : "Submit Survey"
                     // }
                     disabled={!modified || props.survey.status == "Attended"}
                     onClick={() => handleClick("submit")}
                   >
                     <IconSurveySubmit style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }}/>
                     <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.submit}</span>
                   </HelpfulButton>
                  
                  
                  </>}
                 
                  <HelpfulButton
                    variant="danger"
                    size="sm"
                    pill
                    wide
                    style={{borderRadius:"5px", marginRight:"5px",marginBottom :"0px"}}
                    className={css.cancel}
                    // tip={
                    //   !disable
                    //     ? "One or more fields are incomplete"
                    //     : "Submit Survey"
                    // }
                    // disabled={props.survey.status === "Attended"}
                    onClick={handleCancel}
                  >
                    <IconSurveyCancel style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }}/>
                    <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.cancel}</span>
                  </HelpfulButton>
                </div>
            </Card.Body>
            <Card className="questioncard">
              <Card.Body>
                {/* <div className="survey-question-text">{surveyName}</div> */}
                {selectedSurvey && selectedSurvey.questions && selectedSurvey.questions.map((section, i) => {
                    const lastIndex = selectedSurvey.questions.length - 1;
                    const isAttended = props.survey && props.survey.status === "Attended";
                     return( 
                <div key={i} className={isAttended ? (i === lastIndex ? "last_index1" : "view-survey")  : (i === lastIndex ? "last_index" : "attend-survey") }>
                {selectedSurvey.questions[i].questionType == 1 &&
                 <div style={isAttended ? {pointerEvents:"auto"} : null }>
                    <Comment question = {selectedSurvey.questions[i].question}
                    questionOrder ={selectedSurvey.questions[i].question_order}
                            response={true}
                             onAnswered ={questionDataChange}
                             surveyDetails = {selectedSurvey}
                             
                             comment ={selectedSurvey.questions[i].user_selection && selectedSurvey.questions[i].user_selection.comment}
                             >
                    </Comment>
                    </div>  
                }
                {selectedSurvey.questions[i].questionType == 2 &&
                  // <div className={isAttended ?"element-selector-disable" : null}>                    
                  <StarRating question = {selectedSurvey.questions[i].question}
                            questionOrder ={selectedSurvey.questions[i].question_order}
                             response={true}
                             displayType={selectedSurvey.questions[i].ratingOptionType == "three"? 3 : 4}
                             starRating={[]}
                             starRatings={selectedSurvey.questions[i].choices}
                             onAnswered ={questionDataChange}
                             surveyDetails = {selectedSurvey}
                             userSelection={selectedSurvey.questions[i].user_selection}
                             isAttended>
                    </StarRating>
                    // </div>
                }
                {selectedSurvey.questions[i].questionType == 0 && selectedSurvey.questions[i].multipleChoiceType == "scale" &&
                // <div className={isAttended ?"element-selector-disable" : null}>                    
                 <MultipleChoice
                    question = {selectedSurvey.questions[i].question}
                    questionOrder ={selectedSurvey.questions[i].question_order}
                    choices = {selectedSurvey.questions[i].choices}
                    answers = {selectedSurvey.questions[i].choices}
                    onAnswered ={questionDataChange}
                    response={true}
                    surveyDetails = {selectedSurvey}
                    userSelection={selectedSurvey.questions[i].user_selection}
                    isAttended
                    >
                   {/* {React.createElement(questionClass, surveyProps)} */}
                    </MultipleChoice>
                // </div>
                }
                {selectedSurvey.questions[i].questionType == 0 && selectedSurvey.questions[i].multipleChoiceType == "dropdown" &&
                //  <div className={isAttended ?"element-selector-disable" : null}>
                    <MultipleChoiceScale
                    question = {selectedSurvey.questions[i].question}
                    questionOrder ={selectedSurvey.questions[i].question_order}
                    choices = {selectedSurvey.questions[i].choices}
                    answers = {selectedSurvey.questions[i].choices}
                    onAnswered ={questionDataChange}
                    response={true}
                    surveyDetails = {selectedSurvey}
                    userSelection={selectedSurvey.questions[i].user_selection}
                    isAttended
                    >

                    </MultipleChoiceScale>
                // </div>
                } 
                </div>

                     ) 
                })} 
                
                
                {/* <div>{React.createElement(questionClass, surveyProps)}</div> */}
              </Card.Body>
            </Card>
            
            
          </div>
          
        </Col>
      </div>
    )
  );
};
export default withApiContext(withWindowContext(withUserContext(withLabelsContext(StudentResponse))));
