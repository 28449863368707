import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// import MediaQuery from "react-responsive";
import "./Html.css";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import classNames from "classnames";

class Html extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    module: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
  };

  componentDidMount = () => {
    // Allow the user to indicate when he is done with the content
    this.props.onAllViewed();
  };

  componentWillUnmount = () => {
    // this.reportModuleProgress();
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
  };

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: 0,
        maxViewedPos: 0,
      });
    }
  };

  renderControls = () => (
    <div className={classNames(`${cmCss.toolbar_mob} bg-color2 text-white`)}>
      <div className={cmCss.toolbar_left}>&nbsp;</div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );
  renderControlsMob = () => (
    <div className={classNames(`${cmCss.toolbar_mob} bg-color2 text-white`)}>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div></div>
    );

  render = () => {
    const m = this.props.module;
    const blanked = this.props.minPos === this.props.maxPos;
    const screenSize = window.innerWidth<768? false:true;
    return (
      <div
        className={[
          // "html",
          this.props.isFullscreen ? "html-fullscreen" : "html-window",
          this.props.className,
        ].join(" ")}
      >
        {/* <MediaQuery minDeviceWidth={768}> */}
        <div className="html-frame">
          <iframe src={m.url} title={m.name} allowFullScreen={false} />
          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        {/* </MediaQuery> */}

        {/* <MediaQuery maxDeviceWidth={767}>
        <div className="html-frame">
          <iframe src={m.url} title={m.name} allowFullScreen={false} />
          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        </MediaQuery> */}
         {screenSize ? this.renderControls() : this.renderControlsMob()}
      {/* {this.renderControlsMob()} */}
      </div>
    );
  };
}

export default Html;
