import * as config from "config";
import {
  withApiContext,
  withLabelsContext,
  withWindowContext,
  withUserContext,
} from "context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import './QuestionForm.css';
import { Collapse, Row, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import { padding, rgba } from "polished";
import { IconTrash, IconPlus, IconPencil } from "Icons";
//import {Button , InputField } from "components"
import {
  Button,
  InputField,
  Select,
  ToggleButtonGroup,
  Textarea,
} from "components";
import FormField from "../../components/FormField";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MultipleChoiceAnswers from "./MultipleChoiceAnswers";
import RatingQuestionAnswers from "./RatingQuestionAnswers";
const SectionItmHeader = styled.div`
  && {
    background-color: ${({ theme }) => rgba(theme.color4, 0.5)};
  }
  .add-section-btn {
    fill: ${({ theme }) => rgba(theme.color2, 1)};
  }
`;
const StyledCol = styled(Col)`
  border: 0.5px solid ${({ theme }) => theme.color4};
  border-radius: 10px;
  width:100%
    }
`;
class QuestionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sections: props.value,
      origImage: "",
      question:"",
      questionType: 0 ,
      choiceType: 0,
      multipleChoiceType:0,
    };
    // this.token = -1.0;
  }

  initSection = (s = 1, pid="Section") => ({
    question: "",
    choices:[],
    question_order: s,
    questionType:"0",
    multipleChoiceType : "scale",
    answerType: "0",
    ratingOptionType: "",
    open: true,

  });

  handleSectionAdd = (e) => {
    e.stopPropagation();
    if (this.props.value) {
      const questionSection = this.props.value.map((ch) => {
        console.log(ch);
        const newseq = ch.questions[ch.questions.length - 1].question_order + 1;
        const section = [...ch.questions, this.initSection(newseq)];
        return {
          ...ch,
          questions: section, 
        };
      });
      this.props.onChange(questionSection);
      this.setState({sections : questionSection });
    }
  };

  handleSectionDelete = (seq) => (e) => {
    e.stopPropagation();
    if (this.props.value) {
      const curValue = this.props.value;
      const questionSection = curValue.map((section) => {
        if (section.questions) {
          const updatedQuestions = section.questions
            .filter((_, i) => i !== seq)
            .map((question, i) => ({
              ...question,
              question_order: i + 1, // Update question_order
            }));
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
      this.props.onChange(questionSection);
      this.setState({ sections: questionSection });
    }
  };

  dbdropSec = (result) => {
    if (result.destination !== null) {
      const curValue = [...this.props.value];
      const seq = result.source.index;
      const selectedItem = curValue[0].questions[seq];
      curValue[0].questions.splice(seq, 1);
      const destIndex = result.destination.index;
      curValue[0].questions.splice(destIndex, 0, selectedItem);

      // Update the question_order for each item
      const originalOrder = curValue[0].questions.map((item, i) => i + 1);
      curValue[0].questions = curValue[0].questions.map((item, i) => {
        return { ...item, question_order: originalOrder[i] };
      });
      this.props.onChange(curValue);
      this.setState({ sections: curValue });
    }
  }
  

  renderSections = () => {
    const modalOpen = this.state.sections
      .map((m) => {
        console.log(m)
        return m.questions.map((r) => {
          console.log(r)
          return r.open ? r.open : false;
        })
      })
      .includes(true);
    return (
      <DragDropContext 
      onDragEnd={(result) => this.dbdropSec(result)}
      >
            <Droppable droppableId={`session`}>
              {(provided) => (
                <ul
                  style={{ listStyleType: "none", paddingLeft:"unset" }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
              {this.props.value.map((sec, i) => {
                return (sec.questions.map((section, i) => {
                  return (
                    <Draggable key={i} draggableId={`${i}`} index={i} isDragDisabled={modalOpen}>
                      {(provided) => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {
                            <div className="test-section-itm" key={i}>
                              {this.renderSectionHeader(section, i)}
                              {this.renderSectionContent(sec.questions, i)}
                            </div>
                          }
                        </li>
                      )}
                    </Draggable>
                  );
                }))
              })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
    );
  };
  toggleSectionCollapse = (seq) => (e) => {
    e.stopPropagation();
    const questionSection = this.state.sections.map((ch) => {
      const contents = ch.questions.map((r, i) => {
        return i === seq ? { ...r, open: !r.open} : r;
      });
      return {...ch, questions :contents}; 
    });
      this.setState({sections: questionSection});
      this.props.onChange(questionSection);
  };

  renderSectionHeader =(section,i) => {
    return(
      <SectionItmHeader className="section-itm-header" onClick={this.toggleSectionCollapse(i)}>
        <div className="sec-title">
          <span> 
            {/* {this.props.value && this.props.value[i].question_order && ( */}
              <>
                Question {section.question_order}
              </>
            {/* )} */}
          </span>
        </div>
        <span>
        <div className="add-section-btn" onClick={this.handleSectionAdd}>
           + AddQuestion
        </div>
      </span>
      {this.props.value[0].questions.length > 1 && (
      <div
          className="delete-section-btn"
          // onClick={this.handleSectionDelete(i)}
        >
          <Button
            variant="link"
            style={{ padding: "1px 1px" }}
            onClick={this.handleSectionDelete(i)}
          >
            <IconTrash style={{height:"18px"}}/>
          </Button>
        </div>)
        }
      </SectionItmHeader>
    )
  }

  handleChange =
    (seq) =>
    ({ name, value }) => {
      if (this.props.onChange) {
        const questionSection = this.props.value.map((ch) => {
          const contents = ch.questions.map((s, i) => {
            if (i !== seq) {
              return s;
            } else {
              return {
                ...s,
                [name]: value,
              };
            } 
          });
          return {...ch, questions :contents}; 
        });
        this.props.onChange(questionSection);
        this.setState({ sections :questionSection});
      }
    };
 
  renderSectionContent = (section, i) => {
    // const sortedQuestions = section.questions.sort((a, b) => a.order - b.order);
    return (
      <Collapse in={section[i].open}>
        <div className="section-questions">
          {this.renderContent(section, i)}
        
        </div>
     </Collapse>
    );
  };

  renderContent = (section,i) => {
    const toggleButtonOptions = [
      { label: "Scale", value: "scale" },
      { label: "Drop Down ", value: "dropdown" },
    ];
    const toggleRatingOptions = [
      { label: "Three Rating ", value: "three" },
      { label: "five Rating ", value: "five" },
    ];
    return(
      <>
      <div className="editSectiondtls">
        <Row>
          <Col md={6}>
            <FormField
              type={Textarea}
              maxLength="999"
              name="question"
              value={section[i].question}
              onChange={this.handleChange(i)}
              style={{padding: "14px 16px 14px 16px"}}
              // autoFocus={this.adding()}
              // maxLength={config.maxLNameLength}
            />
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <FormField
                  className='stdAssignmentType'
                  type={Select}
                  options={config.surveyQuestionOptions}
                  name="questionType"
                  onChange={this.handleChange(i)}
                  value={section[i].questionType}
                  // autoFocus={this.adding()}
                  // maxLength={config.maxLNameLength}
                  disabled={this.props.modified && section[i].isModified}
                />
                {section[i].questionType  == 2 &&
                <FormField
                    className="module-input-type"
                    type={ToggleButtonGroup}
                    name="ratingOptionType"
                    options={toggleRatingOptions}
                    onChange={this.handleChange(i)}
                    value={section[i].ratingOptionType}
                  />}
              </Col>
              {section[i].questionType  == 0 ?
              <Col md={6}>
                <FormField
                  className='intro-standard'
                  type={Select}
                  options={config.surveyAnswerOptions}
                  name="choiceType"
                  onChange={this.handleChange(i)}
                  value={section[i].choiceType}
                  // autoFocus={this.adding()}
                  // maxLength={config.maxLNameLength}
                  // disabled={this.state.isQuestionType != 0}
                />
              </Col> : section[i].questionType  == 2?
              <Col md={6}>
              <FormField
                type={RatingQuestionAnswers}
                name="choices"
                label={null}
                value={section[i].choices}
                onChange={this.handleChange(i)}
                ratingType={section[i].ratingOptionType}
                modified={this.props.modified}
                questionType={section[i].questionType}
              />
            </Col>
              : null }
            </Row>
            <Row>
              {section[i].questionType == 0 ?
              <>
              <Col md={6}>
                <FormField
                  className="module-input-type"
                  type={ToggleButtonGroup}
                  name="multipleChoiceType"
                  options={toggleButtonOptions}
                  onChange={this.handleChange(i)}
                  value={section[i].multipleChoiceType}
                />
              </Col>
              <Col md={6}>
             {section[i].questionType == 0 &&
                <FormField
                  className="multiple-choice"
                  type={MultipleChoiceAnswers}
                  name="choices"
                  // label="Enter the Choices"
                  value={section[i].choices}
                  onChange={this.handleChange(i)}
                  isAnswerType={section[i].choiceType}
                  modified={this.props.modified}
                  questionType={section[i].questionType}
                  status={this.props.status}
                />
                }
              </Col>
              </>
              : section[i].questionType == 2 && (
                <>
                {/* <Col md={6}>
                  <FormField
                    className="module-input-type"
                    type={ToggleButtonGroup}
                    name="ratingOptionType"
                    options={toggleRatingOptions}
                    onChange={this.handleChange(i)}
                    value={section[i].ratingOptionType}
                  />
                </Col> */}
                </>
              )
              }
            </Row>
            <Row>
            {section[i].questionType == 2 &&
            <Col md={6}>
                  {/* <FormField
                    type={RatingQuestionAnswers}
                    name="choices"
                    label={null}
                    value={section[i].choices}
                    onChange={this.handleChange(i)}
                    ratingType={section[i].ratingOptionType}
                    modified={this.props.modified}
                    questionType={section[i].questionType}
                  /> */}
                </Col>}
            </Row>
          </Col>  
          </Row>
          </div>
      </>   
    )
  }
  
  render = () => {
    return (
      <div className="test-sections-wrapper">
        {this.renderSections()}
      </div>
    );
  };
}
export default withApiContext(
  withUserContext(withLabelsContext(withWindowContext(QuestionForm)))
);