import { month } from "@breejs/later";

export const configurableLabels = {
  defaults: {
    // virtualroom:     {},
    academicYear: { singular: "Academic Year" },
    analyticsSuccessfully: { singular: "Analytics Sync Successfully"},
    accessRight: { singular: "Access Right" },
    addQuestionTest: {singular: "Add Question to test"},
    addAssignmentTest: { singular: "Add Assignment to Test"},
    access: { singular: "Access"},
    aiRecommendationQuestion: { singular: "AI Recommended Questions"},
    assmntAttnd: { singular: "AssmntAttnd"},
    assmntAsgnd: { singular: "AssmntAsgnd"},
    accountId: { singular: "Account Id" },
    add: {singular:"Add"},
    assignmentType: {singular: "Assignment Type"},
    addMedia: {singular: "Add Media"},
    admin: { singular: "Admin" },
    administrator: {singular: "Administrator"},
    ai: { singular: "AI"},
    attempt: { singular: "Attempt"},
    aiRecommendation: { singular: "AI Recommendation" },
    aiRequest: { singular: "AI Request" },
    all: {singular:"All"},
    applicableRoles: { singular: "Applicable Roles" },
    assessment: { singular: "Assessment", plural: "Assessments" },
    assessmentList: { singular: "Assessment List", plural: "Assessment Lists" },
    assessmentEvaluation:{singular:"Assessment Evaluation"},
    assigAssesAct: { singular: "Active Assessments" },
    assigChap: { singular: "Chapters Allocated" },
    assigClass: { singular: "Classes Assigned" },
    assignment: { singular: "Assignment"},
    action: { singular: "Action", plural: "Actions" },
    activeStudent: { singular: "Active Students" },
    assmtsPubl: { singular: "Assmts Publ" },
    allocation: { singular: "Allocation"},
    allocations: { singular: "Allocations"},
    answers:{singular:"Answer" , plural: "Answers"},
    allocationStatus: {},
    allowBackward: { singular: "allow backward" },
    allowChanges: { singular: "allow changes" },
    allowedSyllabus: { singular: "allowed syllabus" },
    allowReview: { singular: "allow review" },
    attendance: { singular: "Attendance" },
    attribute: { singular: "Attribute" },
    attributeDesc: { singular: "Description" },
    attributeValue: { singular: "Indicative Value" },
    attributeType: { singular: "Attribute Type" },
    attributeRequired: { singular: "Required" },
    audience: { singular: "Audience" },
    addSection: {singular: " Add Section"},
    audio: { singular: "AUDIO"},
    addMember: { singular: "Add Member"},
    asscDomain: { singular: "Assc Domain"},
    avgAsses: { singular: "Average Assessment Score %" },
    avgAssmtScore: { singular: "Avg Assmt Score" },
    avgScore: { singular: "Avg Score" },
    accountCreation: {singular: "Account Creation"},
    avgTest: { singular: "Avg. Test" },
    avgCompletion: { singular: "Average Completion %" },
    avgPle: { singular: "Average PLE Score" },
    avgPLE: { singular: "Avg PLE" },
    batch: { singular: "Batch", plural: "Batches" },
    browse: {singular:"Browse"},
    imageOnly: {singular: "Images Only"},
    maxSize: {singular: "Max size"},
    preferredSize: {singular: "Preferred size"},
    filetoUpload: {singular: "file to upload"},
    batchClass: { singular: "Batch/Class"},
    botId: { singular: "Bot Id" },
    beginning:{singular:"Beginning"},
    backToAdmin: {singular: "Back To Admin"},
    batchAllocation: {singular: "Batch Allocation"},
    body: { singular: "Body"},
    cache:{singular:"Cache"},
    campus: {},
    courseTag: { singular: "Course Tag"},
    cancel: {singular: "Cancel"},
    calendar:{ singular: "Calendar" },
    category: { plural: "Categories" },
    certificate: {},
    clickUpdateEod: { singular: "Click to update EOD"},
    clickResetCache: { singular: "Click to reset cache"},
    clickSync: { singular:"Click to Sync"},
    create: { singular: "Create"},
    createChapter: {singular:" Create Chapter"},
    createTest: { singular: "Create Test"},
    chapterAllocation: { singular: "Chapter Allocation" },
    curriculumCredit: { singular: "Curriculum Credit" },
    chatBotEnabled: { singular: "Ai Integrated" },
    courseAttnd: { singular: "CourseAttnd"},
    courseAsgnd: { singular: "CourseAsgnd"},
    // curriculumCredit: { singular: "curriculum credit" },
    city: {},
    current:{singular:"Current"},
    clearAll:{singular:"Clear all"},
    clear: {singular: "Clear"},
    class: { singular: "Class",plural:"Classes"},
    classnBatch: { singular: "ClassnBatch"},
    classlist: { singular : "ClassList"},
    classPerformance: { singular: "Class Performance" },
    classTeacher: { singular: "class Teacher" },
    closed: {singular:"Closed"},
    comment: {singular:"Comment"},
    content: { singular: "Content"},
    contentPerformance: { singular: "Content Performance" },
    course: { singular: "Course", plural: "Courses" },
    completionPercentage: { singular: "Completion %" },
    configId:{singular:"Config Id"},
    // courseBuilder:        {singular: 'Course Builder'},
    courses: {singular: "Courses"},
    created: {singular: "Created"},
    createdBy: { singular: "Created By" },
    createNew: {singular: "Create New"},
    createdOn: { singular: "created On" },
    clickToMedia: { singular: "Click to add media"},
    clickToView: {singular: "Click to View/Hide media"},
    complperc: { singular:"Compl. %"},
    cacheReset: { singular: "Cache reset successfully"},
    // createdBy: { singular: "Created By" },
    // createdOn: { singular: "Created On" },
    creationDate: { singular: "Created Date" },
    credit: {singular: "Credit", plural:"credits"},
    creditTeacherStudent: { singular: "Teacher SME vs Student PLE" },
    curriculum: { plural: "Curriculum" },
    congratulationsMsg: { singular: "Congratulations! You have subscribed to this"},
    chooseDate: {singular:"Choose Date"},
    cancelQtsUpdate: { singular: "Cancel question update"},
    changelanguage: { singular: "Change Language"},
    campusSettings: { singular: "Campus Settings"},
    coBrandUrl: { singular: "Co-Brand URL"},
    coBrandImg: {singular: "Co-Brand Image"},
    daily: {singular: "Daily" },
    difficultyName:{singular:"Difficulty Name"},
    date:{singular:"Date", plural:"Dates"},
    days: { singular: "day(s)" },
    dashboard: {},
    delete: {singular:"Delete"},
    languagePopUp: { singular: "Language updated successfully"},
    deliveryOption: {singular: "Delivery Options"},
    assignSelect:{singular:"Assign To ( Select anyone )"},
    discardQtsChanges: { singular: "Discard question changes"},
    delivery: {singular: "Delivery"},
    description: { sigular: "Description"},
    department: { singular: "Department"},
    deptRoles: { singular: "Department Role" },
    division: {},
    displayDirection: {singular: "Display Direction"},
    doYouWantTo:{singular :"Do you want to "},
    parent:{ singular: " Parent"},
    trainerName:{ singular: "Trainer name"},
    mark: {singular: "Mark", plural: "Marks"},
    parentMenu:{singular:"Parent Menu"},
    displayAnswer: { singular: "display answer" },
    displayCard: { singular: "list" },
    displayList: { singular: "display list based " },
    seekable: { singular: "Seekable" },
    displayMark: { singular: "display mark" },
    displayOption: { singular: "display option" },
    displayTotalMarks: { singular: "display total marks" },
    displayQuestion: { singular: "display question" },
    displayName:{singular:"Display Name"},
    defaultLanguage: {singular: "Default Language"},
    display: { singular: "Display"},
    dragMsg: { singular: "Drag and drop file here"},
    dueDates: { singular: "Due Date" },
    duration: {singular:"DURATION", plural:"Duration"},
    deliveryGroup: {singular: "Delivery Groups"},
    eod: { singular: "EOD"},
    email: { singular: "email", plural: "Email Address" },
    embedded : {singular: "EMBED" },
    end:{singular:"End"},
    eventTitle: { singular: "Event Title"},
    eventDetails: {singular: "Event Details"},
    endby: { singular: "End By" },
    endDate: { singular: "End Date",  plural:"ENDED DATE" }, 
    enrollDate: { singular: "Enrollment End Date" },
    eventCreation: {singular: "Event Creation"},
    enterprise: { singular: "Enterprise" },
    evaluation: { singular: "Evaluation" },
    everyday: { singular: "Everyday" },
    externalDomain: {singular: "External Domain"},
    export: {singular:"Export"},
    externalAuthentication: { singular: "External Authentication"},
    externalChannel: { singular: "External Channel" },
    faceImage:{ singular: "Face Login"},
    fatherName: { singular: "Father Name" },
    firstName: { singular: "First Name" },
    format: {},
    fileUploadSuccessfully: { singular: "File uploaded successfully!"},
    fileToLarge: { singular: "File is too large!"},
    for: { singular: "for"},
    firstOrLast: {singular: "First and/or last name"},
    frequency: { singular: "Auto Refresh" },
    fromDate: { singular: "From Date" },
    fromTime: {singular: "From Time"},
    fromPercent:{singular: "From Percent"},
    from: { plural: "from" },
    forgotPassword:{ singular: "Forgot Password"},
    // general: {},
    general: { singular: "General"},
    goi: { singular: "Group of Institute" },
    // groupAdmin: {singular:"Group Admin"},
    //goi: { singular: "Group Of Institute" },
    grade: { singular: "Grade" },
    groupAdmin: { singular: "Group Admin"},
    generate: { singular: "Generate"},
    hod: {},
    group:{ singular: "Group"},
    goodMorning: { singular: "Good Morning"},
    goodAfternoon: { singular: "Good Afternoon"},
    goodEvening: { singular: "Good Evening" },
    home: { plural: "home" },
    here: { singular: "here"},
    import: {singular: "Import"},
    icon: {},
    image: { singular : "Image"},
    inCourse: { singular: "In-Course"},
    inActive: { singular: "Inactive"},
    inCourseQuiz: { singular: "In Course Quiz" },
    individual: {singular: "Individual"},
    infra: {},
    incomplete: {singular: "incomplete"},
    intScore: { singular: "Int Score" },
    knowledgeShelf: {
      singular: "Knowledge Shelf",
      plural: "Knowledge Shelves",
    },
    highestScore:{singular:"Highest Score"},
    highest:{singular:"Highest"},
    latestScore:{ singular: "Latest Score"},
    latest:{ singular: "Latest"},
    viewShelf: { singular: "View Shelf"},
    createShelf: { singular: "Create Shelf"},
    label: {singular: "Label", plural: "Labels"},
    lastName: { singular: "Last Name" },
    lastActive: { singular: "Last Active Time" },
    leaderboard: { singular: "Leaderboard" },
    length: {},
    language: { singular: "Language"},
    library: {},
    list:{singular:"list"},
    logo: {singular: "Logo"},
    lastUpdate: { singular: "Last Update"},
    moduleIncomplete: { singular: "Module name is incomplete"},
    moduleCreditIncom: {singular: "Module credits is incomplete"},
    contentIncom: {singular: "Content is incomplete"},
    noModuleAdd: {singular: "No modules are added"},
    noFieldMod: {singular: "No field is modified"},
    returnTo: {singular: "return to the "},
    searchPage: {singular: "search page"},
    edit: {singular: "Edit"},
    manual: {},
    mangApp: { singular: "Manager Approval" },
    maxStud: { singular: "Max no. of participants" },
    meetingUrl: { singular: "Meeting Url" },
    searchBy: { singular: "Search By" },
    meetingType: { singular: "Meeting Type" },
    member: { singular: "Member"},
    menu: {},
    markScored: { singular: "Marks Scored"},
    menuName:{singular:"Menu"},
    message: {},
    mixIn: { singular: "mix in" },
    modify: {singular:"Modify"},
    modAssnd: { singular: "Mod.Assnd" },
    module: {singular:"Module" , plural: "Modules"},
    moduleModifiedBy: { singular: "module is modifiable by all teacher" },
    moduleViewableBy: { singular: "module is viewable by all teacher" },
    motherName: { singular: "Mother Name" },
    myCourseYTD: { singular: "My Course Progress YTD" },
    myOpenAssessments: { singular: "My Open Assessments" },
    myOpenCourses: { singular: "My Open Courses" },
    myOpenSubject: { singular: "My Open Subjects"},
    unattended: {singular: "Unattended"},
    totalQuestion:{ singular: "Total Questions"},
    my: {singular:"My"},
    // Name: {singular:"Name"},
    negative: { singular: "Negative"},
    noOfClasses: { singular: "No. of Classes" },
    noRecordFound: {singular: "No records found!"},
    noOfCourse: { singular: " No. of Course", plural: "No. of Courses"},
    noOf: { singular: "No Of"},
    // open: {},
    organization: {singular: "Organization"},
    on: { singular: "On"},
    off: { singular: "Off"},
    // organizeMenu:{singular:" organize menu"},
    orgMenu: { singular: "organize menu" },
    orientation: { singular:"Orientation"},
    page: {},
    param: { singular: "Param" },
    paramColl: { singular: "Bot Parameters" },
    password: { singular: "Password"},
    pdf: { singular: "PDF" },
    permission: { singular:"Permission",plural: "Permissions" },
    phone: {},
    photo: {},
    passwordSuccessfully: { singular: "Password updated successfully"},
    processingFile: { singular: "Processing your file. Please wait..."},
    proceed: {singular: "Proceed"},
    popupMsg: { singular : "Are you sure you want to"},
    playback:{singular:"Playback"},
    ple: { singular: "ple" },
    pleaseSelectSession:{singular:"Please Select Session"},
    positionOnPage: { singular: "position on page" },
    position:{singular:"Position"},
    principal: {singular: "Principal" },
    progress: { plural: "Progress", singular:"Progress" },
    publish: { singular: "Publish", plural: "Publishing" },
    publishTest: { singular: "Publish Test" },
    publishDetails:{singular:"Publish Details"},
    publishCourse: {singular: "Publish Course"},
    promotion:{ singular: "Promotion"},
    positionRequired: { singular: "Position is required"},
    typeTest: { singular: "Type of Test"},
    unitTest: { singular: "Unit Test"},
    testPub: { singular: "Test Published" },
    termTest: { singular: "Term Test"},
    rating: {singular:"Rating", plural:"Ratings"},
    userRating: {singular: "No. of user rated"},
    totalStudent: { singular: "Total Students" },
    totalCourse: { singular: "Total Courses" },
    totalAssessment: { singular: "Total Assessments" },
    na: {singular: "NA"},
    no:{singular:"No"},
    nochangesPermittedSubmitting: { singular: "No changes permitted during submitting"},
    noRecordingsavailable:{singular: "No Recording available",plural:"No Recordings available"},
    notificationType: {singular: "Notification Type"},
    qa: { singular: "Q&A", plural: "Q&A" },
    qnsToQB: { singular: "Qns to QB" },
    question: {singular: "Question", plural: "Questions"},
    questionbank: { singular: "Question Bank" },
    questionComment: { singular: "Question Comment" },
    questionCredit: { singular: "Question Credit" },
    questionImage: { singular: "Question Image" },
    questionType: { singular: "Question Type" },
    quizz: { plural: "Quizzes" },
    questionSucessfully: { singular: "Question Saved Successfully"},
    randomizeSection: { singular: "Randomize Section" },
    randomizeQuestion: { singular: "Randomize Question" },
    recommendation: {singluar: "Recommendation"},
    recording :{singular: "Recording", plural:"Recordings"},
    registrationNumber: { singular: "Registration Number" },
    report: { singular: "Report", plural: "Reports"},
    reporter: {},
    resourceRoomStudent: { singular: "Resource Room Student" },
    resourceRoom: { singular: "Resource Room"},
    resultSummary: { singular: "result summary" },
    reviewAnswer: { singular: "review answer" },
    resultFound:{singular:"results found"},
    result: { singular: "Result"},
    roomType: { singular: "Room Type"},
    role: {},
    registrationStatus: {singular: "Registration Status"},
    regStatus: {singular: "Reg. Status"},
    resume: { singular: "Resume"},
    restart:{ singular: "Restart"},
    roleName:{singular:"Role"},
    roleType: { singular: "Member Type", plural: "Member Types" },
    removeDuplicates:{singular:"Remove duplicate choice"},
    rooms: { singular: "Room" , plural  : "Rooms" },
    remove: {singular: "Remove"},
    school: {},
    site: {singular:"Site"},
    submitQtsUpdates: { singular: "Submit question updates"},
    score: { singular: "Score" },
    selection: { singular: "selection" },
    selfReg: { singular: " Self Registration" },
    seqContent: { singular: "Sequential Content Access" },
    sendMessage: { singular: "Send Message" },
    serverUrl: { singular: "Server URL" },
    serverPort: { singular: "Server Port" },
    settings: {},
    noSessionMsg: { singular: " Sorry, we couldn't find any Sessions"},
    switchRole: { singular: "Switch Role"},
    select:{singular:"Select"},
    skills:{singular:"Skills"},
    successfully: { singular: "Successfully"},
    shelf: {singular: "Shelf"},
    save:{singular: "Save"},
    section: {singular:"Section"},
    shelfLibrary: { singular: "Shelf Library" },
    shortName: { singular: "Short Name" },
    shortCodes: {singular: "Short Code"},
    showAllAssessments: { singular: "show all publishing" },
    showAllChapters: { singular: "show all chapters" },
    showAllModules: { singular: "show all" },
    showAllQuestion: { singular: "show all question" },
    showAllRooms: { singular: "show all rooms" },
    showAllShelf: { singular: "show all shelf" },
    showAllStandard: { singular: "All Standard" },
    showAllTest: { singular: "show all test" },
    showMyAssessments: { singular: "show my publishing" },
    showMyChapters: { singular: "show my chapters" },
    showMyModules: { singular: "show mine" },
    showMyQuestion: { singular: "show my question" },
    showMyRooms: { singular: "show my rooms" },
    showMyShelf: { singular: "show my shelf" },
    showMyStandard: { singular: "Current Standard" },
    showMyTest: { singular: "show my test" },
    searchByKeyword:{singular:"Search by keyword"},
    searchByName: { singular:" Search by name"},
    searchFor:{singular:"Search for"},
    selectPosition:{singular:"Select position...."},
    selectType:{singular:"Select type...."},
    sme: { singular: "sme" },
    selectCreatedDate: { singular: "Select Created Date"},
    selectDifficultyLevel: {singular: "Select Difficulty Level"},
    sessAttnd:{ singular: "SessAttnd"},
    sessAsgnd: { singular: "SessAsgnd"},
    smeCredits: { singular: "SME credits" },
    standaloneCredit: { singular: "stand alone credit" },
    standard: {singular:"Standard"},
    start: { singular: "Start"},
    startDate: { singular: " Start Date" },
    toDate: { singular: "To Date"},
    state: {},
    status: { singular: "Status"},
    student: { singular: "Student", plural : "Students"},
    studentDetails: { singular: " Student Detail", plural: "Student Details" },
    studentSummary: { singular: "Student Summary" },
    subjectProgress: { singular: "Subject Progress" },
    summary: { singular: "Summary"},
    submit:{singular:"Submit"},
    reSubmit: { singular: "Re-submit" },
    syllabus: { plural: "syllabuses" },
    limit: { singular: "Limit"},
    toPercent :{singular: "To Percent" },
    teacherList: { singular: "teacher list", plural: "teacher lists" },
    test: {singular: "Test"},
    testresult: { singular: "Test Result" },
    time:{singular:"Time"},
    testCreated: { singular: "Test Created" },
    to: { plural: "to" },
    timeSettings:{singular:"Time Settings"},
    generateButtonMsg: { singular: "Please select filters and click on Generate button to view the report"},
    teacher:              {singular: "Teacher"},
    trainee: { singular:"Trainee"},
    trainer: { singular: "Trainer"},
    trainerZoomPin: { singular: "Meeting ID" },
    trainerZoomPwd: { singular: "Meeting Password" },
    type: { singular : " Type "},
    uploading: {singular: " Uploading"},
    untitled:{singular:"Untitled"},
    used: {singular: "used"},
    updateClasslist: {singular: "Update classlist"},
    utility: { singular: "Utility"},
    validityFrom: { singular: "Validity From" },
    validityTo: { singular: "Validity To" },
    variant : {singular : "Variant"},
    vcSess: { singular: "VC Sess" },
    video: { singular: "VIDEO"},
    enablePpt: {singular: "PPT"},
    selectReportType: {singular: "Report"},
    pptEditor: {singular: "PPT"},
    view: {singular:"View"},
    waitlistAllow: { singular: "Waitlist Allowed" },
    week: {},
    weekday: { singular: "Every weekday" },
    weekly: {},
    webPage: { singular: "WEBPAGE" },
    webSite: { singular: "Web Site" },
    zipCode: { singular: "Zip Code" },
    zoneName:{singular:"zone Name"},
    virtualclassroom: { singular: "Virtual Classroom" },
    scorm: { singular: "SCORM" },
    trainingZone: { singular: "Training Zone" },
    user: { singular: "member" },
    userRole: { singular: "role", plural: "roles" },
    urlExtension: { singular: "URL Extension" },
    instruction: {},
    coursePublish: {singular: "Course Publish"},
    launch: {singular:"Launch"},
    last: { singular: "Last"},
    loginTime: {singular: "Login Time"},
    serviceProvider: {
      singular: "Service Provider",
      plural: "Service Providers",
    },
    chapter: { singular: "Chapter", plural: "Chapters"},
    template: { singular: "template", plural: "Templates" },
    sms: { singular: "sms" },
    program: { singular:"Program"},	
    session: {singular:"Session"},
    highlight: {singular: "INTRO"},
    analytics: {singular: "ANALYTICS"},
    aiIntegrated: {singular: "AI INTEGRATED"},
    graphqlInterval: {singular: "GRAPHQL INTERVAL"},
    studymapTriggerInterval: {singular: "STUDYMAP TRIGGER INTERVAL"},
    idleTimeoutInterval: {singular: "IDLE TIMEOUT INTERVAL"},
    graphUrlPath: {singular: "GRAPHURL PATH"},
    defaultScorm: {singular: "DEFAULT SCORM"},
    faceId: {singular: "FACE ID"},
    reportsTrainer: {singular: "TRAINER REPORTS"},
    reportsTrainee: {singular: "TRAINEE REPORTS"},
    coursePublishGrouping:{singular:"COURSE PUBLISH GROUPING"},
    maxPdfSize: {singular: "MAXIMUM PDF SIZE"},
    multipleChoice: { singular: "Multiple Choice"},
    multipleChoiceSingleOption: {singular: "Multiple Choice (Single Option)"},
    fillInTheBlanks: {singular: "Fill-in-the-Blanks"},
    shortAnswer: {singular: "Short Answer/Essay"},
    maxAudioSize: {singular: "MAXIMUM AUDIO SIZE"},
    maxImageSize: {singular: "MAXIMUM IMAGE SIZE"},
    maxImageWidth: {singular: "MAXIMUM IMAGE WIDTH"},
    maxImageHeight: {singular: "MAXIMUM IMAGE HEIGHT"},
    maxVideoSize: {singular: "MAXIMUM VIDEO SIZE"},
    pollInterval: {singular: "VIMEO UPLOAD TRANSCODE POLLING INTERVAL"},
    jitsiMeet: {singular: "JITSI MEET"},
    chapterScheduleGap: {singular: "CHAPTER SCHEDULE GAP"},
    releaseMode: {singular: "Rel. Mode"},
    embeddedEnabling:{singular:"EMBEDDED ENABLING"},
    chapterCompletionCredit:{singular:"CHAPTER COMPLETION CREDITS"},
    coursePublishRefresh:{singular:"COURSE PUBLISH REFRESH"},
    assessmentGrade:{singular : "GRADE"},
    difficultyLevel: { singular:"Difficulty Level" },
    newMessagePolling:{singular:"NEW MESSAGE POLLING"},
    zone: { singular:"Zone" },
    zoneGeneralTab: { singular:"General" },
    defaultRole:{singular:"Default Role"},
    showCredits:{singular:"Show Credits"},
    noMatchesFound: { singular: "No match found", plural: "" },
    matchesFound: { singular: "matches found", plural: "" },
    //matchesFound: { singular: "search by name", plural: "" },
    matchesFoundSearch:{singular:"Search by name"},
    actions: { singular: " Actions", plural: "" },
    export: { singular: " Export", plural: "" },
    changePassword: { singular:"Change Password"},
    oldPassword: { singular: "Old Password"},
    newPassword: { singular: " New Password " },
    image: { singular: " Image"},
    email: { singular: "Email"},
    name: { singular: "name"},
    bot : { singular : " Bot "},
    meetingOn: {singular: "Meeting On"},
    selectDate : {singular: "Selected Date/Virtual Room"},
    sessionMessage: {singular: "No Sessions Available !"},
    timePeriod: { singular: "Time Period"},
    virtualRoom: { singular:"Virtual Room" ,plural : "Virtual Rooms"},
    virtualSession: { singular:"Virtual Session" },
    moduleCredit: { singular: "Module Credits"},
    maxCredit: { singular: "Max Credit"},
    addFromLibrary: {singular: "Add from library"},
    eventCategory: {singular:"Event Category"},
    schedule: {singular: "Schedule"},
    toTime:{singular: "To Time"},
    repeatEvery: {singular:"Repeat Every"},
    title:{singular:"Title"},
    uploadedSuccessfully: {singular: "Uploaded successfully"},
    uploadCsvFile: { singular: "Upload CSV file..."},
    upload: { singular: "Upload"},
    pleaseUploadFile: { singular: "Please upload the file"},
    event:{singular:"Event"},
    filterBy: {singular: "Filter By"},
    reminder: {singular: "Reminder"},
    selectedContent: {singular: "Selected Content" },
    contentBuilder:{singular: "Content Builder"},
    previewAvailable:{singular:"No Preview Available"},
    campusRole:{ singular:"Campus Role Switch"},
    impersonate: { singular:"Impersonate"},
    apply: { singular:"Apply"},
    activeSwitch: { singular:"Active Child Switch"},
    active: { singular: "Active"},
    graduated: { singular: "Graduated"},
    left: { singular : "Left"},
    terminated: { singular: "Terminated"},
    suspended: { singular: "Suspended"},
    subscription: { singular:"Subscription Options"},
    profileSetting: { singular:"Profile Settings"},
    filterBy: { singular:"Filter By"},
    filter: {singular: "Filter"},
    websiteUrl: { singular: "Website URL" },
    externalUrl: { singular: "External URL"},
    siteSettings : { singular: "Site Settings"},
    configurations: { singular: "Configurations"},
    screenSettings: { singular: "Screen Settings"},
    contactDetails: {singular: "Contact Details"},
    socialSettings: {singular:  "Social Settings"},
    termsAndConditions: {singular: "Terms and Conditions"},
    privacyPolicy: {singular: "Privacy Policy"},
    colorScheme: { singular: "Color Scheme"},
    socialNetworks: { singular: " Social Networks "},
    instituteContactPhone: { singular: "Institute Contact Phone"},
    instituteContactEmail: {singular: "Institute Contact Email"},
    instituteAddress: {singular: "Institute Address" },
    homeScreenContent: {singular: "Home Screen Content"},
    mobileBanner: {singular: "Mobile Banner"},
    websiteBanner: {singular: "Website Banner" },
    subtitle: {singular: "Subtitle"},
    homeScreenDetails: { singular: "Home Screen Details"},
    transcript: { singular: "Transcript"},
    negCredit: { Singular: "Neg. Credit"},
    negCreditIn: {singular: "Neg. Credit In"},
    tags: { singular: "Tags"},
    or: { singular: "OR"},
    question: {singular: "Question", plural:"Questions"},
    mathQuestion: {singular: "Math Question"},
  
    shelfTags: { singular: "Shelf Tags"},
    topics: { singular: "Topics"},
    subTopics: { singular: "Sub Topics"},
    exclusions:{ singular: "Exclusions"},
    specifySearchDomain: { singular: "Specify Search-Domains"},
    never: { singular:  "Never"},
    weekly: { singular: " Weekly"},
    monthly: { singular: "Monthly"},
    expired: { singular: "Expired"},
    everyWeek: { singular: "Every week day"},
    doesNotRepeat: { singular: "Does not repeat"},
    openCourse: { singular: "Open Course"},
    sortBy: {singular: "Sort By"},
    noOpenAssesmentsFound : { singular: "No Open Assesments Found"},
    noOpenCoursesFound: { singular: "No Open Courses Found"},
    pleCredit: {plural: "PLE Credits"},
    new: {singular: "New"},
    avgCompletionRate: {singular: "Avg Completion Rate"},
    myAvgPLEScore: {singular: "My Avg. PLE Score"},
    preview: {singular: "Preview"},
    subscribe: {singular: "Subscribe"},
    subscribeCourse: {singular:"Congratulations! You have subscribed to this course successfully"},
    subscriptionFailure: {singular: "Failed to subscribe"},
    subscriptionFailed: {singular: "Request Failed"},
    seeAll: {singular: "See All"},
    subscribePreview: {singular: "Subscribe"},
    back: {singular: "Back"},
    testAvailable : {singular: "No Test Available"},
    favIcon: {singular: "fav Icon"},
    surveyAvailable: {singular: "No Survey Available"},
    serverName: {singular: "Server Name"},
    domainName: {singular: "Domain Name"},
    domainUrl: {singular: "Domain URL"},
    contentType: {singular: "Content type"},

    open: { singular: "Open"},
    newChapter: { singular: "New Chapter"},
    updateChapter: { singular: "Update Chapter"},
    update: { singular: "Update"},
    parentsDetails: { singular:"Parents Details"},
    published: { singular: "Published"},
    hideContent: { singular: "Hide Content"},
    showContent: { singular: "Show Content"},
    liveSessions:{ singular:"Live Sessions"},
    sessions:{singular:"Sessions"},
    subject:{ singular:"Subject",plural: "Subjects"},
    totalMark:{ singular: "Total Mark", plural: "Total Marks"},
    department:{singular:"DEPARTMENT"},
    startDate:{singular:"Start Date"},
    dueDate: {singular: "DUE DATE"},
    addFromList: {singular: "Add from List"},
    // totalMarks: { singular: "TOTAL MARKS"},
    totalQuestions:{ singular: "TOTAL QUESTIONS"},
    attended: {singular: "Attended"},
    videoUploaded:{singular:"Video Uploaded Successfully"},
    selectedFile: {singular: "Selected File"},
    externalUrlLink:{singular:"please enter valid external url link"},
    uploadVideo: {singular:"Upload Video"},
    allocated: {singular:"Allocated"},
    // displayName: {singular:"Display Name"},
    inavlidShort:{singular:"invalid short name"},
    inavlidContent: {singular:"invalid content"},
    select:{singular: " Select"},
    cannotUpdate: {singular:"cannot update this module " },
    moduleCredits:{ singular:"Module Credits"},
    isModifiableBy: { singular:"is modifiable by all"},
    isViewableBy:{singular: " is viewable by all"},
    format:{singular:"Format"},
    showSessions: { singular: "Show Session", plural: "Show Sessions" },
    month:{ singular: " Month"},
    year:{ singular: "Year"},
    attendanceDetails: { singular: " Attendance Details"},
    sessionDetails: {singular: "Session details are not available"},
    sessionName:{singular:"SESSION NAME"},
    specific:{ singular: "Specific"},

    cache: { singular: " Cache"},
    yes: { singular: "Yes"},
    no: { singular: "No"},
    pushNotification: {singular:"Push notification"},
    enableSms: { singular:"Enable SMS"},
    enableEmail: { singular: "Enable Email"},
    topicRelease:{ singular:"Topic Release Mode" },
    audience: { singular: "Audience"},
    notification:{ singular: "Notification"},
    groupName:{ singular: "Group Name"},
    sequence:{ singular: "Sequence"},
    startDateCannot: {singular:"Start date cannot be lesser than current date"},
    endDateCannot:{singular: "End date cannot be less than start date"},
    invalidDate:{ singular:"Invalid date"},
    listUpdate:{ singular: "list updated successfully"},
    listWasNot:{ singular: "list was not updated"},
    enable: { singular: "Enable"},
    disable: { singular: "Disable"},
    variants: { singular: "Variants"},
    listView: { singular: "List View"},
    download: { singular: "Download"},
    one: { singular: "One"},
    two: { singular: "Two"},
    three: { singular: "Three"},
    four: { singular: "Four"},
    five: { singular: " five"},
    six: { singular: "Six"},
    sun: { singular:"SUNDAY"},
    mon: { singular:"MONDAY"},
    tue: { singular:"TUESDAY"},
    wed: { singular:"WEDNESDAY"},
    thu: { singular:"THURSDAY"},
    fri: { singular:"FRIDAY"},
    sat: { singular:"SATURDAY"},
    topicRelease:{ singular: "Topic Release Mode"},
    inSequence: { singular: "In Sequence"},
    nonAcademic:{ singular: "Non-Academic"},
    allTopic:{ singular: "All Topics will be released at once."},
    useAllocation:{ singular:  "Use Allocation screen to release Topics"},
    obtainTopic:{ singular:"Obtain Topics from"},
    clickToRefresh:{ singular: "Click to refresh "},
    list:{ singular: "List"},
    search: { singular: "Search"},
    grouping: { singular: " Grouping"},
    // externalChannel: { singular:"External Channels"},
    applyFilters: {singular: "Apply Filters"},
    thereAreNoVideo: { singular: "There are no videos available!"},
    // failedComment: { singular: "Sorry, fetching video content from YouTube failed! Please try again later or try with different channel."},
    // NoZoom: { singular: "No ZOOM details registered with your Account. Please contact Administrator."},
    startSession: { singular: "START SESSION"},
    zoomId:{ singular: "MEETING ID"},
    topicFlow:{ singular: "Topic Flow"},
    incourseQuestions : { singular: "incourse Questions"},
    newQuestion: { singular: "New Question"},
    endSession: { singular: "END SESSION"},
    downloadCsv:{ singular:"Download CSV"},
    testProgress: { singular: "Test Progress"},
    marksDistribution: {singular: "Marks Distribution"},
    scoreType: { singular: "Score Type"},
    options: { singular: "Options"},
    randomizeQuestions: { singular: "Randomize Questions"},
    numberOfAttempts: {singular:"Number of Attempts"},
    noOfAttempts: {singular:"No of Attempts"},
    publishTo: { singular: "Publish to"},
    selectAnyOne: { singular: "select any one"},
    timeLimited: { singular: "Time Limited"},
    inProgress: {singular: "In Progress"},
    yetToStart: {singular: "Yet To Start"},
    yet: {singular: "Yet "},
    progPerc: {singular: "Progress%"},
    hasExp: {singular: "has expired"},
    this: {singular: "This"},
    logOut: {singular: "Log Out"},
    extendTest: {singular:"Extend Test"},
    recBasedIntrst: {singular: "Recommendations based on your Interest"},
    recBasedJobPrfle: {singular: "Recommendations based on your Job Profile"},
    completed: {singular: "Completed"},
    filterByPrgrs: {singular: "Filter by progress"},
    searchForCrse: {singular: "Search for course"},
    started: {singular: "Started"},
    ended: {singular:"Ended"},
    inActive: {singular:"In Active"},
    join: {singular:"Join"},
    playbackStudent: {singular:"Playback Student"},
    jobProfileInterest: {singular: "Job Profile Interest"},
    interest: {singular:" Interest"},
    contentNotAdd: {singular: "Content Not Added Yet"},
    trainerDetails: {singular: "Trainer Details"},
    notes: {singular: "Notes"},
    rate: {singular: "Rate "},
    numberOfUsersRated: {singular: "Number of users rated"},
    average : {singular:"Average"},
    askTheTrainer: {singular: "QA"},
    assigned: {singular:"Assigned"},
    studentTraining: {singular: "Student Training"},
    teacherTraining: {singular: "Teacher Training"},
    viewAll: { singular: "View All"},
    submitTest: {singular: "Submit Test"},
    saveAndContnu:{singular: "Save And Continue Later"},
    exit: {singular: "Exit"},
    flag: {singular: "FLAG"},
    unFlag: {singular: "UNFLAG"},
    previous: {singular: "Previous"},
    next: {singular: "Next"},
    wantToRevisit: {singular: "Want To Revisit Later"},
    toContinueCompletemod: {singular: "To Continue, Complete modules in previous Chapter."},
    chapDetls: {singular: "Chapter Details"},
    thisActionNewTab: {singular: "This action will open the requested page in a new tab. Would you like to continue"},
    markAsComp: {singular:"Mark as Complete"},
    continue: {singular: "CONTINUE"},
    backTo: {singular: "BACK TO"},
    congratsOnComplte: {singular: "Congratulations on completing"},
    youHaveEarned: {singular: "You have earned"},
    youHaveReached: {singular: "You have reached the end of chapter"},
    resumeMod: {singular: "RESUME MODULE"},
    done: {singular: "DONE"},
    courseComp: {singular: "COURSE COMPLETED"},
    progressUpdtn: {singular: "Progress updation failed"},
    moduleCntntNotAvlbl: {singular: "module content not available"},
    pleaseTryAgn: {singular: "Please try again"},
    failedToFetch: {singular: "Failed to fetch content, please try again later"},
    okay: {singular: "Okay"},
    courseHasExp: {singular: "This course has been expired"},
    resumeCourse: {singular: "Resume Course"},
    startCourse: {singular: "Start Course"},
    youHaveAlrdyStartd: {singular:"You have already started consuming"},
    pleaseClickBelow: {singular: "please click below to continue"},
    jan: {singular: "January"},
    feb: {singular: "February"},
    march: {singular: "March"},
    april: { singular: "April"},
    may: { singular: "May"},
    june:{ singular: "June"},
    july: { singular: "July"},
    aug: { singular: "August"},
    sept: { singular: "September"},
    oct: { singular: "October"},
    nov: { singular: "November"},
    dec: { singular: "December"},
    technicalDifficulties: { singular: "We're currently experiencing technical difficulties."},
    mediaConvert:{singular:"Media Convertor"},
    tracker:{singular:"Tracker"},
    Students:{},
    aibot:{},
    share: {singular: "Share"},
    skillAssessment:{singular:"Skill Assessment"},
    enableSkills:{singular:"Enable Skills"},
    enableCourseShare: {singular: "Enable Course Share"},
    enableCourseRating:{singular:"Enable Course Rating"},
    maxConvertSize: {singular: "MAXIMUM CONVERT SIZE"},
    filetooLarge: {singular: "File too Large" },
    invalidFile: {singular: "Invalid file type "},
    discardChanges: {singular: "Discard changes"},
    clearAllFields: {singular: "Clear All fields"},
    noFieldsModified: { singular: "No fields have been modified"},
    refresh: { singular: "Refresh"},
    updateSuccessfully: {singular: "updated successfully"},
    haveBeenSelected: { singular: "have been selected"},
    noteFillInBlanks: {singular: "Note : Fill-in-the-blank questions require at least one answer"},
    noteMultipleChoice: {singular: "Note : Multiple-choice questions require at least two choices, at least one of which must be marked correct"},
    noteMultipleChoiceses: {singular: "Note : Multiple-choice questions should require at least two choices and one of the choices must be marked as correct"},
    another: { singular: "another"},
    correctTheFollowing: {singular: "Please correct the following first:"},
    testNameIncom: {singular: "Test name is incomplete"},
    testName: { singular: "Test Name"},
    shortNameIncom: {singular: "Short Name is incomplete"},
    noAssessmentAdded: { singular: "No assessments are added"},
    addSelected: { singular: "Add Selected"},
    importasCSV: { singular: "Import as CSV"},
    questionNameIncom: {singular : "Question name is incomplete"},
    questionTypeIncom: {singular: "Question type is incomplete"},
    markIncom: { singular: "Mark is incomplete"},
    questionInvalid: { singular: "Question credit is invalid"},
    invalidUrl: { singular: "Invalid Url"},
    answerIncom: { singular: "Answer is incomplete"},
    oneOrMoreField: { singular: "One or more fields are incomplete"},
    reccurance: { singular: "Reccurance"},
    trainerReport: { singular : "Trainer Report"},
    traineeReport: { singular: "Trainee Report"},
    chapterwiseAllocationReport : { singular: "Chapterwise Allocation Report"},
    cohortProgressReport: { singular: "Cohort Progress Report"},
    studentLearningProcessReport: { singular: "Student Learning Process Report"},
    overallAssessmentReport : { singular: "Overall Assessment Report"},
    learnerAssessmentCard : { singular: "Learner Assessment Card"},
    studentProgressReport : { singular: "Student Progress Report"},
    testAssessmentSummary : { singular: "Test Assessment Summary"},
    classStudyTimeCategoriesReport : { singular: "Class Study Time Categories Report"},
    loginReport : { singular : "Login Report"},
    cumulativeStudyTimeReport : { singular: "Cumulative Study Time Report"},
    attendanceReport : { singular : "Attendance Report"},
    classTimeConsumptionReport : { singular: "Class Time Consumption Report"},
    studentTimeConsumptionReport : { singular: "Student Time Consumption Report"},
    chapterAddedSuccessfully : { singular: "Chapter Added Successfully"},
    attendanceAddedSuccessfully : { singular: "Attendance Added Successfully"},
    logoutConfirm : { singular: "Are you sure you want to logout?"},
    chatCancelConfirm : {singular: "Are you sure you want to cancel?"},
    logoutAlert : { singular: "You are now logged out."},
    campusSummaryReport : { singular: "Campus Summary Report"},
    campusEngagementReport: { singular: "Campus Engagement Report"},
    userEngagementStatisticsReport : { singular: "User Engagement Statistics Report"},
    courseWiseAttendanceViewerReport : { singular: "Course Wise Attendance Viewer Report"},
    classWiseAttendanceViewerReport : { singular: "Class Wise Attendance Viewer Report"},
    teacherEngagementReport : { singular: "Teacher Engagement Summary Report"},
    UserAnalysisReport : { singular: "Monthly User Engagement Analysis Report"},
    // optionalSkills: { singular : " Optional Skills "},
    essentialSkills: { singular: "Essential Skills"},
    jobProfile: { singular: "Job Profile"},
    skillAssessmentmsg: { singular: "This member not associated with any job profile Please Assign a job profile"},
    ltr: { singular: "LTR"},
    rtl: { singular: "RTL"},
    link: { singular: "Link"},
    your: { singular: "your"},
    registered: { singular: "Registered"},
    nonRegistered: { singular:  "Non-Registered"},
    jobTitle: { singular: "Job Title"},
    jobProfileMsg: { singular: " You are not associated with any jobProfile. Please contact your admin"},
    attendanceTracker: { singular: "Attendance Tracker"},
    attendanceViewer: { singular: "Attendance Viewer"},
    viewer:{singular:"Viewer"},
    selectClass : {singular: "Select Class & Date"},
    trackerMessage: {singular: "Attendance Not Available !"},
    classBatchName: {singular: "CLASS/BATCH"},
    dayOrderIncom: {singular: "Day Order is incomplete"},
    fromDateIncom: {singular: "Date is incomplete"},
    unitIncom: {singular: "Unit is incomplete"},
    timePeriodIncom: {singular: "Time Period is incomplete"},
    desciptionIncom: {singular: "Description is incomplete"},
    completedOn: { singular: "Completed On" },
    coursePublishStatus: { singuler: "CoursePublishStatus"},
    logoutConfirm: { singular: "Are you sure you want to logout?"},
    out: { singluar : "out?"},
    chapterIncom : { singular: "Chapter name is incomplete"},
    nofieldMod: { singular: "No fields are modified"},
    noChapteradded: { singular: "No chapters are added"},
    sameQtsexist: { singular: "Same question name already exist"},
    cannotAddPosition: { singular: "Cannot add questions on the same position"},
    qtsChanges: { singular: "Question changes updating"},
    requiredPendingupdate: { singular: "Required fields are pending to update"},
    chapternotModifiable: { singular: "This chapter is not modifiables"},
    uploaded: {singular: "Uploaded"},
    // { singular: "Your session has expired, Please login again to continue"}
    zoom: { singular: "Zoom"},
    webEx: { singular:"WebEx"},
    googleMeet: { singular: "Google Meet"},
    microsoftTeams: { singular: "Microsoft Teams"},
    exampleEmail: { singular: "john.smith@example.com"},
    hasBeenSelected: { singular: "has been selected yet"},
    addCourse: { singular: "Add Course", plural : " Add courses"},
    courseName: {singular: "Course Name", plural: "Course Name"},
    defined: { singular: "defined."},
    answerImage: { singular: "Answer Image"},
    choiceImage: { singular: "Choice Image"},
    choice: { singular: "Choice"},
    isCorrect: { singular : "Is correct"},
    usedInAnotherTests: { singular: "Used in another tests"},
    questionLevelStatistics: { singular: "Question Level Statistics"},
    yesEnd: { singular: "Yes, End."},
    noKeepIt: { singular: "No, keep it"},
    areYouSureMsg: { singular: "Are you sure you want to end the session."},
    courseEmptymsg:{ singular:"Sorry, you are not associated with any  courses from the previous"},
    notassociatedMsg: { singular: "Sorry, you are not associated with any"},
    pleaseContact: {singular: "Please contact your"},
    summaryCannotDisplay: { singular: "Summary cannot be displayed as evaluation is pending"},
    tickMarkSelected: { singular: "Tick mark is trainee selected answer."},
    greenCorrectAnswer: { singular: "Green is correct answer."},
    viewFeedback: { singular: "View Feedback"},
    feedback : {singular: "Feedback"},
    downloadSummary: { singular: "DOWNLOAD SUMMARY"},
    noFeedbackAvailable: {singular: "No feedback available"},
    correctAnswer: { singular: "Correct Answer"},
    wrongAnswer: { singular: "Wrong Answer"},
    wrong: {singular : "Wrong"},
    correct: {singular: "Correct"},
    attempted: {singular: "Attempted"},
    unAttempted:  { singular: "Unattempted"},
    totalCorrect: { singular: " Total Correct"},
    total: {singular: "Total"},
    generalInstructions: { singular: "General Instructions"},
    viewAssignment: { singular: "View Assignment"},
    toTheList: { singular: "to the list"},
    isAvailiable: { singular: "is availiable"},
    toBeAdded: { singular: "to be added"},
    selectNumberAttempts: { singular: "Select Number of Attempts"},
    object: { singular: "Object"},
    labelSingular: { singular: "Label (singular)" , plural: "Label (plural)"},
    exceptionFor: { singular: "exception for"},
    noObjectSelected: { singular: "No object has been selected yet"},
    selectObject: { singular: "Select object..."},
    sendMessages: { singular: "Send Messages"},
    addAttendance: { singular: "Add Attendance"},
    randomizeSection: { singular: "Randomize Section"},
    timehr: {singular:"Time(hr)"},
    date: {singular:"Date"},
    totalTime: {singular:"Total Time"},
    isIncomplete: { singular: "is incomplete"},
    activeTime: {singular:"Active Time"},
    studentStatus:{singular: "Student Status"},
    teacherStatus:{singular:"Teacher Status"},
    courseStatus:{singular:"Course Status"},
    assessmentPublished:{singular:"Assessment Published"},
    weeklyActivity:{singular:"Weekly Activity"},
    avg:{singular:"Avg."},
    comp:{singular:"Comp.%"},
    reloadPreview: {singular: "Reload preview"},
    courseDetl: {singular:"Overview"},
    coursePubNameIncom: {singular: "Course Name is incomplete"},
    teacherNameIncom: {singular: "Teacher Name is incomplete"},
    weeklyActTime: {singular: "Weekly Active Time"},
    chapterDescription: {singular: "chapterDescription"},
    noRecentActivityFound : { singular: "No Recent Activity Found"},
    recentActivity : { singular: "Recent Activity"},
    thereAreNo: { singular: "There are no"},
    loadingContent:{singular: "Loading content..."},
    activeUsers: { singular: "Active Users"},
    totalUsers: {singular: "Total Users"},
    htmlEditor:{ singular:"Text Editor"},
    activeUsers: { singular: "Active Users"},
    totalUsers: {singular: "Total Users"},
    courseAssign: {singular: "Course Assigned"},
    chapterAssign: { singular: "Chapter Assigned"},
    survey:{singular:"Survey", plural:"Surveys"},
    publishSurvey: { singular: "Publish Survey" },
    attemptStartedOn: {singular: "Attempt Started on"},
    attemptEndedOn: {singular: "Attempt Ended on"},
    seeDetails: {singular: "See Details"},
    studentProgress : { singular: "Student Progress"},
    removeAll : { singular: "Remove All" },
    studentCount : { singular: "Student Count" },
    calendarDay : { singular: "Calendar Day"},
    missigAudioErr: { singular: "Missing audio file!"},
    missingVideoErr: { singular: "Missing video file!"},
    missingPdfErr: { singular: "Missing pdf file!"},
    unSelectAll: { singular: "Unselect All"},
    selectAll: { singular: "Select All"},
    aOrVSnippet: { singular: "A/V" },
    weeklyTime: { singular: "Weekly Time"},
    consumption: { singular: "Consumption"},
    evaluatedBy: { singular: "Evaluated by"},
    obtainMarks: { singular: "Obtained Marks"},
    maxMarks: { singular: "Max Marks"},
    count: { singular: "Count"},
    assignedBy: { singular: "Assigned By"},
    completionStatus: { singular: "Completion Status"},
    conductedBy: { singular: "Conducted By"},
    thereIsNoData: { singular: "There is no data"},
    hey: { singular: "Hey"},
    myDashboard : { singular: "My Dashboard" },
    adminOverview : { singular: "Admin Overview" }, 
    courseInsights : { singular: "Course Insights" },
    courseAnalytics : { singular: "Course Analytics" },
    assessmentInsights : { singular: "Assessment Insights" },
    assessmentAnalytics : { singular: "Assessment Analytics" },
    report : { singular: "Report" },
    performanceOverview : { singular: "Performance Overview" },
    StudentCentral : { singular: "Student Central" },
    Manage : { singular: "Manage and create student profiles" },
    TeacherCentral : { singular: "Teacher Central" },
    Organize : { singular: "Organize and Add Teaching Staff" },
    ClassManagement : { singular: "Class Management" },
    ClassesBatches : { singular: "Create and Monitor Classes/Batches" },
    CourseLibrary : { singular: "Course Library" },
    CoursesCatalog : { singular: "Courses Catalog" },
    AssessmentLibrary : { singular: "Assessment Library" },
    AssessmentsRepository : { singular: "Assessments Repository" },
    Survey : { singular: "Survey" },
    platformInsights : { singular: "Platform Insights" },
    userManagement : { singular: "User Management" },
    contentManagement : { singular: "Content Management" },
    contentStats : { singular: "Content Stats" },
    studentStats:{singular: "Student Stats"},
    teacherStats:{singular:"Teacher Stats"},
    courseStats:{singular:"Course Stats"},
    assessmentStats : { singular: "Assessment Stats" },
    campusInsight : { singular: "Campus Insight" },
    assessmentsCreated : { singular: "Assessment Created" },
    assessmentsAllocated : { singular: "Assessment Allocated" },
    modulesCreated : { singular: "Modules Created" },
    modulesAllocated : { singular: "Modules Allocated" },
    campusAnalytics : { singular: "Campus Analytics" },
    facultyInsight : { singular: "Faculty Insight" },
    classInsight : { singular: "Class Insight" },
    classAnalytics : { singular: "Class Analytics" },
    teacherAnalytics : { singular: "Teacher Analytics" },
    courseAllocStats : { singular: "Course Allocation Stats" },
    assessmentAllocStats : { singular: "Assessment Allocation Stats" },
    lastLogin : { singular: "Last Login" },
    AssessmentName: { singular: "Assessment Name" },
    ntrtopic: { singular: "Enter The Topic" },
    srtessay: { singular: "Short Essay" },
    totlMrk:{ singular: "Total Marks"},
    noofqs:{ singular: "No.of Qs"},
    enableAiTestGeneration:{singular:"Enable AI Test Generation"},
    generateAiTest:{singular:"Generate AI Test"},
    zoneSequence: { singular: "Zone Sequence"},
    markperqs: {singular: "Mark per question", plural: "Marks per question"},
    popularCourse: { singular: "Popular Course", plural: "Popular Courses" },
    creatAiTest:{singular:"Create test using AI"},
    questionPool: { singular:"Question Pool" },
    pasteUrl:{singular:"Paste Your External URL Here..."}, 
    achievementType: {singular: "Achievement Type"}, 
    uniqueIdentifier: {singular: "Unique Identifier"}, 
    moduleType : { singular: "Module Type"}, 
    params: {singular: " Parameters"},
    certificateTemplate: {singular: "Certificate Template"},
    certificate_assigned_type: {singular: "Certificate For"},
    testCertificate: {singular: "Select Test"},
    testPercentage: {singular: "Test Percentage"},
    courseCompletion: {singular: "Course Completion"},
    workdayLogs: { singular: "Workday Logs" },
    month: { singular: "Month" },
    workingDays: { singular: "working Days" },
    WorklogAttendanceViewerReport : { singular: "Worklog Attendance Viewer Report"},
    testDetails : {singular:"Test details"},
    enableAchievements:{singular: "Enable Achievements"},
    uploadImage:{singular: "Upload Image"},
    removeImage:{singular: "Remove Image"},
    addSelected:{singular: "Add Selected"},
    list:{singular: "List"},
    // WorklogAttendanceViewerReport : { singular: "Class Wise Attendance Viewer Report"},
    questionReorder: { singular: "Question Reorder"},
    sectionReorder : {singular: "Section Reorder"},
    feedback: { singular: "Feedback"},
    saveMore: {singular: "Save & More"},
    questionnaire: {singular: "Questionnaire"},
    createQuestion : {singular: "Create Question"},
    questionDescription: {singular: "Question Description"},
    recorder : {singluar:"Recorder"},
    media: {sigular:"Media"},
    createSection: {singular: "Create Section"},
    complexity: {singular: "Complexity"},
    rangeDetails: { singular : "Range Details"},
    questionDetails: {singular: "Question Details"},
    range : {singular: "Range"},
    min: {singluar: "Min"},
    max: {singluar: "Max"},
    sectionCard: {singular: "Section Name"},
    sectionDescription:{singular: "Section Description"},
    concludingAnalysis: {singular: "Concluding Analysis"},
    concludingDescription: {singular: "Concluding Analysis Description"},
    topic: {singular: "Topic"},
    aiPoweredTest: {singular: "AI Powered Test Generation"},
    createAssessment: {singluar: "Create Assessment"},
    enablePoll: {singular: "Enable poll"},


  
    
    evaluate: {singular: "EVALUATE"},
    chosatmpt: {singular: "Choose an Attempt"},
    missingHtmlEmbedErr: { singular: "Invalid Url!"},
    certificatechoose : { singular: "Please select at least one option"},
  },

  exceptions: {
    member: {
      additions: {
        // dashboard: null,
        trainee: null,
        // organization: null,
      },
    },
  },
  trainer: {
    additions: {
      member: {},
      trainer: {},
      // organization: null,
    },
  },
  admin: {
    additions: {
      trainer: {},
    },
  },
};
