import React, { useEffect, useState } from "react";
import { withApiContext } from "context";
import SurveyGeneral from "../../pages/StudentSurvey/SurveyGeneral";
function SurveyRouter(props) {

  
  
 

  return (
    <>
     
        <SurveyGeneral
          from={"course"}
          fromSurvey={true}
          callSurvey={true}
          {...props}
        />
      
    </>
  );
}

export default withApiContext(SurveyRouter);
