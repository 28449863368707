import AsyncSelect from 'components/Select/AsyncSelect';
import { withApiContext, withLabelsContext, withUserContext } from 'context';
import React, { PureComponent } from 'react';




class CurriculumSelect extends PureComponent {
  fetch = async () => {
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd:  'get_subject_names',
      args: {},
    });

    return rsp.map(({ curriculum_id, name }) => ({
      label: name,
      value: curriculum_id,
    }));
  }

  render = () => (
    <AsyncSelect
      { ...this.props }
      optionName={this.props.ctx_labels.singular.curriculum}
      fetch={this.fetch}
      unselectedValue=""
    />
  );
}


export default
withApiContext(
  withLabelsContext(
    withUserContext(
      CurriculumSelect
    )
  )
)
