import StudentAssessments from "./StudentAssessments";

// export default StudentAssessments;

import { useTheme } from "styled-components";
export default function (props) {
  const theme = useTheme();

  return <StudentAssessments {...props} theme={theme} />;

  
}
