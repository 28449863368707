import React , { useRef , useEffect, useState} from 'react'
import Captcha from "react-google-recaptcha"
import './ReCaptcha.css'
import {
  withSiteContext,
  withApiContext,
} from "context";


function ReCaptcha(props) {
    const captchaRef = useRef(null);
    const captchaChange = () => {
        const token = captchaRef.current.getValue();
        console.log(token);
        props.onChange(token,"captchaCode");
        // captchaRef.current.reset();
        if(token !== ""){
          props.ctx_api
        .publicRequest({
          cmd: "captcha_validate",
          args: { token: token },
        })
        .then(handleCaptchaResponse)
        .catch(handleCapError);
        }
    }

    const handleCapError = (err) => {
      console.log(err);
      props.ctx_window.alert(err);
    };

    const handleCaptchaResponse = ({rsp}) => {
      props.onChange(rsp.status == '1',"captchaValid")
      if(rsp.status !== '1'){
        props.ctx_window.alert(rsp.statusText);
      }
      
    }
  return (
    <div className="captcha">
    <Captcha
    sitekey={props.ctx_site.site.siteKeyCaptcha}
    ref={captchaRef}
    onChange={captchaChange}
    />
    </div>
  )
}

export default withSiteContext(withApiContext(ReCaptcha))