const labels = {
  play:       'Play video',
  pause:      'Pause video',
  mute:       'Mute video',
  unmute:     'Unmute video',
  seek:       'Seek video',
  captions:   'Toggle captions',

  firstPage:    'First page',
  previousPage: 'Previous page',
  nextPage:     'Next page',
  lastPage:     'Last page',
  zoomIn:       'Zoom in',
  zoomOut:      'Zoom out',

  fullscreen: 'Toggle fullscreen mode',
  paneMenu:   'Select visible panes',
};


export default labels
