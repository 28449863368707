module.exports = {
  // Domain name of the Jitsi instance
  domain: 'jitsishard.learnerway.live',

  // Extra classes that need to be added to the JitsiFrame component
  extraClasses: 'w-75 vh-100',

  // URL to open media devices in new tab
  mediaDevicesUrl: '/media-devices',

  // Refer docs for options pararm of JitsiMeetExternalAPI(domain, options) here:
  // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe#creating-the-jitsi-meet-api-object
  // NOTE: roomName and userInfo will be taken from JWT in the localStorage
  options: {
    parentNode: document.getElementById('jitsi-frame'),
    configOverwrite: {
      startWithAudioMuted: true,
      startWithVideoMuted: true,
      disableTileView: true,
    },
  },
}
