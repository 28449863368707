import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Button, Row, Col } from 'react-bootstrap';
// import HtmlIframe from './Html/IframeWeb';
// import UploadButton from 'components/UploadButton/UploadButton';
// import HtmlIframe   from 'scenes/MemberHome/Module/Html/Iframe';
import HtmlIframe from './Iframe'
import { BlockUpload } from 'components'
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { StatusBar } from '@uppy/react';

import { withApiContext } from 'context';

import * as config from 'config';
import * as util   from 'services/util';

import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import './ScormInput.css';
import MediaQuery from 'react-responsive';


class ScormInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title:      null,
      previewUrl: null,
      ...this.initUploadState,
    };
    this.initUppy();
    this.justMounted = true;
  }

  initUploadState = {
    phase:       'ready',
    remoteFname: null,
  };

  static propTypes = {
    id:       PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  setIframeRef = (r) => {
    this.iframeElt = ReactDOM.findDOMNode(r);
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const scormId = this.props.isContentBuilder ? this.props.scormId : this.props.id;
    if (this.state.phase === 'uploading' && prevState.phase === 'ready') {
      this.doUpload();
    }   // Wait to display the preview (if any) until the iframe has been rendered
    // so the height can be correctly determined
    if(this.props.isContentBuilder) {
      if(_prevProps.scormId !== this.props.scormId)
      {
        this.fetchCourseInfo()
      }
    }
    if (this.justMounted) {
      this.justMounted = false;
      if ( scormId!= null) {
        this.fetchCourseInfo();
      }
    }
  }

  componentWillUnmount = () => {
    this.uppy.close();
  }

  fetchCourseInfo = () => {
    this.props.ctx_api.privateRequest({
      cmd:  'get_scorm_course_info',
      args: { courseId: this.props.isContentBuilder ? String(this.props.scormId) : String(this.props.id)},
    })
      .then(({ rsp }) => {
        this.setState({title:rsp.title,previewUrl:rsp.previewUrl.launchLink});
        if (this.props.isContentBuilder) {
          this.props.handleUploadSelection(rsp);
        }
      })
      .catch((err) => {
        console.log('SCORM course info fetch error: ', err.message);
      });
  }

  initUppy = () => {
    const restrictions = {
      maxNumberOfFiles: 1,
    };
    const uppyOptions = {
      restrictions,
      id:                   'uppy-scorm',
      allowMultipleUploads: false,
    };
    const headers = {
      // The tus client/server only allow certain headers, so we cannot use
      // x-access-token to pass the token
      'Authorization': util.getAuth(),
    };
    const tusOptions = {
      headers,
      endpoint:                   '/scorm/upload',
      removeFingerprintOnSuccess: true,
    };
    this.uppy = new Uppy(uppyOptions)
      .use(Tus, tusOptions)
      .on('cancel-all',   this.handleUppyCancelAll)
      .on('upload-retry', this.handleUppyRetry)
      .on('complete',     this.handleUppyComplete)
    this.uppy.addPostProcessor(this.postProcessUpload);
  }

  uploadFile = (file) => {
    this.uppy.reset();
    this.uppy.addFile({
      source: 'fileBrowser',
      name:   file.name,
      type:   file.type,
      data:   file,
    });
    this.setState({ phase: 'uploading' });
  }

  doUpload = () => {
    this.uppy.upload();
  }

  postProcessUpload = (fileIds) => {
    if (0 < fileIds.length) {
      const file = this.uppy.getFile(fileIds[0]);
      if (!file.error) {
        return this.doPostProcess(file)
          .catch(this.handlePostProcessError(file));
      }
    }
    return Promise.resolve();
  }

  setPostProcessProgress = (file, percent, phase) => {
    const mode = percent == null ? 'indeterminate' : 'determinate';
    const value = util.toInt(percent) / 100;
    if (value === 1) {
      phase = 'preparing for display';
    }
    this.uppy.emit('postprocess-progress', file, {
      mode,
      value,
      message: `Processing${phase ? ` (${phase.toLowerCase()})` : ''}...`,
    });
  }

  insertCourseSync = async (file, filename) => {
    this.setPostProcessProgress(file);
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd:  'insert_scorm_course_sync',
      args: { filename },
    });
    return rsp;
  }

  insertCourse = async (file, filename) => {
    try {
      this.setPostProcessProgress(file, 0);
  
      const { rsp: cookie } = await this.props.ctx_api.privateRequest({
        cmd: 'insert_scorm_course',
        args: { filename },
      });
      let r;
      do {
        await util.sleep(config.scormCourseInsertStatusPollInterval);
        r = await this.props.ctx_api.privateRequest({
          cmd: 'get_scorm_course_insert_status',
          args: { cookie },
        });
        this.setPostProcessProgress(file, r.rsp.progress, r.rsp.message);
      } while (r.rsp.status === 'RUNNING');
      if (this.props.isContentBuilder) {
        this.props.handleUploadSelection(r.rsp);
      }
  
      return r.rsp;
  
    } catch (error) {
      console.error('Error inserting course:', error);
      // Handle error appropriately
    }
  }
  
  doPostProcess = async (file) => {
    const url = file.uploadURL;
    const remoteFname = url.substring(url.lastIndexOf('/') + 1);
    this.setState({
      remoteFname,
      phase: 'post-processing',
    });
    const {
      courseId,
      title,
      previewUrl,
    } = await this.insertCourse(file, remoteFname);
    // Exit if the user cancelled the upload
    if (this.state.phase !== 'post-processing') {
      return;
    }
    this.uppy.emit('postprocess-complete', file);
    const length = 1;
    this.props.onChange({ id: courseId, length });
    this.setState({ title, previewUrl });
  }

  handlePostProcessError = (file) => (error) => {
    this.uppy.emit('upload-error', file, error);
  }

  finalizeUploadSuccess = () => {
    this.setState((s0) => s0.phase === 'done' ? { phase: 'ready' } : null);
  }

  handleUppyCancelAll = () => {
    this.setState((s0) => {
      if (s0.phase === 'post-processing') {
        // Perform any required cleanup
      }
      return this.initUploadState;
    });
  }

  handleUppyRetry = () => {
    this.setState({ phase: 'uploading' });
  }

  handleUppyComplete = ({ successful }) => {
    this.setState((s0) => {
      let phase;
      if (0 < successful.length) {
        setTimeout(this.finalizeUploadSuccess, 3000);
        phase = 'done';
      } else {
        phase =
          s0.phase === 'uploading' ? 'upload-error' :
                                     'post-process-error'
      }
      return { phase };
    });
  }

  handleUploadClick = () => {
    this.uppy.reset();
  }

  handleUploadSelection = (acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach(this.uploadFile);
    
  }

  handlePreviewReload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.fetchCourseInfo()
  }

  renderPreviewTitle = () => (
    <div className="module-scorm-preview-title">
      {this.state.title || (
        <React.Fragment>&nbsp;</React.Fragment>
      )}
    </div>
  );

  renderPreviewIframe = () => {
    const iframeTitle = this.state.title || 'SCORM preview iframe';
    return (
      <HtmlIframe
        className="module-scorm-preview-iframe"
        title={iframeTitle}
        url={this.state.previewUrl}
      />
    );
  }

  renderUploadStatus = () => {
    const classNames = ['module-scorm-upload-status'];
    const { phase } = this.state;
    if (phase === 'ready') {
      classNames.push('uppy-status-hidden');
    }
    if (phase === 'post-processing' || phase === 'post-process-error') {
      classNames.push('uppy-status-pause-retry-hidden');
    }
    return (
      <div className={classNames.join(' ')}>
        <StatusBar
          uppy={this.uppy}
          hideAfterFinish={false}
          showProgressDetails
        />
      </div>
    );
  }

  renderPreviewIframeUploadStatus = () => (
    <div >
      {this.renderIframe()}
      {/* {this.renderUploadStatus()} */}
    </div>
  );

  renderPreview = () => (
    <div>
      {/* {this.renderPreviewTitle()} */}
      {this.renderPreviewIframeUploadStatus()}
    </div>
  );

  handlePreviewReady = () => {
    this.setState({ loadingUrl: null });
  };

  renderIframe = () => {
    const iframeTitle = this.state.title || 'SCORM preview iframe';
    return(
    // <div className="module-html-iframe-wrapper">
      <HtmlIframe
        className="module-scorm-preview-iframe"
        title={iframeTitle}
        url={this.state.previewUrl}
      />
 
    )
  };

  renderUploadButton = () => {
    const { phase } = this.state;
    const enabled = phase === 'ready' || phase === 'done';
    const text =
      !enabled ? 'Uploading...' :
                 `Upload ${this.props.id ? 'different ' : ''} course...`;
    return (
      <BlockUpload
      disabled={!enabled}
      onClick={this.handleUploadClick}
      onChange={this.handleUploadSelection}
    />
      
    );
  }

  renderReloadButton = () => {
    const disabled = this.state.previewUrl === null;
    return (
      <Button
        className="module-scorm-preview-button"
        disabled={disabled}
        bsStyle={disabled ? 'default' : 'warning'}
        onClick={this.handlePreviewReload}
      >
        Reload preview
      </Button>
    );
  }

  renderControls = () => (
    <div className="module-scorm-controls">
      <div className='module-scorm-control1'>
        {this.renderReloadButton()}
      </div>
      <div className='module-scorm-control2'>
        {this.renderUploadButton()}
      </div>  
      <div className='module-scorm-control2'>
      {this.renderUploadStatus()}
      </div>  
    </div>
  );

  // render = () => (
  //   <div className="module-scorm">
  //     {this.renderPreview()}
  //     {this.renderControls()}
  //   </div>
  // );
  render = () => (
    this.props.isContentBuilder ? (
      <Row>
        <Col md={12}>
          {this.renderUploadButton()}
          {this.renderUploadStatus()}
        </Col>
      </Row>
    ) : (
      <Row className="module-pdf">
        <MediaQuery minDeviceWidth={374} maxDeviceWidth={767}>
          <>
            <Col sm={6}>
              <div className="module-content-left-box">
                {this.props.renderFormat("scorm")}
                {/* {this.displaySelectedContent()} */}
                {/* {this.renderUrl("desktopUrl", "Desktop URL")} */}
                {/* {this.renderUrl("mobileUrl", "Mobile URL")} */}
                {this.renderControls()}
              </div>
            </Col>
            <Col sm={6}>
              {this.renderPreview()}
            </Col>
          </>
        </MediaQuery>
  
        <MediaQuery minDeviceWidth={1023}>
          <>
            <Col sm={6}>
              <div className="module-content-left-box">
                {this.props.renderFormat("scorm")}
                {/* {this.displaySelectedContent()} */}
                {/* {this.renderUrl("desktopUrl", "Desktop URL")} */}
                {/* {this.renderUrl("mobileUrl", "Mobile URL")} */}
                {this.renderControls()}
              </div>
            </Col>
            <Col sm={6}>
              {this.renderPreview()}
            </Col>
          </>
        </MediaQuery>
  
        <MediaQuery minDeviceWidth={768} maxDeviceWidth={1000}>
          <>
            <Col sm={12}>
              <div className="module-content-left-box">
                {this.props.renderFormat("scorm")}
                {/* {this.displaySelectedContent()} */}
                {/* {this.renderUrl("desktopUrl", "Desktop URL")} */}
                {/* {this.renderUrl("mobileUrl", "Mobile URL")} */}
                {this.renderControls()}
              </div>
            </Col>
            <Col sm={12}>
              {this.renderPreview()}
            </Col>
          </>
        </MediaQuery>
      </Row>
    )
  );
  
}


export default
withApiContext(
  ScormInput
)
