import React from 'react';
import { Pause as PauseIcon } from '../icons/Pause';
import { PlayArrow as PlayArrowIcon } from '../icons/PlayArrow';
import { Button } from 'components';
import './PlayPause.css';




export default ({ onClick, paused, hidden, className, ariaLabelPlay, ariaLabelPause }) => {
    return (
        <div
          className={[
            'video-play-pause',
            className
          ].join(' ')}
          style={hidden ? { visibility: 'hidden' } : null}
        >
            <Button
                iconOnly
                variant="link" 
                className="p-0"
                // className="video-play-pause-button"
                style={{ width: "34px", height: "24px" }}
                onClick={onClick}
                disabled={hidden}
                aria-label={ paused
                    ? ariaLabelPlay
                    : ariaLabelPause }
                >
                { paused
                    ? <PlayArrowIcon
                        // className="video-play-pause-icon"
                        style={{height: "28px", width: "28px"}}
                        fill="#fff" />
                    : <PauseIcon
                        // className="video-play-pause-icon"
                        style={{height: "28px", width: "28px"}}
                        fill="#fff" /> }
            </Button>
        </div>
    );
};
