import { withApiContext, withLabelsContext, withUserContext } from "context";
import React, { useEffect, useState } from "react";
import * as util from "services/util";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";
import { OverlayTooltip } from "components";

const CreditHistoryTable = (props) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    const cmd =
      [1, 4].includes(props.ctx_user.user.type)
        ? "get_member_transactions"
        : "get_sme_transactions";
    const memberId =
      props.ctx_user.user.type === 4
        ? props.ctx_user.user.dependantId
        : props.ctx_user.user.id;
    const { rsp } = await props.ctx_api.privateRequest({
      cmd,
      args: { memberId },
    });
    setTransactions(rsp);
  };

  const mkActivityString = (transaction) => {
    const f =
      props.ctx_user.user.type === 1
        ? util.mkMemberActivityString
        : util.mkTrainerActivityString;
    const obj = {
      type: transaction.type,
      name: transaction.object_name,
    };
    return f(obj, props.ctx_labels.singular);
  };

  /* to group by Date -- start*/

  // const partitionTransactionsByDate = () => {
  //   const groups = [];
  //   let currDateStr;
  //   let i = -1;
  //   transactions.forEach((t) => {
  //     const dateStr = util.toDateString(new Date(t.datetime));
  //     if (dateStr !== currDateStr) {
  //       currDateStr = dateStr;
  //       i = i + 1;
  //       groups.push({
  //         dateStr,
  //         transactions: [],
  //       });
  //     }
  //     groups[i].transactions.push(t);
  //   });
  //   return groups;
  // };

  // const renderTransactionGroups = (groups) => {
  //   let transData = [];
  //   groups.map((g, dateIdx) => {
  //     g.transactions.map((t, transIdx) => {
  //       const newDateStr = transIdx == 0 ? g.dateStr : "";
  //       const activityStr = mkActivityString(t);
  //       transData.push({
  //         dateStr: newDateStr,
  //         activityStr: activityStr,
  //         credits: t.credits,
  //         balance: t.balance,
  //       });
  //     });
  //   });
  //   return transData;
  // };
  // const groups = partitionTransactionsByDate();

  /* to group by Date  -- end */

  const renderTransactions = () => {
    let transData = [];
    // groups.map((g, dateIdx) => {
    transactions.forEach((t, transIdx) => {
      // const newDateStr = transIdx == 0 ? g.dateStr : "";
      const activityStr = mkActivityString(t);
      transData.push({
        dateStr: t.datestr,
        activityStr: activityStr,
        credits: t.credits,
        balance: t.balance,
      });
    });
    // });
    return transData;
  };
  const groups = renderTransactions();

  const theme = useTheme();
  const tableStyles = getTableStyles(theme);

  const columns = [
    { name: "Date", selector: row => row.dateStr, sortable: false, width: "250px " },
    {
      name: "Activity", selector: row => row.activityStr, sortable: false, width: "300px",

      cell: (e) => {

        return (
          <OverlayTooltip placement="top" tipId={e.balance} tip={e.activityStr}>
            <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {e.activityStr}
            </span>
          </OverlayTooltip>
        );
      },
    },
    { name: "Credits", selector: row => row.credits, sortable: false, width: "250px " },
    { name: "Balance", selector: row => row.balance, sortable: false, width: "150px " },
  ];

  return (
    0 < groups.length && (
      <DataTable
        noHeader={true}
        columns={columns}
        // data={renderTransactionGroups(groups)} /* to group by date */
        data={groups}
        customStyles={tableStyles}
        pagination
        striped
        defaultSortField="no"
      />
    )
  );
};

export default withApiContext(
  withLabelsContext(withUserContext(CreditHistoryTable))
);
