import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './Iframe.css';

class HtmlIframe extends Component {
  constructor(props) {
    super(props);
    this.iframeElt = null;
  }

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    onLoad: PropTypes.func,
    onAbort: PropTypes.func,
    isContentbuilder: PropTypes.bool,
  };

  static defaultProps = {
    title: 'iframe',
  };

  componentDidMount = () => {
    this.setUrl(this.props.url);
  };

  componentDidUpdate(prevProps) {
    if (!this.props.isContentbuilder && prevProps.url !== this.props.url) {
      this.setUrl(this.props.url);
    }
  }

  setUrl = (url) => {
    if (this.iframeElt) {
      this.iframeElt.src = this.props.isContentbuilder ? (url ? url : 'about:blank') : url || 'about:blank';
    }
  };

  setIframeRef = (r) => {
    this.iframeElt = ReactDOM.findDOMNode(r);
  };

  render = () => {
    const {
      url,
      title, 
      ...restProps
    } = this.props;
    const className = ['module-html-iframe', this.props.className].join(' ');

    return (
      <iframe
        {...restProps}
        className={className}
        title={title}
        ref={this.setIframeRef}
        src={url} 
      />
    );
  };
}

export default HtmlIframe;
