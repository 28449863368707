import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import { Button } from 'react-bootstrap';
import { IconThumbsUp, IconThumbsDown } from 'Icons';
// , Glyphicon

import Emph              from './Emph';
import AnswerStatus      from './AnswerStatus';
import AnswerExplanation from './AnswerExplanation';
import ReviewLink        from './ReviewLink';

import * as config from 'config';


class Survey extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question:     PropTypes.string.isRequired,
    choices:      PropTypes.arrayOf(PropTypes.object).isRequired,
    answers:      PropTypes.arrayOf(PropTypes.string).isRequired,
    comments:     PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds:   PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered:   PropTypes.func.isRequired,
    onReview:     PropTypes.func.isRequired,
    onAttempted:  PropTypes.func.isRequired,
    type:         PropTypes.oneOf([
                    config.testQuestionTypeE.MULTIPLE_CHOICE,
                    config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE,
                  ]).isRequired,
  };

  testR = null;

  initState = {
    selected:  [],
    submitted: false,
  };

  componentDidMount = () => {
    const {user_selection} = this.props
    if(user_selection) {
      var arrayOfNumbers = user_selection.map(Number);
      // alert(JSON.stringify(arrayOfNumbers))
      this.setState({ selected:arrayOfNumbers });
    }

    this.acquireFocus();

  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.question !== this.props.question) {
      this.setState(
        this.initState,
        this.acquireFocus
      );
        if(newProps.user_selection) {
          var arrayOfNumbers = newProps.user_selection.map(Number);
          this.setState({ selected:arrayOfNumbers });
        }
    }
  }

  componentDidUpdate = (_prevProps, prevState) => {
    const {onAnswered, onAttempted} =this.props
    if (this.state.selected !== prevState.selected) {
      const response = this.state.selected ;
      this.state.selected.length > 0 ? onAttempted(true) :  onAttempted(false);
      onAnswered(response)
    }
  }


  acquireFocus = () => {
    const elt = ReactDOM.findDOMNode(this.testR);
    if (elt) {
      elt.focus();
    }
  }

  handleKeyDown = (e) => {
    if (!this.state.submitted) {
      e.stopPropagation();
      const choiceCount = this.props.choices.length;
      if (e.key === 'Enter' && 0 < this.state.selected.length) {
        this.handleSubmit(e);
      } else {
        const charCode = e.key.charCodeAt(0);
        if (65 <= charCode && charCode < 65 + choiceCount) {
          this.handleChoiceClick(charCode - 65)();
        } else if (97 <= charCode && charCode < 97 + choiceCount) {
          this.handleChoiceClick(charCode - 97)();
        }
      }
    }
  }

  handleChoiceClick = (i) => () => {
    const {id, onAttempted} = this.props;
    this.setState((s0) => {
      let idx = s0.selected.indexOf(i);
      let selected = [...s0.selected];
      if (idx !== -1) {
        selected.splice(idx, 1);
      } else if (
        this.props.type === config.testQuestionTypeE.MULTIPLE_CHOICE &&
        1 < this.props.answers.length
      ) {
        selected.push(i);
        selected.sort((a, b) => a - b);
      } else {
        selected = [i];
      }
      return { selected };
    });
      onAttempted(id)

  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
  }

  isSurveyQuestion = () =>
    this.props.type === config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE;

  indexToLetter = (i) => String.fromCharCode(65 + i);

  renderChoices = () => {
    const highestLength = this.props.choices.reduce(
      (highest, choice) => highest> choice.answerText.length ? highest : choice.answerText.length, 
      0
    );
    const widthClassName =  highestLength < 8 ? 'small' : 
                            highestLength < 30 ? 'medium' :
                            highestLength < 50 ? 'big' : 'large';
    return (
      <div className="test-question-choices-wrap">
        <div className="test-question-choices-items">
          {this.props.choices.map((choice, i) => {
            const letter = this.indexToLetter(i);
            const isSelected = this.state.selected.indexOf(i) !== -1;
            const isAnswer = this.props.answers.indexOf(choice.answerText) !== -1;
            const result =
              this.isSurveyQuestion() ? null                       :
              !this.state.submitted   ? null                       :
              !isSelected             ? null                       :
              isSelected &&  isAnswer ? <AnswerStatus.Correct   /> :
              isSelected && !isAnswer ? <AnswerStatus.Incorrect /> :
                                        null;
            const className = isSelected ? ' selected ' : '';
            const resultClass = 
              !this.state.submitted    ? ''                       :
              isAnswer  ? ' selected choice-correct-item'    :
              isSelected && !isAnswer  ? ' selected choice-incorrect-item'  : '';
            return (
              
              <div className={`tq-choices-item-wrap tq-choice-width-${widthClassName}`} key={i} >
                <div className="tq-choices-item-outer">
                  <div
                    className={'qs-choice-item ' + className + resultClass}
                    onClick={this.state.submitted ? null : this.handleChoiceClick(i)}
                    key={`answer-choice-${i}`}
                  >
                    {
                      this.state.submitted && (isAnswer || isSelected) &&
                      <div className="qci-on-submit">
                        <div className="answer-indication">
                        {isAnswer ?
                            <div className="answer-correct">
                              <IconThumbsUp/>
                            </div>
                            :
                            <div className="answer-correct">
                              <IconThumbsDown/>
                            </div>
                            
                          }
                        </div>
                      </div>
                    }
                    <div className="qci-item">
                      { 
                        choice.answerImage && 
                        <div className="qci-image">
                          <img src={`data:image/png;base64,${choice.answerImage}`} alt=""/>
                        </div>
                      }
                      <div className="qci-text">
                        <div className="qci-text-letter-wrap">
                          <div className="qci-text-letter-d">
                            <span className="qci-text-letter">{letter}</span>
                          </div>
                        </div>
                        <div className="qci-text-wrap">
                          <div className="qci-text-wrap-val">{choice.answerText}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
          })}


        </div>
      </div>
    );
  };

  renderIndex = (i, addReviewLink = false) => (
    <span>
      <Emph>{this.indexToLetter(i)}</Emph>
      {
        addReviewLink                                         &&
        0 <= this.props.reviewStarts[i]                       &&
        this.props.reviewStarts[i] < this.props.reviewEnds[i] && (
          <span>
            {' '}
            <ReviewLink
              id={`review-answer-${i}`}
              onClick={this.props.onReview(
                this.props.reviewStarts[i],
                this.props.reviewEnds[i],
              )}
            />
          </span>
        )
      }
    </span>);

  renderIndexList = (indexList, addReviewLinks = false) => {
    switch (indexList.length) {
      case 0:
        return null;
      case 1:
        return this.renderIndex(indexList[0], addReviewLinks);
      case 2:
        return (
          <span>
            {this.renderIndex(indexList[0], addReviewLinks)}
            {' and '}
            {this.renderIndex(indexList[1], addReviewLinks)}
          </span>);
      default:
        const init = [...indexList];
        const last = init.pop();
        return (
          <span>
            {init.map((i) => (
              <span key={`indexList-${i}`}>
                {this.renderIndex(i, addReviewLinks)}
                {', '}
              </span>))}
            {'and '}
            {this.renderIndex(last, addReviewLinks)}
          </span>);
    }
  }

  renderUserAnswer = () => (
    <div className="test-question-choices-footer">
      {/* <span>
        Your answer:
        &nbsp;
        {this.renderIndexList(this.state.selected)}
      </span> */}
      {!this.state.submitted && (
        <Button
          bsStyle="primary"
          disabled={this.state.selected.length === 0}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>)}
    </div>);

  renderExplanationDetails = (intro, indexList, descrip, key) => {
    const n = indexList.length;
    if (n === 0) {
      return null;
    }
    return (
      <div className="child" key={key}>
        <span>
          {intro && <span>{intro} {' '}</span>}
          {this.renderIndexList(indexList, true)}
          {1 < n ? ' are ' : ' is '}
          {descrip}
          {'.'}
        </span>
        {indexList.map((i) => this.props.comments[i] && (
          <div className="child" key={`${descrip}-${i}`}>
            <Emph>{this.props.choices[i].answerText}</Emph>
            {' : '}
            {this.props.comments[i]}
          </div>))}
      </div>);
  }

  renderExplanation = () => {
    if (this.isSurveyQuestion()) {
      return;
    }

    const isCorrect = (i) =>
      this.props.answers.indexOf(this.props.choices[i].answerText) !== -1;

    const correct = this.state.selected.filter(isCorrect);
    const incorrect = this.state.selected.filter((i) => !isCorrect(i));
    const choiceTexts = this.props.choices
      .map((choice) => { 
        return choice.answerText;
      });
    const missed = this.props.answers
      .map((answer) => {
        const i = choiceTexts.indexOf(answer);
        return this.state.selected.indexOf(i) === -1 ? i : -1;
      })
      .filter((i) => i !== -1);

    const allMissedDescrip =
      `the correct answer${1 < missed.length ? 's' : ''}`;
    const detailsArgs = 0 < correct.length ? [
      ['Well done, ',     correct,   'correct'     ],
      [null,              missed,    'also correct'],
      ['Unfortunately, ', incorrect, 'incorrect'   ],
    ] : [
      ['Unfortunately, ', incorrect, 'incorrect'     ],
      [null,              missed,    allMissedDescrip],
    ];

    return (
      <AnswerExplanation visible={this.state.submitted}>
        {detailsArgs.map((args, i) =>
          this.renderExplanationDetails.apply(this, [...args, i]))}
      </AnswerExplanation>
    );
  }

  render = () => (
    <div>
    <div className="test-question-assesment-title">
    Question {this.props.count}
    </div>
    <div className="test-question-text">
      {this.props.question}
    </div>
    <div
      tabIndex="-1"
      style={{ outline: 'none' }}
      onKeyDown={this.handleKeyDown}
      ref={(r) => { this.testR = r; }}
    >

      { this.props.image && 
        <div className="test-question-image">
          <img src={`data:image/png;base64,${this.props.image}`} alt=""/>
        </div>
      }
      {this.renderChoices()}
      {/* {this.renderUserAnswer()} */}
      {/* {this.renderExplanation()} */}
    </div>
    </div>);
}


export default Survey
