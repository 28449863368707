import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import css from "./block-upload.module.scss";
import classNames from "classnames";
import { IconImageFile } from "Icons";
import PropTypes from "prop-types";
import { withUserContext, withLabelsContext } from "context";
import Resizer from "react-image-file-resizer";
import { propTypes } from "react-bootstrap/esm/Image";
import { useTheme } from "styled-components";
import { rgba } from "polished";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
function BlockUpload({
  id,
  uploadType,
  value,
  onChange,
  maxSize,
  disabled,
  dimension,
  noCompression,
  isRemove,
  istoggleChange,
  fromQuestionBank,
  togglefunc,
  isCourse,
  fromTest,
  // imageSize,
  // ...props
  ...restProps
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [originalSize, setOriginalSize] = useState("");
  const [afterSize, setAfterSize] = useState("");
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": theme.primary,
    "--bgColor":rgba(theme.primary, 0.5)
  };
  const handleImageDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        onChange(window.btoa(reader.result));
        restProps.onDimensionChange && restProps.onDimensionChange(acceptedFiles);
      };
      if (noCompression) {
        reader.readAsBinaryString(file);
      }
      //props.imageSize === 0 ? no limit should be applied
      //props.imageSize > 0 ? props.imageSize
      //props.imageSize === undefined ? user context
      else {
        if (file) {
          //Temporary func()
          setOriginalSize(file.size);
        }
        let maxHeight = 100;
        let maxWidth = 250;
        if (restProps.cusomDimension) {
          maxHeight = parseInt(restProps.dimensionHeight);
          maxWidth = parseInt(restProps.dimensionWidth);
        }
        let type = file.type.substr(6, 10);
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          type,
          100,
          0,
          (uri) => {
            setAfterSize(uri.size);
            reader.readAsBinaryString(uri);
          },
          "blob"
        );
      }
    });
  };
  let maximumSize;
  if (maxSize === 0) {
    maximumSize = null;
  } else if (maxSize > 0) {
    maximumSize = (maxSize / (1024 * 1024)).toFixed(2);
  } else {
    maximumSize = restProps.ctx_user.user.maxImageSize / (1024 * 1024);
  }
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    // minSize: 0,
    // maxSize: { PhotoSize },
    maxSize: maxSize,
    maxFiles: 1,
    disabled: disabled,
    accept:
      // {'image/png': ['.png']},
      uploadType === "image"
        ? restProps.onlyPngIco ? { 'image/png': ['.png'] } : { 'image/*': ['.png', '.jpg', '.jpeg'] }
        : uploadType === "pdf"
          ? { 'application/pdf': ['.pdf'] }
          : uploadType === "video"
            ? { 'video/*': ['.mp4', '.avi', '.mkv'] }
            : uploadType === "audio"
              ? { 'audio/*': ['.mp3', '.wav', '.ogg'] }
              : uploadType === "ppt"
                ? { 'ppt': ['.pptx', '.pptm', '.ppt'] }
                : { 'application/zip': ['.zip'] },
    // maxSize: uploadType === "video" ?
    onDrop: (acceptedFiles, fileRejections) => {
      {
        uploadType !== "image"
          ? onChange(acceptedFiles, fileRejections)
          : acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              fromQuestionBank ? onChange(
                `data:${file.type};base64,${window.btoa(reader.result)}`, false
              )
                : onChange(
                  `data:${file.type};base64,${window.btoa(reader.result)}`)
            };

            // let type = file.type.substr(6,10)
            reader.readAsBinaryString(file);
          });
        if (fromQuestionBank && uploadType === 'image' && fileRejections.length > 0) {
          onChange(acceptedFiles, fileRejections)
        }
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        const photo = files.map((file) => file.preview);
      }
    },
    onFileDialogCancel: () => {
      togglefunc()
    },
    onFileDialogOpen: () => {
      if(togglefunc) togglefunc()
    }
  });

  const Icon = uploadType === "image" ? <IconImageFile /> : "";

  const filesp = acceptedFiles.map((file, ind) => (
    <div className={css.selectedFile} key={ind}>
      <div className="text-clamp-2">{file.name}</div>
      <div className={`${css.info_row}`}>
        <div className={css.file_info}>
          {Icon} {file.size} bytes
        </div>
        {/* <div className="text-primary cursor-pointer" onClick={removeAll}> */}
        {restProps.ctx_labels.singular.remove}
      </div>
      {/* </div> */}
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const removeHandleClick = () => {
    onChange(null, "remove");
    restProps.onDimensionChange && restProps.onDimensionChange(null);
    setAfterSize(null);
    setOriginalSize(null);
  };
  const fileRegCon = fromQuestionBank ? (fileRejections.length > 0 && istoggleChange == false) : fileRejections.length > 0;
  return (
    <div className={css.wrapper}>
    <div
  className={classNames(
    isCourse ? css.block1 : null, // If props.isCourse is true, apply css.bblovk class, otherwise null
     // If isDragActive is true, apply css.active class
     fromTest ? css.testBlock :null,
     !fromTest ? css.block :null,
     !isCourse ? css.block : null,
     isDragActive && css.active
      // If props.isCourse is false and isDragActive is true, apply css.block class
  )}
id={id?id:null}
  
  style={inlineBackground}
>

        {value && uploadType == "image" && (
          <img
            className={css.image_size_selector}
            src={value}
          />
        )}
        {selectedFiles.length ? (
          filesp
        ) : (
          <div {...getRootProps()} className={!disabled && css.droparea}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <div>
                  <div className={css.icon_drag}>
                    {Icon && <IconImageFile />}
                  </div>
                  Drop here
                  <div className={css.small}>Leave the file here to upload</div>
                </div>
              </>
            ) : (
              <>
                <div>
                  {restProps.ctx_labels.singular.dragMsg}
                  <div className={css.small}>
                    Or <span className="text-primary">{restProps.ctx_labels.singular.browse}</span> {restProps.ctx_labels.singular.your} <b> {uploadType} </b>
                    {restProps.ctx_labels.singular.filetoUpload}
                  </div>
                </div>

                {Icon ? (
                  <div className={`${css.info_row}`}>
                    <div className={css.file_info}>
                      <IconImageFile /> {restProps.ctx_labels.singular.imageOnly}
                    </div>
                    {uploadType === 'ppt' ? null : maxSize !== 0 && <div>{restProps.ctx_labels.singular.maxSize} {maximumSize} MB</div>}
                  </div>
                )
                  :

                  <div className={`${css.info_row}`}>
                    <div className={css.file_info}>
                    </div>
                    {uploadType === 'ppt' ? null : maxSize !== 0 && <div>{restProps.ctx_labels.singular.maxSize} {maximumSize} MB</div>}
                  </div>
                }
                <div>
                  {dimension && (
                    <div className={css.dimension}>
                      {" "}
                      &nbsp;&nbsp;&nbsp; {restProps.ctx_labels.singular.preferredSize} {dimension}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {value && isRemove && !disabled && (
        <div className={`${css.small}`}>
          <span
            className={`${css.remove_text} text-primary`}
            onClick={removeHandleClick}
          >
            {restProps.ctx_labels.singular.remove}
          </span>{" "}
          {uploadType}
        </div>
      )}

      {/* {value && !disabled && uploadType == "image" && (
        <div className={`${css.small}`}>
          <span
            className={`${css.remove_text} text-primary`}
            onClick={removeHandleClick}
          >
            Remove
          </span>{" "}
          Image
        </div>
      )} */}


      {/* error */}
      {fileRegCon && (
        <div className={`text-danger ${css.error}`}>
          {fileRejections[0].errors[0].code === "file-invalid-type"
            ? restProps.ctx_labels.singular.invalidFile
            : fileRejections[0].errors[0].code === "too-many-files"
              ? "Too Many Files"
              : restProps.ctx_labels.singular.filetooLarge}
        </div>
      )}
      {/* : null } */}
      {afterSize && (
        <div //Temporary element delete on production
        // style={{
        //   width: "fit-content",
        //   display: "inline-grid",
        //   margin: "5px 1px",
        // }}
        >
          <span className={`${css.medium}`}>
            {`Original size: ${Math.trunc(originalSize / 1024)}kb 
            Compressed size: ${Math.trunc(afterSize / 1024)}kb`}
          </span>
        </div>
      )}
    </div>
  );
}

BlockUpload.propTypes = {
  uploadType: PropTypes.string,
  isRemove: PropTypes.bool,
};

export default withUserContext(withLabelsContext(BlockUpload));
