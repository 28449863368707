import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import "./Audio.css";

const Audio = (props) => {
  useEffect(() => {
    const audio = document.getElementById(`audio-${props.mediaId}`);
    const source = document.getElementById(`audioSource-${props.mediaId}`);
    source.src = props.url;

    audio.load();
  }, [props.url]);

  return (
    <>
        <audio id={`audio-${props.mediaId}`} controls className="audioNew">
          <source id={`audioSource-${props.mediaId}`} src={props.url} type="audio/mpeg" />
        </audio>
    </>
  );
};

export default Audio;
