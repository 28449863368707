import React from "react";
import StarRating from "./StarRating";
import MultipleChoiceScale from "./MultipleChoiceScale";
import MultipleChoice from "./MultipleChoice";
// import MultipleChoice from "components/Player/TestQuestion/MultipleChoice";
import Comment from "./Comment";
import { Card, Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import css from "../../pages/Assessments/StudentAssessments/student-assessments.module.scss";
import { withApiContext, withWindowContext, withUserContext, withLabelsContext } from "context";
import { HelpfulButton } from "components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as config from "config";
import { Redirect } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Loader from "components/Spinners/Loader";
import './StudentSurvey.css'
import ChapterInner from "pages/ChapterInner";
import { IconSurveySave, IconSurveySubmit, IconSurveyCancel } from "Icons";

const StudentSurvey = (props) => {
  const [survey, setSurvey] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [surveyId, setsurveyId] = useState("")
  const history = useHistory();
  const [modified, setModified] = useState(false)
  const [disable, setDisable] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isModified, setIsModified] = useState(false)
  

  useEffect(()=>{
    
    if(props.survey?.preview && !hasFetched){
      fetchSurvey();
      setHasFetched(true);
    }

    return()=>{
      setHasFetched(false);
    }
  },[])

  useEffect(() => {
    
    if (props.fromSurvey && !hasFetched) {

      if(props.from === "course" ){
       
        fetchSurvey();
      }
      else{
        fetch(props);
      }
     
      setHasFetched(true);
    }

    if (props.name === "preview") {
      setSelectedSurvey(props.value)
      props.preview && props.onValueChange(true)
    }
    

    if (selectedSurvey?.questions) {
      const modified = selectedSurvey.questions.every(question => {
        if (question.user_selection) {
          if (question.questionType === "0") {
            return question.user_selection.selected_id.length > 0;
          } else if (question.questionType === "1") {
              return question.user_selection.comment.length > 0;
          } else {
              return true;
        }
        } else {
          return false; 
        }
      });
      if (modified) {
        setModified(true);
      }
      if (!modified) {
        setModified(false);
      }
      const isModified = selectedSurvey.questions.some(question => question.user_selection);
      if (isModified) {
        setIsModified(true);
      }
    }

  }, [props.fromSurvey, selectedSurvey]);

  const fetch = async (action) => {
    await props.ctx_api
      .privateRequest({
        cmd: "get_publish_survey_details_2",
        args: {
          publishSurveyId:props.survey.id.toString()
        },
      })
      .then(handleFetchResponse)
    // .catch(handleFetchFailure);
  };


  const fetchSurvey = async () => {
    try {
      const response=await props.ctx_api
      .privateRequest({
        cmd: "get_survey_dtl",
        args: {
           testId: props.survey?.id?props.survey.id.toString():props.survey.videoUnitId 
        },
      })
      transform(response.rsp)
      setSurvey(transform(response.rsp))
    setsurveyId(props.survey.id?props.survey.id.toString():props.survey.videoUnitId)
    setSurveyName(response.rsp[0].name)
setSelectedSurvey(response?.rsp[0]?.questionlist[0])
      console.log(response.rsp[0].questionlist[0])
      
    } catch (error) {
      
    }
  };

const transform=(currentData)=>{
  // console.log("🔥currentData",currentData)
  const transformedData = currentData.map(item => ({
    survey_id: props.survey.id?props.survey.id.toString():props.survey.videoUnitId,
    descriptions:item.description,
    survey_name: item.name,
    survey_short_name: item.name,
    survey: item.questionlist.map(surveyItem => ({
      questions: surveyItem.questions,
      sectionName: surveyItem.sectionName,
      sectionOrder: surveyItem.sectionOrder
    }))
  }))
  // console.log("🔥transformedData",transformedData)
  return transformedData
}

const handleTimeInsert=async()=>{
  // console.log("🔥C🔥","insert_update_trainee_module_time")
  setRedirect(true)
//  try {
//   const req={
//     timeId: "1",
//     inProgress: 0,
//     status: 1,
//     courseId: props.subjectData.course_id,
//     memberId: props.subjectData.memberId,
//     videoUnitId: props.subjectData.videoUnitId,
//     curriculumId: props.subjectData.curriculum_id,
//     coursePublishId: props.subjectData.course_publish_id,
//     lastViewedPos: 1,
//     maxViewedPos: 1,
   
//   }

//   const response= await props.ctx_api
//   .privateRequestRow({
//     cmd: "insert_update_trainee_module_time",
//     args: req,
//   })

//   console.log(response)
//  } catch (error) {
//   console.log(error)
//  }

//  try {
//   const req2={
// acadYearId: props.subjectData.acadYearId,
// 	chapterId:props.subjectData.chapterId,
// 	classId: props.subjectData.class_id,
// 	courseId: props.subjectData.course_id,
// 	coursePublishId: props.subjectData.course_publish_id,
// 	curriculumId: props.subjectData.curriculum_id,
// 	memberId: props.subjectData.memberId,
// 	type: "S",
// 	videoUnitId:props.subjectData.videoUnitId,
   
//   }

//   const response2= await props.ctx_api
//   .privateRequestRow({
//     cmd: "/insert_update_trainee_points",
//     args: req2,
//   })

  
//   setRedirect(true)
//  } catch (error) {
//   setRedirect(true)
//   console.log(error)
//  }

 



}

  const handleFetchResponse = (rsp) => {
    setSurvey(rsp.rsp)
    setsurveyId(rsp.rsp[0].survey_id)
    setSurveyName(rsp.rsp[0].survey_name)
    setSelectedSurvey(rsp.rsp[0].survey[0]);
    return null;
  };
  const questionDataChange = (answers) => {
    setSelectedSurvey(answers);
  };

  const handleDisable = (changedArray) => {
    const answers = changedArray.map((r) => {
      return r.user_selection;
    });
    const haveElements = answers.every((array) => array.length > 0);
    return haveElements;
  };

  const handleCancel = () => {
    console.log("cancel trigerred")
    setRedirect(true)
  }
  const handleClick = (item) => {

    const flag=props.from==="course"
    // console.log("🔥C🔥",survey,"--",selectedSurvey,surveyId,surveyName,flag)
   
    try{
      let args = {
        memberId: props.ctx_user.user.id,
        publishSurveyId: props.survey.id?props.survey.id.toString():null,
        coursePublishId:flag?props.survey.course_publish_id:"0",
        surveyId: surveyId,
        action: item,
        surveyAssessmentId: "0",
        surveyDetails: JSON.stringify(survey.map((sec, i) => {

          console.log("//",sec)
          return {
            survey_id: sec.survey_id,
            description: sec.description,
            survey_name: sec.survey_name,
            survey_short_name: sec.survey_short_name,
            survey: sec.survey.map((s, i) => {
              return {
                questions: selectedSurvey.questions,
                sectionName: selectedSurvey.sectionName,
                sectionOrder: selectedSurvey.sectionOrder
              };
            }),
          };
        })
        )
      };
      props.ctx_api
        .privateRequestRow({
          cmd: "insert_survey_assessment",
          args: args,
        })
        .then(({ rsp }) => {
          if (rsp.statusvalue === 1) {
            const value = item == "save" ? "Saved" : "Submitted"
            props.ctx_window.alert(`Survey ${value} successfully`);
            handleTimeInsert()
         
          } else if (rsp.statusvalue === 2) {
            props.ctx_window.alert("Survey saved successfully");
          
           
            handleTimeInsert()
          }
          else {
            props.ctx_window.alert("Failed to submit");
            handleTimeInsert()
          }
        });

    }catch(e){
      // console.log("⚠️",e)
    }


  };


  const redirecting = () => {
    
    const flag=props.from==="course"
    // history.push("/content");
    let redirect;
    // console.log("⚠️ redirectInitiated",flag,props.from, props.subjectData);
    if (props.from==="course") {
     


      redirect = (
        <Redirect
          to={{
            pathname: props.survey.origin?props.survey.origin: "/content",
            subjectData: props.subjectData,
            state: {
              location: props.location,
              subjectData: props.subjectData,
              courseId: props.courseId,
              coursePublishId: props.coursePublishId,
              grouped: props.grouped,
              fromSurvey: true,
            },
          }}
        />
      );
    } else if ( props.from === "survey") {
      redirect = (
        <Redirect
          to={{
            pathname: "/StudentSurvey",
            state: {
              filter: props.filter,
            },
          }}
        />
      );
    } else {
      redirect = null;
    }
  
    console.log("⚠️ redirectLunhed");
  
    return redirect;
  };

  // const handleData =() =>{
  //   if (redirect) {
  //     if (props.fromSurvey) {
  //       // Redirect to the studentSurvey route
  //       <Link
  //         to={{
  //             pathname: "/StudentSurvey",
  //             state: { survey: survey} 
  //             }}/>
  //     } else {
  //       // Call the handleSubmitRef function
  //       props.handleSubmitRef();
  //     }
  //   }
  // }


  const screenSize = window.screen.width

  return (

redirect?(redirecting()):


   selectedSurvey?
    <div className="surveyViewMainWrapper"  style={{height: "100%" }}>
        <Col style={{ paddingTop: "1rem",height: "100%"  }}>
          <div className="surveyViewWrapper" style={{height: "100%" }}>
            {props.name !== "preview" &&
              <Card.Body className="card_button">
                <div className="survey-question-text">{surveyName}</div>
                {
                  props.hide?null:<div>
                  {
                    props.from!=="course"&&<HelpfulButton
                    variant="primary"
                    size="sm"
                    pill
                    wide
                    style={{ borderRadius: "5px", marginRight: "5px",marginBottom :"0px"}}
                    className={css.save}
                    // tip={
                    //   !disable
                    //     ? "One or more fields are incomplete"
                    //     : "Submit Survey"
                    // }
                    disabled={!isModified}
                    onClick={() => handleClick("save")}
                  >
                    <IconSurveySave style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }} />
                    <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.save}</span>
                    {/* {window.screen.width > 822 ? props.ctx_labels.singular.save : null} */}
                  </HelpfulButton>
                  }
                  <HelpfulButton
                    variant="success"
                    size="sm"
                    pill
                    wide
                    style={{ borderRadius: "5px", marginRight: "5px",marginBottom : "0px"  }}
                    className={css.submit}
                    // tip={
                    //   !disable
                    //     ? "One or more fields are incomplete"
                    //     : "Submit Survey"
                    // }
                    disabled={!modified}
                    onClick={() => handleClick("submit")}
                  >
                    <IconSurveySubmit style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }} />
                    <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.submit}</span>
                  </HelpfulButton>
                  <HelpfulButton
                    variant="danger"
                    size="sm"
                    pill
                    wide
                    style={{ borderRadius: "5px", marginRight: "5px", marginBottom : "0px" }}
                    className={css.cancel}
                    // tip={
                    //   !disable
                    //     ? "One or more fields are incomplete"
                    //     : "Submit Survey"
                    // }
                    // disabled={props.survey.status === "Attended"}
                    onClick={handleCancel}
                  >
                    <IconSurveyCancel style={{ width: "16px", height: "16px", cursor: "pointer", fill: "white" }} />
                    <span className={css.label}>{screenSize > 822 && props.ctx_labels.singular.cancel}</span>
                  </HelpfulButton>
                </div>
                }
              </Card.Body>}
            <Card className="questioncard">
              <Card.Body>

                {selectedSurvey && selectedSurvey.questions && selectedSurvey.questions.map((section, i) => {
                  const lastIndex = selectedSurvey.questions.length - 1;
                  const isAttended = props?.survey && props.survey?.status === "Attended";
                  return (
                    <div key={i} className={isAttended ? (i === lastIndex ? "last_index1" : "view-survey")  : (i === lastIndex ? "last_index" : "attend-survey")}>
                      {selectedSurvey.questions[i].questionType == 1 &&
                        <Comment question={selectedSurvey.questions[i].question}
                          questionOrder={selectedSurvey.questions[i].question_order}
                          survey={true}
                          onAnswered={questionDataChange}
                          surveyDetails={selectedSurvey}
                          from={props.name}
                          // autoFocus
                        >
                        </Comment>
                      }
                      {selectedSurvey.questions[i].questionType == 2 &&
                        <StarRating question={selectedSurvey.questions[i].question}
                          questionOrder={selectedSurvey.questions[i].question_order}
                          survey={true}
                          displayType={selectedSurvey.questions[i].ratingOptionType == "three" ? 3 : 4}
                          starRating={[]}
                          starRatings={selectedSurvey.questions[i].choices}
                          onAnswered={questionDataChange}
                          surveyDetails={selectedSurvey}
                          from={props.name}
                          // autoFocus
                          >
                        </StarRating>
                      }
                      {selectedSurvey.questions[i].questionType == 0 && selectedSurvey.questions[i].multipleChoiceType == "scale" &&
                        <div>
                          <MultipleChoice
                            question={selectedSurvey.questions[i].question}
                            questionOrder={selectedSurvey.questions[i].question_order}
                            choices={selectedSurvey.questions[i].choices}
                            answers={selectedSurvey.questions[i].choices}
                            onAnswered={questionDataChange}
                            survey={true}
                            surveyDetails={selectedSurvey}
                            from={props.name}
                            // autoFocus
                          >
                            {/* {React.createElement(questionClass, surveyProps)} */}
                          </MultipleChoice>
                        </div>}
                      {selectedSurvey.questions[i].questionType == 0 && selectedSurvey.questions[i].multipleChoiceType == "dropdown" &&
                        <div>
                          <MultipleChoiceScale
                            question={selectedSurvey.questions[i].question}
                            questionOrder={selectedSurvey.questions[i].question_order}
                            choices={selectedSurvey.questions[i].choices}
                            answers={selectedSurvey.questions[i].choices}
                            onAnswered={questionDataChange}
                            survey={true}
                            surveyDetails={selectedSurvey}
                            from={props.name}
                            // autoFocus
                            >

                          </MultipleChoiceScale>
                        </div>}
                    </div>

                  )
                })}

              </Card.Body>
            </Card>
          </div>

        </Col>
      </div>:<><h1>no survey....</h1></>
   
  );
};

StudentSurvey.defaultProps = {
 hide: false,
 
};
export default withApiContext(withWindowContext(withUserContext(withLabelsContext(StudentSurvey))));
