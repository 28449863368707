import PropTypes from 'prop-types';
import React from 'react';
import './BaseControls.css';
import Continue from './BaseControls/Continue';
import Fullscreen from './BaseControls/Fullscreen';
import PaneMenu from './BaseControls/PaneMenu';
import Download from "./BaseControls/Download";
import classNames from 'classnames';


const BaseControls = ({
  continueVisible,
  continueLabel,
  onContinue,
  isFullscreen,
  toggleFullscreen,
  preview,
  playerState,
  viewType,
  subjectData,
  playerOnly,
  downloadable,
  handleDownload,
  fromChapterInner,
  fromMultiPreview,
  pdfLoaded,
  type,
  moduleProgress,
  isIphone,
  isLoom
  // ...paneMenuProps
}) => {
const screenSize = window.innerWidth < 768 ? false : true;
return (
  <div
    className={classNames(
      (fromChapterInner || fromMultiPreview) && !screenSize
        ? "base-controls-mob"
        : isLoom
        ? "loom-base-controls"
        : "base-controls"
    )}
  >
    {type === "pdf" ? (
      pdfLoaded === true && (
        <Continue
          visible={continueVisible}
          label={continueLabel}
          onClick={onContinue}
          playerState={playerState}
          viewType={viewType}
          subjectData={subjectData}
          preview={preview}
          playerOnly={playerOnly}
          fromChapterInner={fromChapterInner}
        />
      )
    ) : type === "test" ||type === "survey"? (
      moduleProgress === 100 && (
        <Continue
          visible={continueVisible}
          label={continueLabel}
          onClick={onContinue}
          playerState={playerState}
          viewType={viewType}
          subjectData={subjectData}
          preview={preview}
          playerOnly={playerOnly}
          fromChapterInner={fromChapterInner}
        />
      )
    ) : (
      <Continue
        visible={continueVisible}
        label={continueLabel}
        onClick={onContinue}
        playerState={playerState}
        viewType={viewType}
        subjectData={subjectData}
        preview={preview}
        playerOnly={playerOnly}
        fromChapterInner={fromChapterInner}
      />
    )}
    {type !== "test" && isIphone === false && isLoom === false ?(
      <Fullscreen
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    ):null}
    {!fromChapterInner && downloadable && playerState === "playing" && (
      <Download downloadContent={handleDownload} />
    )}
    {/* {paneMenuProps.paneMenuEntries&&!preview&&<PaneMenu {...paneMenuProps} />} */}
  </div>
);
};

BaseControls.propTypes = {
  continueVisible:  PropTypes.bool.isRequired,
  continueLabel:    PropTypes.string,
  onContinue:       PropTypes.func,
  isFullscreen:     PropTypes.bool.isRequired,
  toggleFullscreen: PropTypes.func,
  playerOnly: PropTypes.bool.isRequired,
  downloadable: PropTypes.bool,
  handleDownload: PropTypes.func,
  isIphone:PropTypes.bool,
}


export default BaseControls
