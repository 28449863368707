// import { Button, PageTitle } from "components";
import React, { Component } from "react";
import { MainContainer } from "pages/Main";
import { Card, Col, Row, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Question from "./Question/Question";
import Section from ".././../../pages/Assessments/StudentAssessments/Section/Section";
import css from "../../../pages/Assessments/StudentAssessments/student-assessments.module.scss";
import { withRouter } from "react-router-dom";
import { withApiContext, withLabelsContext, withWindowContext, withUserContext, } from "context";
import Timer from "react-compound-timerv2/build";
// import Instructions from "../InternalItems/Instruction";
// import MarkDetails from "../InternalItems/MarkDetails";
import Question from "../../../pages/Assessments/InternalItems/Question";
import Questions from "../../../pages/Assessments/InternalItems/Questions";
import MediaQuery from "react-responsive";
// import Summary from "../InternalItems/Summary";
import TimeDetails from "../../../pages/Assessments/InternalItems/TimeDetails";
import Loader from "components/Spinners/Loader";
import * as config from "config";
import { Spin as SpinIcon } from "components/Spinners/Spin";
import { Redirect } from "react-router";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import ScrollBar from "react-perfect-scrollbar";
import TableSummary from "./TableSummary/TableSummary";
import { Badge, Modal } from "react-bootstrap";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import DataTable from "react-data-table-component";
import getTableStyles from "themes/table-styles";
import PropTypes from "prop-types";
import {
  OverlayTooltip,
  Breadcrumb,
  Button,
  JustifyBetween,
  PageTitle,
  RadioButton,
} from "components";
import * as util from "services/util";
import './StudentAssessments.css';
import styled from "styled-components";
import { rgba } from "polished";
import HelpfulButton from "components/Button/HelpfulButton";
import "jodit";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import ChartDoughnut from "components/ChartDoughnut/ChartDoughnut";

const StyledPlayerMessage = styled.div`
  && {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
    height: 100%
  }
`;

const StyledChapterRibbon = styled.div`
  && {
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => rgba(theme.color4, 0.5)};
    ${"" /* color: ${({ theme }) => theme.button.primary.text} */}
  }
`;

const Pagination = () => {
  return (
    <div className={css.pagination}>
      <Button variant="gray" pill wide>
        Previous
      </Button>
      <div className={css.pagination_number}>
        <span>4</span> of 8
      </div>
      <Button variant="primary" pill wide>
        Next
      </Button>
    </div>
  );
};

class StudentAssessments extends Component {
  static propTypes = {
    className: PropTypes.string,
    module: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
    playerState: PropTypes.element.isRequired,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirect: false,
      onClickBackButton: false,
      tests: null,
      questionSection: [this.initSectionItem()],
      questionAnswer: [],
      testQuestions: {},
      testAnswers: {},
      selectedItem: null,
      assessmentId: null,
      index: 0,
      prevIndex: 0,
      direction: "prev",
      startBtn: null,
      btnActive: false,
      allSaved: true,
      updateElapsedTimeOrNot: true,
      alertTime: false,
      duration: 0,
      action: 0,
      assessmentDetails: {},
      testState: 1,
      testClosed: false,
      viewSummary: false,
      COLORS: [
        "#0e4f69",
        "#FFBB28",
        "#00C49F",
        "#08a822",
        "#00A49F",
        "#FFDB48",
        "#FG8012",
      ],
      status: 0,
      summaryData: null,
      summaryError: null,
      testAssessmentId: null,
      loading: true,
      pdfUrl: "",
      modalOpen: false,
      testSubmittedFlag: false,
      lattestAssessmentId: null,
      sections: [],
      modalFeedbackOpen: false,
      feedback: "",
      flagged: "",
      unattempted: null,
      incorrect: null,
      correct: null,
      totMarks: null,
      attempted: null,
      totQuestions: null,
      dateFrom: null,
      dateTo: null,
      elapsedTime: null,
      obtMarks: null,
      pdfNameUrl: "",
      pdfUrlFile: "",
      pdfUrlType: null,
      rand_question: 0,
      modalAssignmentOpen: false
    };
  }
  initSectionItem = () => ({
    section_order: 0,
    section_name: "",
    questions: [],
  });

  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  componentDidMount = () => {
    if (this.props != undefined) {
      this.getStudentAssessmentQuestions();
    }
  };
  componentWillUnmount = () => {
    this.setState({
      testSubmittedFlag: false
    });

  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.publishId !== this.props.publishId) {
      this.setState({ assessmentId: null, selectedItem: null, viewSummary: false }, () => this.getStudentAssessmentQuestions())
    }
    if (prevProps.playerState !== this.props.playerState) {
      this.setState({ viewSummary: false })
    }
  }
  getStudentAssessmentQuestions = () => {

    const publish_id = this.props.publishId;


    // const publish_id = this.props.location.assessmentData.assessmentData.detailsData.assessmentId;

    // alert(publish_id)
    this.props.ctx_api
      .privateRequestRow({
        cmd: "get_student_assessment_details",
        args: { publishId: publish_id.toString() },
      })
      .then(this.handleGetTestResponse)
      .catch(this.handleGetTestFailure);
  };
  handleGetTestResponse = ({ rsp }) => {
    const { testDetails, onBackToCourse } = this.props;
    this.setState({ lattestAssessmentId: rsp.latest_assessment_id });



    const tests = {
      publishId: this.props.publishId,
      id: rsp.test_id,
      title: rsp.name,
      identifier: rsp.identifier,
      description: rsp.description,
      category: rsp.standard,
      subject: rsp.subject,
      dateFrom: rsp.from_date,
      dateTo: rsp.to_date,
      timerFrom: rsp.timer_from,
      timerTo: rsp.timer_to,
      duration: rsp.duration,
      status: rsp.status,
      action: rsp.action,
      elapsedTime: rsp.elapsed_time,
      lattestAssessmentId: rsp.latest_assessment_id,
      totMarks: rsp.totmarks,
      obtMarks: rsp.obtmarks,
      totQuestions: rsp.totquestions,
      correct: rsp.correct,
      incorrect: rsp.incorrect,
      missed: rsp.missed,
      attempted: rsp.attempted,
      unattempted: rsp.unattempted,
      flagged: rsp.flagged,
      testState: rsp.test_state,
      testStateName: rsp.test_state_name,
      testType: rsp.test_type,
      testTypeName: rsp.test_type_name,
      multiAttempt: rsp.multi_attempt,
      totDuration: rsp.total_duration,
      attemptCount: rsp.attempt_count,
      coursePublishId: rsp.course_publish_id,
      courseId: rsp.course_id,
      feedback: rsp.feedback,
      groupedTest: rsp.grouped_test,
      summary_viewable: rsp.summary_viewable,
    };
    this.setState({
      feedback: rsp.feedback,
      unattempted: rsp.unattempted,
      incorrect: rsp.incorrect,
      correct: rsp.correct,
      totMarks: rsp.totmarks,
      attempted: rsp.attempted,
      flagged: rsp.flagged,
      totQuestions: rsp.totquestions,
      dateFrom: rsp.from_date,
      dateTo: rsp.to_date,
      duration: rsp.duration,
      elapsedTime: rsp.elapsed_time,
      obtMarks: rsp.obtmarks,
      summary_viewable: rsp.summary_viewable,
    })

    this.setState({ tests, rand_question: rsp.rand_question }, () => {

      this.setState((s0) => ({
        prevIndex: s0.index,
        index: s0.index + 1,
        status: 1,
        action: 1,
      }));
    });
    if (this.state.tests.testStateName == "Yet to Start" || this.state.tests.testStateName == "In Progress") {
      this.setState({ testSubmittedFlag: false });
      this.createStudentAssessment();







    }
    else {
      this.setState({ testSubmittedFlag: true });
      // this.props.onBackToCourse()
      this.props.onAllViewed();





      // this.viewSummary()


    }
  };
  handleGetsTestResponse = (rsp) => {

    const { testDetails, onBackToCourse } = this.props;
    this.setState({ lattestAssessmentId: rsp.latest_assessment_id });

    const tests = {
      publishId: this.props.publishId,
      id: rsp.test_id,
      title: rsp.name,
      identifier: rsp.identifier,
      description: rsp.description,
      category: rsp.standard,
      subject: rsp.subject,
      dateFrom: rsp.from_date,
      dateTo: rsp.to_date,
      timerFrom: rsp.timer_from,
      timerTo: rsp.timer_to,
      duration: rsp.duration,
      status: rsp.status,
      action: rsp.action,
      elapsedTime: rsp.elapsed_time,
      lattestAssessmentId: rsp.latest_assessment_id,
      totMarks: rsp.totmarks,
      obtMarks: rsp.obtmarks,
      totQuestions: rsp.totquestions,
      correct: rsp.correct,
      incorrect: rsp.incorrect,
      missed: rsp.missed,
      attempted: rsp.attempted,
      unattempted: rsp.unattempted,
      flagged: rsp.flagged,
      testState: rsp.test_state,
      testStateName: rsp.test_state_name,
      testType: rsp.test_type,
      testTypeName: rsp.test_type_name,
      multiAttempt: rsp.multi_attempt,
      totDuration: rsp.total_duration,
      attemptCount: rsp.attempt_count,
      coursePublishId: rsp.course_publish_id,
      courseId: rsp.course_id,
      feedback: rsp.feedback,
      groupedTest: rsp.grouped_test,
    };
    this.setState({
      feedback: rsp.feedback,
      unattempted: rsp.unattempted,
      incorrect: rsp.incorrect,
      correct: rsp.correct,
      totMarks: rsp.totmarks,
      attempted: rsp.attempted,
      flagged: rsp.flagged,
      totQuestions: rsp.totquestions,
      dateFrom: rsp.from_date,
      dateTo: rsp.to_date,
      duration: rsp.duration,
      elapsedTime: rsp.elapsed_time,
      obtMarks: rsp.obtmarks
    })

    this.setState({ tests }, () => {

      this.setState((s0) => ({
        prevIndex: s0.index,
        index: s0.index + 1,
        status: 1,
        action: 1,
      }));
    });
    if (this.state.tests.testStateName == "Yet to Start" || this.state.tests.testStateName == "In Progress") {
      this.setState({ testSubmittedFlag: false });
      this.createStudentAssessment();







    }
    else {
      this.setState({ testSubmittedFlag: true });
      // this.props.onBackToCourse()
      this.props.onAllViewed();





      // this.viewSummary()


    }
  };
  toggleCollapse = (idx, sec) => {
    const newSections = this.state.sections.map((s, i) => {
      // console.log(i)
      if (i === idx) {
        return {
          ...s,
          open: !s.open
        }
      }
      else return s
    })
    this.setState({ sections: newSections })
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  onSelectExit = (e) => {
    const extitTest = (allow) => {
      e.stopPropagation();
      if (allow) {
        this.updateElapsedTime();
        this.goToLanding();
      }
    };

    this.props.ctx_window.simpleConfirm(
      "Are you sure you want to exit this test ?",
      extitTest
    );
  };
  updateElapsedTime = (interval) => {



    // const { testDetails } = this.props;
    const { assessmentId } = this.state;
    const { tests } = this.state;

    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_test_assessment_stub",
        args: {
          publishId: tests.publishId.toString(),
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          interval: interval || "0",
          endTime: this.dateToday(),
          status: interval ? 4 : 5,
          coursePublishId: tests.coursePublishId
            ? tests.coursePublishId.toString()
            : null,
          courseId: tests.courseId
            ? tests.courseId.toString()
            : null,
          groupedTest: tests.groupedTest,
        },
      })
      .then(({ rsp }) => this.handleUpdateElapsedTimeResponse(rsp, interval))
      .catch(this.handleUpdateElapsedTimeFailure);
  };
  handleImageClick = (img) => {
    this.setState({ modalOpen: false, img })
  }
  handleUpdateElapsedTimeResponse = (rsp, interval) => {
    !interval &&
      this.setState({ duration: rsp.duration });
  };
  goToLanding = () => {
    // this.setState({ redirect: true });
  };

  handleActionBtn = (timerStop) => {
    // const stat = "Attended";
    // this.setState({ redirect: true });
    // localStorage.setItem("status", stat);
    // this.setState({redirect: true});

    this.setState((s0) => ({ startBtn: !s0.startBtn }));
    if (timerStop) {
      this.setState({
        testClosed: true,
        updateElapsedTimeOrNot: false
      });

      if (this.state.action) {
        this.onTimerCheckpoint(0, 1);
      }
    }
  };
  onTimerCheckpoint = (rem, completed) => {
    if (completed) {
      // this.showSuccessPopupAndThen();
      this.setState({ updateElapsedTimeOrNot: false });

      this.handleSaveTest(2, () => {
        // this.goToSummary(() => {});
      });
    } else {
      this.setState({ alertTime: true });
    }
  };
  createQuestionSummary = () => {
    // const { testDetails } = this.props;
    const { tests } = this.state;
    if (this.state.lattestAssessmentId !== null) {

      const testAssessmentId = this.state.lattestAssessmentId;
      //   // const testAssessmentId =1995;
      //   // const testAssessmentId =3500;

      //   // this.setState({ testAssessmentId: testDetails.lattestAssessmentId });
      //   this.props.ctx_api
      //     .privateRequest({
      //       cmd: "get_trainee_question_summary",
      //       
      //       args: {
      //         testAssessmentId: testAssessmentId.toString(),
      //       },
      //     })
      //     .then(this.handleQuestionSummaryResponse)
      //     .catch(this.handleQuestionSummaryFailure);
      //     }

      const args = {
        // testAssessmentId:this.state.assessmentId,
        testAssessmentId: testAssessmentId.toString(),
      };
      const arg = {
        publishId: tests.publishId.toString(),
      };


      const apiCalls = [
        { cmd: "get_trainee_question_summary", args: args },
        { cmd: "get_student_assessment_details", args: arg },
      ];

      Promise.all(apiCalls.map(this.props.ctx_api.privateRequest))
        .then((rs) => {
          if (0 < rs.length) {
            this.handleGetAttemptDetailsResponse(rs);
          }
        })
        .catch(this.handleGetAttemptDetailsFailure);
    };

  };

  handleGetAttemptDetailsResponse = (rsp) => {
    this.handleQuestionSummaryResponse(rsp[0].rsp);
    this.handleGetsTestResponse(rsp[1].rsp[0]);

  };


  handleQuestionSummaryResponse = (s) => {
    // console.log("b",s);
    const contents = s.map((r, i) => this.extractSummaryResponse(r, i));
    const sections = contents.map((content) => {
      return {
        sectionOrder: content.section_order,
        sectionName: content.section_name,
        open: true
      }
    })
    const uniqueSections = [...new Set(sections.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    // console.log(uniqueSections)
    this.setState({ summaryData: contents, sections: uniqueSections });
  };

  fetchPdfView = async (pdfFileName) => {
    const { rsp } = await this.props.ctx_api
      .privateRequest({
        cmd: "get_assignment_url",
        args: {
          fileName: pdfFileName, contentType: "pdf"
        },
      })
    this.setState({ modalAssignmentOpen: true, pdfNameUrl: rsp.url })
  };


  handleAttachment = (e, pdfUrl, file) => {
    e.stopPropagation();
    this.fetchPdfView(pdfUrl);
    this.setState({ pdfUrlFile: pdfUrl, pdfUrlType: file, })
  }

  renderModalBody = () => {
    return (

      <div>
        {this.state.pdfUrlType == "0" && (
          <Audio
            url={this.state.pdfNameUrl}
            onLoaded={() => { }}
            onError={() => { }}
            className={"audio-wrapper"}
          />
        )}
        {this.state.pdfUrlType == "1" && (
          <Video
            url={this.state.pdfNameUrl}
            onLoaded={() => { }}
            onError={() => { }}
            className={"video-wrapper"}
          />
        )}
        {this.state.pdfUrlType == "2" && (
          <Pdf
            pdfUrl={this.state.pdfNameUrl}
            onLoaded={() => { }}
            onError={() => { }}
          />
        )}
      </div>
    );
  };

  extractSummaryResponse = (r, i) => {


    const AnswerOptions = (
      question_type,
      answers,
      answer_key,
      question_name,
      is_record
    ) => {


      const colMatch = [
        {
          name: "Question",
          selector: row => row.question,
          sortable: false,
          width: "150px",
          center: false,
        },
        {
          name: "Correct/Incorrect",
          selector: row => row.status,
          sortable: false,
          width: "150px",
          center: false,
        },
        {
          name: "Selection",
          selector: row => row.selection,
          sortable: false,
          minWidth: "150px",
          wrap: true,
        },
      ];
      const tableStyles = getTableStyles(this.props.theme);
      tableStyles.cells.style.alignItems = "flex-start";
      return (
        <div className="pt-1">
          {answers &&
            question_type !== 3 &&
            question_type !== 4 &&
            question_type !== 5 ? (
            <>
              {answers.map((answer, i) => (
                <div className="mb-2" key={i}>
                  <RadioButton
                    // key={i}
                    // name={i}
                    className={answer.is_correct == 1 ? "fw-500" : ""}
                    checked={answer.attempted == 1 ? true : false}
                    readOnly={true}
                  >
                    {answer.answer}
                    {answer.is_correct == 1 && (
                      <span className="d-inline-block text-success ml-1 mr-0 fw-600">
                        &nbsp;Correct Answer
                      </span>
                    )}
                    {answer.is_correct == 0 && (
                      <span className="d-inline-block text-danger ml-1 mr-0 fw-600">
                        &nbsp;Wrong Answer
                      </span>
                    )}
                  </RadioButton>
                </div>
              ))}
            </>
          ) : (
            <>
              {question_type === 3 &&
                answers[0].answer &&
                answers[0].answer.split("\n").map((str) => <div className="answer-comment" dangerouslySetInnerHTML={{
                  __html: `${str ?? ""}`
                }} />)}
              {question_type === 5 && (
                <>
                  <DataTable
                    noHeader={true}
                    columns={colMatch}
                    data={[...answers].map((answer, i) => {
                      return {
                        question: answer.question_code + " " + answer.question,

                        status:
                          answer.is_correct || answer.is_attended ? (
                            <Badge pill variant="success">
                              Correct
                            </Badge>
                          ) : (
                            <Badge pill variant="danger">
                              Incorrect
                            </Badge>
                          ),

                        selection: answer.answer_code + " " + answer.answer,
                      };
                    })}
                    customStyles={tableStyles}
                    striped="#FAFAFA"
                    defaultSortField="no"
                  />
                  Answer Key: {answer_key}
                </>
              )}

              {question_type === 4 && (
                <>
                  {answers[0].answer ? (
                    <OverlayTooltip
                      tip={"Click to view assignment"}
                      placement="top"
                    >
                      <Button
                        pill
                        wide
                        variant="primary"
                        onClick={(e) =>
                          this.handleAttachment(
                            e,
                            answers[0].answer,
                            answers[0].std_assignment_type,
                          )
                        }
                      >
                        View Assignment
                      </Button>
                    </OverlayTooltip>
                  ) : (
                    <div
                      style={{
                        color: "#d61818",
                        margin: "15px 5px",
                        padding: "3px 5px",
                      }}
                    >
                      {util.capitalizeWord(
                        this.props.ctx_labels.singular["assignment"]
                      )}{" "}has not been uploaded
                    </div>
                  )}
                  <div className="answer-comment" dangerouslySetInnerHTML={{
                    __html: `${answers[0].assignment_comment ?? ""}`
                  }} />

                </>
              )}
              {(question_type === 3 && is_record == 1) && (
                <>
                  {answers[0].assignment_comment ? (

                    <Audio
                      url={answers[0].assignment_comment}
                      onLoaded={() => { }}
                      onError={() => { }}
                      mediaId="student-assessment-audio"
                    />

                  ) : (<div
                    style={{
                      color: "#d61818",
                      margin: "15px 5px",
                      padding: "3px 5px",
                    }}
                  >
                    Audio
                    has not been uploaded
                  </div>
                  )}
                </>
              )}
            </>
          )}
          {/* {question_type === 3 &&  answers[0].answer.split('\n').map(str => <p>{str}</p>)  } */}
        </div>
      );
    };
    //
    const mkMarks = (marks) => {


      return marks < 0 ? (
        <p
          style={{
            color: "#d61818",
          }}
        >
          {marks}
        </p>
      ) : (
        <p>{marks}</p>
      );
    };
    const showStatus = (status, question_type) => {

      return (status === "0" || status === 0) && question_type !== 3 ? (
        <Badge pill variant="danger">
          Wrong
        </Badge>
      ) : (status === "1" || status === 1) && question_type !== 3 ? (
        <Badge pill variant="success">
          Correct
        </Badge>
      ) : (status === "1" || status === 1) && question_type == 4 ? (
        <Badge pill variant="primary">
          Attempted
        </Badge>
      ) : (status === "1" || status === 1) && question_type == 3 ? (
        <Badge pill variant="primary">
          Attempted
        </Badge>
      ) : (
        <Badge pill variant="dark">
          Unattempted
        </Badge>
      );
    };

    let response = {};

    response = {
      // id: r.question_id,
      no: i + 1,
      question: r.question_name,
      // answer: r.question_name,

      questionType: r.question_type,
      answers: AnswerOptions(
        r.question_type,
        r.answers,
        r.answer_key,
        r.question_name,
        r.is_record
      ),
      status: showStatus(r.status, r.question_type),
      marks: mkMarks(r.obt_marks),
      evaluated: r.evaluated,
      // feedback: r.feedback,
      section_name: r.section_name,
      section_order: r.section_order

    };
    return response;
  };

  handleQuestionSummaryFailure = (err) => {
    this.setState({ summaryError: "Data fetching failed : summaryError !" });
  };
  handleSaveTest = (flag, callback) => {
    this.setState({
      testSubmittedFlag: true
    });

    this.createStudentAssessment(flag, callback);
    // the below line commented for the purpose of doubling issue
    // this.handleActionBtn();
    // callback()
  };

  onSelectItem = (selectedItemId) => () => {
    this.setState({
      selectedItem: selectedItemId,
    });
  };
  onSelectSave = () => {
    // this.showSuccessPopupAndThen("Saved");
    this.createStudentAssessment(1);
  };
  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  onTestClosed = () => {
    this.setState({ updateElapsedTimeOrNot: false });
    this.props.onAllViewed();


  };
  createStudentAssessment = (flag, callback) => {
    const { tests } = this.state;
    const { assessmentId, testQuestions, testAnswers } = this.state;
    this.setState({ loading: true, viewSummary: false });
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_test_assessment_stub",
        args: {
          publishId: tests.publishId.toString(),
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          testQuestions: testQuestions,
          testAnswers: testAnswers,
          endTime: this.dateToday(),
          status: flag || 0,
          coursePublishId: tests.coursePublishId
            ? tests.coursePublishId.toString()
            : null,
          courseId: tests.courseId
            ? tests.courseId.toString()
            : null,
          groupedTest: tests.groupedTest,
        },
      })

      .then(({ rsp }) =>
        this.handleStudentAssessmentResponse(rsp, callback, flag)
      )
      .catch(this.handleStudentAssessmentFailure);
    // callback ? callback() :null ;
  };

  handleStudentAssessmentFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
    this.setState({ loading: false });
  };

  handleStudentAssessmentResponse = (rsp, callback, flag) => {
    this.setState({ lattestAssessmentId: rsp.testassessmentid });
    const action = flag === 1 ? "Saved" : "Submitted";
    if (!this.state.assessmentId) {
      this.getTestQuestions(rsp.testassessmentid);
    } else {

      this.setState({ loading: false });
      if (this.state.assessmentId == rsp.testassessmentid) {
        this.showSuccessPopupAndThen(action);
      }
    }
    callback && callback();
    // callback ? callback() : null;
  };
  showSuccessPopupAndThen = (action) => {
    if (action == "Saved") {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true
        })

      );

    }
    else {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true, redirect: true
        })

      );

    }

  };
  dateToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var time =
      String(today.getHours()).padStart(2, "0") +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      String(today.getSeconds()).padStart(2, "0");

    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd + " " + time;
    return today;
  };
  onSelectFlag = () => {
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    let flag = testQuestions[selectedItem].flagged;
    testQuestions[selectedItem].flagged = flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = !flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  };

  getTestQuestions = (testassessmentid) => {
    const apiCalls = [
      {
        cmd: "get_student_assessment_questions",
        args: { studentAssessmentId: testassessmentid },
      },
      {
        cmd: "get_student_assessment_question_answers",
        args: { studentAssessmentId: testassessmentid },
      },
    ];
    Promise.all(apiCalls.map(this.props.ctx_api.privateRequest))
      .then((rs) => {
        const pre_test = rs[0].rsp;
        const pre_test_ans = rs[1].rsp;
        this.setState({
          questionSection: pre_test[0].questions,
          questionAnswer: pre_test_ans,
          assessmentId: testassessmentid,
        });
        this.handleTestAnswers();
      })
      .catch(this.handleGetQuestionFailure);
  };
  handleTestAnswers = () => {
    const { questionAnswer, questionSection } = this.state;
    // const { questionSection } = this.props;
    const testQuestions = {};
    const testAnswers = {};
    let selectedItem = null;
    let questionIds = [];

    questionSection.forEach((se) => {
      const sortedQuestions = Object.values(se.questions).sort(
        (a, b) => a.sequence - b.sequence
      );
      sortedQuestions.forEach((qu) => {
        testQuestions[qu.id] = qu;
        questionIds.push(qu.id);
        if (!selectedItem) {
          selectedItem = qu.id;
        }
        // !selectedItem && selectedItem.push(qu.id);
        testAnswers[qu.id] = {
          choices: [],
          answers: [],
          comments: [],
          reviewStarts: [],
          reviewEnds: [],
          questions: [],
        };
      });
    });
    questionAnswer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if (
          q.type === typeE.FILL_IN_THE_BLANK ||
          (q.type === typeE.MULTIPLE_CHOICE && r.is_correct_ans)
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    });

    this.setState({
      testQuestions,
      testAnswers,
      selectedItem,
      questionIds,
      loading: false,
    });
    // this.startTestTimer()
  };
  mkTestQuestionOverlay = () => {
    // this.handleTestAnswers();
    const { questionIds, selectedItem, questionSection } = this.state;
    // const qNumber = this.state.questionIds.indexOf(selectedItem)
    if (selectedItem) {
      let question = this.state.testQuestions[selectedItem];
      const answers = this.state.testAnswers[selectedItem];
      const qSection = question.section_order;
      const currQuestionSection = questionSection && questionSection.filter((section) => section.section_order === qSection)
      const currQuestion =
        currQuestionSection &&
        currQuestionSection[0].questions.filter((quest) => quest.id === question.id);

      const disabled =
        (currQuestionSection[0].questions_attempted <=
          +currQuestionSection[0].max_questions &&
          currQuestion && currQuestion[0].attempted === 1)
          ||
          (currQuestionSection[0].questions_attempted <
            +currQuestionSection[0].max_questions &&
            currQuestion && currQuestion[0].attempted === 0)
          ? false
          : true


      return (
        <div>
          <Card className={`${css.question_card} border-0`}>
            <Card.Body>
              <Question
                // qNumber = {qNumber +1}
                question={question}
                Ids={questionIds}
                answers={answers}
                disabled={disabled}
                onAnswered={this.handleQuestionAnswered}
                onReview={this.handleReview}
                onAttempted={this.handleOnAttempted}
                isPlayerTest
                // hidden={!!this.state.reviewing}
                bgColor="#fff" //{this.state.module.type === 'assessment' ? '#fff' : '#000'}
              // width={this.state.moduleWidth}
              // answerFooter={() => this.renderAnswerFooter()}
              />
            </Card.Body>
          </Card>
          {this.renderAnswerFooter()}
        </div>
      );
    } else return null;
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  onSelectSubmit = () => {
    const count = this.handleCount();
    const msg = [
      count.unanswered && count.unansweredAssignment ? `1.` : "",
      count.unanswered || count.unansweredAssignment || count.flag
        ? ` You have `
        : "",
      count.unanswered && count.flag || count.unanswered
        ? `${count.unanswered} unattempted `
        : "",
      count.unanswered === 1 && "question",
      count.unanswered > 1 && "questions",
      count.flag === 0 && count.unanswered ? "." : "",
      count.flag && count.unansweredAssignment === 0 && count.unanswered === 0
        ? ` marked ${count.flag}`
        : "",
      count.flag && count.unansweredAssignment === 0 && count.unanswered !== 0
        ? ` and marked ${count.flag}`
        : "",
      count.flag && count.unansweredAssignment === 0
        ? <p>{count.flag > 1 ? "questions" : "question"} for review</p>
        : "",
      count.unansweredAssignment && count.flag === 0
        ? <p><br></br>2.You have not uploaded any content for {count.unansweredAssignment} {count.unansweredAssignment > 1 ? "questions" : "question"}.</p>
        : "",
      count.unansweredAssignment && count.flag
        ? <p><br></br>2.You have not uploaded any content for {count.unansweredAssignment} {count.unansweredAssignment > 1 ? "questions" : "question"}.<br>
        </br><br></br>3.You have marked {count.flag} {count.flag > 1 ? "questions" : "question"} for review.</p>
        : "",
      `Would you like to continue ?`,
    ]

    const submitTest = (allow) => {
      if (allow) {


        this.setState({
          updateElapsedTimeOrNot: false,
          testSubmittedFlag: true
        });
        this.props.onAllViewed();


        this.handleSaveTest(3, () => {

          // this.goToSummary(() => {});
        });
        // this.props.onBackToCourse

      }
    };
    this.props.ctx_window.simpleConfirm(msg, submitTest);
  };
  handleCount = () => {
    const { testQuestions } = this.state;
    const objTestQuestion = Object.values(testQuestions);
    const delta = {
      unanswered: 0,
      flag: 0,
      unansweredAssignment: 0
    };
    objTestQuestion.forEach((s0) => {
      if (s0.type === 4 && s0.attempted === 0) {
        delta.unansweredAssignment = delta.unansweredAssignment + 1;
      }
      if (s0.flagged === 1) {
        delta.flag = delta.flag + 1;
      }
      if (s0.attempted === 0) {
        delta.unanswered = delta.unanswered + 1;
      }
    });
    return delta;
  };

  renderQuestionTest = () => {
    const sectionNumbers1 = [
      { label: "1", status: "completed" },
      { label: "2", status: "completed" },
      { label: "3", status: "skip" },
      { label: "4", status: "current" },
      { label: "5" },
      { label: "6" },
      { label: "7" },
      { label: "8" },
    ];

    const sectionNumbers2 = [
      { label: "1" },
      { label: "2" },
      { label: "3" },
      { label: "4" },
      { label: "5" },
      { label: "6" },
      { label: "7" },
      { label: "8" },
    ];

    const question = {
      title: "Section A - Question 4",
      question: "What’s the one thing that doesn’t belong to a to-do list?",
      options: [
        { text: "The movie you want to watch", value: "1" },
        { text: "A detailed list of all tasks for the day", value: "2" },
      ],
    };
    const {
      questionSection,
      selectedItem,
      testQuestions,
      testAnswers,
    } = this.state;
    const currQuestion = testQuestions[selectedItem]
    const currSection = questionSection.filter(section => section.section_order === currQuestion.section_order)
    if (!testQuestions || !testAnswers) {
      return (
        <Row>
          <Col md={12}>
            <Loader width={30} padding="4em" />
          </Col>
        </Row>
      );
    }
    const format = currSection[0].content ? currSection[0].content_format : "";
    let MediaPlayer;
    switch (format) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }
    if (questionSection[0].questions.length > 0) {
      return (


        <Row>
          {/* Side */}
          <Col lg={5}>
            <Card className="mb-4 border-0">
              <Card.Body className={this.props.testView ? "section-image" : "pb-1"}>
                {currSection[0].content || currSection[0].description ? <div>
                  <h5 className={css.sec_name}> {(currSection[0].questions[0].section_name)} </h5> </div> : <></>
                }
                <MediaPlayer
                  className="ov"
                  fromTrainingZone
                  url={currSection[0].content}
                  mediaId="media-player-student-assessments"
                  // onReady={this.handleVideoReady}
                  // onError={this.handleVideoError}
                  // noValidateFormId={this.noValidateFormId}
                  autoPlay={true}
                  pdfUrl={currSection[0].content}
                />
                {currSection[0].content_format === "3" &&
                  <img src={currSection[0].content} onClick={() => this.handleImageClick(currSection[0].content)} />

                }
                <div>
                  <p className={css.para}><div dangerouslySetInnerHTML={{ __html: currSection[0].description }} /></p>
                </div>
                <Section
                  rand={this.state.rand_question}
                  question={questionSection}
                  selectedItemId={selectedItem}
                  selected={(selectedItemId) => {
                    this.setState({
                      selectedItem: selectedItemId,
                      // index: 2
                    });
                  }}
                />
              </Card.Body>
            </Card>
          </Col>

          {/* Question */}
          <Col lg={7}>
            <Card className={`${css.question_card} border-0`}>
              {selectedItem && this.mkTestQuestionOverlay()}
            </Card>
          </Col>
        </Row>
      );
    } else {
      return this.renderLoadingSpinner();
    }
  };
  renderLoadingSpinner = () => (
    <Row>
      <Col md={4}>
        {/* <div className="loading-indicator-wrapper">
          <div className="loading-indicator">
            <SpinIcon width={35} />
          </div>
        </div> */}
      </Col>
      <Col md={4}>
        <div className="loading-indicator-wrapper">
          <div style={{ textAlign: "center", paddingTop: "100px" }}>
            <SpinIcon width={35} />
          </div>
        </div>
      </Col>
      {/* <Col md={4}>
        <div className="loading-indicator-wrapper">
          <div className="loading-indicator">
            <SpinIcon width={35} />
          </div>
        </div>
      </Col> */}
    </Row>
  );
  handleArrowAction = (action) => {
    const { selectedItem, questionIds } = this.state;
    const index = questionIds.indexOf(selectedItem);
    const nextId =
      action === "right" ? questionIds[index + 1] : questionIds[index - 1];
    if (
      !(action === "left" && index === 0) &&
      !(action === "right" && index === questionIds.length - 1)
    ) {
      this.setState({ selectedItem: nextId });
    } else {
      return null;
    }
  };
  handleQuestionAnswered = (response) => {
    const { selectedItem } = this.state;
    const typeQBE = config.testQuestionTypeQBE;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].user_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response.map(({ answer }) => answer)
        : response || [];
    testQuestions[selectedItem].match_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response || []
        : [];
    this.setState({ testQuestions: testQuestions });
  };


  handleOnAttempted = (value) => {
    // const { selectedItem } = this.state;
    // let testQuestions = this.state.testQuestions;
    // testQuestions[selectedItem].attempted = value ? 1 : 0;
    // this.setState({ testQuestions: testQuestions });
    const { selectedItem, questionSection } = this.state;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].attempted = value ? 1 : 0;
    const qSection = testQuestions[selectedItem].section_order;

    let newQuestionSection = questionSection.map((section, i) => {
      if (section.section_order === qSection) {
        const currentAttempted = section.questions.filter(quest => quest.attempted === 1).length;
        return {
          ...section,
          questions_attempted: currentAttempted,
        }
      }
      else return section
    });
    this.setState({ testQuestions: testQuestions, questionSection: newQuestionSection });
  };
  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  };

  handleClear = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      // assessmentId: null,
      testQuestions: {},
      testAnswers: {},
    });
  };

  handleExit = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      assessmentId: null,
      testQuestions: {},
      testAnswers: {},
      loading: true,
    });
  };
  renderAnswerFooter = () => {
    {
      const { index, selectedItem, testQuestions, questionIds } = this.state;
      const Disabled = true;
      const selectedIndex = questionIds.indexOf(selectedItem);
      let isFlag = testQuestions[selectedItem].flagged;

      return (
        index !== 0 && !this.state.testClosed && (
          <Card className={`${css.question_card} border-0`}>
            <Card.Footer className={css.footer}>
              <div>
                Want to revisit it later?
                <Button onClick={this.onSelectFlag} className="ml-2 mr-0">
                  {/* flsg */}
                  {!isFlag ? "FLAG" : "UNFLAG"}
                </Button>
              </div>
              {/* <Pagination /> */}
              <div className={css.pagination}>
                <Button
                  onClick={() => this.handleArrowAction("left")}
                  variant="gray"
                  pill
                  wide
                  disabled={selectedIndex === 0 ? true : false}
                >
                  Previous
                </Button>
                <div className={css.pagination_number}>
                  <span>{selectedIndex + 1}</span> of {questionIds.length}
                </div>
                &nbsp;
                <Button
                  disabled={
                    selectedIndex === questionIds.length - 1 ? true : false
                  }
                  onClick={() => this.handleArrowAction("right")}
                  variant="primary"
                  pill
                  wide
                >
                  Next
                </Button>
              </div>
            </Card.Footer>
          </Card>
        )
      );
      // else return null
    }
  };
  // createTestCheckPoints = (duration, interval) => {
  //   if(duration > 0){
  //   let checkpoints = [];

  //   checkpoints.push({
  //     time: 60000,
  //     callback: () => this.onTimerCheckpoint(duration / 4, 0),
  //     // callback: () => this.setState({alertTime : true})
  //   });

  //   checkpoints.push({
  //     time: 0,
  //     callback: () => this.onTimerCheckpoint(0, 1),
  //   });
  //   checkpoints.push(
  //     ...new Array(Math.floor(duration / interval))
  //       .fill(undefined)
  //       .map((_, i) => {
  //         return {
  //           time: i * interval,
  //           callback: () => this.updateElapsedTime(interval),
  //         };
  //       })
  //   );

  //   return checkpoints;

  //   }

  // };
  msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  startTestTimer = () => {
    const binkStyle = ["blink", this.state.alertTime ? "alert" : ""].join(" ");

    const createTestCheckPoints = (duration, interval) => {
      let checkpoints = [];

      checkpoints.push({
        time: 60000,
        callback: () => this.onTimerCheckpoint(duration / 4, 0),
        // callback: () => this.setState({alertTime : true})
      });

      checkpoints.push({
        time: 0,
        callback: () => this.onTimerCheckpoint(0, 1),
      });
      checkpoints.push(
        ...new Array(Math.floor(duration / interval))
          .fill(undefined)
          .map((_, i) => {
            return {
              time: i * interval,
              callback: () => this.updateElapsedTime(interval),
            };
          })
      );

      return checkpoints;
    };

    const { status, duration } = this.state.tests;


    return (
      <Timer
        initialTime={duration}
        // initialTime= {90000}
        direction="backward"
        // checkpoints={createTestCheckPoints(90000 , 30000)}
        checkpoints={createTestCheckPoints(duration, 30000)}
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      >
        {(start, resume, pause, stop, reset) => (
          <React.Fragment>
            <span class={binkStyle}>
              <Timer.Hours />:
              <Timer.Minutes />:
              <Timer.Seconds />
            </span>
          </React.Fragment>
        )}
      </Timer>
    )
    // : (
    //   <span class="glyphicon glyphicon-time">
    //     &nbsp;{this.msToTime(duration)}
    //   </span>
    // );
  };
  renderControls = () => (
    <div className={`${cmCss.toolbar} bg-color2 text-white`}>
      <div className={cmCss.toolbar_left}></div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );
  viewSummary = () => {

    this.setState({ viewSummary: true, testSubmittedFlag: true });
    this.props.onAllViewed();
    this.createQuestionSummary();


  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  MarksCircle = () => {
    const { totMarks, obtMarks, totQuestions } = this.state;
    const data = {
      labels: ["Marks Lost", "Obtained Mark"],
      datasets: [
        {
          label: "# of Votes",
          data: [totMarks < 0 ? totMarks : totMarks - obtMarks, obtMarks],
          backgroundColor: ["#A6CEE3", "#1F78B4"],
          borderColor: ["#A6CEE3", "#1F78B4"],
          borderWidth: 0,
        },
      ],
    };
    return (
      <ChartDoughnut
        data={data}
        center={obtMarks}
        title={"Marks" + "(out of" + " " + totMarks + ")"}
      />
    );
  };

  TotalCircle = () => {
    const { unattempted, attempted, flagged } = this.state;

    const data = {
      labels: ["Attempted", "Unattempted", "Flagged"],
      datasets: [
        {
          label: "# of Votes",
          data: [attempted, unattempted, flagged],

          backgroundColor: ["#A6CEE3", "#1F78B4", "#B2DF8A"],
          borderColor: ["#A6CEE3", "#1F78B4", "#B2DF8A"],
          borderWidth: 1,
        },
      ],
    };

    const legend = [
      { label: `Attempted ${attempted}`, color: "#A6CEE3" },
      { label: `Unattempted ${unattempted}`, color: "#1F78B4" },
      { label: `Flagged ${flagged}`, color: "#B2DF8A" },

    ];

    const StyledCenter = styled.div`
      font-size: 23px;
      line-height: 1;
      text-align: center;
      small {
        display: block;
        font-size: 12px;
        line-height: 1;
      }
    `;
    const center = (
      <StyledCenter className="text-color2">
        {this.state.totQuestions}
        <small>Total</small>
      </StyledCenter>
    );

    return (
      <ChartDoughnut
        data={data}
        center={center}
        title="&nbsp;"
        legend={legend}
      />
    );
  };

  TotalMarks = () => {
    const { unattempted, incorrect, correct, totMarks } = this.state;
    const data = {
      labels: ["Total Correct", "Total Incorrect", "Unattempted"],
      datasets: [
        {
          label: "# of Votes",
          data: [correct, incorrect, unattempted],
          backgroundColor: ["#A6CEE3", "#1F78B4", "#B2DF8A"],
          borderColor: ["#A6CEE3", "#1F78B4", "#B2DF8A"],
          borderWidth: 1,
        },
      ],
    };

    const legend = [
      { label: `Total Correct ${correct}`, color: "#A6CEE3" },
      { label: `Total Incorrect ${incorrect}`, color: "#1F78B4" },
      { label: `Unattempted ${unattempted}`, color: "#B2DF8A" },
    ];

    const StyledCenter = styled.div`
      font-size: 23px;
      line-height: 1;
      text-align: center;
      small {
        display: block;
        font-size: 12px;
        line-height: 1;
      }
    `;
    const center = (
      <StyledCenter className="text-color2">
        {this.state.totQuestions}
        <small>Total</small>
      </StyledCenter>
    );
    // console.log(this.state);
    return (
      <ChartDoughnut
        data={data}
        center={center}
        title="&nbsp;"
        legend={legend}
      />
    );
  };

  msToTime = () => {
    const { duration } = this.state
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  handleFeedback = ({ }) => {
    this.setState({ modalFeedbackOpen: true });
  }
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  joditConfig = {
    height: 300,
    allowResizeY: false,
    hidePoweredByJodit: true,
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  handleHide = () => {
    this.setState({ modalFeedbackOpen: false, modalAssignmentOpen: false })
  }

  renderFeedbackModalBody = (rsp) => {
    return (
      <JoditEditor
        name="feedback"
        value={this.state.feedback}
        config={this.joditConfig}
        editorRef={this.setRef}
      // onBlur={(newContent) => setContent(newContent)}
      // onChange={(newContent) => {}}
      // onClick={(e)=>e.stopPropagation} 

      />

    );

  };


  render = () => {
    const { testDetails, onBackToCourse, playerState } = this.props;
    const { summaryData } = this.state;
    const currentDate = new Date();
    const headers = [
      { key: "no", val: "No:" },
      { key: "name", val: "Quiz" },
      { key: "options", val: "Options" },
      { key: "status", val: "Status", className: "text-al-center" },
      { key: "pleScore", val: "Marks", className: "text-al-right" },
    ];
    const fsClassName =
      this.props.isFullscreen ? 'assessment-fullscreen' : 'assessment-window';
    const className = `assessment ${fsClassName}`;

    // const zoomClassName = zoomFactor > 0 ? 'assessment-zoomed-in' : 'assessment-unzoomed';
    const isEvaluated = summaryData && summaryData.find(s => s.evaluated == 0)
    return (
      <>
        <Modal
          animation={false}
          show={this.state.modalOpen}
          onHide={this.handleClose}
          scrollable={true}
          // backdrop="static"
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              // height: "500px",
              // width: "500px"
            }}
          >
            {/* <div className="text-center">{this.renderModalBodyHeader()}</div>  */}
            {<img src={this.state.img} />}
          </Modal.Body>
        </Modal>
        <Modal
          dialogClassName="evaluate-modal-frame"
          show={this.state.modalFeedbackOpen}
          onHide={this.handleHide}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderFeedbackModalBody()}</Modal.Body>
        </Modal>
        <Modal
          dialogClassName="evaluate-modal-frame"
          show={this.state.modalAssignmentOpen}
          onHide={this.handleHide}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderModalBody()}</Modal.Body>
        </Modal>


        <div
        
        >


          {
            this.state.testSubmittedFlag && this.onTestClosed
            &&

            <div >
              <div >

                {this.state.viewSummary == false &&
                  <div style={{ paddingTop: "30px" }}>
                    <StyledPlayerMessage className="player-module-message">

                      <div style={{ fontSize: "18px", padding: "20px" }}>Click
                        <a onClick={this.viewSummary} style={{ color: "blue", cursor: "pointer" }}> here</a>&nbsp;
                        to view the summary of the test</div>
                    </StyledPlayerMessage>
                  </div>}
                {this.state.viewSummary &&
                  <div>
                    <div style={{ float: "right", paddingTop: "35px", paddingRight: "20px" }}>
                      <HelpfulButton
                        tip={!this.state.feedback ? "No feedback available" : "View Feedback"}
                        placement="top"
                        pill
                        wide
                        variant="primary"
                        disabled={!this.state.feedback}
                        onClick={this.handleFeedback}
                      >View Feedback
                      </HelpfulButton>
                    </div>
                    <div >
                      <Row>
                        <Col
                          className="mb-3 mb-lg-0"
                          style={{ flexBasis: "auto", paddingTop: "20px", paddingLeft: "25px" }}
                        >
                          <div className="text-color4 fs-12">START DATE</div>
                          {this.state.dateFrom}
                          <br></br> &nbsp;
                          <div className="text-color4 fs-12">END DATE</div>
                          {this.state.dateTo}
                        </Col>
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={200} maxDeviceWidth={540}>
                            <Col classname="firstColData"
                              lg={3} md={6} sm={12} style={{ paddingTop: "25px", display: "flex" }}>
                              {this.TotalCircle()}
                            </Col>
                            <Col classname="secondColData"
                              style={{ paddingTop: "25px", display: "flex", flexDirection: "column" }}
                            >
                              {this.TotalMarks()}
                            </Col>
                            <Col classname="thirdColData"
                              style={{ display: "flex", marginLeft: "-5px" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={541} maxDeviceWidth={767} >
                            <Col classname="firstColData"
                              style={{ paddingTop: "12px" }}
                            >
                              {this.TotalCircle()}
                            </Col>
                            <Col classname="secondColData"
                            >
                              {this.TotalMarks()}
                            </Col>
                            <Col classname="thirdColData"
                              style={{ flexGrow: "0" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={768} maxDeviceWidth={819}>
                            <Col classname="firstColData">
                              {this.TotalCircle()}
                            </Col>
                            <Col classname="secondColData"
                            >
                              {this.TotalMarks()}
                            </Col>
                            <Col classname="thirdColData"
                              style={{ flexGrow: "0", marginLeft: "-5px", display: "flex" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={820} maxDeviceWidth={1023} >
                            <Col classname="firstColData"
                              style={{ paddingTop: "12px" }}
                            >
                              {this.TotalCircle()}
                            </Col>
                            <Col classname="secondColData"
                              style={{ paddingTop: "12px" }} >
                              {this.TotalMarks()}
                            </Col>
                            <Col classname="thirdColData"
                              style={{ marginLeft: "250px", marginTop: "-145px" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={1024} maxDeviceWidth={1279}  >
                            <Col style={{ paddingTop: "25px", display: "flex" }}>

                              {this.TotalCircle()}
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column" }}>
                              {this.TotalMarks()}
                            </Col>
                            <Col
                              //   lg={3} md={6} sm={12}
                              style={{ display: "flex", paddingRight: "35px", flexGrow: "0" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                        {!isEvaluated && summaryData !== null &&
                          <MediaQuery minDeviceWidth={1280}  >
                            <Col style={{ paddingTop: "25px", display: "flex" }}>

                              {this.TotalCircle()}
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                              {this.TotalMarks()}
                            </Col>
                            <Col
                              //   lg={3} md={6} sm={12}
                              style={{ display: "flex", paddingLeft: "25px", flexGrow: "0", paddingRight: "25px", marginTop: "25px" }}
                            >
                              {this.MarksCircle()}
                            </Col>
                          </MediaQuery>}
                      </Row>
                    </div>
                  </div>
                }

                {this.state.summaryData !== null && this.state.viewSummary == true && playerState != "ready" && this.state.summary_viewable == 1 &&
                  this.state.sections.map((section, idx) => (
                    <div>

                      <StyledChapterRibbon className="chapter-ribbon chapter-ribbon-card chapter-ribbon-item-card"
                        onClick={() => this.toggleCollapse(idx, section)}
                        style={{ width: "96%", marginLeft: "2%", marginTop: "5px" }}>
                        <div className="ribbon-chapter-title">
                          {section.sectionName || ""}
                        </div>
                      </StyledChapterRibbon>
                      <Collapse in={section.open}>
                        {/* <Row>
            <Col lg={12} xl={12}> */}
                        <TableSummary
                          view="question"
                          headers={headers}
                          contents={this.state.summaryData.filter(item => item.section_order === section.sectionOrder)}
                          error={this.state.summaryError}
                        />

                        {/* </Col>
          </Row> */}
                      </Collapse>

                    </div>
                  ))}

                {this.state.summaryData == null && this.state.viewSummary == true && this.state.summary_viewable == 1 &&
                  <Row>
                    <Col sm={4}>
                      {/* <Loader width={30} padding="4em" /> */}
                    </Col>
                    <Col sm={4}>
                      <Loader width={30} padding="4em" />
                    </Col>
                  </Row>
                }
              </div>
            </div>
          }

          {
            // this.state.testSubmittedFlag && 
            !this.state.testSubmittedFlag &&

            <>
              <div className={css.toolbar} style={{ alignItems: "center" }}>
                <div>
                  &nbsp;
                </div>

                {this.state.tests !== null && this.state.tests.duration !== 0 && this.state.tests.testType === 1 &&
                  this.state.questionSection[0].questions.length > 0 &&
                  <>
                    <Button variant="primary" size="large" pill wide>
                      {this.startTestTimer()}
                    </Button>
                  </>
                }

                <div>
                  {this.state.selectedItem !== null ? (
                    <>
                      <Button
                        onClick={this.onSelectSave}
                        variant="light-gray"
                        size="sm"
                        pill
                      >
                        Save
                      </Button>

                      <Button
                        onClick={this.onSelectSubmit}
                        variant="success"
                        size="sm"
                        pill
                        wide
                      >
                        Submit
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>


              <div style={{ padding: "0px 20px 24px" }} >
                <div >

                  <Row>
                    <Col lg={12} xl={12}>
                      {this.state.selectedItem !== null ? (
                        <>
                          {this.renderQuestionTest()}
                        </>
                      ) : (
                        this.renderLoadingSpinner()
                      )}
                    </Col>
                  </Row>
                </div>
              </div>

            </>
          }




        </div>
        {/* {this.renderControls()} */}

      </>
    );
  };
}
export default withWindowContext(
  withLabelsContext(withApiContext(withRouter(withUserContext(StudentAssessments))))
);
