import AsyncSelect from './AsyncSelect';
import { withApiContext, withLabelsContext } from 'context';
import React, { PureComponent } from 'react';




class CategorySelect extends PureComponent {
  fetch = async () => {
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd:  'get_all_category_v2',
      args: {},
    });
    const selectAll ={
      label: "All",
      value:"0"
    }
    let data =  rsp.map(({ categorization_id, categorization }) => ({
      label: categorization,
      value: categorization_id,
    }));
    !this.props.testGenerate && data.unshift(selectAll)
    return data
  }

  render = () => (
    <AsyncSelect
      { ...this.props }
      optionName={this.props.ctx_labels.singular.category}
      fetch={this.fetch}
      unselectedValue=""
    />
  );
}


export default
withApiContext(
  withLabelsContext(
    CategorySelect
  )
)
