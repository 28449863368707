import React from "react";
import InfoCard from "./InfoCard";
import TestQuestion from "./TestQuestion";
import css from "../StudentAssessments/Question/question.module.scss";
import  Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import MediaQuery from "react-responsive";
import {  Col, Row } from "react-bootstrap";
import Pdf from "components/PlayerNew/Pdf";

const Question = props => {
  const { question, answers, onAnswered, onReview, isFlaged, flag,  onAttempted, answerFooter, Ids ,  disabled, pdfComment, isPlayerTest, qNumber, section_order } = props;
// const section_orders =section_order?section_order[0].section_order : question.section_order;
const questionNumber = qNumber ? qNumber : question.order
const  disabledText="Maximum question limit for this section reached";
const handleFlagClick = (newIsFlag) => {
  props.isFlaged(newIsFlag); // Call the isFlaged prop
};
  return (
    <div>
    {/* <div className={`${css.title} text-color4`}> {`Section ${section_orders}`} - Question {questionNumber }</div> */}
    {/* QN1. */}
   
   { <TestQuestion
        {...question}
        {...answers}
        // mathSymbol={question.math_symbol}
        disabled={disabled}
        onAnswered={onAnswered}
        onReview={onReview}
        onAttempted={onAttempted}
        disabledText={props.fromChapterInner&& disabledText}
        bgColor="#fff" 
        // count={(Ids.indexOf(question.id)+1)}
        pdfComment={pdfComment}
        isPlayerTest={isPlayerTest}
        questionNumber ={questionNumber}
        isFlaged = {handleFlagClick}
        flag = {flag}
        fromChapterInner={props.fromChapterInner}
        count={Ids.indexOf(question.id) + 1}
        fromCreateTest = {props.fromCreateTest}
        fromAssessment={props.fromAssessment}
        togglefunc={props.togglefunc}
      />    
   }
    {/* {<div style={{fontSize:14,position: "absolute", top: 25, right: 128 }}>{question.shortCodes}</div>} */}
  </div>
  );
};

export default Question;
