import * as config from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import * as util from "services/util";
import { InputField } from "components";
// import './FormField.css';

class FormField extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    validationColor: PropTypes.arrayOf(PropTypes.oneOf(["success", "error"]))
      .isRequired,
    validationFeedback: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    type: "text",
    maxLength: config.maxFieldLength,
    required: false,
    validationColor: ["error"],
    validationFeedback: false,
  };

  isCustomControl = () => typeof this.props.type !== "string";

  handleChange = (arg) => {
    const { name } = this.props;
    const value = this.isCustomControl() ? arg : arg.target.value;
    this.props.onChange({ name, value });
  };

  render = () => {
    const {
      className,
      name,
      onChange,
      label,
      placeholder,
      required,
      validation,
      validationColor,
      validationFeedback,
      ...controlProps
    } = this.props;
    let controlClass = InputField;
    if (this.isCustomControl()) {
      controlClass = controlProps.type;
      delete controlProps.type;
      controlProps.name = name;
      controlProps.required = required;
    }
    controlProps.onChange = this.handleChange;
    controlProps.placeholder = placeholder != null ? placeholder : label;
    let validationState =
      validation == null ? null : validation === true ? "success" : "error";
    controlProps.isInvalid = validationState === "error";
    const control = React.createElement(controlClass, controlProps);
    if (validationColor.indexOf(validationState) === -1) {
      validationState = null;
    }
    const help = typeof validation === "boolean" ? null : validation;
    return (
      <FormGroup
        className={className}
        controlId={name}
        validationState={validationState}
      >
        {label &&
          (!required ? (
            <label className="fw-500">{util.capitalizeWord(label)}</label>
          ) : (
            <label className="fw-500">
              {util.capitalizeWord(label)}{" "}
              <span className="text-danger">*</span>
            </label>
          ))}
        {control}
        {validationFeedback && <FormControl.Feedback />}
        {help && <small className="text-danger">{help}</small>}
      </FormGroup>
    );
  };

  static RequiredMessage = () => (
    <div className="form-required-field-message">
      <span className="form-field-required">*</span>
      <span>{" = required field"}</span>
    </div>
  );
}

export default FormField;
