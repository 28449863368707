import React, { Component } from "react";
import JoditEditor from "jodit-react";
import { Col, Row } from "react-bootstrap";
import "./HTMLEditor.css";
import PropTypes from "prop-types";
import { withUserContext } from "context";
import FormField from "components/FormField";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import classNames from "classnames";
import ReactDOM from 'react-dom';
import FullScreen from "components/PlayerNew/Video/FullScreen";
import HTMLEditorfullscreen from "./HTMLeditorfullscreen";

class HTMLEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorContent: "",
      isInvalidUrl: false,
    };
  }

  static propTypes = {
    frameClassName: PropTypes.string,

    desktopUrl: PropTypes.string.isRequired,

    mobileUrl: PropTypes.string.isRequired,

    onChange: PropTypes.func.isRequired,

    baseControls: PropTypes.element.isRequired,

    onModuleProgress: PropTypes.func,

  };

  static defaultProps = {
    desktopUrl: "",

    mobileUrl: "",
  };
 

  handleEditorChange = (editorContent) => {
    this.setState({ editorContent }); // Update the local state with editor content

    this.props.onChange({
      content: editorContent,

      saveFile: true,

      length: 1,
      isInvalidUrl: false,
    });
  };

  jodit;

  setRef = (jodit) => (this.jodit = jodit);



  joditConfig = {
    readonly: false,

    height: "250px",

    maxWidth:"500px",

    minHeight:"100px",
    
    allowResizeY: false,

    hidePoweredByJodit: true,

    removeButtons:
      "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,align,size,redo-undo,clean-html,powered-by-jodit,source",
  };
  

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: 1,
        maxViewedPos: 1,
      });
    }
  };
  
  render() {
    return (
      <Row>
        {
            (
              <>
                <Col sm={6}>
                  {this.props.renderFormat("HTMLEditor")}

                  <JoditEditor
                    name="name"
                    value={this.props.isContentBuilder ? this.props.url || this.props.browseUrl : this.props.contentUrl}
                    // || this.state.editorContent
                    onChange={this.handleEditorChange}
                    config={this.joditConfig}
                    editorRef={this.setRef}
                    style={{ minHeight: '100px', overflowY: "scroll" }}
                  />
                </Col>
                <Col sm={6}>
                 
                <HTMLEditorfullscreen
                editorContent ={this.state.editorContent}/> 
                </Col>
              </>
            )
        }

      </Row>
    );
  }
  
}

export default withUserContext(HTMLEditor);

