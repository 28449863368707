import { Button } from "components";
import { Icon6Dots } from "Icons";
import DragAndDrop from "pages/ContentBuilder/DragAndDrop";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { TestContext } from "./Context/TestContex";
import css from "./testCreation.module.scss";
function SectionReorder(props) {
  const {
    testData,
    setTestData,
    resetTestData,
    question,
    setQuestion,
    sections,
    responseSetter,
    sectionSetter,
    restQuestion,
    setSections,
    testFeedback,
    setTestFeedback,
  } = useContext(TestContext);
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": theme.primary,
  };
  const [sectionData, setSectionData] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});

  useEffect(() => {
    const selectedSec = sections.find(
      (section) => section.section_order === props.sectionOrder
    );
    setSelectedSection(selectedSec)
    setSectionData(sections)
  }, []);
  
  const handleUpdate = (data) => {
    const resetOrder = data.map((section, index) => {
      const updatedSectionOrder = index + 1;
      const updatedQuestions = section.questions.map(question => ({
        ...question,
        section_order: updatedSectionOrder
      }));
      return {
        ...section,
        section_order: updatedSectionOrder,
        questions: updatedQuestions
      };
    });
  
    setSectionData(resetOrder);
  };
  
  const handleSave = () => {
    const selectedSec = sectionData.filter((item)=>item.section_name === selectedSection.section_name);
    props.handleSectionData(selectedSec[0])
    setSections((prev)=>{
      return(
        sectionData
      )
    });
    props.setSelectedSection(selectedSec[0].section_order)
  }
  const renderReorder = (section,index) => {
    return (
      <>
        <div
          key={index}
          className={css.addquestionCard}
          style={inlineBackground}
        >
          <Icon6Dots />
          <div className={css.addQue}> {section.section_name}</div>
        </div>
        <div className={css.horizontalLine} style={inlineBackground}></div>
      </>
    )
  }
  return (
    <div className={css.sectionReorder}>
    <div style={{ marginLeft: "-25px" }}>
      <DragAndDrop
        items={sectionData}
        render={renderReorder}
        onUpdate={(data) => handleUpdate(data)}
        customId={"question"}
      />
      <div className={css.questionButton} style={ sections.length<5 ? {position: "absolute",bottom: "0",right: "0"}:{}}>
        <Button
          className={css.rectangleButton}
          style={{
            backgroundColor: theme.primary,
            borderColor: theme.primary,
          }}
          // disabled={isSaveDisabled}
          onClick={handleSave}
        >
          Done
        </Button>
        <Button
          className={css.rectangleButton}
          variant="btn-outline-cancel"
          onClick={props.handleReset}
        >
          Cancel
        </Button>
      </div>
    </div>
    </div>
  );
}

export default SectionReorder;
