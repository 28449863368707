import { Button, OverlayTooltip, PageTitle } from "components";
import React, { Component } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import SectionNumber from "pages/Assessments/StudentAssessments/Section/SectionNumber";
import css from "pages/Assessments/StudentAssessments/student-assessments.module.scss";
import { withRouter } from "react-router-dom";
import { withApiContext, withLabelsContext, withWindowContext, withUserContext, withAnalyticsContext } from "context";
import Timer from "react-compound-timerv2/build";
import Question from "pages/Assessments/InternalItems/Question"
import Loader from "components/Spinners/Loader";
import * as config from "config";
import { Spin as SpinIcon } from "components/Spinners/Spin";
import { Redirect } from "react-router";
// import OverlayTooltip from 'components/OverlayTooltip/OverlayTooltip';
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import { Link } from "react-router-dom";
import * as util from "services/util";
import { IconCancelAssessment, IconClockAssessment, IconSaveAssessment, IconSubmitAssessment, IconNextNew, IconQuestionGrid } from "Icons";
import styled from "styled-components";
import Grid from "pages/Assessments/StudentAssessments/Section/Grid";
import ResponsiveTabsAssessment from "components/ResponsiveTabs/ResponsiveTabAssessment";


const StyledScrollbarDiv = styled.div`
  /* Your scrollbar styles here */
  scrollbar-color: rgb(10, 119, 227) #7bbbe0;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color2};
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color2};
  }
    
  /* Customize scrollbar track when dragging */
  &::-webkit-scrollbar-thumb:active {
    background-color: #333;
  }
`;

const StyledButton = styled.div`
    .${css.previous}{
      background-color: ${({ theme }) => theme.color2};
    }
    .${css.next}{
      background-color: ${({ theme }) => theme.color2};
    }
`;

const StyledScrollbar = styled(Col)`
  /* Your scrollbar styles here */
  scrollbar-color: rgb(10, 119, 227) #7bbbe0;
    
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color2};
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.color2};
  }
    
  /* Customize scrollbar track when dragging */
  &::-webkit-scrollbar-thumb:active {
    background-color: #333;
  }
`;

const StyledPlayerButton = styled.span`
  && {
    .${css.grid}{
      color: ${({ theme }) => theme.color2};
    }
    .grid{
      fill: ${({ theme }) => theme.color2};
    }
  }
`;

class AssessmentTest extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      gridOpen: false,
      modalOpen: false,
      sections: [],
      testQuestions: {},
      selectedItem: null,
      sectionItem: 0,
      isLoaded: false,
      questionSection: this.props.questionSection
    }
  }

  handleClick = () => {

    this.props.onAssessmentTestChange(this.state.selectedItem);
  };
  componentDidMount = () => {
    if (!this.props.fromCreateTest) {
      if (!document.fullscreenElement) {
        if (!this.props.isIphone) {
          document.documentElement.requestFullscreen(); // Re-enter if exited
          // this.props.onSelectExit()
        }
      }
    }
    this.setState({ isLoaded: this.props.isLoaded, testQuestions: this.props.testQuestions })
  }
  componentDidUpdate = (prevState) => {
    // this.props.onSelectItem(this.state.selectedItem)
    if (this.state.sectionItem === 0 && this.state.selectedItem === null) {
      this.setState({ selectedItem: this.props.selectedItem })
    }


    if (this.props.fromCreateTest && prevState.isLoaded !== this.state.isLoaded) {
      this.setState({ isLoaded: this.props.isLoaded })
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false, img: "" });
  };

  handleCloseGrid = () => {
    this.setState({ gridOpen: !this.state.gridOpen })
  }


  handleArrowAction = (action) => {

    const index = this.props.questionIds.indexOf(this.state.selectedItem);
    const nextId =
      action === "right" ? this.props.questionIds[index + 1] : this.props.questionIds[index - 1];
    const currQuestion = this.props.testQuestions[nextId];
    if (
      !(action === "left" && index === 0) &&
      !(action === "right" && index === this.props.questionIds.length - 1)
    ) {
      this.props.fromChapterInner ? this.handleSelectedItem(nextId, currQuestion.type) : this.handleSectionSelectedItem(nextId, currQuestion.type)
      // this.setState({ selectedItem: nextId });
    } else {
      return null;
    }
  };
  componentWillUnmount = () => {
    // Remove event listener when component unmounts
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  renderAnswerFooter = () => {

    const { index, selectedItem, questionIds } = this.props;
    const Disabled = true;
    const selectedIndex = questionIds?.indexOf(this.state.selectedItem);
    const length = questionIds?.length
    const tip = "Previous"
    const tip1 = "Next"
    return (
      index !== 0 && !this.props.testClosed && (
        <Col md={12} lg={12} sm={12} xl={12}>

          <div className={css.pagination_footer}>
            <StyledButton>
              <OverlayTooltip
                tip={tip}
                placement="top"
              >

                <Button
                  iconOnly
                  onClick={() => this.handleArrowAction("left")}
                  size="sm"
                  className={css.previous}
                  disabled={selectedIndex === 0}
                >
                  <IconNextNew className={css.iconrotate} fill="white" height={18} width={14} />
                </Button>
              </OverlayTooltip>
              <OverlayTooltip
                tip={tip1}
                placement="top"
              >

                <Button
                  size="sm"
                  className={css.next}
                  onClick={() => this.handleArrowAction("right")}
                  disabled={selectedIndex + 1 === length}
                >
                  <IconNextNew fill="white" height={18} width={15} />
                </Button>
              </OverlayTooltip>
            </StyledButton>
          </div>
        </Col>

      )
    );
    // else return null

  };

  handleOnAttempted = (value) => {

    const selectedItem = this.state.selectedItem ? this.state.selectedItem : this.props.selectedItem;
    const { questionSection } = this.props;
    let testQuestions = this.props.testQuestions;
    testQuestions[selectedItem].attempted = value ? 1 : 0;
    const qSection = testQuestions[selectedItem].section_order;

    let newQuestionSection = questionSection.map((section, i) => {
      if (section.section_order === qSection) {
        const currentAttempted = section.questions.filter(quest => quest.attempted === 1).length;
        return {
          ...section,
          questions_attempted: currentAttempted,
        }
      }
      else return section
    });

    this.setState({ testQuestions: testQuestions, questionSection: newQuestionSection }, () => this.props.findTotalAttended());
    // this.findTotalAttended(newQuestionSection,"updated")
  };

  handleQuestionAnswered = (response, file) => {
    // const { selectedItem } = this.state;
    const selectedItem = this.state.selectedItem ? this.state.selectedItem : this.props.selectedItem;
    const typeQBE = config.testQuestionTypeQBE;
    let testQuestions = this.props.testQuestions;
    testQuestions[selectedItem].user_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response.map(({ answer }) => answer)
        : response || [];
    testQuestions[selectedItem].match_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response || []
        : [];
    if (file) {
      testQuestions[selectedItem].file = file;
    }
    if (this.props.rand_question === 0) {
      this.setState({ testQuestions: testQuestions });
    }
  };

  dateToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var time =
      String(today.getHours()).padStart(2, "0") +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      String(today.getSeconds()).padStart(2, "0");

    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd + " " + time;
    return today;
  };

  handleFlaged = (flag) => {
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].flagged = !flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  }

  mkTestQuestionOverlay = (description, content, testQuestions) => {
    const { questionIds, selectedItem, rand_question, fromChapterInner } = this.props;
    const { questionSection } = this.state;
    const selectedItems = this.state.selectedItem ? this.state.selectedItem : selectedItem;
    if (selectedItem) {
      const qNumber = this.props.questionIds.indexOf(selectedItems)

      let question = this.props.testQuestions[selectedItems];
      const answers = this.props.testAnswers[selectedItems];
      const modifiedAns = answers !== undefined ? answers : {}
      const qSection = question.section_order;
      const currQuestionSection =
        questionSection &&
        questionSection.filter((section) => section.section_order === qSection);
      const currQuestion =
        currQuestionSection &&
        currQuestionSection[0].questions.filter(
          (quest) => quest.id === question.id
        );
      const disabled =
        (currQuestionSection[0].questions_attempted <=
          +currQuestionSection[0].max_questions &&
          currQuestion &&
          currQuestion[0].attempted === 1) ||
          (currQuestionSection[0].questions_attempted <
            +currQuestionSection[0].max_questions &&
            currQuestion &&
            currQuestion[0].attempted === 0)
          ? false
          : true;
      const select = this.props.questionSection.filter(sec => question.section_name === sec.section_name ? sec.section_order : "")
      let isFlag = testQuestions[this.state.selectedItem]?.flagged;

      return (
        // <div style={screenSize > 822 ? { marginLeft: "-15px" } : {}}>
        <div className={`${css.question_card} border-0`}>
          <div className={(description || content) ? css.body_question_description : css.body_question}>
            <Question
              section_order={select}
              qNumber={qNumber + 1}
              question={question}
              Ids={questionIds}
              answers={answers}
              disabled={disabled}
              onAnswered={this.handleQuestionAnswered}
              onReview={this.props.onReview}
              onAttempted={this.handleOnAttempted}
              bgColor="#fff" //{this.state.module.type === 'pdf' ? '#fff' : '#000'}

              isFlaged={this.handleFlaged}
              flag={isFlag}
              fromChapterInner={this.props.fromChapterInner}
              fromCreateTest={this.props.fromCreateTest}
              fromAssessment={this.props.isAssessment}
              togglefunc={this.props.toggleAssignment}
            />
          </div>
        </div>

        // </div>
      );
    } else return null;
  };


  renderMedia = (description, content, format) => {
    const content_format = content ? format : ""
    let MediaPlayer;
    switch (content_format) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }
    const currQuestion = this.props.testQuestions[this.props.selectedItem];
    const currSection = this.props.questionSection.filter((section) => {
      return section.section_order === currQuestion.section_order;
    });
    
    return (
      <>
        <div className={css.section_media}>
          {content === null
            ? ""
            : format === "3"
              ? <img
                src={this.props.fromCreateTest ? currSection[0].media_link !== null ? currSection[0].media_link : content : content}
                onClick={() =>
                  this.handleImageClick(
                    content
                  )
                }
              />
              : <MediaPlayer
                className={css.media}
                url={this.props.fromCreateTest ? currSection[0].media_link : content}
                pdfUrl={this.props.fromCreateTest ? currSection[0].media_link : content}
                fromStudentAssessment
                fromCreateTest={this.props.fromCreateTest}
                style={format === 0 && { marginLeft: "-15px" }}
              />
          }
          <div className={css.section_detail}>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              className={css.section_description}
            />

          </div>
        </div>
      </>
    )
  }

  renderQuestionTest = () => {

    const {
      questionSection,

      testQuestions,
      testAnswers,
      questionIds,
      fromChapterInner
    } = this.props;
    const selectedItem = this.state.selectedItem === null ? this.props.selectedItem : this.state.selectedItem
    const currQuestion = testQuestions[selectedItem]
    const currSection = questionSection.filter(section => section.section_order === currQuestion.section_order)
    const index = questionSection.findIndex((section) => {
      return section.questions.some((item) => item.id === selectedItem);
    });
    let sectionDescription = questionSection[index].description
    let sectionContent = questionSection[index].content
    let sectionContentFormat = questionSection[index].content_format
    let desc = sectionDescription && sectionDescription.includes('<p><br></p>')
    let description = desc ? "" : sectionDescription
    let MediaPlayer;
    switch (sectionContentFormat) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }
    { this.props.fromCreateTest && this.state.isLoaded && this.props.fetchPreviewAnswers(this.state.selectedItem === null ? this.props.selectedItem : this.state.selectedItem, currQuestion.type) }
    const select = questionSection.map((section) => {
      return section.section_order
    })
    if (questionSection[0].questions.length > 0) {
      return (
        <>
          {(description || sectionContent) ? (
            <>

              <StyledScrollbar md={5} className={this.props.fromCreateTest ? css.testSectionDetails : css.section_details}>
                {this.renderMedia(description, sectionContent, sectionContentFormat)}
              </StyledScrollbar>
              <StyledScrollbar md={7} className={this.props.fromCreateTest ? css.testQuestionStyle : css.question_style}>
                {selectedItem && this.mkTestQuestionOverlay(description, sectionContent, testQuestions)}
              </StyledScrollbar>
            </>
          )
            :
            <StyledScrollbar md={12} className={css.section_details_full}>
              {selectedItem && this.mkTestQuestionOverlay(description, sectionContent, testQuestions)}
            </StyledScrollbar>
          }
        </>
      );
    }
    if (this.state.selectedItem !== null || questionSection[0].questions.length < 0) {
      return this.renderLoadingSpinner();
    }
  };


  renderLoadingSpinner = () => (
    <div className={`loading-indicator-wrapper ${css.center}`}>
      <div className="loading-indicator">
        <SpinIcon width={35} />
      </div>
    </div>
  );




  handleSelectedItem = (selectedItemId, question) => {
    this.setState({ selectedItem: selectedItemId })
  }

  handleSectionSelectedItem = (selectedItemId, type) => {
    this.setState({ selectedItem: selectedItemId })
    // this.setState({
    //       selectedItem: selectedItemId,
    // },this.fetchAnswers(selectedItemId));
    if (type !== 3 && type !== 4) {
      this.props.testAnswers[selectedItemId].answers.length === 0 ?
        this.props.fetchPreviewAnswers(selectedItemId) :
        this.setState({ selectedItem: selectedItemId })
    } else {
      this.setState({ selectedItem: selectedItemId })
    }
  }

  renderSectionName = () => {
    const { questionSection, fromChapterInner, fromCreateTest, selectedItem } = this.props;
    // const {questionSection} = this.state;
    const index = questionSection.findIndex((section) => {
      return section.questions.some((item) => {
        if (fromChapterInner) {
          return item.id === (this.state.selectedItem === null ? this.props.selectedItem : this.state.selectedItem);
        } else {
          return item.id === this.state.selectedItem;
        }
      });
    });

    const sections = questionSection.map((sec) => {
      const isFirstQuestionIdEqual = sec.section_order === (sec.questions.length > 0 ? sec.questions[0].id : null);
      return { ...sec, isFirstQuestionIdEqual };
    });
    // this.setState({sections: sections})
    const itemDataLoop = questionSection?.map((section, sectionIndex) => {
      const  section_hide  = this.props.tests?.section_hide
      return {
        title: section_hide === 1 ? "Sections" : section.section_name,
        content: (
          <>
            <SectionNumber
              rand={this.props.rand_question}
              qpool={this.props.qpool}
              question={section.questions.length !== 0 && section.questions}
              questionIds={this.props.questionIds}
              selectedItemId={this.props.fromCreateTest ? this.state.selectedItem : this.state.selectedItem}
              // selectedItemId={this.props.fromCreateTest ? (this.state.sectionItem === 0 ? this.props.selectedItem :this.state.selectedItem) : selectedItem}
              selected={this.props.fromChapterInner ? (selectedItemId) => {
                this.handleSelectedItem(selectedItemId, section.questions);
              } : (selectedItemId, type) => { this.handleSectionSelectedItem(selectedItemId, type) }}
              fromCreateTest={this.props.fromCreateTest}
            />
          </>
        ),
      };
    });
    const handleTabChange = (selectedTabKey) => {
      this.setState({ sectionItem: selectedTabKey, isLoaded: true })
      if (this.props.rand_question === 0 || (this.props.fromCreateTest && this.state.selectedItem !== null)) {

        const selectedSection = questionSection.find((section, sectionIndex) => {
          return section.section_order - 1 === selectedTabKey;
        });

        if (selectedSection) {
          // Assuming that each section has at least one question
          const selectedItemId = selectedSection.questions.find((item) => {
            return item.order === 1 || !item.order;
          });
          if (selectedItemId) {
            this.setState({ selectedItem: selectedItemId.id });
          }
          if (this.tabsRef?.current) {
            if (index) {
              index.focus();
            }
          }
        }
      }
      else {
        const selectedItemId = questionSection[selectedTabKey].questions[0].id
        this.setState({ selectedItem: selectedItemId });
      }

    };

    return (
      <ResponsiveTabsAssessment
        ref={this.tabsRef}
        tight
        items={itemDataLoop}
        showMore={false}
        selectedTabKey={index}
        onChange={handleTabChange}
        fromStudentAssessment
        showTails={true}
      />
    );
  };

  handleGrid = () => {
    this.setState({ gridOpen: !this.state.gridOpen })
  }

  renderHeader = () => {
    const tip = "Click here to view question status"
    const screenSize = window.screen.width
    const title = this.props.tests?.title.length > 80 ? this.props.tests?.title.slice(0, 80) + "..." : this.props.tests?.title;
    const mobTitle = this.props.tests?.title.length > 10 ? this.props.tests?.title.slice(0, 10) + "..." : this.props.tests?.title;
    return (
      <div className={css.toolbar_item} style={{ alignItems: "center" }}>
        <div className={css.title_name}>{screenSize > 768 ? title : mobTitle}</div>
        <div >
          {this.props.selectedItem !== null ? (
            <StyledPlayerButton >
              <div className={css.buttonsContainer}>
                {this.props.tests !== null && this.props.tests.duration !== 0 && this.props.tests.testType === 1 &&
                  this.props.questionSection[0].questions.length > 0 &&
                  <>
                    <Button variant="white" size="sm" className={css.timer}>
                      <div className={css.timercontainer}>
                        <IconClockAssessment
                          className="grid"
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                            display: "flex",
                            paddingTop: "3px"
                          }}
                        />
                        {this.props.startTestTimer()}
                      </div>
                    </Button>
                  </>
                }
                <OverlayTooltip
                  tip={tip}
                  placement="top"

                >
                  <Button
                    onClick={this.handleGrid}
                    variant="white"
                    className={css.grid}
                    size="sm"
                    disabled={this.props.load || this.props.onExit || this.props.onSave}
                  >
                    <IconQuestionGrid className="grid" style={{ width: "15px", height: "15px", cursor: "pointer", display: "flex", strokeWidth: 2, padding: "0px 0px 0px 2px" }} />
                  </Button>
                </OverlayTooltip>
                <Button
                  onClick={this.props.onSelectSave}
                  variant="primary"
                  className={css.save}
                  size="sm"
                  disabled={this.props.load || this.props.onExit || this.props.onSave}
                >
                  <IconSaveAssessment style={{ width: "15px", height: "15px", cursor: "pointer", fill: "white" }} />
                  {screenSize > 822 && <span className={css.label}>{this.props.ctx_labels.singular.save}</span>}
                </Button>
                <Button
                  onClick={this.props.onSelectSubmit}
                  variant="success"
                  size="sm"
                  className={css.submit}
                  disabled={this.props.load || this.props.onSave || this.props.onExit}
                >
                  <IconSubmitAssessment
                    style={{ width: "15px", height: "15px", cursor: "pointer", fill: "white", paddingTop: "1px", paddingRight: "0px" }} />
                  {screenSize > 822 && <span className={css.label}> {this.props.ctx_labels.singular.submit}</span>}
                </Button>

                {this.props.location.assessmentData.fromChapterInner ?
                  <Link
                    to={{
                      pathname: config.ROUTES.traineeCurriculum,
                      subjectData:
                        this.props.location.assessmentData?.subjectData
                    }}
                  >
                    <Button
                      variant="danger"
                      className={css.cancel}
                      size="sm"
                      disabled={this.props.load || this.props.onSave || this.props.load}
                    >
                      <IconCancelAssessment
                        style={{ width: "15px", height: "15px", cursor: "pointer", fill: "white", paddingTop: "1px", paddingRight: "0px" }} />
                      {screenSize > 822 && <span className={css.label}>{this.props.ctx_labels.singular.cancel}</span>}
                    </Button>
                  </Link>
                  :

                  <Button
                    onClick={this.props.onSelectExit}
                    variant="danger"
                    className={css.cancel}
                    size="sm"
                    disabled={this.props.load || this.props.onSave || this.props.load}
                  >
                    <IconCancelAssessment
                      style={{ width: "15px", height: "15px", cursor: "pointer", fill: "white", paddingTop: "1px", paddingRight: "0px" }} />
                    {screenSize > 822 && <span className={css.label}>{this.props.ctx_labels.singular.cancel}</span>}
                  </Button>}
              </div>
            </StyledPlayerButton>
          ) : null}
        </div>
      </div>
    )
  }



  handleModalGrid = () => {
    const { questionSection, selectedItem, testQuestions } = this.props;
    const flaggedq1 = Object.values(testQuestions)?.find(q1 => q1.flagged === 1)
    const unattemptedq1 = Object.values(testQuestions)?.find(q1 => q1.attempted === 0)
    const attemptedq1 = Object.values(testQuestions)?.find(q1 => q1.attempted === 1)
    return (
      <>
        {attemptedq1 && (
          <>
            <div className={`${css.title} text-color2`}>
              Attempted
            </div>
            <Grid
              question={questionSection}
              selectedItemId={selectedItem}
              attempted
              rand={this.props.rand_question}
              selected={(selectedItemId) => {
                this.setState({
                  selectedItem: selectedItemId,
                  gridOpen: false,
                  isConfirmationModalOpen: false

                });
              }}
              qpool={this.props.qpool}
            />
          </>
        )}

        {flaggedq1 && (
          <>

            <div className={`${css.title} text-color2`}>
              Flagged
            </div>
            <Grid
              question={questionSection}
              selectedItemId={selectedItem}
              flagged
              rand={this.props.rand_question}
              selected={(selectedItemId) => {
                this.setState({
                  selectedItem: selectedItemId,
                  gridOpen: false,
                  isConfirmationModalOpen: false
                  // index: 2
                });

              }}
              qpool={this.props.qpool}
            />
          </>
        )}
        {unattemptedq1 && (
          <>
            <div className={`${css.title} text-color2`}>
              Unattempted
            </div>
            <Grid
              question={questionSection}
              selectedItemId={selectedItem}
              unattempted
              rand={this.props.rand_question}
              selected={(selectedItemId) => {
                this.setState({
                  selectedItem: selectedItemId,
                  gridOpen: false,
                  isConfirmationModalOpen: false
                  // index: 2
                });

              }}
              qpool={this.props.qpool}
            />
          </>
        )}
      </>
    )
  }


  render = () => {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: config.ROUTES.assessmentsLanding,
        data: { filterdata: this.props.location.assessmentData.filterdata },
        assessmentData: this.props.location.assessmentData.subjectData,
        fromChapterInner: this.props.location.assessmentData.fromChapterInner,
      }} />;
    }
    const question = {
      title: "Section A - Question 4",
      question: "What’s the one thing that doesn’t belong to a to-do list?",
      options: [
        { text: "The movie you want to watch", value: "1" },
        { text: "A detailed list of all tasks for the day", value: "2" },
      ],
    };


    const screenSize = window.screen.width

    return (
      <>
        <Modal
          animation={false}
          show={this.props.isConfirmationModalOpen}
          onHide={() => {
            this.props.handleConfirmationSubmit(false);
          }}
          scrollable={true}
          size={"lg"}
          centered
        >
          <Modal.Body>{this.handleModalGrid()}</Modal.Body>
          <Modal.Footer className={css.modalfooter}>
            <div className={css.confirmmsg}>
              {this.props.confirmationMessage}
            </div>
            <div style={{ flexDirection: "row" }}>
              <Button
                onClick={() => {
                  this.props.handleConfirmationSubmit(true);
                }}
                style={{ marginRight: '10px' }}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.props.handleConfirmationSubmit(false);
                }}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          animation={false}
          show={this.state.modalOpen}
          onHide={this.handleClose}
          scrollable={true}
          size={"lg"}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={this.state.img} />
          </Modal.Body>
        </Modal>
        <Modal
          animation={false}
          show={this.state.gridOpen}
          onHide={this.handleCloseGrid}
          scrollable={true}
          // backdrop="static"
          size={"lg"}
          centered
        >
          <Modal.Header>
            <Modal.Title> {this.state.tests?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.handleModalGrid()}</Modal.Body>
        </Modal>

        <div style={{ backgroundColor: "white" }}>
          {this.props.testClosed && this.props.onTestClosed}
          {!this.props.testClosed && (
            <>
              {this.props.fromChapterInner && <Row
                style={
                  screenSize > 822
                    ? { backgroundColor: "white" }
                    : { backgroundColor: "white", marginRight: "0px" }
                }
              >
                <Col md={12} lg={12} xl={12}>
                  {this.renderHeader()}
                </Col>
              </Row>}
              <Row style={screenSize > 822 ? {} : { marginRight: "0px" }}>
                <Col
                  md={12} lg={12} xl={12}
                  className={css.section_style}
                  style={this.props.fromCreateTest ? { paddingTop: "20px" } : {}}
                >
                  <div className={css.toolbar_section_name}>
                    {this.renderSectionName()}
                  </div>
                </Col>
              </Row>
              <StyledScrollbarDiv
                className={window.screen.width < 822 ? css.mobile : null}
              >
                <Row
                  style={
                    window.screen.width < 822 ? { marginRight: "0px" } : {}
                  }
                >
                  <>
                    {this.props.selectedItem !== null && (
                      <>{this.renderQuestionTest()}</>
                    )}
                  </>
                  {this.state.onSubmit && (
                    <Col
                      md={12}
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {this.renderLoadingSpinner()}
                    </Col>
                  )}
                </Row>
              </StyledScrollbarDiv>
              {/* <MediaQuery minWidth={822}> */}
              <Row className={this.props.fromContentBuilder ? css.footer_style_cb : css.footer_style}>
                {this.renderAnswerFooter()}
              </Row>
              {/* </MediaQuery> */}
            </>
          )}
        </div>
      </>
    );


  };
}
export default withWindowContext(
  withLabelsContext(withApiContext(withRouter(withUserContext(withAnalyticsContext(AssessmentTest)))))
);

