import React from 'react';

import './Time.css';


const formatWidth = (seconds) =>
  seconds <      10 * 60 ? 4 :
  seconds <      60 * 60 ? 5 :
  seconds < 10 * 60 * 60 ? 7 :
                           8;

const formatTime = (seconds, width) => {
    const date = new Date(Date.UTC(1970,1,1,0,0,0,0));
    seconds = isNaN(seconds) || seconds > 86400
        ? 0
        : Math.floor(seconds);
    date.setSeconds(seconds);
    return date.toISOString().substr(19 - width, width);
};

export default ({ currentTime, duration, className, hidden }) => {
    const width = formatWidth(duration);
    return (
        <div
          className={[
            'video-time',
            className
          ].join(' ')}
          style={hidden ? { visibility: 'hidden' } : null}
        >
            <span className="video-time-current">
                { formatTime(currentTime, width) }
            </span>
            /
            <span className="video-time-duration">
                { formatTime(duration, width) }
            </span>
        </div>
    );
};
