import { Button, OverlayTooltip, PageTitle } from "components";
import React, { Component } from "react";
import { MainContainer } from "pages/Main";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "./Section/Section";
import css from "./student-assessments.module.scss";
import { withRouter } from "react-router-dom";
import {
  withApiContext,
  withLabelsContext,
  withWindowContext,
  withUserContext,
  withAnalyticsContext,
} from "context";
import Timer from "react-compound-timerv2/build";
import Question from "./InternalItems/Question";
import Loader from "components/Spinners/Loader";
import * as config from "config";
import { Spin as SpinIcon } from "components/Spinners/Spin";
import { Redirect } from "react-router";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import Media from "pages/Components/Meet/config/media";
import MediaQuery from "react-responsive";
import * as util from "services/util";

// const Pagination = () => {
//   return (
//     <div className={css.pagination}>
//       <Button variant="gray" pill wide>
//         Previous
//       </Button>
//       <div className={css.pagination_number}>
//         <span>4</span> of 8
//       </div>
//       <Button variant="primary" pill wide>
//         Next
//       </Button>
//     </div>
//   );
// };
class TestPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      redirect: false,
      onClickBackButton: false,
      tests: null,
      questionSection: [],
      questionAnswer: [],
      testQuestions: {},
      testAnswers: {},
      selectedItem: null,
      assessmentId: null,
      index: 0,
      prevIndex: 0,
      direction: "prev",
      startBtn: null,
      btnActive: false,
      allSaved: true,
      updateElapsedTimeOrNot: true,
      alertTime: false,
      duration: 0,
      action: 0,
      totalQnsAttended: 0,
      testState: 1,
      testClosed: false,
      load: false,
      COLORS: [
        "#0e4f69",
        "#FFBB28",
        "#00C49F",
        "#08a822",
        "#00A49F",
        "#FFDB48",
        "#FG8012",
      ],
      status: 0,
      summaryData: null,
      summaryError: null,
      testAssessmentId: null,
      loading: true,
      pdfUrl: "",
      modalOpen: false,
      img: "",
      answers: [],
      isLoaded: false,
      previousHeight:0
    };
    this.myDivRef = React.createRef();
    this.initialTime = new Date();
    this.initiallyAttended = 0;
  }

  initSectionItem = () => ({
    section_order: 0,
    section_name: "",
    questions: [],
  });

  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  componentDidMount = () => {

    const questionSection = this.props.preview && this.props.preview;
    const questionAnswer = questionSection.flatMap((q) =>
      q.questions.flatMap((a) => {
        return a.answer !== null && a.answer;
      })
    );
  this.setState({ questionSection, questionAnswer, isLoaded: true });
  this.handleTestAnswers(questionSection);
    if (this.props.location.assessmentData != undefined) {
      this.getStudentAssessmentQuestions();
      this.summaryInterval = setInterval(
        this.handleSummary,
        parseInt(this.props.ctx_user.user.analytics.studyMapTriggerInterval)
      );
    }
  };

componentDidUpdate = (prevProps, prevState) => {
   if(!this.props.preview ==  prevProps.preview) {
    this.setState({questionSection: []})
    }
  const currentHeight = this.myDivRef.current.clientHeight;
  if (currentHeight !== this.state.previousHeight) {
    this.updatePreviousHeight()
    this.props.handleTestHeight && this.props.handleTestHeight(this.myDivRef.current.clientHeight)
  }
}
  updatePreviousHeight = () => {
    this.setState({
      previousHeight: this.myDivRef.current.clientHeight,
    });
 
}
  componentWillUnmount = () => {
    if (this.state.updateElapsedTimeOrNot == true) {
      this.updateElapsedTime();
    }
    clearInterval(this.summaryInterval);
    this.props.handleTestHeight && this.props.handleTestHeight(0)
  };
  handleSummary = (prevModule = null) => {
    var current = new Date();
    const summaryData = {
      event_id: parseInt(
        this.props.location.assessmentData.data.detailsData.assessmentId
      ),
      event_name: this.props.location.assessmentData.data.detailsData.name,
      eventactive_time: Math.round(
        (current.getTime() - this.initialTime.getTime()) / 1000
      ),
      eventstart_at: this.initialTime,
      eventend_at: new Date(),
      intent: "assessment",
      details: {
        publish_test_id: parseInt(
          this.state.tests?.lattestAssessmentId
            ? this.state.tests?.lattestAssessmentId
            : ""
        ),
        // "course_id": "<course id>",
        // "course_name": "<course name>",
        // "course_publish_id": "<course publish id>",
        // "course_publish_name": "<course publish name>",
        // "curriculum_id": "<curriculum id>",
        // "curriculum_name": "<curriculum name>",
        // "topic_id": "<chapter id>",
        // "topic_name": "<chapter name>",
        questions_attended: this.state.totalQnsAttended,
      },
    };
    this.initialTime = current;
    this.updateAnalytics(summaryData);
  };

  updateAnalytics = (r) => {
    this.props.ctx_analytics.update("player")({ ...r });
  };
  getStudentAssessmentQuestions = () => {
    const publish_id =
      this.props.location.assessmentData.data.detailsData.assessmentId;

    // alert(this.props.publishId)
    this.props.ctx_api
      .privateRequestRow({
        cmd: "get_student_assessment_details",
        args: { publishId: publish_id.toString() },
      })
      .then(this.handleGetTestResponse)
      .catch(this.handleGetTestFailure);
  };

  handleImageClick = (img) => {
    this.setState({ modalOpen: true, img });
  };

  //   handleClose = () => {
  //   this.setState({ show: false });
  // };

  handleGetTestResponse = ({ rsp }) => {
    const tests = {
      publishId:
        this.props.location.assessmentData.data.detailsData.assessmentId,
      id: rsp.test_id,
      title: rsp.name,
      identifier: rsp.identifier,
      description: rsp.description,
      category: rsp.standard,
      subject: rsp.subject,
      dateFrom: rsp.from_date,
      dateTo: rsp.to_date,
      timerFrom: rsp.timer_from,
      timerTo: rsp.timer_to,
      duration: rsp.duration,
      status: rsp.status,
      action: rsp.action,
      elapsedTime: rsp.elapsed_time,
      lattestAssessmentId: rsp.latest_assessment_id,
      totMarks: rsp.totmarks,
      obtMarks: rsp.obtmarks,
      totQuestions: rsp.totquestions,
      correct: rsp.correct,
      incorrect: rsp.incorrect,
      missed: rsp.missed,
      attempted: rsp.attempted,
      unattempted: rsp.unattempted,
      testState: rsp.test_state,
      testStateName: rsp.test_state_name,
      testType: rsp.test_type,
      testTypeName: rsp.test_type_name,
      multiAttempt: rsp.multi_attempt,
      totDuration: rsp.total_duration,
      attemptCount: rsp.attempt_count,
      coursePublishId: rsp.course_publish_id,
    };
    this.setState({ tests }, () => {
      this.createStudentAssessment();
      this.setState((s0) => ({
        prevIndex: s0.index,
        index: s0.index + 1,
        status: 1,
        action: 1,
      }));
    });
    // if(this.state.tests !== undefined?(this.createStudentAssessment()):(null))
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  onSelectExit = (e) => {
    const extitTest = (allow) => {
      e.stopPropagation();
      if (allow) {
        this.updateElapsedTime();
        this.goToLanding();
      }
    };

    this.props.ctx_window.simpleConfirm(
      "Are you sure you want to exit this test ?",
      extitTest
    );
  };
  updateElapsedTime = (interval) => {
    const { assessmentId } = this.state;
    const { tests } = this.state;

    //   this.props.ctx_api
    //     .privateRequestRow({
    //       cmd: "insert_update_test_assessment_stub",
    //       args: {
    //         publishId: tests.publishId.toString(),
    //         studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
    //         interval: interval || "0",
    //         endTime: this.dateToday(),
    //         status: interval ? 4 : 5,
    //         coursePublishId: tests.coursePublishId
    //           ? tests.coursePublishId.toString()
    //           : null,
    //       },
    //     })
    //     .then(({ rsp }) => this.handleUpdateElapsedTimeResponse(rsp, interval))
    //     .catch(this.handleUpdateElapsedTimeFailure);
  };

  handleUpdateElapsedTimeResponse = (rsp, interval) => {
    !interval &&
      this.setState({
        duration: rsp.duration,
        //  load: rsp.StatusValue === 1 ? false : true
      });
  };
  goToLanding = () => {
    this.setState({ redirect: true });
  };

  handleActionBtn = (timerStop) => {
    this.setState((s0) => ({ startBtn: !s0.startBtn }));
    if (timerStop) {
      this.setState({ testClosed: true, updateElapsedTimeOrNot: false });
      if (this.state.action) {
        this.onTimerCheckpoint(0, 1);
      }
    }
  };
  onTimerCheckpoint = (rem, completed) => {
    if (completed) {
      this.setState({ updateElapsedTimeOrNot: false });

      this.handleSaveTest(2, () => {
        // this.goToSummary(() => {});
      });
    } else {
      this.setState({ alertTime: true });
    }
  };

  handleSaveTest = (flag, callback) => {
    this.createStudentAssessment(flag, callback);
    // the below line commented for the purpose of doubling issue
    // this.handleActionBtn();
    // callback()
  };

  onSelectItem = (selectedItemId) => () => {
    this.setState({
      selectedItem: selectedItemId,
    });
  };
  onSelectSave = () => {
    this.createStudentAssessment(1);
  };
  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };
  onTestClosed = () => {
    this.setState({ updateElapsedTimeOrNot: false });
    // this.createStudentAssessment(3);
    // this.handleSaveTest(3, () => {
    //   this.setState({ updateElapsedTimeOrNot: false });
    // });
    // this.setState({
    //   updateElapsedTimeOrNot: false });
    // // this.showSuccessPopupAndThen("Saved");
    // this.createStudentAssessment(1);
    // this.props.ctx_window.alert(`Test is closed`, () =>
    //   this.setState({
    //     redirect: true,updateElapsedTimeOrNot: false })

    // );
  };
  createStudentAssessment = (flag, callback) => {
    const { tests } = this.state;
    const { assessmentId, testQuestions, testAnswers } = this.state;
    this.setState({ loading: true });
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_test_assessment_stub",
        args: {
          publishId: tests.publishId.toString(),
          studentAssessmentId: assessmentId ? assessmentId.toString() : "0",
          testQuestions: testQuestions,
          testAnswers: testAnswers,
          endTime: this.dateToday(),
          status: flag || 0,
          coursePublishId: tests.coursePublishId
            ? tests.coursePublishId.toString()
            : null,
        },
      })

      .then(({ rsp }) =>
        this.handleStudentAssessmentResponse(rsp, callback, flag)
      )
      .catch(this.handleStudentAssessmentFailure);
  };

  handleStudentAssessmentFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
    this.setState({ loading: false });
  };

  handleStudentAssessmentResponse = (rsp, callback, flag) => {
    const action = flag === 1 ? "Saved" : "Submitted";
    if (!this.state.assessmentId) {
      this.getTestQuestions(rsp.testassessmentid);
    } else {
      this.setState({ load: rsp.StatusValue === 1 ? false : true });
      this.showSuccessPopupAndThen(action);
    }
    callback && callback();
  };
  showSuccessPopupAndThen = (action) => {
    if (action == "Saved") {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
        })
      );
    } else {
      this.props.ctx_window.alert(`Test ${action} Successfully`, () =>
        this.setState({
          allSaved: true,
          redirect: true,
        })
      );
    }
  };
  dateToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var time =
      String(today.getHours()).padStart(2, "0") +
      ":" +
      String(today.getMinutes()).padStart(2, "0") +
      ":" +
      String(today.getSeconds()).padStart(2, "0");

    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd + " " + time;
    return today;
  };
  onSelectFlag = () => {
    const { selectedItem } = this.state;
    let testQuestions = this.state.testQuestions;
    let flag = testQuestions[selectedItem].flagged;
    testQuestions[selectedItem].flagged = flag ? 0 : 1;
    testQuestions[selectedItem].flagged_on = !flag ? this.dateToday() : "";
    this.setState((s0) => ({ testQuestions: testQuestions }));
  };

  getTestQuestions = (testassessmentid) => {
    const apiCalls = [
      {
        cmd: "get_student_assessment_questions",
        args: { studentAssessmentId: testassessmentid },
      },
      {
        cmd: "get_student_assessment_question_answers",
        args: { studentAssessmentId: testassessmentid },
      },
    ];
    Promise.all(apiCalls.map(this.props.ctx_api.privateRequest))
      .then((rs) => {
        const pre_test = rs[0].rsp;
        const pre_test_ans = rs[1].rsp;
        this.setState({
          questionSection: pre_test[0].questions,
          questionAnswer: pre_test_ans,
          assessmentId: testassessmentid,
        });
        this.findTotalAttended(pre_test[0].questions, "started");
        this.handleTestAnswers();
      })
      .catch(this.handleGetQuestionFailure);
  };
  findTotalAttended = (questionSectionWise, condition) => {
    if (condition == "started") {
      let total = 0;
      questionSectionWise.forEach((item, i) => {
        total = total + item.questions_attempted;
        return total;
      });
      this.initiallyAttended = total;
      this.setState({ totalQnsAttended: 0 });
    } else {
      let total = 0;
      questionSectionWise.forEach((item, i) => {
        total = total + item.questions_attempted;
        return total;
      });
      this.setState({ totalQnsAttended: total - this.initiallyAttended });
    }
  };
  handleTestAnswers = (questionSection, questionAnswer) => {
    const testQuestions = {};
    const testAnswers = {};
    let selectedItem = null;
    let questionIds = [];
    questionSection.forEach((se) => {
      const sortedQuestions = Object.values(se.questions).sort(
        (a, b) => a.sequence - b.sequence
      );
      const addOrderValue = sortedQuestions.map( (s,i) => {
        return {
          ...s,
          order:i,
        }
      })
      addOrderValue.forEach((qu) => {
        testQuestions[qu.id] = qu;
        questionIds.push(qu.id);
        if (!selectedItem) {
          selectedItem = qu.id;
        }
        testAnswers[qu.id] = {
          choices: [],
          answers: [],
          comments: [],
          reviewStarts: [],
          reviewEnds: [],
          questions: [],
        };
      });
    });
    questionAnswer && questionAnswer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if(q.type === typeE.FILL_IN_THE_BLANK){
            a.answers.push(r.answer);
        }
        if (
          (q.type === typeE.FILL_IN_THE_BLANK ||
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE) &&
            r.is_correct_ans
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    });
    this.setState({
      testQuestions,
      testAnswers,
      selectedItem,
      questionIds,
      loading: false,
      // isLoaded: false
    });
    this.props.fromCreateTest && this.props.direction(this.state.direction)
    // this.startTestTimer()
  };
  
  // andleClick = (answerImage) => {
  //   this.setState({ show: true, image: answerImage });
  // };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  mkTestQuestionOverlay = () => {
    const { questionIds, selectedItem, questionSection } = this.state;
    if (selectedItem) {
      let question = this.state.testQuestions[selectedItem];
      const answers = this.state.testAnswers[selectedItem];
      const modifiedAns = answers !== undefined ? answers : {}
      const qSection = question.section_order;
      const currQuestionSection =
        questionSection &&
        questionSection.filter((section) => section.section_order === qSection);
      const currQuestion =
        currQuestionSection &&
        currQuestionSection[0].questions.filter(
          (quest) => quest.id === question.id
        );
      const disabled =
        (currQuestionSection[0].questions_attempted <=
          +currQuestionSection[0].max_questions &&
          currQuestion &&
          currQuestion[0].attempted === 1) ||
        (currQuestionSection[0].questions_attempted <
          +currQuestionSection[0].max_questions &&
          currQuestion &&
          currQuestion[0].attempted === 0)
          ? false
          : true;
      return (
        <div>
          <Card className={`${css.question_card} border-0`}>
            <Card.Body>
              <Question
                question={question}
                Ids={questionIds}
                answers={modifiedAns}
                disabled={disabled}
                onAnswered={this.handleQuestionAnswered}
                onReview={this.handleReview}
                onAttempted={this.handleOnAttempted}
                // hidden={!!this.state.reviewing}
                bgColor="#fff" //{this.state.module.type === 'pdf' ? '#fff' : '#000'}
                // width={this.state.moduleWidth}
                // answerFooter={() => this.renderAnswerFooter()}
              />
            </Card.Body>
          </Card>
          {this.renderAnswerFooter()}
        </div>
      );
    } else return null;
  };

  handleGetQuestionFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };
  onSelectSubmit = () => {
    // this.setState({load: true})
    const count = this.handleCount();
    const msg = [
      count.unanswered && count.unansweredAssignment ? `1.` : "",
      count.unanswered || count.unansweredAssignment || count.flag
        ? ` You have `
        : "",
      (count.unanswered && count.flag) || count.unanswered
        ? `${count.unanswered} unattempted `
        : "",
      count.unanswered === 1 && "question",
      count.unanswered > 1 && "questions",
      count.flag === 0 && count.unanswered ? "." : "",
      count.flag && count.unansweredAssignment === 0 && count.unanswered === 0
        ? ` marked ${count.flag}`
        : "",
      count.flag && count.unansweredAssignment === 0 && count.unanswered !== 0
        ? ` and marked ${count.flag}`
        : "",
      count.flag && count.unansweredAssignment === 0 ? (
        <p>{count.flag > 1 ? "questions" : "question"} for review</p>
      ) : (
        ""
      ),
      count.unansweredAssignment && count.flag === 0 ? (
        <p>
          <br></br>2.You have not uploaded any contents for {count.unansweredAssignment}{" "}
          {count.unansweredAssignment > 1 ? "questions" : "question"}.
        </p>
      ) : (
        ""
      ),
      count.unansweredAssignment && count.flag ? (
        <p>
          <br></br>2.You have not uploaded any contents for {count.unansweredAssignment}{" "}
          {count.unansweredAssignment > 1 ? "questions" : "question"}.<br></br>
          <br></br>3.You have marked {count.flag}{" "}
          {count.flag > 1 ? "questions" : "question"} for review.
        </p>
      ) : (
        ""
      ),
      `Would you like to continue ?`,
    ];

    const submitTest = (allow) => {
      if (allow) {
        this.setState({ updateElapsedTimeOrNot: false, load: true });
        this.handleSaveTest(3, () => {});
      }
    };
    this.props.ctx_window.simpleConfirm(msg, submitTest);
  };
  handleCount = () => {
    const { testQuestions, questionSection } = this.state;
    const objTestQuestion = Object.values(testQuestions);
    const objQuestionSection = Object.values(questionSection);
    const delta = {
      unanswered: 0,
      flag: 0,
      unansweredAssignment: 0,
      answered: 0,
      maxQuestion: 0,
    };
    objQuestionSection.forEach((m0) => {
      const points = util.toInt(m0.max_questions);
      if (m0.max_questions !== 0) {
        delta.maxQuestion = delta.maxQuestion + points;
      }
    });
    objTestQuestion.forEach((s0) => {
      if (s0.attempted === 1) {
        delta.answered = delta.answered + 1;
      }
      if (s0.type === 4 && s0.attempted === 0) {
        delta.unansweredAssignment = delta.unansweredAssignment + 1;
      }
      if (s0.flagged === 1) {
        delta.flag = delta.flag + 1;
      }
      if (s0.attempted === 0) {
        if (delta.answered >= delta.maxQuestion) {
          delta.unanswered = 0;
        } else {
          delta.unanswered = delta.maxQuestion - delta.answered;
        }
      }
    });
    return delta;
  };

  handleSelectedItem = (selectedItemId, type) => {
    // this.setState({
    //       selectedItem: selectedItemId,
    // },this.fetchAnswers(selectedItemId));
  //   if(type !== 3 && type !== 4) {
  //     this.state.testAnswers[selectedItemId].answers.length === 0 ?
  //     this.fetchPreviewAnswers(selectedItemId) : 
  //     this.setState({selectedItem: selectedItemId})
  //   } else {
  //       this.setState({selectedItem: selectedItemId})
  //   }
  // }

  if (type !== 3 && type !== 4) {
    if (this.state.testAnswers[selectedItemId].answers.length === 0) {
      this.fetchPreviewAnswers(selectedItemId);
    } else if (this.state.selectedItem !== selectedItemId) {
      this.setState({ selectedItem: selectedItemId });
    }
  } else if (this.state.selectedItem !== selectedItemId) {
    this.setState({ selectedItem: selectedItemId });
  }
}

  fetchPreviewAnswers = (selectedItemId) => {
    this.props.ctx_api.privateRequest({
      cmd: "get_question_answers",
      args: {
        questionId: selectedItemId.toString(),
      }
    })
    .then(({rsp}) => this.handlePreviewAnswers(rsp,selectedItemId))
    .catch(this.handlePreviewAnswerError)
  }

  handlePreviewAnswers = (rsp, selectedItemId) => {
    // this.setState({ answers: rsp})
    this.mkPreviewAnswers(rsp, selectedItemId)
    // this.handleTestAnswers(this.state.questionSection, rsp);
  }

  mkPreviewAnswers = (answer, selectedItemId) => {
    const { answers, testQuestions } = this.state;
    const testAnswers = {};
    testAnswers[selectedItemId] = {
      choices: [],
      answers: [],
      comments: [],
      reviewStarts: [],
      reviewEnds: [],
      questions: [],
    };

    answer && answer.forEach((r) => {
      const q = testQuestions[r.question_id];
      if (q) {
        const a = testAnswers[r.question_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
        ) {
          a.choices.push({ answerText: r.answer, answerImage: r.image });
        }
        if(q.type === typeE.FILL_IN_THE_BLANK){
            a.answers.push(r.answer);
        }
        if (
          (q.type === typeE.FILL_IN_THE_BLANK ||
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE) &&
            r.is_correct_ans
        ) {
          a.answers.push(r.answer);
        }
        if (q.type === config.testQuestionTypeQBE.MATCH_THE_FOLLOWING) {
          a.answers.push({ answerCode: r.answer_code, answer: r.answer });
          a.questions.push({
            questionCode: r.question_code,
            question: r.question,
          });
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }

    });
 
  
    this.setState((s0) => {
      const updatedTestAnswers = {
        ...s0.testAnswers,
        [selectedItemId]: testAnswers[selectedItemId]
      }
      console.log(updatedTestAnswers)
      return {
        testAnswers: updatedTestAnswers,
        selectedItem: selectedItemId,
        isLoaded: false
      }
    })
  }



  handlePreviewAnswerError = (err) => {
    console.log(err);
  }

  renderQuestionTest = () => {
    const { questionSection, selectedItem, testQuestions, testAnswers } =
      this.state;
    const currQuestion = testQuestions[selectedItem];
    const currSection = questionSection.filter((section) => {
      return section.section_order === currQuestion.section_order;
    });
    if (!testQuestions) {
      return (
        <Row>
          <Col md={12}>
            <Loader width={30} padding="4em" />
          </Col>
        </Row>
      );
    }
    const format = currSection[0]?.content ? currSection[0].content_format : "";

    let MediaPlayer;
    switch (format) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;

      default:
        MediaPlayer = React.Fragment;
        break;
    }
   {this.state.isLoaded && this.handleSelectedItem(selectedItem, currQuestion.type)}
    if (questionSection[0] && questionSection[0].questions.length > 0) {
      return (
        <Row>
          {/* Side */}
          <Col lg={5} xl={5}>
            <Card
              className= {this.props.fromRenderPreview ? css.QueSection : "mb-3 border-0"}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              }}
            >
              <Card.Body className={this.props.testView ? "section-image" : "pb-1"}>
                {/* <div className={css.main}>    */}
                {/* </div> */}
                {/* <div className="test"> */}
                {currSection[0].content || currSection[0].description ? (
                  <div>
                    <h5 className={css.sec_name}>
                      {" "}
                      {currSection[0].questions[0].section_name}{" "}
                    </h5>{" "}
                  </div>
                ) : (
                  <></>
                )}

                <MediaQuery minDeviceWidth={768}>
                  <MediaPlayer
                    className="newPlayer"
                    fromTestPreview
                    url={currSection[0].media_link}
                    pdfUrl={currSection[0].media_link}
                    mediaId="test-preview-section-media"
                    // onReady={this.handleVideoReady}
                    // onError={this.handleVideoError}
                    // noValidateFormId={this.noValidateFormId}
                    // autoPlay={true}
                  />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={767}>
                  <MediaPlayer
                    className="newPlayer"
                    fromTestPreview
                    url={currSection[0].media_link}
                    pdfUrl={currSection[0].media_link}
                    mediaId="test-audio-preview-section-media"
                    // onReady={this.handleVideoReady}
                    // onError={this.handleVideoError}
                    // noValidateFormId={this.noValidateFormId}
                    // autoPlay={true}
                  />
                </MediaQuery>
                {currSection[0].content_format === "3" && (
                  <img
                  src={!this.props.fromCreateTest ? currSection[0].media_link : currSection[0].content}
                  onClick={() =>
                    this.handleImageClick(
                      !this.props.fromCreateTest ? currSection[0].media_link : currSection[0].content
                    )
                  }
                />
                )}

                {currSection[0].description && (
                  <>
                    <MediaQuery minDeviceWidth={768}>
                      <div>
                        <p className={css.para}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: currSection[0].description,
                            }}
                          />
                        </p>
                      </div>
                    </MediaQuery>

                    <MediaQuery maxDeviceWidth={767}>
                      <div>
                        <p className="para">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: currSection[0].description,
                            }}
                          />
                        </p>
                      </div>
                    </MediaQuery>
                  </>
                )}
                {/* </div>  */}
                <MediaQuery minDeviceWidth={768}>
                <Section
                  // section_name={section_name}
                  question={questionSection}
                  selectedItemId={selectedItem}
                  selected={(selectedItemId, type) => {this.handleSelectedItem(selectedItemId, type)}}
                  // selected={(selectedItemId)=> {this.setState({
                  //     selectedItem: selectedItemId,
                  //     // index: 2
                  //   }); 
                    
                  // }}
                  //  title="Section A" numbers={sectionNumbers1}
                />
                </MediaQuery>
                
                <MediaQuery maxDeviceWidth={767} >
                <Row>
                  <Col md={6}>
                  <Section
                  // section_name={section_name}
                  question={questionSection}
                  selectedItemId={selectedItem}
                  selected={(selectedItemId, type) => {this.handleSelectedItem(selectedItemId, type)}}
                  // selected={(selectedItemId) => {
                  //   this.setState({
                  //     selectedItem: selectedItemId,
                  //     // index: 2
                  //   });
                  // }}
                  //  title="Section A" numbers={sectionNumbers1}
                />
                  </Col>
                </Row>
                </MediaQuery>
                
              

                {/* <Section title="Section B" numbers={sectionNumbers2} /> */}
              </Card.Body>
            </Card>
          </Col>

          {/* Question */}
          <Col lg= {7} xl={7}>
            <Card
              className={`${css.question_card} border-0`}
              style={{
                
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              }}
            >
              {selectedItem && this.mkTestQuestionOverlay()}
            </Card>
          </Col>
        </Row>
      );
    } 
    else {
      <></>
    }
  };
  renderLoadingSpinner = () => (
    <Row>
      <Col md={12}>
        <div className="loading-indicator-wrapper">
          <div className="loading-indicator">
            <SpinIcon width={35} />
          </div>
        </div>
      </Col>
    </Row>
  );
  handleArrowAction = (action) => {
    const { selectedItem, questionIds, testQuestions } = this.state;
    const index = questionIds.indexOf(selectedItem);
    const nextId =
      action === "right" ? questionIds[index + 1] : questionIds[index - 1];
      const currQuestion = testQuestions[nextId];
    if (
      !(action === "left" && index === 0) &&
      !(action === "right" && index === questionIds.length - 1)
    ) {
      this.handleSelectedItem(nextId, currQuestion.type)
      // this.setState({ selectedItem: nextId });
    } else {
      return null;
    }
  };
  handleQuestionAnswered = (response) => {
    const { selectedItem } = this.state;
    const typeQBE = config.testQuestionTypeQBE;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].user_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response.map(({ answer }) => answer)
        : response || [];
    testQuestions[selectedItem].match_selection =
      testQuestions[selectedItem].type === typeQBE.MATCH_THE_FOLLOWING
        ? response || []
        : [];
    this.setState({ testQuestions: testQuestions });
  };

  handleOnAttempted = (value) => {
    const { selectedItem, questionSection } = this.state;
    let testQuestions = this.state.testQuestions;
    testQuestions[selectedItem].attempted = value ? 1 : 0;
    const qSection = testQuestions[selectedItem].section_order;

    let newQuestionSection = questionSection.map((section, i) => {
      if (section.section_order === qSection) {
        const currentAttempted = section.questions.filter(
          (quest) => quest.attempted === 1
        ).length;
        return {
          ...section,
          questions_attempted: currentAttempted,
        };
      } else return section;
    });
    this.setState({
      testQuestions: testQuestions,
      questionSection: newQuestionSection,
    });
    this.findTotalAttended(newQuestionSection, "updated");
  };
  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  };

  handleClear = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      // assessmentId: null,
      testQuestions: {},
      testAnswers: {},
    });
  };

  handleExit = () => {
    this.setState({
      status: 0,
      selectedItem: null,
      assessmentId: null,
      testQuestions: {},
      testAnswers: {},
      loading: true,
    });
  };

  renderAnswerFooter = () => {
    {
      const { index, selectedItem, testQuestions, questionIds } = this.state;
      const Disabled = true;
      const selectedIndex = questionIds.indexOf(selectedItem);
      let isFlag = testQuestions[selectedItem].flagged;
      return (
        //index !== 0 &&
        !this.state.testClosed && (
          <Card className={`${css.question_card} border-0`}>
            <Card.Footer
              className={css.footer}
              style={{ justifyContent: "end" }}
            >
              {/* <div> */}
              {/* Want to revisit it later? */}
              {/* <Button onClick={this.onSelectFlag} className="ml-2 mr-0"> */}
              {/* flsg */}
              {/* {!isFlag ? "FLAG" : "UNFLAG"} */}
              {/* </Button> */}
              {/* </div> */}
              {/* <Pagination /> */}
              <div className={css.pagination}>
                <Button
                  onClick={() => this.handleArrowAction("left")}
                  variant="primary"
                  pill
                  wide
                  disabled={selectedIndex === 0}
                >
                  Previous
                </Button>
                <div className={css.pagination_number}>
                  <span>{selectedIndex + 1}</span> of {questionIds.length}
                </div>
                &nbsp;
                <Button
                  disabled={selectedIndex === questionIds.length - 1}
                  onClick={() => this.handleArrowAction("right")}
                  variant="primary"
                  pill
                  wide
                >
                  Next
                </Button>
              </div>
            </Card.Footer>
          </Card>
        )
      );
      // else return null
    }
  };

  msToTime = (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  startTestTimer = () => {
    const binkStyle = ["blink", this.state.alertTime ? "alert" : ""].join(" ");

    const createTestCheckPoints = (duration, interval) => {
      let checkpoints = [];

      checkpoints.push({
        time: 60000,
        callback: () => this.onTimerCheckpoint(duration / 4, 0),
        // callback: () => this.setState({alertTime : true})
      });

      checkpoints.push({
        time: 0,
        callback: () => this.onTimerCheckpoint(0, 1),
      });
      checkpoints.push(
        ...new Array(Math.floor(duration / interval))
          .fill(undefined)
          .map((_, i) => {
            return {
              time: i * interval,
              callback: () => this.updateElapsedTime(interval),
            };
          })
      );

      return checkpoints;
    };

    const { status, duration } = this.state.tests;
    return (
      <Timer
        initialTime={duration}
        // initialTime= {90000}
        direction="backward"
        // checkpoints={createTestCheckPoints(90000 , 30000)}
        checkpoints={createTestCheckPoints(duration, 30000)}
        formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
      >
        {(start, resume, pause, stop, reset) => (
          <React.Fragment>
            <span class={binkStyle}>
              <Timer.Hours />:
              <Timer.Minutes />:
              <Timer.Seconds />
            </span>
          </React.Fragment>
        )}
      </Timer>
    );
    // : (
    //   <span class="glyphicon glyphicon-time">
    //     &nbsp;{this.msToTime(duration)}
    //   </span>
    // );
  };

  // renderModalBody = () => {
  //   return (
  //     <img src={this.state.img} />
  //   )
  // }

  render = () => {
    if (this.state.redirect) {
      return <Redirect push to={config.ROUTES.assessmentsLanding} />;
    }
    return (
      <>
        <Modal
          animation={false}
          show={this.state.modalOpen}
          onHide={this.handleClose}
          scrollable={true}
          // backdrop="static"
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={
              {
                // height: "500px",
                // width: "500px"
              }
            }
          >
            {/* <div className="text-center">{this.renderModalBodyHeader()}</div>  */}
            {<img src={this.state.img} />}
          </Modal.Body>
        </Modal>         
        {this.state.testClosed && this.onTestClosed}
        {!this.state.testClosed && (
          <>
            <div style={{ padding: "0px 20px 24px" }} id = "testID" ref={this.myDivRef}>
              <PageTitle>{this.state.tests?.title}</PageTitle>

              <Row>
                {/* Side */}
                {/* <Col lg={4} xl={3}>
              <Card className="mb-4 border-0">
                <Card.Body className="pb-1">
                  <Section title="Section A" numbers={sectionNumbers1} />
                  <Section title="Section B" numbers={sectionNumbers2} />
                </Card.Body>
              </Card>
            </Col> */}

                {/* Question */}
                <Col lg={12} xl={12}>
                  {this.state.selectedItem !== null ? (
                    <>
                      {/* <Card.Body> */}
                      {/* <Question {...question} /> */}

                      {this.renderQuestionTest()}
                      {/* </Card.Body> */}
                      {/* {this.renderAnswerFooter()}  */}
                    </>
                  ) : (
                    this.renderLoadingSpinner()
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  };
}
export default withWindowContext(
  withLabelsContext(
    withApiContext(
      withRouter(withUserContext(withAnalyticsContext(TestPreview)))
    )
  )
);
