import {
  Breadcrumb,
  Button,
  FilterDropdown,
  JustifyBetween,
  PageTitle,
  SearchField,
  ToggleButton,
  Toolbar,
  OverlayTooltip,
  CardGrid
} from "components";
import FormField from "components/FormField";
import * as config from "config";
import { withLabelsContext, withWindowContext, withUserContext, withAnalyticsContext } from "context";
// import { Steps } from 'intro.js-react';
// import 'intro.js/introjs.css';
import * as messages from "messages";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { ButtonGroup, Row, Col, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Loader from "components/Spinner/Loader";
import * as util from "services/util";
import "./Search.css";
import DataTable from "react-data-table-component";
import getTableStyles from "themes/table-styles";
import {
  IconPencil,
  // IconPlus,
  IconRocket,
  IconTrash,
  IconCircleAdd,
  IconDownload,
  IconExport,
 CircleAddNew,
 IconAiStar,
 IconStar,

} from "Icons";
import FilterDropdownForm from "components/FilterDropdown/FilterDropdownForm";
import MediaQuery from "react-responsive";
import { Redirect, Link } from "react-router-dom";
import { IconRefresh } from "Icons";
import FilterPanel from "components/FilterDropdown/FilterPanel";
import { decryptObject } from "crypto.js";
import CardSurvey from "pages/Survey/PublishSurvey/CardSurvey";
import SurveyPagination from "pages/Survey/PublishSurvey/SurveyPagination";
import Dropdown from 'react-bootstrap/Dropdown';
import TestGeneration from "./TestGeneration";

class EntitySearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortSpec: {},
      fieldState: props.initFieldState,
      searching: false,
      tableRows: [],
      csvData: [],
      modalOpen: false,
      stepsEnabled: false,
      initialStep: 0,
      steps: [],
      isOpenFilter: false,
      currentPage: 1,
      rowsPerPage: 10,
      refreshEnabled: true,
      // viewType: "mine",
      cardsPerPage: 8,
      testgen:false,
    };
    this.csvLink = React.createRef();
    this.instantSearchPending = false;
    this.delayedSearchTimer = null;
  }

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    initFieldState: PropTypes.object.isRequired,
    instantFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsRenderer: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    refresh: PropTypes.bool.isRequired,
    addNewPosition: PropTypes.string,
    tableColumns: PropTypes.array.isRequired,
    // data: PropTypes.object,
    // headers: PropTypes.object,
    fileName: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    menu: PropTypes.array,
  };

  static defaultProps = {
    instantFields: [],
    refresh: false,
    addNewPosition: "search",
    menu: [],
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this.listener);
    this.doSearch();

    if (
      this.props.addRedirection &&
      !this.state.formVisible &&
      this.getPermission("Add")
    ) {
      this.addStepNew();
    } else {
      this.addStep();
    }
    this.renderSearchForm()
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.refresh && !this.props.refresh) {
      this.doSearch();
    }
    if (nextProps.action !== this.props.action) {
      this.resetFields(nextProps);
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.fieldState !== prevState.fieldState) {
      if (
        this.instantSearchPending ||
        this.props.instantFields.some(
          (name) => this.state.fieldState[name] !== prevState.fieldState[name]
        )
      ) {
        this.instantSearchPending = false;
        this.doSearch();
      }
      if (this.state.fieldState.viewType !== prevState.fieldState.viewType) {
        this.doDelayedSearch();
      }
      // else {
      //   this.doDelayedSearch();
      // }
    }
    if (this.state.fieldState === prevState.fieldState) {
      if (this.props.name !== _prevProps.name) {
        this.instantSearchPending = false;
        this.doSearch();
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.listener);
    this.cancelDelayedSearch();
  };

  listener = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      this.doSearch();
    }
  };

  doDelete = (s) => {
    const { keyName } = this.props;
    this.props.ctx_api
      .privateRequestRow({
        cmd: "delete_entity",
        args: {
          entityName: keyName,
          entityId: s.id,
        },
      })
      .then(({ rsp }) => this.handleDeleteEntityResponse(rsp, s.id, s.name));
  };

  handleDeleteEntityResponse = (rsp, id, name) => {
    if (
      this.props.ctx_user.user.type === 2 &&
      this.props.hasTrainerMilestone &&
      rsp.StatusValue === 1
    ) {
      this.updateTrainerMilestones(id, name);
    }
    this.setState({ currentPage: 1 });
    this.doSearch();
  };

  updateTrainerMilestones = (id, name) => {
    const mileStone = {
      event_type: "delete",
      event_subject: this.props.name,
      event_id: +id,
      event_name: name,
      event_date: new Date().toISOString(),
    };
    this.props.ctx_analytics.update("trainerMilestones")(mileStone);
  };

  handleDeleteEntityResponse = (rsp, id, name) => {
    if (
      this.props.ctx_user.user.type === 2 &&
      this.props.hasTrainerMilestone &&
      rsp.StatusValue === 1
    ) {
      this.updateTrainerMilestones(id, name);
    }
    this.setState({ currentPage: 1 });
    this.doSearch();
  };

  handleCardChange = (page) => {
    if (this.props.paginationServer) {
      this.setState({ currentPage: page });
      this.doSearch(page, this.state.cardsPerPage);
    } else return;
  }

  updateTrainerMilestones = (id, name) => {
    const mileStone = {
      event_type: "delete",
      event_subject: this.props.name,
      event_id: +id,
      event_name: name,
      event_date: new Date().toISOString(),
    };
    this.props.ctx_analytics.update("trainerMilestones")(mileStone);
  };

  addStep = () => {
    const searchStep = {
      element: ".intro-search-field",
      intro: "You can use these filters to search your " + this.getNameLabel(),
      title: "Search Field",
    };

    const searchResultTable = {
      element: ".intro-search-results",
      intro: "Your search results show up here",
    };

    this.setState((prevState) => ({
      steps: [...prevState.steps, searchStep, searchResultTable],
    }));
  };

  addStepNew = () => {
    const searchStep = {
      element: ".intro-search-field",
      intro: "You can use these filters to search your " + this.getNameLabel(),
      title: "Search Field",
    };

    const createButton = {
      element: ".intro-create-button",
      intro: "Press this button to create a new module",
    };

    const searchResultTable = {
      element: ".intro-search-results",
      intro: "Your search results show up here",
    };

    this.setState((prevState) => ({
      steps: [...prevState.steps, searchStep, createButton, searchResultTable],
    }));
  };

  getNameLabel = (props = this.props, capWord = true) => {
    const nameLabel = props.ctx_labels.singular[props.name]
      ? props.ctx_labels.singular[props.name]
      : props.name;
    return capWord ? util.capitalizeWord(nameLabel) : nameLabel;
  };

  getPermission = (props) => {
    const { menu, selectedMenuId, action } = this.props;

    const retrievedEncryptedData = localStorage.getItem("selectedMenuId");
    const decryptedMenuId = decryptObject(retrievedEncryptedData);
    const menuId = decryptedMenuId.menuId;

    if (menu.length === 0 && !selectedMenuId) {
      return false;
    }
    const allowedAction =
      action === "add" || action === "switch" || action === "change";
    const menuPermission = selectedMenuId
      ? menu[selectedMenuId].permissions
      : menu[menuId].permissions;
    const permission = menuPermission ? menuPermission[props] : null;
    return !permission && !allowedAction ? false : true;
  };

  resetFields = (props) => {
    // Copy the initial field state to ensure componentDidUpdate() fires
    this.setState({ fieldState: { ...props.initFieldState } });
    this.instantSearchPending = true;
  };

  doSearch = (page, newPerPage, column, sortDirection) => {
    this.cancelDelayedSearch();
    this.setState({ searching: true });
    this.props
      .search({
        ...this.state.fieldState,
        page,
        newPerPage,
        column,
        sortDirection,
      })
      .then(this.handleSearchResponse)
      .catch(this.handleSearchFailure)
      .finally(this.finalizeSearch);
  };

  handleSearchResponse = (tableRows) => {
    this.setState({ tableRows });
  };

  handleSearchFailure = (err) => {
    console.log("err", err)
    this.props.ctx_window.alert(messages.mkEntitySearchErrorAlert());
  };

  finalizeSearch = () => {
    this.setState({ searching: false });
  };

  doDelayedSearch = () => {
    if (this.delayedSearchTimer) {
      clearTimeout(this.delayedSearchTimer);
    }
    this.delayedSearchTimer = setTimeout(
      this.doSearchAfterDelay,
      config.searchFormDebounceMillis
    );
  };

  cancelDelayedSearch = () => {
    if (this.delayedSearchTimer) {
      clearTimeout(this.delayedSearchTimer);
      this.delayedSearchTimer = null;
    }
  };

  doSearchAfterDelay = () => {
    if (this.delayedSearchTimer) {
      this.delayedSearchTimer = null;
      this.doSearch();
    }
  };

  getCSVData = (e) => {
    e.stopPropagation();
    this.props
      .search({ ...this.state.fieldState, isExport: -1 })
      .then((data) => {
        this.setState({ csvData: data });
      })
      .then(() => {
        this.csvLink.current.link.click();
      })
      .catch((err) => console.log(err));
  };

  handleFieldChange = ({ name, value }) => {
    this.setState((s0) => {
      const fieldState = {
        ...s0.fieldState,
        [name]: value,
      };
      return { fieldState };
    });
  };

  handleSearchButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.doSearch();
  };

  handleSortSpecChange = (sortSpec) => {
    this.setState({ sortSpec });
  };

  handleIntroClick = () => {
    this.setState({ stepsEnabled: true });
  };

  handleToggleChange = (e) => {
    e.persist();
    this.setState((s0) => {
      return {
        fieldState: {
          ...s0.fieldState,
          viewType: e.target.value,
        },
      };
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    if (this.props.paginationServer) {
      this.setState({ rowsPerPage: newPerPage });
      this.doSearch(page, newPerPage);
    } else return;
  };

  handlePageChange = (page) => {
    if (this.props.paginationServer) {
      this.setState({ currentPage: page });
      this.doSearch(page, this.state.rowsPerPage);
    } else return;
  };

  handleSort = (col, sortDirection) => {
    if (this.props.sortServer) {
      const column = col.key;
      const { currentPage, rowsPerPage } = this.state;
      this.doSearch(currentPage, rowsPerPage, column, sortDirection);
    } else return;
  };

  renderItemList = () => {
    this.setState({testgen:true});
  }

  handleClick = () => {
    // this.props.passSelect(true);
    const { isCourse, addRedirection, onSelect, isModalRedirection, modalOpen,isTest } = this.props;
      
    if(isCourse) {
      onSelect("Modal");
      modalOpen(true)
    }
    else if(isTest) {
      onSelect("Modal");
      modalOpen(true)
    }
    else {
      addRedirection();
    }
   
  }
  

  renderAddNewButton = () => {
 
    if (
      this.props.addRedirection || this.props.isModalRedirection &&
      !this.state.formVisible &&
      this.getPermission("Add")
    ) {
      const label = this.getNameLabel();
      return (
        // <OverlayTooltip
        //   tip={`${this.props.ctx_labels.singular.createNew} ${label}`}
        //   id="create"
        //   placement="left"
        // >
        this.props.fromPubTest  || this.props.fromCoursePub?
        
        <div></div> : <Button
        style={{ minWidth: "max-content"}}
        className="split-btn"
      >
        <OverlayTooltip
      tip={`${this.props.ctx_labels.singular.createNew} ${label}`}
      id="create"
      placement="left"
      >
        <div id="create"
        onClick={this.handleClick}
        disabled={this.props.location.fromClassViewer}>
        <CircleAddNew className="circlenew" />
        {label}
        </div></OverlayTooltip>
        {/* <div className="verti-sep"></div> */}
        {this.props.ctx_user.user.enableAiTestGeneration &&<div>
        {/* { this.props.testgeneration &&
        <OverlayTooltip
       tip={`${this.props.ctx_labels.singular.creatAiTest}`}
        id="create"
        placement="top"
        >
        <div>
        <Dropdown className="drpdwn">
          <Dropdown.Toggle className="drpdwn"></Dropdown.Toggle>
          <Dropdown.Menu className="drpdwn-menu">
            <Dropdown.Item className="drpdwn-menu-item" onClick={this.renderItemList}><IconStar/>Create Using AI</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div></OverlayTooltip>
        } */}
        </div>}
      </Button>
        // </OverlayTooltip>
        // <div className="entity-head-right">
        //   <Button
        //     className="entity-add-new-button add-btn p-large intro-create-button"
        //     bsStyle="primary"
        //     disabled={false}
        //     onClick={this.props.addRedirection}
        //   >
        //     <MediaQuery maxDeviceWidth={576}>
        //       hell
        //       {/* <Glyphicon glyph="plus" /> */}
        //     </MediaQuery>
        //     <MediaQuery minDeviceWidth={577}>
        //       {`Create ${this.getNameLabel()}`}
        //     </MediaQuery>
        //   </Button>
        // </div>
      );
    } else {
      return null;
    }
  };

  renderHeader = () => {
    return (
      <div>
        {/* <div className="entity-search-header">
          {`Select ${this.getNameLabel()} to ${this.props.action}`}
        </div> */}
      </div>
    );
  };

  renderSearchField = (props) => (
    <FormField
      {...props}
      value={this.state.fieldState[props.name]}
      onChange={this.handleFieldChange}
    />
  );

  renderSearchButton = () => {
    const disabled = this.state.searching;
    return (
      <Button
        className="entity-search-form-search-button e p-large"
        size="sm"
        bsStyle="primary"
        disabled={disabled}
        onClick={this.handleSearchButtonClick}
      >
        {this.props.ctx_labels.singular.search}
        {/* <Glyphicon glyph="search" /> */}
      </Button>
    );
  };

  renderSearchForm = () => {
    const { menu, selectedMenuId } = this.props;
    // const parentMenuName = this.getNameLabel({
    //   name: menu[menu[selectedMenuId].parent_id].menu_name,
    //   ctx_labels: this.props.ctx_labels,
    // });
    const retrievedEncryptedData = localStorage.getItem("selectedMenuId");
    const decryptedMenuId = decryptObject(retrievedEncryptedData);
    console.log('Decrypted Data:', decryptedMenuId );
    const menuId = decryptedMenuId.menuId;
    const newMenuId = selectedMenuId ? selectedMenuId : menuId
    const parentMenuName =
      menu[newMenuId] &&
      menu[newMenuId].parent_id &&
      util.capitalizeWord(
        this.getNameLabel({
          name: menu[menu[newMenuId].parent_id].menu_name,
          ctx_labels: this.props.ctx_labels,
        })
      );
    return (
      <React.Fragment>
        <MediaQuery maxDeviceWidth={1023}>
          <div className="menu-button">
            {this.props.addNewPosition === "search" &&
              this.renderAddNewButton()}
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1024}>
          <JustifyBetween>
            {this.props.location && this.props.location.state && (this.props.location.state.adminHome == true) ? (
              <Breadcrumb
                list={[
                  {
                    title: util.capitalizeWord(this.props.ctx_labels.singular.user),
                  },
                  {
                    title: util.capitalizeWord(this.props.ctx_labels.singular.student),
                    current: true,
                  },
                ]}
              />
            ) : (
              (!this.props.hideBreadcrumb && (
                <Breadcrumb
                  list={[
                    { title: parentMenuName, current: true },
                    { title: this.getNameLabel(), current: true },
                  ]}
                />
              ))
            )}
            {this.props.customBreadcrumb ? this.props.customBreadcrumb : null}
            {this.props.addNewPosition === "search" &&
              this.renderAddNewButton()}
          </JustifyBetween>
        </MediaQuery>
        <JustifyBetween breakpoint="md">
          <PageTitle>{this.getNameLabel()}</PageTitle>
          {/* Toolbar */}
          <Toolbar>
            <MediaQuery maxDeviceWidth={1023}>
              <Toolbar.Item>
                {this.props.showToggle && (
                  <div className="toggle-container">
                    <ButtonGroup>
                      <ToggleButton
                        type="radio"
                        name="radio"
                        value="mine"
                        checked={this.state.fieldState.viewType === "mine"}
                        onChange={this.handleToggleChange}
                      >
                        {this.props.ctx_labels.singular.my}
                      </ToggleButton>
                      <div className="mb-2"></div>
                      <ToggleButton
                        type="radio"
                        name="radio"
                        value="all"
                        checked={this.state.fieldState.viewType === "all"}
                        onChange={this.handleToggleChange}
                      >
                        {this.props.ctx_labels.singular.all}
                      </ToggleButton>
                    </ButtonGroup>
                  </div>
                )}
              </Toolbar.Item>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1023}>
              <Toolbar.Item>
                {this.props.showToggle && (
                  <ButtonGroup>
                    <ToggleButton
                      type="radio"
                      name="radio"
                      value="mine"
                      checked={this.state.fieldState.viewType === "mine"}
                      onChange={this.handleToggleChange}
                    >
                      {this.props.ctx_labels.singular.my}
                    </ToggleButton>
                    <div className="mb-2"></div>
                    <ToggleButton
                      type="radio"
                      name="radio"
                      value="all"
                      checked={this.state.fieldState.viewType === "all"}
                      onChange={this.handleToggleChange}
                    >
                      {this.props.ctx_labels.singular.all}
                    </ToggleButton>
                  </ButtonGroup>
                )}
              </Toolbar.Item>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1023}>
              <Toolbar.Item>
                <SearchField
                  id="searchField"
                  style={{ marginTop: "10px" }}
                  onSearchClick={() => this.doSearch()}
                  placeholder={this.props.ctx_labels.singular.searchByName}
                  onChange={(e) => {
                    e.persist();
                    this.setState((s0) => {
                      return {
                        fieldState: { ...s0.fieldState, name: e.target.value },
                      };
                    });
                  }}
                />
              </Toolbar.Item>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1024}>
              <Toolbar.Item>
                <SearchField
                  id="searchField"
                  onSearchClick={() => this.doSearch()}
                  placeholder={this.props.ctx_labels.singular.searchByName}
                  onChange={(e) => {
                    e.persist();
                    this.setState((s0) => {
                      return {
                        fieldState: { ...s0.fieldState, name: e.target.value },
                      };
                    });
                  }}
                />
              </Toolbar.Item>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1023}>
              <Toolbar.Item className="text-right">
                <FilterDropdown
                  style={{ marginTop: "10px" }}
                  isOpen={this.state.isOpenFilter}
                  onClick={() =>
                    this.setState({ isOpenFilter: !this.state.isOpenFilter })
                  }
                />
              </Toolbar.Item>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1024}>
              <Toolbar.Item className="text-right">
                <FilterDropdown
                  isOpen={this.state.isOpenFilter}
                  onClick={() =>
                    this.setState({ isOpenFilter: !this.state.isOpenFilter })
                  }
                />
              </Toolbar.Item>
            </MediaQuery>
          </Toolbar>
        </JustifyBetween>

        {this.state.isOpenFilter && (
          <FilterPanel>
            <FilterDropdownForm className="entity-search-form">
              <Row>
                <Col md={10}>
                  {this.props.fieldsRenderer({
                    s: this.state.fieldState,
                    onChange: this.handleFieldChange,
                    SearchField: this.renderSearchField,
                    SearchButton: this.renderSearchButton,
                  })}
                </Col>
                <Col md={2} className="apply-button-col">
                  <div className="mt-4">
                    <Button
                      id="applyFilter"
                      onClick={() => this.doSearch()}
                      pill
                      wide
                    >
                      <MediaQuery minDeviceWidth={1025}>
                        {this.props.ctx_labels.singular.applyFilters}
                      </MediaQuery>
                      <MediaQuery maxDeviceWidth={1024}>
                        {this.props.ctx_labels.singular.apply}
                      </MediaQuery>
                    </Button>
                  </div>
                </Col>
              </Row>
            </FilterDropdownForm>
          </FilterPanel>
        )}
      </React.Fragment>
      // <div className="entity-page-search-wrapper">
      //   <div className="entity-page-search-fields">
      //     <form className="entity-search-form intro-search-field">
      //       {this.props.fieldsRenderer({
      //         s: this.state.fieldState,
      //         onChange: this.handleFieldChange,
      //         SearchField: this.renderSearchField,
      //         SearchButton: this.renderSearchButton,
      //       })}
      //     </form>
      //   </div>
      //   <div className="entity-page-search-right">
      //     <MediaQuery minDeviceWidth={577}>
      //       {this.props.addNewPosition === "search" && this.renderAddNewButton()}
      //     </MediaQuery>
      //   </div>
      // </div>
    );
  };

  handleRowDelete = (row) => {
    const allowDelete = (allow) => {
      if (allow) {
        this.doDelete(row);
      }
    };
    this.props.ctx_window.simpleConfirm(
      `This action will delete ${row.name}. Do you want to continue ?`,
      allowDelete
    );
  };
  handleRowSelect = (e) => {
    const row = e;
   
    if (this.getPermission("View")) {
      if (row.delete) {
        delete row.delete;
      }
      if (row.goto) {
        delete row.goto;
      }
      this.props.onSelect(row);
    }
    // else {
    //   () => { }
    // }
  };

  handleRedirection = (row) => {
    console.log(row);
    const entry = row;
    if (row.delete) {
      delete entry.delete;
    }
    if (row.goto) {
      delete entry.goto;
    }
    // if (row.id) {
    //   entry.id = "0";
    // }
    this.getPermission("Launch") && this.props.alternateRedirection(entry);
  };

  handleModalClose = () => {
    this.setState({testgen:false})
  }
  renderModalHeader = () => {
    return (
      <div>
        <span className="page-title"><IconAiStar style={{height:'20px',width:'20px'}}/>  AI Powered Test Creation</span>
      </div>
    );    
    // return 
    // <span >{text}</span>;
  };
  

  handleRefresh = (e) => {
    // e.stopPropagation();
    this.setState({ refreshEnabled: false });
    const coursePubType = this.props.ctx_user.user.coursePubType;
    const newCourseList = e.course_list.map((c) => {
      return c.value
    });
    let args = coursePubType
      ? { coursePubId: e.id, courseId: e.courseId, isMultiCourse: e.is_multiCourse, courseIds: newCourseList }
      : { coursePubId: e.id, courseId: e.courseId, isMultiCourse: e.is_multiCourse, courseIds: newCourseList, action: "refresh" };

    const cmd = coursePubType
      ? "refresh_published_course"
      : "insert_course_publish_queue";
    this.props.ctx_api
      .privateRequestRow({
        cmd: cmd,
        args: args,
      })
      .then(({ rsp }) => {
        if (rsp.statusvalue === 1) {
          this.props.ctx_window.alert(
            `${this.props.ctx_labels.singular.coursePublish} ${this.props.ctx_labels.singular.updateSuccessfully}`
          );
          this.setState({ refreshEnabled: true });
        } else {
          this.renderError();
        }
      })
      .catch(this.renderError);
  };

  renderSearchResults = () => {
    const columnData = this.props.tableColumns.map((col) => {
      return { ...col, name: util.capitalizeWord(col.name) };
    });
    const columns =
      this.getPermission("Launch") ||
      this.getPermission("View") ||
      this.getPermission("Delete")
        ? [
            ...columnData,
            {
              name: this.props.ctx_labels.singular.action,
              selector: row => row.actions,
              sortable: false,
              center: true,
              format: (e) => renderActions(e),
              width: "200px",
            },
          ]
        : columnData;
    // const {}
    // if (this.state.tableRows === null || this.state.searching) {
    //   return (
    //     <div className="loading-indicator-wrapper">
    //       <div className="loading-indicator">
    //         <Loader active />
    //       </div>
    //     </div>
    //   );
    // }
    const matchCount = this.props.totalRows || this.state.tableRows.length;
    const matchStrPlural = this.props.ctx_labels.singular.noMatchesFound;
    const matchStrSingular = this.props.ctx_labels.singular.matchesFound;
    // `${matchCount || "No"} match${
    //   matchCount === 1 ? "" : "es"
    // }`;
    const matchStr =
      matchCount !== 0
        ? `${matchCount} ${matchStrSingular}`
        : `${matchStrPlural}`;
    const getCSVheaders = () => {
      return this.props.tableColumns.map((field) => {
        return {
          label: field.name.toUpperCase(),
          key: field.key,
        };
      });
    };

    // const deleteCol = () => {
    //   if (this.getPermission("Delete")) {
    //     return {
    //       delete: {
    //         name: "delete",
    //         label: null,
    //         alignment: "right",
    //       },
    //     };
    //   } else {
    //     return null;
    //   }
    // };

    // const deleteRow = (s) => {
    //   if (this.getPermission("Delete")) {
    //     return {
    //       delete: (
    //         <button
    //           type="button"
    //           class={`btn btn-danger${
    //             s.deletable === 0 ? " disabled " : " "
    //           }btn-xs`}
    //         >
    //           <span class="" name="delete"></span>
    //         </button>
    //       ),
    //     };
    //   } else {
    //     return null;
    //   }
    // };
    // const alternateCol = () => {
    //   if (this.props.alternateRedirection && this.getPermission("Launch")) {
    //     return {
    //       goto: {
    //         name: "goto",
    //         label: `${this.props.addButtonLabel}`,
    //         alignment: "center",
    //       },
    //     };
    //   } else {
    //     return null;
    //   }
    // };

    const alternateRow = () => {
      if (this.props.alternateRedirection && this.getPermission("Launch")) {
        return {
          goto: (
            <button type="button" class={`btn btn-primary btn-xs`}>
              <span class="" name="goto"></span>
            </button>
          ),
        };
      } else {
        return null;
      }
    };

    const renderActions = (e) => (
      <div className="">
        {this.getPermission("View") && (
          <OverlayTooltip
            tip={`${this.props.ctx_labels.singular.edit} ${util.capitalizeWord(
              this.props.name
            )}`}
            tipId={e.id}
            placement="top"
          >
            <Button
              onClick={() => this.handleRowSelect(e)}
              iconOnly
              variant="white"
              id="edit"
              size="sm"
              className="mr-1 ml-0"
            >
              <IconPencil />
            </Button>
          </OverlayTooltip>
        )}
        {this.getPermission("Delete") && (
          <OverlayTooltip
            tip={`${this.props.ctx_labels.singular.delete} ${this.props.name}`}
            tipId={e.id}
            placement="top"
          >
            <Button
              iconOnly
              variant="white"
              size="sm"
              id="delete"
              className="mr-1 ml-0"
              onClick={() => this.handleRowDelete(e)}
              disabled={e.can_delete === 0}
            >
              <IconTrash />
            </Button>
          </OverlayTooltip>
        )}

        {this.props.alternateRedirection && this.getPermission("Launch") && (
          <OverlayTooltip
            tip={
              this.props.customAlternateTip ??
              `${this.props.ctx_labels.singular.publish} ${this.props.name}`
            }
            tipId={e.id}
            placement="top"
          >
            <Link
              to={{ pathname: this.props.redirectionRoute, alternateEntry: e }}
            >
              <Button
                iconOnly
                variant="white"
                size="sm"
                className="mr-1 ml-0"
                id="publish"
                onClick={() => this.handleRedirection(e)}
              >
                <IconRocket />
              </Button>
            </Link>
          </OverlayTooltip>
        )}
        {this.props.name == "coursePublish" &&
          this.props.ctx_user.user.coursePublishRefresh && (
            <OverlayTooltip
              tip={
                this.props.customAlternateTip ??
                `${
                  this.props.ctx_labels.singular.refresh
                } ${util.capitalizeWord(this.props.name)}`
              }
              tipId={e.id}
              placement="top"
            >
              <Button
                // tip={addTip}
                variant="white"
                iconOnly
                size="sm"
                className="mr-1 ml-0"
                onClick={() => this.handleRefresh(e)}
                disabled={e.canRefresh == 0 || !this.state.refreshEnabled}
              >
                {" "}
                <IconRefresh />
              </Button>
            </OverlayTooltip>
          )}
      </div>
    );

    // const setTableColumn = () => {
    //   const { tableColumns } = this.props;
    //   const delC = deleteCol();
    //   const altC = alternateCol();
    //   const item = {
    //     ...tableColumns,
    //     ...altC,
    //     ...delC,
    //   };
    //   return item;
    // };
    // const setTableRow = () => {
    //   const { tableRows } = this.state;
    //   const item = [];
    //   tableRows.forEach((s) => {
    //     const delR = deleteRow(s);
    //     const altR = alternateRow();
    //     item.push({
    //       ...s,
    //       ...delR,
    //       ...altR,
    //     });
    //   });
    //   return item;
    // };
    const tableStyles = getTableStyles(this.props.theme);
    const pointerOnHover =
      this.getPermission("Launch") ||
      this.getPermission("View") ||
      this.getPermission("Delete");
    return (
      <React.Fragment>
        {/* <div className="entity-search-results-header"> */}
        <JustifyBetween>
          <div className="mb-2">{`${matchStr} `}</div>
          <div style={{ display: "inline-flex" }}>
            {this.props.headerRenderer && (
              <div className="mr-2">{this.props.headerRenderer()}</div>
            )}
            {this.getPermission("Export") && (
              <OverlayTooltip
                tip="Download as CSV"
                id="download-as-csv"
                placement="top"
              >
                <>
                  <Button
                    variant="link"
                    className="p-0 mb-2"
                    onClick={this.getCSVData}
                  >
                    {this.props.ctx_labels.singular.export}
                    <IconExport className="mr-0 ml-1" />
                  </Button>
                  <CSVLink
                    headers={getCSVheaders()}
                    filename={this.props.name + ".csv"}
                    data={this.state.csvData}
                    ref={this.csvLink}
                  />
                </>
              </OverlayTooltip>
            )}
          </div>
        </JustifyBetween>

        <div className="entity-search-results-right">
          {/* <OverlayTooltip
              tip="Download as CSV"
              id="download-as-csv"
              placement="top"
            > */}
          {/* <CSVLink
                headers={getCSVheaders()}
                filename={this.props.name + ".csv"}
                data={this.state.tableRows}
                className="btn btn-primary pull-right"
              >
                <Glyphicon glyph="download-alt" />
              </CSVLink>
            </OverlayTooltip> */}
        </div>
        {/* )} */}
        {/* </div> */}
        {/* <div className="mb-4"></div> */}
        {this.props.resultType === undefined 
        && this.props.name !== "publishSurvey"
         ? (
          <DataTable
            progressPending={this.state.searching}
            onRowClicked={(rowData) => this.handleRowSelect(rowData)}
            pointerOnHover={pointerOnHover}
            defaultSortField={"name"}
            noHeader={true}
            columns={columns}
            data={this.state.tableRows}
            customStyles={tableStyles}
            pagination
            paginationServer={this.props.paginationServer || false}
            onChangeRowsPerPage={this.handlePerRowsChange}
            onChangePage={this.handlePageChange}
            paginationDefaultPage={this.state.currentPage}
            paginationTotalRows={this.props.totalRows}
            paginationPerPage={this.state.rowsPerPage}
            progressComponent={<Loader active className="entity-loader" />}
            sortServer={this.props.sortServer || false}
            onSort={this.handleSort}
            persistTableHead
            striped
          />
        )
         :  this.props.name == "publishSurvey"?
        <div>
        <CardGrid>
          {this.state.tableRows.map((itm,i) => {
            return(
              <CardGrid.Item>
              <CardSurvey key={i}
              {...itm}
              onCardClicked={(cardData) => this.handleRowSelect(cardData)}
              onCardViewClicked={(e) => this.handleRedirection(e)}
              onCardDelete={(cardData) => this.handleRowDelete(cardData)}
              data={this.state.tableRows}
              itm={itm}
              redirectionRoute={this.props.redirectionRoute}
              renderActions={this.renderActions}
          />
        </CardGrid.Item>
            )
        })}
        </CardGrid>
        {this.state.tableRows &&
        <SurveyPagination
        pageIndex={this.state.currentPage}
        paginationDefaultPage={this.state.currentPage}
        totalCount={this.props.totalRows}
        onChangePage={this.handleCardChange}
        pageSize={this.state.cardsPerPage}
        onChange={() => this.handlechange}
        />
        }
      </div>
       : 
       null}
      </React.Fragment>
    );
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  render = () => {
    const className = [this.props.className, this.props.name].join(" ");
    // const { stepsEnabled, steps, initialStep } = this.state;
    if (this.props.menu.length === 0) {
      return <Redirect to="/" />;
    }
    return (
      <div className={className} style={this.props.style}>
         <Modal
        animation={false}
        size="xl"
        aria-labelledby="contained-modal-title-lg"
        show={this.state.testgen}
        // onHide={this.handleHideModal}
        dialogClassName="ai-modal-container"
        scrollable={true}
      >
         <Modal.Header >
            {/* {this.renderModalHeader()} */}
          </Modal.Header>
        <Modal.Body>
          {/* <TestGeneration modalClose={this.handleModalClose}/> */}
        </Modal.Body>
       </Modal>
        {/* <Steps
            enabled={this.props.ctx_user.user.highlightIntro ? stepsEnabled : false}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          /> */}
        {/* {this.props.ctx_user.user.highlightIntro &&
            <div style={{ margin: '5px', position: 'absolute', top: '0', right: '0' }}>
              <OverlayTooltip
                tip="Help"
                id="help"
                placement="top"
              >
                <Glyphicon className='btn glyphicon-info-sign'
                  // bsStyle="primary pull-right"
                  onClick={this.handleIntroClick} />
              </OverlayTooltip>
            </div>
          } */}
        {this.renderSearchForm()}
        {this.renderSearchResults()}
      </div>
    );
  };
}

export default withWindowContext(
  withLabelsContext(withUserContext(withAnalyticsContext(EntitySearch)))
);
