import { Select } from "components";
import CoursePublishSelect from 'components/Select/CoursePublishSelect';
import { withApiContext, withLabelsContext, withUserContext } from "context";
import React, { PureComponent } from "react";
import EntitySearch from "../Entity/Search";
import * as util from "services/util";
import { Col, Row } from "react-bootstrap";

// import "./Search.css";

class SurveyListSearch extends PureComponent {
  constructor(props) {
   
    super(props);
    this.initFieldState = {
      categoryId: "",
      curriculumId: "",
      coursePublishId: "",
      courseId:"",
      name: "",
      shortName: "",
      currName: "",
      startDate: "",
      status: "",
      score: "",
      standard: "",
      viewType: "mine",
      showCoursePub: "",
    };

    if (props.categoryId) {
      this.initFieldState.categoryId = props.categoryId;
    }
    if (props.curriculumId) {
      this.initFieldState.curriculumId = props.curriculumId;
    }
    if (props.coursePublishId) {
      this.initFieldState.coursePublishId =
        props.fromClassViewer === 1 ? props.coursePublishId : "";
    }
    if (props.courseId) {
      this.initFieldState.courseId =
        props.fromClassViewer === 1 ? props.courseId : "";
    }
    if (props.location.data) {
      this.initFieldState.categoryId = props.location.data.course_category_id || "";
      this.initFieldState.curriculumId = props.location.data.master_curriculum_id || "";
      this.initFieldState.coursePublishId = props.location.data.course_publish_id || "";
      this.initFieldState.courseId = props.location.data.course_id || "";
    }

    this.tableColumns = this.mkTableColumns(props);
  }

  isTeacher = (props = this.props) => props.ctx_user.user.type === 2;

  search = async (s) => {
    const viewable = s.viewType === "all" ? 0 : 1;
       const args = {
      viewable: viewable,
      showCoursePub:(this.props.location.data || s.coursePublishId) ? 1 : 0
         
    };
    if (s.categoryId) {
      args.categoryId = s.categoryId.toString();
    }
    if (s.curriculumId) {
      args.curriculumId = s.curriculumId;
    }
    if (s.coursePublishId) {
      args.coursePublishId = s.coursePublishId;
    }
    if(s.courseId) {
      args.courseId = s.courseId;
    }

    if (s.name) {
      args.name = s.name;
    }
  
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "get_trainer_surveys",
      args: args,
    });

    return rsp.map((r) => ({
      id: r.id,
      name: r.name,
      shortName: r.short_name,
      currName: r.currname,
      startDate: util.toDateString(new Date(r.start_date)),
      endDate: util.toDateString(new Date(r.due_date)),
      status: r.status,
      type: r.type,
      compPercentage: parseInt(r.completion_percentage, 10),
      standard: r.standard,
      fromDate: r.from_date,
      toDate: r.to_date,
      tot_questions: r.tot_questions,
      tot_marks:r.tot_marks,
      attended:r.attended,
      coursePublishId: this.props.location.data ? this.props.location.data.course_publish_id : null,
      courseId: this.props.location.data ? this.props.location.data.courseId : null,
      fromClassView: this.props.location.data ? 1 : 0,
     // __sortName: r.name.toLowerCase(),
      __sortStartDate: new Date(r.start_date),
      __sortEndDate: new Date(r.due_date),
      can_delete: r.can_delete
    }));
  };

  mkTableColumns = (props) => [
    {
      name: this.props.ctx_labels.singular.test,
      selector: row =>row.name,
      key: "name",
      sortable: true,
    },
    {
      name: this.props.ctx_labels.singular.curriculum,
      selector: row =>row.currName,
      key: "currName",
      sortable: true,
    },
    {
      name: this.props.ctx_labels.singular.category,
      selector: row =>row.standard,
      key: "standard",
      sortable: true,
    },
    {
      name: this.props.ctx_labels.singular.startDate,
      selector: row =>row.startDate,
      key: "startDate",
      sortable: true,
      sortFunction: (a, b) => {
        return util.searchSorting(a.startDate,b.startDate);
      },

    },
    {
      name: this.props.ctx_labels.singular.endDate,
      selector: row =>row.endDate,
      key: "endDate",
      sortable: true,
      sortFunction: (a, b) => {
        return util.searchSorting(a.endDate,b.endDate);
      },
    },
    {
      name: this.props.ctx_labels.singular.type,
      selector: row =>row.type,
      key: "type",
      sortable: true,
    },
    {
      name: this.props.ctx_labels.singular.status,
      selector: row =>row.status,
      key: "status",
      sortable: true,
    },
    {
      name: this.props.ctx_labels.singular.complperc,
      selector: row =>row.compPercentage,
      key: "compPercentage",
      sortable: true,
    },
  ];

  renderNameWithLogo = (row) => (
    <div className="organization-search-results-logo-name">
      <div className="organization-search-results-logo">
        {row.logo && <img src={`data:image/png;base64,${row.logo}`} alt="" />}
      </div>
      <div className="organization-search-results-name">{row.name}</div>
    </div>
  );

  renderFields = ({ SearchField, SearchButton }) => {
    return (
      <div className="search-wrapper">
         <Row>
         <Col md={3}>
        <SearchField
          className="test-search-form-name"
          name="name"
          label={this.props.ctx_labels.singular.name}
        />
        </Col>
        <Col md={3}>
        <SearchField
          className="test-search-form-standard"
          type={Select}
          options={this.props.ctx_user.user.campusCategories}
          placeholder={`${this.props.ctx_labels.singular.select} ${util.capitalizeWord(
            this.props.ctx_labels.singular.standard
          )}`}
          name="categoryId"
          label={this.props.ctx_labels.singular.category}
        />
        </Col>
        <Col md={3}>
        <SearchField
          className="test-search-form-curriculum"
          type={Select}
          options={this.props.ctx_user.user.campusSubj}
          placeholder={`${this.props.ctx_labels.singular.select} ${util.capitalizeWord(
            this.props.ctx_labels.singular.curriculum
          )}`}
          name="curriculumId"
          label={this.props.ctx_labels.singular.curriculum}
        />
        </Col>
        <Col md={3}>  
        <SearchField
          className="test-search-form-curriculum"
          type={CoursePublishSelect}
          name="coursePublishId"
          label={this.props.ctx_labels.singular.coursePublish}
           />
        </Col>

        {/* <SearchButton /> */}
      </Row>
      </div>
    );
  };
  
  render = () => (
    <EntitySearch
      {...this.props}
      initFieldState={this.initFieldState}
      fieldsRenderer={this.renderFields}
      search={this.search}
      tableColumns={this.tableColumns}
      // addRedirection={false}
      showToggle={this.isTeacher}
    />
  );
}

export default withApiContext(
  withLabelsContext(withUserContext(SurveyListSearch))
);
