import TestPreview from 'pages/Test/Components/CourseTestPreview';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from "styled-components";
import { rgba } from "polished";
import { Button } from 'react-bootstrap';
import {
  withApiContext,
} from "context";


const StyledPlayerMessage = styled.div`
  && {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
  }
`;

class PreviewTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      playerTestModule : [],
      openTestPreview: false,
    }
  }
  componentWillUnmount = () => {
    this.props.handleOpenTestPreview(true)
  }
  handleTestPreview = async(module)=> {
    await this.props.ctx_api
       .privateRequest({
         cmd: "get_publish_test_preview",
         args: {testId: this.props.module.contentId, isPublishTest : this.props.fromCourse && 0 },
       })
       .then(this.handleTestListResponse)
       .catch(this.handleTestListFailure);
   };
   handleTestListResponse = ({ rsp }) => {
     this.setState({ playerTestModule :  rsp , openTestPreview: true})
     this.props.handleOpenTestPreview && this.props.handleOpenTestPreview(false)
    this.renderPlayerScreen();
   };

   renderPlayerScreen = () => {
    
    return (
      <div>
        <TestPreview
          preview={this.state.playerTestModule}
          handleTestHeight={this.props.handleTestHeight??""}
          fromRenderPreview
        />
      </div>
    );
    
    }

render = () =>  {
  const screenSize = window.screen.width
  return (
    <>
    {this.state.openTestPreview == false ?
        <StyledPlayerMessage 
          className="player-module-message player-Preview-test" 
          style={screenSize < 821 ? { marginTop: "20px" } : {}}
        >
    <div style={{fontSize:"18px",padding:"20px"}}>Click
           <a onClick={this.handleTestPreview} style={{color:"blue",cursor: "pointer"}}> here</a>&nbsp;
            to view the preview of the test</div>
 </StyledPlayerMessage> :
    <StyledPlayerMessage  
      style={screenSize > 821 ? {borderRadius : "10px"} : {borderRadius : "10px",marginTop:"20px"}} 
    >
    <div className="player-no-module-wrapper">
    <div style={{fontSize:"18px",padding:"20px"}}>
    
     </div>
    {this.renderPlayerScreen()}
    </div>
</StyledPlayerMessage>
    }
       


    </>
      
  );
};
}


export default withApiContext(PreviewTest);
