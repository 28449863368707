import {
  Breadcrumb,
  Button,
  JustifyBetween,
  PageTitle,
  ResponsiveTabs,
} from "components";
import { withApiContext, withLabelsContext, withUserContext } from "context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Card, Col, Row } from "react-bootstrap";
import TrainerOverview from "./TrainerOverview/TrainerOverview";
import StudentTestResults from "./EmployeeWiseResults/EmployeeWiseResults";
//import EvaluateForm from "./EvaluateForm";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";
import * as util from "services/util";
import * as config from "config";
import QuestionnaireChart from "./QuestionAnswerStats/QuestionAnswerStats";
import ShortQuestionnaire from "./QuestionAnswerStats/ShortQuestionAnswerStats";
import "./QuestionAnswerStats/QuestionAnswerStats.css";
import MediaQuery from 'react-responsive';
import RatingAndReviews from "./QuestionAnswerStats/StarQuestionAnswerStats";
import Multistatics from "./QuestionAnswerStats/Multistatics";
import "./Form.css";
import SurveyComment from "./Surveycomment/SurveyComment"
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from "react-router-dom";



const Badge = styled.div`
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  min-width: 80px;
  text-align: center;
  padding: 6px 10px;
  line-height: 10px;
  border-radius: 22px;
`;

const BadgeCompleted = styled(Badge)`
  background-color: ${({ theme }) => rgba(theme.color2, 1)};
`;

const BadgeProgress = styled(Badge)`
  background-color: ${({ theme }) => rgba(theme.color3, 0.6)};
`;

const BadgeYetToStart = styled(Badge)`
  background-color: ${({ theme }) => rgba(theme.color4, 0.4)};
`;
const cardStyles = {
  position: 'absolute',
  top: '70px',
  left: '20px',
};

const cardStylesRsp2 = {
  position: 'absolute',
  top: '70px',
  left: '20px',
  border: 'none'
};


const cardStylesRsp = {
  position: 'absolute',
  width: '33%',
  display: 'flex',
};



const StyledSurveyCount = styled.div`
&&{
  background-color: ${({ theme }) => theme.color2};
  color: white; 
}
`;
const StyledArrow = styled.div`
&&{
  :hover{
    background-color: ${({ theme }) => theme.color3};
    color:white
  }
}
`;

class SurveyListForm extends PureComponent {
  constructor(props) {
    super(props);
    // this.downloadURL = null;
    this.state = {
      //selectedAssessment: null,
      //summary: false,
      studentResultList: [],
      //assQuestionList: [],
      //stepsEnabled: false,
      //initialStep: 0,
      //statField: "",
      dataArr: [],
      downloadData: [],
      selectedItem: [],
      surveyShortData: {},
      surveyData: [],
      currentQuestionIndex: 0,
      currentPage: 0,
      lengthVal: false,
      surveyCommentDatas: [],
      currentSurvey: [],
      currentPageScroll: 0,
      containerScrollLeft: 0,
      className: 'summary_survey',
      isSummary: true,
    };


  }
  
  componentDidMount() {
    this.getTrainerDeptDetails();
    //this.getTrainerTestStudentQuestSummary();
    this.getTraineeSurveyResponse();
    const container = document.querySelector('.page-numbers-container');
    if (container) {
      container.scrollLeft = 0;
      this.setState({ containerScrollLeft: 0 });
    }

    this.handleResize(); // Initial check when the component mounts
    window.addEventListener('resize', this.handleResize);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= 576) {
      this.setState({ className: 'summary_survey2' });
    } else {
      this.setState({ className: 'summary_survey' });
    }
  }

  getTrainerDeptDetails = () => {
    this.setState({ trendings: [], busy: false });

    // const args = {
    //   publishId: this.props.entity?.id || this.props.location.entityData.id,
    // };
    const args = {
      publishId: this.props.entity?.id || this.props.location.entityData.id,
      coursePublishId:this.props.location.entityData.coursePublishId?.toString() || "0"
    };

    //const { rsp } = await this.props.ctx_api.privateRequest({
    // cmd: 'get_academic_year',
    // args: { name, shortName }
    //  });

    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainer_survey_student_results",
        args,
      })
      .then(this.handleGetTrainerStudentResultsResponse)
      .catch(this.handleGetTrainerStudentResultsFailure);
  };

  handleGetTrainerStudentResultsResponse = ({ rsp }) => {
    const dataArr = rsp.map((rw) => {


      return {
        traineeId: rw.trainee_id,
        id: rw.assessment_id,
        name: rw.name,
        category: rw.category,
        status: this.status,
        statusText: rw.status,
        score: rw.score,
        score_percentage: rw.score_percentage,
        correct: rw.correct,
        incorrect: rw.incorrect,
        unattempted: rw.unattempted,
        link: rw.link,
        evaluatedBy: rw.evaluated_by,
        can_evaluate: rw.can_evaluate ? true : false,
        can_reassign: rw.can_reassign,
        subject: rw.subject,
        grade: rw.grade,
        reassign_date: rw.reassign_date ? rw.reassign_date : null,
        reassignDate: rw.reassign_date
          ? util.toDateString(new Date(rw.reassign_date), false, true)
          : null,
        attendedDate: rw.completed_on
          ? new Date(rw.completed_on).toDateString()
          : null,
      };
    });
    const downloadData = rsp.map((rw) => {
      return {
        traineeId: rw.trainee_id,
        id: rw.assessment_id,
        name: rw.name,
        category: rw.category,
        status: rw.status,
        statusText: rw.status,
        score: rw.score,
        score_percentage: rw.score_percentage,
        correct: rw.correct,
        incorrect: rw.incorrect,
        unattempted: rw.unattempted,
        evaluatedBy: rw.evaluated_by,
        link: rw.link,
        subject: rw.subject,
        attendedDate: rw.completed_on,
      };
    });

    const selectedItem = dataArr
      .filter((rw) => rw.statusText === "Attended")
      .map((rw) => {
        return {
          traineeId: rw.traineeId,
          id: rw.id,
          name: rw.name,
        };
      });

    this.setState({
      dataArr: dataArr,
      downloadData: downloadData,
      selectedItem: selectedItem,
    });
  };

  handleGetTrainerStudentResultsFailure = (err) => {
    this.setState({ trendings: [], view: "", busy: false });

    if (this.props.onError) {
      this.props.onError(err);
    }
  };

  getTraineeSurveyResponse = () => {
    this.setState({ data: [], busy: true });

    //const args = { publishId: this.props.publishId };
    ;
    const args = { publishId: this.props.entity?this.props.entity.id : this.props.location.entityData.id,
      coursePublishId:this.props.location.entityData.coursePublishId?.toString() || "0",

    };

    this.props.ctx_api
      .privateRequest({
        cmd: "get_survey_response_v2",
        args,
      })
      .then(this.handleGetTraineeSurveyResponse)
      .catch(this.handleGetTraineeSurveyFailure);
  };

  handleGetTraineeSurveyResponse = ({ rsp }) => {

    this.setState({
      surveyShortData: rsp[0].questions,
      surveyData: rsp[0].respfinal !== null ? rsp[0].respfinal : [],
      // surveyCommentDatas: rsp[0].respfinal.surveyComment
    });
    const downloadData = rsp[0].respfinal.map((rw) => {
      rw.answers.map((rs) => {

        return {
          student_name: rs.student_name,
          survey_comment: rs.survey_comment
        };
      })
    }
    )
  }




  handleGetTraineeSurveyFailure = (err) => {
    this.setState({ data: [], busy: false });
    if (this.props.onError) {
      this.props.onError(err);
    }
  };


  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    action: PropTypes.string.isRequired,
    // onCreditsChange: PropTypes.func.isRequired,
  };

  adding = () => this.props.action === "add";



  isZero = (n) => !n || n === "0";




  handleStudentResult = (tableData) => {
    const dataArr = tableData.map((rw) => {

      return {
        traineeId: rw.trainee_id,
        id: rw.assessment_id,
        name: rw.name,
        category: rw.category,
        status: rw.status,
        score: rw.score,
        score_percentage: rw.score_percentage,
        correct: rw.correct,
        incorrect: rw.incorrect,
        unattempted: rw.unattempted,
        link: rw.link,
        attendedDate: rw.completed_on,
        reassign_date: rw.reassign_date ? rw.reassign_date : null,
        reassignDate: rw.reassign_date
          ? util.toDateString(new Date(rw.reassign_date), false, true)
          : null,
      };
    });
    this.setState({ studentResultList: dataArr });
  };



  getNameLabel = (props) =>
    this.props.ctx_labels.singular[props.name] || props.name;

  handleNextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1
    }));
  };

  handlePreviousPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1
    }));
  };

  handlePageClick = (pageIndex) => {
   
    this.setState(prevState => ({
      currentPage: pageIndex
    }));
  };

  handlePageClick = (pageIndex) => {
    this.setState({ currentPage: pageIndex });
  };


  // handleComments = () => {
  //   this.setState((prevState) => ({ isSummary: !prevState.isSummary }));  };

  handleComments =() => {
    this.setState({isSummary:true})
  }
  handleComments2 =() => {
    this.setState({isSummary:false})
  }


  scrollLeft = () => {
    const container = document.querySelector('.page-numbers-container');
    if (container) {
      const newScrollLeft = Math.max(0, this.state.containerScrollLeft - 100); // Adjust the scroll amount as needed
      container.scrollLeft = newScrollLeft;
      this.setState({ containerScrollLeft: newScrollLeft });
    }
  };

  scrollRight = () => {
    const container = document.querySelector('.page-numbers-container');
    if (container) {
      const newScrollLeft = Math.min(
        container.scrollWidth - container.clientWidth,
        this.state.containerScrollLeft + 100
      ); // Adjust the scroll amount as needed
      container.scrollLeft = newScrollLeft;
      this.setState({ containerScrollLeft: newScrollLeft });
    }
  };


  render = () => {

    const { isSummary } = this.state;
    const { dataArr, downloadData, selectedItem } = this.state;
    const { menu, selectedMenuId } = this.props;
    if (!selectedMenuId) {
      return <Redirect to="/" />
    }
    const parentMenu = menu[menu[selectedMenuId]?.parent_id];
    const entity = menu[selectedMenuId];
    const { surveyShortData } = this.state;
    const { k } = this.state;
    //const { surveyData } = this.state;
    const { currentPage, surveyData } = this.state;
    // const totalAnswersLength = surveyData.reduce(
    //   (acc, item) => item.answers.length,
    //   0
    // );
    //const answerLengths = surveyData.answers.map((answer) => answer.length);
    // const shouldRenderChart = surveyData.some(item => item.answers.length <= 3);
    // const surveyCommentData = surveyData.survey_comment.map((item) => {
    //   return{
    //     surveyComment: item.survey_comment,
    //     surveyStudentName: item.student_name
    //   }
    // } )



    const surveyComments = surveyData.map((rw) => {
      if (rw.survey_comment !== null) {
        return rw.survey_comment.map((rs) => {

          return {
            student_name: rs.student_name,
            survey_comment: rs.survey_comment
          };
        });
      } else {
        return null;
      }
    });



    const surveyDataFiltered = surveyData.filter(
      item => item.survey_question_type === 3 || item.survey_question_type === 7 || item.survey_question_type === 0
    );
    const currentComponent = surveyDataFiltered[currentPage];

    const currentSurveyComponent = surveyComments[currentPage];
    const pageNumberBoxWidth = 50;
    const containerWidth = 1200;
    //currentSurveyComponent.length > 0 || currentSurveyComponent === null && this.setState({currentSurvey:currentSurveyComponent}) 
    const pageNumbers = Array.from({ length: surveyData.length }, (_, index) => index + 1);
    //const { shouldRender } = this.state;
    const answerLengths = [];
    surveyData.forEach((question) => {
      if (question.answers && Array.isArray(question.answers)) {
        answerLengths.push(question.answers.length);
      } else {
        // If answers array is missing or not an array, push 0
        answerLengths.push(0);
      }
    });
    const sumOfAnswerLengths = answerLengths.reduce((acc, val) => acc + val, 0);

    answerLengths.every((length) => length <= 3) && this.setState({ lengthVal: true })
    answerLengths.every((length) => length > 3) && this.setState({ lengthVal2: true })

    
    return (
      <React.Fragment>
        {/* {(!this.props.entity || !this.props.location) && <Redirect to="/" />} */}
        {this.props.ctx_user.user.type == 2 ? (
          <Breadcrumb
            list={[
              {
                title: util.capitalizeWord(
                  this.getNameLabel({
                    name: parentMenu?.menu_name,
                  })
                ),
                current: true,
              },
              {
                title: util.capitalizeWord(
                  this.getNameLabel({
                    name: this.props.location.alternateEntry
                      ? `${this.props.ctx_labels.singular.publishSurvey}`
                      : entity?.menu_name,
                  })
                ),
                onLinkClick: () => {
                  // this.props.location.alternateEntry
                  this.props.pubSurveyRedirection();
                  // : this.props.onActionDone("canceled");
                },
                link: this.props.location.alternateEntry
                  ? config.ROUTES.publishSurvey
                  : entity.route,
              },
              {
                title: this.props.entity
                  ? this.props.entity.name
                  : this.props.location.entityData.name,
                current: true,
              },
            ]}
          />
        ) : (
          <Breadcrumb
            list={[
              {
                title: util.capitalizeWord(
                  this.getNameLabel({
                    name: `${this.props.ctx_labels.singular.survey}`,
                  })
                ),
                current: true,
              },
              {
                title: util.capitalizeWord(
                  this.getNameLabel({
                    name: this.props.location.alternateEntry
                      ? `${this.props.ctx_labels.singular.publishSurvey}`
                      : entity.menu_name,
                  })
                ),
                onLinkClick: () => {
                  // this.props.location.alternateEntry
                  this.props.pubSurveyRedirection();
                  // : this.props.onActionDone("canceled");
                },
                link: this.props.location.alternateEntry
                  ? config.ROUTES.publishSurvey
                  : entity.route,
              },
              {
                title: this.props.entity
                  ? this.props.entity.name
                  : this.props.location.entityData.name,
                current: true,
              },
            ]}
          />
        )}
        {/* <JustifyBetween breakpoint="sm">
          <PageTitle>
            {this.props.entity
              ? this.props.entity.name
              : this.props.location.entityData.name}
          </PageTitle>

        </JustifyBetween> */}
        <TrainerOverview

          shortName={
            this.props.entity
              ? this.props.entity.name
              : this.props.location.entityData.name
          }

          fromDate={
            this.props.entity
              ? this.props.entity.fromDate
              : this.props.location.entityData.fromDate
          }
          toDate={
            this.props.entity
              ? this.props.entity.toDate
              : this.props.location.entityData.toDate
          }
          attended={
            this.props.location.entityData?.coursePublishId?this.props.location.entityData?.attended:(this.props.location.entityData.tot_students - this.props.location.entityData.unattended)
          }
          tot_marks={
            this.props.entity
              ? this.props.entity.tot_marks
              : this.props.location.entityData.tot_marks
          }
          tot_questions={
            this.props.entity
              ? this.props.entity.tot_questions
              : this.props.location.entityData.tot_questions
          }
          unattended={
            this.props.entity
              ? this.props.entity.unattended
              : this.props.location.entityData.unattended
          }
        />

        <div className="mt-2"></div>        
<Card className={this.state.className} style={{ borderRadius: "7px", height: "fit-content" }}> 
          <ResponsiveTabs
            items={[
              {
                title: <div style={{ marginTop: "10px" }}
                onClick={this.handleComments}
                >Survey Summary</div>,
                content: (
                  <><div style={{ height: "auto"}}>
                    <Card className="border-1 pb-4 summary" style={{ borderRadius: "7px",overflowY: "auto" }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {surveyData.length>=27 ? 
                        <StyledArrow className="page-number-box arrow left-arrow " style={{marginTop:"20px"}} onClick={this.scrollLeft}>
                          {"<"}
                        </StyledArrow> : null}
                        <div className="page-numbers-container" style={{ width: `${containerWidth}px`, overflow: 'hidden', flex: '1' }}>
                          <div className="page-numbers" style={
                            { 
                              transform: `translateX(-${this.state.containerScrollLeft}px)`, 
                              // whiteSpace: 'nowrap', 
                              // display: 'flex' 
                            }
                            }>
                            {pageNumbers.map((pageNumber, pageIndex) => (
                              <div
                               key={pageNumber}
                                style={{ width: `${pageNumberBoxWidth}px`, display: 'inline-block' }}
                                onClick={() => this.handlePageClick(pageIndex)}
                              >
                                {pageIndex === currentPage ?
                                 <StyledSurveyCount
                                 className="page-number-box"
                               >
                                 {pageNumber}
                               </StyledSurveyCount>
                               :
                               <div
                               className="page-number-box"
                             >
                               {pageNumber}
                             </div>
                              }
                                                              
                              </div>
                            ))}
                          </div>
                        </div>
                        {surveyData.length>=27 ? 
                        <StyledArrow className="page-number-box arrow left-arrow " style={{marginTop:"20px",marginRight: "5px"}} onClick={this.scrollRight}>
                          {">"}
                        </StyledArrow> : null}
                      </div>
                      <div style={{ margin: "25px", overflowY: "inherit", overflowX: "clip", }}>
                        
                          <>
                            <MediaQuery maxWidth={575}>
                              {currentComponent ?(
                                <div stle={{ width: "60px" }}>
                                {currentComponent.survey_question_type === 3 && (
                                  <Col xl={12} lg={7}>
                                    <ShortQuestionnaire surveyData={currentComponent} />
                                  </Col>
                                )}
                                {currentComponent.survey_question_type === 7 && (
                                  <Col xl={5} lg={7}>
                                    <RatingAndReviews surveyData={currentComponent} />
                                  </Col>
                                )}

                                {currentComponent.survey_question_type === 0 && (currentComponent.survey_progress_bar === 1 ?

                                  <Col xl={12} lg={16}>
                                    <Multistatics surveyData={currentComponent} />
                                  </Col> : <Col xl={5} lg={7}>
                                    <QuestionnaireChart surveyData={currentComponent} /></Col>

                                )}
                              </div>
                              ):<div className="no-records" style={{marginTop:"-15px"}}>There are no records to display</div>}
                              
                            </MediaQuery>

                            <MediaQuery minWidth={576} maxWidth={820}>
                              {currentComponent ?(
                                <Card style={cardStylesRsp2}>
                                {currentComponent.survey_question_type === 3 && (
                                  <Col xl={12} lg={7}>
                                    <ShortQuestionnaire surveyData={currentComponent} />
                                  </Col>
                                )}
                                {currentComponent.survey_question_type === 7 && (
                                  <Col xl={5} lg={7}>
                                    <RatingAndReviews surveyData={currentComponent} />
                                  </Col>
                                )}

                                {currentComponent.survey_question_type === 0 && (currentComponent.survey_progress_bar === 1 ?

                                  <Col xl={12} lg={16} >
                                    <Multistatics surveyData={currentComponent} />
                                  </Col> : <Col xl={5} lg={7}>
                                    <QuestionnaireChart surveyData={currentComponent} /></Col>

                                )}
                              </Card>
                              ):<div className="no-records">There are no records to display</div>}
                              
                            </MediaQuery>

                            <MediaQuery minWidth={821}>
                              {currentComponent ?(
                                <Card style={{ ...cardStyles,  width: '97%', height: 'auto', display: 'flex', flexDirection: 'column', borderRadius: '7px', }}>
                                {currentComponent.survey_question_type === 3 && (
                                  <Col xl={12} lg={7}>
                                    <ShortQuestionnaire surveyData={currentComponent}/>
                                  </Col>
                                )}
                                {currentComponent.survey_question_type === 7 && (
                                  <Col xl={5} lg={7} style={{paddingBottom: "15px"}}>
                                    <RatingAndReviews surveyData={currentComponent}
                                     />
                                  </Col>
                                )}

                                {currentComponent.survey_question_type === 0 && (currentComponent.survey_progress_bar === 1 ?

                                  <Col xl={12} lg={16}>
                                    <Multistatics surveyData={currentComponent} />
                                   
                                  </Col> : <Col xl={5} lg={7} >
                                    <QuestionnaireChart surveyData={currentComponent} />
                                    </Col>

                                )}
                              </Card>
                              ):
                              <div className="no-records">There are no records to display</div>
                              }
                              

                            </MediaQuery>
                          </>


                        
                        <MediaQuery minWidth={821}>
                        {currentComponent ?
                          <div className="button" style={{ marginTop: "60px", marginRight: "30px" }}>
                            <Button
                              variant="primary"
                              onClick={this.handlePreviousPage}
                              disabled={currentPage === 0}
                              className="small-button"
                              style={{ backgroundColor: "white", color: "black", alignItems: "center",marginTop: "3px", marginBottom: "11px" }}
                            >
                              <FontAwesomeIcon icon={faChevronLeft} size="2x" style={{ marginTop: "5px",marginLeft: "3px",marginBottom: "-1px",marginRight: "2px" }} />
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.handleNextPage}
                              disabled={currentPage === surveyDataFiltered.length - 1}
                              className="small-button"
                              style={{ backgroundColor: "white", color: "black", alignItems: "center",marginTop: "3px",marginBottom: "11px" }}
                            >
                              <FontAwesomeIcon icon={faChevronRight} size="2x" style={{ marginTop: "7px",marginLeft: "2px",marginBottom: "1px",marginRight: "3px"}} />
                            </Button>
                          </div> : null}
                        </MediaQuery>

                      </div>
                    </Card> 
                    </div></>
                ),
              },

              {
                title: <div style={{ marginTop: "10px", }}
                 onClick={()=>{console.log("need to fix it --this.handleComments2")}}
                >Student Level Summary</div>,
                content: (
                  <>
                    <StudentTestResults
                      entityData={
                        this.props.entity
                          ? this.props.entity
                          : this.props.location.entityData
                      }
                      addRedirection={this.props.addRedirection}
                      publishId={
                        this.props.entity
                          ? this.props.entity.id
                          : this.props.location.entityData.id
                      }
                      //onRowClick={this.handelResultRowSelect}
                      studentResultList={dataArr}
                      downloadData={downloadData}
                      onTableLoad={this.handleStudentResult}
                      selectedItem={selectedItem}
                      handleReassignResults={this.handleReassignForm}
                    />
                  </>
                ),
              }
            ]}
          />
        </Card>

        <MediaQuery maxWidth={575}>
        {isSummary === true ?
          <div style={{ marginTop: "20px", width: "100%", }}>
            <Card style={{ height: "auto", marginLeft: "-15px", marginRight: "-15px", paddingTop: "-30px" }}>
              <ResponsiveTabs style={{ marginTop: "10px" }}
                items={[
                  {
                    title: <div style={{ marginTop: "10px" }}>Comments</div>,
                    content: (
                      <>
                        {currentSurveyComponent && (currentComponent.survey_question_type !== 3 && isSummary === true) ? (
                          <SurveyComment
                            surveyData={surveyData}
                            downloadData={downloadData}
                            surveyComments={surveyComments}
                            surveyCommentss={currentComponent}
                          />
                        ) : (
                          null
                        )}

                      </>)
                  }]} />
            </Card>
          </div>:""}
        </MediaQuery>

        <MediaQuery minWidth={576} maxWidth={767}>
        {isSummary === true ?
          <div style={{ marginTop: "70px", width: "auto", }}>
            <Card>
              <ResponsiveTabs
                items={[
                  {
                    title: "Comments",
                    content: (
                      <>
                        {currentSurveyComponent && (currentComponent.survey_question_type !== 3 && isSummary === true) ?  (
                          <SurveyComment
                            surveyData={surveyData}
                            downloadData={downloadData}
                            surveyComments={surveyComments}
                            surveyCommentss={currentComponent}
                          />
                        ) : (
                          null
                        )}

                      </>)
                  }]} />
            </Card>
          </div>: ""}
        </MediaQuery>

        <MediaQuery minWidth={768}>
        {isSummary === true ?
          <div style={{ marginTop: "70px", width: "auto" }}> 
            <Card style={{ marginTop: "10px", borderRadius: '10px',border: 'none'}}>
              <ResponsiveTabs
                items={[
                  {
                    title: <div style={{ marginTop: "10px" }}>Comments</div>,
                    content: (
                      <>
                        {currentSurveyComponent && (currentComponent.survey_question_type !== 3 && isSummary === true) ?  (
                          <SurveyComment
                            surveyData={surveyData}
                            downloadData={downloadData}
                            surveyComments={surveyComments}
                            surveyCommentss={currentComponent}
                          />
                        ) : (
                          null
                        )}

                      </>)
                  }]} />
            </Card> 
          </div>: ""}
        </MediaQuery>


      </React.Fragment>
    );
  };
}

export default withApiContext(
  withLabelsContext(withUserContext(withRouter(SurveyListForm)))
);
