import { rgba } from "polished";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// import { default as ReactSelect } from "react-select";
import styled, { useTheme } from "styled-components";
import "./select.scss";
import Select from 'react-select';
// import 'react-select/dist/react-select.css';

// Theme
// const StyledReactSelect = styled(Select)`

// `;

// export default function Select(props) {
// 	return (
// 		<StyledReactSelect
// 			className="react-select-container"
// 			classNamePrefix="react-select"
// 			isSearchable={false}
// 			{...props}
// 		/>
// 	);
// }

class NewSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: props.async ? [] : this.mkOptions(props),
    };
    this.searchString = "";
  }

  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    ),
    onChange: PropTypes.func.isRequired,
    unselectedValue: PropTypes.any,
    renderOption: PropTypes.func,
    required: PropTypes.bool.isRequired,
    allOptionsVisible: PropTypes.bool.isRequired,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    required: false,
    allOptionsVisible: false,
    unselectedValue: null,
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.options !== this.props.options) {
      const options = this.mkOptions(nextProps);
      this.setState({ options });
    }
  };

  mkOptions = ({ options }) =>
    !options
      ? null
      : options.map((o) => ({
          ...o,
          __lcLabel: o.label.toLowerCase(),
        }));

  handleInputChange = (str) => {
    this.searchString = str.toLowerCase() || "";
  };

  mkColourStyles = () => {
    const hexToRgbColor = (hex) => {
      hex = hex.replace(/^#/, '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return { r, g, b };
    };
    const boxshadowColor = hexToRgbColor( this.props.theme.primary )
    const rgbaColor = `rgba(${boxshadowColor.r}, ${boxshadowColor.g}, ${boxshadowColor.b}, 0.5)`;
    return {
      control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        // style for the control (container) element
        ...styles,
        borderColor:  `${rgbaColor} !important`,
        borderRadius: 8,
        height:"40px",
        boxShadow: isFocused ? `${rgbaColor} 0px 1px 4px 0px, ${rgbaColor} 0px 1px 4px 0px`: isSelected ?  `${this.props.theme.primary} !important`  :'none',
        cursor: 'pointer',
        '&:hover': {
          borderColor: (isFocused || isSelected) ? `${this.props.theme.primary} !important` : "none",
        },
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected
            ? this.props.theme.primary
            : isFocused
            ? this.props.theme.color6
            : "",
         boxShadow: (isFocused || isSelected) ? rgbaColor : 'none',
        };
      },
    };
  };

  handleChange = (option) => {
    let value;
    if (option) {
      if (option.value === null || option.value === undefined) {
        value = option;
      } else {
        value = option.value;
      }
    } else {
      value = this.props.unselectedValue;
    }
    this.props.onChange(value);
  };

  handleSetValue = (data) => {
    console.log(data);
  };

  handleMultiChange = (option) => {
    const value = option.map((option) => option.value);
    this.props.onChange(value);
  };

  renderSelectedOption = (option) => {
    
    if (this.props.renderOption) {
      return this.props.renderOption(option.label, option);
    }
    return option.label;
  };

  renderMenuOption = (option) => {
    const { value, label, __lcLabel } = option;
    let keyIdx = 0;
    const labelFrags = [];
    const addLabelFrag = (i, j, className) => {
      labelFrags.push(
        <span className={className} key={`${value}-${keyIdx}`}>
          {label.substring(i, j)}
        </span>
      );
      keyIdx = keyIdx + 1;
    };
    let i = 0;
    const matchLen = this.searchString.length;
    for (;;) {
      const j = __lcLabel.indexOf(this.searchString, i);
      if (matchLen === 0 || j === -1) {
        addLabelFrag(i);
        break;
      }
      addLabelFrag(i, j);
      i = j + matchLen;
      addLabelFrag(j, i, "select-label-substring-match");
    }
    if (this.props.renderOption) {
      return this.props.renderOption(labelFrags, option);
    }
    return labelFrags;
  };

  render = () => {
    const {
      className,
      options,
      onChange,
      renderOption,
      required,
      allOptionsVisible,
      inputRef,
      ...restProps
    } = this.props;
    const selectClassName = ["select", className].join(" ");
    const wrapperStyle =
      !allOptionsVisible || !this.state.options
        ? {}
        : {
            "--select-max-visible-options": this.state.options.length,
          };
    const value = this.props.async
      ? this.state.options?.filter(
          (o) =>
            this.props?.value !== null &&
            this.props?.value?.length > 0 &&
            (typeof this.props?.value === "string"
              ? this.props?.value == o.value
              : this.props?.value.includes(o.value))
        )
      : this.state.options?.filter((o) => o.value === this.props.value);
    return (
      <Select
        {...restProps}
        value={value}
        isMulti={this.props.multi || false}
        // isSearchable={false}
        className={
          this.props.customClass
            ? `react-select-container ${this.props.className}`
            : "react-select-container"
        }
        classNamePrefix="react-select"
        wrapperStyle={wrapperStyle}
        isLoading={this.state.options === null}
        onChange={this.props.multi ? this.handleMultiChange : this.handleChange}
        onInputChange={this.handleInputChange}
        options={this.state.options}
        setValue={this.handleSetValue}
        // optionRenderer={this.renderMenuOption}
        // valueRenderer={this.renderSelectedOption}
        // trimFilter={false}
        // matchProp="label"
        isClearable={this.props.fromCB ? required : !required}
        // backspaceRemoves={!required}
        // deleteRemoves={!required}
        // escapeClearsValue={!required}
        ref={inputRef}
        styles={this.mkColourStyles()}
        isDisabled ={this.props.disabled}
        hideSelectedOptions={this.props.hideSelectedOptions}
        maxMenuHeight={this.props.maxMenuHeight}
      />
    );
  };
}

export default NewSelect;
