import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox } from "components";



class FormCheckbox extends PureComponent {
  static propTypes = {
    title:    PropTypes.string.isRequired,
    value:    PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleChange = (e) => {
    this.props.onChange(e.target.checked);
  }

  render = () => {
    const {
      title,
      value,
      ...restProps
    } = this.props;
    return (
      <Checkbox
        { ...restProps }
        checked={value}
        onChange={this.handleChange}
      >
        {title}
      </Checkbox>
    );
  }
}


export default FormCheckbox
