import RichTextArea from "components/Notes/RichTextArea";
import * as config from "config";
import { withApiContext, withWindowContext } from "context";
import * as messages from "messages";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import ResizeHandler from "services/ResizeHandler";
import JoditEditor from "jodit-react";
// import './ModuleNotes.css';

class ModuleNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIdx: null,
      notes: [],
      moduleNameElts: [],
      toolbarElt: null,
    };
    this.textAreas = [];
    this.resizeHandler = null;
  }

  static propTypes = {
    memberId: PropTypes.string.isRequired,
    curriculumId: PropTypes.string.isRequired,
    configId: PropTypes.string,
    contentId: PropTypes.string,
    onReady: PropTypes.func,
    onUnavailable: PropTypes.func,
  };

  componentDidMount = () => {
    this.addResizeHandler();
    this.getNotes();
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.memberId !== this.props.memberId ||
      nextProps.curriculumId !== this.props.curriculumId ||
      nextProps.configId !== this.props.configId ||
      nextProps.contentId !== this.props.contentId
    ) {
      this.finalizeNotes();
      this.getNotes(nextProps);
    }
  };

  componentWillUnmount = () => {
    this.finalizeNotes();
    this.removeResizeHandler();
  };

  addResizeHandler = () => {
    this.resizeHandler = new ResizeHandler(
      this.updateHeights,
      config.curriculumNotesResizeDebounceMillis
    );
  };

  removeResizeHandler = () => {
    this.resizeHandler.cleanup();
  };

  updateHeights = () => {
    this.forceUpdate();
  };

  hasText = (n) => n.editorValue.getEditorState().getCurrentContent().hasText();

  getNotes = (props = this.props) => {
    props.ctx_api
      .privateRequest({
        cmd: "get_video_rating_notes",
        args: {
          memberId: props.memberId,
          curriculumId: props.curriculumId,
          configId: props.configId,
          videoUnitId: props.contentId === "" ? null : props.contentId,
        },
      })
      .then(this.handleGetNotesResponse(props))
      .catch(this.handleNotesSystemFailure);
  };

  handleGetNotesResponse = (props) => ({ rsp }) => {
    const notes = rsp.map((r) => ({
      id: r.video_rating_id,
      moduleName: r.topic,
      editorValue: RichTextArea.fromString(r.video_notes, "html"),
    }));
    let selectedIdx = null;
    if (props.contentId) {
      selectedIdx = 0;
      if (notes.length === 0) {
        notes[0] = { editorValue: RichTextArea.empty() };
      }
    }
    this.setState({ notes, selectedIdx }, () => {
      if (props.onReady) {
        props.onReady();
      }
    });
  };

  finalizeNotes = () => {
    const i = this.state.selectedIdx;
    if (i !== null) {
      this.updateNotes(this.state.notes[i]);
    }
    if (this.props.onUnavailable && !this.state.notes.some(this.hasText)) {
      this.props.onUnavailable({
        memberId: this.props.memberId,
        curriculumId: this.props.curriculumId,
        configId: this.props.configId,
        contentId: this.props.contentId,
      });
    }
  };

  updateNotes = (n) => {
    const args = {
      status: 1,
      // status: this.hasText(n) ? 1 : 0,
      videoRatingId: n.id,
      videoNotes: n.editorValue.toString("html"),
    };
    if (!args.videoRatingId) {
      if (args.status === 0) {
        return;
      }
      args.videoRatingId = "0";
      args.memberId = this.props.memberId;
      args.curriculumId = this.props.curriculumId;
      args.videoUnitId = this.props.contentId;
      args.coursePublishId = this.props.coursePublishId;
    }
    this.props.ctx_api
      .privateRequestRow({
        cmd: "insert_update_video_rating_notes",
        args,
      })
      .then(this.handleUpdateNotesResponse)
      .catch(this.handleNotesSystemFailure);
  };

  handleUpdateNotesResponse = ({ rsp }) => {
    if (rsp.statusvalue !== 1) {
      this.handleNotesSystemFailure();
    }
  };

  handleNotesSystemFailure = () => {
    this.props.ctx_window.alert(messages.mkNotesErrorAlert());
  };

  handleChange = (i) => (v) => {
  // handleChange = (e) => {
    // if(this.props.copyNotes && this.state.notes[i].videoNotes !== v.toString('html')){
    //   this.props.onNoteChange(this.state.notes[i].id, v.toString('html'), this.props.contentId, this.props.curriculumId)
    // }
    this.setState((s) => {
      const notes = [...s.notes];
      notes[i].editorValue = v;
      return { notes };
    });
  };

  handleClick = (i) => (e) => {
    if (this.textAreas[i]) {
      this.textAreas[i].focus();
    }
    this.setState((s) => {
      const oldI = s.selectedIdx;
      if (oldI !== null && oldI !== i) {
        this.updateNotes(s.notes[oldI]);
      }
      return { selectedIdx: i };
    });
  };

  setModuleNameElement = (i) => (r) => {
    const elt = ReactDOM.findDOMNode(r);
    if (!elt || this.state.moduleNameElts[i]) {
      return;
    }
    this.setState((s0) => {
      const moduleNameElts = [...s0.moduleNameElts];
      moduleNameElts[i] = elt;
      return { moduleNameElts };
    });
  };

  setToolbarElement = (toolbarElt) => {
    this.setState({ toolbarElt });
  };

  renderRichTextArea = (i, readOnly = false) => {
    const config = {
      statusbar: false,
      readonly: false,
      height: 320,
      disablePlugins:
        "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,size,redo-undo,clean-html,powered-by-jodit,source",
    };
    return(
    <RichTextArea
      value={this.state.notes[i].editorValue}
      readOnly={readOnly}
      onChange={this.handleChange(i)}
      ref={(r) => { this.textAreas[i] = r; }}
      setToolbarElement={this.setToolbarElement}
    />)
    // return (
    //   <JoditEditor
    //     // config={config}
    //     // ref={(r) => {
    //     //   this.textAreas[i] = r;
    //     // }}
    //     // value={this.state.notes[i].editorValue._cache.html}
    //     // onChange={this.handleChange(i)}
    //     onChange={(e)=>{this.handleChange(e)}}
    //   />
    // );
  };

  renderModuleNotes = () =>
    this.state.notes.length === 0 ? null : (
      <div className="module-notes" onClick={this.handleClick(0)}>
        {this.renderRichTextArea(0)}
      </div>
    );

  renderCurricModuleNotes = (n, i) => {
    const readOnly = i !== this.state.selectedIdx;
    const className = [
      "notes-row",
      readOnly ? "notes-row-read-only" : "notes-row-read-write",
    ].join(" ");
    const elt = this.state.moduleNameElts[i];
    const moduleNameHeight = elt ? elt.scrollHeight : 0;
    const rowStyle = {
      "--notes-row-module-name-height": `${moduleNameHeight}px`,
    };
    return (
      <div
        className={className}
        style={rowStyle}
        onClick={this.handleClick(i)}
        key={`notes-row-${i}`}
      >
        <div className="notes-module">
          <div className="notes-module-name" ref={this.setModuleNameElement(i)}>
            {n.moduleName}
          </div>
          <div className="notes-module-toolbar-placeholder" />
        </div>
        <div className="notes-notes">
          {this.renderRichTextArea(i, readOnly)}
        </div>
      </div>
    );
  };

  renderCurriculumNotes = () => {
    const toolbarScrollHeight = this.state.toolbarElt
      ? this.state.toolbarElt.scrollHeight
      : 0;
    const notesStyle = {
      "--notes-toolbar-scroll-height": `${toolbarScrollHeight}px`,
    };
    return (
      <div className="notes" style={notesStyle}>
        {this.state.notes.length === 0
          ? messages.mkNotesNotFoundInfo()
          : this.state.notes.map((n, i) => this.renderCurricModuleNotes(n, i))}
      </div>
    );
  };

  render = () =>
    this.props.contentId
      ? this.renderModuleNotes()
      : this.renderCurriculumNotes();
}

export default withWindowContext(withApiContext(ModuleNotes));
