import CryptoJS from "crypto-js";
import { encryptionKey } from "config";

export const encryptObject = (data, type) => {
    // console.log(data)
    const encrypted = CryptoJS.AES.encrypt(type === "object" ? JSON.stringify(data) : data, encryptionKey).toString();
    // console.log(encrypted)
    return encrypted;
};

export function decryptObject (encryptedData) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
    const decryptedObject = JSON.parse(decrypted);
    return decryptedObject;
  };