import React from "react";
import Slider from 'react-input-slider';
import { IconCircleAdd, IconCircleMinus } from "Icons";
import css from './zoom-slider.module.scss';
import { Button } from "components";

class ZoomSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: 40
        }

        this.style = {
            track: {
                backgroundColor: 'rgba(224, 224, 224, 0.32)',
                height: '2px'
            },
            active: {
                backgroundColor: 'rgba(224, 224, 224, 0.32)',
            },
            thumb: {
                width: '4px',
                height: '14px',
                borderRadius: '10px'
            },
        }
    }

    render() {
        return (
            <div className={css.zoom}>
                <Button variant="link" iconOnly className="p-0" onClick={() => this.setState({ value: (this.state.value > 0) ? this.state.value - 10 : 0 })}>
                    <IconCircleMinus width="20" height="20" />
                </Button>
                <div className={css.slider_wrapper}>
                    <Slider className={css.slider}
                        axis="x"
                        x={this.state.value}
                        styles={this.style}
                        onChange={({ x }) => this.setState({ value: x })}
                    />
                </div>

                <Button variant="link" iconOnly className="p-0" onClick={() => this.setState({ value: this.state.value < 100 ? this.state.value + 10 : 100 })}>
                    <IconCircleAdd width="20" height="20" />
                </Button>
            </div>
        )
    }
}

export default ZoomSlider;

