import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import css from "./block-upload-pdf.module.scss";
import classNames from "classnames";
import { IconImageFile, IconPDF } from "Icons";
import PropTypes from "prop-types";

function BlockUploadPdf({ type, maxSize, minSize, multiple, onPdfDrop, isAssignment }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    },
    [selectedFiles]
  );
  
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
     
  } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: type === "application/pdf" ? "application/pdf" : "",
    minSize: minSize,
    maxSize: maxSize,
    multiple: multiple
  });

  const Icon = type === "application/pdf" ? <IconPDF /> : "";

  const removeAll = () => {
    setSelectedFiles([]);
    onPdfDrop({},'removed')
  };

  const files = acceptedFiles.map((file, ind) =>{ 
    const fileData = selectedFiles.length ? file : {};
    const fileStatus = selectedFiles.length ? 'uploaded' : 'removed';
    onPdfDrop(fileData, fileStatus);
    // onPdfDrop(file,'uploaded')
    return(
    <div className={css.pdf_selectedFile} key={ind}>
      <div className="text-clamp-2">{file.name}</div>
      <div className={`${css.pdf_info_row}`}>
        <div className={css.pdf_file_info}>
          {Icon} {file.size} bytes
        </div>
        <div className="text-primary cursor-pointer" onClick={removeAll}>
          Remove
        </div>
      </div>
    </div>
  )});

  return (
    <div>
    <div className={css.pdf_wrapper}>
      <div className={classNames(css.pdf_block, isDragActive && css.pdf_active)}>
        {selectedFiles.length && !isAssignment ? (
          files
        ) : (
          <div {...getRootProps()} className={css.pdf_droparea}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <div>
                  <div className={css.pdf_icon_drag}>
                    {Icon && <IconPDF />}
                  </div>
                  Drop here
                  <div className={css.pdf_small}>Leave the file here to upload</div>
                </div>
              </>
            ) : (
              <>
                <span>
                  Drag and drop file here &nbsp;
                  <span className={css.pdf_small}>
                    Or &nbsp;<span className="text-primary">browse</span> your file to
                    upload
                  </span>
                </span>

                {Icon && (
                  <div className={`${css.pdf_info_row}`}>
                    <div className={css.pdf_file_info}>
                      <IconPDF /> PDF Only
                    </div>
                    <div>Max size {Math.floor(maxSize / 1024 / 1024)} MB</div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {/* error */}
      {fileRejections.length > 0 && (
        <div className={`text-danger ${css.pdf_error}`}>
          { fileRejections[0].errors[0].code == 'file-too-large'
          ? 'File is too large !' 
          : fileRejections[0].errors[0].code == 'file-too-small'
          ? 'File is too small !' : 'Incorrect file type, please upload PDF'
          }          
        </div>
      )}
    </div>
    </div>
  );
}

BlockUploadPdf.propTypes = {
  type: PropTypes.string,
};

export default BlockUploadPdf;
