import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { withUserContext } from "context";
import FullScreen from "components/PlayerNew/Video/FullScreen";
import "./HTMLEditor.css";
import ReactDOM from 'react-dom';
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import classNames from "classnames";
 
class HTMLEditorfullscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorContent: "",
      isInvalidUrl: false,
      fullScreen: false,
      primaryPane: null,
      multiplePanesSupported: false,
    };
  }
 
  static defaultProps = {
    desktopUrl: "",
    mobileUrl: "",
  };
 
  componentDidMount() {
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", this.handleFullscreenChange);
  }

  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("MSFullscreenChange", this.handleFullscreenChange);
  }

  handleFullscreenChange = () => {
    const isFullscreenNow = !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );

    this.setState({ fullScreen: isFullscreenNow });
  };

  toggleFullScreen = pane => () => {
    this.setState({ fullScreen: !this.state.fullScreen });
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }
 
    this.setState({ fullScreen: !this.state.fullScreen });
  };
 
  isFullscreenNow = () => !!(
    document.fullscreenElement    ||
    document.msFullscreenElement  ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement
  );
 
 
  renderControls = () => (
    <div className={`${this.props.isContentBuilder ? cmCss.toolbar : cmCss.toolbar_module} bg-color2 text-white`}>
      <div className={cmCss.toolbar_left}></div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.renderFullScreen()}</div>
    </div>
  );
 
  toggleFullScreen = (pane) => {
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }
 
    this.setState({ fullScreen: !this.state.fullScreen });
  };
 
  renderFullScreen = (key,hidden) => {
    const {fullScreen} =this.state;
    const fullscreenHeight = fullScreen ? 800 : 200;
   
    return(
    <FullScreen
      onClick={this.toggleFullScreen}
      hidden={hidden}
      key={key}
      fullScreen={fullScreen}
      fullscreenHeight={fullscreenHeight}
      style ={{ height : `${window.screen.height}px` }}
    />
  );
  };
 
  renderControls = () => (
   
    <div className={classNames(`${cmCss.toolbar_mob} bg-color2 text-white`)}>
      <div className={cmCss.toolbar_left}>&nbsp;</div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.renderFullScreen()}</div>
    </div>
  );
 
  render() {
    const { editorContent } = this.props;
    const screenSize = window.innerWidth < 768 ? false : true;
 
    return (
      
      <div className="htlmleditor-frame">
        <div 
         className={this.state.fullScreen ? 'html-content-full' : 'html-content-nofull'}  
        dangerouslySetInnerHTML={{ __html: editorContent }} />
        <div style = {{bottom: "0px"}}>{this.renderControls()}</div>
      </div>
    );
  }
}
 
HTMLEditorfullscreen.propTypes = {
  desktopUrl: PropTypes.string,
  mobileUrl: PropTypes.string,
};
 
export default withUserContext(HTMLEditorfullscreen);