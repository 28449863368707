import PropTypes from "prop-types";
import React from "react";
import { Download as DownloadIcon } from "../icons/Download";
import labels from "../labels";
import "./Download.css";
import { Button } from "components";

const Download = ({ downloadContent }) => {
  // const iconType = DownloadIcon;
  const iconProps = {
    className: "player-download-icon",
    height: 34,
    fill: "#fff",
  };
  return (
    <Button
      variant="link"
      iconOnly
      className="p-0"
      // type="button"
      // className="btn-player-control"
      // aria-label={labels.fullscreen}
      onClick={downloadContent}
    >
      {/* {React.createElement(iconType, iconProps)} */}
      <DownloadIcon style={{ width: "24px", height: "24px", margin: "0 7px" }}/>
    </Button>
  );
};

Download.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
  toggleFullscreen: PropTypes.func,
};

export default Download;
