import { DashboardPanel } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Card } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import MediaQuery from "react-responsive";
import { useTheme } from "styled-components";


function ActiveTimeConsumption(props) {
  const theme = useTheme();
  const PieChart = ({ pieData }) => {
    const data01 = pieData.map((item) => item.ActiveTime);
    const data02 = pieData.map((item) => item.intent);
    const colors = [theme.primary,theme.color2]
   const series = data01;
  
    const options = {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data02,
      colors: colors,
      dataLabels:{
        enabled: true,
        formatter: function (value) {
          return value.toFixed(2) + "%";
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            const duration = moment.duration(value, "minutes");
            const hours = Math.floor(duration.asHours());
            const formattedMinutes = (duration.asMinutes() % 60).toFixed(2);
            return `${hours}h ${formattedMinutes}min`;
          },
        },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              width: "100%",
              height: 300,
            },
          },
        },
      ],
    };
  
    return (
      <>
        <MediaQuery minDeviceWidth={0} maxDeviceWidth={600}>
          <div id="chart" style={{ marginTop: "55px", marginLeft: "10px" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              width={400}
            />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={601} maxDeviceWidth={991}>
          <div id="chart" style={{ marginTop: "20px", marginLeft: "130px" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              width={400}
            />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={992} maxDeviceWidth={1280}>
          <div id="chart" style={{ marginTop: "20px", marginLeft: "20px" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              width={400}
            />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1281}>
          <div id="chart" style={{ marginTop: "20px", marginLeft: "120px" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              width={400}
            />
          </div>
        </MediaQuery>
      </>
    );
  };

  return (
    <DashboardPanel title={`${props.ctx_labels.singular.activeTime} ${props.ctx_labels.singular.consumption}`}>
      {props.data && props.data.length > 0 ? (
        <Card body className="border-0" style={{ height: "355px" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "-30px", marginTop: "14px" }}>
          <PieChart pieData={props.data} />
        </div>
      </Card>
      ) : (
        <Card body className="border-0">
          {`${props.ctx_labels.singular.thereIsNoData}`}
        </Card>
      )}
    </DashboardPanel>
  );
}

export default withLabelsContext(ActiveTimeConsumption);


