import * as config from 'config';


class ResizeHandler {
  constructor(
    callback,
    interval = config.defaultResizeDebounceIntervalMillis,
  ) {
    this.__interval = interval;
    this.__timer = null;
    this.__callback = () => {
      if (this.__timer) {
        this.__timer = null;
        callback();
      }
    }
    window.addEventListener('resize', this.__handleResize);
  }

  __handleResize = () => {
    if (this.__timer) {
      clearTimeout(this.__timer);
    }
    this.__timer = setTimeout(this.__callback, this.__interval);
  }

  cleanup = () => {
    if (this.__timer) {
      clearTimeout(this.__timer);
      this.__timer = null;
    }
    window.removeEventListener('resize', this.__handleResize);
  }
}


export default ResizeHandler
