import FormField from "components/FormField";
import * as config from "config";
import {
  withApiContext,
  withUserContext,
  withSiteContext,
  withLabelsContext,
} from "context";
import React, { PureComponent } from "react";
import { Col, Row, Button } from "react-bootstrap";
import {BlockUpload} from "components";
import * as util from "services/util";
import { IconImport, IconImportMedia } from "Icons";
import styled from "styled-components";
import JoditEditor from "jodit-react";
import{ rgba } from "polished";
import "./Videoinput.css";
import { MoonLoader } from "react-spinners";
import ScromInput from "components/ModuleInput/Html/ScromInput";


const ActionInputCancel = styled.div`
.transparent-btn {
  background-color: transparent;
  border: 1px solid ${({ theme }) =>
    theme.primary}; /* You can adjust the border color as needed */
  color: #002C79;
  padding: 4px 16px; /* Adjust padding as needed */
  font-size: 12px; /* Adjust font size as needed */
  cursor: pointer;
  // height: 4vh;
  // width: 11vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.module-video-url {
  width: 100%;
  word-break: break-word;
  margin-left: 15px;
}

.upload-btn {
  border: 1px solid #C9C9C9;
  min-height: 36px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 36px;
  margin-left: .8rem;
  justify-content: center;
  border-radius: 4px;
}

.img-icon{   
  fill: ${({ theme }) => theme.primary};
  padding: 24px;
  }

}`;
const ImportButton = styled.div`
border: 1px solid ${({ theme }) => theme.primary};
height: 40px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
cursor: pointer;

& > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: ${({ theme }) => theme.primary};
    }
}

&:hover {
    background-color: ${({ theme }) => theme.primary};

    & > div svg {
        fill:white;
    }
}

`;
const ImportButtonLoader = styled.div`
border: 1px solid ${({ theme }) => theme.primary};
height: 40px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
cursor: not-allowed;
background-color: ${({ theme }) => theme.primary};

`;



class VideoInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // urlEntered: 0 < props.url.length,
      extUrl: "",
      errorMsg: null,
      fileUploadSuccess: null,
      storageType: null,
      polling: false,
      toggleMediaPicker: false,
      isMediaExists: false,
      fileName:"",
      loader:false
    };
    this.fileInput = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.module && prevProps.module.id !== this.props.module.id) {
      if (this.props.module && this.props.module.content) {
        this.setState({ extUrl: this.props.module.content.contentUrl });
      }
    }
  };


  handleImageSelect = async (event) => {
    this.setState({ loader: true });
    this.props.fromTestCreation && this.props.setPreviewDisable(true);
    !this.props.fromTestCreation && this.props.updateFileUploading(true);
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      const base64File = reader.result;
  
      this.setState({ loader: false });
      this.props.fromTestCreation && this.props.setPreviewDisable(false);
  
      let fileData = {
        url: base64File,
        contentUrl: base64File,
        contentSource: "external",
        originalUrl: base64File,
        aspectRatio: null,
        saveFile: false,
        length: 0,
        contentName: selectedFile.name,
        sourceName: this.state.fileName,
      };
  
      // You can further process the fileData as needed
      this.uploadSuccessTimer = setTimeout(() => {
        this.setState({ fileUploadSuccess: null });
      }, 3000);
  
      this.props.onChange(fileData);
      !this.props.fromTestCreation && this.props.updateFileUploading(false);
    };
  
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      this.setState({ loader: false, errorMsg: "Error reading file" });
      !this.props.fromTestCreation && this.props.updateFileUploading(false);
    };
  };

  handleUploadSelection = async (files) => {
    this.setState({loader:true});
   
    
    if (!files.previewUrl) {
      this.setState({
        errorMsg: null,
        fileUploading: false,
        fileUploadSuccess: null,
        uploadedData: null,
        extUrl: ''
      });
      this.props.onChange({
        url: "",
        contentUrl: "",
        contentSource: "",
        originalUrl: '',
        uploadedData: null,
        uploadedDataId: null,
        length: 0,
      });
      this.props.updateFileUploading(true);
      return;
    }
    else {

      this.setState({
        errorMsg: null,
        fileUploading: true,
        fileUploadSuccess: true,
        uploadedData: null,
        extUrl: files.previewUrl,
      });
      this.props.onChange({
        url: files.previewUrl,
        contentUrl: files.previewUrl.launchLink ? files.previewUrl.launchLink : files.previewUrl,
        contentSource: "",
        originalUrl: '',
        uploadedData: null,
        uploadedDataId: null,
        length: 1,
      });
      this.props.updateFileUploading(false);
    }
  }
  
  handleFileSelectAndUpload = async (eventOrFiles, rejectedFiles = []) => {
    this.setState({loader:true});
      this.props.fromTestCreation && this.props.setPreviewDisable(true)
      !this.props.fromTestCreation && this.props.updateFileUploading(true);
    let selectedFile;
    if (eventOrFiles.target) {
      selectedFile = eventOrFiles.target.files[0];
    } else {
      selectedFile = eventOrFiles[0];
    }
  
    if (!selectedFile) {
      this.setState({
        errorMsg: null,
        fileUploading: false,
        fileUploadSuccess: null,
        uploadedData: null,
        extUrl: ''
      });
      this.props.onChange({
        url: "",
        contentUrl: "",
        contentSource: "",
        originalUrl: '',
        uploadedData: null,
        uploadedDataId: null,
        length: 0,
      });
      this.props.updateFileUploading(false);
      return;
    }
  
    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > this.props.ctx_user.user.maxPdfSize;
  
    if (isFileTooLarge) {
      this.setState({
        errorMsg: 'File is too large!',
        fileUploading: false,
        fileUploadSuccess: null,
        uploadedData: null,
        extUrl: ''
      });
      this.props.updateFileUploading(false);
      return;
    }
  
    this.setState({
      errorMsg: null,
      fileUploading: true,
      fileUploadSuccess: null,
    });
  
    const data = new FormData();
    data.append("file", selectedFile);
  
    try {
      const { rsp } = await this.props.ctx_api.privateRequest({
        cmd: "upload_file",
        args: data,
        isFileUpload: true,
      });
      this.setState({loader:false})
      this.props.fromTestCreation && this.props.setPreviewDisable(false)
      this.setState({
        urlEntered: 0 < rsp.fileName.length,
        errorMsg: null,
        fileUploading: false,
        fileUploadSuccess: this.props.ctx_labels.singular.fileUploadSuccessfully,
        uploadedData: null,
        extUrl: rsp.fileUrl
      });
  
      this.props.onChange({
        url: rsp.fileName,
        contentUrl: rsp.fileUrl,
        contentSource: rsp.storageType,
        originalUrl: rsp.fileName,
        uploadedData: null,
        uploadedDataId: null,
        length: 1,
        isInvalidUrl: false
      });
  
      !this.props.fromTestCreation && this.props.updateFileUploading(false);
  
      this.uploadSuccessTimer = setTimeout(() => {
        this.setState({ fileUploadSuccess: null });
      }, 3000);
    } catch (error) {
      console.error("Error uploading file:", error);
      this.setState({ errorMsg: "Error uploading file" });
      !this.props.fromTestCreation && this.props.updateFileUploading(false);
    }
  };

  handleEditorChange = (editorContent) => {
    this.setState({ editorContent }); // Update the local state with editor content

    this.props.onChange({
      content: editorContent,

      saveFile: true,

      length: 1,
      isInvalidUrl: false,
    });
  };

  
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  joditConfig = {
    toolbar: false,
    readonly: false,
    height: "235px",
    allowResizeY: false,
    hidePoweredByJodit: true,
    addNewLine: false,
    removeButtons:
      "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,align,size,redo-undo,clean-html,powered-by-jodit,source",
  };

  
  handleUrlChange = ({ value }) => {
    const lengthVal =
    this.props.length === 0
      ? null
      : util.toHHMMSS(this.props.length) + " Mins";
    if(this.props.format === "AUDIO"){
    let regex =
      /^https?:\/\/(?:.+\/)+.+(\.(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm))$/i; // Audio
    if (regex.test(value)) {
      // Audio file logic
      this.setState({
        extUrl: value,
        urlEntered: false,
        errorMsg: null,
        fileUploading: false,
        fileUploadSuccess: null,
        isInvalidUrl: false,
      });
      this.props.onChange({
        url: value,
        originalUrl: value,
        contentUrl: value,
        contentSource: "external",
        saveFile: true,
        length: this.props.length,
        aspectRatio: null,
        isInvalidUrl: false,
        storageType:"external"
      });
    } 
    else {
      // Invalid URL logic
      this.setState({
        extUrl: value,
        urlEntered: false,
        errorMsg: this.props.ctx_labels.singular.missigAudioErr,
        fileUploadSuccess: null,
        isInvalidUrl: true,
      });
      this.props.onChange({
        url: value,
        originalUrl: value,
        contentUrl: value,
        contentSource: "external",
        saveFile: true,
        length: 0,
        aspectRatio: null,
        isInvalidUrl: true,
        fromApi: false,
      });
    }
  }
 else if(this.props.format === "PDF"){
  let regex = /^https?:\/\/(?:.+\/)+.+(\.(pdf))$/i; // PDF
      if (regex.test(value)) {
        // PDF file logic
        this.setState({
          extUrl: value,
          urlEntered: false,
          errorMsg: null,
          uploadedData: null,
          fileUploading: false,
          fileUploadSuccess: null,
        });
        this.props.onChange({
          url: value,
          originalUrl: value,
          contentUrl: value,
          saveFile: this.props.ctx_site.site.saveFile,
          contentSource: "external",
          length: 0,
          isInvalidUrl: false,
        });
      }
      else {
        // Invalid URL logic
        this.setState({
          extUrl: value,
          urlEntered: false,
          errorMsg: this.props.ctx_labels.singular.missingPdfErr,
          fileUploadSuccess: null,
          isInvalidUrl: true,
        });
        this.props.onChange({
          url: value,
          originalUrl: value,
          contentUrl: value,
          contentSource: "external",
          saveFile: true,
          length: 0,
          aspectRatio: null,
          isInvalidUrl: true,
          fromApi: false,
        });
      }
     } 
      else if(this.props.format === "HTML"){
        let regex = /^https?:\/\/(?:.+\/)*([^\/?#]+)?(\.html?|\/?|#.*)?$/i;
            if (regex.test(value)) {
              // PDF file logic
              this.setState({
                extUrl: value,
                urlEntered: false,
                errorMsg: null,
                uploadedData: null,
                fileUploading: false,
                fileUploadSuccess: null,
              });
              this.props.onChange({
                url: value,
                originalUrl: value,
                contentUrl: value,
                saveFile: this.props.ctx_site.site.saveFile,
                contentSource: "external",
                length: 1,
                isInvalidUrl: false,
              });
            }
            else {
              // Invalid URL logic
              this.setState({
                extUrl: value,
                urlEntered: false,
                errorMsg: this.props.ctx_labels.singular.missingHtmlEmbedErr,
                fileUploadSuccess: null,
                isInvalidUrl: true,
              });
              this.props.onChange({
                url: value,
                originalUrl: value,
                contentUrl: value,
                contentSource: "external",
                saveFile: true,
                length: 1,
                aspectRatio: null,
                isInvalidUrl: true,
                fromApi: false,
              });
            }
           } 
           else if(this.props.format === "VIDEO"){
            let regex = /^.*((vimeo)|(dailymotion)|(dai.ly)|(youtube)|(youtu.be)|(facebook)|(soundcloud)|(twitch)|(streamable)|(dailymotion)|(mixcloud)|(wistia)|(loom)).*$/gi;
              if (regex.test(value)) {
                // Video URL logic
                this.setState({
                  extUrl: value,
                  urlEntered: false,
                  errorMsg: "",
                  fileUploadSuccess: null,
                  storageType: null,
                  isInvalidUrl: false,
                });
                this.props.onChange({
                  url: value,
                  originalUrl: value,
                  contentUrl: value,
                  contentSource: "external",
                  saveFile: true,
                  length: this.props.length,
                  aspectRatio: null,
                  isInvalidUrl: false,
                  fromApi: false,
                });
                !this.props.fromTestCreation && this.props.updateFileUploading(false);
              } 
              else {
                // Invalid URL logic
                this.setState({
                  extUrl: value,
                  urlEntered: false,
                  errorMsg: this.props.ctx_labels.singular.missingVideoErr,
                  fileUploadSuccess: null,
                  isInvalidUrl: true,
                });
                this.props.onChange({
                  url: value,
                  originalUrl: value,
                  contentUrl: value,
                  contentSource: "external",
                  saveFile: true,
                  length: 0,
                  aspectRatio: null,
                  isInvalidUrl: true,
                  fromApi: false,
                });
              }
            } else if (this.props.format === 'EMBED'){
              let regex = /<iframe\s+[^>]*src="([^"]+)"[^>]*><\/iframe>|<embed.*?src="(.*?)".*?>|<object.*?data="(.*?)".*?><\/object>/
              if (regex.test(value)) {
                // Video URL logic
                this.setState({
                  extUrl: value,
                  urlEntered: false,
                  errorMsg: "",
                  fileUploadSuccess: null,
                  storageType: null,
                  isInvalidUrl: false,
                });
                this.props.onChange({
                  url: value,
                  originalUrl: value,
                  contentUrl: value,
                  contentSource: "external",
                  saveFile: true,
                  length: 1,
                  aspectRatio: null,
                  isInvalidUrl: false,
                  fromApi: false,
                });
                
              } 
              else {
                // Invalid URL logic
                this.setState({
                  extUrl: value,
                  urlEntered: false,
                  errorMsg: this.props.ctx_labels.singular.missingHtmlEmbedErr,
                  fileUploadSuccess: null,
                  isInvalidUrl: true,
                });
                this.props.onChange({
                  url: value,
                  originalUrl: value,
                  contentUrl: value,
                  contentSource: "external",
                  saveFile: true,
                  length: 1,
                  aspectRatio: null,
                  isInvalidUrl: true,
                  fromApi: false,
                });
              }
            }
           else {
                  if (value) {
                    // PDF file logic
                    this.setState({
                      extUrl: value,
                      urlEntered: false,
                      errorMsg: null,
                      uploadedData: null,
                      fileUploading: false,
                      fileUploadSuccess: null,
                    });
                    this.props.onChange({
                      url: value,
                      originalUrl: value,
                      contentUrl: value,
                      saveFile: this.props.ctx_site.site.saveFile,
                      contentSource: "external",
                      length: 0,
                      isInvalidUrl: false,
                    });
                  }
                  else {
                    // Invalid URL logic
                    this.setState({
                      extUrl: value,
                      urlEntered: false,
                      errorMsg: this.props.ctx_labels.singular.missingHtmlEmbedErr,
                      fileUploadSuccess: null,
                      isInvalidUrl: true,
                    });
                    this.props.onChange({
                      url: value,
                      originalUrl: value,
                      contentUrl: value,
                      contentSource: "external",
                      saveFile: true,
                      length: 0,
                      aspectRatio: null,
                      isInvalidUrl: true,
                      fromApi: false,
                    });
                  }
                 } 
  };

  getFileAcceptType = (format) => {
    switch (format) {
      case "VIDEO":
        return "video/*";
      case "AUDIO":
        return "audio/*";
      case "PDF":
        return ".pdf";
      case "IMAGE":
        return "image/*";
      default:
        return "*";
    }
  };

  renderUrl = () => {
    // let url = this.state.extUrl;
    // this.urlValidator(url);    
    const validation =
      this.state.extUrl && this.state.storageType !== "vimeo"
        ? this.state.errorMsg
        : null;

        const videoUrl= this.props.contentSource !== "aws"?
                        (this.props.url ||
                        this.state.extUrl) : ""

          const { format, ctx_labels } = this.props;

          if (format === 'HTMLEDITOR') {
            return (
              <div id="forTextEditor" >
                {/* <Col md={12}> */}
                  <FormField
                    type={JoditEditor}
                    value={this.props.isContentBuilder &&  this.props.module.content.url}
                    name="description"
                    config={this.joditConfig}
                    editorRef={this.setRef}
                    onChange={this.handleEditorChange}
                    onClick={this.stopPropagation}
                    onMouseDown={this.stopPropagation}
                  />
                {/* </Col> */}
              </div>
            );
          } else if (format === "HTML" || format === "EMBED") {
            return (
              <Row className="tab">
                <Col md={12} >
                  <FormField
                    className="course-text"
                    isCourse
                    maxLength ={config.maxUrlLength}
                    value={this.state.extUrl || this.props.contentUrl }
                    placeholder={ctx_labels.singular.pasteUrl}
                    //  isLoading={isLoading}
                    onChange={this.handleUrlChange}
                    required
                  />
                  {this.state.errorMsg && ( 
                    <div className="error-message">{this.state.errorMsg}</div>
                  )}
                </Col>
               {this.props.module.format === "HTML"  ?
                 <Col md={4} className="new-tab">
                  {this.props.renderNewTab()}
                </Col> : ""}
              </Row>
            );
          } else if (format === "PPT" ) {
            return (
              <Row>
                <Col md={12}>
                  <BlockUpload
                    onChange={this.handleFileSelectAndUpload}
                    uploadType={ "ppt"}
                    minSize={0}
                    value={this.props.url} />
                </Col>
              </Row>
            );
          } 
          else if(format === "SCORM") {
            return (
            <Row>
            <Col md={12}>
            <ScromInput
            onChange={this.props.onChange}
            handleUploadSelection={this.handleUploadSelection}
            isContentBuilder
            scormId= {this.props.id}/>
            </Col>
            </Row>
            )
          }
          else {
            return (
              <Row style={this.props.fromTestCreation ? {} : {marginLeft: "0"}}>
                <Col md={10}  id="urlBox">
                  <FormField
                    className="course-text"
                    name="url"
                    value={videoUrl||this.props.url}
                    onChange={this.handleUrlChange}
                    placeholder={ctx_labels.singular.pasteUrl}
                    maxLength ={config.maxUrlLength}
                    disabled={format === "IMAGE"}
                  />
                  {this.state.errorMsg && ( 
                    <div className="error-message">{this.state.errorMsg}</div>
                  )}
                </Col>
                <Col md={2} id="urlBoxBtn">
                  {this.props.fromTestCreation ?
                  <input
                    type="file"
                    accept={this.getFileAcceptType(format)}
                    onChange={format === "IMAGE" ? this.handleImageSelect : this.handleFileSelectAndUpload }
                    style={{ display: "none" }}
                    ref={this.fileInput}
                  />
                :
                <input
                type="file"
                accept={this.getFileAcceptType(format)}
                onChange={this.handleFileSelectAndUpload}
                style={{ display: "none" }}
                ref={this.fileInput}
              />
                }
                  {this.props.fromTestCreation ?
                 this.state.loader ?
                 <ImportButtonLoader>
                  <div>
                  <MoonLoader size={18} color="white"/>
                </div>
                 </ImportButtonLoader>
                 :
                 <ImportButton>
                  <div 
                   onClick={() => this.fileInput.current.click()}
                   >
                  <IconImportMedia />
                </div>
                </ImportButton>
                  : 
                  <ActionInputCancel>
                  <div className = "upload-btn" onClick={() => this.fileInput.current.click()}>
                  {!  this.state.loader ? <IconImport /> : <MoonLoader size={18} color="blue"/>} 
                    {/* <IconImport /> */}
                  </div>
                  </ActionInputCancel>}
                </Col>
              {!this.props.fromTestCreation &&  this.props.format === "VIDEO" ?
                <Col md={4} >
                  <div className="moduleform-360">
                    {this.props.rendervideo360()}
                  </div>
                </Col> : ""}
              </Row>

            );
          }
        
  
  };
  render = () => {
    return <>{this.renderUrl()}</>;
  };
}
export default withApiContext(
  withLabelsContext(withUserContext(withSiteContext(VideoInput)))
);
