import { OverlayTooltip } from 'components';
import { IconChevronFilter } from 'Icons';
import React, { useState, useRef, useEffect } from 'react';
import './CustomSelect.css';

const Select = ({ options, placeholder, onChange, required }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(required ? options[0] : null);
    const [searchQuery, setSearchQuery] = useState('');
    const containerRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (required && options.length > 0 && selectedOption === null) {
            setSelectedOption(options[0]);
            onChange(options[0]);
        }
    }, [required, options, onChange]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClearSelection = (event) => {
        event.stopPropagation(); // Prevent triggering the toggle
        setSelectedOption(null);
        onChange(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="custom-select-container" ref={containerRef}>
            {placeholder.length > 15 ? (
                <OverlayTooltip tip={placeholder} placement="top">
                    <div className="custom-select-header" onClick={handleToggle} tabIndex={0}>
                        <span className="custom-select-header-text">
                            {selectedOption ? selectedOption.label : placeholder}
                        </span>
                        {selectedOption && !required && (
                            <button className="custom-select-clear" onClick={handleClearSelection}>
                                ✕
                            </button>
                        )}
                        <span className="custom-select-arrow" onClick={handleToggle}>
                            <IconChevronFilter />
                        </span>
                    </div>
                </OverlayTooltip>
            ) : (
                <div className="custom-select-header" onClick={handleToggle} tabIndex={0}>
                    <span className="custom-select-header-text">
                        {selectedOption ? selectedOption.label : placeholder}
                    </span>
                    {selectedOption && !required && (
                        <button className="custom-select-clear" onClick={handleClearSelection}>
                            ✕
                        </button>
                    )}
                    <span className="custom-select-arrow" onClick={handleToggle}>
                        <IconChevronFilter />
                    </span>
                </div>
            )}
            {isOpen && (
                <div className="custom-select-options">
                    <input
                        type="text"
                        className="custom-select-search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div className="custom-select-options-list">
                        {filteredOptions.map((option) => (
                            <div
                                key={option.value}
                                className="custom-select-option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Select;
