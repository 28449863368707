import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Button, Col, Row, Modal } from "react-bootstrap";
import Pdf from "components/PlayerNew/Pdf";
import "./SurveyGeneral.css";
import {
  Breadcrumb,
  CardGrid,
  HelpfulButton,
  JustifyBetween,
  OverlayTooltip,
  Select,
  Toolbar,
  ToolbarSort,
} from "components";
import { withApiContext, withLabelsContext, withUserContext } from "context";
import * as config from "config";
import { Link } from "react-router-dom";
import {
  IconViewSurvey,
  IconCalender,
  IconSurveyClock,
  IconSurveyInstruction,
  IconSurveyGeneralStart,
  IconSurveyGeneralExit,
} from "Icons";
import { MainContainer } from "pages/Main";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Survey from "pages/Survey/Survey";

const StyledRow = styled(Row)`
  && {
    .date-year {
      background-color: ${({ theme }) =>
        theme.color2}; // Use 'background-color' instead of 'backgroundColor'
    }
  }
`;

class SurveyGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      survey: null,
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.callSurvey) {
      this.callSurvey();
    }
  }

  callSurvey = async () => {
    try {
      const response = await this.props.ctx_api.privateRequest({
        cmd: this.props.callSurvey
          ? "get_survey_dtl"
          : "get_publish_survey_details_2",
        args: { testId: this.props.survey.videoUnitId },
      });
      const sanitizedResponse = this.sanitizeSurveyResponse(response);
      this.setState({
        survey: sanitizedResponse,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching survey:", error);
      this.setState({ loading: false });
    }
  };

  sanitizeSurveyResponse(response) {
    if (!Array.isArray(response?.rsp) || response?.rsp.length === 0) {
      console.log("??", response);
      return null;
    }

    const survey = response.rsp[0];
    console.log("===", survey, survey?.questionlist);
    return {
      survey_id: survey.survey_id || "",
      description: survey.descriptions || this.props.survey.summary || "",
      name: survey.survey_name || "",
      short_name: survey.survey_short_name || "",
      survey: survey.survey || [],
      status: survey.status || "",
      newStartDate: survey.newStartDate || "",
      newEndDate: survey.newEndDate || "",
      submittedOn: survey.submittedOn || "",
      totalQuestion: survey?.questionlist[0]?.questions?.length,
      action: survey.action || 1,
    };
  }

  render = () => {
    const { location, theme } = this.props;
    const survey = this.props.callSurvey
      ? this.state.survey
      : this.props.survey;

    const whereFrom = this.props.callSurvey
      ? { from: "course", fromSurvey: true, fromView: survey?.completed }
      : {};

    const { state } = location;
    const { survey: surveyState } = state;
    const formatDateTime = (dateString) => {
      console.log("date issued", dateString);
      if (dateString) {
        const date = new Date(dateString);

        // Get day of the month
        const day = date.getUTCDate().toString().padStart(2, "0");

        // Get month
        const months = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        const month = months[date.getUTCMonth()];

        // Get year
        const year = date.getUTCFullYear();

        // Get time in 12-hour format
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Format the time
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        // Combine all parts into the desired format
        return `${day}-${month}-${year}`;
      }
    };

    //const formattedDateTime = formatDateTime("2024-08-01 10:40:00");
    // console.log(formattedDateTime); // "01-AUG-2024"

    const {
      newStartDate = formatDateTime(surveyState.start_date),
      newEndDate = formatDateTime(surveyState.end_date),
      description = this.props.survey.summary,
      name,
    } = surveyState;
    console.log("...state", survey);
    const [day, month, year] = newStartDate
      ? newStartDate.split("-")
      : ["", "", ""];
    const [day1, month1, year1] = newEndDate
      ? newEndDate.split("-")
      : ["", "", ""];
    const screenSize = window.screen.width;

    const { submittedOn, status, action, completed } = survey || {};
    const [submittedDate, submittedTime] = submittedOn
      ? submittedOn.split("|")
      : ["", ""];

    console.log("status--", status);

    return survey ? (
      <MainContainer>
        <MediaQuery minDeviceWidth={1030}>
          <StyledRow
            style={{ display: "flex", justifyContent: "space-between" }}
            className={
              description ? "description-aligns" : "description-align-d"
            }
          >
            <Col md={7} className={screenSize > 821 ? "border-line" : ""}>
              <Row style={{ padding: "10px", marginTop: "15px" }}>
                <Col style={{ marginTop: "-90px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    {name}
                  </div>
                  <div className="desktopview-line"></div>
                </Col>
              </Row>
              <Row className="date-div">
                <div
                  style={{ display: "flex", justifyContent: "space-between" ,gap:"2rem"}}
                >
                  <div>
                    <div
                      style={{
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                    >
                      <div className="start-date">
                        Start Date
                        <div className="box-align">
                          <StyledRow className="date-year">
                            <div>{month}</div>
                            <div>{year}</div>
                          </StyledRow>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "5px",
                              fontSize: "20px",
                            }}
                          >
                            {day}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="survey-general">
                    <div className="total-question">Total Question</div>
                    <div
                      className={
                        screenSize > 821 ? "survey-align-k" : "survey-mobalign"
                      }
                    >
                      {this.state.survey?.totalQuestion
                        ? this.state.survey?.totalQuestion
                        : survey.survey[0]?.questions.length}
                    </div>
                  </div>
                  <div className="survey-attend">
                    <div className="start-date">
                      End Date
                      <div className="box-align">
                        <Row className="date-year">
                          <div>{month1}</div>
                          <div>{year1}</div>
                        </Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px",
                            fontSize: "20px",
                          }}
                        >
                          {day1}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="instruction-div">
                {description && (
                  <div style={{ fontWeight: "600", fontSize: "16px" }}>
                    Instructions
                  </div>
                )}
                <div
                  className="instruction-description"
                  style={
                    description?.length > 500
                      ? { height: "200px" }
                      : { height: "" }
                  }
                >
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  )}
                </div>
              </Row>
              <Row className="button-div">
                <div>
                  {status === "Attended" ||
                  status === "In Progress" ||
                  completed ||
                  this.props.survey?.revisit ? (
                    <Link
                      to={{
                        pathname: config.ROUTES.surveyResponse,
                        state: { ...state, ...whereFrom },
                      }}
                    >
                      <div>
                        {status === "Attended" ||
                        completed ||
                        this.props.survey?.revisit ? (
                          <Button className="button-play">
                            <IconViewSurvey className="custom-icon" />
                            View
                          </Button>
                        ) : (
                          <Button className="button-play">
                            <IconSurveyGeneralStart className="custom-icon" />
                            Resume
                          </Button>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname:
                          action !== 1 ? "" : config.ROUTES.surveyAttend,
                        state: { ...state, ...whereFrom },
                        filter: this.props.filter,
                      }}
                    >
                      <div>
                        <HelpfulButton
                          className="button-play"
                          tip={`Assessment will be active on\n${newStartDate}`}
                          placement="top"
                          // disabled={action !== 1}
                        >
                          <IconSurveyGeneralStart className="custom-icon" />
                          Start
                        </HelpfulButton>
                      </div>
                    </Link>
                  )}
                </div>
                <div>
                  <Link
                    to={{
                      pathname:
                        this.props.from === "course"
                          ? this.props.survey.origin
                            ? this.props.survey.origin
                            : config.ROUTES.traineeCurriculum
                          : config.ROUTES.studentSurvey,
                      subjectData: this.props.subjectData,

                      state: { ...state, ...whereFrom },
                      filter: this.props.filter,
                    }}
                  >
                    <div>
                      <Button className="button-exit">
                        <IconSurveyGeneralExit className="custom-icon" />
                        Exit
                      </Button>
                    </div>
                  </Link>
                </div>
              </Row>
            </Col>
            <MediaQuery minDeviceWidth={1030}>
              <Col
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "80px",
                  width: "100%",
                }}
              >
                <Row>
                  <div>
                    <IconSurveyInstruction />
                  </div>
                  {submittedOn && (
                    <div style={{ padding: "21px 0px 0px 50px" }}>
                      <div className="attended-det">
                        <div className="attended-det-box">Attended at:</div>
                        <div
                          className="attended-cal-det-box"
                          style={{ color: theme.color3 }}
                        >
                          <IconCalender
                            className="icon-svg"
                            stroke={theme.color3}
                            fill="white"
                          />
                          &nbsp;&nbsp;
                          <div className="attended-box">{submittedDate}</div>
                        </div>
                        <div
                          className="attended-duration-det-box"
                          style={{ color: theme.color3 }}
                        >
                          <IconSurveyClock
                            className="icon-svg"
                            stroke={theme.color3}
                            fill="white"
                          />
                          &nbsp;&nbsp;
                          {submittedTime}
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </Col>
            </MediaQuery>
          </StyledRow>
        </MediaQuery>
        <MediaQuery minDeviceWidth={321} and maxDeviceWidth={1029}>
          <div
            className={description ? "description-align" : "description-align"}
          >
            <Row
              style={{
                fontWeight: "bold",
                display: "contents",
                marginTop: "20px",
              }}
            >
              <div className="mob-header">{name}</div>
              <div className="mobileview-line"></div>
            </Row>
            <StyledRow>
              <Col md={12}>
                <Row className="date-div">
                  <div className="mob-start-date">
                    <div className="start-date">Start Date</div>
                    <div className="box-align-mob">
                      <StyledRow className="date-year">
                        <div>{month}</div>
                        <div>{year}</div>
                      </StyledRow>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "5px",
                          fontSize: "20px",
                        }}
                      >
                        {day}
                      </div>
                    </div>
                  </div>
                  <div className="mob-end-date">
                    <div className="start-date">End Date</div>
                    <div className="box-align-mob">
                      <StyledRow className="date-year">
                        <div>{month1}</div>
                        <div>{year1}</div>
                      </StyledRow>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "5px",
                          fontSize: "20px",
                        }}
                      >
                        {day1}
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="mob-button">
                  <div className="survey-general">
                    <div className="total-question">Total Questions</div>
                    <div className="survey-align-k">
                    {this.state.survey?.totalQuestion
                        ? this.state.survey?.totalQuestion
                        : survey.survey[0]?.questions.length}
                    </div>
                  </div>
                </Row>
              </Col>
            </StyledRow>
            <Row className="button-div">
              <div>
                {status === "Attended" ||
                status === "In Progress" ||
                completed ||
                this.props.survey?.revisit ? (
                  <Link
                    to={{
                      pathname: config.ROUTES.surveyResponse,
                      state: { ...state, ...whereFrom },
                    }}
                  >
                    <div>
                      {status === "Attended" ||
                      completed ||
                      this.props.survey?.revisit ? (
                        <Button className="button-play">
                          <IconViewSurvey className="custom-icon" />
                          View
                        </Button>
                      ) : (
                        <Button className="button-play">
                          <IconSurveyGeneralStart className="custom-icon" />
                          Resume
                        </Button>
                      )}
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: action !== 1 ? "" : config.ROUTES.surveyAttend,
                      state: { ...state, ...whereFrom },
                      filter: this.props.filter,
                    }}
                  >
                    <div>
                      <HelpfulButton
                        className="button-play"
                        tip={
                          action !== 1 &&
                          `Assessment will be active on\n${newStartDate}`
                        }
                        placement="top"
                        disabled={action !== 1}
                      >
                        <IconSurveyGeneralStart className="custom-icon" />
                        Start
                      </HelpfulButton>
                    </div>
                  </Link>
                )}
              </div>
              <div>
                <Link
                  to={{
                    pathname:
                      this.props.from === "course"
                        ? this.props.survey.origin
                          ? this.props.survey.origin
                          : config.ROUTES.traineeCurriculum
                        : config.ROUTES.studentSurvey,

                    subjectData: this.props.subjectData,
                    state: { ...state, ...whereFrom },
                    filter: this.props.filter,
                  }}
                >
                  <div>
                    <Button className="button-exit">
                      <IconSurveyGeneralExit className="custom-icon" />
                      Exit
                    </Button>
                  </div>
                </Link>
              </div>
            </Row>
            <Row className="instruction-div">
              {description && (
                <div style={{ fontWeight: "600", fontSize: "16px" }}>
                  Instructions
                </div>
              )}
              <div
                className="instruction-description"
                style={
                  description?.length > 500
                    ? { height: "200px" }
                    : { height: "" }
                }
              >
                {description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                )}
              </div>
            </Row>
            <MediaQuery minDeviceWidth={321} and maxDeviceWidth={1029}>
              <Col
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "50px",
                  width: "100%",
                }}
              >
                <Row>
                  <div>
                    <IconSurveyInstruction />
                  </div>
                  {submittedOn && (
                    <div style={{ padding: "21px 0px 0px 50px" }}>
                      <div className="attended-det">
                        <div className="attended-det-box">Attended at:</div>
                        <div
                          className="attended-cal-det-box"
                          style={{ color: theme.color3 }}
                        >
                          <IconCalender
                            className="icon-svg"
                            stroke={theme.color3}
                            fill="white"
                          />
                          &nbsp;&nbsp;
                          <div className="attended-box">{submittedDate}</div>
                        </div>
                        <div
                          className="attended-duration-det-box"
                          style={{ color: theme.color3 }}
                        >
                          <IconSurveyClock
                            className="icon-svg"
                            stroke={theme.color3}
                            fill="white"
                          />
                          &nbsp;&nbsp;
                          {submittedTime}
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </Col>
            </MediaQuery>
          </div>
        </MediaQuery>
      </MainContainer>
    ) : (
      <>
        <h1></h1>
      </>
    );
  };
}

export default withRouter(
  withApiContext(withLabelsContext(withUserContext(SurveyGeneral)))
);
