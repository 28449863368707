import React from 'react';
import PropTypes from 'prop-types';

import { Button} from 'react-bootstrap';
// , Glyphicon

// import OverlayTooltip from 'components/OverlayTooltip';


const ReviewLink = ({
  id,
  onClick,
}) => (
  // <OverlayTooltip
  //   tip="Review this material"
  //   id={id}
  // >
    <Button
      className="test-answer-review-button"
      bsStyle="primary"
      onClick={onClick}
    >
      {/* <Glyphicon glyph="share-alt" className="mirrored-x" /> */}
    </Button>
  // {/* </OverlayTooltip> */}
  );

ReviewLink.propTypes = {
  id:      PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default ReviewLink
