import { Button } from "components";
import { Icon6Dots } from "Icons";
import DragAndDrop from "pages/ContentBuilder/DragAndDrop";
import { rgba } from "polished";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { TestContext } from "./Context/TestContex";
import css from "./testCreation.module.scss";
function QuestionReorder(props) {
  const theme = useTheme();
  const {
    testData,
    setTestData,
    resetTestData,
    question,
    setQuestion,
    sections,
    responseSetter,
    sectionSetter,
    restQuestion,
    setSections,
    testFeedback,
    setTestFeedback,
  } = useContext(TestContext);
  const [selectedSection, setSelectedSection] = useState([]);
  const inlineBackground = {
    "--bg-color-1": theme.primary,
    "--bgColor2":rgba(theme.primary, 0.109)
  };
  useEffect(() => {
    const selectedSectionQue = sections.find(
      (section) => section.section_order === props.sectionOrder
    );
    setSelectedSection(selectedSectionQue.questions)
  }, [props.sectionOrder]);
  // const selectedSection = sections.find(
  //   (section) => section.section_order === props.sectionOrder
  // );

  const renderReorder = (que,index) => {
    return(
      <>
      <div
        key={index}
        className={css.addquestionCard}
        style={inlineBackground}
      >
        <Icon6Dots />  
        <div
        className={css.queOrderNo}

        >
          {que.order}  </div>
        <div className={css.addQue}> {que.question}</div>
      </div>
        <div className={css.horizontalLine} style={inlineBackground}></div>
      </>
    )
  }
  const handleUpdate = (data) => {
    const resetOrder = data.map((question, index) => ({
      ...question,
      order: index + 1
    }));
    setSelectedSection(resetOrder);
  };
const handleSave = () => {
  const updatedSections = sections.map((section) => {
    if (section.section_order === props.sectionOrder) {
      return {
        ...section,
        questions: selectedSection
      };
    }

    return section;
  });
  setSections(updatedSections)
}
  return (
    <div className={css.sectionReorder}>
    <div style={{marginLeft: "-25px"}}>
    {selectedSection.length > 0 ? 
      <DragAndDrop
       items={selectedSection}
       render={renderReorder}
       onUpdate={(data)=>handleUpdate(data)}
       customId={"question"}
      /> :
      <div style={{marginLeft: "25px"}}> Add or Create Question </div>
      }
    </div>
    <div className={css.questionButton} style={ selectedSection?.length < 5 ? {position: "absolute",bottom: "0",right: "0"}:{}}>
    <Button
      className={css.rectangleButton}
      style={{
        backgroundColor: theme.primary,
        borderColor: theme.primary,
      }}
      disabled={selectedSection.length <= 0}
      onClick={handleSave}
    >
      Done
    </Button>
    <Button
      className={css.rectangleButton}
      variant="btn-outline-cancel"
      onClick={props.handleReset}
    >
      Cancel
    </Button>
  </div>
  </div>
  );
}

export default QuestionReorder;
