import { withLabelsContext } from "context";
import React from "react";
import { Card } from "react-bootstrap";
import css from "./filter-dropdown.module.scss";
import classNames from "classnames";


const FilterPanel = (props) => {
	return (
		<Card
			body
			border="primary"
			className={classNames(css.panel, props.className ?? "")}
		>
			{props.children}
		</Card>
	);
};

export default withLabelsContext(FilterPanel)