import { Select } from "components";
import { withApiContext, withUserContext } from "context";
import { IconTagClose } from "Icons";
import { rgba } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import css from "./courseTag.module.scss";
function CourseTagSelect(props) {
  const { subject, standard, Id } = props;
  const subjects = props.ctx_user.user.campusSubj;
  const standards = props.ctx_user.user.campusCategories;
  const [taggedCourses, setTaggedCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [selectedTaggedCourses, setSelectedTaggedCourses] = useState([]);
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": rgba(theme.primary, 0.5),
  };
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevSelectedTaggedCourses = usePrevious(selectedTaggedCourses);
  const prevAvailableCourses = usePrevious(availableCourses);
  useEffect(() => {
    setSelectedTaggedCourses([]);
    fetchTaggedCourses();
  }, [standard,subject]);

  // useEffect(() => {
  //   if (
  //     prevSelectedTaggedCourses !== selectedTaggedCourses ||
  //     prevAvailableCourses !== availableCourses
  //   ) {
  //     const updateAvailableCourses = () => {
  //       setAvailableCourses((prev) =>
  //         prev.filter(item => !selectedTaggedCourses.includes(item.value))
  //       );
  //     };

  //     updateAvailableCourses();
  //   }
  // }, [selectedTaggedCourses, availableCourses, prevSelectedTaggedCourses, prevAvailableCourses]);

  useEffect(() => {
    if (
      prevSelectedTaggedCourses !== selectedTaggedCourses ||
      prevAvailableCourses !== availableCourses
    ) {
      const updateAvailableCourses = () => {

        const newFilteredCourses = availableCourses.filter(item => !selectedTaggedCourses.includes(item.value));

        // Update state only if the new filtered courses are different from the current state
        setAvailableCourses(prev => {
          if (JSON.stringify(prev) !== JSON.stringify(newFilteredCourses)) {
            return newFilteredCourses;
          }
          return prev;
        });
      };

      updateAvailableCourses();
    }
  }, [selectedTaggedCourses, availableCourses, prevSelectedTaggedCourses, prevAvailableCourses]);

  useEffect(() => {
    if(props.isEdit === true){
      const values = props.value.map(item => item.value); 
    setSelectedTaggedCourses (props.value);
    props.setSelectedTaggedCourses(props.value)
  }
  if(props.value?.length<=0){
    setSelectedTaggedCourses([])
  }
  }, [props.isEdit,props.value]);

  const fetchTaggedCourses = async () => {
    const subjectId = subjects.find((item) => item.value === subject);
    const standardId = standards.find((item) => item.value === standard);
    try {
      if (subjectId && standardId) {
        const { rsp } = await props.ctx_api.privateRequest({
          cmd: "get_tagged_courses",
          args: {
            categoryId: standardId?.value,
            curriculumId: subjectId?.value,
            Id: Id,
          },
        });

        const courses = rsp.map(({ course_id, course_name }) => ({
          label: course_name,
          value: course_id,
        }));

        setTaggedCourses(courses);
        setAvailableCourses(courses);
      }
    } catch (error) {
      console.error("Error fetching tagged courses:", error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    const selectedTag = taggedCourses.find(
      (item) => item.value === selectedOption
    );
    
    const selectedValue = selectedTag ? selectedTag.value : null;
    if(props.isEdit && props.isEdit === true){
      setSelectedTaggedCourses((prev)=>{
        return[...prev, selectedValue]
      });
      props.setSelectedTaggedCourses((prev)=>{
        return[...prev, selectedValue]
      });
    }
    else {   
    setSelectedTaggedCourses([...selectedTaggedCourses, selectedValue]);
    props.setSelectedTaggedCourses([...selectedTaggedCourses, selectedValue])
    }
    
  };

  const handleClear = (optionToRemove) => {
    const courseToRemove = selectedTaggedCourses.find(
      (course) => course === optionToRemove
    );
    const foundObject = taggedCourses.find(item => item.value === optionToRemove);
    if (courseToRemove) {
      setSelectedTaggedCourses((prev) =>
        prev.filter((course) => course !== optionToRemove)
      );
      props.setSelectedTaggedCourses((prev) =>
        prev.filter((course) => course !== optionToRemove)
      );
      setAvailableCourses((prev) => [foundObject, ...prev]);
    }
  };
  return (
    <div>
      <label className={css.testLables}>Course Tag</label>
      <div>
        <Select
          options={availableCourses}
          onChange={handleSelectChange}
          placeholder="Course Tag"
        />
      </div>
      <div className={css.taggedWrap}>
        {selectedTaggedCourses.map((item, index) => {
          const foundObject = taggedCourses.find(items => items.value === item);
          const label = foundObject&&foundObject.label
          return (
            <div
              key={index}
              className={css.taggedCourse}
              style={inlineBackground}
            >
              {label}{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleClear(item)}
              >
                &nbsp; <IconTagClose />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withUserContext(withApiContext(CourseTagSelect));
