import * as config from 'config';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Fade } from 'react-bootstrap';
import * as util from 'services/util';




class DelayedFade extends PureComponent {
  constructor(props) {
    super(props);
    let timeout = null;
    let state = 'out';
    let timeoutId = 0;
    if (props.in) {
      state = 'fadeInPending';
      timeoutId = timeoutId + 1;
      timeout = setTimeout(this.transition(timeoutId), props.inDelay);
    }
    this.state = {
      state,
      timeout,
      timeoutId,
    };
  }

  static propTypes = {
    in:          PropTypes.bool.isRequired,
    inDelay:     PropTypes.number.isRequired,
    inInterval:  PropTypes.number.isRequired,
    outDelay:    PropTypes.number.isRequired,
    outInterval: PropTypes.number.isRequired,
    children:    PropTypes.element.isRequired,
  };

  static defaultProps = {
    inDelay:     config.defaultFadeInDelay,
    inInterval:  config.defaultFadeInInterval,
    outDelay:    config.defaultFadeOutDelay,
    outInterval: config.defaultFadeOutInterval,
  };

  transition = (issuedTimeoutId) => () => {
    this.setState((s0) => {
      let state = s0.state;
      let timeout = s0.timeout;
      if (issuedTimeoutId === s0.timeoutId) { // this is the latest timeout
        timeout = null;
        switch (s0.state) {
          case 'fadeInPending':  state = 'fadingIn' ; break;
          case 'fadeOutPending': state = 'fadingOut'; break;
          default:                                    break;
        }
      }
      return { state, timeout };
    });
  }

  componentWillReceiveProps = (newProps) => {
    let state = null;
    let delay = null;
    if (newProps.in && !this.props.in) {
      state = 'fadeInPending';
      delay = newProps.inDelay;
    } else if (!newProps.in && this.props.in) {
      if (newProps.outDelay === 0) {
        // Ensure the Fade is unmounted immediately
        state = newProps.outInterval === 0 ? 'out' : 'fadingOut';
      } else {
        state = 'fadeOutPending';
        delay = newProps.outDelay;
      }
    }
    if (state !== null) {
      this.setState((s0) => {
        const timeoutId = s0.timeoutId + 1;
        util.cancelTimeout(s0.timeout);
        const timeout = delay === null ? null :
          setTimeout(this.transition(timeoutId), delay);
        return { state, timeout, timeoutId };
      });
    }
  }

  componentWillUnmount = () => {
    util.cancelTimeout(this.state.timeout);
  }

  handleFadedIn = () => {
    this.setState({ state: 'in' });
  }

  handleFadedOut = () => {
    this.setState({ state: 'out' });
  }

  render = () => {
    const {
      inDelay,
      inInterval,
      outDelay,
      outInterval,
      children,
      ...fadeProps
    } = this.props;

    const s = this.state.state;
    const mounted = s !== 'out';
    const visible = ['fadingIn', 'in', 'fadeOutPending'].indexOf(s) !== -1;
    const interval = s === 'fadingIn' ? inInterval : outInterval;
    return mounted && (
      <Fade
        {...fadeProps}
        in={visible}
        timeout={interval}
        onEntered={this.handleFadedIn}
        onExited={this.handleFadedOut}
      >
        {children}
      </Fade>
    );
  }
}


export default DelayedFade
