import { OverviewCard, PageTitle, JustifyBetween } from "components";
import { withApiContext, withLabelsContext, withUserContext } from "context";
import { MainContainer } from "pages/Main";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FormField from "components/FormField";
import ChartDoughnut from "components/ChartDoughnut/ChartDoughnut";
import styled from "styled-components";
import SubjectStatus from "./SubjectStatus";
import AssessmentStatus from "./AssessmentStatus";
import VCStatus from "./VCStatus";
import Select from "components/Select/Select";
import WeeklyTimeConsumption from "./WeeklyTimeConsumption";
import ActiveTimeConsumption from "./ActiveTimeConsumption";
import { useTheme } from "styled-components";

function DashboardParent(props) {
  const [ownCourses, setOwnCourses] = useState([]);
  const [ownAssessments, setOwnAssessments] = useState([]);
  const [ownSessions, setOwnSessions] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [intentData, setIntentData] = useState([]);
  const [childSelect, setChildSelect] = useState("");
  const [childData, setChildData] = useState([]);
  const [child, setChild] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if(props.ctx_user.user.type === 1){
    getParent1Data();
    setLeaderboardData(props.dependantLeaderboardData);
    }
  }, [props.dependantLeaderboardData]);

  
useEffect(() => {
  if(props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1){
  setLeaderboardData(props.dependantLeaderboardData);
  setIntentData(props.dependantIntentData);
  }
},[props.dependantLeaderboardData,props.dependantIntentData]);

useEffect(() => {
  if(props.ctx_user.user.type === 1 ){
    getParent2Data();
    setIntentData(props.dependantIntentData);
  }
}, []);

useEffect(() => {
  if(props.ctx_user.user.type === 1 ){
    getParent3Data();
    setOwnCourses(props.dependantCourseData);
    setOwnAssessments(props.dependantAssessmentData);
    setOwnSessions(props.dependantSessionData);
  }
}, []);

  useEffect(() => {
    props.ctx_user.user.type === 4 &&
    setLeaderboardData(props.dependantLeaderboardData);
    setIntentData(props.dependantIntentData);
    setOwnCourses(props.dependantCourseData);
    setOwnAssessments(props.dependantAssessmentData);
    setOwnSessions(props.dependantSessionData);
    setChildSelect(props.dependantSelect);
    setChildData(props.dependantData);
    setChild(props.dependant);
  }, [
    props.dependantLeaderboardData,
    props.dependantIntentData,
    props.dependantCourseData,
    props.dependantAssessmentData,
    props.dependantSessionData,
    props.dependantSelect,
    props.dependantData,
    props.dependant,
  ]);

  const getParent1Data = () => {
    if((props.ctx_user.user.type===1 && props.dependantLeaderboardData.length===0)|| props.ctx_user.user.type===4||props.ctx_user.user.type===3)
    props.getParentLeaderboardData();
  };

  const getParent2Data = () => {
    if((props.ctx_user.user.type===1 && props.dependantIntentData.length===0)|| props.ctx_user.user.type===4||props.ctx_user.user.type===3)
    props.getIntentData();
 };

  const getParent3Data = () => {
    //if((props.ctx_user.user.type===1 && props.dependantIntentData.length===0)|| props.ctx_user.user.type===4||props.ctx_user.user.type===3)
    props.getParentDashboardData();
  };

  const subProgress = (statusCount, totalCount) => {
 
    const data = {
      labels: [`${props.ctx_labels.singular.courseAttnd}`, `${props.ctx_labels.singular.courseAsgnd}`],
      datasets: [
        {
          label: "# of Votes",
          data: [statusCount, totalCount],
          backgroundColor: [theme.primary,theme.color3],
          borderColor: [theme.primary, theme.color3],
          borderWidth: 1,
        },
      ],
    };
    const legend = [
      {
        label: `${props.ctx_labels.singular.courseAttnd} ${statusCount} `,
        color: theme.primary,
      },
      {
        label: `${props.ctx_labels.singular.courseAsgnd} ${totalCount} `,
        color: theme.color3,
      },
    ];
    const StyledCenter = styled.div`
      font-size: 23px;
      line-height: 1;
      text-align: center;
      small {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
      }
    `;
    const center = (
      <StyledCenter>
        <small>
          {statusCount}/{totalCount}
        </small>
      </StyledCenter>
    );
    return <ChartDoughnut data={data} legend={legend} center={center} />;
  };

  const assessmentProgress = (statusCount, totalCount) => {
    // const theme = useTheme();
    const data = {
      labels: [`${props.ctx_labels.singular.assmntAttnd}`, `${props.ctx_labels.singular.assmntAsgnd}`],
      datasets: [
        {
          label: "# of Votes",
          data: [statusCount, totalCount],
          backgroundColor: [theme.primary,theme.color3],
          borderColor: [theme.primary,theme.color3],
          borderWidth: 1,
        },
      ],
    };
    const legend = [
      {
        label: `${props.ctx_labels.singular.assmntAttnd} ${statusCount}`,
        color: theme.primary,
      },
      {
        label: `${props.ctx_labels.singular.assmntAsgnd} ${totalCount}`,
        color: theme.color3,
      },
    ];
    const StyledCenter = styled.div`
      font-size: 23px;
      line-height: 1;
      text-align: center;
      small {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
      }
    `;
    const center = (
      <StyledCenter>
        <small>
          {statusCount}/{totalCount}
        </small>
      </StyledCenter>
    );
    return <ChartDoughnut data={data} legend={legend} center={center} />;
  };

  const sessionProgress = (statusCount, totalCount) => {
    // const theme = useTheme();
    const data = {
      labels: [`${props.ctx_labels.singular.sessAttnd}`, `${props.ctx_labels.singular.sessAsgnd}`],
      datasets: [
        {
          label: "# of Votes",
          data: [statusCount, totalCount],
          backgroundColor: [theme.primary,theme.color3],
          borderColor: [theme.primary,theme.color3],
          borderWidth: 1,
        },
      ],
    };
    const legend = [
      {
        label: `${props.ctx_labels.singular.sessAttnd} ${statusCount}`,
        color: theme.primary,
      },
      {
        label: `${props.ctx_labels.singular.sessAsgnd} ${totalCount}`,
        color: theme.color3,
      },
    ];
    const StyledCenter = styled.div`
      font-size: 23px;
      line-height: 1;
      text-align: center;
      small {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
      }
    `;
    const center = (
      <StyledCenter>
        <small>
          {statusCount}/{totalCount}
        </small>
      </StyledCenter>
    );
    return <ChartDoughnut data={data} legend={legend} center={center} />;
  };

  const dependantName = childSelect
    ? childSelect
    : props.studentDetail?props.userData
    : props.ctx_user.user.dependantName;
  const childName = childSelect
    ? childSelect
    : props.ctx_user.user.dependantName
    ? props.ctx_user.user.dependantName
    : props.ctx_user.user.firstName;
    
  const mkGreetingMsg = () => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    if (hrs < 12) return props.ctx_labels.singular.goodMorning;
    else if (hrs >= 12 && hrs <= 17)
      return props.ctx_labels.singular.goodAfternoon;
    else if (hrs >= 17 && hrs <= 24)
      return props.ctx_labels.singular.goodEvening;
  };

  return (
    <MainContainer>
      <Row>
        <Col>
          <JustifyBetween>
            {props.ctx_user.user.type === 4 && (
              <PageTitle>
                {mkGreetingMsg()}, {props.name}
              </PageTitle>
            )}
          </JustifyBetween>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          {props.ctx_user.user.type === 4 ? (
            <div
              className="text-primary-dark"
              style={{ marginTop: "10px", fontSize: "15px", fontWeight: "500" }}
            >
              Details of {dependantName}
            </div>
          ) :(props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) ? (
            <div
              className="text-primary-dark"
              style={{ marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}
            >
              Details of {dependantName}
            </div>
          ) :(
            <div
              className="text-primary-dark"
              style={{
                marginTop: "10px",
                fontSize: "15px",
                fontWeight: "500",
                marginBottom: "10px",
              }}
            >
            <div style={{ display: (props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) ? 'none' : 'block' }}>
                <PageTitle>
                  {(props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) ? '' : `${props.ctx_labels.singular.hey} ${childName}${","}`}
                </PageTitle>
            </div>
            </div>
          )}
        </Col>
        <Col md={6}>
          {props.ctx_user.user.type === 4 && (
            <div style={{ float: "right", width: "45%" }}>
              <FormField
                name="childSelect"
                type={Select}
                options={childData}
                placeholder={`Select child.....`}
                onChange={props.handleChildChange}
                value={child}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xl={4} sm={6} className="mb-3">
          <OverviewCard>
            <Row>
              <Col>
                <div
                  style={{
                    fontSize: "50",
                    display: "block",
                    textAlign: "left",
                    marginTop: "10",
                    width: "350px"
                  }}
                >
                  <p>{`${props.ctx_labels.singular.curriculum} ${props.ctx_labels.singular.progress}`}</p>
                </div>
              </Col>
              <Col>
                {ownCourses && ownCourses.length > 0
                  ? subProgress(
                      ownCourses[0].completed_status_count,
                      ownCourses[0].totcnt
                    )
                  : subProgress(0, 0)}
              </Col>
            </Row>
          </OverviewCard>
        </Col>
        <Col xl={4} sm={6} className="mb-3">
          <OverviewCard>
            <Row>
              <Col>
                <div
                  style={{
                    fontSize: "50",
                    display: "block",
                    textAlign: "left",
                    marginTop: "10",
                    width: "350px"
                  }}
                >
                  <p>{`${props.ctx_labels.singular.assessment} ${props.ctx_labels.singular.progress}`}</p>
                </div>
              </Col>
              <Col>
                {ownAssessments && ownAssessments.length > 0
                  ? assessmentProgress(
                      ownAssessments[0].completed_status_count,
                      ownAssessments[0].totcnt
                    )
                  : assessmentProgress(0, 0)}
              </Col>
            </Row>
          </OverviewCard>
        </Col>
        <Col xl={4} sm={6} className="mb-3">
          <OverviewCard>
            <Row>
              <Col>
                <div
                  style={{
                    fontSize: "50",
                    display: "block",
                    textAlign: "left",
                    marginTop: "10",
                    width: "350px"
                  }}
                >
                  <p>{`${props.ctx_labels.singular.liveSessions } ${props.ctx_labels.singular.progress}`}</p>
                </div>
              </Col>
              <Col>
                {ownSessions && ownSessions.length > 0
                  ? sessionProgress(
                      ownSessions[0].completed_status_count,
                      ownSessions[0].totcnt
                    )
                  : sessionProgress(0, 0)}
              </Col>
            </Row>
          </OverviewCard>
        </Col>
      </Row>
      <div className="mb-4"></div>
      <Row>
        <Col lg={6}>
          <WeeklyTimeConsumption data={leaderboardData} />
        </Col>
        <Col lg={6}>
          <ActiveTimeConsumption data={intentData} />
        </Col>
      </Row>
      <div className="mb-4"></div>
      <Row>
        <Col lg={12}>
          <Row>
            <Col lg={12}>
              <SubjectStatus
                alternateRedirection={props.alternateRedirection}
                data={ownCourses}
                child={child}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <AssessmentStatus
                alternateRedirection={props.alternateRedirection}
                addRedirection={props.addRedirection}
                data={ownAssessments}
                child={child}
                dependant={dependantName}
                studentDashboard ={props.studentDashboard ? true : false}
                adminHome= {props.adminHome}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12}>
          <VCStatus data={ownSessions} />
        </Col>
      </Row>
    </MainContainer>
  );
}

export default withLabelsContext(
  withApiContext(withUserContext(DashboardParent))
);
