import * as config from "config";
import PropTypes from "prop-types";
import React from "react";
import * as util from "services/util";
import "./TestQuestion.css";
import Comment from "./TestQuestion/Comment";
import FillInTheBlank from "./TestQuestion/FillInTheBlank";
import MultipleChoice from "./TestQuestion/MultipleChoice";
// import MultipleChoiceSingleAnswer from "./TestQuestion/MultipleChoiceSingleAnswer";
import Survey from "./TestQuestion/Survey";
import Assignment from "./TestQuestion/Assignment";
import MatchTheFollowing from './TestQuestion/MatchTheFollowing';

const TestQuestion = ({ bgColor, hidden, width, ...restProps }) => {
  const l = util.rgbLightness(bgColor);
  const theme = l < config.minLightThemeBgLightness ? "dark" : "light";
  const className = `test test-${theme}`;

  const style = {};
  if (hidden) {
    style.display = "none";
  }
  if (width != null) {
    style.fontSize = width > 800 ? `20px` : `15px`; //`${Math.floor(width / 40)}px`;
  }

  const typeE = config.testQuestionTypeE;
  const typeQBE = config.testQuestionTypeQBE;
  const questionClass =
    restProps.type === typeE.MULTIPLE_CHOICE
      ? MultipleChoice
      : restProps.type === typeE.FILL_IN_THE_BLANK
      ? FillInTheBlank
      : restProps.type === typeE.SURVEY_MULTIPLE_CHOICE
      ? Survey
      : restProps.type === typeE.COMMENT
      ? Comment
      : restProps.type === typeQBE.ASSIGNMENT
      ? Assignment
      : restProps.type === typeQBE.MATCH_THE_FOLLOWING  ? MatchTheFollowing:
      restProps.type === typeQBE.MULTIPLE_CHOICE_SINGLE
      ? MultipleChoice
      : null;
  if (restProps.type === typeQBE.ASSIGNMENT) {
    restProps.length = 0;
  }

  return (
    questionClass && (
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '290px' }}>
        {/* <div className={className} style={style}> */}
          {/* <div className="question-wrapper"> */}
            {/* <div className="test-question-item-name">
       {"Q: "}{restProps.question}
      </div> */}
            {React.createElement(questionClass, restProps)}
          {/* </div> */}
        {/* </div> */}
      </div>
    )
  );
};

TestQuestion.propTypes = {
  bgColor: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  width: PropTypes.number,
  type: PropTypes.oneOf([
    config.testQuestionTypeE.MULTIPLE_CHOICE,
    config.testQuestionTypeE.FILL_IN_THE_BLANK,
    config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE,
    config.testQuestionTypeE.COMMENT,
    config.testQuestionTypeQBE.ASSIGNMENT,
    config.testQuestionTypeQBE.MATCH_THE_FOLLOWING,
    config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE,
  ]).isRequired,
};

export default TestQuestion;
