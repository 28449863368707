import PropTypes from "prop-types";
import React from "react";
import { Fullscreen as FullscreenIcon } from "../icons/Fullscreen";
import { FullscreenExit as FullscreenExitIcon } from "../icons/FullscreenExit";
import { IconFit } from "Icons";
import { FullscreenExit } from "Icons";
import { Button } from "components";
import labels from "../labels";
import "./Fullscreen.css";

const Fullscreen = ({ isFullscreen = false, toggleFullscreen }) => {
  const IconType = isFullscreen ? FullscreenExit : IconFit;
  // const iconProps = {
  //   className: "player-fullscreen-icon",
  //   height: 34,
  //   fill: "#fff",
  // };
  return !toggleFullscreen ? null : (
    <Button variant="link" iconOnly className="p-0"  onClick={toggleFullscreen}>
      <IconType style={{ width: "22px", height: "22px", margin: "0 7px" }} />
    </Button>
    // <div
    //   // type="button"
    //   className="btn-player-control"
    //   aria-label={labels.fullscreen}
    //   onClick={toggleFullscreen}
    // >
    //   {React.createElement(iconType, iconProps)}
    // </div>
  );
};

Fullscreen.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
  toggleFullscreen: PropTypes.func,
};

export default Fullscreen;
