import { withApiContext, withLabelsContext, withUserContext } from "context";
import {
  IconCalendar,
  IconFillUpType,
  IconFilterNew,
  IconMCQType,
  IconSearchRocket,
  IconShortAnswerType,
} from "Icons";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { styled, useTheme } from "styled-components";
import { TestContext } from "./Context/TestContex";
import Select from "./Select";
import css from "./testCreation.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Checkbox, InputField, OverlayTooltip } from "components";
import { rgba } from "polished";
import Loader from "components/Spinners/Loader";
import { convertLatexToMarkup } from "mathlive";
import MathInput from "components/MathInput/MathInput";
const FilterButton = styled.div`
  display: flex;
  width: 22px;
  height: 23px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--bg-color-1) !important;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    svg {
      stroke: white;
    }
  }
`;
const QuestionCard = styled.div`
  // &:hover {
  //     background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
  // }
`;
function AddQuestion(props) {
  const {
    testData,
    setTestData,
    resetTestData,
    question,
    setQuestion,
    sections,
    responseSetter,
    sectionSetter,
    restQuestion,
    setSections,
  } = useContext(TestContext);
  const [showFilter, setShowFilter] = useState(false);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedQuesID, setSelectedQuesID] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [disableMore, setDisableMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loader, setLoader] = useState(true);
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": theme.primary,
    "--bg-color-0": rgba(theme.primary, 0.19),
    "--bg-color-2": theme.color3,
  };
  const [filterData, setFilterData] = useState({
    shortCode: "",
    difficultyLevel: "",
    createdDate: "",
    trainerName: "",
    question: "",
    sortBy: "",
  });

  useEffect(() => {
    getDifficultyLevel();
    getTrainers();
  }, []);

  useEffect(() => {
    const totalClicks = Math.ceil(totalQuestion / 10);
    const disable = pageNo + 1 > totalClicks ? true : false;
    setDisableMore(disable);
  }, [pageNo, totalQuestion]);

  useEffect(() => {
    if(totalQuestion===0){
      setDisableMore(true)
    }
  }, [allQuestions]);

  const getDifficultyLevel = async () => {
    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_difficulty_level",
        args: {},
      });

      const difficultyLevels = rsp.map(({ level_id, level_name }) => ({
        label: level_name,
        value: level_id,
      }));
      setDifficultyLevels(difficultyLevels);
    } catch (error) {
      console.error("Error fetching difficulty levels:", error);
    }
  };

  const getTrainers = async () => {
    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_creator",
        args: {},
      });

      const teachers = rsp.map(({ member_id, member_name }) => ({
        label: member_name,
        value: member_id,
      }));

      setTrainers(teachers);
    } catch (error) {
      console.error("Error fetching difficulty levels:", error);
    }
  };
  const getAllQuestions = async (data) => {
    // const addedQueId = this.props.value.flatMap(r => r.questions.map(s => s.id));
    // const queRemoveId = this.state.questionRemove !== null && this.state.questionRemove.map(r => r.id);
    // const selectedQueId = [addedQueId,queRemoveId].filter(Boolean).flat();
    // this.setState({ questLoading: true });
    let pageIndex;
    if (data === "filter") {
      pageIndex = 1;
      setPageNo(1);
    } else if (data === "moreQuestion") {
      pageIndex = pageNo + 1;
      setPageNo((prev) => prev + 1);
    } else {
      pageIndex = 1;
      setPageNo(1);
    }
    const selectedQueId = sections.flatMap((section) =>
      section.questions.map((question) => question.id)
    );
    const args = {
      curriculumId: testData.curriculumId,
      categoryId: testData.categoryId,
      pageSize: 10,
      pageIndex: pageIndex,
      sortColumn: "default",
      usedQuestion: "new",
      memberId: filterData && filterData.trainerName ? filterData.trainerName : props.ctx_user.user.id,
      selectedQueId: selectedQueId,
      usedQuestion: filterData && filterData.sortBy ? filterData.sortBy : "new",
    };

    if (filterData) {
      if (filterData.createdDate) {
        args.createdDate = filterData.createdDate;
      }
      if (filterData.difficultyLevel) {
        args.difficultyLevel = filterData.difficultyLevel;
      }
      if (filterData.question) {
        args.questionName = filterData.question;
      }
      if (filterData.shortCode) {
        args.shortCodes = filterData.shortCode;
      }
    }
    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_question",
        args: args,
      });
      setLoader(false);
      const question = rsp[0]?.selection;
      const totalQue = +rsp[0]?.total_count;
      setTotalQuestion(+totalQue);
      if (data === "filter") {
        question ? setAllQuestions((prev) => [...question]) : setAllQuestions([]) ;
      } else if (data === "moreQuestion") {
        question && setAllQuestions((prev) => [...question, ...prev]);
      } else {
        question ? setAllQuestions((prev) => [...question]) : setAllQuestions([]) ;
      }
      if(!totalQue){
        setTotalQuestion(0);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching Questions:", error);
    }
  };
  useEffect(() => {
    getAllQuestions();
    getDifficultyLevel();
    getTrainers();
  }, []);

  const handleChange = (field, event) => {
    const selectField =
      field === "difficultyLevel" ||
      field === "trainerName" ||
      field === "sortBy";
    const datePicker = field === "createdDate";
    const inputField = field === "shortCode" || field === "question";
    if (selectField) {
      setFilterData((prevState) => ({
        ...prevState,
        [field]: event?.value ?? "",
      }));
    } else if (datePicker) {
      if(event){
        const date = new Date(event);
        const day = String(date.getDate()).padStart(2, "0");
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        const createdDtate = `${day}-${month}-${year}`;
        setFilterData((prevState) => ({
          ...prevState,
          [field]: createdDtate,
        }));
      }
      else{
        setFilterData((prevState) => ({
          ...prevState,
          [field]: "",
        }));
      }
      
    } else if (inputField) {
      setFilterData((prevState) => ({
        ...prevState,
        [field]: event.target.value,
      }));
    }
  };
  const handleQuestionSelect = async (event, question) => {
    const isChecked = event.target.checked;
    let mediaLink = "";
    const contentType =
      question.content_format === 0
        ? "audio"
        : question.content_format === 1
        ? "video"
        : question.content_format === 2
        ? "pdf"
        : null;
    if(contentType!==null){
      try {
        const { rsp } = await props.ctx_api.privateRequest({
          cmd: "get_module_url",
          args: {
            contentType,
            fileName: question.content,
          },
        });
        if (rsp.success === true) {
          mediaLink = rsp.url
        }
      } catch (error) {
        console.error("Error fetching media URL:", error);
        throw error; // Propagate the error to be handled later if needed
      }
    }
    else{
      mediaLink = null
    }

    const transformedQuestion = {
      id: question.question_id,
      question: question.question_name,
      points: question.question_credit.toString(),
      order: question.rownum,
      negative_points: question.negative_credit.toString(),
      content: question.content,
      content_source: question.content_source,
      content_format: question.content_format ? question.content_format.toString() : question.content_format,
      math_symbol: question.math_symbol,
      type: question.question_type,
      section_order: 1, // Assuming default value as 1, change if necessary
      section_name: "", // Assuming default value as empty string, change if necessary
      difficulty_level: question.difficulty_level,
      is_record: 0, // Assuming default value as 0, change if necessary
      assignment_content: null,
      asgnmt_content_format: null,
      asgnmt_content_source: null,
      std_assignment_type: null,
      shortCodes: question.shortcodes,
      solution: "",
      media_link: mediaLink,
      answer: [],
      negativeCreditPercent:question.neg_credit_percent
    };

    setSelectedQuesID((prevState) => {
      if (isChecked) {
        return [...prevState, transformedQuestion];
      } else {
        return prevState.filter((itm) => itm.id !== question.question_id);
      }
    });
  };
  const handleAdd = () => {
    const updatedSections = sections.map((section) => {
      if (section.section_order === props.sectionOrder) {
        const highestOrder = section.questions.length > 0 ? Math.max(...section.questions.map(q => q.order)) : 0;
        const updatedSelectedQuesID = selectedQuesID.map((ques, index) => {
          return {
              ...ques,
              order: highestOrder + index + 1,
              section_order: props.sectionOrder,
              section_name: section.section_name
          };
      });
      
        const updatedQuestions = [...section.questions, ...updatedSelectedQuesID];
        return {
          ...section,
          questions: updatedQuestions,
        };
      }

      return section;
    });
    setSections(updatedSections);
    setAllQuestions((prev) => {
      const allQues = prev.filter(
        (questions) =>
          !selectedQuesID.some(
            (selectedItem) => selectedItem.id === questions.question_id
          )
      );
      return allQues;
    });
    setSelectedQuesID([]);
  };
  const handleCancel = () => {
    setSelectedQuesID([]);
    props.handleReset();
  }
  const sortByOptions = [
    { value: "new", label: "New" },
    { value: "all", label: "All" },
    { value: "used", label: "Used" },
  ];
  const renderFilter = () => {
    const currentDate = null
    return (
      <div className={css.filter}>
        <Row className={css.filterRow}>
          <div className={css.filterDivRow1}>
            <Select
              name="trainerName"
              options={trainers}
              placeholder={props.ctx_labels.singular.trainerName}
              onChange={(selectedOption) => {
                handleChange("trainerName", selectedOption);
              }}
            />
          </div>
          <div className={css.filterDivRow1}>
            <input
              name="shortCode"
              className={css.filterInput}
              style={inlineBackground}
              placeholder={props.ctx_labels.singular.shortCodes}
              onChange={(e) => {
                handleChange("shortCode", e);
              }}
            />
          </div>
        </Row>
        <Row className={css.filterRow}>
          <div className={css.filterDivRow2}>
            <Select
              name="difficultyLevel"
              options={difficultyLevels}
              placeholder={props.ctx_labels.singular.difficultyLevel}
              onChange={(selectedOption) => {
                handleChange("difficultyLevel", selectedOption);
              }}
            />
          </div>
          <div className={css.filterDivRow2}>
            <DatePicker
              selected={
                filterData.createdDate !== ""
                  ? new Date(filterData.createdDate)
                  : null
              }
              name="createdDate"
              showIcon
              icon={<IconCalendar />}
              className={css.filterCalendar}
              placeholderText="Created date"
              onChange={(date) => {
                handleChange("createdDate", date);
              }}
              isClearable
            />
          </div>
          <div className={css.filterDivRow2}>
            <Select
              name="sortBy"
              options={sortByOptions}
              placeholder="Sort By"
              required
              onChange={(selectedOption) => {
                handleChange("sortBy", selectedOption);
              }}
            />
          </div>
        </Row>
        <Row className={css.filterRow}>
          <div className={css.filterDivRow3}>
            <input
              name="question"
              className={css.filterInput}
              style={inlineBackground}
              placeholder={props.ctx_labels.singular.question}
              onChange={(e) => {
                handleChange("question", e);
              }}
            />
          </div>
          <div className={css.filterRow3Buton}>
            <div
              className={css.searchFilter}
              style={inlineBackground}
              onClick={() => getAllQuestions("filter")}
            >
              <IconSearchRocket />
            </div>
          </div>
        </Row>
      </div>
    );
  };
  return (
    <div className={css.addQuestion}>
      <div className={css.addQuestionHeader}>
        <div>Add Question</div>
        <OverlayTooltip
        tip="Filter"
        placement="top"
        >
        <FilterButton
          style={inlineBackground}
          onClick={() => setShowFilter(!showFilter)}
        >
          <IconFilterNew stroke={theme.primary} />
        </FilterButton>
      </OverlayTooltip>
      </div>

      <div>{showFilter && renderFilter()}</div>

      <div
        className={css.queCard}
        style={
          showFilter
            ? { marginTop: "1rem", height: "9.6rem" }
            : { marginTop: "2rem", height: "17rem" }
        }
      >
        {loader ? (
          <Loader width={35} active />
        ) : (
          <>
            {allQuestions.map((item, index) => {
              return (
                <QuestionCard key={index}>
                  <div className={css.addquestionCard} style={inlineBackground}>
                    <Checkbox
                      questionType={item.question_type}
                      fromCb={true}
                      checked={selectedQuesID.some(
                        (selected) => selected.id === item.question_id
                      )}
                      onChange={(e) => {
                        handleQuestionSelect(e, item);
                      }}
                    />
                    <div className={css.addQue}>{item.math_symbol=== 1 ? <MathInput id = "addQues" disabled={true} value={item.question_name} /> : item.question_name}</div>
                  </div>
                  <div
                    className={css.horizontalLine}
                    style={inlineBackground}
                  ></div>
                </QuestionCard>
              );
            })}
            { allQuestions.length === 0 && !disableMore && 
            <div className={css.noMoreQue}>
              Click <b>More Questions</b> to see more.
            </div>
            }
            {disableMore && (
              <div className={css.noMoreQue}>
                There are no more questions available.
              </div>
            )}
          </>
        )}
      </div>
      {!loader && (
        <div className={css.addQueButton}>
          <button
            className={css.addMore}
            style={inlineBackground}
            disabled={disableMore}
            onClick={() => getAllQuestions("moreQuestion")}
          >
            More Questions
          </button>
          <Button
            className={css.rectangleButton}
            style={{
              backgroundColor: theme.primary,
              borderColor: theme.primary,
            }}
            //   disabled={isSaveDisabled}
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button
            className={css.rectangleButton}
            variant="btn-outline-cancel"
              onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

export default withUserContext(withApiContext(withLabelsContext(AddQuestion)));
