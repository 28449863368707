import React from "react";
import { IconCircleCancelRed, IconCircleCheckGreen } from "Icons";

const mkAnswerStatus = (glyph, color) => () =>
  glyph === "ok" ? (
    <IconCircleCheckGreen />
  ) : glyph === "remove" ? (
    <IconCircleCancelRed />
  ) : null;

class AnswerStatus {}

AnswerStatus.Correct = mkAnswerStatus("ok", "green");
AnswerStatus.Incorrect = mkAnswerStatus("remove", "red");

export default AnswerStatus;
