import * as config from 'config';
import * as levenshtein from 'fast-levenshtein';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button,Form } from 'react-bootstrap';

import AnswerExplanation from 'pages/Assessments/InternalItems/TestQuestion/AnswerExplanation';

import AnswerStatus from './AnswerStatus';
import Emph from './Emph';
import ReviewLink from './ReviewLink';
import css from "../../../pages/StudentAssessments/Question/question.module.scss";
import "./FillInTheBlank.css"




class FillInTheBlank extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    comments: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered: PropTypes.func.isRequired,
    onReview: PropTypes.func.isRequired,
  };

  initState = {
    userAnswer: '',
    typoFound: false,
    matchIdx: null,
  };

  componentWillReceiveProps = (newProps) => {

    if (newProps.question !== this.props.question) {
      this.setState(this.initState);
    }
  }

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.matchIdx !== null && prevState.matchIdx === null) {
      const response = this.state.matchIdx !== -1 ? {
        correct: [this.state.userAnswer],
      } : {
          incorrect: [this.state.userAnswer],
          missed: [...this.props.answers],
        };
      this.props.onAnswered(response);
    }
  }

  handleChange = (e) => {
    this.setState({ userAnswer: e.target.value });
  }

  handleKeyPress = (e) => {
    if (e.which === 13) {
      this.handleSubmit(e);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let minErr = null;
    let matchIdx = null;
    const lcUserAnswer = this.state.userAnswer.toLowerCase().trim();
    this.props.answers.forEach((answer, i) => {
      if (matchIdx === null) {
        const err =
          levenshtein.get(lcUserAnswer, answer.toLowerCase().trim()) / answer.length;
        if (err === 0) {
          matchIdx = i;
        }
        minErr = minErr === null ? err : Math.min(minErr, err);
      }
    });

    let typoFound = false;
    if (0 < minErr) {
      if (
        !this.state.typoFound && minErr <= config.maxFillInTheBlankErrorRate
      ) {
        typoFound = true;
      } else {
        matchIdx = -1;
      }
    }

    this.setState({
      typoFound,
      matchIdx,
    });
  }

  renderValidation = () =>
    this.state.typoFound ? null :
      this.state.matchIdx === -1 ? <AnswerStatus.Incorrect /> :
        this.state.matchIdx !== null ? <AnswerStatus.Correct /> :
          null;

  renderInput = () => (
    <div>
      <form id="test-answer-form">
        <div className="test-answer-input">
        <Form.Group>
            <Form.Control
              as="textarea"
              rows="1"
              disabled={this.state.matchIdx !== null}
              value={this.state.userAnswer}
              maxLength={config.maxTestAnswerLength}
              placeholder={config.testAnswerPlaceholder}
              onChange={this.handleChange}
              form="test-answer-form"
              autoFocus
            />
              {this.renderValidation()}
          </Form.Group>
          {/* <div className="test-answer-line">
            <input
              disabled={this.state.matchIdx !== null}
              value={this.state.userAnswer}
              maxLength={config.maxTestAnswerLength}
              placeholder={config.testAnswerPlaceholder}
              onChange={this.handleChange}
              form="test-answer-form"
              autoFocus
            />
            {this.renderValidation()}
          </div> */}
        </div>
      </form>
      {this.state.typoFound && (
        <div className="test-answer-line-footer">
          Please try again...perhaps you misspelled your answer
        </div>
      )}
    </div>
  );

  renderAnswerComment = (i) => (
    <span>
      {/* {this.props.comments[i]} */}
      {this.props.comments[i] && 0 < this.props.comments[i].length &&
        <span>&nbsp;</span>}
      {
        0 <= this.props.reviewStarts[i] &&
        this.props.reviewStarts[i] < this.props.reviewEnds[i] && (
          <ReviewLink
            id={`review-answer-${i}`}
            onClick={this.props.onReview(
              this.props.reviewStarts[i],
              this.props.reviewEnds[i],
            )}
          />
        )
      }
    </span>);

  renderExplanationForIncorrect = () => (
    <div>
      <div className="child">
        Unfortunately, <Emph>{this.state.userAnswer}</Emph> is not correct.
      </div>
      {this.props.answers.length === 1 ? (
        <div className="child">
          The correct answer is <Emph>{this.props.answers[0]}</Emph>.
          {' '}
          {this.renderAnswerComment(0)}
        </div>
      ) : (
          <div className="child">
            There are several correct answers:
          {this.props.answers.map((answer, i) => (
              <div className="child" key={`correct-answer-${i}`}>
                <Emph>{answer}</Emph>
                {' '}
                {this.renderAnswerComment(i)}
              </div>
            ))}
          </div>
        )}
    </div>);

  renderExplanationForCorrect = () => (
    <div className="child">
      Well done, <Emph>{this.state.userAnswer}</Emph> is correct.
      {' '}
      {this.renderAnswerComment(this.state.matchIdx)}
    </div>);

  renderExplanation = () => (
    <AnswerExplanation visible={this.state.matchIdx !== null}>
      {this.state.matchIdx === -1 ?
        this.renderExplanationForIncorrect() :
        this.renderExplanationForCorrect()}
    </AnswerExplanation>);

  render = () => (
    <div>
      <div  className={`${"question-title-fill"} text-color4`}>
      <div>
      Question 
      </div>
      <div>
      {this.state.matchIdx === null &&
            <Button
           
              className="test-answer-submit-button"
              bsStyle="primary"
              disabled={this.state.userAnswer.length === 0}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>}</div></div>
      <div className={`${css.question} text-color2`}>
        {this.props.question}
      </div>
      {this.props.questionImage &&
        <div className={this.props.isPlayerTest ? "player-image" : "test-question-image"}>
          <img src={this.props.questionImage} alt="" />
        </div>
      }
      {this.renderInput()}
      {this.renderExplanation()}
    </div>);
}


export default FillInTheBlank
