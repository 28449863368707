import React, { useEffect, useRef, useState } from "react";

import MathLive, { convertLatexToMarkup } from "mathlive";

import { MathfieldElement } from "mathlive";

import "./MathInput.css";
import styled from "styled-components";
import { rgba } from "polished";

const StyledTrWrapper = styled.div`
    border: 1px solid ${({ theme }) => rgba(theme.color4, 0.8)};
    border-radius: 3px;
`;

const TestWrapper = styled.div`
    border: none;
    outline:none;
    &:hover {
      outline:none;
    }
    &.focused {
      outline:none;
    }
    math-field:focus-within {
      outline: none;
    }
    .placeholder {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: grey;
    font-size: 12px;
  }
`;

const Editor = ({ value, onChange, id, onBlur, disabled,...props }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const onInputChange = (e) => {
    onChange && onChange(e.target.value);
  };
 
  const handleClick = () => {

    if(value && value !==""){
      setShowPlaceholder(false);
    }
    else{
      setShowPlaceholder(true);
    }
  };

  useEffect(() => {
    if(value && value !==""){
      setShowPlaceholder(false);
    }
    else{
      setShowPlaceholder(true);
    }
  }, [value]);

  useEffect(() => {
    const mf = document.getElementById(id);
    mf.setOptions({
      mathModeSpace: "\\:",
      // soundsDirectory : null
    });
  }, [id]);

  return (
    <>
      {disabled ? ( //rendering math-fields conditionally because if it has any value as disabled the field is getting disabled
        <math-field
          id={id ? id : "formula"}
          disabled
          virtual-keyboard-mode="manual"
          value={value}
          onInput={onInputChange}
          className="formula_mathinput"
          onBlur={onBlur ? onBlur : null}
          // keypress-sound="none"
        >
          {value}
        </math-field>
      ) : 
      props.fromCreateTest ?
      (
      <TestWrapper  onClick={handleClick}>
        {showPlaceholder && <div className="placeholder">{props.placeholder}</div>}
         <math-field
            id={id ? id : "formula"}
            virtual-keyboard-mode="manual"
            value={value}
            onInput={onInputChange}
            className="formula_mathinput"
            onBlur={onBlur ? onBlur : null}
            // keypress-sound="none"
            placeholder={"Antony"}
          >
            {value}
          </math-field>
      </TestWrapper>)
      :
      (
        <StyledTrWrapper>
          <math-field
            id={id ? id : "formula"}
            virtual-keyboard-mode="manual"
            value={value}
            onInput={onInputChange}
            className="formula_mathinput"
            onBlur={onBlur ? onBlur : null}
            // keypress-sound="none"
          >
            {value}
          </math-field>
        </StyledTrWrapper>
      )}
    </>
  );
};

export default Editor;
