import PropTypes from 'prop-types';
import React from 'react';
import './Button.css';



const Button = ({
  iconType,
  className,
  onClick,
  disabled = false,
  ...restProps
}) => {
  const iconProps = {
    className: 'pdf-button-icon',
    height:     34,
    fill:      '#fff',
  };
  const buttonProps = {
    ...restProps,
    type:      'button',
    className: 'pdf-button-button',
    onClick:   onClick,
    disabled:  disabled,
  }
  return (
    <div className={[
      'pdf-button',
      disabled ? 'pdf-button-disabled' : 'pdf-button-enabled',
      className
    ].join(' ')}>
      <button {...buttonProps}>
        {React.createElement(iconType, iconProps)}
      </button>
    </div>);
}

Button.propTypes = {
  iconType:  PropTypes.func.isRequired,
  className: PropTypes.string,
  onClick:   PropTypes.func.isRequired,
  disabled:  PropTypes.bool,
}

Button.defaultProps = {
  disabled:  false,
}


export default Button
