import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cmCss from "./Iframe.module.scss";
import FullScreen from 'components/PlayerNew/Video/FullScreen';
import labels from "./labels";
import css from './Iframe.module.scss';


class HtmlIframe extends Component {
  constructor(props) {
    super(props);
    this.iframeElt = null;
    this.state={
      fullScreen: this.isFullscreenNow(),
      // hidden: true,
      url: null
    }
  }

  static propTypes = {
    className: PropTypes.string,
    title:     PropTypes.string.isRequired,
    url:       PropTypes.string,
    onLoad:    PropTypes.func,
    onAbort:   PropTypes.func,
  };

  static defaultProps = {
    title: 'iframe',
  };

  shouldComponentUpdate = () => false;

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.url !== this.props.url) {
      this.setUrl(nextProps);
    }
  }

  setUrl = (props) => {
    if (this.iframeElt) {
      this.iframeElt.src = props.url || 'about:blank';
    }
  }

  setIframeRef = (r) => {
    this.iframeElt = ReactDOM.findDOMNode(r);
  }

  toggleFullScreen = pane => () => {
    this.setState({ fullScreen: !this.state.fullScreen });
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }

    this.setState({ fullScreen: !this.state.fullScreen });
  };

  isFullscreenNow = () => !!(
    document.fullscreenElement    ||
    document.msFullscreenElement  ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement
  );
 

  renderControls = () => (
    <div className={`${this.props.isContentBuilder ? cmCss.toolbar : cmCss.toolbar_module} bg-color2 text-white`}>
      <div className={cmCss.toolbar_left}></div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.renderFullScreen()}</div>
    </div>
  );

  toggleFullScreen = (pane) => {
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }

    this.setState({ fullScreen: !this.state.fullScreen });
  };

  renderFullScreen = (key,hidden) => {
    return(
    <FullScreen
      onClick={this.toggleFullScreen}
      hidden={hidden}
      ariaLabelPlay={labels.fullscreen}
      key={key}
      fullScreen={this.state.fullScreen}
    />
  )
  };

  render = () => {
    const {
      url,
      title, // pass explicitly to avoid warning about missing title
      ...restProps
    } = this.props;
    const {fullscreen} = this.state;
    this.setState({url: this.props.url});
    const className = ['module-html-iframe', this.props.className].join(' ');
    const fsStyle = fullscreen ? `${css.frame_html_fs}` : '';
    const iframefsStyle = fullscreen ? `${css.module_html_iframe_fs}` : `${css.module_html_iframe}`
    return (
      <div className={fsStyle}>
      {/* <> */}
        <iframe
        {...restProps}  
        className={iframefsStyle}
        title={title}
        ref={this.setIframeRef}
      />
      {this.renderControls()
}
       {/* </> */}
      </div> 
    );
  }
}


export default HtmlIframe