import { InputField } from "components";
import { IconSearch } from "Icons";
import React from "react";
import css from "./search-field.module.scss";

class SearchField extends React.Component {
	keyUp(e) {
		this.props.onKeyUp && this.props.onKeyUp(e);
	}

	render() {
		return (
			<div className={css.field}>
				{!this.props.noIcon && <IconSearch className={css.icon} width="16" height="16" onClick={this.props.onSearchClick ? this.props.onSearchClick : null}/>}
				<InputField
					type="text"
					onKeyUp={(e) => this.keyUp(e)}
					{...this.props}
				/>
			</div>
		);
	}
}

export default SearchField;
