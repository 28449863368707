import React from 'react';

import { Fullscreen as FullscreenIcon } from '../icons/Fullscreen';
import { FullscreenExit     as FullscreenExitIcon     } from '../icons/FullscreenExit';

import './PlayPause.css';


export default ({ onClick, fullScreen, hidden, className, ariaLabelFullScreen }) => {
    return (
        <div
          className={[
            'video-play-pause',
            className
          ].join(' ')}
          style={hidden ? { visibility: 'hidden' } : null}
        >
            <button
                className="video-play-pause-button"
                onClick={onClick}
                disabled={hidden}
                aria-label={ariaLabelFullScreen}
                    
                type="button">
                { fullScreen
                    ? <FullscreenExitIcon
                        className="video-play-pause-icon"
                        height={34}
                        fill="#fff" />
                    : <FullscreenIcon
                        className="video-play-pause-icon"
                        height={34}
                        fill="#fff" /> }
            </button>
        </div>
    );
};
