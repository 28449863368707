import React, { useState } from "react";
import PropTypes from "prop-types";
import css from "./dashboard-panel.module.scss";
import classNames from "classnames";
import {
  IconChevronLeft,
  IconChevronRight,
  IconClose,
  IconFullScreen,
} from "Icons";
import JustifyBetween from "components/JustifyBetween/justifyBetween";
import { OverlayTooltip } from "components";
import { withUserContext } from "context";

function DashboardPanel(props) {
  const [full, setFull] = useState(false);

  return (
    <div
      className={classNames(css.panel, full && css.fullscreen, props.className)}
    >
      {full && (
        <span className={css.close} onClick={() => setFull(false)}>
          <IconClose />
        </span>
      )}
      <JustifyBetween>
        <div className={css.header}>
          <div className={classNames(css.title,css.opensub, "text-color2")}>
          {props.tip ? <OverlayTooltip
            placement = "top"
            tip={props.tip ? props.tip.length>0 &&  props.tip : console.log()}
            >
            <span>
            {props.title}
            </span>
            </OverlayTooltip> : <span>{props.title}</span> }
            {props.fullScreen && (
              <span
                className={css.fullscreen_trigger}
                onClick={() => setFull(true)}
              >
                {props.fullScreen && !full && <IconFullScreen />}
              </span>
            )}
          </div>

          {/* end title */}
          {props.swiperRef && (
            <div className={css.slider_nav}>
              <div onClick={() => props.swiperRef.current.swiper.slidePrev()}>
                <IconChevronLeft className="fill-color2" />
              </div>
              <div onClick={() => props.swiperRef.current.swiper.slideNext()}>
                <IconChevronRight className="fill-color2" />
              </div>
            </div>
          )}
          {/* end nav */}
        </div>
        {props.sideContent? props.sideContent:null}
      </JustifyBetween>
      {props.children}
    </div>
  );
}

DashboardPanel.propTypes = {
  title: PropTypes.any,
  fullScreen: PropTypes.bool,
};

export default withUserContext(DashboardPanel) ;
