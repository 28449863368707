import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withApiContext, withUserContext, withLabelsContext } from "context";
import { Card, Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { IconAudio, IconFlag, IconPDF, IconVideo, IconAssignment, IconPaperClip } from "Icons";
import { OverlayTooltip, Button, Loader, HelpfulButton, BlockUpload, Textarea } from "components";
// import "./Assignment.css";
// import css from ".../StudentAssessments/Question/question.module.scss"
// import css from "../../StudentAssessments/Question/question.module.scss";
// import { Form } from "react-bootstrap";
// import css  from "styled-components";

// import css  from "../StudentAssessments/Question/question/question.module.scss";
import UploadButtonAssign from "components/UploadButtonAssign";
import css from "../../Question/question.module.scss";
import * as config from "config";
import { ButtonGroup, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { renderAnswerFooter } from "pages/Assessments/StudentAssessments";
// import { callIfDefined } from "react-pdf/build/shared/util";
import Video from "components/PlayerNew/Video";
// import { BlockUpload } from "components";
import Audio from "components/PlayerNew/Audio";
import "jodit";
import JoditEditor from "jodit-react";
import "jodit/build/jodit.min.css";
import Pdf from "components/PlayerNew/Pdf";
import styled from "styled-components";
import MediaQuery from "react-responsive";

const StyledPlayerButton = styled.span`
  && {
    .media_assignment {
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
    .media_assignment-flag{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
    .label{
      color: white;
      font-weight:500
    }
    .viewAssignment{
      background-color: ${({ theme }) => theme.color2};
      color : white
    }
  }`

  const StyledRow = styled(Row)`
  && {
    .assignment_icon {
      fill: ${({ theme }) => theme.color2}; // Assuming theme is correctly provided
    }
  }
`;


class Assignment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
    this.uploadedDataIsNew = false;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    onAnswered: PropTypes.func.isRequired,
    onAttempted: PropTypes.func.isRequired,
    questionContent: PropTypes.string.isRequired,
  };

  initState = {
    comment: "",
    submitted: false,
    uploadedData: null,
    fileUploading: false,
    fileUploadSuccess: null,
    errorMsg: null,
    pdfUrl: "",
    modalOpen: false,
    pdfFileName: "",
    description: "",
    disabled: true,
    pdfActive: true,
    pdfFile: {},
    pdfLoader: false,
    activeModalContent: "",
    flag: this.props.flag === 1 ? true : false, 
  };
  valueChangeState = {
    comment: "",
    submitted: false,
    uploadedData: null,
    fileUploading: false,
    fileUploadSuccess: null,
    errorMsg: null,
    pdfUrl: "",
    modalOpen: false,
    pdfFileName: "",
    description: "",
    pdfActive: false,
    pdfFile: {},
    pdfLoader: false,
    
  };

  componentDidMount = () => {
    const { user_selection } = this.props;
    if (user_selection) {
      this.setState({
        pdfUrl: user_selection[0],
        description: user_selection[1],
        pdfFileName: user_selection[0],
        comment: user_selection[0],
        pdfFile: user_selection[2],
      });
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.id !== this.props.id) {
      this.setState(this.valueChangeState);
      setTimeout(() => this.setState({ pdfActive: true }), 1);
      if (newProps.user_selection) {
        this.setState({
          comment: newProps.user_selection[0],
          description: newProps.user_selection[1],
          pdfUrl: newProps.user_selection[0],
          pdfFileName: newProps.user_selection[0],
          pdfFile: newProps.user_selection[2],
        });
      }
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { onAttempted, onAnswered } = this.props;
    if (_prevProps.id !== this.props.id ) {
      this.setState({flag : this.props.flag === 0 ? false : true})
    }
    if (this.state.submitted !== prevState.submitted) {
      const response = [
        this.state.comment,
        this.state.description,
        this.state.pdfUrl,
        this.state.pdfFile,
      ];
      onAnswered(response);
      this.state.comment.length > 0 ? onAttempted(true) : onAttempted(false);
    }
    // this.state.comment.length > 0 ? onAttempted(true) : onAttempted(false);
  };

  handleChange = (value) => {
    this.setState({ description: value });
    const { onAttempted, onAnswered } = this.props;
    const response = [
      this.state.comment,
      value,
      this.state.pdfFile,
      this.state.pdfUrl,
    ];
    this.state.comment.length > 0 ? onAttempted(true) : onAttempted(false);
    onAnswered(response);

  };

  // handleClear = () => {
  //   const response = ["", "", "", ""]
  //   this.props.onAnswered(response);
  //   this.setState({
  //     pdfUrl: "",
  //     pdfFileName: "",
  //     comment: "",
  //     disabled: true,
  //     pdfFile: {},
  //   });
  // }
  handleUpload = async (acceptedFiles, rejectedFiles) => {
    this.setState({
      pdfUrl: "",
      pdfFileName: "",
      comment: "",
      disabled: true,
      pdfFile: {},
      pdfLoader: true
    });
    if (acceptedFiles == null || rejectedFiles.length > 0) {
      this.setState((_s0) => ({
        pdfUrl: "",
        pdfFileName: "",
        comment: "",
        disabled: true,
        pdfFile: "",
        pdfLoader: false
      }));
      const { onAttempted, onAnswered } = this.props;
      const response = ["", this.state.description, "", ""]

      this.state.comment.length > 0 ? onAttempted(true) : onAttempted(false);
      onAnswered(response);
    } else {
      const uploadingFile = acceptedFiles[0];
      const data = new FormData();
      data.append("file", uploadingFile);

      const { rsp } = await this.props.ctx_api.privateRequest({
        cmd: "upload_file",
        args: data,
        isFileUpload: true,
      });
      if (rsp.storageType === "aws" && rsp.success) {
        this.setState({ pdfLoader: false });
      }

      this.setState((_s0) => ({
        pdfUrl: rsp.fileUrl,
        pdfFileName: rsp.fileName,
        comment: rsp.fileName,
        disabled: false,
        pdfFile: uploadingFile,
      }));

      const { onAttempted, onAnswered } = this.props;
      const response = [
        this.state.comment,

        this.state.description,
        this.state.pdfFile,
        this.state.pdfUrl,
      ];
      this.state.comment.length > 0 ? onAttempted(true) : onAttempted(false);
      onAnswered(response);
    }
  }


  renderUploadButton = () => (
    <React.Fragment>

      <BlockUpload
        onChange={this.handleUpload}
        uploadType={this.props.std_assignment_type == 0
          ? "audio"
          : this.props.std_assignment_type == 1
            ? "video"
            : this.props.std_assignment_type == 2
              ? "pdf"
              : ""}
        minSize={0}
        maxSize={this.props.std_assignment_type == 0
          ? this.props.ctx_user.user.maxAudioSize
          : this.props.std_assignment_type == 1
            ? this.props.ctx_user.user.maxVideoSize
            : this.props.std_assignment_type == 2
              ? this.props.ctx_user.user.maxPdfSize
              : ""}
        disabled={this.props.disabled}
        isRemove
        value={this.state.comment}
      />
      
    </React.Fragment>
  );

  renderUrlUploadButtonLength = () => (
    <StyledRow>
      <MediaQuery minWidth={822}>
            <Col md={9} lg={9}>
              {this.renderUploadButton()}
            </Col>
            <Col md={3} lg={3} >
              <IconAssignment className="assignment_icon" width={100} height= {100} style={{ marginTop: "10px" , marginLeft:"-25px", height:"100px", width:"75px"}} />
            </Col>
      </MediaQuery>
      <MediaQuery maxWidth={821}>
        <div className="test-pdf-url-upload-button" id="pdf-file-content-update">
          <Row >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Col md={9} lg={9} sm={9}>
                {this.renderUploadButton()}
              </Col>
              <Col md={3} lg={3} sm={3} style={{paddingRight:"5px"}}>
                <IconAssignment className="assignment_icon" width={100} height= {100} style={{ marginTop: "10px" }} />
              </Col>
            </div>
          </Row>
        </div>
      </MediaQuery>
    </StyledRow>
  );

  fetchPdfView = async (pdfFileName) => {
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "get_assignment_url",
      args: {
        fileName: pdfFileName, contentType: this.props.std_assignment_type == 0
          ? "audio"
          : this.props.std_assignment_type == 1
            ? "video"
            : this.props.std_assignment_type == 2
              ? "pdf"
              : ""
      },
    });
    this.setState({ pdfUrl: rsp.url, modalOpen: true, activeModalContent: "assignment" });
  };

  handleAttachment = (e, pdfName,) => {
    e.stopPropagation()
    this.fetchPdfView(pdfName)
  };
  handleClick = () => {
    this.setState({ modalOpen: true, activeModalContent: "" })
  };

  renderViewAssign = () => {
    const { comment, disabled , pdfUrl} = this.state;
    const assignTip =
    pdfUrl !== ""
        ? "Click to view assignment"
        : "Please upload an assignment to view";
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledPlayerButton>
            <OverlayTooltip tip={assignTip} placement="top">
              <Button
                wide
                variant="white"
                onClick={(e) =>
                  comment !== "" ? this.handleAttachment(e, comment) : null
                }
                disabled={this.state.pdfUrl === ""}
                className={this.state.pdfUrl === "" ? "disableviewAssignment" : "viewAssignment"}
              >
                <span className={this.state.pdfUrl === "" ? "disableLabel" : "label"}>
                  {this.props.ctx_labels.singular.viewAssignment}
                </span>
              </Button>
            </OverlayTooltip>
          </StyledPlayerButton>
        </div>
      </React.Fragment>

    );
  };

  renderFileName = () => {
    return <div>{this.state.pdfFileName}</div>;
  };

  renderImage = () => {
    const assignmentContent = this.props.assignment_content
      ? this.props.assignment_content.replace(/^data:image\/\w+;base64,/, "")
      : null
      const assignmentContentPreview = this.props.assignment_content
      ? this.props.assignment_content.replace(/^data:image\/\w+;base64,/, "")
      : this.props.content
      ? this.props.content.replace(/^data:image\/\w+;base64,/, "")
      : null
    const assignmentContenttestPreview=this.props.content ? `data:image/jpg;base64,${assignmentContentPreview}` : null
    return <img src={this.props.fromChapterInner ? this.props.assignment_content : assignmentContenttestPreview}/>
  };

  renderMedia = () => {
    let currLink = this.props.fromChapterInner ? this.props.assignment_content : this.props.media_link;
    const currType = this.props.assignment_content ? this.props.asgnmt_content_format : this.props.content_format;
    const viewAssignment = this.state.activeModalContent === "assignment" ? this.props.std_assignment_type : currType

    let MediaPlayer;
    switch (viewAssignment) {
      case 1:
        MediaPlayer = Video;
        break;
      case 0:
        MediaPlayer = Audio;
        break;
      case 2:
        MediaPlayer = Pdf;
        break;
      default:
        MediaPlayer = React.Fragment;
        break;

    }
    return (
      <>
        <MediaPlayer
          className="ov"
          url={this.state.activeModalContent === "assignment" ? this.state.pdfUrl : currLink}
          pdfUrl={this.state.activeModalContent === "assignment" ? this.state.pdfUrl : currLink}
          mediaId="audio-assignments"
        />
        {(this.state.activeModalContent === "" && this.props.description) &&
          <p className="paragraph"><div dangerouslySetInnerHTML={{ __html: this.props.description }} /></p>}

      </>)
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  joditConfig = {
    height: "250px",
    allowResizeY: false,
    hidePoweredByJodit: true,
    readonly: false,
    removeButtons:
      "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,align,size,redo-undo,clean-html,powered-by-jodit,source", // all options from https://xdsoft.net/jodit/doc/
  };

  handleMedia = () => {
    this.setState({ modalOpen: true, activeModalContent: "" })
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleFlagClick = () => {
    const newIsFlag = !this.state.flag;
    this.props.isFlaged(newIsFlag); // Pass data to the parent component
    this.setState({ flag: newIsFlag });
  }

  render = () => {
    const assignmentContent = this.props.assignment_content
      ? this.props.assignment_content.replace(/^data:image\/\w+;base64,/, "")
      : null

      const assignmentContentPreview = this.props.assignment_content
      ? this.props.assignment_content.replace(/^data:image\/\w+;base64,/, "")
      : this.props.content
      ? this.props.content.replace(/^data:image\/\w+;base64,/, "")
      : null
    const assignmentContenttestPreview=this.props.content ? `data:image/jpg;base64,${assignmentContentPreview}` : null
    const mediaLabel =
      this.props.asgnmt_content_format === 0
        ? "Audio"
        : this.props.asgnmt_content_format === 1
        ? "Video"
        : this.props.asgnmt_content_format === 2
        ? "PDF"
        : "";
    const type = this.state.activeModalContent === "assignment" ? this.props.std_assignment_type : this.props.asgnmt_content_format
    let currLink = this.props.fromChapterInner ? this.props.assignment_content : this.props.media_link;
    let contentNil = this.state.activeModalContent === "assignment" ? this.state.pdfUrl : currLink
    return (
      <React.Fragment>
        <Modal
          dialogClassName="evaluate-modal-frame"
          animation={false}
          show={this.state.modalOpen}
          onHide={this.handleClose}
          scrollable={true}
          centered
          size={type === 0 ? "sm" :"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{type === 0
              ? "Audio"
              : type === 1
                ? "Video"
                : type === 2
                  ? "Pdf"
                  : type === 3
                    ? "Image"
                    : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {(this.state.activeModalContent === "" && (this.props.asgnmt_content_format === 3 || this.props.content_format === 3)) && this.renderImage()}
              {(this.state.activeModalContent === "" && this.props.asgnmt_content_format !== 3 && this.props.asgnmt_content_format !== null) && this.renderMedia()}
              {(this.state.activeModalContent === "assignment" && this.state.pdfUrl) && this.renderMedia()}
            </div>
          </Modal.Body>
        </Modal>

        <div>
          {this.props.disabled &&
            <div style={{ color: "red" }}>
              {this.props.disabledText}
            </div>}

          <div className={`${css.question} text-color2`}>
            <StyledPlayerButton>
              <MediaQuery minWidth={822}>
                <Row>
                  {((this.props.asgnmt_content_format !== 3 && this.props.asgnmt_content_format !== null) || (this.props.content_format !== 3 && this.props.content_format !== null)) ? (
                    <>
                      <Col md={10}>
                      <div className='question-style'>
                        QN{this.props.questionNumber}. {this.props.question}</div></Col>
                      <Col md={2} className="d-flex justify-content-end">
                        <>
                          {contentNil &&
                          <HelpfulButton
                            iconOnly
                            size="sm"
                            pill
                            className="media_assignment rounded-circle" // Add the rounded-circle class
                            onClick={this.handleMedia}
                            tip = {"Click here to view " + mediaLabel}
                            placement = "top"
                          >
                            {(this.props.asgnmt_content_format === 0 || this.props.content_format === 0)  ? (
                              <IconAudio width={10} height={10} fill="white" />
                            ) : (this.props.asgnmt_content_format === 1 || this.props.content_format === 1 )? (
                              <IconVideo width={10} height={10} fill="white" />
                            ) : (this.props.asgnmt_content_format === 2 || this.props.content_format === 2) ? (
                              <IconPDF width={10} height={10} fill="white" />
                            ) : (
                              ""
                            )}
                          </HelpfulButton>
  }
                         {this.props.fromChapterInner &&  <HelpfulButton
                            iconOnly
                            size="sm"
                            pill
                            className="media_assignment-flag rounded-circle" // Add the rounded-circle class
                            onClick={this.handleFlagClick}
                            tip = "Flag"
                            placement = "top"
                          >
                            <IconFlag width={10} height={10} fill="white" />
                          </HelpfulButton>}
                        </>
                      </Col>
                    </>)
                    : (
                      <>
                        <Col md={11}>
                        <div className='question-style'>
                        QN{this.props.questionNumber}. {this.props.question}</div></Col>
                        <Col md={1} className="d-flex justify-content-end">
                       { this.props.fromChapterInner && <HelpfulButton
                            iconOnly
                            size="sm"
                            pill
                            className="media_assignment-flag rounded-circle" // Add the rounded-circle class
                            onClick={this.handleFlagClick}
                            tip = "Flag"
                            placement = "top"
                          >
                            <IconFlag width={10} height={10} fill="white" />
                          </HelpfulButton>}
                        </Col>
                      </>
                    )}
                </Row>
              </MediaQuery>
              <MediaQuery maxWidth={821}>
                <Row>

                  <>
                    <Col md={12}>
                    <div className='question-style'>
                    QN{this.props.questionNumber}. {this.props.question}</div>
                    {(this.props.asgnmt_content_format !== 3 && this.props.asgnmt_content_format !== null && this.props.content_format !== 3 && this.props.content_format !== null)  && (
                      <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_assignment rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {(this.props.asgnmt_content_format === 0 || this.props.content_format === 0)  ? (
                              <IconAudio width={10} height={10} fill="white" />
                            ) : (this.props.asgnmt_content_format === 1 || this.props.content_format === 1 )? (
                              <IconVideo width={10} height={10} fill="white" />
                            ) : (this.props.asgnmt_content_format === 2 || this.props.content_format === 2) ? (
                              <IconPDF width={10} height={10} fill="white" />
                            ) : (
                              ""
                            )}
                      </HelpfulButton>
                    )}
                    {this.props.fromChapterInner && <HelpfulButton
                      iconOnly
                      size="sm"
                      pill
                      className="media_assignment-flag rounded-circle" // Add the rounded-circle class
                      onClick={this.handleFlagClick}
                      // tip = "Flag"
                      // placement = "top"
                    >
                      <IconFlag width={10} height={10} fill="white" />
                    </HelpfulButton>}
                    </Col>
                  </>
                </Row>
              </MediaQuery>
            </StyledPlayerButton>
          </div>

          { ((this.props.asgnmt_content_format === 3 && this.props.assignment_content) || (this.props.content_format === 3 && this.props.content)) && (
            <img src={this.props.fromChapterInner ? this.props.assignment_content : assignmentContenttestPreview} className="assignment-image" alt="" onClick={this.handleClick} />
          )}
          {/* <img src={this.props.fromChapterInner ? this.props.assignment_content : assignmentContenttestPreview} className="assignment-image" alt="" onClick={this.handleClick} /> */}

            {  (this.props.question_content || this.props.question_content) &&
            <div
              dangerouslySetInnerHTML={{ __html:  this.props.question_content || this.props.question_content}}
              className="description"
            >
            </div>}

          <Row>
            <Col md={6} style={window.screen.width < 768 ? {marginBottom:"10px"} : {}}>
              <Card body style={ {minHeight: "250px"} }>
                <Row >
                  <Col md={12}>
                    {this.state.pdfFileName !== "" &&
                      this.renderFileName()}
                  </Col>
                  &nbsp;
                  <Col md={12}> {this.renderUrlUploadButtonLength()}</Col>
                  <Col md={12}>{this.renderViewAssign()}</Col>
                  {this.state.pdfLoader &&
                    <Col md={12}>
                      <div style={{ height: "50px" }}>
                        <Loader active width={30} />
                      </div>
                      <div style={{ textAlign: "center" }}>Uploading....</div>
                    </Col>
                  }
                </Row>
              </Card>
            </Col>
            <Col md={6}>
              <div className="test-com-text" >
                <Form.Group >
                  <JoditEditor 
                    value={this.state.description}
                    config={this.props.disabled ? {readonly: this.props.disabled} : this.joditConfig}
                    editorRef={this.setRef}
                    // onBlur={(newContent) => setContent(newContent)}
                    onChange={this.props.fromCreateTest ? null : this.handleChange}
                  // onClick={(e)=>e.stopPropagation} 

                  />
                  {/* <Form.Control
                  rows={11}
                  as="textarea"
                  value={this.state.description}
                  placeholder="Your comment here "
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  disabled={this.props.disabled}
                  autoFocus
                /> */}
                </Form.Group>

              </div>
            </Col>
            {!this.props.fromChapterInner && this.props.solution && (
                <Col md={12} >
                  <label style={{ fontWeight: 500, fontSize: "15px" }}>Solution</label>
                  <Textarea
                  className="text-area"
                    // type={Textarea}
                    readOnly={true}
                    value={this.props.solution}
                  />
                </Col>
              )}

          </Row>

        </div>
      </React.Fragment>
    )
  };
}

export default withApiContext(withUserContext(withLabelsContext(Assignment)));
