import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import css from "./trainer-overview.module.scss";
import {
  withApiContext,
  withLabelsContext, withUserContext
} from 'context';
import MediaQuery from 'react-responsive';
import Media from "pages/Components/Meet/config/media";
import styled from "styled-components";
// import OverlayTooltip from "./OverlayTooltip/OverlayTooltip";
import { OverlayTooltip} from "components";

const StyledPublish = styled.span`
    && {
        .${css.surveyheader} {
            color: ${({ theme }) => theme.primary};
        }
        .${css.surveyheaders}{
          color: ${({ theme }) => theme.color2};
        }
        .${css.surveyheadersq}{
          color: ${({ theme }) => theme.color2};
        }
    }
`;

const Item = ({ label, value }) => {
  return (
    <div className={`${css.item}`}>
      <div className={`${css.label} text-color4`}>{label}</div>
      <div className={css.value}>{value}</div>
    </div>
  );
};

const ItemNumber = ({ label, value }) => {
  return (
    <div className={`${css.item} ${css.item_number}`}>
      <div className={css.value}>{value}</div>
      <div className={`${css.label} text-color4`}>{label}</div>
    </div>
  );
};
class TrainerOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      shortName: "",
      currName: "",
      standard: "",
      fromDate: "",
      toDate: "",
      tot_questions: "",
      tot_marks: "",
      attended: "",

    };
  }

  componentDidMount = () => {
    this.props.entity && (
      this.setState(
        {
          name: this.props.name,
          shortName: this.props.shortName,
          currName: this.props.currName,
          standard: this.props.standard,
          fromDate: this.props.fromDate,
          toDate: this.props.toDate,
          tot_questions: this.props.tot_questions,
          tot_marks: this.props.tot_marks,
          attended: this.props.attended,
        },
      )
    );
  };

  render = () => {
    return (

      <>
        <MediaQuery maxWidth={575}>
          <Row>
            <div className={`${css.wrappermob} `}>
              <Row className="survey_head_wrapper2 border_class_sur_wrapper ">
              <Col sm={4}>
        <Item
          label={
            <span className={css.surveyheader} 
              style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                //color: "#0F83BC",
                fontWeight: "500",
              }}
            >
              {this.props.ctx_labels.singular.survey}
            </span>
          }
          value={
            <OverlayTooltip tip={this.props.shortName} placement="top" >
            <span
             style={{
                fontFamily: "Poppins",
                fontSize: "13px",
                color: "#001B4A",
                fontWeight: 400,
                maxWidth: "5px",
                whiteSpace: "normal", // Prevent text from wrapping
                overflowWrap: "break-word", // Allow word breaking
              }}
              className="text-left"
            >
              {this.props.shortName && this.props.shortName.length >= 15 ? (this.props.shortName.slice(0,22)+ '...') : this.props.shortName}
            </span>
            </OverlayTooltip>
          }
        />
      </Col>
                <Col sm={4} style={{borderLeft: "1px solid #0F83BC"}}
                //  className="align-self-center" style={{ marginLeft: "120px", marginTop: "-57px", borderLeft: "1px solid #0F83BC" }}
                 >
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500",
                       
                      }}>
                        {this.props.ctx_labels.singular.startDate}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400, 
                       
                      }} 
                      
                      >
                        {this.props.fromDate}
                      </span>

                    }
                  />
                </Col>
                <Col sm={4} style={{borderLeft: "1px solid #0F83BC"}}
                // className="align-self-center"
                //  style={{ marginLeft: "252px", marginTop: "-57px", borderLeft: "1px solid #0F83BC" }}
                 >
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500"
                      }}>
                        {this.props.ctx_labels.singular.dueDates}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400 }} className="text-left">
                        {this.props.toDate}
                      </span>
                    }
                  />

                </Col>
              </Row>

              <Row className="survey_head_wrapper border_class_sur_wrapper ">
                <Col sm={4}  
                >
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500",
                      }}>
                        {this.props.ctx_labels.singular.attended}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, marginLeft: "25px" }} className="text-left">
                        {this.props.attended}
                      </span>

                    }
                  />
                </Col>
                
                  
                    <Col sm={4} style={{borderLeft: "1px solid #0F83BC"}}>
                      <Item
                        label={
                          <span className={css.surveyheader} style={{
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            //color: "#0F83BC",
                            fontWeight: "500",
                            marginLeft: "0px"
                          }}>
                            {this.props.ctx_labels.singular.unattended}
                          </span>}
                        value={
                          <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "30px" }} >
                            {this.props.unattended}
                          </span>


                        }
                      />
                    </Col>
                    <Col sm={4} style={{marginLeft:"2px",borderLeft: "1px solid #0F83BC"}}>
                      <Item
                        label={
                          <span className={css.surveyheader} style={{
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            //color: "#0F83BC",
                            fontWeight: "500"
                          }}>
                            {this.props.ctx_labels.singular.totalQuestion}
                          </span>}
                        value={
                          <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "40px", marginBottom: "10px" }} className="text-left">
                            {this.props.tot_questions}
                          </span>

                        }
                      />
                    </Col>
                    
                
                
                {/* ./col */}
              </Row>
            </div>
          </Row>
        </MediaQuery>


        <MediaQuery minWidth={576} maxWidth={820}>
        <Row>
            <div className={`${css.wrappermob} `}>
              <Row className="survey_head_wrapper border_class_sur_wrapper ">
              <Col sm={4} style={{ flex: 1 }}>
                
                  <Item
                    label={
                      <span className={css.surveyheader}
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11.5px",
                        //color: "#0F83BC",
                        fontWeight: "400",
                      }}
                      >
                        {this.props.ctx_labels.singular.survey}
                      </span>}
                    value={
                      <OverlayTooltip tip={this.props.shortName} placement="top" >
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400, maxWidth: "5px", whiteSpace: "normal" }} className="text-left">
                      {this.props.shortName && this.props.shortName.length >= 15 ? (this.props.shortName.slice(0,9)+ '...'): this.props.shortName }
                      </span>
                      </OverlayTooltip>

                    }
                  />
                </Col>
                <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
                
                
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500",
                       
                      }}>
                        {this.props.ctx_labels.singular.startDate}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400, 
                       
                      }} 
                      
                      >
                        {this.props.fromDate}
                      </span>

                    }
                  />
                </Col>
                <Col sm={4} style={{borderLeft: "1px solid #0F83BC"}}
                // className="align-self-center"
                //  style={{ marginLeft: "252px", marginTop: "-57px", borderLeft: "1px solid #0F83BC" }}
                 >
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500"
                      }}>
                        {this.props.ctx_labels.singular.dueDates}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400 }} className="text-left">
                        {this.props.toDate}
                      </span>
                    }
                  />

                </Col>
              </Row>

          <Row className="survey_head_wrapper border_class_sur_wrapper" style={{ display: 'flex' }}>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}>
                    {this.props.ctx_labels.singular.attended}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, marginLeft: "25px" }} className="text-left">
                    {this.props.attended}
                  </span>
                }
              />
            </Col>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "0px"
                  }}>
                    {this.props.ctx_labels.singular.unattended}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "43px" }} >
                    {this.props.unattended}
                  </span>
                }
              />
            </Col>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500"
                  }}>
                    {this.props.ctx_labels.singular.totalQuestion}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "40px", marginBottom: "10px" }} className="text-left">
                    {this.props.tot_questions}
                  </span>
                }
              />
            </Col>
          </Row>
        </div>
      </Row>
        </MediaQuery>
          
        <MediaQuery minWidth={821} maxWidth={1199}>
        <Row>
            <div className={`${css.wrappermob} `}>
              <Row className="survey_head_wrapper border_class_sur_wrapper ">
              <Col sm={4} style={{ flex: 1 }}>
                
                  <Item
                    label={
                      <span className={css.surveyheader}
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11.5px",
                        //color: "#0F83BC",
                        fontWeight: "400",
                      }}
                      >
                        {this.props.ctx_labels.singular.survey}
                      </span>}
                    value={
                      <OverlayTooltip tip={this.props.shortName} placement="top" >
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400, maxWidth: "5px", whiteSpace: "normal" }} className="text-left">
                      {this.props.shortName && this.props.shortName.length >= 15 ? (this.props.shortName.slice(0,9)+ '...'): this.props.shortName }
                      </span>
                      </OverlayTooltip>

                    }
                  />
                </Col>
                <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
                
                
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500",
                       
                      }}>
                        {this.props.ctx_labels.singular.startDate}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400, 
                       
                      }} 
                      
                      >
                        {this.props.fromDate}
                      </span>

                    }
                  />
                </Col>
                <Col sm={4} style={{borderLeft: "1px solid #0F83BC"}}
                // className="align-self-center"
                //  style={{ marginLeft: "252px", marginTop: "-57px", borderLeft: "1px solid #0F83BC" }}
                 >
                  <Item
                    label={
                      <span className={css.surveyheader} style={{
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        //color: "#0F83BC",
                        fontWeight: "500"
                      }}>
                        {this.props.ctx_labels.singular.dueDates}
                      </span>}
                    value={
                      <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 400 }} className="text-left">
                        {this.props.toDate}
                      </span>
                    }
                  />

                </Col>
              </Row>

          <Row className="survey_head_wrapper border_class_sur_wrapper" style={{ display: 'flex' }}>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}>
                    {this.props.ctx_labels.singular.attended}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, marginLeft: "25px" }} className="text-left">
                    {this.props.attended}
                  </span>
                }
              />
            </Col>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500",
                    marginLeft: "0px"
                  }}>
                    {this.props.ctx_labels.singular.unattended}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "43px" }} >
                    {this.props.unattended}
                  </span>
                }
              />
            </Col>
            <Col sm={4} style={{ flex: 1, borderLeft: "1px solid #0F83BC" }}>
              <Item
                label={
                  <span className={css.surveyheader} style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: "500"
                  }}>
                    {this.props.ctx_labels.singular.totalQuestion}
                  </span>}
                value={
                  <span style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#001B4A', fontWeight: 700, textAlign: 'center', marginLeft: "40px", marginBottom: "10px" }} className="text-left">
                    {this.props.tot_questions}
                  </span>
                }
              />
            </Col>
          </Row>
        </div>
      </Row>
        </MediaQuery>
        

        <MediaQuery minWidth={1200}>
          <StyledPublish>
          <div className={`${css.wrapper} `}>
            <Row>
              
              <Col lg={3} xl={2} className="border-0 align-self-center" style={{ marginTop: "15px", }}>
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      // color: "#0F83BC",
                      fontWeight: "500",
                      marginLeft: "20px"
                      
                    }}> 
                      {this.props.ctx_labels.singular.survey}
                    </span>}
                    
                  value={
                    <OverlayTooltip tip={this.props.shortName} placement="top" >
                      <span style={{ marginLeft: "20px" }} className={css.surveyheaders}>
                      {this.props.shortName && this.props.shortName.length >= 15 ? (this.props.shortName.slice(0, 15) + "......") :this.props.shortName}
                    </span>   
                      </OverlayTooltip>
                  }
                />


              </Col>
              <Col sm={6} md={4} lg={3} xl={2} className="align-self-center" style={{ marginTop: "15px", }}>
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      // fontFamily: "Poppins",
                      // fontSize: "13px",
                      // color: "#0F83BC",
                      // fontWeight: "500"
                      marginLeft: "42px"
                    }}>
                      {this.props.ctx_labels.singular.startDate}
                    </span>}
                  value={
                    <span className={css.surveyheaders} >
                      {this.props.fromDate}
                    </span>

                  }
                />
              </Col>
              {/* ./col */}
              <Col sm={6} md={4} lg={3} xl={2} className="align-self-center" style={{ marginTop: "15px" }}>
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      // fontFamily: "Poppins",
                      // fontSize: "13px",
                      // color: "#0F83BC",
                      // fontWeight: "500"
                      marginLeft: "42px"
                    }}>
                      {this.props.ctx_labels.singular.dueDates}
                    </span>}
                  value={
                    <span className={css.surveyheaders}>
                      {this.props.toDate}
                    </span>
                  }
                />

              </Col>
              <Col sm={6} md={4} lg={3} xl={2} className="align-self-center" style={{ marginTop: "15px" }}>
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      // fontFamily: "Poppins",
                      // fontSize: "13px",
                      // color: "#0F83BC",
                      // fontWeight: "500",
                      marginLeft: "60px"
                    }}>
                      {this.props.ctx_labels.singular.attended}
                    </span>}
                  value={
                    <span style={{ marginLeft: "85px" }} className={css.surveyheaders}>
                      {this.props.attended}
                    </span>

                  }
                />
              </Col>

              <Col sm={6} md={4} lg={3} xl={2} className="align-self-center" style={{ marginTop: "15px" }}>
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      // fontFamily: "Poppins",
                      // fontSize: "13px",
                      // color: "#0F83BC",
                      // fontWeight: "500",
                      marginLeft: "50px"
                    }}>
                      {this.props.ctx_labels.singular.unattended}
                    </span>}
                  value={
                    <span style={{  marginLeft: "85px" }} className={css.surveyheaders}>
                      {this.props.unattended}
                    </span>

                  }
                />
              </Col>
              <Col sm={6} md={4} lg={3} xl={2} className="align-self-center" style={{ width: 'auto', marginTop: "15px", }} >
                <Item
                  label={
                    <span className={css.surveyheader} style={{
                      // fontFamily: "Poppins",
                      // fontSize: "13px",
                      // color: "#0F83BC",
                      // fontWeight: "500",
                      marginLeft: "30px"
                    }}>
                      {this.props.ctx_labels.singular.totalQuestion}
                    </span>}
                  value={
                    <span style={{  marginLeft: "75px" }} className={css.surveyheaders}>
                      {this.props.tot_questions}
                    </span>

                  }
                />
              </Col>
              {/* <Col sm={4} className={`${css.col_number} d-sm-flex mb-0`}>
          <ItemNumber label={this.props.ctx_labels.singular.attended} 
          //value={this.props.attended} 
          />
        </Col> */}

              {/* ./col */}
            </Row>
          </div>
          </StyledPublish>
        </MediaQuery>
      </>
      
      

    );


  };


}
export default withLabelsContext(
  withApiContext(withUserContext(TrainerOverview))
);



