import { withSiteContext, withUserContext, withApiContext } from "context";
import styled from "styled-components";
import SidebarToggle, {
  CompLogo,
  CreditsDropdown,
  TraineeDropdown,
  Title,
  Logo,
} from "./components";
import { OverlayTooltip } from "components";
import css from "./scss/header.module.scss";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { IconEnvelope } from "Icons";
import * as config from "config";
import { useMediaQuery } from "react-responsive";

const StyledHeader = styled.header`
  && {
    border-bottom-color: ${(props) => props.theme.primary};
  }
`;

const Notification = ({ messages,messagesPolling}) => {
  return (
    <div className={css.notification}>
      <div className={css.notification_button}>
        <IconEnvelope
          className={css.notification_icon}
          width="31"
          height="31"
        />
        {messagesPolling && <span className={css.notification_badge}>{messages}</span>}
      </div>
    </div>
  );
};

function Header(props) {
  const [messages, setMessages] = useState(0);
  const[isRole, setisRole] = useState(true);
  // const [pollUrl, setPollUrl] = useState(props.ctx_user.user.pollUrl);
  let notifRefreshTimer;
  const logo = !props.loggedIn
    && props.ctx_site.site?.logo 
    ? props.ctx_site.site.logo 
    : props.ctx_user.user?.logo
    ? props.ctx_user.user.logo
    : null;
    const campusLogo =
      props.loggedIn && props.ctx_user.user?.coBrand
          ? props.ctx_user.user.coBrand.image
        : !props.loggedIn && props.ctx_site.site.coBrandImage
          ? props.ctx_site.site.coBrandImage
        : null;
  const campusList = props.loggedIn ? props.ctx_user.user.campusList : null;
  const organizationId = props.loggedIn
    ? props.ctx_user.user.organizationId
    : null;
  const roleId = props.loggedIn ? props.ctx_user.user.roleId : null;
  const campus =
    props.loggedIn &&
    campusList.find((d) => d.campus_role_id == `${organizationId} : ${roleId}`);
  const title = props.loggedIn
    ? campus
      ? campus.organization_nm
      : props.ctx_site.site.siteName
    : props.ctx_site.site.siteName;

  //   const poll =  props.ctx_user.user.enablePoll;
  
  //   const enablePoll = poll && campus.member_type === 1 || campus.member_type === 2;
   
  // const photo = props.ctx_user.user.pollImage;

  useEffect(() => {
    if (props.loggedIn) {
      const { type, newMessagePolling } = props.ctx_user.user;
      if (type === 1 || type === 2) {
        fetchNewMessages();
        if(newMessagePolling){
        notifRefreshTimer = setInterval(
          fetchNewMessages,
          1000 * config.sideMenuRefreshIntervalSecs
        )};
      }
      if(props.role =='Reporter' || type == 5){
        setisRole(false)
      }else{setisRole(true)}
      return () => {
        if (notifRefreshTimer) {
          clearInterval(notifRefreshTimer);
        }
      };
    }
  }, []);

  const fetchNewMessages = () => {
    props.ctx_api
      .privateRequest({
        cmd: "get_new_messages",
      })
      .then(handleGetNewMessagesResponse())
      .catch(handleGetNewMessagesFailure);
  };

  const handleGetNewMessagesResponse =
    () =>
    ({ rsp }) => {
      const messageCount = rsp[0].new_message_count;
      setMessages(messageCount);
    };

  const handleGetNewMessagesFailure = (err) => {
    console.log(err);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 767px)" });

  
  // const handlePolls = () => {
  //   window.open (pollUrl, "_blank");
  //     }


  return (
    <>
      <StyledHeader className={css.container}>
        <div className={css.inner}>
          <div className={css.left}>
          <div>
            {isTabletOrMobile && props.loggedIn && <SidebarToggle 
              isSidebarOpen={props.isSidebarOpen}
            />}
            </div>
            <Logo logo={logo} Expiredate={props.ctx_user.user && props.ctx_user.user.passwordExpiry}/>
            <Title title={title} />
          </div>
          <div className={css.right}>
            {props.loggedIn && (
              <>
                {campus && 
               ([1, 2,5].includes(campus.member_type))
                 ? (
                  <>
              {/* {enablePoll && (
                <OverlayTooltip
              tip= "Rate Us"
              id="poll"
              placement="bottom"
            >
              <div  className = {css.pollIcon} onClick = {handlePolls}><img src={photo} alt="" /></div>
            </OverlayTooltip>
            )} */}
                    <Link to="/chat">
                      {isRole && < Notification 
                        messages={messages} 
                        messagesPolling={props.ctx_user.user.newMessagePolling}
                      />}
                    </Link>

                    {/* <CreditsDropdown
                      credits={campus.total_points}
                      memberLevel={props.ctx_user.user.level}
                      memberType={props.ctx_user.user.type}
                    /> */}
                  </>
                ) : null}
                  {campus && props.ctx_user.user.showCredits && (props.ctx_user.user.type === 1 || props.ctx_user.user.type === 2)
               // ([1, 2, 4,5].includes(campus.member_type)) 
                ? (
                  <>
                    {/* <Link to="/chat">
                      <Notification messages={messages} />
                    </Link> */}

                    <CreditsDropdown
                      credits={campus.total_points}
                      memberLevel={campus.member_level_name}
                      memberType={props.ctx_user.user.type}
                    />
                  </>
                ) : null}
                <TraineeDropdown
                  acadYearName={campus ? campus.acad_year_name : null}
                  orgName={campus ? `${campus.organization_name} - ${campus.role_name}` : null}
                  onOrgSwitch={props.onCampusSwitch}
                />
              </>
            )}
            <CompLogo logo={campusLogo} />

            {!isTabletOrMobile && props.loggedIn && !props.noSidebar && <SidebarToggle />}
          </div>
        </div>
      </StyledHeader>
      <div className={css.space}></div>
    </>
  );
}

export default withSiteContext(withUserContext(withApiContext(Header)));
