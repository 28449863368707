import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Fade } from "react-bootstrap";
import { Button } from "components";
import { PlayArrow } from "../icons/PlayArrow";
import "./Continue.css";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import * as config from "config";
import classNames from 'classnames';
class Continue extends PureComponent {
  constructor(props) {
    super(props);
    const label = props.label;
    this.state = {
      label,
      pendingLabel: label,
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    label: "CONTINUE",
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.label !== this.props.label) {
      this.setState((s0) => ({
        label: newProps.visible ? newProps.label : s0.label,
        pendingLabel: newProps.label,
      }));
    }
  };

  handleEnter = () => {
    // Update the label in case it changed while the button was hidden
    this.setState((s0) => ({ label: s0.pendingLabel }));
  };

  // We must manually disable Button when it is hidden, as using the disabled
  // prop interferes with Fade
  render = () => {
    // const {
    //   type,
    //   course_publish_id,
    //   course_name,
    //   curriculum_id,
    //   course_id,
    // } = this.props.subjectData;
    const screenSize = window.innerWidth < 768 ? false : true;
    return (
      <div visibility={this.props.visible ? "visible" : "hidden"}>
        <Fade in={this.props.visible} onEnter={this.handleEnter}>
          {!this.props.playerOnly && !this.props.preview && this.props.playerState === "concluding" && this.props.subjectData? (
            <Link
              to={{
                pathname: this.props.subjectData.type === "C" ? config.ROUTES.traineeCurriculum : "/",
                subjectData: this.props.subjectData.type === "C" && {
                  coursePublishId: this.props.subjectData.course_publish_id,
                  courseName: this.props.subjectData.course_name,
                  curriculumId: this.props.subjectData.curriculum_id,
                  courseId: this.props.subjectData.course_id,
                  type: this.props.subjectData.type,
                  course_list:this.props.subjectData.course_list,
                  is_multicourse:this.props.subjectData.is_multicourse,
                  class_id: this.props.subjectData.class_id,
                  
                },
              }}
            >
              <Button
                // disabled={this.props.playerOnly}
                iconOnly
                // className="player-continue-button"
                // onClick={this.props.visible ? this.props.onClick : null}
                variant="danger"
                style={this.props.visible ? null : { cursor: "default" }}
                // className={classNames(!screenSize && this.props.fromChapterInner && "continue_button")}
                // className="continue_button"
              >
                <span>
                  <MediaQuery minDeviceWidth={768}>
                    {this.state.label}
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={767}>
                    <PlayArrow />
                  </MediaQuery>
                </span>
              </Button>
            </Link>
          ) : (
            <Button
              iconOnly
              // className="player-continue-button"
              onClick={this.props.visible ? this.props.playerOnly && this.props.playerState === "concluding" ? null : this.props.onClick : null}
              variant="danger"
              style={this.props.visible ? null : { cursor: "default" }}
            >
              <span>
                <MediaQuery minDeviceWidth={768}>{this.state.label}</MediaQuery>
                <MediaQuery maxDeviceWidth={767}>
                  <PlayArrow />
                </MediaQuery>
              </span>
            </Button>
          )}
        </Fade>
      </div>
    );
  };
}

export default Continue;
