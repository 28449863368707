import { Select, ToggleButton } from "components";
import { IconSortAsc, IconSortDesc } from "Icons";
import React, { useState } from "react";
import css from "./toolbar-sort.module.scss";
import { withLabelsContext } from "context";
import * as config from 'config';
import PropTypes from "prop-types";
import context from "react-bootstrap/esm/AccordionContext";


// export default function ToolbarSort() {
	class ToolbarSort extends React.Component{
		constructor(props) {
			super(props);
			this.state = {
			  sort: '',
			  order:"asc"
			//   sortBase: 'asc',
			//   filter: '',
			//   isAssessmentLoaded: false,
			//   assessments: {},
			};
			}
	// const [order, setOrder] = useState("asc");
	setOrder=(value) =>{
		this.setState({
			order: value

		})
		const obj = {'type':this.state.sort, 'order':value};
        this.props.onSelectType(obj);

	}
	setOrder2=(value) =>{
		this.setState({
			order: value

		})
		const obj = {'type':this.state.sort, 'order':value};
        this.props.onSelectType(obj);
		

	}

	
	handleTypeChange = (value) => {
		this.setState({
			sort: value

		});
    
		const obj = {'type': value, 'order':this.state.order};
        this.props.onSelectType(obj);            
    }
	render(){
		return (
			<div className={css.wrapper}>
				<div className={css.dropdown}>
					<label className={css.label}>{this.props.ctx_labels.singular.sortBy}</label>
					<Select
						options={this.props.options}
						// className={css.select_width}
						// style={{width: "0px"}}
						defaultValue={{ value: "sequence", label: "Sequence" }}
						onChange={this.handleTypeChange}
						value={this.state.sort}
						placeholder={this.props.placeholder}
						// label={this.props.ctx_labels.singular.sortBy}
					/>
				</div>
				<div className={css.buttons}>
					<ToggleButton
						checked={this.state.order === "asc"}
						type="radio"
						name="order"
						value="asc"
						onChange={(e) => this.setOrder(e.currentTarget.value)}
						iconOnly
					>
						<IconSortAsc />
					</ToggleButton>
					<ToggleButton
						checked={this.state.order === "desc"}
						type="radio"
						name="order"
						value="desc"
						onChange={(e) => this.setOrder2(e.currentTarget.value)}
						iconOnly
					>
						<IconSortDesc />
					</ToggleButton>
				</div>
			</div>
		);

	}

	
}
 export default withLabelsContext(ToolbarSort)
