import Select from "./Select";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SelectMulti from "./SelectMulti";
import { IconCalendarMenu } from "Icons";
class AsyncSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
    };
  }

  static propTypes = {
    optionName: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired,
    refresh: PropTypes.bool.isRequired,
    onFetched: PropTypes.func,
    onError: PropTypes.func,
  };

  static defaultProps = {
    refresh: false,
  };

  componentDidMount = () => {
    this.doFetch();
  };

  componentWillReceiveProps(nextProps) {
    if ((nextProps.refresh && !this.props.refresh) || (nextProps.selectedOption !== this.props.selectedOption)) {
      this.doFetch();
    }
  };

  doFetch = () => {
    this.props
      .fetch()
      .then(this.handleFetchResponse)
      .catch(this.handleFetchFailure)
      .finally(this.finalizeFetch);
  };

  handleFetchResponse = (options) => {
    this.setState({ options });
  };

  handleFetchFailure = (err) => {
    if (this.props.onError) {
      this.props.onError(err);
    }
  };

  finalizeFetch = () => {
    if (this.props.onFetched) {
      this.props.onFetched();
    }
  };

  render = () => {
    const {
      optionName,
      fetch,
      refresh,
      onFetched,
      onError,
      multi,
      ...restProps
    } = this.props;

    const SelectComponent = multi ? SelectMulti : Select;
    return (
      <SelectComponent
        async
        {...restProps}
        placeholder={ this.props.academicYear ? <IconCalendarMenu title=""/> :`${this.props.ctx_labels.singular.select} ${optionName}...`}
        options={this.props.standardId ? (this.state.options ? this.state.options.filter(option => option.categoryId === this.props.standardId) : []) : (this.state.options || [])}
      />
    );
  };
}

export default AsyncSelect;