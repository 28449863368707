import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import "./input-field.scss";
import classNames from "classnames";
import { rgba } from "polished";

// theme.
const StyledInputField = styled(Form.Control)`
  border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }

  &.input-overlight {
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    background-color: ${({ theme }) => rgba(theme.primary, 0.05)};

    &:focus {
      border-color: ${({ theme }) => theme.primary};
    }
  }
`;

// Extend bootstrap form control
export default function InputField({ overLightBg, error, value, ...props }) {
  return (
    <StyledInputField
      onWheel={(e) => props.type === "number" && e.target.blur()}
      className={classNames(overLightBg && "input-overlight")}
      value={typeof value === "object" ? "" : value}
      {...props}
    />
  );
}
