import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import BlockUploadPdf from "components/BlockUploadPdf/BlockUploadPdf";
import './UploadButtonAssign.css';




class UploadButtonAssign extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    accept: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    isAssignment: PropTypes.bool
  };

  render = () => {
    const className = ['upload-button', this.props.className].join(' ');
    return (
      <BlockUploadPdf
        type="application/pdf"
        maxSize={this.props.maxSize}
        minSize={this.props.minSize}
        multiple={false}
        onPdfDrop={this.props.onSelect}
        isAssignment={this.props.isAssignment}
      // upFileName={this.props.upFileName}
      />
      // <Dropzone
      //   className={className}
      //   // onDrop={() => console.log('acceptedFiles')}
      //   // accept={this.props.accept}
      //   onDrop={this.props.onSelect}
      //   multiple={false}
      //   minSize={this.props.minSize}
      //   maxSize={this.props.maxSize}
      // >
      //   {this.props.children}
      // </Dropzone>
    );
  }
}


export default UploadButtonAssign
