import React, { useContext, useState } from 'react';
import { Accordion, Card, Row, Col } from "react-bootstrap";
import styled, { useTheme } from "styled-components";
import css from "./testCreation.module.scss";
import { IconChevronDownNew, IconQuestionEdit, IconChevronUpNew, IconQuestionDelete, IconEmptySection } from "Icons";
import { TestContext } from './Context/TestContex';
import { rgba } from "polished";
import { withLabelsContext } from 'context';
const EditButton = styled.div`
  cursor: pointer;
  svg {
    fill: #4f4f4f;
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

const DeleteButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
  flex: 1;
  gap: 1rem;
  svg {
    stroke: #4f4f4f;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.primary};
    }
  }
`;

function FeedbackCard(props) {
  const { sections, setSections, setFeedBackEdit } = useContext(TestContext);
  const [openAccordion, setOpenAccordion] = useState(null);
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": "#E9E9E9",
  };
  const handleToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const findFeedbackArray = (selected) => {
    return sections.find((obj) => obj.section_order === selected);
  };

  const feedBackArray = findFeedbackArray(props.sectionOrder);

  const handleEdit = (index) => {
    setFeedBackEdit({
        
        isEdit: true,
        index: index
  });
};
  const handleDelete = (index) => {
    const updatedSections = sections.map((section) =>
      section.section_order === props.sectionOrder
        ? {
            ...section,
            feedBacks: section.feedBacks.filter((_, i) => i !== index),
          }
        : section
    );
    setSections(updatedSections);
  };

  const feedBackRender = () => {
    return (
      <>
  {feedBackArray?.feedBacks?.length > 0 ? (
    feedBackArray.feedBacks.map((item, i) => {
      const data = item;
      const isOpen = openAccordion === i;
      return (
        <Accordion
          id={isOpen ? css.border : null}
          style={{ borderBottom: "1px solid lightgrey" }}
          key={i}
          activeKey={isOpen ? "0" : ""}
        >
          <Card style={{ width: "100%", border: "none", backgroundColor: "transparent" }}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className={css.quecardHeader}
              onClick={() => handleToggle(i)}
              style={{
                cursor: "pointer",
                borderBottom: "none",
                backgroundColor: "transparent",
              }}
            >
              <div className={css.headerAlign}>
                <div style={{ flex: "1", display: "flex", gap: "1rem" }}>
                {props.ctx_labels.singular.range}
                  <div className={css.borderboxAlign}>
                    {`${data.min} -> ${data.max}`}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <EditButton onClick={() => handleEdit(i)}>
                    <IconQuestionEdit />
                  </EditButton>
                  <DeleteButton onClick={() => handleDelete(i)}>
                    <IconQuestionDelete />
                  </DeleteButton>
                  <div>
                    {isOpen ? (
                      <IconChevronUpNew onClick={() => handleToggle(i)} />
                    ) : (
                      <IconChevronDownNew onClick={() => handleToggle(i)} />
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className={css.queCardBody}>
                <Row>
                  <Col md={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className={css.borderboxAlign}
                      dangerouslySetInnerHTML={{
                        __html: `${data.feedbackdescription}`,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div className={css.taggedWrap} style={inlineBackground}>
                      {data.courseTag.map((tag, index) => {
                        const label = props.taggedCourses.filter((itm)=>itm.value === tag)
                        return (
                        <div key={index} className={css.feedcourseTag}>
                          {label[0].label}
                        </div>
                      )})}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    })
  ) : (
    <div className={css.sectionEmpty}>
    <IconEmptySection />
    No feedbacks have been created, they will be displayed here once available.
  </div>
  )}
</>

    );
  };

  return feedBackRender();
}

export default withLabelsContext(FeedbackCard);
