import React from 'react';
import { ClosedCaption as ClosedCaptionIcon } from '../icons/ClosedCaption';
import './Captions.css';




export default ({ textTracks, onClick, onItemClick, className, ariaLabel }) => {
    return (
        <div className={[
            'video-captions',
            className
        ].join(' ')}>
            <button
                type="button"
                onClick={onClick}
                aria-label={ariaLabel}
                className="video-captions-button">
                <ClosedCaptionIcon
                    className="video-captions-icon"
                    fill="#fff" />
            </button>
            <ul className="video-captions-track-List">
                { textTracks && [...textTracks].map((track) => (
                    <li
                        key={track.language}
                        className={track.mode === track.SHOWING || track.mode === 'showing'
                            ? 'video-captions-track-item video-captions-active-track-item'
                            : 'video-captions-active-track-item'}
                        onClick={onItemClick.bind(this, track)}>
                        { track.label }
                    </li>
                ))}
            </ul>
        </div>
    );
};
