import classNames from "classnames";
import { Button } from "components";
import { withLabelsContext } from "context";
import { IconChevronDown, IconChevronUp, IconFilter } from "Icons";
import React from "react";
import css from "./filter-dropdown.module.scss";



const FilterDropdown = (props) => {
  const screenSize = window.innerWidth < 768 ? false : true;
  return (
    <>
      {props.fromZonePage ? (
        <>
          {!screenSize ? (
            <div id="filter" {...props} className={css.filter_mob}>
              <IconFilter style={{ width: "15px", marginTop: "-3px" }} />
            </div>
          ) : (
            <Button
              id="filter"
              variant="white"
              {...props}
              className={classNames(
                css.button,
                props.className ?? ""
              )}
            >

              <IconFilter />
              <span>{props.ctx_labels.singular.filter}</span>
              {props.isOpen ? (
                 <IconChevronUp width="10" height="10" />
                
              ) : (
                <IconChevronDown width="10" height="10" />
              )}
            </Button>

          )}
        </>
      ) : (
        <Button
          id="filter"
          variant="white"
          {...props}
          className={classNames(
            css.button,
            props.isOpen && css.button_open,
            props.className ?? ""
          )}
        >
          <IconFilter />
          {(!props.fromCB && 
          <>
          <span>{props.ctx_labels.singular.filter}</span> 
          <IconChevronDown width="10" height="10" className={css.button_arrow} />
          </>
          )}
        </Button>
      )}
    </>
  );

};

// FilterDropdown.Panel = FilterPanel;



export default withLabelsContext(FilterDropdown);