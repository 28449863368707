import React from "react";
import PropTypes from "prop-types";
import { CardContent, CardGrid, OverlayTooltip } from "components";
import { Col, Row, Card, ProgressBar, Button,Tooltip,OverlayTrigger  } from "react-bootstrap";
// import css from "./card-assessment.module.scss";
import { withLabelsContext } from "context";
import {
  IconPencil,
  IconRocket,
  IconTrash,
} from "Icons";
import { useTheme } from "styled-components"
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Redirect, Link } from "react-router-dom";
import css from "./card_assessment.modules.scss"

const StyledSide = styled.div`
.assessment {
  background: ${({ theme }) => theme.color3};
`;

const StyledPublish = styled.div`
    && {
        .${css.surveyheader1} {
            color: ${({ theme }) => theme.primary};
        }
        .${css.surveyheader2}{
          color: ${({ theme }) => theme.color2};
        }
        .custom-progress-color2 .progress-bar {
          background-color: ${({ theme }) => theme.color3};
      }

        .custom-progress-color3 .progress-bar {
          background-color: ${({ theme }) => theme.color4}; 
      }
    }
`;

function CardSurvey({
  data,
  name,
  tot_questions,
  audience,
  fromDate,
  toDate,
  attended,
  unattended,
  tot_students,
  ...props
}) {
  const attemptProgress = ((tot_students - unattended) / tot_students) * 100;
  const surveyName = name && name.length >= 15 ? (name.slice(0, 15) + " ...") : name
  const surveyAudience =audience && audience.length >= 30 ? (audience.slice(0, 30) + "...") : audience
  const theme = useTheme();
  const tooltipEdit = <Tooltip id="edit-tooltip">Edit Publish Survey</Tooltip>;
  const tooltipView = <Tooltip id="view-tooltip">View</Tooltip>;
  const tooltipDel = <Tooltip id="del-tooltip">Delete Publish Survey</Tooltip>;



  return (

    // <Row>
    <>
      {/* <CardGrid.Item > */}
      <CardContent {...props} className="card-align">
        <Card.Body className="pb-2"
        >
          <>
            <Row>
              <MediaQuery minDeviceWidth={768} maxDeviceWidth={820}>
                <Col md={4}
                  onClick={(e) => props.onCardClicked(props.itm)}>
                  <OverlayTooltip
                    placement="top"
                    tip={name}>
                    <h5 className="name_h5">{surveyName}</h5>
                  </OverlayTooltip>
                  <OverlayTooltip
                    placement="top"
                    tip={audience}>
                    <div className="audience_card">{surveyAudience}</div>
                  </OverlayTooltip>
                </Col>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={767}>
                <Col 
                  onClick={(e) => props.onCardClicked(props.itm)}>
                  <OverlayTooltip
                    placement="top"
                    tip={name}>
                    <h5 className="name_h5">{surveyName}</h5>
                  </OverlayTooltip>
                  <OverlayTooltip
                    placement="top"
                    tip={audience}>
                    <div className="audience_card">{surveyAudience}</div>
                  </OverlayTooltip>
                </Col>
              </MediaQuery>
              <MediaQuery minDeviceWidth={821}>
                <Col md={6} style={{paddingRight:"0px"}}
                  onClick={(e) => props.onCardClicked(props.itm)}>
                  <OverlayTooltip
                    placement="top"
                    tip={name}>
                    <h5 className="name_h5">{surveyName}</h5>
                  </OverlayTooltip>
                  <OverlayTooltip
                    placement="top"
                    tip={audience}>
                    <div className="audience_card">{surveyAudience}</div>
                  </OverlayTooltip>
                </Col>
                {/* <Col md={1}></Col> */}
              </MediaQuery>
              <Col>
                <Row>
                <MediaQuery maxDeviceWidth={374}>
                  <Col style={{padding:"0px", marginLeft:"35px"}}
                    onClick={(e) => props.onCardClicked(props.itm)}>
                    <h5 className="questions_card">{tot_questions} </h5>
                    <div style={{ color: `${theme.color4}`, fontSize: "11px", fontWeight: "400" }}>Questions</div>
                  </Col>

                  </MediaQuery>
                  <MediaQuery minDeviceWidth={375} maxDeviceWidth={898}>
                  <Col style={{padding:"0px", marginLeft:"46px"}}
                    onClick={(e) => props.onCardClicked(props.itm)}>
                    <h5 className="questions_card">{tot_questions} </h5>
                    <div style={{ color: `${theme.color4}`, fontSize: "11px", fontWeight: "400" }}>Questions</div>
                  </Col>

                  </MediaQuery>
                  <MediaQuery minDeviceWidth={899}>
                  <Col style={{padding:"0px", marginLeft:"35px"}}
                     onClick={(e) => props.onCardClicked(props.itm)}>
                    <h5 className="questions_card">{tot_questions} </h5>
                    <div style={{ color: `${theme.color4}`, fontSize: "11px", fontWeight: "400" }}>Questions</div>
                  </Col>
                  </MediaQuery>   
                  <Col style={{ justifyContent: "end", display: "inline-grid" , paddingLeft:"0px"}}>
                    {/* <Button id="publish"></Button> */}

                    {/* <Row> */}
                    <OverlayTrigger placement="top" overlay={tooltipEdit}>
                    <Button
                      onClick={(e) => props.onCardClicked(props.itm)}
                      iconOnly
                      variant="white"
                      id="edit"
                      size="sm"
                      className="ml-0"
                      style={{ padding: "3px 5px 3px 5px", marginBottom: "5px" ,borderRadius:"5px"}}
                    >
                      <IconPencil style={{marginRight:"0px", marginBottom:"0px"}}/>
                    </Button>
                    </OverlayTrigger>
                    {/* </Row>
                  <Row> */}
                    <Link
                      to={{ pathname: props.redirectionRoute, entityData: props.itm }}
                    >
                      <OverlayTrigger placement="top" overlay={tooltipView}>
                      <Button
                        iconOnly
                        variant="white"
                        size="sm"
                        className="ml-0"
                        id="publish"
                        style={{ padding: "3px 5px 3px 5px", marginBottom: "5px" ,borderRadius:"5px"}}
                        onClick={(e) => props.onCardViewClicked(props.itm)}
                      >
                        <IconRocket style={{marginRight:"0px", marginBottom:"0px"}}/>
                      </Button>
                      </OverlayTrigger>
                    </Link>
                    {/* </Row>
                    <Row> */}
                    <OverlayTrigger placement="top" overlay={tooltipDel}>
                    <Button
                      iconOnly
                      variant="white"
                      size="sm"
                      id="delete"
                      className="ml-0"
                      style={{ padding: "3px 5px 3px 5px", marginBottom: "5px", borderRadius:"5px" }}
                      onClick={(e) => props.onCardDelete(props.itm)}
                      // disabled={props.itm.attended !== 0}
                    >
                      <IconTrash style={{marginRight:"0px", marginBottom:"0px"}}/>
                    </Button>
                    </OverlayTrigger>
                    {/* </Row> */}
                  </Col>
                </Row>

              </Col>
            </Row>
          </>
        </Card.Body>
        {/* {!props.hideDetails && */}
        <Card.Footer className="pt-0" style={{ paddingBottom: "14px" }} onClick={(e) => props.onCardClicked(props.itm)}>
          <>
            <Row style={{ paddingBottom: "6px" }}>
              <Col>
                <div style={{ color: `${theme.color3}`}} className="date_card1">Start</div>
                <div className="date_card">{fromDate}</div>
              </Col>
              <Col style={{ textAlign: "end" }}>
                <div style={{ color: `${theme.color3}`}} className="date_card1">End</div>
                <div  className="date_card">{toDate}</div>
              </Col>
            </Row>
            {/* <Row> */}
            <StyledPublish style={{ paddingBottom: "3px" }}>
              <ProgressBar now={attemptProgress} style={{ height: "6px" }} small={true} className='custom-progress-main custom-progress-color2' />
            </StyledPublish>
            <div style={{ color: `${theme.color4}` ,display:"flex",justifyContent:"space-between"}} className={css.attended_card}><div className={css.fromWhere}>{props.coursePublishId?"c":"s"}</div>{attended}/{tot_students} attended</div>
            {/* </Row> */}
          </>

        </Card.Footer>
      </CardContent>
    </>

  );
}

CardSurvey.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  tot_questions: PropTypes.number,
  audience: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  attended: PropTypes.number,
  tot_students: PropTypes.number,
  asessments: PropTypes.number,
};

export default withLabelsContext(CardSurvey);
