import PropTypes from "prop-types";
import css from "./grid.module.scss";
import classNames from "classnames";
import { withLabelsContext } from 'context';

function Grid(props) {
  const { question, selected, selectedItemId, rand, attempted, unattempted, flagged, qpool } = props;
  let count = 0;
  let max_question = 0;

  return (
    <div className={css.numbers}>
      {question &&
        question.map((section, i) => {
          const qCount = `any ${section.max_questions} out of ${section.questions.length}`
          let subTitle
          if (section.is_limit === 1) {
            subTitle = `Attempt ${qCount} ${props.ctx_labels.plural.question.toLowerCase()}`;
          } else if (section.is_limit === 0) {
            subTitle = +section.max_questions === section.questions.length;
          } else {
            return " ";
          }
          // let max_question = section_order === 1 && 0;
          // const max_question = max_question + section.max_questions

          return (
            <>
              {Object.values(section.questions)
                .sort((a, b) => {
                  if (qpool) {
                    return question.indexOf(a) - question.indexOf(b);
                  } else {
                    if (rand === 0) {
                      return a.sequence - b.sequence;
                    } else {
                      return 0;
                    }
                  }
                })
                .map((item, j) => {
                  count = count + 1;
                  if (item.attempted === 1 && attempted === true) {

                    return (
                      <span
                        style={{ cursor: "pointer" }}
                        key={item.id}
                        className={classNames(
                          item.attempted === 1 && css.completed,
                          item.attempted === 2 && css.skip,
                        )}
                        renderedOn="form"
                        selectable
                        selected={selectedItemId === item.id ? true : false}
                        onClick={() => selected(item.id)}
                      >
                        {count}
                      </span>
                    );
                  }
                  if (item.attempted === 0 && unattempted === true) {

                    return (
                      <span
                        style={{ cursor: "pointer" }}
                        key={item.id}
                        className={classNames(
                          item.attempted === 1 && css.completed,
                          item.attempted === 2 && css.skip,
                        )}
                        renderedOn="form"
                        selectable
                        selected={selectedItemId === item.id ? true : false}
                        onClick={() => selected(item.id)}
                      >
                        {count}
                      </span>
                    );
                  }
                  if (item.flagged === 1 && flagged === true) {

                    return (
                      <span
                        style={{ cursor: "pointer" }}
                        key={item.id}
                        className={classNames(
                          item.flagged === 1 && css.current
                        )}
                        renderedOn="form"
                        selectable
                        selected={selectedItemId === item.id ? true : false}
                        onClick={() => selected(item.id)}
                      >
                        {count}
                      </span>
                    );
                  }
                })}
            </>
          )
        }
        )}
    </div>
  );
}

Grid.propTypes = {
  // title: PropTypes.string,
  // numbers: PropTypes.array, item: PropTypes.object.isRequired,
  renderedOn: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  deletable: PropTypes.bool,
  reorderable: PropTypes.bool,
  sequence: PropTypes.number,
};

Grid.defaultProps = {
  renderedOn: "form", // 'form' or 'modal'
  deletable: false,
  selectable: false,
  selected: false,
  reorderable: false,
  // title: "",
  // numbers: [
  //   {
  //     label: "1",
  //   },
  // ],
};

export default withLabelsContext(Grid);
