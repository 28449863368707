import { DashboardPanel } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Card } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "styled-components";

function WeeklyTimeConsumption(props) {
  const theme = useTheme();
  const AreaChart = ({ graphData }) => {
    const xAxis = graphData.map((item) => item._id);
    const colors = [theme.primary,theme.color2];
    const formattedDates = xAxis.map((date) => {
      const format = { day: "numeric", month: "short", year: "2-digit" };
      return new Date(date).toLocaleDateString("en-GB", format);
    });
  
    const data01 = [...graphData].map((entry) => {
      return entry.total_duration;
    });
    const data02 = [...graphData].map((entry) => {
      return entry.total_active_study_time;
    });
  
    const series = [
      {
        name: props.ctx_labels.singular.totalTime,
        data: data01,
        label: "Time",
      },
      {
        name: props.ctx_labels.singular.activeTime,
        data: data02,
        label: "Time",
      },
    ];
  
    const options = {
      chart: {
        height: 300,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: formattedDates,
        labels: {
          datetimeUTC: false,
          format: "dd/MMM/yy HH:mm",
        },
        title: {
          text: props.ctx_labels.singular.date,
        },
      },
      colors: colors,
      yaxis: [
        {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
          title: {
            text: props.ctx_labels.singular.timehr,
            style: {
              fontSize: "12px",
            },
          },
        },
      ],
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
        y: {
          formatter: function (value) {
            const hours = Math.floor(value);
            const minutes = Math.round((value % 1) * 60);
            return `${hours}h ${minutes}min`;
          },
        },
      },
    };
  
    return (
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={300}
        />
      </div>
    );
  };
  return (
    <DashboardPanel title={`${props.ctx_labels.singular.weeklyTime} ${props.ctx_labels.singular.consumption}`} fullScreen>
      <Card body className="border-0">
        {props.data && props.data.length > 0 ? (
          <AreaChart graphData={props.data} />
        ) : (
          "There is no data."
        )}
      </Card>
    </DashboardPanel>
  );
}

WeeklyTimeConsumption.propTypes = {};

export default withLabelsContext(WeeklyTimeConsumption);
