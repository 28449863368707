import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// import MediaQuery from "react-responsive";
import "./Embedded.css";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";

class Embedded extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    module: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
  };

  componentDidMount = () => {
    // Allow the user to indicate when he is done with the content
    this.props.onAllViewed();
  };

  componentDidUpdate = () => {
    // Allow the user to indicate when he is done with the content while 
    this.props.onAllViewed();
  }

  componentWillUnmount = () => {
    // this.reportModuleProgress();
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
  };

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: 0,
        maxViewedPos: 0,
      });
    }
  };

  renderControls = () => (
    <div className={window.innerWidth > 822 ? (this.props.crsePrev && this.props.module.type === "HTMLEditor" ? `${cmCss.toolbar_embed2} bg-color2 text-white` : `${cmCss.toolbar_embed} bg-color2 text-white`) : `${cmCss.toolbar} bg-color2 text-white`}>
      <div className={cmCss.toolbar_left}>&nbsp;</div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );

  render = () => {
    const m = this.props.module;
    const blanked = this.props.minPos === this.props.maxPos;
    const HTMLEditorStyle = m.type === "HTMLEditor" && this.props.isFullscreen
    const htmlstyle = window.screen.width < 822 ? window.screen.height - 90 : window.screen.height

    const {
      type,
      isFullscreen,
      frmRndrprvw,
      frmChprprvw,
      crsePrev
    } = this.props;

    const frameClass = m.type === "HTMLEditor"
      ? isFullscreen
        ? "htmleditor-frame-ful"
        : "htmleditor-frame" :
      isFullscreen && frmRndrprvw ? "embed-frame-full-rndrprvw" :
        isFullscreen && frmChprprvw ? "embed-frame-full-chpprvw" :
          crsePrev ? "embed-frame-crsePrev" :
            frmRndrprvw ? "embed-frame-Rndrprvw" :
              "embed-frame";

    return (
      <div
        className={[
          "embedded",
          this.props.isFullscreen ? "embed-fullscreen" : this.props.frmChprprvw ? "" : "embed-window",
          this.props.className,
        ].join(" ")}
      >
        {/* <MediaQuery minDeviceWidth={768}> */}
        <div className={frameClass}>
          {/* <iframe src={m.url} title={m.name} allowFullScreen={false} /> */}
          {this.props.isFullscreen ? (
            <div
              id="iframe-full"
              className="innerEmbed-frame-full"
              dangerouslySetInnerHTML={{ __html: m.url }}
            />
          ) : (
            <div
              className="innerEmbed-frame"
              dangerouslySetInnerHTML={{ __html: m.url }}
            />
          )}
          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        {/* </MediaQuery> */}

        {/* <MediaQuery maxDeviceWidth={767}>
        <div className="html-frame">
          <iframe src={m.url} title={m.name} allowFullScreen={false} />
          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        </MediaQuery> */}
        {this.renderControls()}
      </div>
      //  <div dangerouslySetInnerHTML={{ __html: m.url}}/>
    );
  };
}

export default Embedded;
