import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import HtmlIframe from "pages/Module/Html/Iframe";

import { withApiContext } from "context";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import "./Scorm.css";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import classNames from 'classnames';

const CloseButtonWrapper = styled.div`
  height: 45px;
  background-color: ${({ theme }) => theme.color2};
  color: #fff;
  font-weight: bolder;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

class Scorm extends PureComponent {
  constructor(props) {
    super(props);
    const { lastViewedPos, maxViewedPos } = props;
    this.state = {
      lastViewedPos,
      maxViewedPos,
      registrationId: null,
      launchId: null,
      launchUrl: null,
      launchUrlCopy: null,
    };
  }

  static propTypes = {
    className: PropTypes.string,
    configId: PropTypes.string.isRequired,
    module: PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      subContentId: PropTypes.string.isRequired,
    }).isRequired,
    lastViewedPos: PropTypes.number.isRequired,
    maxViewedPos: PropTypes.number.isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    // onDismissed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    onError: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
  };

  componentDidMount = () => {
    this.fetchLaunchInfo();
    this.addIframeExitListener();
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.getCourseId(nextProps) !== this.getCourseId()) {
      this.fetchLaunchInfo(nextProps);
    }
    if (
      nextProps.playerState === "dismissing" &&
      this.props.playerState !== "dismissing"
    ) {
      this.dismissCourse(nextProps);
    }
  };

  componentWillUnmount = () => {
    this.finalizeCourse();
    this.removeIframeExitListener();
    this.reportModuleProgress();
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
  };

  addIframeExitListener = () => {
    window.addEventListener("message", this.handleIframeExit, false);
  };

  removeIframeExitListener = () => {
    window.removeEventListener("message", this.handleIframeExit);
  };

  getCourseId = (props = this.props) => props.module.subContentId;

  fetchLaunchInfo = (props = this.props) => {
    const courseId = this.getCourseId(props);
    if (courseId === null) {
      return;
    }
    this.props.ctx_api
      .privateRequest({
        cmd: "start_scorm_course",
        args: {
          courseId,
          coursePublishId: props.coursePublishId,
          videoUnitId: props.module.contentId,
        },
      })
      .then(({ rsp }) => {
        const { registrationId, launchId, launchUrl } = rsp;
        this.setState({
          registrationId,
          launchId,
          launchUrl,
          launchUrlCopy: launchUrl,
        });
      })
      .catch((err) => {
        console.log("SCORM launch info fetch error: ", err.message);
        if (props.onError) {
          props.onError(err);
        }
      });
  };

  dismissCourse = (props) => {
    this.setState({ launchUrl: null });
    this.props.onScormClosed("close");
    // Delay to ensure course has a chance to push update to server
    setTimeout(() => this.finalizeCourse(props), 3000);
  };

  finalizeCourse = (props = this.props) => {
    const { registrationId, launchId } = this.state;
    if (registrationId === null || launchId === null) {
      // props.onDismissed();
      return;
    }
    this.props.ctx_api
      .privateRequestRow({
        cmd: "stop_scorm_course",
        args: { registrationId, launchId },
      })
      .then(({ rsp }) => {
        this.props.onScormClosed("continue");
        const {
          last_viewed_pos: lastViewedPos,
          max_viewed_pos: maxViewedPos,
        } = rsp;
        const complete = lastViewedPos === 1 && maxViewedPos === 1;
        !complete && this.setState({ launchUrl: this.state.launchUrlCopy });
        const cb = complete
          ? this.props.onAllViewed
          : this.reportModuleProgress;
        this.setState({ lastViewedPos, maxViewedPos }, cb);
      })
      .catch((err) => {
        console.log("SCORM latest progress fetch error: ", err.message);
        if (props.onError) {
          props.onError(err);
        } else {
          console.log("err")
          // props.onDismissed();
        }
      });
  };

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      const { lastViewedPos, maxViewedPos } = this.state;
      this.props.onModuleProgress({ lastViewedPos, maxViewedPos });
    }
  };

  handleIframeExit = (e) => {
    if (e.data === "SCORM cloud exited") {
      this.finalizeCourse();
    }
  };

  renderIframe = () => {
    const url =
      this.props.minPos === this.props.maxPos ? null : this.state.launchUrl;
    return (
      <HtmlIframe
        className="player-scorm-iframe"
        title={this.props.module.name || "player SCORM iframe"}
        url={url}
        allowFullScreen={false}
        onLoad={this.handleIframeLoad}
      />
    );
  };

  renderControls = () => {
    const screenSize = window.innerWidth<821? false:true;
  return(
    <div className={classNames(screenSize ? `${cmCss.toolbar} bg-color2 text-white` : `${cmCss.toolbar_scorm} bg-color2 text-white`)}>
    <MediaQuery minDeviceWidth={1025}>
    <div className={cmCss.toolbar_left}>&nbsp;</div>
      <div className={cmCss.toolbar_center}></div>
      </MediaQuery>  
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );
}

  render = () => {
    const blanked = this.props.minPos === this.props.maxPos;
    return (
      <div
        className={[
          "player-scorm",
          this.props.isFullscreen
            ? "player-scorm-fullscreen"
            : "player-scorm-window",
          this.props.className,
        ].join(" ")}
      >
         <MediaQuery minDeviceWidth={1025}>
        <div className="player-scorm-frame">
          <div
            style={{
              height: "92vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CloseButtonWrapper>
              <div
                
                style={{ fontSize: "20px", padding: "0 10px 0 0", cursor: "pointer" }}
                onClick={this.dismissCourse}
              >
                x
              </div>
            </CloseButtonWrapper>
            {this.renderIframe()}
          </div>

          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1024}>
        <div className="player-scorm-frame">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CloseButtonWrapper>
              <div
                
                style={{ fontSize: "20px", padding: "0 10px 0 0", cursor: "pointer" }}
                onClick={this.dismissCourse}
              >
                x
              </div>
            </CloseButtonWrapper>
            {this.renderIframe()}
          </div>

          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}
        </div>
        </MediaQuery>
        {this.renderControls()}
      </div>
      
    );
  };
}

export default withApiContext(Scorm);
