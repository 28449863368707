import React from 'react';
import './Seek.css';



export default ({
   currTimePercent,
   minSeekPercent,
   maxSeekPercent,
   maxMaxSeekPercent,
   highlightSeekable,
   onChange,
   onMouseDown,
   onMouseUp,
   hidden,
   ariaLabel,
}) => (
  <div
    className="video-seek"
    style={hidden ? { visibility: 'hidden' } : null}
  >
    {!isNaN(currTimePercent) && (
      <div className="video-seek-track">
        <div
          className="video-seek-max-max"
          style={{ width: `${maxMaxSeekPercent}%` }}
        />
        <div
          className="video-seek-prefill"
          style={{ width: `${minSeekPercent}%` }}
        />
        <div
          className="video-seek-fill"
          style={{
            left:  `${minSeekPercent}%`,
            width: `${currTimePercent - minSeekPercent}%`,
          }}
        />
        {highlightSeekable && (
          <div
            className="video-seek-highlight"
            style={{
              left:  `${minSeekPercent}%`,
              width: `${maxSeekPercent - minSeekPercent}%`,
            }}
          />)}
          <form id="novalidation-form" noValidate />
          <input
            min={minSeekPercent}
            step="any"
            max={maxSeekPercent}
            type="range"
            required
            form="novalidation-form"
            orient="horizontal"
            onChange={onChange}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
            aria-label={ariaLabel}
            value={currTimePercent}
            disabled={hidden}
            style={{
              left:  `${minSeekPercent}%`,
              width: `${maxSeekPercent - minSeekPercent}%`,
              padding: 0
            }}
          />
        </div>
    )}
  </div>);
