import PropTypes from "prop-types";
import css from "./section.module.scss";
import classNames from "classnames";
import { withLabelsContext } from 'context';
import  Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
function Section(props) {
  const { question, selected, selectedItemId, rand } = props;
  let count = 0;

  return (
    <>
      {question &&
        question.map((section, i) => {
          const qCount = `any ${section.max_questions} out of ${section.questions.length}`
      let subTitle 
        if (section.is_limit === 1) {
          subTitle = `Attempt ${qCount} ${props.ctx_labels.plural.question.toLowerCase()}`;
        } else if (section.is_limit === 0) {
          subTitle = +section.max_questions === section.questions.length;
        } else {
          return " ";
        }  
        
                        return(
            
          <>
           {/*
         */}
       

       {  question[0].content || question.description ?
       <div className={css.section}>
      <div className={`${css.title} text-color2`}/> 
  
       </div>
       : <></> }
               
            <div className={css.section}>
              <div className={`${css.title} text-color2`}>
              {/* {  console.log(section.section_name)} */}
              
              {section.section_name}
              </div>
              <div className={`text-color2`}>
              {subTitle}
              {/* ddsfdfdfdfd dfgfgfgfg */}
              </div>
              <br />
              <div className={css.numbers}>
              {Object.values(section.questions)
                   .sort((a, b) => rand === 0 ? a.sequence - b.sequence : 0)
                  .map((item, j) => {
                    count = count + 1;
                    return (
                      <span
                        style={{ cursor: "pointer" }}
                        key={item.id}
                        className={classNames(
                          item.attempted === 1 && css.completed,
                          item.attempted === 2 && css.skip,
                          item.flagged === 1 && css.current
                        )}
                        renderedOn="form"
                        selectable
                        selected={selectedItemId === item.id ? true : false}
                        onClick={() => selected(item.id)}
                      >
                        {count}
                      </span>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        )}
    </>
  );
}

Section.propTypes = {
  // title: PropTypes.string,
  // numbers: PropTypes.array, item: PropTypes.object.isRequired,
  renderedOn: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  deletable: PropTypes.bool,
  reorderable: PropTypes.bool,
  sequence: PropTypes.number,
};

Section.defaultProps = {
  renderedOn: "form", // 'form' or 'modal'
  deletable: false,
  selectable: false,
  selected: false,
  reorderable: false,
  // title: "",
  // numbers: [
  //   {
  //     label: "1",
  //   },
  // ],
};

export default withLabelsContext(Section);
