import React, { useContext, useMemo, useRef, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { styled, useTheme } from "styled-components";
import FormField from "components/FormField";
import TaggedCourseSelect from "components/Select/TaggedCourseSelect";
import {
  TestContext,
  withTestContext,
} from "pages/TestGeneration/Context/TestContex";
import "jodit";
import {
  withApiContext,
  withLabelsContext,
  withUserContext,
  withWindowContext,
} from "context";
import { max } from "moment";
import css from "./testCreation.module.scss";
import { Button, OverlayTooltip, Textarea } from "components";
import SectionCard from "./SectionCard";
import FeedbackCard from "./FeedbackCard";
import CourseTagSelect from "./CourseTagSelect";

function FeedbackTest(props) {
  const theme = useTheme();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const editor = useRef(null);
  const {
    testData,
    testFeedback,
    setTestFeedback,
    sections,
    setSections,
    feedBackEdit,
    setFeedBackEdit,
  } = useContext(TestContext);
  const [testId, setTestId] = useState("");
  const [selectedSec,setSelectedSec] = useState({});
  const [feedback, setFeedback] = useState({
    min:"",
    max:"",
    feedbackdescription:"",
    courseTag:[]
  });
  const [rightTab, setRightTab] = useState({
    feedBack: true,
    section: false,
  });
  const [error, setError] = useState([]);
  const [selectedTaggedCourses, setSelectedTaggedCourses] = useState([]);
  const configs = useMemo(
    () => ({
      height: 300,
      allowResizeY: false,
      hidePoweredByJodit: true,
      readonly: false,
      buttons: "bold,italic,eraser,fontsize,\n,brush,paragraph,left,copyformat",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      toolbarButtonSize: "small",
      maxHeight: 300,
      useSearch: false,
      removeButtons: [
        "source",
        "superscript",
        "subscript",
        "outdent",
        "indent",
        "image",
        "file",
        "video",
        "table",
        "link",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "copyformat",
        "selectall",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
        "preview",
        "find",
        "replace",
        "inserthorizontalrule",
      ],
    }),
    []
  );
  const validateFeedback = (feedback) => {
    let messages = [];
  
    if (feedback.min === "") {
      messages.push('Min value is required.');
    }
  
    if (feedback.max === "") {
      messages.push('Max value is required.');
    }
  
    if (feedback.feedbackdescription === "") {
      messages.push('Feedback description is required.');
    }

    if(feedback.max <= feedback.min){
      messages.push('Max value must be greater than min value.');
    }

    if (!feedback.edit && !isValidRange(feedback.min, feedback.max)) {
      messages.push('The new range overlaps with an existing range.');
    }
  
    return messages;
  };

  useEffect(() => {
  }, [sections]);

  useEffect(() => {
    const messages = validateFeedback(feedback);
    setIsSaveDisabled(messages.length > 0);
    setError(messages);
  }, [feedback]);


  useEffect(() => {
    setFeedback((prev) => ({
      ...prev,
      courseTag: selectedTaggedCourses,
    }));
  }, [selectedTaggedCourses]);

  useEffect(() => {
    const section = sections.find(
      (section) => section.section_order === props.sectionOrder
    );
    setSelectedSec((prev)=>section);

    if (feedBackEdit.isEdit) {
      const feedbackToEdit =
        sections[props.sectionOrder - 1]?.feedBacks[feedBackEdit.index];
      if (feedbackToEdit) {
        setFeedback({...feedbackToEdit,edit:true});
        setSelectedTaggedCourses(feedbackToEdit.courseTag)
      }
    }
  }, [feedBackEdit, props.sectionOrder, sections]);

  const isValidRange = (newMin, newMax) => {
    const feedBackList = selectedSec.feedBacks
    if(feedBackList && feedBackList.length>0){
    for (let range of feedBackList) {
      if (
        (newMin >= range.min && newMin <= range.max) ||
        (newMax >= range.min && newMax <= range.max) ||
        (newMin <= range.min && newMax >= range.max)
      ) {
        return false;
      }
    }
  }
    return true;
  };
  

  const editReset = () => {
    setFeedBackEdit({ isEdit: false, index: 0 });
    setFeedback({ min :  "",
    max: "",
    feedbackdescription:"",
    courseTag : []
  });
  setSelectedTaggedCourses([]);
  };

  const handleEditFeedback = (index, item) => {
    setSections((prev) => {
      let currentPrev = prev;
      let feedEdit = prev[props.sectionOrder - 1].feedBacks;
      if (feedEdit.length > index) {
        feedEdit[index] = item;
      }
      currentPrev[props.sectionOrder - 1].feedBacks = feedEdit;
      return [...currentPrev];
    });
  };

  const handleSave = () => {
    if (feedBackEdit.isEdit) {
      handleEditFeedback(feedBackEdit.index, feedback);
      editReset();
      return true;
    }

    const updatedSections = sections.map((section) => {
      if (section.section_order === props.sectionOrder) {
       
          const updatedFeedBacks = section.feedBacks ? [...section.feedBacks, feedback] : [feedback] ;
        
        return {
          ...section,
          feedBacks: updatedFeedBacks,
        };
      
      }
      return section;
    });
    setSections(updatedSections);
    editReset();
    setFeedback({
      min:"",
      max:"",
      feedbackdescription:"",
      courseTag : []
    });
    setSelectedTaggedCourses([]);
  };

  const handleCancel = () => {
    props.setTabButton({
      questionnaire: true,
      feedback: false,
      preview: false,
    });
    props.setRightTab({
      feedBack: false,
      section: true,
    });
    props.handleReset()
    editReset();
  };

  const handleFeedBack = (data) => {
    if (data.name === "min" || data.name === "max"){
      if(data.value!==""){
      setFeedback((prev) => {
        const feedback = { ...prev, [data.name]: +data.value };
        return feedback;
      });  
     }
     else{
      setFeedback((prev) => {
        const feedback = { ...prev, [data.name]: null };
        return feedback;
      });
     }
    }
    else{
      setFeedback((prev) => {
        const feedback = { ...prev, [data.name]: data.value };
        return feedback;
      });
    }
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              gap: "3rem",
            }}
          >
            <div>{props.ctx_labels.singular.range}</div>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}
              >
                <div>{props.ctx_labels.singular.min}<span style={{ color: "red" }}>*</span></div>
                <FormField
                  type="number"
                  name="min"
                  value={feedback.min}
                  max={100}
                  onChange={handleFeedBack}
                  placeholder="Min"
                  style={{ padding: "5px", width: "80px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-around",
                  gap: "1rem",
                }}
              >
                <div>{props.ctx_labels.singular.max}<span style={{ color: "red" }}>*</span></div>
                <FormField
                  type="number"
                  name="max"
                  onChange={handleFeedBack}
                  max={100}
                  value={feedback.max}
                  placeholder={props.ctx_labels.singular.max}
                  style={{ padding: "5px", width: "80px" }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <FormField
                componentClass="textarea"
                className="input-full"
                type={JoditEditor}
                label= "Feedback"
                name="feedbackdescription"
                value={feedback.feedbackdescription}
                config={configs}
                editorRef={editor}
                onChange={handleFeedBack}
                placeholder={props.ctx_labels.singular.questionDescription}
                required
              />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <CourseTagSelect
            subject={testData.curriculumId}
            standard={testData.categoryId}
            Id={testData.testId}
            onChange={setSelectedTaggedCourses}
            value={selectedTaggedCourses}
            setSelectedTaggedCourses={setSelectedTaggedCourses}
            isEdit = {feedback.edit??false}           
          />
        </Col>
      </Row>

      <Row>
        <Col md={12} className={css.questionButton}>
          <OverlayTooltip
          tip={
            <div>
                  <ul style={{ textAlign: "left" }}>
                    {error.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
          }
          placement="top"
          disabled={error.length===0}
          fromTestSec
          >
          <Button
            className={css.rectangleButton}
            style={{
              backgroundColor: theme.primary,
              borderColor: theme.primary,
            }}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
             {props.ctx_labels.singular.save}
          </Button>
          </OverlayTooltip>
          
          <Button
            className={css.rectangleButton}
            variant="btn-outline-cancel"
            onClick={handleCancel}
          >
            {props.ctx_labels.singular.cancel}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default withLabelsContext(FeedbackTest);
