import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from "../../Question/question.module.scss";
import { Form, Modal } from "react-bootstrap";
import MathLive, { convertLatexToMarkup } from "mathlive";
import MathInput from "components/MathInput/MathInput";
import { Col, Row } from "react-bootstrap";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";

import * as config from "config";
import { Last } from "react-bootstrap/esm/PageItem";
import MediaQuery from "react-responsive";
import { HelpfulButton, Textarea} from "components";
import { IconAudio, IconFlag, IconPDF, IconVideo } from "Icons";
import styled from "styled-components";

const StyledPlayerButton = styled.span`
  && {
    .media_mul{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
    .media_mul-flag{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
  }`

class FillInTheBlank extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    comments: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered: PropTypes.func.isRequired,
    onReview: PropTypes.func.isRequired,
    onAttempted: PropTypes.func.isRequired,
  };

  initState = {
    userAnswer: "",
    typoFound: false,
    matchIdx: null,
    submit: false,
    matchFound: false,
    hide: true,
    show: false,
  };

  componentDidMount = () => {
    const { user_selection } = this.props;
    if (user_selection) {
      // alert(user_selection[0])
      this.setState({ userAnswer: user_selection[0] });
    }
    this.changeState(false);
  };

  changeState = (state) => {
    this.setState({ hide: state });
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.question !== this.props.question) {
      this.setState(this.initState);
      if (newProps.user_selection) {
        this.setState({ userAnswer: newProps.user_selection[0] });
      }
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (
      _prevProps.question !== this.props.question &&
      this.props.math_symbol === 1
    ) {
      this.changeState(true);
      setTimeout(() => this.changeState(false), 1);
    }
    if (this.state.submit !== prevState.submit) {
      const { onAttempted, onAnswered } = this.props;
      const response = [this.state.userAnswer];
      this.state.userAnswer.length > 0 ? onAttempted(true) : onAttempted(false);
      onAnswered(response);
    }
  };

  handleChange = (e) => {
    if (this.props.math_symbol === 1) {
      this.setState({ userAnswer: e });
    } else {
      this.setState({ userAnswer: e.target.value });
    }
  };

  handleKeyPress = (e) => {
    if (e.which === 13) {
      this.handleSubmit(e);
    }
  };

  handleSubmit = (e) => {
    this.setState((s0) => ({ submit: !s0.submit }));
  };

  handleClick = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  renderInput = (a) => {
    // const inputAnswers = this.props.answers.join("\n");
    // const sample = <h1>hello</h1>;
    
    return (
      <div>
        <form id="test-answer-form">
          <div className="test-answer-input">
            {this.props.math_symbol === 1 ? (
              this.state.hide ? (
                <></>
              ) : (
                <MathInput
                  disabled={true}
                  id="fillBlanks"
                  value={a}
                  maxLength={config.maxTestAnswerLength}
                  placeholder={config.testAnswerPlaceholder}
                  onChange={this.handleChange}
                  onBlur={this.handleSubmit}
                  onKeyPress={this.handleKeyPress}
                />
              )
            ) : (
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="1"
                  disabled={true}
                  value={a}
                  style = {(this.props.content_format ==3) ? {marginTop:"30px", marginLeft:"-18px"}:{}}
                  maxLength={config.maxTestAnswerLength}
                  placeholder={config.testAnswerPlaceholder}
                  onChange={this.handleChange}
                  onBlur={this.handleSubmit}
                  onKeyPress={this.handleKeyPress}
                  form="test-answer-form"
                  autoFocus
                />
              </Form.Group>
            )}

            {/* <div className="test-answer-line">
            <input
              // disabled={this.state.matchIdx !== null}
              value={this.state.userAnswer}
              maxLength={config.maxTestAnswerLength}
              placeholder={config.testAnswerPlaceholder}
              onChange={this.handleChange}
              onBlur={this.handleSubmit}
              onKeyPress={this.handleKeyPress}
              form="test-answer-form"
              autoFocus
            />
          </div> */}
          </div>
        </form>
        {/* {this.state.typoFound && (
        <div className="test-answer-line-footer">
          Please try again...perhaps you misspelled your answer
        </div>
      )} */}
      </div>
    );
  };

  renderModalBody = () => {
    return <img src={this.props.image} />;
  };
  renderImage = () => {
    return <img src={this.props.media_link} />;
  };
  renderMedia = () => {
    let currLink = this.props.content_source == "external" ? this.props.content: this.props.media_link;
    const currType = this.props.content ? this.props.content_format : "";
    let MediaPlayer;
    switch (currType) {
      case 1:
        MediaPlayer = Video;
        break;
      case 0:
        MediaPlayer = Audio;
        break;
      case 2:
        MediaPlayer = Pdf;
        break;
      default:
        MediaPlayer = React.Fragment;
        break;
    }
    return (
      <div  style={currType == "1" ? {paddingBottom:"3rem"} : {}}>
        <MediaPlayer 
        className="ov" 
        url={currLink} 
        pdfUrl={currLink}
        mediaId="fill-in-the-blanks-media-player" />
      </div>
    );
  };

  handleMedia = () => {
    this.setState({ show: true })
  }

  handleFlagClick = () => {
    const newIsFlag = !this.state.flag;
    this.props.isFlaged(newIsFlag); // Pass data to the parent component
    this.setState({ flag: newIsFlag });
  }

  handleImageClick = () =>{
    this.setState({show : !this.state.show})
  }

  render = () => {
    const { content, media_link, content_format } = this.props;
    const questionImage =
    content ?
    content.match(/^data:image\/\w+;base64,/) !== null ?
    content 
    : media_link
    : "";
    const mediaLabel =
      this.props.content_format === 0
        ? "Audio"
        : this.props.content_format === 1
        ? "Video"
        : this.props.content_format === 2
        ? "PDF"
        : "";
    return(
    <>
      <Modal
        animation={false}
        show={this.state.show}
        onHide={this.handleClose}
        scrollable={true}
        centered
        size={this.props.content_format === 0 ? "sm" : "lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.content_format === 0
            ? "Audio"
            : this.props.content_format === 1
              ? "Video"
              : this.props.content_format === 2
                ? "Pdf"
                : this.props.content_format === 3
                  ? "Image"
                  : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.props.content_format === 3 && this.renderImage()}
            {(this.props.content_format !== 3 && this.props.content_format !== null) && this.renderMedia()}
          </div>
        </Modal.Body>
      </Modal>
      {this.props.disabled && <div style={{ color: "red" }}>
        {this.props.disabledText}
      </div>}
      <div className={`${css.question} text-color2`}>
        <StyledPlayerButton>
          <MediaQuery minWidth={822}>
          <Row>
            {(this.props.content_format !== 3 && this.props.content_format !== null && this.props.content) ? (
              <>
                <Col md={10} sm ={12}>
                  {this.props.math_symbol === 1 ? (
                    this.state.hide ? (
                      <></>
                    ) : (
                      <>
                        QN{this.props.questionNumber}.
                        <MathInput
                          disabled={true}
                          id="fillBlanksQuestion"
                          value={this.props.question}
                        />
                      </>
                    )
                  ) : (
                    <div className='question-style'>
                      QN{this.props.questionNumber}. {this.props.question}
                    </div>
                  )}
                </Col>
                <Col md={2} className="d-flex justify-content-end">
                    <>
                    {this.props.content &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {this.props.content_format === 0 ? (
                          <IconAudio width={10} height={10} fill="white" />
                        ) : this.props.content_format === 1 ? (
                          <IconVideo width={10} height={10} fill="white" />
                        ) : this.props.content_format === 2 ? (
                          <IconPDF width={10} height={10} fill="white" />
                        ) : (
                          ""
                        )}
                      </HelpfulButton>}
                      {this.props.fromChapterInner && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </>
                </Col>

              </>
            ) :
              (
                <>
                  <Col md={11}>
                    {this.props.math_symbol === 1 ? (
                      this.state.hide ? (
                        <></>
                      ) : (
                        <>
                          QN{this.props.questionNumber}.
                          <MathInput
                            disabled={true}
                            id="fillBlanksQuestion"
                            value={this.props.question}
                          />
                        </>
                      )
                    ) : (
                      <div>
                        QN{this.props.questionNumber}. {this.props.question}
                      </div>
                    )}
                  </Col>
                    <Col md={1} className="d-flex justify-content-end">
                     { this.props.fromChapterInner && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </Col>
                </>
              )}
          </Row>
          </MediaQuery>
          <MediaQuery maxWidth={821}>
          <Row>
              <>
                <Col  md= {12} sm ={12}>
                  {this.props.math_symbol === 1 ? (
                    this.state.hide ? (
                      <></>
                    ) : (
                      <>
                        QN{this.props.questionNumber}.
                        <MathInput
                          disabled={true}
                          id="fillBlanksQuestion"
                          value={this.props.question}
                        />
                        {(this.props.content_format !== 3 && this.props.content_format !== null && this.props.content) && (
                      <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {this.props.content_format === 0 ? (
                          <IconAudio width={10} height={10} fill="white" />
                        ) : this.props.content_format === 1 ? (
                          <IconVideo width={10} height={10} fill="white" />
                        ) : this.props.content_format === 2 ? (
                          <IconPDF width={10} height={10} fill="white" />
                        ) : (
                          ""
                        )}
                      </HelpfulButton>
                        )}
                     { this.props.fromChapterInner && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </>
                    )
                  ) : (
                    <>
                    <div className='question-style'>
                      QN{this.props.questionNumber}. {this.props.question}
                    </div>
                    
                    {(this.props.content_format !== 3 && this.props.content_format !== null && this.props.content) && (
                        <HelpfulButton
                          iconOnly
                          size="sm"
                          pill
                          className="media_fill rounded-circle" // Add the rounded-circle class
                          onClick={this.handleMedia}
                          tip = {"Click here to view " + mediaLabel}
                          placement = "top"
                        >
                          {this.props.content_format === 0 ? (
                            <IconAudio width={10} height={10} fill="white" />
                          ) : this.props.content_format === 1 ? (
                            <IconVideo width={10} height={10} fill="white" />
                          ) : this.props.content_format === 2 ? (
                            <IconPDF width={10} height={10} fill="white" />
                          ) : (
                            ""
                          )}
                        </HelpfulButton>
                    )}
                        {this.props.fromChapterInner && <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_fill-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                      </>
                  )}
                  
                </Col>
              </>
          </Row>
          </MediaQuery>
          
        </StyledPlayerButton>


      </div>
      {this.props.content_format === 3 && this.props.content && (
        <Row>
          <img className = "question-image" src = {this.props.fromChapterInner ? this.props.content :  questionImage } onClick={() => this.handleImageClick(this.props.content)}/>
        </Row>)}
        {(this.props.description ||this.props.question_content)&&
        <Row>
        <Col sm={12} md={12} lg={12} className="description_fill" dangerouslySetInnerHTML={{ __html: this.props.description ||this.props.question_content }} />
      </Row>}
      <Row><Col>{this.props.fromChapterInner ? this.renderInput() : this.props.answers.map(a => this.renderInput(a))}</Col>
      
      {!this.props.fromChapterInner && this.props.solution && (
                <Col md={12} >
                  <label style={{ fontWeight: 500, fontSize: "15px" }}>Solution</label>
                  <Textarea
                  className="text-area"
                    // type={Textarea}
                    readOnly={true}
                    value={this.props.solution}
                  />
                </Col>
              )}
     </Row>
   
    
    </>);
  }

}

export default FillInTheBlank;
