import React from 'react';

import { PlayArrow as PlayArrowIcon } from '../icons/PlayArrow';
import { Pause     as PauseIcon     } from '../icons/Pause';

import './PlayPause.css';


export default ({ onClick, paused, hidden, className, ariaLabelPlay, ariaLabelPause }) => {
    return (
        <div
          className={[
            'video-play-pause',
            className
          ].join(' ')}
          style={hidden ? { visibility: 'hidden' } : null}
        >
            <button
                className="video-play-pause-button"
                onClick={onClick}
                disabled={hidden}
                aria-label={ paused
                    ? ariaLabelPlay
                    : ariaLabelPause }
                type="button">
                { paused
                    ? <PlayArrowIcon
                        className="video-play-pause-icon"
                        height={34}
                        fill="#fff" />
                    : <PauseIcon
                        className="video-play-pause-icon"
                        height={34}
                        fill="#fff" /> }
            </button>
        </div>
    );
};
