import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import labels from "./labels";
import "./Video.css";
// import Captions from "./Video/Captions";
import Captions from "../../components/Player/Video/Captions"
import Overlay from "components/Overlay/Overlay";
import FullScreen from "../../components/PlayerNew/Video/FullScreen";
import PlayPause from "./Video/PlayPause";
import Seek from "components/Seek/Seek";
import Time from "components/Time/Time";
import Volume from "./Video/Volume";
import { withUserContext } from "context";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import audioPlaceholder from '../../assets/images/audio-placeholder.gif';
import pausedImage from '../../assets/images/paused-image.png';
import styled from "styled-components";

const StyledBottomBar = styled.div`
  width: 100%;
  height: 50px;
  border: 1px solid;
  background-color: ${({ theme }) => theme.color2}; /* Remove the extra characters here */
`;




const defaultControlsSpec = [
  "PlayPause",
  "Seek",
  "Time",
  "Volume",
  "FullScreen"
];

class Video extends Component {
  constructor(props) {
    super(props);
    this.pausePos = null;
    this.initPos = props.minPos;
    this.spinnerVisible = false;
    this.errorVisible = false;
    this.errorMessage = null;
    this.state = {
      pip: false,
      playing: props.autoPlay ? props.autoPlay : false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      fullScreen:  window.innerWidth < 769 ? this.isFullscreenNow() : "",
      isShown: false,
    };
  }

  static propTypes = {
    url: PropTypes.string,
    minPos: PropTypes.number,
    maxPos: PropTypes.number,
    playDisabled: PropTypes.bool.isRequired,
    playArrowVisible: PropTypes.oneOf([true, false, "onHover"]),
    onReady: PropTypes.func,
    onCommandReceived: PropTypes.func,
    onUserCommand: PropTypes.func,
    controlsSpec: PropTypes.arrayOf(PropTypes.oneOf(defaultControlsSpec))
      .isRequired,
    noValidateFormId: PropTypes.string,
    video: PropTypes.object.isRequired
  };

  static defaultProps = {
    playDisabled: false,
    playArrowVisible: true,
    controlsSpec: defaultControlsSpec,
    video: {}
  };

  componentDidMount = () => {
    this.handleCommandReceived("load");
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.minPos !== null && this.props.minPos === null) {
      if (nextProps.minPos < 0) {
        this.handleCommandReceived("pause", nextProps);
        this.onPause();
      } else if (nextProps.minPos === nextProps.maxPos) {
        this.handleCommandReceived("seek", nextProps);
        this.pauseAt(nextProps.minPos);
      } else if (nextProps.minPos < nextProps.maxPos) {
        this.handleCommandReceived("play", nextProps);
        this.resumeAt(nextProps.minPos);
        this.pausePos = nextProps.maxPos;
      }
    }

    if (nextProps.url !== this.props.url) {
      this.initPos = nextProps.minPos;
    }

    if (nextProps.url) {
      if (nextProps.video.error) {
        if (nextProps.video.error.message !== this.errorMessage) {
          this.onError(nextProps);
        }
        this.errorVisible = true;
      }
      this.errorMessage = nextProps.video.error
        ? nextProps.video.error.message
        : null;
    }

    if (
      nextProps.playDisabled &&
      !this.props.playDisabled &&
      !this.state.playing
    ) {
      this.onPause();
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.url !== prevProps.url) {
      this.handleCommandReceived("reload");
      this.setState({
        played: 0,
        loaded: 0,
        pip: false
      });
    }
  };

  onReady = () => {
    this.errorVisible = false;
  };
  onDuration = duration => {
    this.setState({ duration });
    if (this.props.onReady) {
      this.errorVisible = false;
      this.props.onReady(
        duration,
        null // TODO: find a way to find out the aspect ratio of video
      );
    }
    if(this.props.fromCB){
   this.props.content(this.state.duration)
    }
  };


  toggleFullScreen = pane => () => {
    this.setState({ fullScreen: !this.state.fullScreen });
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }

    this.setState({ fullScreen: !this.state.fullScreen });
  };

  playPause = () => {
    this.handleUserCommand(this.state.playing ? "pause" : "play");
    this.setState({ playing: !this.state.playing });
  };
  stop = () => {
    this.setState({ url: null, playing: false });
  };
  pauseAt = val => {
    this.setState(s0 => {
      return {
        played: val / s0.duration,
        playing: false
      };
    }, this.player.seekTo(val / this.state.duration));
  };
  resumeAt = val => {
    this.setState(s0 => {
      return {
        played: val / s0.duration,
        playing: true
      };
    }, this.player.seekTo(val / this.state.duration));
  };
  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };
  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };
  setPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };
  onPlay = () => {
    this.setState({ playing: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  togglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };
  onEnablePIP = () => {
    this.setState({ pip: true });
  };
  onDisablePIP = () => {
    this.setState({ pip: false });
  };
  onSeekMouseDown = e => {
    this.setState({ seeking: true });
  };
  onSeekChange = e => {
    this.handleUserCommand("seek");
    this.setState({ played: parseFloat(e.target.value) / 100 });
  };
  onSeekMouseUp = e => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value) / 100);
  };
  onProgress = state => {
    if (!this.state.seeking) {
      // We only want to update time slider if we are not currently seeking
      this.setState(state);
    }
    // Since time updates are only sent every ~200 ms, we try to stop within
    // 0.1 s of the target
    const posLimit = this.pausePos - 0.1;
    // We can't use the video.currentTime prop, as it has not been updated yet
    if (this.pausePos !== null && posLimit <= state.playedSeconds) {
      this.handleCommandReceived("pause");
      this.pausePos = null;
      this.onPause();
    }
  };
  onEnded = () => {
    // this.setState({ playing: this.state.loop })
  };
  onError = e => {
    const format = this.props.audio ? "audio" : "video"
    let message =
    e.target && e.target.error ? e.target.error.message : `Invalid ${format} URL`;
    if (this.props.onError) {
      this.props.onError({ message });
    }
    this.errorVisible = true;
  };
  onClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player))
  };
  ref = player => {
    this.player = player;
  };

  stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleCommandReceived = (cmd, props = this.props) => {
    this.spinnerVisible = cmd !== "seek";
    if (props.onCommandReceived) {
      props.onCommandReceived(cmd);
    }
  };

  handleUserCommand = cmd => {
    this.spinnerVisible = true;
    if (this.props.onUserCommand) {
      this.props.onUserCommand(cmd);
    }
  };

  renderSeek = (key, hidden) => {
    return (
      <Seek
        currTimePercent={this.state.played * 100}
        minSeekPercent={0}
        maxSeekPercent={100}
        maxMaxSeekPercent={100}
        highlightSeekable={false}
        onMouseDown={this.onSeekMouseDown}
        onChange={this.onSeekChange}
        onMouseUp={this.onSeekMouseUp}
        hidden={hidden}
        ariaLabel={labels.seek}
        noValidateFormId={this.props.noValidateFormId}
        key={key}
      />
    );
  };

  renderPlayPause = (key, hidden) => (
    <PlayPause
      onClick={this.playPause}
      hidden={hidden}
      ariaLabelPlay={labels.play}
      ariaLabelPause={labels.pause}
      key={key}
      paused={!this.state.playing}
      {...this.props.video}
    />
  );

  isFullscreenNow = () => !!(
    document.fullscreenElement    ||
    document.msFullscreenElement  ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement
  );

  toggleFullScreen = (pane) => {
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState(s0 =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }

    this.setState({ fullScreen: !this.state.fullScreen });
  };

  renderFullScreen = (key, hidden) => (
     <FullScreen
      onClick={this.toggleFullScreen}
      hidden={this.props.fromPublishTest ? !this.props.fromPublishTest :this.props.fromCreateTest ? !this.props.fromCreateTest : 
              this.props.fromStudentAssessment ? this.props.fromStudentAssessment : this.props.fromAssessment ? this.props.fromAssessment : hidden}
      ariaLabelPlay={labels.fullscreen}
      key={key}
      fullScreen={this.state.fullScreen}
    />
  );

  renderTime = (key, hidden) =>
    isNaN(this.state.duration) ? null : (
      <Time
        hidden={hidden}
        key={key}
        currentTime={this.state.duration * this.state.played}
        duration={this.state.duration}
        {...this.props.video}
      />
    );

  renderVolume = (key, hidden) =>
    isNaN(this.state.volume) ? null : (
      <Volume
        onClick={this.toggleMuted}
        onChange={this.setVolume}
        hidden={hidden}
        volume={this.state.volume}
        muted={this.state.muted}
        ariaLabelMute={labels.mute}
        ariaLabelUnmute={labels.unmute}
        key={key}
        {...this.props.video}
      />
    );

  renderCaptions = key =>
    !this.props.video.textTracks ||
    this.props.video.textTracks.length === 0 ? null : (
      <Captions
        onClick={this.props.onCaptionsClick}
        ariaLabel={labels.captions}
        onItemClick={this.props.onCaptionsItemClick}
        key={key}
        {...this.props.video}
      />
    );


  renderControls = hidden =>
    this.props.controlsSpec.map((name, i) =>
      name === "Seek"
        ? this.renderSeek(i, hidden)
        : name === "PlayPause"
        ? this.renderPlayPause(i, hidden)
        : name === "Time"
        ? this.renderTime(i, hidden)
        : name === "Volume"
        ? this.renderVolume(i, hidden)
        : name === "FullScreen"
        ? this.renderFullScreen(i, hidden)
        : name === "Captions"
        ? this.renderCaptions(i, hidden)
        : null
    );

  render() {
    const {
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      // played,
      // loaded,
      // duration,
      playbackRate,
      pip,
      fullScreen
    } = this.state;

    const {
      url,
      // minPos,
      video,
      className,
      playArrowVisible,
      isTestQuestion,
      fromTestPreview,
      playback,
      // onReady,
      // ...restProps
    } = this.props;

    const fsStyle = fullScreen ? "video-fs" : (fromTestPreview ? "video-player-wraper" : "video-player");
    const testQnStyle = isTestQuestion ? (fullScreen ? "Qn-fs" : "video-new") : "video-new";
    const trainingZoneStyle =
      fullScreen && !this.props.fromTrainingZone
        ? "videod"
        :fullScreen && this.props.fromTrainingZone
        ? "videod"
        : !fullScreen && fromTestPreview
        ? "test-preview"
        : "";
        const noContent = (this.props.url === undefined || this.props.url === "");
        const isVideoContent = this.props.video === true;
        const styles = {
          playingState: {
              backgroundImage: !playback && (!this.props.playback && `url(${audioPlaceholder})`),
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              border: !playback && ((isVideoContent && !noContent) ? 'none' : '0.05px solid rgb(219, 217, 217)'),
          },
          pausedState: {
              backgroundImage:  !playback && `url(${pausedImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              border: !playback && ((isVideoContent && !noContent) ? 'none' : '0.05px solid rgb(219, 217, 217)'),
          },
      };
    return (
      // <div className={testQnStyle}>
      <div className={fullScreen? "video-fullscreen hFix" : (this.props.fromCB && "hFix")}>
      <div
        className={!playback && ["video", trainingZoneStyle, className].join(" ")}
        onClick={this.stopPropagation}
        // onMouseEnter={() => {
        //   this.state.isShown(true);
        // }}
        // onMouseLeave={() => {
        //   this.state.isShown(false);
        // }}
      >
        {/* <div className={fsStyle}> */}
        <div className='player-wrapper' style={this.state.playing ? styles.playingState : styles.pausedState}>
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            width="100%"
            height={(this.props.playerHeight&& this.props.fromCB) ?this.props.playerHeight:"100%"}
            // height="100%"
            url={url}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={this.onReady}
            // onStart={() => console.log('onStart')}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnablePIP={this.onEnablePIP}
            onDisablePIP={this.onDisablePIP}
            // onBuffer={() => console.log('onBuffer')}
            // onSeek={e => console.log('onSeek', e)}
            onEnded={this.onEnded}
            onError={this.onError}
            onProgress={this.onProgress}
            onDuration={this.onDuration}
            config={this.props.ctx_user.user.videoPlayerConfig}
          />
          {url && !this.props.isVideo360 && (
            <Overlay
              error={this.errorVisible}
              paused={video.paused}
              loading={this.spinnerVisible && video.loading}
              playArrowVisible={playArrowVisible}
              onClick={this.playPause}
            />
          )}
        </div>
        {noContent && <StyledBottomBar />}
        {/* </div> */}
        {url &&  (
          <div classname= "vid-playback"
          // className="video-controls-hover-region"
          // onMouseEnter={() => {
          //   {!fullScreen && this.setState({ isShown: true })}
          // }}
          // onMouseLeave={() => {
          //   {!fullScreen && this.setState({ isShown: false })}
          // }}
          
          
          // onClick={() => {
          //  {fullScreen && this.setState({ isShown: true })}}
          // }
          // onMouseOver={() => {
          //   fullScreen &&
          //   clearTimeout(this.viewedMessageTimer)
          //   this.setState({ isShown: true })
              // setTimeout(() => {
              //  this.setState({ isShown: false });
              // }, 3000)
          //  }}
          // onMouseDown={() => {
          //   this.setState({ isShown: true })
          // }}
          >
            
            { !this.props.isVideo360 &&
            <div
              className="video-controls-top-padding"
              onClick={this.playPause}
            /> }
            {/* <div className={`${cmCss.toolbar} bg-color2 text-white`}> */}
           {!this.props.fromMediaPicker && 
           <div className="player-controls video-controls">
              {this.renderControls(false)}
            </div>}
          </div>
        )}
      </div>
      </div>
    );
  }
}

export default withUserContext(Video);
