

export const vimeoPlayerConfig = {
  qualityItems: [
    { label: "Auto", value: "auto" },
    { label: "1080p", value: "1080p" },
    { label: "720p", value: "720p" },
    { label: "540p", value: "540p" },
    { label: "360p", value: "360p" },
  ],
  vimeo: { playerOptions: { quality: "auto" } },
};
