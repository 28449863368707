import classNames from "classnames";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";
import css from "./checkbox.module.scss";
import { IconMCQType, IconFillUpType, IconAssignmentType, IconShortAnswerType, IconCheckedNew } from "Icons";
// theme.
const StyledCheckbox = styled.label`
  > input {
    + span {
      &::before,
      &::after {
        border-color: ${({ theme }) => theme.primary};
      }
    }
  }

  &:hover {
    > input {
      + span {
        &::before {
          background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
        }
      }
    }
  }


`;

const StyledChapterCheckbox = styled.label`
  > input {
    + span {
      &::before,
      &::after {
        border-color: ${({ theme }) => theme.primary};
        margin: -8px
      }
    }
  }

  &:hover {
    > input {
      + span {
        &::before {
          background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
        }
      }
    }
  }


`;

const CBCheckbox = styled.label`
  padding: 0;
  > input {
    + span {
      &::before,
      &::after {
        border-color: ${({ theme }) => theme.primary};
        margin: -8px
      }
    }
  }

  > input + span {
    display: none;
    padding-left: 0;
  }

  &:hover {
    background-color:  ${({ theme }) =>theme.color2};
    > input {
      + span {
        &::before {
          background-color: ${({ theme }) =>theme.color2};
          border-radius:5px
        }
      }
    }
  }
`;

const TestCheckBox = styled.label`
margin-bottom:0 !important;
>input {
  +span {
      &::after {
          border-left: 2px solid white;
          border-bottom: 2px solid white;
      }
  }

  &:checked {
      +span {
          &::before{
              background-color: ${({ theme }) => theme.primary};
          }
          &::after {

              background-color: transparent;
              opacity: 1;
          }
      }
  }

}
`;
const ChoiceCheckBox = styled.label`
>input {
  +span {
      &::after {
          border-left: 2px solid white;
          border-bottom: 2px solid white;
      }
  }

  &:checked {
      +span {
          &::before{
              background-color: ${({ theme }) => theme.primary};
          }
          &::after {

              background-color: transparent;
              opacity: 1;
          }
      }
  }

}
`;

const StyledMFCheckbox = styled.label`
  > input {
    + span {
      &::before,
      &::after {
        border-color: #000000;
      }
    }
  }
`;


export default class Checkbox extends React.Component {

  getIconComponent = (questionType) => {
    switch (questionType) {
      case 4:
        return <IconAssignmentType />;
      case 1:
        return <IconFillUpType />;
      case 0:
      case 6:
        return <IconMCQType />;
      case 3:
        return <IconShortAnswerType />;
      default:
        return <IconAssignmentType />;
    }
  };

  render() {
    const childProps = { ...this.props };
    delete childProps.children;
    const iconComponent = this.getIconComponent(this.props.questionType, this.props.checked);
  
    if (this.props.fromChapterForm) {
      return (
        <StyledChapterCheckbox
          className={classNames(css.label, this.props.className ?? "")}
        >
          <input type="checkbox" {...childProps} />
          <span></span>
          {this.props.children}
        </StyledChapterCheckbox>
      );
    } 
    else if (this.props.fromCB || this.props.fromCb) {
      return (
        <CBCheckbox
          className={classNames(css.label, this.props.className ?? "")}
        >
          {this.props.checked ? <IconCheckedNew /> : iconComponent}
          <input type="checkbox" {...childProps} />
          <span></span>
          {this.props.children}
        </CBCheckbox>
      );
    }
    else if (this.props.fromTest){
      return(
        <TestCheckBox
          className={classNames(css.label, this.props.className ?? "")}
        >
          <input type="checkbox" {...childProps} />
          <span></span>
          {this.props.children}
        </TestCheckBox>
      )
    }
    else if (this.props.choiceTest){
      return(
        <ChoiceCheckBox
          className={classNames(css.choiceCheckBox, this.props.className ?? "")}
        >
          <input type="checkbox" {...childProps} />
          <span></span>
          {this.props.children}
        </ChoiceCheckBox>
      )
    }
    else if (this.props.fromModuleForm){
      return(
        <StyledMFCheckbox
        className={classNames(css.label, this.props.className ?? "")}
      >
        <input type="checkbox" {...childProps} />
        <span></span>
        {this.props.children}
      </StyledMFCheckbox>
      )
    }
    else {
      return (
        <StyledCheckbox
          className={classNames(css.label, this.props.className ?? "")}
        >
          <input type="checkbox" {...childProps} />
          <span></span>
          {this.props.children}
        </StyledCheckbox>
      );
    }
  }
  
}
