import classNames from "classnames";
import { IconClose } from "Icons";
import MainContext from "pages/Main/MainContext";
import React from "react";
import styled from "styled-components";
import LogoutButton from "./LogoutButton";
import css from "./scss/sidebaricons.module.scss"
import SidenavIcons from "./SidenavIcons";
import SubjectsMenu from "./SubjectsMenu";
import ShelfMenu from "./ShelfMenu";
import UserProfile from "./UserProfile";
import { withUserContext, withApiContext } from "context";
import ParentSubjectsMenu from "./ParentSubjectsMenu";
import BotMenu from "./BotMenu";
import OldCoursesMenu from "./OldCoursesMenu";

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.color2};
`;

class SidebarIcons extends React.Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);

    this.toggleShelfMenu = this.toggleShelfMenu.bind(this);
    this.toggleSubjectsMenu = this.toggleSubjectsMenu.bind(this);
    this.toggleBotMenu = this.toggleBotMenu.bind(this);
    this.toggleOldCoursesMenu = this.toggleOldCoursesMenu.bind(this);
    this.state = {
      isSubjectsMenuOpen: false,
      subjects: [],
      // oldSubjects:[],
      isViewShelfMenuOpen: false,
      shelf: [],
      completed: false,
      shelfComplete: false,
      showButton: false,
      openParentSubjectsMenu: false,
      isBotMenuOpen: false,
      bot: [],
      bot1 :[],
      isOldCoursesMenuOpen: false,
      oldcourses:[],
      subjectHover:false,
    };
  }

  componentDidMount = () => {
    // if (this.props.ctx_user.user.type === 1) {
    //   this.props.getStudentSubjectList();
    //   this.getShelfList();
    //   this.props.getStudentOldSubjectList();
    // }
    if (this.props.ctx_user.user.type === 4) {
      this.props.getStudentSubjectList();
    }
    if (this.props.ctx_user.user.type === 6) {
      this.getBotList();
      this.getAiServer();
    }

  };
  // getStudentSubjectList = () => {
  //   return new Promise((resolve, reject) => {
  //     let args = {
  //       studentId: this.props.ctx_user.user.type === 4 ? this.props.ctx_user.user.dependantId : this.props.ctx_user.user.id,
  //       acadYearId: this.props.ctx_user.user.acadYearId,
  //       sidebar: 1
  //     }
  //     this.props.ctx_api
  //       .privateRequest({
  //         cmd: "get_trainee_course_publishings",
  //         args: args,
  //       })
  //       .then(({ rsp }) => {
  //         this.setState({ subjects: rsp, completed: true });
  //         resolve();
  //       })
  //       .catch(() => {
  //         reject();
  //       });
  //   });
  // };

  

  getShelfList = () => {
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_published_shelves",
          args: {},
        })
        .then(({ rsp }) => {
          this.setState({ shelf: rsp, shelfComplete: true });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  getAiServer= (accountId,serverId)  => {
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_aiservers",
          // args: {accountId:55,
          //   serverId:1
          
          // },
        })
        .then(({ rsp }) => {
          this.setState({ bot1 : rsp});
          resolve();
          // console.log(this.state.bot1)
          console.log(rsp);
        })
        .catch(() => {
          reject();
        });
    }
    )
    
  }

  getBotList = (accountId) => {
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_aibots",
          args: {accountId:55},
        })
        .then(({ rsp }) => {
          this.setState({ bot : JSON.parse(rsp)});
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  componentDidUpdate(prevProps) {
    // if (prevProps.loadSubjects !== this.props.loadSubjects) {
    //   this.props.getStudentSubjectList();
    //   this.props.getStudentOldSubjectList();
    // }
    if (prevProps.isMobileOpen !== this.props.isMobileOpen) {
      // close subject menu if sidebar is closed on mobile
      if (!this.props.isMobileOpen) {
        this.setState({
          isSubjectsMenuOpen: false,
          isViewShelfMenuOpen: false,
          isBotMenuOpen: false,
          isOldCoursesMenuOpen: false,
        });
      }
    }
  }
  handlehover2=(value)=>{
    this.props.hoverLeave(value)
    console.log(value);
  }
  handlesubjecthover=(value)=>{
    this.props.hoversubjectenter(value)
  }
  toggleSubjectsMenu() {
    if (this.props.ctx_user.user.type === 4) {
      const open = !this.state.openParentSubjectsMenu
      this.setState({
        openParentSubjectsMenu: open,
      });
      return open;
    } else {
      const open = !this.state.isSubjectsMenuOpen;
      this.setState({
        isSubjectsMenuOpen: open,
      });
      this.props.menuChange(this.state.isSubjectsMenuOpen);
      return open;
    }
  }

  toggleBotMenu() {
    const open = !this.state.isBotMenuOpen;
    this.setState({
      isBotMenuOpen: open,
    });

    return open;
  }

  toggleShelfMenu() {
    const open = !this.state.isViewShelfMenuOpen;
    this.setState({
      isViewShelfMenuOpen: open,
    });

    return open;
  }
  toggleOldCoursesMenu() {	
    	
    const open = !this.state.isOldCoursesMenuOpen;	
    this.setState({	
      isOldCoursesMenuOpen: open,	
    });	
    return open;	
}	
handleSlidebackClick = (open) => {	
  this.props.handleSidebarToggle(open);	
  this.setState({	
    isSubjectsMenuOpen: false,	
    isViewShelfMenuOpen: false,	
    isBotMenuOpen: false,	
    isOldCoursesMenuOpen: false,	
    isOldCoursesMenuOpen: false,
    sidebar:false,
  });	
  
};
  // handleCloseSubjectsMenu = () => {
  // this.setState({ isSubjectsMenuOpen: false})
  // }
  hoverforsidebar =()=>{
    this.setState({sidebar:true})
    
  }
  hoverleavesidebar =()=>{
    this.setState({sidebar:false})
  }
  
  handleBurgerMenu =(value)=>{
    this.props.burgerMenuAction(value)
  }
  render() {
    return (
      <StyledWrapper className={css.wrapper}>
        <div className={css.main}>
          {/* user profile */}
          <UserProfile fromSidebarIcons/>
          {/* user menu */}
          { !this.props.ctx_user.user.passwordExpiry &&
            <SidenavIcons
            onMenuSelect={this.props.onMenuSelect}
            getMenu={this.props.getMenu}
            isSubjectsMenuOpen={this.state.isSubjectsMenuOpen}
            isParentSubjectsMenuOpen={this.state.openParentSubjectsMenu}
            toggleSubjectsMenu={this.toggleSubjectsMenu}
            sidenavData={this.props.sidenavData}
            sidenavLoading={this.props.sidenavLoading}
            isViewShelfMenuOpen={this.state.isViewShelfMenuOpen}
            toggleShelfMenu={this.toggleShelfMenu}
            loader={this.props.showItems}
            isBotMenuOpen={this.state.isBotMenuOpen}
            toggleBotMenu={this.toggleBotMenu}
            isMobileOpen={this.props.isMobileOpen}
            isOldCoursesMenuOpen={this.state.isOldCoursesMenuOpen}	
            toggleOldCoursesMenu={this.toggleOldCoursesMenu}
            burgerMenuAction = {this.handleBurgerMenu}
          />}
        </div>
        {/* ./main */}

        <div>
          {/* logout link */}
            <LogoutButton 
              // isMobileOpen={this.props.isMobileOpen}
              fromSidebarIcons
              onLogout={this.props.onLogout}
          />
        </div>

        {/* subject menu */}
        {this.state.isSubjectsMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button
                className="btn btn-link"
                onClick={this.toggleSubjectsMenu}
              >
                <IconClose width="16" height="16" />
              </button>
            </div>

            <SubjectsMenu
              hoversubjectenter={this.handlesubjecthover}
              toggleSubjectsMenu={this.toggleSubjectsMenu}
              studentSubjects={this.props.subjects}
              completed={this.state.completed}
              subListCompleted={this.props.subListCompleted}
            />
          </div>
        )}

                 {/* old courses menu */}	
                 {this.state.isOldCoursesMenuOpen && (	
          <div className={classNames(css.subjects_menu_wrapper)}>	
            <div className="text-right my-2 d-block d-md-none">	
              <button	
                className="btn btn-link"	
                onClick={this.toggleOldCoursesMenu}	
              >	
                <IconClose width="16" height="16" />	
              </button>	
            </div>	
            <OldCoursesMenu	
              toggleOldCoursesMenu={this.toggleOldCoursesMenu}	
              studentSubjects={this.props.oldSubjects}	
              completed={this.state.completed}	
            />	
          </div>	
        )}	


        {/* subject menu */}

        {this.state.openParentSubjectsMenu &&
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button
                className="btn btn-link"
                onClick={this.toggleSubjectsMenu}
              >
                <IconClose width="16" height="16" />
              </button>
            </div>
            <ParentSubjectsMenu
              toggleSubjectsMenu={this.toggleSubjectsMenu}
              studentSubjects={this.props.subjects}
              completed={this.state.completed}
            />
          </div>
        }

        {this.state.isViewShelfMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button className="btn btn-link" onClick={this.toggleShelfMenu}>
                <IconClose width="16" height="16" />
              </button>
            </div>

            <ShelfMenu
              toggleShelfMenu={this.toggleShelfMenu}
              shelfList={this.state.shelf}
              shelfComplete={this.state.shelfComplete}
            />
          </div>
        )}
         {this.state.isBotMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button className="btn btn-link" onClick={this.toggleBotMenu}>
                <IconClose width="16" height="16" />
              </button>
            </div>

            <BotMenu
              toggleBotMenu={this.toggleBotMenu}
              botList={this.state.bot}
              bot1={this.state.bot1}
            />
          </div>
        )}
      </StyledWrapper>
      // ./wrapper
    );
  }
}

export default withApiContext(withUserContext(SidebarIcons));
