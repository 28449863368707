import * as config from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Col, Row, Modal, Card } from "react-bootstrap";
// Glyphicon,
import ReactDOM from "react-dom";
import AnswerExplanation from "../../InternalItems/TestQuestion/AnswerExplanation";
import Emph from "./Emph";
import ReviewLink from "./ReviewLink";
import css from "../../StudentAssessments/Question/question.module.scss";
import { convertLatexToMarkup } from "mathlive";
import MathInput from "components/MathInput/MathInput"
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import { IconAudio, IconFlag, IconPDF, IconVideo } from "Icons";
import "./MultipleChoice.css";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { HelpfulButton, Textarea} from "components";

const StyledPlayerButton = styled.span`
  && {
    .media_mul{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
    .media_mul-flag{
      background-color: ${({ theme }) => theme.color2};
      color: white;
    }
  }`

class MultipleChoice extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    comments: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered: PropTypes.func.isRequired,
    onReview: PropTypes.func.isRequired,
    onFlagChange: PropTypes.func.isRequired,
    onAttempted: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      config.testQuestionTypeE.MULTIPLE_CHOICE,
      config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE,
      config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE,
    ]).isRequired,
  };

  testR = null;

  initState = {
    selected: [],
    submitted: false,
    hide: true,
    show: false,
    image: "",
    isAnswerImage: false,
    flag: this.props.flag === 1 ? true : false , 
    isFlag: false
  };

  componentDidMount = () => {

    const { user_selection } = this.props;
    if (user_selection) {
      var arrayOfNumbers = user_selection.map(Number);
      // alert(JSON.stringify(arrayOfNumbers))
      this.setState({ selected: arrayOfNumbers });
    }

    this.acquireFocus();
    this.changeState(false)
  }

  changeState = (state) => {
    this.setState({ hide: state });
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.id !== this.props.id) {
      this.setState(this.initState, this.acquireFocus);
      if (newProps.user_selection) {
        var arrayOfNumbers = newProps.user_selection.map(Number);
        this.setState({ selected: arrayOfNumbers });
      }
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (_prevProps.id !== this.props.id && this.props.math_symbol === 1) {
      this.changeState(true)
      setTimeout(() => this.changeState(false), 1);
    }
    if (_prevProps.id !== this.props.id ) {
      this.setState({flag : this.props.flag === 0 ? false : true})
    }
    const { onAnswered, onAttempted } = this.props;
    if (this.state.selected !== prevState.selected) {
      const response = this.state.selected;
      onAnswered(response);
      this.state.selected.length > 0 ? onAttempted(true) : onAttempted(false);
    }
  };

  acquireFocus = () => {
    const elt = ReactDOM.findDOMNode(this.testR);
    if (elt) {
      elt.focus();
    }
  };

  handleKeyDown = (e) => {
    if (!this.state.submitted) {
      e.stopPropagation();
      const choiceCount = this.props.choices.length;
      if (e.key === "Enter" && 0 < this.state.selected.length) {
        this.handleSubmit(e);
      } else {
        const charCode = e.key.charCodeAt(0);
        if (65 <= charCode && charCode < 65 + choiceCount) {
          this.handleChoiceClick(charCode - 65)();
        } else if (97 <= charCode && charCode < 97 + choiceCount) {
          this.handleChoiceClick(charCode - 97)();
        }
      }
    }
  };

//   handleChoiceClick = (i) => () => {

//     this.setState((s0) => {
//       let idx = s0.selected.indexOf(i);
//       let selected = [...s0.selected];
//       if (idx !== -1) {
//         selected.splice(idx, 1);
//       }
//       // else if (
//       //   this.props.type === config.testQuestionTypeE.MULTIPLE_CHOICE &&
//       //   1 < this.props.answers.length
//       // ) {
//       //   selected.push(i);
//       //   selected.sort((a, b) => a - b);
//       // }
//       else {
//         if (this.props.type === config.testQuestionTypeE.MULTIPLE_CHOICE) {

//           selected.push(i);
//           selected.sort((a, b) => a - b);
//         }
//         if (
//           this.props.type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE
//         ) {
//           selected = [i];
//         }
//       }
// console.log(selected.length);
// console.log(this.props.max_selection);
//       return { selected };
//     });

//   };
handleChoiceClick = (i) => () => {
  this.setState((prevState) => {
    const { selected } = prevState;
    const { type, max_selection } = this.props;

    const isSelected = selected.includes(i);
    let newSelected = [...selected];

    if (isSelected) {
      // If option is already selected, remove it from selected list
      newSelected = newSelected.filter(item => item !== i);
    } else if (type === config.testQuestionTypeE.MULTIPLE_CHOICE) {
      // If it's a multiple choice question
      if (max_selection > 0 && newSelected.length < max_selection) {
        // Check if max selection limit is not reached
        newSelected.push(i);
        newSelected.sort((a, b) => a - b);
      } else if (max_selection === 0) {
        // If max_selection is not defined or 0, allow unlimited selections
        newSelected.push(i);
        newSelected.sort((a, b) => a - b);
      }
    } else if (type === config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE) {
      // If it's a single choice question
      newSelected = [i];
    }

    return { selected: newSelected };
  });
};


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
  };

  handleClick = (answerImage) => {
    this.setState({ show: true, image: answerImage, isAnswerImage: true })
  }

  handleClose = () => {
    this.setState({ show: false, isAnswerImage: true });
  };

  isSurveyQuestion = () =>
    this.props.type === config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE;

  indexToLetter = (i) => String.fromCharCode(65 + i);

  renderChoices = () => {
    return (
      <div className={css.options}>
        <Row>
          {this.props.choices.map((choice, i) => {
            const isAnswer =this.props.answers.indexOf(choice.answerText) !== -1;
            const letter = this.indexToLetter(i);
            const isSelected = this.state.selected.indexOf(i) !== -1;
            return (
              <Col sm={6} key={i}>
                <div>
                  {(
                    <div
                      style={{ padding: "10px" }}
                      className={this.props.isPlayerTest ? "player-image" : "test-question-image"}>
                      <label style={{ display: "flex" }} className={css.checkbox}>
                        <input
                          onClick={
                            this.state.submitted
                              ? null
                              : this.handleChoiceClick(i)
                          }
                          type="radio"
                          id={i}
                          disabled={this.props.disabled}
                          checked={this.props.fromChapterInner ? isSelected : isAnswer}
                        />
                        <span>({letter}) &nbsp;&nbsp;</span>
                        {this.props.math_symbol === 1 ?
                          this.state.hide ?
                            (<></>) :
                            <MathInput
                              disabled={true}
                              id="fillBlanksQuestion"
                              value={choice.answerText}
                            />

                          : <div className={css.answer_text}>
                            {choice.answerText}
                          </div>
                        }
                        {/* {choice.answerText} */}
                      </label>
                      {choice.answerImage &&
                        <img
                          src={choice.answerImage}
                          alt=""
                          onClick={() => this.handleClick(choice.answerImage)}
                          style={{ cursor: "pointer" }}
                          className="answer-image"
                        />}
                    </div>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  renderIndex = (i, addReviewLink = false) => (
    <span>
      <Emph>{this.indexToLetter(i)}</Emph>
      {addReviewLink &&
        0 <= this.props.reviewStarts[i] &&
        this.props.reviewStarts[i] < this.props.reviewEnds[i] && (
          <span>
            {" "}
            <ReviewLink
              id={`review-answer-${i}`}
              onClick={this.props.onReview(
                this.props.reviewStarts[i],
                this.props.reviewEnds[i]
              )}
            />
          </span>
        )}
    </span>
  );

  renderIndexList = (indexList, addReviewLinks = false) => {
    switch (indexList.length) {
      case 0:
        return null;
      case 1:
        return this.renderIndex(indexList[0], addReviewLinks);
      case 2:
        return (
          <span>
            {this.renderIndex(indexList[0], addReviewLinks)}
            {" and "}
            {this.renderIndex(indexList[1], addReviewLinks)}
          </span>
        );
      default:
        const init = [...indexList];
        const last = init.pop();
        return (
          <span>
            {init.map((i) => (
              <span key={`indexList-${i}`}>
                {this.renderIndex(i, addReviewLinks)}
                {", "}
              </span>
            ))}
            {"and "}
            {this.renderIndex(last, addReviewLinks)}
          </span>
        );
    }
  };

  renderUserAnswer = () => (
    <div className="test-question-choices-footer">
      {/* <span>
        Your answer:
        &nbsp;
        {this.renderIndexList(this.state.selected)}
      </span> */}
      {!this.state.submitted && (
        <Button
          bsStyle="primary"
          disabled={this.state.selected.length === 0}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      )}
    </div>
  );

  renderExplanationDetails = (intro, indexList, descrip, key) => {
    const n = indexList.length;
    if (n === 0) {
      return null;
    }
    return (
      <div className="child" key={key}>
        <span>
          {intro && <span>{intro} </span>}
          {this.renderIndexList(indexList, true)}
          {1 < n ? " are " : " is "}
          {descrip}
          {"."}
        </span>
        {indexList.map(
          (i) =>
            this.props.comments[i] && (
              <div className="child" key={`${descrip}-${i}`}>
                <Emph>{this.props.choices[i].answerText}</Emph>
                {" : "}
                {this.props.comments[i]}
              </div>
            )
        )}
      </div>
    );
  };

  renderExplanation = () => {
    if (this.isSurveyQuestion()) {
      return;
    }

    const isCorrect = (i) =>
      this.props.answers.indexOf(this.props.choices[i].answerText) !== -1;

    const correct = this.state.selected.filter(isCorrect);
    const incorrect = this.state.selected.filter((i) => !isCorrect(i));
    const choiceTexts = this.props.choices.map((choice) => {
      return choice.answerText;
    });
    const missed = this.props.answers
      .map((answer) => {
        const i = choiceTexts.indexOf(answer);
        return this.state.selected.indexOf(i) === -1 ? i : -1;
      })
      .filter((i) => i !== -1);

    const allMissedDescrip = `the correct answer${1 < missed.length ? "s" : ""
      }`;
    const detailsArgs =
      0 < correct.length
        ? [
          ["Well done, ", correct, "correct"],
          [null, missed, "also correct"],
          ["Unfortunately, ", incorrect, "incorrect"],
        ]
        : [
          ["Unfortunately, ", incorrect, "incorrect"],
          [null, missed, allMissedDescrip],
        ];

    return (
      <AnswerExplanation visible={this.state.submitted}>
        {detailsArgs.map((args, i) =>
          this.renderExplanationDetails.apply(this, [...args, i])
        )}
      </AnswerExplanation>
    );
  };

  mathQuestion = () => {
    const data = this.props.math_symbol === 1 ? convertLatexToMarkup(this.props.question) : this.props.question
    return (
      // "test"
      this.props.math_symbol === 1 ? <div dangerouslySetInnerHTML={{ __html: data }} /> : this.props.question
    )
  }

  renderAnswerImage = () => {
    return <img src={this.state.image} className="image" />
  };

  renderImage = () => {
    return <img src={this.props.content} className="image" />
  };

  renderMedia = () => {
    let currLink = this.props.fromChapterInner ? this.props.content : (this.props.content_source=="external" ? this.props.content: this.props.media_link)
    const currType =  this.props.content ? this.props.content_format : "";
    const contentType = currType.toString();
    let MediaPlayer;
    switch (contentType) {
      case "1":
        MediaPlayer = Video;
        break;
      case "0":
        MediaPlayer = Audio;
        break;
      case "2":
        MediaPlayer = Pdf;
        break;
      default:
        MediaPlayer = React.Fragment;
        break;
    }
    return (
      <>
        <MediaPlayer
          className="ov"
          url={currLink}
          pdfUrl={currLink}
          mediaId="audio-multiple-choice"
          fromAssessment={this.props.fromAssessment}
        />

      </>)
  };

  handleMedia = () => {
    this.setState({ show: true, isAnswerImage: false })
  }

  handleFlagClick = () => {
    const newIsFlag = !this.state.flag;
    this.props.isFlaged(newIsFlag); // Pass data to the parent component
    this.setState({ flag: newIsFlag });
  }

  render = () => {
    const { content, media_link, content_format } = this.props;
    const mediaLabel =
    (this.props.content_format === 0 ||this.props.content_format === "0" )
    ? "Audio"
    : (this.props.content_format === 1 || this.props.content_format === "1")
      ? "Video"
      : (this.props.content_format === 2 || this.props.content_format === "2")
        ? "Pdf"
          : "";
          const questionImage =
          typeof content === 'string' && content.match(/^data:image\/\w+;base64,/) !== null
          ? content
          : media_link || "";
    return(
    <div>
      <Modal
        animation={false}
        show={this.state.show && !this.props.isPlayerTest}
        onHide={this.handleClose}
        scrollable={true}
        // backdrop="static"
        size={this.props.content_format === 0 ? "sm" : "lg"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.isAnswerImage
            ? "Image"
            : (this.props.content_format === 0 ||this.props.content_format === "0" )
              ? "Audio"
              : (this.props.content_format === 1 || this.props.content_format === "1")
                ? "Video"
                : (this.props.content_format === 2 || this.props.content_format === "2")
                  ? "Pdf"
                  : (this.props.content_format === 3 || this.props.content_format === "3")
                    ? "Image"
                    : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.state.isAnswerImage && this.renderAnswerImage()}
            {(this.props.content_format === 3 && this.props.content_format === "3" && !this.state.isAnswerImage) && this.renderImage()}
            {(this.props.content_format !== 3 && this.props.content_format !== "3"  && this.props.content_format !== null && !this.state.isAnswerImage) && this.renderMedia()}
          </div>
        </Modal.Body>
      </Modal>

      {this.props.disabled && (
        <div className="disabledText">{this.props.disabledText}</div>
      )}

      <div className={`${css.question} text-color2`}>
      <StyledPlayerButton>
        <MediaQuery minWidth={822}>
        <Row>
          {(this.props.content_format !== null  && this.props.content_format !== "3" ) ? (
            <>
              <Col md={10} lg={10}>
                {this.props.math_symbol === 1 ? (
                  this.state.hide ? (
                    <></>
                  ) : (
                    <div className="question-style">
                      QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}.
                      <MathInput
                        disabled={true}
                        id="multipleQuestion"
                        value={this.props.question}
                      />
                    </div>
                  )
                ) : (
                  <div question-style>
                    QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}. {this.props.question}
                  </div>
                )}
              </Col>
              <Col md={2} className="d-flex justify-content-end">
                    <>
                    {this.props.content && (this.props.content_format !== 3 && this.props.content_format !== "3") ?<HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {(this.props.content_format === 0 ||this.props.content_format === "0" ) ? (
                          <IconAudio width={10} height={10} fill="white" />
                        ) : (this.props.content_format === 1 || this.props.content_format === "1") ? (
                          <IconVideo width={10} height={10} fill="white" />
                        ) : (this.props.content_format === 2 || this.props.content_format === "2") ? (
                          <IconPDF width={10} height={10} fill="white" />
                        ) : (
                          ""
                        )}
                      </HelpfulButton>:""}
                    {this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </>
                </Col>
              
            </>
           ) :
            (
              <>
                <Col md={11}>
                  {this.props.math_symbol === 1 ? (
                    this.state.hide ? (
                      <></>
                    ) : (
                      <>
                        QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}.
                        <MathInput
                          disabled={true}
                          id="multipleQuestion"
                          value={this.props.question}
                        />
                      </>
                    )
                  ) : (
                    <div className="question-style"> 
                      QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}. {this.props.question}
                    </div>
                  )}
                </Col>
                <Col md={1} className="d-flex justify-content-end">
              { this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        tip = "Flag"
                        placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                  </Col>

              </>
            )}
        </Row>
        </MediaQuery>
        {/* <MediaQuery maxWidth={821}>
        <Row>
            <>
              <Col md={10} lg={10}>
                {this.props.math_symbol === 1 ? (
                  this.state.hide ? (
                    <></>
                  ) : (
                    <>
                    <div className="question-style">
                      QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}.
                      <MathInput
                        disabled={true}
                        id="multipleQuestion"
                        value={this.props.question}
                      />
                    </div>
                    {(this.props.content_format   && this.props.content_format !== 3) && (
                    <HelpfulButton
                      iconOnly
                      size="sm"
                      pill
                      className="media_mul rounded-circle" // Add the rounded-circle class
                      onClick={this.handleMedia}
                      tip = {"Click here to view " + mediaLabel}
                      placement = "top"
                    >
                      {this.props.content_format === 0 ? (
                        <IconAudio width={10} height={10} fill="white" />
                      ) : this.props.content_format === 1 ? (
                        <IconVideo width={10} height={10} fill="white" />
                      ) : this.props.content_format === 2 ? (
                        <IconPDF width={10} height={10} fill="white" />
                      ) : (
                        ""
                      )}
                    </HelpfulButton>)}
                    {this.props.fromChapterInner && <HelpfulButton
                      iconOnly
                      size="sm"
                      pill
                      className="media_mul-flag rounded-circle" // Add the rounded-circle class
                      onClick={this.handleFlagClick}
                      tip = "Flag"
                      placement = "top"
                    >
                      <IconFlag width={10} height={10} fill="white" />
                    </HelpfulButton>}
                  </>
                  )
                ) : (
                  <>
                   <div question-style>
                    QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}. {this.props.question}
                  </div>
                  {(this.props.content_format  &&  this.props.content_format !== 3) && (
                  <HelpfulButton
                  iconOnly
                  size="sm"
                  pill
                  className="media_mul rounded-circle" // Add the rounded-circle class
                  onClick={this.handleMedia}
                  tip = {"Click here to view " + mediaLabel}
                  placement = "top"
                >
                  {this.props.content_format === 0 ? (
                    <IconAudio width={10} height={10} fill="white" />
                  ) : this.props.content_format === 1 ? (
                    <IconVideo width={10} height={10} fill="white" />
                  ) : this.props.content_format === 2 ? (
                    <IconPDF width={10} height={10} fill="white" />
                  ) : (
                    ""
                  )}
                </HelpfulButton>)}
               { this.props.fromChapterInner &&  <HelpfulButton
                  iconOnly
                  size="sm"
                  pill
                  className="media_mul-flag rounded-circle" // Add the rounded-circle class
                  onClick={this.handleFlagClick}
                  tip = "Flag"
                  placement = "top"
                >
                  <IconFlag width={10} height={10} fill="white" />
                </HelpfulButton>}</>
                 
                )}
              </Col>
              
            </>
        </Row>
        </MediaQuery> */}
        <MediaQuery maxWidth={821}>
        <Row>
          {(this.props.content_format !== null  &&this.props.content_format !== "3") ? (
            <>
              <Col md={10} lg={10}>
                {this.props.math_symbol === 1 ? (
                  this.state.hide ? (
                    <></>
                  ) : (
                    <div className="question-style">
                      QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}.
                      <MathInput
                        disabled={true}
                        id="multipleQuestion"
                        value={this.props.question}
                      />
                    </div>
                  )
                ) : (
                  <div question-style>
                    QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}. {this.props.question}
                  </div>
                )}
              </Col>
              <Col md={2} >
                    <>
                    { this.props.content? <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul rounded-circle" // Add the rounded-circle class
                        onClick={this.handleMedia}
                        tip = {"Click here to view " + mediaLabel}
                        placement = "top"
                      >
                        {this.props.content_format === 0 ? (
                          <IconAudio width={10} height={10} fill="white" />
                        ) : this.props.content_format === 1 ? (
                          <IconVideo width={10} height={10} fill="white" />
                        ) : this.props.content_format === 2 ? (
                          <IconPDF width={10} height={10} fill="white" />
                        ) : (
                          ""
                        )}
                      </HelpfulButton>:""}
                    {this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                    </>
                </Col>
              
            </>
           ) :
            (
              <>
                <Col md={11}>
                  {this.props.math_symbol === 1 ? (
                    this.state.hide ? (
                      <></>
                    ) : (
                      <>
                        QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}.
                        <MathInput
                          disabled={true}
                          id="multipleQuestion"
                          value={this.props.question}
                        />
                      </>
                    )
                  ) : (
                    <div className="question-style"> 
                      QN{this.props.fromCreateTest ? this.props.count : this.props.questionNumber}. {this.props.question}
                    </div>
                  )}
                </Col>
                <Col md={1} className="d-flex justify-content-end">
              { this.props.fromChapterInner &&  <HelpfulButton
                        iconOnly
                        size="sm"
                        pill
                        className="media_mul-flag rounded-circle" // Add the rounded-circle class
                        onClick={this.handleFlagClick}
                        // tip = "Flag"
                        // placement = "top"
                      >
                        <IconFlag width={10} height={10} fill="white" />
                      </HelpfulButton>}
                  </Col>

              </>
            )}
        </Row>
        </MediaQuery>
        
        </StyledPlayerButton>
      </div>

      {this.props.content && (this.props.content_format === "3" || this.props.content_format === 3)   && (
          <img src={this.props.fromChapterInner ? this.props.content : questionImage} className="image_question" alt="" onClick={() => { this.handleClick(this.props.fromChapterInner ? this.props.content : this.props.media_link) }} style={{ cursor: "pointer" }} />
        )}

        {/* <div
          tabIndex="-1"
          style={(this.props.content_format === 2 && this.props.content) ? { marginTop: "5px" } : {}}
          onKeyDown={this.handleKeyDown}
          // ref={(r) => {
          //   this.testR = r;
          // }}
        > */}
        {this.props.image && (
          <div
            style={{ paddingBottom: "20px" }}
            className={this.props.isPlayerTest ? "player-image" : "test-question-image"}
          >
            <img src={this.props.image} alt="" onClick={() => this.handleClick(this.props.image)} style={{ cursor: "pointer" }} />
          </div>
        )}
      {(this.props.description ||this.props.question_content)&&
        <Row>
        <Col lg={12} xl={12} sm={12} md={12}>
          <div 
          dangerouslySetInnerHTML={{ __html: this.props.description ||this.props.question_content }} 
          className="description"/>
        </Col>
      </Row>}
      <Row><Col sm={12}>{this.renderChoices()}</Col>
      {!this.props.fromChapterInner && this.props.solution && (
                <Col md={12} >
                  <label style={{ fontWeight: 500, fontSize: "15px" }}>Solution</label>
                  <Textarea
                  className="text-area"
                    // type={Textarea}
                    readOnly={true}
                    value={this.props.solution}
                  />
                </Col>
              )}</Row>
   
    </div>
  );
      }
}

export default MultipleChoice;
