// RemoveUnwantedIframesHOC.js
import React, { useEffect } from 'react';

const removeUnwantedIframes = () => {
  // Create a MutationObserver to watch for DOM changes
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (const node of mutation.addedNodes) {
          if (node.tagName === 'IFRAME') {
            const zIndex = getComputedStyle(node).getPropertyValue('z-index');
            if (zIndex === '2147483647') {
              node.remove();
            }
          }
        }
      }
    }
  });

  // Observe the entire document for changes
  observer.observe(document, {
    childList: true,
    subtree: true,
  });
};

const RemoveUnwantedIframes = ({ children }) => {
  useEffect(() => {
    // Start monitoring for and removing unwanted iframes
    removeUnwantedIframes();
  }, []);

  return <div>{children}</div>;
};

export default RemoveUnwantedIframes;
