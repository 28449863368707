import DashboardPanel from "components/DashboardPanel/DashboardPanel";
import { withLabelsContext, withUserContext } from "context";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";
import * as util from 'services/util';
import { OverlayTooltip } from "components";
import { Redirect } from "react-router-dom";
import * as config from "config";
import { Link } from 'react-router-dom';

function AssessmentStatus(props) {
  console.log(props.data);
    const theme = useTheme();
    const [redirState, setRedirState] = useState(false);
    const [data, setData] = useState({});
    const tableStyles = getTableStyles(theme);
    tableStyles.pagination.style.borderTopWidth = "1px !important";
    tableStyles.pagination.style.borderTopStyle = "solid !important";

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('-');
      const monthMap = {
        JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
        JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
      };
      return new Date(year, monthMap[month], day);
    };
  
    const sortDate = (rowA, rowB) => {
      const dateA = parseDate(rowA.test_date.split(' - ')[0]);
      const dateB = parseDate(rowB.test_date.split(' - ')[0]);
      return dateA - dateB;
    };
  

    const columns = [
      {
        name: `${props.ctx_labels.singular.assessment}`,
        selector: row => row.test_name,
        sortable: true,
        width:"15%",
        cell: (e) =>{
          return(
            <OverlayTooltip placement="top" tipId={e.id} tip={e.test_name}>
              <span style={{display:"block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}> 
                {e.test_name}
              </span>
            </OverlayTooltip>
          )
        }
      },
      {
        name: `${props.ctx_labels.singular.curriculum}`,
        selector: row => row.subject,
        sortable: true,
        center: true,
      },
       {
        name: `${props.ctx_labels.singular.createdBy}`,
        selector: row => row.created_by,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.evaluatedBy}`,
        selector: row => row.evaluated_by,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.status}`,
        selector: row => row.status,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.classnBatch}`,
        selector: row => row.class_batch_name,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.date}`,
        selector: row => row.test_date,
        sortable: true,
        center: true,
        width:"20%",
        cell: (e) => {
          return (
            <OverlayTooltip placement="top" tip={e.test_date}>
              <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {e.test_date}
              </span>
            </OverlayTooltip>
          );
        },
        sortFunction: sortDate,
      },
        {
          name: `${props.ctx_labels.singular.obtainMarks}`,
          selector: row => row.evaluated_by === 'Not Evaluated' ? 0 : row.score,
          sortable: true,
          center: true,
        },
        {
          name: `${props.ctx_labels.singular.maxMarks}`,
          selector: row => row.max_marks,
          sortable: true,
          center: true,
          sortFunction: (a, b) => {
            return a.max_marks - b.max_marks;
          }
          
        },
    ];

    if (props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) {
      columns.forEach(column => {
        column.width = "10%";
      });
    }
    
    const linkStyles = {
      common: {
        display: "inline-block",
        fontSize: "12px",
        minWidth: "50px",
        textAlign: "center",
        padding: "6px 10px",
        lineHeight: "10px",
        borderRadius: "7px",
      },
      active: {
        backgroundColor: theme.color4,
        fontWeight: "600",
        color: "#ffffff",
      },
      disabled: {
        color: "grey",
      },
    };
    
    if (props.ctx_user.user.type === 3 || props.ctx_user.user.type === -1) {
      columns.push({
        name: "link",
        selector: row => row.link,
        sortable: false,
        cell: (row) => {
          const isActive = row.status === "Attended";
          return (
            <Link
              to={isActive ? {
                pathname: config.ROUTES.trainerEvaluate,
                data: row,
                interaction: props.data.interaction,
                addRedirection: props.addRedirection,
                alternateRedirection: props.alternateRedirection,
                name: props.dependant,
                id: row.test_assessment_id,
                adminHome: props.adminHome,
                selectedItem: props.data.filter((item) => item.status === "Attended"),
                content: {
                  currName: row.subject,
                  currTitle: row.subject,
                  date: row.to_date,
                  endDate: row.due_date,
                  id: row.test_assessment_id,
                  name: row.test_name,
                  reassign: row.reassign_date,
                  reassignDate: row.reassign_date,
                  startDateoriginal: row.to_date,
                  status: row.status,
                  subject: row.subject,
                  standard: row.standard,
                  endedOn: row.time,
                  student: props.dependant,
                  completedOn: row.completed_on,
                  traineeId: row.student_id,
                  testid: row.id,
                  name: props.dependant,
                },
                subjectData: {
                  currName: row.subject,
                  currTitle: row.subject,
                  date: row.to_date,
                  endDate: row.due_date,
                  id: row.test_assessment_id,
                  name: row.test_name,
                  reassign: row.reassign_date,
                  reassignDate: row.reassign_date,
                  startDateoriginal: row.to_date,
                  status: row.status,
                  subject: row.subject,
                  standard: row.standard,
                  endedOn: row.time,
                  student: props.dependant,
                  completedOn: row.completed_on,
                  traineeId: row.student_id,
                  testid: row.id,
                  name: props.dependant,
                }
              } : '/'}
              className={isActive ? '' : 'disabledCursor'}
              onClick={(event) => !isActive && event.preventDefault()}
              style={{
                ...linkStyles.common,
                ...(isActive ? linkStyles.active : linkStyles.disabled),
              }}
            >
              {props.ctx_labels.singular.view}
            </Link>
          );
        },
      });
    }

    let redirecting = redirState ? (
      <Redirect
        to={{ pathname: config.ROUTES.assessmentsLanding, entityData: data }}
      />
    ) : null;
    return (redirState && props.ctx_user.user.type===4) ? (
      redirecting
    ) : (
      <DashboardPanel
        title={`${props.ctx_labels.singular.assessment} ${props.ctx_labels.singular.status}`}
        fullScreen
      >
        <Card className="pb-25 border-0">
          <DataTable
           onRowClicked={(rowData) => {
            const entityData = {
              id: rowData.id,
              name: rowData.test_name,
              shortName: rowData.test_short_name,
              currName: rowData.subject,
              standard: rowData.standard,
              fromDate: rowData.started_on,
              toDate: util.toDateString(new Date(rowData.to_date)),
              attended: rowData.attended,
              tot_marks: rowData.max_marks,
              tot_questions: rowData.tot_questions,
              dueDate: rowData.due_date,
              child:props.child
            };
            
            setRedirState(true);
            setData(entityData);
          }}
            noHeader={true}
            columns={columns}
            data={props.data}
            customStyles={tableStyles}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            pointerOnHover={true}
            striped
          />
        </Card>
      </DashboardPanel>
    );
  }
  
  export default withLabelsContext(withUserContext(AssessmentStatus));
  