import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { Header, Sidebar, SidebarIcons  } from "components";
import * as msgs from "messages";
import { encryptObject } from "crypto.js";
import Loader from "components/Spinners/Loader";
import { withRouter, Route, Switch } from "react-router";
import css from "./main.module.scss";
import * as util from "services/util";
import MainContext from "./MainContext";
import { stopChatBot } from "services/chatBot";
import {
  AnalyticsContext,
  withAccessContext,
  withAnalyticsContext,
  withSocketContext,
  withApiContext,
  withFrameContext,
  withLabelsContext,
  withThemeContext,
  withUserContext,
  withWindowContext,
} from "context";
import { Redirect } from "react-router-dom";
import { subscribeUser } from "subscription";
import * as config from "config";
import StudentDashboard from "pages/User/StudentDashboard";
import SurveyList from "pages/SurveyList/SurveyList";
import StudentSurveys from "pages/StudentSurvey/StudentSurveys";
import ChapterInner from "pages/ChapterInner/ChapterInner";
import StudentResponse from "pages/StudentSurvey/StudentResponse";
import TestCreation from "pages/TestGeneration/TestCreation";
// import WorkDayLogs from "pages/WorkdayLogs/Workdaylogs";
import SurveyRouter from "pages/StudentSurvey/SurveyRouter";
const DashboardTrainee = lazy(() => import("pages/DashboardTrainee"));
const DashboardTrainer = lazy(() => import("pages/DashboardTrainer"));
const DashboardParent = lazy(() => import("pages/DashboardParent/DashboardParent"));
const AdminHome = lazy(() => import("pages/DashboardAdmin"));
const AdminDashboard = lazy(() => import("pages/DashboardAdmin/AdminDashboard"));
const CampusDashboard = lazy(() =>import("pages/DashboardAdmin/CampusDashboard"))
const TeacherAnalytics = lazy(() => import("pages/DashboardAdmin/TeacherAnalytics"));
const ClassAnalytics = lazy(() => import("pages/DashboardAdmin/ClassAnalytics"));
const CourseInsight = lazy(() => import("pages/DashboardAdmin/CourseInsight"));
const AssessmentInsight = lazy(() => import("pages/DashboardAdmin/AssessmentInsight"));
const Session = lazy(() => import("pages/Session/Session"));
const KnowledgeShelf = lazy(() => import("pages/KnowledgeShelf/KnowledgeShelf"));
const ClassCreate = lazy(() => import("pages/Class/Class"));
const OpenSubjects = lazy(() => import("pages/DashboardTrainee/panels/SubSubject/OpenSubjects"));
const Course = lazy(() => import("pages/Course/Course"));
const VirtualRoom = lazy(() => import("pages/VirtualRoom/VirtualRoom"));
const School = lazy(() => import("pages/School/School"));
const ModuleSearch = lazy(() => import("pages/ModuleEntity/Module"));
const PublishTestSearch = lazy(() => import("pages/PublishTest/PublishTest"));
const StudentUser = lazy(() => import("pages/User/StudentUser"));
const TeacherUser = lazy(() => import("pages/User/TeacherUser"));
const ParentUser = lazy(() => import("pages/User/ParentUser"));
const AdminUser = lazy(() => import("pages/User/AdminUser"));
const Role = lazy(() => import("pages/Role/Role"));
const Classlist = lazy(() => import("pages/ClassList/ClassList"));
const TestCreate = lazy(() => import("pages/Test/Test"));
const QuestionBank = lazy(() => import("pages/QuestionBank/QuestionBank"));
const AcademicYear = lazy(() => import("pages/AcademicYear/AcademicYear"));
const Grade = lazy(() => import("pages/Grade/Grade"));
const Division = lazy(() => import("pages/Division/Division"));
const MyClasses = lazy(() => import("pages/MyClasses/MyClasses"));
const Syllabus = lazy(() => import("pages/Syllabus/Syllabus"));
const ProfileSettings = lazy(() => import("pages/ProfileSettings/ProfileSettings"));
const GOI = lazy(() => import("pages/Goi/Goi"));
const AIConfig = lazy(() => import("pages/AiConfig/AiConfig"));
const AIBot = lazy(() => import("pages/AiBot/AiBot"));
const ExternalContent = lazy(() => import("pages/ExternalChannels/ExternalChannels"));
const ViewShelf = lazy(() => import("pages/ViewShelf/ViewShelf"));
const CreateShelf = lazy(() => import("pages/CreateShelf/CreateShelf"));
const Attendance = lazy(() => import("pages/Attendance/Attendance"));
const Menu = lazy(() => import("pages/Menu/Menu"));
const Playback = lazy(() => import("pages/Playback/Playback/Playback"));
const Standard = lazy(() => import("pages/Standard/Standard"));
const Assessments =  lazy(() => import("pages/Assessments/Assessments"));
const Join =  lazy(() => import("pages/LiveSessions/Join/Join"));
const CurriculumStudent =  lazy(() => import("pages/CurriculumStudent/Curriculum"));
const Messages =  lazy(() => import("pages/Messages"));
const EvaluateForm =  lazy(() => import("pages/AssessmentList/EvaluateForm"));
const Progress =  lazy(() => import("pages/Progress/Form"));
const ExternalChannelsInner =  lazy(() => import("pages/ExternalChannels/ExternalChannelsInner"));
const ViewShelfInner =  lazy(() => import("pages/ViewShelf/ViewShelfInner"));
const ZonePage =  lazy(() => import("pages/ZonePage/ZonePage"));
const MultiCourseStudent =  lazy(() => import("pages/MultiCourseStudent/MultiCourseStudent"));
const Site =  lazy(() => import("pages/Site/Site"));
const Settings =  lazy(() => import("pages/Configuration/Settings"));
const ChapterSelectForm =  lazy(() => import("pages/ChapterAllocation/ChapterAllocation"));
const TermsAndConditions =  lazy(() => import("./TermsAndConditions"))
const Promotion = lazy(() => import("pages/Promotion/Promotion"))
const MeetForm = lazy(() => import("pages/Session/MeetForm"))
const SubscriptionPreview = lazy(() => import("pages/SubscriptionPreview/SubscriptionPreview"))
const ParentZonePage = lazy(() => import("pages/ParentZonePage/ParentZonePage"))
const DifficultyLevel = lazy(() => import("pages/DifficultyLevel/DifficultyLevel"))
const PowerBi = lazy(() => import("pages/PowerBi/PowerBi"))
const MediaConvert = lazy(() => import("pages/MediaConvert/MediaConvert"))
const Zone = lazy(() => import("pages/Zone/Zone"))
const ClassForm = lazy(() => import("pages/ClassStudentList/ClassForm"))
const Language = lazy(() => import("pages/Language/Language"))
const BotCanvas = lazy(() => import("pages/BotCanvas/BotCanvas"))
const BotDocs = lazy(() => import("pages/BotCanvas/BotDocuments"))
const DocumentQuestions = lazy(() => import("pages/BotCanvas/BotDocQuestions"))
const QuestionDetails = lazy(() => import("pages/BotCanvas/BotQuestionDetail"))
const AttendanceTracker = lazy(() => import("pages/ClassAttendance/AttendanceTracker"))
const ViewerForm = lazy(() => import("pages/AttendanceViewer/ViewerForm"))
const OldCourseRenderPreview = lazy(() => import("pages/Course/Components/OldCourseRenderPreview"))
const TrackerForm = lazy(() => import("pages/ClassAttendance/TrackerForm"))
const CoursePublishStatus = lazy(() => import("pages/CoursePublishStatus/CoursePublishStatus"))
const Reports = lazy(() => import("pages/Reports/Common/Menu"))
const UserCalendar = lazy(() => import("pages/Calendar/UserCalendar"))
const Trainingzone = lazy(() => import("pages/Trainingzone/Trainingzone"))
const TrainingzoneInner = lazy(() => import("pages/TrainingzoneInner/TrainingzoneInner"))
const AssessmentTrainee = lazy(() => import("pages/Assessments/AssessmentTrainee"))
const StudentAssessments = lazy(() => import("pages/Assessments/StudentAssessments"))
const ChapterCreate = lazy(() => import("pages/Chapter/Chapter"))
const Curriculum = lazy(() => import("pages/Curriculum/Curriculum"))
const AssessmentList = lazy(() => import("pages/AssessmentList/AssessmentList"))
const Templates = lazy(() => import("pages/Templates/Templates"))
const Campus = lazy(() => import("pages/Campus/Campus"))
const CoursePublish = lazy(() => import("pages/CoursePublish/CoursePublish"))
const StudentSurveyAssessment = lazy(() => import("pages/StudentSurvey/StudentSurveyAssessment"))
const Survey = lazy(() => import("pages/Survey/Survey"))
const PublishSurveySearch = lazy(() => import("pages/Survey/PublishSurvey/PublishSurvey"))
const SummaryDetails = lazy(() => import("pages/Assessments/AssessmentTrainee/Attempt/index"))
const SurveyGeneral = lazy(() => import("pages/StudentSurvey/SurveyGeneral"))
// const StudentSurveys = lazy(() => import("pages/StudentSurvey/StudentSurveys"))
const SurveyCard = lazy(() => import("pages/StudentSurvey/SurveyCard"))
const ContentBuilder = lazy(()=> import("pages/ContentBuilder/ContentBuilder"))
const TrainerEvaluate =  lazy(() => import("pages/TrainerAssessmentEvaluation/TrainerEvaluate"));
const CertificateConfiguration = lazy(() => import("pages/Achievements/CertificateConfiguration"))
const WorkdayLogs = lazy(() => import("pages/WorkdayLogs/WorkdayLogs"));



// import QA from "pages/QASection/QA"

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileSidebar = () => {
      this.setState((state) => ({
        isMobileSidebarOpen: !state.isMobileSidebarOpen,
      }));
    };
    this.isAnalyticsTracking =
      this.props.ctx_user.user.analytics.isAnalyticsTracking;
    this.state = {
      hoversubject:false,
      ishover1:false,
      isMobileSidebarOpen: false,//window.innerWidth >= 768 ? true : false,
      toggleMobileSidebar: this.toggleMobileSidebar,
      subjectmenu:false,
      oldcoursemenu:false,
      activeSession: [],
      sessionStatus: "leave",
      joinAessionFirstData: 0,
      joinSessionStartTime: 0,
      coursePassSelect : false, 
      monthData:[],
      adminHome: false,
      myTrainingzone:{},
      myShelf:[],
      ...this.initState,
    };
    // this.state = {
    //   menu: [],
    //   isMobileSidebarOpen: false,
    //   toggleMobileSidebar: this.toggleMobileSidebar,
    //   subjects: {},
    //   alternateEntry: null,
    //   isSubjectLoaded: false,
    //   selectedSubjectId: null,
    //   selectedClassSubId: null,
    //   selectedPublishId: null,
    //   selectedCoursePublishId: null,
    //   selectedScreenId: null,
    //   selectedMenuId: null,
    //   selectedScreenProps: {},
    //   selectedChapterId: null,
    //   selectedModuleIds: {}, // screen selected module ID in each chapter
    //   currentModuleIds: {},
    //   chapters: {},
    //   activeStudyTime: 0,
    //   responseStudyMap: [],
    //   analytics: {
    //     player: [],
    //     subject: [],
    //   },
    // };
  }

  initState = {
    menu: [],
    sideBarState: true,
    reportId: "",
    subjects: {},
    alternateEntry: null,
    isSubjectLoaded: false,
    selectedSubjectId: null,
    selectedClassSubId: null,
    selectedPublishId: null,
    selectedCoursePublishId: null,
    selectedScreenId: null,
    selectedMenuId: null,
    selectedScreenProps: {},
    selectedChapterId: null,
    selectedModuleIds: {}, // screen selected module ID in each chapter
    currentModuleIds: {},
    chapters: {},
    activeStudyTime: 0,
    responseStudyMap: [],
    responseTrainerMilestones: [],
    responseTraineeMilestones: [],
    analytics: {
      player: [],
      trainerMilestones: [],
      traineeMilestones: [],
      subject: [],
    },
    switching: false,
    branchSwitching: false,
    // noSidebar: false,
    trainerCardData: {},
    trainerClassData: [],
    trainerAssessmentData: [],
    trainerLeaderboardData: [],
    traineeCardData: {},
    traineeCoursesData: [],
    traineeHomeAssessmentsData: [],
    traineeProgressBoardData: [],
    sidenavLoading: true,
    loadSubjects: "",
    ChapterProgress: 0,
    currRole: "",
    redirectHome: false,
    languagebtn: false,
    dependantLeaderboardData: [],
    dependantIntentData: [],
    dependantCourseData: [],
    dependantAssessmentData: [],
    dependantSessionData: [],
    dependantSelect: "",
    dependantData: [],
    dependant: "",
    userRecentActivityData:[],
    oldSubjects:[],
    weeklyUserTime:[],
    reportType: "",
    sideHover:false,
    subListCompleted:false,
  };

  clearState = () => {
    this.setState(this.initState);
  };

  componentDidMount = () => {
    if(this.state.ishover1 == true && this.state.hoversubject == true){
      this.setState({isMobileSidebarOpen:true})
    }
    this.handlePassword()
    const userType = this.props.ctx_user.user.type;
    this.initialTime = new Date();
    // const { isAnalyticsTracking } = config.userTracking.ANALYTICS;
    this.isAnalyticsTracking &&
      [1, 2].includes(userType) &&
      this.fetchAnalyticsOnRefresh(); //Called to query the studymap for the same session
    this.handleComponentMount();
    this.summaryInterval = this.props.playerOnly
      ? null
      : setInterval(this.sessionActiveChecker, 20000);
      if (this.props.location.state && this.props.location.state.adminHome !== undefined) {
        this.setState({ adminHome: this.props.location.state.adminHome });
    } else if (this.props.location.adminHome !== undefined) {
        this.setState({ adminHome: this.props.location.adminHome });
    } else {
        this.setState({ adminHome: false });
    }
  };

  handlePassword = () => {
    const newValue = this.props.ctx_user.user.passwordExpiry;
    if(newValue){
      this.props.ctx_window.confirm({
      message:  `Your Password has expired`,
      yesText:  'Ok, Change it',
      noText : 'No',
      callback: this.handleConfirmation(),
    })
    }
  }
  handleConfirmation = (onData) => (ok) => {
    if (!ok){
      this.props.ctx_access.logOut();
    }
  }

  sessionActiveChecker = () => {
    const { activeSession, joinSessionStartTime } = this.state;
    let summaryData = {};
    const currTime = new Date();
    if (this.state.sessionStatus === "join") {
      summaryData = {
        event_id: activeSession[0].id ? parseInt(activeSession[0].id) : null,
        event_name: activeSession[0].name,
        eventactive_time: Math.round(
          (currTime.getTime() - this.initialTime.getTime()) / 1000
        ),
        eventstart_at: joinSessionStartTime,
        eventend_at: currTime,
        intent: "virtual_session",
        details: {
          subject: activeSession[0].subject,
          classs: activeSession[0].classs,
          session_id: activeSession[0].sessionId,
          class_id: activeSession[0].classId,
        },
      };
      this.initialTime = currTime;
      this.updateAnalytics("player")({ ...summaryData });
    }
  };

  fetchSideNavDashboadData = (roleId, dependantId) => {
    this.homeScreenApiCalls();
    // if (!this.props.ctx_user.user.newUser) {
    this.getMenuSideNav(roleId);
    this.props.ctx_user.user.type === 4 &&
      this.homeScreenApiCallsforParent(dependantId);
    // }
  };

  getMenuSideNav = async (roleId, newUser) => {
    const args = {};
    if (roleId) {
      args.roleId = roleId;
    }
    args.newUser = this.props.ctx_user.user.newUser ? 1 : 0;
    await this.props.ctx_api
      .privateRequest({
        cmd: "get_member_menu",
        args,
      })
      .then(({ rsp }) =>
        this.handleGetMainMenuResponse(rsp.filter((item) => item))
      )
      .catch((err) => console.log(err));
  };

  handleGetMainMenuResponse = (rsp) => {
    this.setState((s0) => {
      const oProps = {};
      const sidenavMenu = [];
      let firstMenu = null;
      let defaultMenu = null;
      rsp.forEach((row) => {
        if (config.memberSubmenus.indexOf(row.screen_id) !== -1) {
          if (!firstMenu) {
            firstMenu = row;
          }
          if (row.home) {
            defaultMenu = row;
          }
          sidenavMenu[row.menu_id] = row;
        }
      });
      const menuItems = sidenavMenu.map((menuItem) =>
        menuItem.has_children === 1 ? { ...menuItem, open: false } : menuItem
      );
      if (!s0.selectedMenuId) {
        const homeMenu = defaultMenu || firstMenu;
        oProps.selectedMenuId = homeMenu.menu_id;
        oProps.selectedScreenId = homeMenu.screen_id;
        oProps.selectedScreenProps = homeMenu.screen_props;
      }

      let coursePublishMenuId = "";
      menuItems.forEach((item) => {
        if (item.screen_id == 181) {
          coursePublishMenuId = item.menu_id;
        }
      });
      this.setState({ coursePublishMenuId });
      return {
        menu: menuItems,
        sidenavLoading: false,
      };
    });
  };

  homeScreenApiCalls = () => {
    const userType = this.props.ctx_user.user.type;
    if (userType === 2) {
      this.getTrainerCardData();
      this.getTrainerDashboardData();
      this.getWeeklyUserTime();
    } else if (userType === 1) {
      this.getTraineeCardData();
      this.getTraineeDashboardData();
      this.getWeeklyUserTime();
    }
  };

  getTraineeCardData = () => {
    const args = {};

    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_home_summary",
        args,
      })
      .then(({ rsp }) => {
        if (rsp) this.setState({ traineeCardData: rsp[0] });
      })
      .catch((err) => console.log(err));
  };

  getStudentSubjectList = () => {
    return new Promise((resolve, reject) => {
      let args = {
        memberId:
          this.props.ctx_user.user.type === 4
            ? this.props.ctx_user.user.dependantId
            : this.props.ctx_user.user.id,
        acadYearId: this.props.ctx_user.user.acadYearId,
        // sidebar: 1,
      };
      this.props.ctx_api
        .privateRequest({
          cmd: "get_dashboard_trainee_course_publishings",
          args: args,
        })
        .then(({ rsp }) => {
          this.setState({ subjects: rsp, completed: true, subListCompleted:true });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  getStudentOldSubjectList = () => {
    return new Promise((resolve, reject) => {
      let args = {
        studentId:
          this.props.ctx_user.user.type === 4
            ? this.props.ctx_user.user.dependantId
            : this.props.ctx_user.user.id,
        acadYearId: this.props.ctx_user.user.acadYearId,
        sidebar: 1,
      };
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_prevacd_course_publishings",
          args: args,
        })
        .then(({ rsp }) => {
          this.setState({ oldSubjects: rsp, completed: true });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };


 getTraineeDashboardData = () => {
    this.getTraineeCourses();
    this.getTraineeHomeAssessments();
    this.props.ctx_user.user.showCredits
        ? this.getTraineeProgressBoard()
        : this.getUserRecentActivity();
};
  getTraineeCourses = () => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
      memberId: this.props.ctx_user.user.id,
    };

    this.props.ctx_api
      .privateRequest({
        cmd: "get_dashboard_trainee_courses",
        args,
      })
      .then(({ rsp }) => this.setState({ traineeCoursesData: rsp }))
      //.then(({ rsp }) => setOpenSubjects(rsp))
      .catch((err) => console.log(err));
  };

  getTraineeHomeAssessments = () => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
    };

    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_home_assessments",
        args,
      })
      .then(({ rsp }) => this.setState({ traineeHomeAssessmentsData: rsp }))
      //.then(({ rsp }) => setOpenAssessments(rsp))
      .catch((err) => console.log(err));
  };


  getTraineeProgressBoard = () => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
      //classId: this.props.ctx_user.user.classId
    };
    // if(classId) {
    //   args.classId = classId
    // }
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_progress_board",
        args,
      })
      .then(({ rsp }) => this.setState({ traineeProgressBoardData: rsp }))
      //.then(({ rsp }) => setLeaderboard(rsp))
      .catch((err) => console.log(err));
  };

  getTrainerDashboardData = () => {
    this.getTrainerClasses();
    this.getTrainerStandAssessments();

    if (this.props.ctx_user.user.showCredits) {
        this.getTrainerLeaderBoard();
    }
};
  getTrainerClasses = () => {
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainer_classes",
        args: {
          acadYearId: this.props.ctx_user.user.acadYearId,
          // classId: props.ctx_user.user.classId,
        },
      })
      .then(({ rsp }) => this.setState({ trainerClassData: rsp }))
      //.then(({ rsp }) => setOwnSubjects(rsp))
      .catch((err) => console.log(err));
  };
  getTrainerStandAssessments = () => {
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainer_stand_assessments",
        args: {
          acadYearId: this.props.ctx_user.user.acadYearId,
          // classId: props.ctx_user.user.classId,
        },
      })
      .then(({ rsp }) => this.setState({ trainerAssessmentData: rsp }))
      //.then(({ rsp }) => setOwnAssessments(rsp))
      .catch((err) => console.log(err));
  };
  getTrainerLeaderBoard = () => {
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainer_leader_board",
        args: {
          acadYearId: this.props.ctx_user.user.acadYearId,
        },
      })
      .then(({ rsp }) => this.setState({ trainerLeaderboardData: rsp }))
      //.then(({ rsp }) => setLeaderboardData(rsp))
      .catch((err) => console.log(err));
  };

  getTrainerCardData = () => {
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainer_home_summary",
        args: {},
      })
      .then(({ rsp }) => {
        if (rsp) this.setState({ trainerCardData: rsp[0] });
        //if (rsp) setCardData(rsp[0]);
      })
      .catch((err) => console.log(err));
  };

  homeScreenApiCallsforParent = (dependantId) => {
    this.getChildData();
    this.getParentLeaderboardData(dependantId);
    this.getIntentData(dependantId);
    this.getParentDashboardData(dependantId);
  };

  getChildData = () => {
    const { campusList } = this.props.ctx_user.user;
    const campus = campusList.find((d) => d.campus_role_id);
    const campusId = campus.organization_id;
    const args = {
      campusId,
    };
    this.props.ctx_api
      .privateRequest({
        cmd: "get_parent_childrn",
        args,
      })
      .then(({ rsp }) => {
        const data = rsp.map(({ child_id, child_name }) => ({
          label: child_name,
          value: child_id,
        }));
        this.setState({ dependantData: data });
      })
      .catch((err) => console.log(err));
  };

  getParentLeaderboardData = (dependantId) => {
    if(this.props.ctx_user.user.type === 1){
    const args = {
      memberId:this.props.ctx_user.user.id
    };
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_studytime",
          args,
        })
        .then(({ rsp }) => {
          this.setState({ dependantLeaderboardData: rsp });
          console.log(this.state.dependantLeaderboardData);
        })
        .catch((err) => console.log(err));
  }
  else{
    const args={};
  if (dependantId || this.props.ctx_user.user.dependantId) {
    args.memberId = dependantId ?? this.props.ctx_user.user.dependantId;
  }
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_studytime",
          args,
        })
        .then(({ rsp }) => {
          this.setState({ dependantLeaderboardData: rsp });
        })
        .catch((err) => console.log(err));
  }
  };

  getIntentData = (dependantId) => {
    if(this.props.ctx_user.user.type === 1){
    const args = {
      memberId:this.props.ctx_user.user.id
    };
      this.props.ctx_api
        .privateRequest({
          cmd: "get_intent_count",
          args,
        })
        .then(({ rsp }) => this.setState({ dependantIntentData: rsp }))
        .catch((err) => console.log(err));
  }
else {
  const args={};
  if (dependantId || this.props.ctx_user.user.dependantId) {
    args.memberId = dependantId ?? this.props.ctx_user.user.dependantId;
  }   
    this.props.ctx_api
      .privateRequest({
        cmd: "get_intent_count",
        args,
      })
      .then(({ rsp }) => this.setState({ dependantIntentData: rsp }))
      .catch((err) => console.log(err));
 }
}

  getParentDashboardData = (dependantId) => {
    this.getParentCourse(dependantId);
    this.getParentAssessment(dependantId);
    this.getParentSession(dependantId);
  };

  getParentCourse = (dependantId) => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
    };
    if (dependantId || this.props.ctx_user.user.dependantId) {
      args.dependantId = dependantId ?? this.props.ctx_user.user.dependantId;
    }
    
    if (
      (this.props.ctx_user.user.type === 1 &&
        this.state.dependantCourseData.length === 0) ||
      this.props.ctx_user.user.type === 4
    ) {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_course_progress_dtl",
          args,
        })
        .then(({ rsp }) => this.setState({ dependantCourseData: rsp }))
        .catch((err) => console.log(err));
    }
  };

  getParentAssessment = (dependantId) => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
    };
    if (dependantId || this.props.ctx_user.user.dependantId) {
      args.dependantId = dependantId ?? this.props.ctx_user.user.dependantId;
    }
    if (
      (this.props.ctx_user.user.type === 1 &&
        this.state.dependantAssessmentData.length === 0) ||
      this.props.ctx_user.user.type === 4
    ) {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_assessment_dtl",
          args,
        })
        .then(({ rsp }) => this.setState({ dependantAssessmentData: rsp }))
        .catch((err) => console.log(err));
    }
  };

  getParentSession = (dependantId) => {
    const args = {
      acadYearId: this.props.ctx_user.user.acadYearId,
    };
    if (dependantId || this.props.ctx_user.user.dependantId) {
      args.dependantId = dependantId ?? this.props.ctx_user.user.dependantId;
    }
    if (
      (this.props.ctx_user.user.type === 1 &&
        this.state.dependantSessionData.length === 0) ||
      this.props.ctx_user.user.type === 4
    ) {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_trainee_live_session_dtl",
          args,
        })
        .then(({ rsp }) => this.setState({ dependantSessionData: rsp }))
        .catch((err) => console.log(err));
    }
  };

  handleChildChange = (item) => {
    if (item.value !== 0) {
      const { dependantData } = this.state;

      const data1 = dependantData
        .filter((s) => s.value === item.value)
        .map((s) => s.label);
      this.setState({ dependantSelect: data1.join(",") });

      const data2 = dependantData
        .filter((s) => s.value === item.value)
        .map((s) => s.value);
      this.setState({ dependant: data2.join(",") });
      this.getParentLeaderboardData(item.value);
      this.getIntentData(item.value);
      this.getParentDashboardData(item.value);
    }
  };
getUserRecentActivity = () => {
    const args = {
      memberId: this.props.ctx_user.user.id,
    };

    this.props.ctx_api
      .privateRequest({
        cmd: "get_recent_member_activity",
        args,
      })
      .then(({ rsp }) => this.setState({ userRecentActivityData: rsp }))
      //.then(({ rsp }) => setOpenAssessments(rsp))
      .catch((err) => console.log(err));
  };

  getWeeklyUserTime = () => {
    
    const args = {
      memberId:this.props.ctx_user.user.id
    };
      this.props.ctx_api
        .privateRequest({
          cmd: "get_weekly_user_time",
          args,
        })
        .then(({ rsp }) => {
          this.setState({ weeklyUserTime: rsp });
          console.log("sai", this.state.weeklyUserTime);
        })
        .catch((err) => console.log(err));
  };




  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //   this.toggleSidebar();
    // }
    // const stateNames = Object.getOwnPropertyNames(this.state);
    // stateNames.forEach(name => {
    //   if(this.state[name] !== prevState[name]) {
    //   }
    // })
    if (prevProps.location.state !== this.props.location.state) {
        if (this.props.location.state && this.props.location.state.adminHome !== undefined) {
            this.setState({ adminHome: this.props.location.state.adminHome });
        } else if (this.props.location.adminHome !== undefined) {
            this.setState({ adminHome: this.props.location.adminHome });
        } else {
            this.setState({ adminHome: false });
        }
    }
}

  componentWillUnmount = () => {
    if (this.isAnalyticsTracking) {
      this.addIntervalData(); // For writing studymap just before log out and refresh
      clearInterval(this.startTimeInterval);
      this.clearAnalytics();
    }
  };

  clearAnalytics = () => {
    this.setState({ analytics: { player: [], subject: [] } });
  };

  // toggleSidebar = () => {
  //   if (config.noSidebarRoutes.includes(this.props.location.pathname)) {
  //     this.setState({ noSidebar: true });
  //   } else {
  //     this.setState({ noSidebar: false });
  //   }
  // };

  handleComponentMount = () => {
    this.fetchSideNavDashboadData();
    const { graphqlCallInterval } = this.props.ctx_user.user.analytics;
    const userType = this.props.ctx_user.user.type;
    this.isAnalyticsTracking &&
      [1, 2].includes(userType) &&
      (this.startTimeInterval = setInterval(
        this.addIntervalData,
        graphqlCallInterval
      ));

    // this.startTimeInterval = setInterval(this.addIntervalData, 25000)
    // The above will NOT immediately call the mutation.
    //In case a refresh or log out happens between two successive graphQL calls,
    //addIntervalData has been called on unmount.

    // this.intervalApi(() => this.addIntervalData(), graphqlCallInterval, false)

    // let { selectedScreenId } = this.state;
    //selectedScreenId === null &&
    // this.setState({ selectedScreenId: config.memberSubmenuE.DASHBOARD });
    // if (userType === 1) {
    //   if (this.props.ctx_user.user.classId) {
    //     this.getStudentSubjectList();
    //   }
    // }
    // if (userType === 3) {
    //   this.getTrainerPerformance();
    // }
    // this.getMainMenu();

    setTimeout(function () {
      if ("Notification" in window) {
        Notification.requestPermission((result) => {
          subscribeUser();
        });
      }
    }, 2000);
  };

  fetchAnalyticsOnRefresh = () => {
    this.props.ctx_api
      .privateRequest({
        cmd: "graphql",
        args: {
          query: this.getQuery(),
        },
      })
      .then(this.handlefetchAnalyticsOnRefreshResponse) //Data will be lost on refresh. To prevent this, a call is required.
      .catch((err) => console.log(err)); //Studymap will be set to responseStudyMap state.
  };

  handlefetchAnalyticsOnRefreshResponse = ({ rsp }) => {
    const { type } = this.props.ctx_user.user;
    const responseData = rsp.data[type === 1 ? "trainee" : "trainer"];
    if (type === 1) {
      this.setState({
        responseStudyMap: responseData.studymap,
        responseTraineeMilestones: responseData.trainee_milestones,
      });
    } else {
      this.setState({
        responseTrainerMilestones: responseData.trainer_milestones,
      });
    }

    this.addIntervalData(); //This call is for immediately setting the studymap to the condition before refresh.
  };

  summarizeStudyMap = (studyMap) => {
    const result = [
      ...studyMap
        .reduce((r, o) => {
          const key =
            o.event_id + "-" + o.event_name + "-" + o.intent + "-" + o.details;

          const item =
            r.get(key) ||
            Object.assign({}, o, {
              eventactive_time: 0,
            });

          item.eventactive_time = item.eventactive_time + o.eventactive_time;
          item.eventstart_at =
            this.state.responseStudyMap[0]?.eventstart_at !== undefined
              ? this.state.responseStudyMap[0].eventstart_at
              : o.eventstart_at;
          item.eventend_at = o.eventend_at;
          item.details.questions_attended =
            studyMap[studyMap.length - 1].details.questions_attended;

          return r.set(key, item);
        }, new Map())
        .values(),
    ];
    return result;
  };

  activeStudyTimeSum = (finalStudyMap) => {
    let time = 0;
    finalStudyMap.forEach((item) => {
      time = time + item.eventactive_time;
    });

    return time;
  };

  getQuery = () => {
    const recordId = util.getLoginDetails().id;
    const query =
      this.props.ctx_user.user.type === 1
        ? `{
      trainee(id :"${recordId}" ){
        studymap
          {
            event_id
            event_name
            eventactive_time
            eventstart_at
            eventend_at
            intent,
            details {
              publish_test_id,
              course_id,
              course_name,
              course_publish_id,
              course_publish_name,
              topic_id,
              topic_name,
              questions_attended,
              classs,
              session_id,
              subject,
              class_id,
            }                             
            } 
          trainee_milestones{
            event_id
            event_subject
            event_status
            event_name
            event_date
            ple_score
          }                        
    }
  }`
        : `{
    trainer(id :"${recordId}" ){
      trainer_milestones
        {
          event_id
          event_name
          event_date
          event_subject
          event_type                           
          }             
  }
}`;

    return query;
  };

  getMutation = (
    recordId,
    recordLoginDate,
    finalStudyMap,
    activeStudyTime,
    trainerMilestones,
    traineeMilestones
  ) => {
    // const recordId = util.getLoginDetails().id; //id of record to be updated in mongoDB
    // const recordLoginDate = util.getLoginDetails().loginDate;
    const mutation =
      this.props.ctx_user.user.type === 2
        ? `mutation{updateTrainerMilestone(
            id: "${recordId}",
            loggedin_at:"${recordLoginDate}",
            loggedout_at:"${new Date().toISOString()}",
            trainer_milestones:${JSON.stringify(trainerMilestones).replace(
              /"(\w+)"\s*:/g,
              "$1:"
            )}
          )             
          {
            trainer_milestones{
              event_id
              event_name
              event_type
              event_subject
              event_date
            }            
          }                        
          }`
        : `mutation{updateTraineeActivity(
            id: "${recordId}",
            loggedin_at:"${recordLoginDate}",
            loggedout_at:"${new Date().toISOString()}",
            active_study_time: ${activeStudyTime},
            studymap:${JSON.stringify(finalStudyMap).replace(
              /"(\w+)"\s*:/g,
              "$1:"
            )},
            trainee_milestones:${JSON.stringify(traineeMilestones).replace(
              /"(\w+)"\s*:/g,
              "$1:"
            )}
          )             
          {
            studymap{
              event_id
              event_name
              eventactive_time
              eventstart_at
              eventend_at
              intent,
              details {
                publish_test_id,
                course_id,
                course_name,
                course_publish_id,
                course_publish_name,
                topic_id,
                topic_name,
                questions_attended,
                classs,
                session_id,
                subject,
                class_id
              }
            }  
            trainee_milestones{
              event_id
              event_subject
              event_status
              event_name
              event_date
              ple_score
            }                       
              }
            
          }`;
    return mutation;
  };

  updateTraineeActivity = () => {
    const {
      recordId,
      recordLoginDate,
      activeStudyTime,
      finalStudyMap,
      trainerMilestones,
      traineeMilestones,
    } = this.mkTraineeActivityData();
    return this.props.ctx_api.privateRequest({
      cmd: "graphql",
      args: {
        query: this.getMutation(
          recordId,
          recordLoginDate,
          finalStudyMap,
          activeStudyTime,
          trainerMilestones,
          traineeMilestones
        ),
      },
    });
  };

  mkTraineeActivityData = () => {
    const { analytics } = this.state;
    const studyMap = [...analytics.player]; //Objects from coursePublishViewer form.
    const filteredStudyMap = [
      ...studyMap.filter((item) => item.event_id !== null),
    ]; //To remove records having module_id null
    const aggregateStudyMap = this.summarizeStudyMap(filteredStudyMap); //For aggregation
    const combinedStudyMap = [
      ...this.state.responseStudyMap,
      ...aggregateStudyMap,
    ];
    const finalStudyMap = this.summarizeStudyMap(combinedStudyMap);
    //In case of refresh, the studymap will be set from responseStudyMap. Subsequent records will be from studymap after refresh
    //contained in analytics.player.

    const trainerMilestones = [
      ...this.state.responseTrainerMilestones,
      ...analytics.trainerMilestones,
    ];

    const traineeMilestones = [
      ...this.state.responseTraineeMilestones,
      ...analytics.traineeMilestones,
    ];

    const recordId = util.getLoginDetails().id; //id of record to be updated in mongoDB
    const recordLoginDate = util.getLoginDetails().loginDate;
    const activeStudyTime = this.activeStudyTimeSum(finalStudyMap);

    return {
      recordId,
      recordLoginDate,
      activeStudyTime,
      finalStudyMap,
      trainerMilestones,
      traineeMilestones,
    };
  };

  handleLogoutGraphql = () => {
    return new Promise((resolve, reject) => {
      this.updateTraineeActivity()
        .then((resp) => resolve(resp))
        .catch((err) => {
          reject(err);
        });
    });
  };

  addIntervalData = () => {
    this.updateTraineeActivity()
      .then(this.handleUpdateTraineeActivityResponse)
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleUpdateTraineeActivityResponse = ({ rsp }) => {
    // this.setState({ responseStudyMap: [], activeStudyTime: 0 })
  };

  handleUserSwitching = (s0) => {
    this.setState({ switching: true });
    const { id, impersonator_id } = this.props.ctx_user.user;
    this.props.ctx_api
      .privateRequestRow({
        cmd: "member_login_impersonate",
        args: {
          impersonatorId: impersonator_id || id,
          targetMemberId: s0,
        },
      })
      .then(this.handleLoginResponse)
      .catch(this.handleLoginError);
  };

  handleLoginResponse = ({ rsp, headers }) => {
    const auth = headers.get("x-access-token");
    const success = rsp.statusvalue === 1 && auth;
    if (success) {
      this.props.ctx_access.logIn(auth, rsp, false);
      this.clearState();
      stopChatBot();
      this.fetchSideNavDashboadData();
      // this.handleComponentMount();
      // rsp.impersonization&&alert()
      this.setState({subjectmenu : false})
    }
    this.setState({ switching: false });
  };

  handleLoginError = () => {
    this.setState({ switching: false });
    this.props.ctx_window.alert("Impersonation Failed");
  };

  handleUserCreditsChange = (credits, level) => {
    const campusList = this.props.ctx_user.user.campusList.map((c, i) => {
      return c.organization_id.toString() ===
        this.props.ctx_user.user.organizationId.toString() &&
        c.role_id.toString() === this.props.ctx_user.user.roleId.toString()
        ? { ...c, total_points: credits }
        : { ...c };
    });
    this.props.ctx_user.update((user) => ({
      ...user,
      credits,
      level,
      campusList,
    }));
  };

  handleSelectModule = (chapterId) => (contentId) => {
    this.setState((s0) => {
      const selectedModuleIds = { ...s0.selectedModuleIds };
      selectedModuleIds[chapterId] = contentId;
      return { selectedModuleIds };
    });
  };

  refreshChapter = (selectCurrent = false) => {
    // We speculatively re-fetch the selected curriculum and its modules; if
    // the curriculum is no longer valid, another curriculum will be
    // selected by SideMenu.Dropdown, and the associated data will be
    // fetched
    const chapterId = this.state.selectedChapterId;
    const chapter = this.state.chapters[this.state.selectedChapterId];
    const subject = this.state.subjects[this.state.selectedCurricId];
    if (chapter) {
      //this.getCurriculum(curricId, curric);
      this.getModules(chapterId, subject, selectCurrent);
    }
  };

  refreshModules = (chapterId) => () => {
    const subject = this.state.subjects[this.state.selectedCurricId];
    this.getModules(chapterId, subject);
  };

  getModules = (chapterId, curric, selectCurrent = false) => {
    this.props.ctx_api
      .privateRequest({
        cmd: "get_portal_curriculum_new_v2",
        deps: [
          "insert_update_trainee_module_time",
          "insert_update_trainee_points",
        ],
        args: {
          memberId: this.props.ctx_user.user.id,
          curriculumId: curric.id,
          chapterId: chapterId,
          type: curric.type,
          configId: curric.configId,
          acadYearId: this.props.ctx_user.user.acadYearId,
          //sharedBy: curric.sharedBy,
        },
      })
      .then(this.handleGetModulesResponse(chapterId, selectCurrent))
      .catch(this.handleGetModulesError(chapterId));
  };

  handleGetModulesResponse =
    (chapterId, selectCurrent) =>
    ({ rsp }) => {
      if (rsp.length === 0) {
        this.handleGetModulesError(chapterId)();
      }
      this.setState((s0) => {
        const modules = { ...s0.modules };
        const ml = this.mkModuleList(rsp);
        modules[chapterId] = ml;

        const currentModuleIds = { ...s0.currentModuleIds };
        const currentModule = util.getCurrentModule(ml);
        const currentContentId = currentModule ? currentModule.contentId : null;
        currentModuleIds[chapterId] = currentContentId;

        const selectedModuleIds = { ...s0.selectedModuleIds };
        const selectedContentId = selectedModuleIds[chapterId];
        if (
          selectCurrent ||
          !selectedContentId ||
          !ml.some((m) => selectedContentId === m.contentId)
        ) {
          selectedModuleIds[chapterId] = currentContentId || this.noContentId;
        }

        return {
          modules,
          currentModuleIds,
          selectedModuleIds,
        };
      });
    };

  handleGetModulesError = (chapterId) => () => {
    this.setState((s0) => {
      const currentModuleIds = { ...s0.currentModuleIds };
      const selectedModuleIds = { ...s0.selectedModuleIds };
      currentModuleIds[chapterId] = null;
      selectedModuleIds[chapterId] = this.noContentId;
      return { currentModuleIds, selectedModuleIds };
    });
  };

  getSubjectChapterListNew = (coursePid) => {
    
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_course_publish_hier_v2",
        args: { coursePublishId: coursePid },
      })
      .then(this.handleGetSubjectChapterListNewResponse)
      .catch(this.handlegetSubjectChapterListNewFailure);
  };

  handleGetSubjectChapterListNewResponse = ({ rsp }) => {
    // alert(JSON.stringify(rsp))
    this.setState({ ChapterProgress: rsp[0] });
    const chapter = rsp[0].course_chapter_details;
    const detail = rsp[0];
    const items = {};
    const chapterDetails = {
      description: detail.description,
      certificate: detail.certificate,
      trainerName: detail.trainer_name,
    };
    chapter.forEach((row) => {
      const cId = row.id;
      const item = this.mkChapterItem(row);
      if (item) {
        items[cId] = item;
      }
    });
    this.setState((s0) => {
      return {
        isSubjectLoaded: true,
        chapters: items,
        chapterDetails: chapterDetails,
      };
    });
  };

  mkChapterItem = (row) => {
    if (!row.id) {
      //Fix me
      return null;
    }
    // if (row.total_count === '0') {
    //   return null;
    // }
    // const remainingCount = row.total_count - row.completed_count;
    // const name = 0 < remainingCount ?
    //   <div className='bold'>
    //     {`${row.name1} (${remainingCount})`}
    //   </div> :
    //   row.name1;
    const completionPercent =
      Math.floor((row.completed_count / row.total_count) * 100) || 0;
    return {
      id: row.id.toString(),
      name: row.name1,
      nameWithCount: row.name,
      type: row.type,
      // configId: row.config_id,
      // sharedBy: subject.sharedBy,
      pointsRewarded: row.points_rewarded, // todo from API
      pointsAvailable: row.points_available, // todo from API
      completionPercent: completionPercent,
      completedCount: util.toInt(row.completed_count),
      totalCount: util.toInt(row.total_count),
      sequence: util.toInt(row.sequence),
      // contentLeft: row.content_left, // todo from API
      // contentLeftText: row.content_left_text, //todo from API
      subjectName: row.subject,
      subjectShortName: row.subject_short,
      image: row.image,
      description: row.description,
      notesAvailable: false,
      chapterType: row.chapter_assignment,
    };
  };

  updateAnalytics = (screen) => (s) => {
    this.setState((s1) => {
      const updater = [...s1.analytics[screen]];
      updater.push(s);

      const analytics = {
        ...s1.analytics,
        [screen]: updater,
      };
      return { analytics };
    });
  };

  updateValue = (data, status) => {
    if (status === "join") {
      this.state.joinAessionFirstData === 0 &&
        this.setState({
          joinSessionStartTime: new Date(),
          joinAessionFirstData: 1,
        });
      this.initialTime = new Date();
    } else if (status === "leave") {
      this.state.joinAessionFirstData === 1 &&
        this.setState({ joinAessionFirstData: 0 });
    }
    this.setState({ activeSession: data, sessionStatus: status });
  };

  getMenu = (menu) => {
    this.setState({ menu });
  };

  subjecthoverleave=(value)=>{
    this.setState({isMobileSidebarOpen:value})
    console.log(value);
  }
  sidebarsubjecthover =(value) =>{
    this.setState({isMobileSidebarOpen:value})
  }
  handleMenuSelect = (menuId, reportID, reportType) => {
    const selectedMenuItem = this.state.menu[menuId];
    const selectedScreenId = this.state.menu[menuId].screen_id;
    const selectedScreenProps = selectedMenuItem.screen_props;
    //const reportID=this.state.menu[menuId].pb_report_id;
    // const sidebarMenu = 
    if(!this.state.isMobileSidebarOpen && window.innerWidth >= 768){
      this.setState({isMobileSidebarOpen: false})
    }else if(this.state.isMobileSidebarOpen && window.innerWidth >= 768){
      this.setState({isMobileSidebarOpen: true})
    }else{
      this.setState({isMobileSidebarOpen: false})
    }

    const menuID = {menuId};
    const jsonData = JSON.stringify(menuID);

    const encryptedMenuId = encryptObject(jsonData);
    localStorage.setItem('selectedMenuId', encryptedMenuId);     
                          
    this.setState({
      selectedMenuId: menuId,
      selectedScreenProps,
      selectedScreenId,
      alternateEntry: null,
     isMobileSidebarOpen: false,
      reportId: reportID,
      reportType: reportType
    });
  };

  handleUserCreditsChange = (credits, level) => {
    const campusList = this.props.ctx_user.user.campusList.map((c, i) => {
      return c.organization_id.toString() ===
        this.props.ctx_user.user.organizationId.toString() &&
        c.role_id.toString() === this.props.ctx_user.user.roleId.toString()
        ? { ...c, total_points: credits }
        : { ...c };
    });
    this.props.ctx_user.update((user) => ({
      ...user,
      credits,
      level,
      campusList,
    }));
  };

  handleMenuChange = (screenId, action) => {
    const { menu } = this.state;

    const menuId = () => {
      const testMenu = menu.filter((m1) => m1.screen_id === screenId);
      return testMenu[0].menu_id;
    };
    const selectedMenuId = menuId();
    // const selectedMenuItem = menu[selectedMenuId];
    const selectedScreenProps = { action: action };
    this.setState(() => {
      return {
        selectedMenuId,
        selectedScreenId: screenId,
        selectedScreenProps,
      };
    });
  };

  handleUpdateSubjects = () => {
    const setLoadSubjects = this.state.loadSubjects
      ? this.setState({ loadSubjects: false })
      : this.setState({ loadSubjects: true });
  };

  reRender = (screenId, screenProps, alternateEntry = null) => {
    this.setState((s0) => {
      let selectedMenu = s0.selectedMenuId;
      if (alternateEntry !== null) {
        s0.menu.forEach((s1) => {
          if (s1.screen_id === screenId) {
            selectedMenu = s1.menu_id;
          }
        });
      }
      return {
        // selectedEntryIds,
        selectedScreenId: screenId,
        selectedScreenProps: screenProps,
        alternateEntry: alternateEntry,
        selectedMenuId: selectedMenu,
       
      };
    },
    );
  };

  renderChapter = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <ChapterCreate
        action={action}
        keyName="chapter"
        actionNoun={action}
        alternateEntry={this.state.alternateEntry}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        screenId={config.memberSubmenuE.CHAPTER}
        name="chapter"
        selectedScreenProps={this.state.selectedScreenProps}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "update" })
        }
        hasTrainerMilestone
      />
    );
  };

  renderCourse = () => {
    const action = this.state.coursePassSelect ? "add" : this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
       const handlePassSelect = (val) => {
    this.setState({coursePassSelect:  val})
      }
      
    return (
      <Course
        name="course"
        keyName="course"
        action={action}
        actionNoun={action}
        passSelect = {handlePassSelect}
        onCreditsChange={this.handleUserCreditsChange}
        alternateEntry={this.state.alternateEntry}
        theme={this.props.ctx_theme.theme}
        addRedirection={() =>
          this.reRender( config.memberSubmenuE.COURSE,{ action: action })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.COURSE, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.COURSE_PUBLISH
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        redirectionRoute={config.ROUTES.coursePublish}
        hasTrainerMilestone
        fromCal={this.props.location.isCalendar}
        // fromCourse={this.props.location.entity}
        menuIds={this.props.menuId}
      />
    );
  };
  renderReportPane = () => {
    const { menu, selectedMenuId } = this.state;
    const menus = menu[selectedMenuId];
    return (
      <>
        {this.state.menu.length > 0 ? (
          <Reports
            name="report"
            menu={menus || []}
            selectedMenuId={this.state.selectedMenuId}
            onChangeScreen={this.handleChangeScreen}
          />
        ) : (
          <Redirect to="/" />
        )}
      </>
    );
  };
  renderPbiReportPane = () => {
    if (this.state.reportId == "") {
      return <Redirect to="/" />;
    }
    return (
      <PowerBi
        name="PowerBi Reports"
        reportId={this.state.reportId}
        role={this.state.redirect}
        reportType={this.state.reportType}
      />
    );
  };
  renderQuestionBank = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <QuestionBank
        name="questionbank"
        keyName="questionbank"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.QUESTION_BANK, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.QUESTION_BANK, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
        hasTrainerMilestone
      />
    );
  };

  renderCurriculum = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Curriculum
        name="curriculum"
        keyName="curriculum"
        action={action}
        actionNoun={action}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.CURRICULUM, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.CURRICULUM, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.COURSE
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        redirectionRoute={config.ROUTES.courses}
      />
    );
  };
  renderAssessmentList = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";

      const alternateEntry = this.props.location.state
      ? this.props.location.state.alternateEntry
      : this.state.alternateEntry;
      
  
    return (
      <AssessmentList
        alternateEntry={alternateEntry}
        name="assessmentlist"
        keyName="assessmentlist"
        action={action}
        fromClassViewer={this.state.fromClassViewer}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.ASSESSMENT_LIST, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.ASSESSMENT_LIST, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.ASSESSMENT_LIST
        ) => this.reRender(screenId, { action: action }, entry)}
        pubTestRedirection={(
          entry,
          action = "update",
          screenId = config.memberSubmenuE.PUBLISH_TEST
        ) => this.reRender(screenId, { action: action }, "")}
        menu={this.props.location.state && this.props.location.state.menu ? this.props.location.state.menu : this.state.menu}
        selectedMenuId={this.props.location.state && this.props.location.state.selectedMenuId ? this.props.location.state.selectedMenuId : this.state.selectedMenuId}
        curriculumId={this.state.selectedCurricId}
        categoryId={this.state.selectedCategoryId}
        coursePublishId={this.state.selectedCoursePublishId}
        publishId={this.props.location.state && this.props.location.state.publishId ? this.props.location.state.publishId : this.state.selectedPublishId}
      />
    );
  };

  renderSurveyList = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <SurveyList
        name="surveyList"
        keyName="survey"
        action={action}
        fromClassViewer={this.state.fromClassViewer}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.SURVEY_LIST, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.SURVEY_LIST, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.SURVEY_LIST
        ) => this.reRender(screenId, { action: action }, entry)}
        pubSurveyRedirection={(
          entry,
          action = "update",
          screenId = config.memberSubmenuE.PUBLISH_SURVEY
        ) => this.reRender(screenId, { action: action }, "")}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        curriculumId={this.state.selectedCurricId}
        categoryId={this.state.selectedCategoryId}
        coursePublishId={this.state.selectedCoursePublishId}
        publishId={this.state.selectedPublishId}
      />
    );
  };


  renderTemplates = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <Templates
          name={this.props.ctx_labels.singular.template}
          keyName="templates"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.TEMPLATES, {
              action: "add",
            })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.TEMPLATES, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };
  renderGrade = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Grade
        name="Grade"
        keyName="Academic"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.GRADE, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.GRADE, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
      />
    );
  };
  renderAcademicYear = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <AcademicYear
        name="academicYear"
        keyName="academicYear"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.ACADEMIC_YEAR, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.ACADEMIC_YEAR, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
      />
    );
  };
  renderTest = () => {
    const action = this.state.coursePassSelect ? "add" : this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
      const handlePassSelect = (val) => {
        this.setState({coursePassSelect:  val})
          }
    return (
      <TestCreate
        name="test"
        keyName="test"
        action={action}
        actionNoun={action}
        fromClassViewer={this.state.fromClassViewer}
        passSelect = {handlePassSelect}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.TEST, { action: action })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.TEST, { action: "update" })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.PUBLISH_TEST
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        redirectionRoute={config.ROUTES.publishTest}
        // onChangeScreen={this.handleChangeScreen}
        // curriculumId={this.state.selectedCurricId}
        // categoryId={this.state.selectedCategoryId}
        // coursePublishId={this.state.selectedCoursePublishId}
        // allocIds={this.state.selectedAllocIds}
        // pubAlloc={this.state.selectedPubAlloc}
        hasTrainerMilestone
      />
    );
  };

  renderDivision = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Division
        name="division"
        keyName="division"
        action={action}
        actionNoun={action}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.DIVISION, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.DIVISION, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
      />
    );
  };

  renderModule = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <ModuleSearch
        name="module"
        keyName="module"
        action={action}
        actionNoun={action}
        onCreditsChange={this.handleUserCreditsChange}
        alternateEntry={this.state.alternateEntry}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.MODULE, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.MODULE, { action: "update" })
        }
        alternateRedirection={(
          entry,
          action = "update",
          screenId = config.memberSubmenuE.MODULE
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
        hasTrainerMilestone
      />
    );
  };

  renderRooms = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <VirtualRoom
        name={this.props.ctx_labels.singular.rooms}
        keyName="room"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.ROOM, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.ROOM, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        //onChangeScreen={this.handleChangeScreen}
        hasTrainerMilestone
      />
    );
  };
  renderPublishTest = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";

    return (
      <PublishTestSearch
        name={this.props.ctx_labels.singular.publishTest}
        keyName="publishtest"
        action={action}
        actionNoun={action}
        alternateEntry={this.state.alternateEntry}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.PUBLISH_TEST, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.PUBLISH_TEST, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.ASSESSMENT_LIST
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        redirectionRoute={config.ROUTES.assessmentList}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
        customAlternateTip={`View`}
        hasTrainerMilestone
      />
    );
  };

  renderSchools = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <School
        name="school"
        keyName="school"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.SCHOOL, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.SCHOOL, { action: "update" })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        //onChangeScreen={this.handleChangeScreen}
      />
    );
  };

  renderTrainee = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";

    return (
      action && (
        <StudentUser
          name="member"
          keyName="member"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.MEMBER, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.MEMBER, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };

  renderTrainer = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <TeacherUser
          name="trainer"
          keyName="trainer"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SME_USER, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SME_USER, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };

  renderAdmin = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <AdminUser
          name="administrator"
          keyName="administrator"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.ADMIN_USER, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.ADMIN_USER, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };

  renderCampus = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <Campus
          name="campus"
          keyName="campus"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.CAMPUS, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.CAMPUS, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };

  renderRole = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <Role
          name="role"
          keyName="role"
          action={action}
          actionNoun={action}
          onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.ROLE, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.ROLE, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };

  renderClasslist = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <Classlist
          name="classlist"
          keyName="classlist"
          action={action}
          actionNoun={action}
          // addRedirection = {() => this.reRender(config.memberSubmenuE.CLASS,{ action:'add'})}
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.CLASS, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
        />
      )
    );
  };

  renderCoursePublish = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <CoursePublish
          action={action}
          keyName="coursePublish"
          actionNoun={action}
          alternateEntry={this.state.alternateEntry}
          menu={this.state.menu}
          selectedMenuId={this.props.location.isCalendar ? this.props.location.menuId : this.state.selectedMenuId}  
          screenId={config.memberSubmenuE.COURSE_PUBLISH}
          name="coursePublish"
          selectedScreenProps={this.state.selectedScreenProps}
          onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.CHAPTER, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.CHAPTER, { action: "update" })
          }
          hasTrainerMilestone
          fromCal={this.props.location.isCalendar}
          fromCourses={this.props.location.entity}
          menuIds={this.props.MenuId}
        />
      )
    );
  };

  renderSession = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Session
        action={action}
        keyName="session"
        actionNoun={action}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        screenId={config.memberSubmenuE.CHAPTER}
        name="session"
        selectedScreenProps={this.state.selectedScreenProps}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "update" })
        }
        hasTrainerMilestone
      />
    );
  };

  renderKnowledgeShelf = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <KnowledgeShelf
        action={action}
        keyName="knowledgeShelf"
        actionNoun={action}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        screenId={config.memberSubmenuE.CHAPTER}
        name="knowledgeShelf"
        selectedScreenProps={this.state.selectedScreenProps}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "update" })
        }
      />
    );
  };

  renderClass = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <ClassCreate
        action={action}
        keyName="class"
        actionNoun={action}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        screenId={config.memberSubmenuE.CHAPTER}
        name="class"
        selectedScreenProps={this.state.selectedScreenProps}
        onCreditsChange={this.handleUserCreditsChange}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.CHAPTER, { action: "update" })
        }
      />
    );
  };

  renderSyllabus = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <Syllabus
          name="syllabus"
          keyName="syllabus"
          action={action}
          actionNoun={action}
          onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SYLLABUS, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SYLLABUS, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        // </PageLayout>
      )
    );
  };
  renderGoi = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout>
        <GOI
          name={this.props.ctx_labels.singular.goi}
          keyName="goi"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.GROUP_OF_INSTITUTE, {
              action: "add",
            })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.GROUP_OF_INSTITUTE, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
        // </PageLayout>
      )
    );
  };
  renderAiConfig = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <AIConfig
          name="Configuration"
          keyName="aiConfiguration"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.AICONFIG, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.AICONFIG, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        // </PageLayout>
      )
    );
  };
  renderAiBot = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <AIBot
          name="Bot"
          keyName="bot"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.AIBOT, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.AIBOT, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        /* </PageLayout> */
      )
    );
  };

  renderSubjectProgress = () => {
    return (
      <Progress
        name="subject progress"
        action="update"
        actionNoun="update"
        menu={this.props.location.state && this.props.location.state.menu ? this.props.location.state.menu : this.state.menu}
        selectedMenuId={this.props.location.state && this.props.location.state.selectedMenuId ? this.props.location.state.selectedMenuId : this.state.selectedMenuId}
        classRedirection={(
          action = "update",
          screenId = config.memberSubmenuE.CLASS
        ) => this.reRender(screenId, { action: action }, "")}
      />
    );
  };

  renderClassStudentList = () => {
    return (
      <ClassForm
        name="classlist"
        keyName="classList"
        action="update"
        actionNoun="update"
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        studentData={this.props.location.Data}
      />
    );
  };

  renderExternalChannels = (id) => {
    return (
      // <PageLayout menuItem={this.state.menu[id]}>
      <ExternalContent />
      /* </PageLayout> */
    );
  };

  renderViewShelf = (id) => {
    return (
      // <PageLayout menuItem={this.state.menu[id]}>
      <ViewShelf />
      /* </PageLayout> */
    );
  };
  renderCreateShelf = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <CreateShelf
          name="Shelf"
          keyName="Shelf"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SHELF, {
              action: "add",
            })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SHELF, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
        /* </PageLayout> */
      )
    );
  };

  renderAttendance = (id) => {
    return (
      // <PageLayout menuItem={this.state.menu[id]}>
      <Attendance />
      // </PageLayout>
    );
  };
  renderRole = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <Role
          name="role"
          keyName="role"
          action={action}
          actionNoun={action}
          onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.ROLE, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.ROLE, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
        //</PageLayout>this.state.menu
      )
    );
  };

  renderMenu = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        //<PageLayout menuItem={this.state.menu[id]}>
        <Menu
          name="menu"
          keyName="menu"
          action={action}
          actionNoun={action}
          onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.MENU, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.MENU, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
        //</PageLayout>
      )
    );
  };

  renderCategories = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout>
        <Standard
          name="category"
          keyName="Standard"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.STANDARD, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.STANDARD, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        // </PageLayout>
      )
    );
  };

  renderSite = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Site
        name="site"
        keyName="site"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.SITE, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.SITE, { action: "update" })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };

  /***** Menu ENDS ******/

  handleActiveChildChange = (rsp) => {
    this.props.ctx_user.update((user) => ({
      ...user,
      dependantId: rsp.dependent_id,
      dependantName: rsp.dependent_name,
      dependantPhoto: rsp.dependent_photo,
    }));
  };

  handleRefreshOrgTheme = (campus) => {
    const { user, refreshOrgTheme } = this.props.ctx_user;
    refreshOrgTheme(
      campus.organization_id,
      user.type,
      user.id,
      user.goiId,
      user.schoolId,
      user.classId,
      user.acadYearId,
      user.activeLanguageId
    );
  };

  handleLanguageSwitching = () => {
    this.setState({ languagebtn: true });
    this.props.ctx_api
      .privateRequest({
        cmd: "update_active_language",
        args: {
          languageId: parseInt(localStorage.getItem("languageId")),
        },
      })
      .then(this.handleLanguageSwitchingResponse)
      .catch(this.handleLanguageSwitchingFailure());
  };

  handleLanguageSwitchingResponse = ({rsp}) => {
    const activeLanguageId = rsp[0].language_id
    if(rsp[0].statusvalue === 1){
      this.setState({languagebtn: false})
      this.handleRefreshRedirect(activeLanguageId)
    this.props.ctx_window.alert(`${this.props.ctx_labels.singular.languagePopUp}`);
      // this.props.ctx_window.confirm({
      //   message: "Langugae updated successfully",
      //   yesText: "Okay",
      //   noText: "",
      //   callback: this.handleRefreshRedirect(activeLanguageId),
      // });
    }
  };

  renderCertificateConfiguration = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <CertificateConfiguration
          name="configuration"
          keyName="achievement_configuration"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.CERTIFICATE_CONFIGURATION, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.CERTIFICATE_CONFIGURATION, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        /* </PageLayout> */
      )
    );
  };

  handleRefreshRedirect = (activeLanguageId) => {
    const { user, refreshOrgTheme } = this.props.ctx_user;
    refreshOrgTheme(
      user.organizationId,
      user.type,
      user.id,
      user.goiId,
      user.schoolId,
      user.classId,
      user.acadYearId,
      activeLanguageId
    );
  };

  handleLanguageSwitchingFailure = () => {
  };

  handleOrganizationSwitching = (campus, activeChild) => {
    this.setState({ branchSwitching: true, redirectHome: true });
    this.props.ctx_api
      .privateRequest({
        cmd: "update_active_member",
        args: {
          campusId: campus.organization_id,
          roleId: campus.role_id,
          memberTypeId: campus.member_type,
          dependantId: activeChild,
        },
      })
      .then(this.handleOrganizationSwitchingResponse(campus))
      .catch(this.handleOrganizationSwitchingFailure);
  };

  handleOrganizationSwitchingResponse =
    (campus) =>
    ({ rsp }) => {
      if (rsp[0].StatusValue === 1) {
        this.setState({ currRole: campus.role_name });
        this.props.ctx_user.update((user) => ({
          ...user,
          dependantId: rsp[0].dependent_id,
          dependantName: rsp[0].dependent_name,
          dependantPhoto: rsp[0].dependent_photo,
          dependantClassId: rsp[0].dep_class_id,
          dependantClassName: rsp[0].dep_class_name,
          classId: rsp[0].class_id,
          className: rsp[0].class_name,
          goiId: campus.goi_id ? campus.goi_id.toString() : campus.goi_id,
          schoolId: campus.school_id
            ? campus.school_id.toString()
            : campus.school_id,
          organizationId: campus.organization_id
            ? campus.organization_id.toString()
            : campus.organization_id,
          acadYearId: campus.acad_year_id
            ? campus.acad_year_id.toString()
            : null,
          acadYearName: campus.acad_year_name
            ? campus.acad_year_name.toString()
            : campus.acad_year_name,
          enterprise: campus.enterprise ? campus.enterprise : null,
          roleId: campus.role_id ? campus.role_id.toString() : campus.role_id,
          type: campus.member_type,
          credits: campus.total_points,
          campusCategories: campus.campus_categories || [],
          campusSubj: campus.campus_subj || [],
          showCredits: rsp[0].show_credits,
        }));
        this.handleRefreshOrgTheme(campus);
        this.fetchSideNavDashboadData(
          // rsp[0].dependent_id,
          campus.role_id ? campus.role_id.toString() : campus.role_id
        );
        this.handleActiveChildChange(rsp[0]);
        this.setState({ branchSwitching: false });
      } else {
        const msg = msgs.mkCampusSwitchErrorAlert();
        this.props.ctx_window.alert(msg);
        this.setState({ branchSwitching: false });
      }
    };

  handleOrganizationSwitchingFailure = () => {};

  handleUpdateUser = (campus) => {
    this.props.ctx_user.update((user) => ({
      ...user,
      goiId: campus.goi_id ? campus.goi_id.toString() : campus.goi_id,
      schoolId: campus.school_id
        ? campus.school_id.toString()
        : campus.school_id,
      organizationId: campus.organization_id
        ? campus.organization_id.toString()
        : campus.organization_id,
      acadYearId: campus.acad_year_id ? campus.acad_year_id.toString() : null,
      acadYearName: campus.acad_year_name
        ? campus.acad_year_name.toString()
        : campus.acad_year_name,
      enterprise: campus.enterprise ? campus.enterprise : null,
      roleId: campus.role_id ? campus.role_id.toString() : campus.role_id,
      type: campus.member_type,
      credits: campus.total_points,
      campusCategories: campus.campus_categories || [],
      campusSubj: campus.campus_subj || [],
    }));
    const { user } = this.props.ctx_user;
    // user.type === 3 && this.getTrainerPerformance();
    // this.clearState();
    // this.handleComponentMount();

    this.setState({ branchSwitching: false });
  };

  handleTermsAccept = () => {
    this.props.ctx_user.update((user) => ({
      ...user,
      acceptedTermsAndConditions: true,
    }));
  };

  renderBranchSwitching = () => (
    <div>
      <Loader width={25} padding="2em">
        {" "}
        Switching Campus...
      </Loader>
    </div>
  );
  handleAfterBranchSwitch = () => {
    this.setState({ redirectHome: false });
    return <Redirect to={"/"} />;
  };

  renderSettingsPane = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout>
        <Settings
          name="configuration"
          keyName="configuration"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SETTINGS, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SETTINGS, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          // onChangeScreen={this.handleChangeScreen}
        />
        // </PageLayout>
      )
    );
  };
  renderTermsAndConditions = () => (
    <TermsAndConditions
      modalTitle="Terms and Conditions"
      modalBody={this.props.ctx_user.user.termsAndConditions}
      displayCheckbox={true}
      acceptButtonLabel="Accept"
      rejectButtonLabel="Reject"
      modalBodyHeader="AGREEMENT TO TERMS"
      checkBoxlabel="I have read the terms and conditions"
      apiOnAccept="update_terms_conditions"
      onTermsAccept={this.handleTermsAccept}
    />
  );

  renderChapterAllocation = () => {
    return (
      <ChapterSelectForm
        name="chapterAllocation" //{this.labelNames("chapterAllocation", false)}
        action="update"
        keyName="chapterAllocation"
        actionNoun="update"
      />
    );
  };

  renderPlayback = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        // <PageLayout menuItem={this.state.menu[id]}>
        <Playback
          name="Playback"
          action={action}
          actionNoun={action}
          // onCreditsChange={this.handleUserCreditsChange}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.PLAYBACK, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.PLAYBACK, { action: "update" })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
        //</PageLayout>
      )
    );
  };

  renderPromotion = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Promotion
        name="Promotion"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.PROMOTION, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.PROMOTION, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        onChangeScreen={this.handleChangeScreen}
      />
    );
  };

  renderParent = (id) => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      action && (
        <ParentUser
          name="parent"
          keyName="parent"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SME_USER, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SME_USER, {
              action: "update",
            })
          }
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
        />
      )
    );
  };
  handleSidebarToggle = (val) => {
    this.setState({ sideBarState: val });
    //this.state.sideBarState=val;
  };

  renderZone = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Zone
        name="zone"
        keyName="zone"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.ZONE, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.ZONE, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.ZONE
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };

  renderDifficultyLevel = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <DifficultyLevel
        name="difficultyLevel"
        keyName="difficultyLevel"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.DIFFICULTY_LEVEL, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.DIFFICULTY_LEVEL, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.DIFFICULTY_LEVEL
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };
  renderworkdayLogs = () => {
    const handleMonthData = (data) => {
      this.setState({monthData:data})
    }
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <WorkdayLogs
        name="WorkdayLogs"
        keyName="workdayLogs"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.WORKDAY_LOGS, {
            action: "add",
          })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.WORKDAY_LOGS, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.WORKDAY_LOGS
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
        handleMonthData={handleMonthData}
        monthData={this.state.monthData}
      />
    );
  };

  renderLanguage = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <Language
        name="language"
        keyName="language"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.LANGUAGE, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.LANGUAGE, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };

  renderMediaConvert = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <MediaConvert
        name="mediaConvert"
        keyName="mediaConvert"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.MEDIA_CONVERT, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.MEDIA_CONVERT, {
            action: "update",
          })
        }
        alternateRedirection={(
          entry,
          action = "add",
          screenId = config.memberSubmenuE.MEDIA_CONVERT
        ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };

 

  renderCoursePublishStatus = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      // <ViewerForm
      //   name="Viewer"
      //   keyName="Viewer"
      //   action={action}
      //   actionNoun={action}
      //   // addRedirection={() =>
      //   //   this.reRender(config.memberSubmenuE.Viewer, { action: "add" })
      //   // }
      //   // updateRedirection={() =>
      //   //   this.reRender(config.memberSubmenuE.VIEWER, {
      //   //     action: "update",
      //   //   })
      //   // }
      //   // alternateRedirection={(
      //   //   entry,
      //   //   action = "add",
      //   //   screenId = config.memberSubmenuE.TRACKER
      //   // ) => this.reRender(screenId, { action: action }, entry)}
      <CoursePublishStatus
        name="coursePublishStatus"
        keyName="coursePublishStatus"
        action={action}
        actionNoun={action}
        addRedirection={() =>
          this.reRender(config.memberSubmenuE.COURSE_PUBLISH_STATUS, { action: "add" })
        }
        updateRedirection={() =>
          this.reRender(config.memberSubmenuE.COURSE_PUBLISH_STATUS, {
            action: "update",
          })
        }
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId ? this.state.selectedMenuId : 31 }
      />
    );
  };


  renderTracker = () => {
    const action = this.state.selectedScreenProps.action
      ? this.state.selectedScreenProps.action
      : "update";
    return (
      <TrackerForm
        name="tracker"
        keyName="tracker"
        action={action}
        actionNoun={action}
        // addRedirection={() =>
        //   this.reRender(config.memberSubmenuE.TRACKER, { action: "add" })
        // }
        // updateRedirection={() =>
        //   this.reRender(config.memberSubmenuE.TRACKER, {
        //     action: "update",
        //   })
        // }
        // alternateRedirection={(
        //   entry,
        //   action = "add",
        //   screenId = config.memberSubmenuE.TRACKER
        // ) => this.reRender(screenId, { action: action }, entry)}
        menu={this.state.menu}
        selectedMenuId={this.state.selectedMenuId}
      />
    );
  };

  renderViewerForm = () => {
    const action = this.state.selectedScreenProps.action
        ? this.state.selectedScreenProps.action
        : "update";
      return (
        <ViewerForm
          name="viewer"
          keyName="viewer"
          action={action}
          actionNoun={action}
          // addRedirection={() =>
          //   this.reRender(config.memberSubmenuE.Viewer, { action: "add" })
          // }
          // updateRedirection={() =>
          //   this.reRender(config.memberSubmenuE.VIEWER, {
          //     action: "update",
          //   })
          // }
          // alternateRedirection={(
          //   entry,
          //   action = "add",
          //   screenId = config.memberSubmenuE.TRACKER
          // ) => this.reRender(screenId, { action: action }, entry)}
          />
      )
    }

    renderSurvey = () => {
      const action = this.state.selectedScreenProps.action
        ? this.state.selectedScreenProps.action
        : "update";
      return (
        <Survey
          name="survey"
          keyName="survey"
          action={action}
          actionNoun={action}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.SURVEY, { action: "add" })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.SURVEY, {
              action: "update",
            })
          }
          alternateRedirection={(
            entry,
            action = "add",
            screenId = config.memberSubmenuE.PUBLISH_SURVEY
          ) => this.reRender(screenId, { action: action }, entry)}
          menu={this.state.menu}
          selectedMenuId={this.state.selectedMenuId}
          redirectionRoute={config.ROUTES.publishSurvey}
          hasTrainerMilestone
        />
      );
    };

    renderPublishSurvey = () => {
      const action = this.state.selectedScreenProps.action
        ? this.state.selectedScreenProps.action
        : "update";
  
      return (
        <PublishSurveySearch
          name="publishSurvey"
          keyName="surveyPublish"
          action={action}
          actionNoun={action}
          alternateEntry={this.state.alternateEntry}
          addRedirection={() =>
            this.reRender(config.memberSubmenuE.PUBLISH_SURVEY, {
              action: "add",
            })
          }
          updateRedirection={() =>
            this.reRender(config.memberSubmenuE.PUBLISH_SURVEY, {
              action: "update",
            })
          }
          alternateRedirection={(
            entry,
            action = "add",
            screenId = config.memberSubmenuE.SURVEY_LIST
          ) => this.reRender(screenId, { action: action }, entry)}
           menu={this.state.menu}
          redirectionRoute={config.ROUTES.surveyList}
          selectedMenuId={this.state.selectedMenuId}
          onChangeScreen={this.handleChangeScreen}
          customAlternateTip={`View`}
          theme={this.props.ctx_theme.theme}
          hasTrainerMilestone
        />
      );
    };
    handlehover=(value)=>{
      this.setState ({isMobileSidebarOpen:value})
   
    }
    hoversidebarSubject=(value)=>{
      
      this.setState({isMobileSidebarOpen:value})
      this.setState({hoversubject:value})
    }
    hoversubjectenter1=(value)=>{
      console.log(value,"value");
      this.setState({isMobileSidebarOpen:value})
    }
    handlesidebar=(value)=>{
      this.setState({isMobileSidebarOpen:value})
    }
    handleBurgerAction=(value)=>{
      console.log(value);
      this.setState({isMobileSidebarOpen:value})
    }
    hovermainsidebar=(value)=>{
      this.setState({isMobileSidebarOpen:value})
      this.setState({ishover1:value})
    }
    handleviewshelfLeave=(value)=>{
      this.setState({isMobileSidebarOpen:value})
    }
    handleoldcousre=(value)=>{
      this.setState({isMobileSidebarOpen:value})
    }
    handlebotmenu=(value)=>{
      this.setState({isMobileSidebarOpen:value})
    }
    handleprofileclick1=(value)=>{
      console.log(value);
      this.setState({isMobileSidebarOpen:false})
    }
    handleFlyoutMenuChange = (open) => {
      if(open){
        this.setState({isMobileSidebarOpen: false})
      }
      else{
        // this.setState({isMobileSidebarOpen:false})
      }
    }
    handlebotmenu1=()=>{
      this.setState({isMobileSidebarOpen:true})
    }
    handlehandleshelf1=()=>{
      this.setState({isMobileSidebarOpen:true})
    }
    handlesubjectmenuclick=()=>{
      this.setState({subjectmenu:true})
    }
 
    handleoldcoursemenuclick=()=>{
      this.setState({oldcoursemenu:true})
    }

    handleTrainigzoneDataLoader = (handleObjectData) => {
      this.setState({ myTrainingzone : handleObjectData})
    }

    handleShelfDataLoader = (rsp) => {
      this.setState({ myShelf : rsp.shelf })
    }
  
  render() {
    // const cssClass = this.state.sideBarState ? css.sidebar : css.sidebarState;
    const cssClass = this.state.isMobileSidebarOpen ? css.sidebar : css.sidebarState
    const { branchSwitching } = this.state;
    const coursePublishId = this.state.selectedCoursePublishId;
    const chapterId = this.state.selectedChapterId;
    const subject = this.state.subjects[coursePublishId];
    const campusList =
      this.props.ctx_user.user && this.props.ctx_user.user.campusList;

    const organizationId =
      this.props.ctx_user.user && this.props.ctx_user.user.organizationId;

    const roleId = this.props.ctx_user.user && this.props.ctx_user.user.roleId;
    const campus =
      campusList &&
      campusList.find(
        (d) => d.campus_role_id == `${organizationId} : ${roleId}`
      );
    let path = "";
    const analytics = {
      update: this.updateAnalytics,
      value: this.updateValue,
      validator: this.sessionActiveChecker,
    };
    const r = config.ROUTES;

    if (branchSwitching) {
      return this.renderBranchSwitching();
    }
    if (this.state.redirectHome) {
      return this.handleAfterBranchSwitch();
    }
    return (
      // context for mobile sidebar
      <>
      {this.props.ctx_user.user.passwordExpiry && <Redirect to={config.ROUTES.profileSettings} />}
        {!this.props.ctx_user.user.acceptedTermsAndConditions &&
          this.props.ctx_user.user.impersonization === 0 &&
          this.renderTermsAndConditions()}
        {(this.props.ctx_user.user.acceptedTermsAndConditions ||
          this.props.ctx_user.user.impersonization === 1) && (
          <AnalyticsContext.Provider value={analytics}>
            <MainContext.Provider value={this.state}>
              <Header
                loggedIn={true}
                noSidebar={this.props.noSidebar}
                onCampusSwitch={this.handleOrganizationSwitching}
                // isSidebarOpen={this.state.isMobileSidebarOpen}
                role={this.state.currRole}
              />
              <div className={css.wrapper}>
                {/* Sidebar */}
                {!this.state.switching && (
                  // !this.state.noSidebar &&
                  this.state.isMobileSidebarOpen ? (
                    <div
                    className={classNames(
                    css.sidebar,
                      this.state.isMobileSidebarOpen && css.sidebar_mobile_open
                    )}
                  >
                    <Sidebar 
                    apicallrestrictionsubject={this.state.subjectmenu}
                    handlesubjectmenuclick={this.handlesubjectmenuclick}
                    apicallrestrictionoldcourse={this.state.oldcoursemenu}
                    handleoldcoursemenuclick={this.handleoldcoursemenuclick}
                    shelfData = {this.state.myShelf}
                    handleShelfDataLoader ={this.handleShelfDataLoader}
                     handleshelf1={this.handlehandleshelf1}
                    handlebotmenu={this.handlebotmenu1}
                    profileclick1={this.handleprofileclick1}
                     menuChange={this.handleFlyoutMenuChange}
                    handlebothover={this.handlebotmenu}
                    handleoldcourse={this.handleoldcousre}
                    handleviewshelfLeave={this.handleviewshelfLeave}
                    sidebarhover={this.hovermainsidebar}
                    hoversidebarsubject={this.hoversidebarSubject}
                      hoversubjectenter={this.hoversubjectenter1}
                      handleleave ={this.handlesidebar}
                      isMobileOpen={this.state.isMobileSidebarOpen}
                      getMenu={this.getMenu}
                      onMenuSelect={this.handleMenuSelect}
                      sidenavData={this.state.menu}
                      sidenavLoading={this.state.sidenavLoading}
                      loadSubjects={this.state.loadSubjects}
                      showButton={this.state.showButton}
                      showItems={this.state.sideBarState}
                      onLogout={this.handleLogoutGraphql}
                      handleSidebarToggle={this.handleSidebarToggle}
                      getStudentSubjectList={this.getStudentSubjectList}
                      getStudentOldSubjectList={this.getStudentOldSubjectList}
                      oldSubjects={this.state.oldSubjects}
                      subjects={this.state.subjects}
                      hover={this.handlehover}
                      subListCompleted={this.state.subListCompleted}
                    ></Sidebar>
                  </div>
                  ) : (
                    <div
                    className={classNames(
                      css.sidebarState,
                      this.state.isMobileSidebarOpen && css.sidebar_mobile_open
                    )}
                  >
                    <SidebarIcons
                    menuChange={this.handleFlyoutMenuChange}
                    hoversubjectenter={this.sidebarsubjecthover}
                      isMobileOpen={this.state.isMobileSidebarOpen}
                      getMenu={this.getMenu}
                      onMenuSelect={this.handleMenuSelect}
                      sidenavData={this.state.menu}
                      sidenavLoading={this.state.sidenavLoading}
                      loadSubjects={this.state.loadSubjects}
                      showButton={this.state.showButton}
                      showItems={this.state.sideBarState}
                      onLogout={this.handleLogoutGraphql}
                      getStudentSubjectList={this.getStudentSubjectList}
                      getStudentOldSubjectList={this.getStudentOldSubjectList}
                      oldSubjects={this.state.oldSubjects}
                      completed={this.state.completed}
                      subjects={this.state.subjects}
                      burgerMenuAction = {this.handleBurgerAction}
                      subListCompleted={this.state.subListCompleted}
                    ></SidebarIcons>
                  </div>)     
              )}

                {/* {this.props.location.pathname !== config.ROUTES.dashboard && ( */}
                {/* <SlideBack handleSidebarToggle={this.handleSidebarToggle} /> */}
                {/* )} */}
                <Suspense fallback={<Loader width={25} padding="2em"></Loader>}>
                {/* Main */}
                <div className={css.main}>
                  <div>
                    <Switch>
                      <Route exact path={r.traineeCurriculum}>
                        <CurriculumStudent
                          grouped={true}
                          memberId={this.props.ctx_user.user.id}
                          coursePublishId={this.state.selectedCoursePublishId}
                          subject={subject}
                          chapterId={chapterId}
                          viewType={this.state.playerView}
                          selectedChapterId={chapterId}
                          onSelectModule={this.handleSelectModule(chapterId)}
                          refreshChapter={this.refreshChapter}
                          refreshModules={this.refreshModules(chapterId)}
                          onCreditsChange={this.handleUserCreditsChange}
                          listCurriculums={this.handleClickSubject}
                          hideSidebarVisibility={
                            this.props.hideSidebarVisibility
                          }
                          // refreshCurriculumList={this.refreshChapterList}
                          refreshCurriculumList={this.getSubjectChapterListNew}
                        />
                      </Route>
                      <Route exact path={r.traineeCardViewInner}>
                        <ChapterInner
                          onSelectModule={this.handleSelectModule(chapterId)}
                          onCreditsChange={this.handleUserCreditsChange}
                          refreshCurriculumList={this.getSubjectChapterListNew}
                          ChapterProgress={this.state.ChapterProgress}
                        />
                      </Route>
                      <Route exact path={r.subscriptionPreview}>
                        <SubscriptionPreview />
                      </Route>
                      <Route exact path={r.evaluateForm}>
                        <EvaluateForm {...this.props.location} />
                      </Route>
                      <Route exact path={r.sessionsMeet}>
                        <MeetForm />
                      </Route>
                      <Route exact path={r.assessments}>
                        <Assessments/>
                      </Route>
                      <Route exact path={r.assessmentsLanding}>
                        <AssessmentTrainee grouped={true} />
                      </Route>
                      <Route exact path={r.traineeSubscriptionSubject}>
                        <OpenSubjects
                          grouped={true}
                          updateSubscribeList={this.handleUpdateSubjects}
                          loadSubjects={this.state.loadSubjects}
                        />
                      </Route>
                      <Route exact path="/SurveyRouter">
                       <SurveyRouter {...this.props.location.state}/>
                      </Route>


                      <Route exact path={r.assessmentsAttend}>
                        <StudentAssessments grouped={true} />
                      </Route>
                      <Route exact path={r.trainingZone}>
                        <Trainingzone 
                        myTrainingzone = {this.state.myTrainingzone}
                        handleTrainigzoneDataLoader ={this.handleTrainigzoneDataLoader}
                        />
                      </Route>
                      <Route exact path={r.parentZonePage}>
                        <ParentZonePage />
                      </Route>
                      <Route exact path={r.trainingZoneContent}>
                        <TrainingzoneInner />
                      </Route>
                      <Route exact path={r.externalChannelsContent}>
                        <ExternalChannelsInner />
                      </Route>
                      <Route exact path={r.viewShelfContent}>
                        <ViewShelfInner />
                      </Route>
                      <Route exact path={r.subjectList}>
                        <ZonePage />
                      </Route>
                      <Route exact path={r.variants}>
                        <MultiCourseStudent courseData={this.props.location} />
                      </Route>
                      <Route exact path={r.calendar}>
                        <UserCalendar
                          onMenuChange={this.handleMenuChange}
                          coursePublishMenuId={this.state.coursePublishMenuId}
                        />
                      </Route>
                      <Route exact path={r.playback}>
                        {this.renderPlayback()}
                      </Route>
                      <Route exact path={r.join}>
                        <Join />
                      </Route>
                      <Route exact path={r.chapters}>
                        {this.renderChapter()}
                      </Route>
                      <Route exact path={r.subjects}>
                        {this.renderCurriculum()}
                      </Route>
                      <Route exact path={r.assessmentList}>
                        {this.renderAssessmentList()}
                      </Route>
                      <Route exact path={r.templates}>
                        {this.renderTemplates()}
                      </Route>
                      <Route exact path={r.campuses}>
                        {this.renderCampus()}
                      </Route>
                      <Route exact path={r.coursePublish}>
                        {this.renderCoursePublish()}
                      </Route>
                      <Route exact path={r.sessions}>
                        {this.renderSession()}
                      </Route>
                      <Route exact path={r.knowledgeShelf}>
                        {this.renderKnowledgeShelf()}
                      </Route>
                      <Route exact path={r.viewShelf}>
                        {this.renderViewShelf()}
                      </Route>
                      <Route exact path={r.createShelf}>
                        {this.renderCreateShelf()}
                      </Route>
                      <Route exact path={r.classes}>
                        {this.renderClass()}
                      </Route>
                      <Route exact path={r.courses}>
                        {this.renderCourse()}
                      </Route>
                      <Route exact path={r.rooms}>
                        {this.renderRooms()}
                      </Route>
                      <Route exact path={r.schools}>
                        {this.renderSchools()}
                      </Route>
                      <Route exact path={r.modules}>
                        {this.renderModule()}
                      </Route>
                      <Route exact path={r.publishTest}>
                        {this.renderPublishTest()}
                      </Route>
                      <Route exact path={r.trainees}>
                        {this.renderTrainee()}
                      </Route>
                      <Route exact path={r.trainers}>
                        {this.renderTrainer()}
                      </Route>
                      <Route exact path={r.admins}>
                        {this.renderAdmin()}
                      </Route>
                      <Route exact path={r.roles}>
                        {this.renderRole()}
                      </Route>
                      <Route exact path={r.classList}>
                        {this.renderClasslist()}
                      </Route>
                      <Route exact path={r.createTest}>
                        {this.renderTest()}
                      </Route>
                      <Route exact path={r.questionBank}>
                        {this.renderQuestionBank()}
                      </Route>
                      <Route exact path={r.academicYears}>
                        {this.renderAcademicYear()}
                      </Route>
                      <Route exact path={r.grade}>
                        {this.renderGrade()}
                      </Route>
                      <Route exact path={r.divisions}>
                        {this.renderDivision()}
                      </Route>
                      {/* <Route exact path={r.classlist}>
                        <ClassForm classData={this.props.location} />
                      </Route> */}
                      <Route exact path={r.chat}>
                        <Messages onMenuChange={this.handleMenuChange} />
                      </Route>
                      <Route exact path={r.class}>
                        <MyClasses
                          onMenuChange={this.handleMenuChange}
                          addRedirection={() =>
                            this.reRender(
                              config.memberSubmenuE.SUBJECT_PROGRESS,
                              { action: "add" }
                            )
                          }
                        />
                      </Route>
                      <Route exact path={r.profileSettings}>
                        <ProfileSettings
                         menuChange={this.handleFlyoutMenuChange}
                          onUserSwitch={this.handleUserSwitching}
                          onCampusSwitch={this.handleOrganizationSwitching}
                          onLanguageSwitch={this.handleLanguageSwitching}
                          languagebtndisabled={this.state.languagebtn}
                          dependantLeaderboardData={
                            this.state.dependantLeaderboardData
                          }
                          dependantIntentData={this.state.dependantIntentData}
                          dependantCourseData={this.state.dependantCourseData}
                          dependantAssessmentData={
                            this.state.dependantAssessmentData
                          }
                          dependantSessionData={this.state.dependantSessionData}
                          getParentLeaderboardData={
                            this.getParentLeaderboardData
                          }
                          getIntentData={this.getIntentData}
                          getParentDashboardData={this.getParentDashboardData}
                        />
                      </Route>
                      <Route exact path={r.home}>
                        {campus && campus.member_type === 2 ? (
                          <DashboardTrainer
                            credits={campus.total_points}
                            name={this.props.ctx_user.user.firstName}
                            addRedirection={() =>
                              this.reRender(
                                config.memberSubmenuE.ASSESSMENT_LIST,
                                {
                                  action: "add",
                                }
                              )
                            }
                            onMenuSelect={this.handleMenuSelect}
                            menuData={this.state.menu}
                            onMenuChange={this.handleMenuChange}
                            trainerCardData={this.state.trainerCardData}
                            trainerClassData={this.state.trainerClassData}
                            trainerAssessmentData={
                              this.state.trainerAssessmentData
                            }
                            trainerLeaderboardData={
                              this.state.trainerLeaderboardData
                            }
                            weeklyUserTime={this.state.weeklyUserTime}
                          />
                        ) : (campus && campus.member_type === 1) ||
                          this.props.ctx_user.user.newUser ? (
                          <DashboardTrainee
                            name={this.props.ctx_user.user.firstName}
                            traineeCardData={this.state.traineeCardData}
                            traineeCoursesData={this.state.traineeCoursesData}
                            loadSubjects={this.state.loadSubjects}
                            updateSubscribeList={this.handleUpdateSubjects}
                            traineeHomeAssessmentsData={
                              this.state.traineeHomeAssessmentsData
                            }
                            traineeProgressBoardData={
                              this.state.traineeProgressBoardData
                            }
                            userRecentActivityData={this.state.userRecentActivityData}
                            weeklyUserTime={this.state.weeklyUserTime}
                            // onClassChange={this.handleLeaderboardClassChange}
                          />
                        ) : campus && campus.member_type === 4 ? (
                          <DashboardParent
                            name={this.props.ctx_user.user.firstName}
                            dependantLeaderboardData={
                              this.state.dependantLeaderboardData
                            }
                            dependantIntentData={this.state.dependantIntentData}
                            dependantCourseData={this.state.dependantCourseData}
                            dependantAssessmentData={
                              this.state.dependantAssessmentData
                            }
                            dependantSessionData={
                              this.state.dependantSessionData
                            }
                            dependantSelect={this.state.dependantSelect}
                            dependantData={this.state.dependantData}
                            dependant={this.state.dependant}
                            handleChildChange={this.handleChildChange}
                            getParentLeaderboardData={
                              this.getParentLeaderboardData
                            }
                            getIntentData={this.getIntentData}
                            getParentDashboardData={this.getParentDashboardData}
                            onCampusSwitch={this.handleOrganizationSwitching}
                          />
                        ) : campus &&
                          (campus.member_type === 3 ||
                            campus.member_type === -1) ? (
                          <AdminHome
                            name={this.props.ctx_user.user.firstName}
                            location={this.props.location}
                            isMobileSidebarOpen  = {this.state.isMobileSidebarOpen}
                            onMenuSelect={this.handleMenuSelect}
                            menuData={this.state.menu}
                            onMenuChange={this.handleMenuChange}
                          />
                        ) : null}
                      </Route>
                      <Route exact path={r.adminDashboard}>
                          <AdminDashboard
                            name={this.props.ctx_user.user.firstName}
                            location={this.props.location}
                          />
                      </Route> 
                      <Route exact path={r.campusDashboard}>
                          <CampusDashboard
                            name={this.props.ctx_user.user.firstName}
                            location={this.props.location}
                          />
                      </Route> 
                      <Route exact path={r.teacherAnalytics}>
                          <TeacherAnalytics
                            location={this.props.location}
                            onMenuSelect={this.handleMenuSelect}
                            menuData={this.state.menu}
                            onMenuChange={this.handleMenuChange}
                          />
                      </Route> 
                      <Route exact path={r.classAnalytics}>
                          <ClassAnalytics
                            location={this.props.location}
                          />
                      </Route> 
                      <Route exact path={r.courseInsight}>
                        <CourseInsight
                         name={this.props.ctx_user.user.firstName}
                         location={this.props.location}
                         />
                      </Route>
                      <Route exact path={r.assessmentInsight}>
                        <AssessmentInsight
                        name={this.props.ctx_user.user.firstName}
                        location={this.props.location}
                        />
                      </Route>
                      <Route exact path={r.syllabus}>
                        {this.renderSyllabus()}
                      </Route>
                      <Route exact path={r.goi}>
                        {this.renderGoi()}
                      </Route>
                      <Route exact path={r.aiConfig}>
                        {this.renderAiConfig()}
                      </Route>
                      <Route exact path={r.aiBot}>
                        {this.renderAiBot()}
                      </Route>
                      <Route exact path={r.subjectProgress}>
                        {this.renderSubjectProgress()}
                      </Route>
                      <Route exact path={r.courseClassList}>
                        {this.renderClassStudentList()}
                        {/* <ClassForm {...this.props.location} /> */}
                      </Route>
                      <Route exact path={r.externalChannels}>
                        {this.renderExternalChannels()}
                      </Route>
                      <Route exact path={r.attendance}>
                        {this.renderAttendance()}
                      </Route>
                      <Route exact path={r.site}>
                        {this.renderSite()}
                      </Route>
                      <Route exact path={r.menu}>
                        {this.renderMenu()}
                      </Route>
                      <Route exact path={r.role}>
                        {this.renderRole()}
                      </Route>
                      <Route exact path={r.categories}>
                        {this.renderCategories()}
                      </Route>
                      <Route exact path={r.reports}>
                        {this.renderReportPane()}
                      </Route>
                      <Route exact path={r.pbiReports}>
                        {this.renderPbiReportPane()}
                      </Route>
                      <Route exact path={r.configuration}>
                        {this.renderSettingsPane()}
                      </Route>
                      <Route exact path={r.chapterallocation}>
                        {this.renderChapterAllocation()}
                      </Route>
                      {/* <Route exact path={r.questionAnswer}>
                    <QA />
                  </Route> */}
                      <Route exact path={r.promotion}>
                        {this.renderPromotion()}
                      </Route>
                      <Route exact path={r.parent}>
                        {this.renderParent()}
                      </Route>

                      <Route exact path={r.difficultyLevel}>
                        {this.renderDifficultyLevel()}
                      </Route>
                      <Route exact path={r.mediaConvert}>
                        {this.renderMediaConvert()}
                      </Route>
                      <Route exact path={r.zone}>
                        {this.renderZone()}
                      </Route>
                      <Route exact path={r.language}>
                        {this.renderLanguage()}
                      </Route>
                      <Route exact path={r.botCanvas}>
                        <BotCanvas />
                      </Route>
                      <Route exact path={r.canvasDocuments}>
                        <BotDocs />
                      </Route>
                      <Route exact path={r.documentQuestions}>
                        <DocumentQuestions />
                      </Route>
                      <Route exact path={r.questionDetails}>
                        <QuestionDetails />
                      </Route>
                      
                      <Route exact path={r.attendanceTracker}>
                        <AttendanceTracker />
                      </Route>
                      <Route exact path={r.oldCourses}>
                        <OldCourseRenderPreview />
                      </Route>
                      
                      <Route exact path={r.coursePublishStatus}>
                        {this.renderCoursePublishStatus()}
                      </Route>
                      <Route exact path={r.tracker}>
                        {this.renderTracker()}
                      </Route>
                      <Route exact path={r.createSurvey}>
                        {this.renderSurvey()}
                      </Route>
                      <Route exact path={r.publishSurvey}>
                        {this.renderPublishSurvey()}
                      </Route>
                      <Route exact path={r.surveyList}>
                        {this.renderSurveyList()}
                      </Route>
                      <Route exact path={r.surveyResponse}>
                        <StudentResponse {...this.props.location.state}/>
                      </Route>
                      <Route exact path={r.surveyAttend}>
                        <StudentSurveys {...this.props.location.state}/>
                      </Route>
                      <Route exact path={r.surveyGeneral}>
                        <SurveyGeneral {...this.props.location.state}/>
                      </Route>
                      <Route exact path={r.studentSurvey}>
                        <StudentSurveyAssessment />
                      </Route>
                      <Route exact path={r.assessmentSummaryDetails}>
                        <SummaryDetails />
                      </Route>
                     
                      <Route exact path={r.ContentBuilder}>
                        <ContentBuilder />
                      </Route>
                      <Route exact path={r.TestCreation}>
                        <TestCreation />
                      </Route>
                      <Route exact path={r.viewerForm}>
                        {this.renderViewerForm()}
                      </Route>
                        
                      <Route exact path={r.workdayLogs}>
                        {this.renderworkdayLogs()}
                      </Route>

                      <Route exact path={r.studentDashboard}>
                        <StudentDashboard
                          //onUserSwitch={this.handleUserSwitching}
                          //onCampusSwitch={this.handleOrganizationSwitching}
                          //onLanguageSwitch={this.handleLanguageSwitching}
                          //languagebtndisabled={this.state.languagebtn}
                          dependantLeaderboardData={
                            this.state.dependantLeaderboardData
                          }
                          dependantIntentData={this.state.dependantIntentData}
                          dependantCourseData={this.state.dependantCourseData}
                          dependantAssessmentData={
                            this.state.dependantAssessmentData
                          }
                          dependantSessionData={this.state.dependantSessionData}
                          getParentLeaderboardData={
                            this.getParentLeaderboardData
                          }
                          getIntentData={this.getIntentData}
                          getParentDashboardData={this.getParentDashboardData}
                          adminHome={this.state.adminHome}
                        />
                      </Route>
                      <Route exact path={r.trainerEvaluate}>
                        <TrainerEvaluate {...this.props.location} />
                      </Route>
                      <Route exact path={r.certificateConfiguration}>
                        {this.renderCertificateConfiguration()}
                      </Route>
                     
                    </Switch>
                  </div>
                </div>
                {/* /.main */}
              </Suspense>
              </div>
            </MainContext.Provider>
          </AnalyticsContext.Provider>
        )}
      </>
    );
  }
}

export { MainContext };
export default withAccessContext(
  withApiContext(
    withWindowContext(
      withFrameContext(
        withThemeContext(
          withUserContext(
            withLabelsContext(withAnalyticsContext(withSocketContext(withRouter(Main))))
          )
        )
      )
    )
  )
);
