import React, { Component } from "react";
import CardGroup from "./CardGroup/CardGroup";
import Modules from "./Modules";
import css from "./chapter-inner.module.scss";
import { withLabelsContext } from "context";
import classNames from "classnames";
import styled from "styled-components";

const StyledWrapper = styled.div`
  &&.${css.wrapper_media} {
    border-bottom: 1px solid ${({ theme }) => (theme.primary)};
  }
`

class ChapterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seq: [],
      loading: false,
    };
  }


  handleToggleChapter = (i, exp) => {
    this.setState((s0) => {
      let seq = s0.seq;
      if (s0.seq.includes(i)) {
        seq = seq.filter((se) => se !== i);
      } else {
        seq.push(i);
      }
      return { seq };
    });
  };

  renderChapterItem = (chapter, i,sKey) => {
    console.log(chapter)
    let modules = chapter.modules
    let highlt = false;
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    let assignmentDate = ""
    for(let item in modules){
      if(sKey === modules[item].contentId){
        highlt = true
      }
      if(chapter.id === modules[item].chapterId){
        console.log("---",chapter,modules[item])
        assignmentDate = modules[item].assignmentDate.toLocaleDateString("en-GB", options)
      }
      }
    const isExpand = this.state.seq.includes(i);
    return (
      // <Collapse>
      <StyledWrapper className={classNames((this.props.fromChapterInner  || this.props.subscriptionPreview ||this.props.fromMultiPreview) && css.wrapper_media)}>
        <CardGroup
          key={i}
          title={chapter.name}
          number={i+1}
          total={chapter.modules.length}
          isOpen={false}
          preview={this.props.preview}
          highlt={highlt}
          skey={this.props.selectedKey}
          fromChapterInner={this.props.fromChapterInner}
          fromMultiPreview={this.props.fromMultiPreview}
          labels = {this.props.ctx_labels}
          assignmentDate = {assignmentDate}
          subscriptionPreview={this.props.subscriptionPreview}
        >
          <Modules modules={chapter.modules} chapterIndex={i} fromMultiPreview={this.props.fromMultiPreview} {...this.props} />
        </CardGroup>
        </StyledWrapper>
        // </Collapse>
    );
  };

  render = () => {
    // if (this.props.loading) {
    //   return <Spinner />;
    // }
    return (
      <>
        {this.props.chapterHier.map((chapter, i) =>
          this.renderChapterItem(chapter, i,this.props.selectedKey)
        )}
      </>
    );
  };
}

export default withLabelsContext(ChapterList);
