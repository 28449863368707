// import BreadCrumb from 'components/BreadCrumb';
import { withLabelsContext } from "context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";
import MediaQuery from "react-responsive";
import * as util from "services/util";
import { MainContainer } from "pages/Main";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import * as config from "config";


class Entity extends PureComponent {
  constructor(props) {
    super(props);
    console.log("props",props);
    this.state = this.mkInitState(props);
  }

  static propTypes = {
    Search: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    Form: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    Modal: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    action: PropTypes.string.isRequired,
    isBreadcrumbs: PropTypes.bool,
    addNewPosition: PropTypes.string,
  };

  static defaultProps = {
    addNewPosition: "search",
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.action !== this.props.action && !this.props.isCourse) {
      this.setState({
        ...this.mkInitState(nextProps),
        refreshSearch: nextProps.action === "update",
      });
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (!prevState.resetForm && this.state.resetForm) {
      this.setState({ resetForm: false });
    }
    if (
      prevState.formVisible &&
      !this.state.formVisible &&
      this.props.action === "add" &&
      this.props.updateRedirection
    ) {
      this.props.updateRedirection();
    }
  };

  mkInitState = (props) => ({
    selectedEntity: null,
    formVisible: !props.isCourse ? 
      (props.action === "add" || this.isSingleton(props) || this.alternateUpdate(props))
      : undefined,
    resetForm: false,
    refreshSearch: false,
    isBreadcrumbs: true,
    addNewPosition: "search",
    isModal: false,
    redirectToCourse: false
    });


  isSingleton = (props = this.props) => !props.Search;

  alternateUpdate = (props = this.props) =>
    props.action === "update" && props.alternateEntry ? true : false;

  getNameLabel = (plural = false, capWord = true, props = this.props) => {
    let nameLabel = plural
      ? props.ctx_labels.plural[props.name]
      : props.ctx_labels.singular[props.name];
    nameLabel = nameLabel ? nameLabel : props.name;
    return capWord ? util.capitalizeWord(nameLabel) : nameLabel;
  };

  handleSelect = (selectedEntity) => {
    console.log(selectedEntity === "Modal" || this.props.isTest);
    
    this.setState({
      selectedEntity: selectedEntity,
      refreshSearch: false,
      formVisible: !(selectedEntity === "Modal" || this.props.isTest || this.props.isCourse),
    });
  };

  handleActionDone = (reason) => {
    // Don't update the state if we are going to be unmounted
    if (reason === "entityChanged") {
      return;
    }
    this.setState(
      this.isSingleton()
        ? {
            resetForm: true,
          }
        : {
            refreshSearch: reason === "saved" && this.props.action === "update",
            formVisible: false,
          }
    );
  };

  renderAddNewButton = () => {
    if (this.props.addRedirection && !this.state.formVisible) {
      return (
        <div className="entity-head-right">
          <Button
            className="entity-add-new-button add-btn p-large"
            bsStyle="primary"
            disabled={false}
            onClick={this.props.addRedirection}
          >
            <MediaQuery maxDeviceWidth={576}>

              {/* <Glyphicon glyph="plus" /> */}
            </MediaQuery>
            <MediaQuery minDeviceWidth={577}>
              {`Create ${this.getNameLabel()}`}
            </MediaQuery>
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  handleClickBreadcrumb = (screen_id, screen_props, menu_id, event) => {
    this.setState(
      this.isSingleton()
        ? {
            resetForm: true,
          }
        : {
            refreshSearch: this.props.action === "update",
            formVisible: false,
          }
    );
    this.props.onChangeScreen(screen_id, screen_props, {}, menu_id);
  };

  renderHeader = () => {
    if (this.props.noHeader) {
      return null;
    }
    let entityNameSingular = this.getNameLabel();
    let entityNamePlural = this.getNameLabel(true);
    let actionLabel = "";
    switch (this.props.action) {
      case "update":
        actionLabel = "";
        break;
      case "add":
        actionLabel = "Create";
        break;
      case "assign":
        actionLabel = "Assign";
        entityNameSingular =
          entityNameSingular === "Curriculum" ? "Trainee" : entityNameSingular;
        break;
      default:
        break;
    }
    return (
      <div className="entity-page-header">
        <div className="entity-page-head-left">
          <h2 className="page-title">
            {
              (entityNamePlural =
                actionLabel === "Create"
                  ? entityNameSingular
                  : entityNamePlural)
            }
            {actionLabel && (
              <span className="page-sub-title">
                {actionLabel + " " + entityNameSingular}
              </span>
            )}
          </h2>
        </div>
        <div className="entity-page-head-right">
          {/* <MediaQuery minDeviceWidth={577}>
          <BreadCrumb 
              menu={this.props.menu}
              selectedMenuId={this.props.selectedMenuId}
              entity={this.props.alternateEntry || this.state.selectedEntity}
              formVisible={this.state.formVisible}
              onClickBreadcrumb={this.handleClickBreadcrumb}
              fromClassViewer = {this.props.fromClassViewer || null}        //temp fix remove this
            /> 
          </MediaQuery>      */}
          <MediaQuery maxDeviceWidth={576}>
            {this.props.addNewPosition === "search" &&
              this.renderAddNewButton()}
          </MediaQuery>
          {this.props.addNewPosition === "head-right" &&
            this.renderAddNewButton()}
        </div>
      </div>
    );
  };
  handleModalClose = () => {
      this.setState({ isModal: false , selectedEntity: null, refreshSearch: true})
      this.props.passSelect(false)
  }

  handleModalOpen = (newval) => {
      this.setState({ isModal: newval })
  }

  render = () => {
    const { Search, Form, Modal, hideHeader, isCourse,  ...restProps } = this.props;
    const searchStyle = this.state.formVisible ? { display: "none" } : null;
    console.log(this.state,this.props)
    return (
      <React.Fragment>
        <MainContainer className="entity">
          {/* { hideHeader ? null : this.renderHeader() } */}
          { Search && (
            <Search
              {...restProps}
              style={searchStyle}
              refresh={this.state.refreshSearch}
              onSelect={this.handleSelect}
              className={this.state.isModal && 'blurred'}
              modalOpen={this.handleModalOpen}
            />
          )}

{ this.state.selectedEntity === "Modal" && (
            <Modal
            {...restProps}
              entity={
                this.state.selectedEntity ||
                this.props.alternateEntry ||
                this.props.location.alternateEntry ||
                null
              }
              
              showModal={this.state.isModal}
              onActionDone={this.handleActionDone}
              reset={this.state.resetForm}
              ModalClose={this.handleModalClose}
              
            />
          )}

           {this.state.formVisible &&  (
            <Form
              {...restProps}
              entity={
                this.state.selectedEntity ||
                this.props.alternateEntry ||
                this.props.location.alternateEntry ||
                null
              }
              onActionDone={this.handleActionDone}
              reset={this.state.resetForm}
            />
          )} 
          {console.log(this.state.selectedEntity,this.state.courseId,this.props.isTest, "ddffss")}
          {this.props.isCourse &&(this.state.selectedEntity !== null && this.state.selectedEntity !== "Modal") && (<Redirect
        to={{
          pathname: config.ROUTES.ContentBuilder,
          subjectData: this.state.selectedEntity,
          courseId: this.state.courseId,
          handleRedirect:this.props.alternateRedirection,
          redirectUrl:this.props.redirectionRoute
        }}
      />)}
      {this.props.isTest && (this.state.selectedEntity !== null && this.state.selectedEntity !== "Modal") && (<Redirect
        to={{
          pathname: config.ROUTES.TestCreation,
          subjectData: this.state.selectedEntity,
          testId:  this.state.selectedEntity.testId,
          handleRedirect:this.props.alternateRedirection,
          redirectUrl:this.props.redirectionRoute
        }}
      />)}
        </MainContainer>
      </React.Fragment>
    );
  };
}

export default withLabelsContext(withRouter(Entity));
