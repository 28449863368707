import React from 'react'
import { IconChevronRight, IconChevronLeft} from 'Icons'
import "./TabTails.scss"
import classNames from "classnames";

function TabTails(props) {
    const scrollContainer = document.querySelector(".RRT__tabs");
    let scrollPos=0;
    let start, end , showTails;
      if(props.length){
        showTails = true
      }
      else if(scrollContainer){
        start = Math.floor(scrollContainer.scrollLeft)
        scrollContainer.scrollTo(start+20,0)
        end = Math.floor(scrollContainer.scrollLeft)
        if(start === end){
          showTails = false
        }else{
          showTails=true
        }
        scrollContainer.scrollTo(0,0)
      }
    const handleTest=(dir)=>{
      if (scrollContainer) {
       if(dir==="R")
       {
        scrollPos = Math.floor(scrollContainer.scrollLeft);
        // scrollContainer.scrollTo(scrollPos+100,0)
        scrollContainer.scrollTo({left:scrollPos+100,behavior:'smooth'})
      }
      else if(dir==="L"){
       scrollPos = Math.floor(scrollContainer.scrollLeft);
       scrollContainer.scrollTo({left:scrollPos-100,behavior:'smooth'})
      }
        
      }
    }
  return (
    <>
    {showTails &&<div className={classNames(props.fromZonePage ? "tab_ends_container_zone" : props.isQuestionSelectModal ? "question_ends_container" : props.fromViewerForm ?"tab_ends_container_viewer" : props.fromStudentAssessment ? "tab_ends_container_assessment" : "tab_ends_container")}>
          <div className="start" onClick={()=>handleTest("L")}>
             <IconChevronLeft className="Chev Chev left"/>
          </div>
          <div className="end" onClick={()=>handleTest("R")}>
          <IconChevronRight className="Chev Chev right"/>
          </div>
    </div> 
}
</>
   ) 
}

export default TabTails