import * as config from "config";
import PropTypes from "prop-types";
import React, { Component, PureComponent, useState } from "react";
import ReactDOM from "react-dom";
// import { Document, Page } from 'react-pdf/build/entry.webpack';
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
// import PerfectScrollbar from 'react-perfect-scrollbar';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import * as util from "services/util";
import { FirstPage as FirstPageIcon } from "./icons/FirstPage";
import { Fullscreen as FullScreenIcon } from "./icons/Fullscreen";
import { FullscreenExit as FullScreenExitIcon } from "./icons/FullscreenExit";
import { LastPage as LastPageIcon } from "./icons/LastPage";
import { NextPage as NextPageIcon } from "./icons/NextPage";
import { PreviousPage as PreviousPageIcon } from "./icons/PreviousPage";
import { ZoomIn as ZoomInIcon } from "./icons/ZoomIn";
import { ZoomOut as ZoomOutIcon } from "./icons/ZoomOut";
import labels from "./labels";
import "./Pdf.css";
import Button from "./Pdf/Button";
// import { Button } from 'components';
import NumberInput from "components/NumberInput/NumberInput";
import MediaQuery from "react-responsive";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class PdfPage extends Component {
  static propTypes = {
    pdf: PropTypes.object.isRequired,
    pageNumber: PropTypes.number.isRequired,
    overlay: PropTypes.shape({
      id: PropTypes.string.isRequired,
      elt: PropTypes.element,
    }),
    width: PropTypes.number,
    topMaskPercent: PropTypes.number.isRequired,
    bottomMaskPercent: PropTypes.number.isRequired,
    cropPaddingPercent: PropTypes.number.isRequired,
    modifyPageScrollTop: PropTypes.func.isRequired,
    onLoadSuccess: PropTypes.func,
    onRenderSuccess: PropTypes.func,
    storeRef: PropTypes.func,
  };

  shouldComponentUpdate = (nextProps) =>
    nextProps.pageNumber !== this.props.pageNumber ||
    nextProps.topMaskPercent !== this.props.topMaskPercent ||
    nextProps.bottomMaskPercent !== this.props.bottomMaskPercent ||
    nextProps.overlay !== this.props.overlay ||
    nextProps.width !== this.props.width;

  componentDidUpdate = (prevProps) => {
    if (prevProps.width && this.props.width !== prevProps.width) {
      const factor = this.props.width / prevProps.width;
      if (factor !== 1) {
        this.props.modifyPageScrollTop((t) => factor * t);
      }
    }
  };

  // XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
  // XXX  using same opacity for top and bottom  XXX
  // XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
  render = () =>
    this.props.width !== null && (
      <PerfectScrollbar>
        <Page
          className="pdf-page"
          pdf={this.props.pdf}
          pageNumber={this.props.pageNumber}
          renderTextLayer={false}
          width={this.props.width}
          topMaskPercent={this.props.topMaskPercent}
          bottomMaskPercent={this.props.bottomMaskPercent}
          topMaskOpacity={config.pdfTopMaskOpacity}
          bottomMaskOpacity={config.pdfTopMaskOpacity}
          cropPaddingPercent={this.props.cropPaddingPercent}
          onLoadSuccess={this.props.onLoadSuccess}
          onRenderSuccess={this.props.onRenderSuccess}
          ref={this.props.storeRef}
          renderAnnotationLayer={false}
        >
          {this.props.overlay && this.props.overlay.elt}
        </Page>
      </PerfectScrollbar>
    );
}


class PdfViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initState,
      pdfWidth: null,
      pageNumber: 1,
      numPages: null,
      fullScreen: this.isFullscreenNow(),
    };
  }

  initState = {
    pageNumber: null,
    fracPos: null,
    zoomFactor: 0,
  };

  static propTypes = {
    pdf: PropTypes.shape({
      numPages: PropTypes.number,
    }),
    pos: PropTypes.number,
    minPos: PropTypes.number,
    maxPos: PropTypes.number,
    onPositionSet: PropTypes.func,
  };

  pdfR = null;

  pdfPageR = null;
  pdfPageElement = null;

  // Maintain our own version since an element's scrollTop is not always valid immediately
  // after a fullscreen change; the tradeoff is that we occasionally use an outdated value
  // when we modify it
  pageScrollTop = null;

  componentDidMount = () => {
    // Hack to ensure the containing div is rendered before we set the width
    this.setState({}, this.setPdfWidth);
  };

  componentWillReceiveProps = (newProps) => {
    if (!newProps.pdf && this.props.pdf) {
      this.setState(this.initState);
      this.pdfPageElement = null;
      return;
    }
    if (
      newProps.pdf !== this.props.pdf ||
      (newProps.pos !== null && newProps.pos !== this.props.pos)
    ) {
      this.setPdfPosition(newProps.pos, newProps);
      if (newProps.pdf && this.props.onPositionSet) {
        this.props.onPositionSet();
      }
    }
  };
  // shouldComponentUpdate(nextProps) {
  //   // Compare only the props that should trigger a re-render
  //   return (
  //     nextProps.pdf !== this.props.pdf ||
  //     nextProps.pos !== this.props.pos ||
  //     nextProps.width !== this.props.width ||
  //     nextProps.overlay !== this.props.overlay
  //   );
  // }
  // shouldComponentUpdate = (nextProps, nextState) =>
  //   nextState.pageNumber !== this.state.pageNumber ||
  //   nextState.topMaskPercent !== this.state.topMaskPercent

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.zoomFactor !== prevState.zoomFactor) {
      this.setPdfWidth();
      if (this.state.zoomFactor === 0) {
        // Since the horizontal scrollbar is not visible, we must ensure
        // the left edge of the page is visible (otherwise, it might be
        // partly hidden if the page had been scrolled to the right before
        // we zoomed out)
        const elt = this.pdfPageElement;
        if (elt) {
          elt.scrollLeft = 0;
        }
      }
    }
  };

  setPdfWidth = (props = this.props) => {
    const elt = ReactDOM.findDOMNode(this.pdfR);
    if (elt) {
      const scale = Math.pow(config.playerZoomStep, this.state.zoomFactor);
      const pdfWidth = scale * elt.clientWidth;
      this.setState({ pdfWidth });
    }
  };

  topPosToPage = (pos, props = this.props) => {
    const page = Math.floor(pos) + 1;
    return props.pdf ? Math.min(props.pdf.numPages, page) : page;
  };

  bottomPosToPage = (pos) => Math.ceil(pos);

  topPosToPdfPos = (pos, props) => {
    const pageNumber = this.topPosToPage(pos, props);
    const fracPos = pos - pageNumber + 1;
    return { pageNumber, fracPos };
  };

  setPdfPosition = (pos, props = this.props) => {
    const newPdfPos = this.topPosToPdfPos(pos || 0, props);
    // If the page number isn't changing, we must call onPageLoadSuccess
    // explicitly
    const cb =
      newPdfPos.pageNumber === this.state.pageNumber
        ? this.onPageLoadSuccess
        : null;
    this.setState(newPdfPos, cb);
  };

  setPageNumber = (n) => {
    const topPos = n - 1;
    this.setPdfPosition(topPos);
  };

  onPageLoadSuccess = () => {
    let elt = this.pdfPageElement;
    if (!elt) {
      elt = ReactDOM.findDOMNode(this.pdfPageR);
      this.pdfPageElement = elt;
      elt.onscroll = this.updatePageScrollTop;
    }
    elt.scrollTop =
      Math.floor(this.state.fracPos * elt.scrollHeight) - elt.clientHeight / 2;
  };

  onPageRenderSuccess = () => {
    const elt = this.pdfPageElement;
    if (elt) {
      this.pageScrollTop = elt.scrollTop;
    }
  };

  updatePageScrollTop = () => {
    const elt = this.pdfPageElement;
    if (elt) {
      this.pageScrollTop = elt.scrollTop;
    }
  };

  modifyPageScrollTop = (g) => {
    const elt = this.pdfPageElement;
    if (elt) {
      elt.scrollTop = Math.floor(g(this.pageScrollTop));
    }
  };

  handlePageNumberChange = (n) => () => {
    this.setPageNumber(n);
  };

  handlePageNumberInput = (minPage, maxPage) => (n) => {
    if (minPage <= n && n <= maxPage) {
      this.setPageNumber(n);
    }
  };

  renderPageControls = () => {
    const pageNumber = this.state.pageNumber;
    const numPages = this.props.file ? this.state.numPages : 1;
    const minPage = this.topPosToPage(0);
    const maxPage = numPages;
    const hidden = numPages === 1;
    return hidden ? null : (
      <div className="pdf-page-controls">
        <Button
          iconType={FirstPageIcon}
          disabled={pageNumber <= minPage || this.props.isQuestionForm}
          onClick={this.handlePageNumberChange(minPage)}
          aria-label={labels.firstPage}
          key="pdf-first-pg"
        />
        <Button
          iconType={PreviousPageIcon}
          disabled={pageNumber <= minPage || this.props.isQuestionForm}
          onClick={this.handlePageNumberChange(pageNumber - 1)}
          aria-label={labels.previousPage}
          key="pdf-prev-pg"
        />
        <NumberInput
          className="page-number-input"
          defaultValue={
            !this.props.pos || this.props.pos === 0
              ? pageNumber
              : Math.floor(this.props.pos) + 1
          }
          disabled={maxPage <= minPage}
          onSubmit={this.handlePageNumberInput(minPage, maxPage)}
        />
        <Button
          iconType={NextPageIcon}
          disabled={maxPage <= pageNumber || this.props.isQuestionForm}
          onClick={this.handlePageNumberChange(pageNumber + 1)}
          label={labels.nextPage}
          key="pdf-next-pg"
        />
        <Button
          iconType={LastPageIcon}
          disabled={maxPage <= pageNumber || this.props.isQuestionForm}
          onClick={this.handlePageNumberChange(maxPage)}
          aria-label={labels.lastPage}
          key="pdf-last-pg"
        />
      </div>
    );
  };



  renderPageControlsMob = () => {
    const pageNumber = this.state.pageNumber;
    const numPages = this.props.file ? this.state.numPages : 1;
    const minPage = this.topPosToPage(0);
    const maxPage = numPages;
    const hidden = numPages === 1;
    return hidden ? null : (
      <div className="pdf-page-controls">
        <div
          onClick={this.handlePageNumberChange(minPage)}
          aria-label={labels.firstPage}
          key="pdf-first-pg"
          style={(pageNumber <= minPage || this.props.isQuestionForm)?{ pointerEvents: "none" }:{}}
          >
          <FirstPageIcon height="20px" fill="white"/>
        </div>
        <div
          style={(pageNumber <= minPage || this.props.isQuestionForm)?{ pointerEvents: "none" }:{}}
          onClick={this.handlePageNumberChange(pageNumber - 1)}
          aria-label={labels.previousPage}
          key="pdf-prev-pg"
        >
          <PreviousPageIcon  height="20px" fill="white"/>
        </div>
        <NumberInput
          className="page-number-input"
          defaultValue={
            !this.props.pos || this.props.pos === 0
              ? pageNumber
              : Math.floor(this.props.pos) + 1
          }
          disabled={maxPage <= minPage}
          onSubmit={this.handlePageNumberInput(minPage, maxPage)}
        />
        <div
          onClick={this.handlePageNumberChange(pageNumber + 1)}
          style={(maxPage <= pageNumber || this.props.isQuestionForm)?{ pointerEvents: "none" }:{}}
          label={labels.nextPage}
          key="pdf-next-pg">
          <NextPageIcon  height="20px" fill="white"/>

        </div>
        <div
          style={(maxPage <= pageNumber || this.props.isQuestionForm)?{ pointerEvents: "none" }:{}}
          onClick={this.handlePageNumberChange(maxPage)}
          aria-label={labels.lastPage}
          key="pdf-last-pg">

          <LastPageIcon  height="20px" fill="white"/>

        </div>
      </div>
    );
  };

  changeZoomFactor = (delta) => () => {
    this.setState((s0) => ({ zoomFactor: s0.zoomFactor + delta }));
  };

  isFullscreenNow = () =>
    !!(
      document.fullscreenElement ||
      document.msFullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    );

  toggleFullScreen = (pane) => {
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState((s0) =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }
    setTimeout(() => this.setPdfWidth(), 100);
    this.setState({ fullScreen: !this.state.fullScreen });
  };

  renderFullScreen = (hidden) => {
    const { zoomFactor, fullScreen } = this.state;
    return (
      <div className="pdf-zoom-controls">{/* {(!this.props.fromAssessment && !this.props.fromStudentAssessment)|| (this.props.fromCreateTest || this.props.fromPublishTest)  && */}
        <Button
          iconType={fullScreen ? FullScreenExitIcon : FullScreenIcon}
          disabled={config.maxPlayerZoomFactor <= zoomFactor}
          hidden={this.props.fromPublishTest ? !this.props.fromPublishTest :this.props.fromCreateTest ? !this.props.fromCreateTest : 
            this.props.fromStudentAssessment ? this.props.fromStudentAssessment : this.props.fromAssessment ? this.props.fromAssessment : hidden}
          onClick={this.toggleFullScreen}
          aria-label={labels.zoomIn}
          key="pdf-zoom-in"
        />
      </div>
    );
  };
  renderFullScreenMob = (hidden) => {
    const { zoomFactor, fullScreen } = this.state;
    return (
      <div className="pdf-zoom-controls">
         <div 
          onClick={this.toggleFullScreen}
          aria-label={labels.zoomIn}
          key="pdf-zoom-in"
          hidden={this.props.fromPublishTest ? !this.props.fromPublishTest :this.props.fromCreateTest ? !this.props.fromCreateTest : 
            this.props.fromStudentAssessment ? this.props.fromStudentAssessment : this.props.fromAssessment ? this.props.fromAssessment : hidden}
          style={(config.maxPlayerZoomFactor <= zoomFactor)?{ pointerEvents: "none" }:{}}
          >
          {fullScreen ? <FullScreenExitIcon height="20px" fill="white" /> : <FullScreenIcon height="20px" fill="white" /> }
        </div>
      </div>
    );
  };

  renderZoomControls = () => {
    const { zoomFactor } = this.state;
    return (
      <div className="pdf-zoom-controls">
        <Button
          iconType={ZoomOutIcon}
          disabled={zoomFactor <= config.minPlayerZoomFactor}
          onClick={this.changeZoomFactor(-1)}
          aria-label={labels.zoomOut}
          key="pdf-zoom-out"
        />
        <Button
          iconType={ZoomInIcon}
          disabled={config.maxPlayerZoomFactor <= zoomFactor}
          onClick={this.changeZoomFactor(1)}
          aria-label={labels.zoomIn}
          key="pdf-zoom-in"
        />
      </div>
    );
  };
  renderZoomControlsMob = () => {
    const { zoomFactor } = this.state;
    return (
      <div className="pdf-zoom-controls">
        <div
        style={(zoomFactor <= config.minPlayerZoomFactor)?{ pointerEvents: "none" }:{}}
        onClick={this.changeZoomFactor(-1)}
        aria-label={labels.zoomOut}
        key="pdf-zoom-out"
        >
          <ZoomOutIcon height = "20px" fill="white"/>
          
        </div>
        <div
         style={(config.maxPlayerZoomFactor <= zoomFactor ) ? { pointerEvents: "none" }:{}}
         onClick={this.changeZoomFactor(1)}
         aria-label={labels.zoomIn}
         key="pdf-zoom-in"
         >
          <ZoomInIcon height = "20px" fill="white"/>
         
        </div>
      </div>
    );
  };

  renderControls = () => {
    const screenSize = window.screen.width
    //  <div className={this.state.fullScreen ? '':''} >
    return (
      <>
        <div
          className="player-controls pdf-controls"
          style={
            screenSize < 821 && !this.state.fullScreen && this.state.numPages > 1
              ? { display: "flex", justifyContent: "space-evenly", height: "33px", alignItems: "center" }
              : screenSize < 821 && this.state.fullScreen && this.state.numPages > 1 ? { display: "flex", justifyContent: "space-between", height: "33px", alignItems: "center" }
                : screenSize < 821 && this.state.numPages <= 1 ? { display: "flex", justifyContent: "space-between", height: "33px", alignItems: "center", padding: "10px" }
                  : {}
          }
        >
          <div>
            <MediaQuery minDeviceWidth={821}>
              {this.renderZoomControls()}
            </MediaQuery>
            <MediaQuery maxDeviceWidth={820}>
              {this.renderZoomControlsMob()}
            </MediaQuery>
          </div>
          <div>
            <MediaQuery minDeviceWidth={821}>
              {this.renderPageControls()}
            </MediaQuery>
            <MediaQuery maxDeviceWidth={820}>
              {this.renderPageControlsMob()}
            </MediaQuery>
          </div>
          <div>
            <MediaQuery minDeviceWidth={821}>
              {this.renderFullScreen()}
            </MediaQuery>
            <MediaQuery maxDeviceWidth={820}>
              {this.renderFullScreenMob()}
            </MediaQuery>
          </div>
        </div>
      </>
    );
  };

  handleLoadSuccess = (pdf) => {
    this.setState({ numPages: pdf.numPages });
    if (this.props.onLoaded) {
      const metadata = pdf.getMetadata() || {};
      metadata.numPages = pdf.numPages;
      this.props.onLoaded(metadata);
    }
  };

  convertToPercentage = (value) => {
    //function is used to set the value for masking of incourse quiz
    const percentage = value * 100;
    const roundedPercentage = Math.round(percentage * 10) / 10;
    const parts = value?.toString().split(".");

    if (roundedPercentage >= 100) {
      return parts?.[1] / 10 + "%";
    } else if (roundedPercentage < 100) {
      return roundedPercentage + "%";
    }
  };

  render = () => {
    const { pageNumber, zoomFactor } = this.state;
    let topMaskPercent = 0;
    let bottomMaskPercent = 50;
    let pageActive = false;

    if (this.props.minPos != null && this.props.maxPos != null) {
      const minPage = this.topPosToPage(this.props.minPos);
      const maxPage = this.topPosToPage(this.props.maxPos);
      if (pageNumber === minPage) {
        topMaskPercent = 100 * (1 + this.props.minPos - minPage);
        pageActive = true;
      } else if (pageNumber < minPage || maxPage < pageNumber) {
        topMaskPercent = 100;
      }
      if (pageNumber === maxPage) {
        bottomMaskPercent = 100 * (maxPage - this.props.maxPos);
        pageActive = true;
      }
    }

    const activeClassName = pageActive ? "pdf-page-active" : "";
    const zoomClassName = zoomFactor > 0 ? "pdf-zoomed-in" : "pdf-unzoomed";
    const className = `pdf-new pdf-window ${activeClassName} ${zoomClassName}`;
    const CBClass = 'pdf-new pdf-cb'
    const mask =
      this.props.maxPos === Math.floor(this.props.maxPos)
        ? 0
        : this.convertToPercentage(this.props.maxPos);
    return (
      <div
        className={(this.props.fromCB || this.props.fromModuleForm || this.props.fromCreateTest)? CBClass : className}
        ref={(r) => {
          this.pdfR = r;
        }}
      >
        <div className="pdf-frame">
          <PerfectScrollbar>
          
            <Document
              onContextMenu={(e) => e.preventDefault()}
              className={this.props.className}
              file={this.props.file}
              // renderAnnotationLayer={false}
              // loading="Loading File..."
              onLoadSuccess={this.handleLoadSuccess}
              // onSourceError={this.handleSourceError}
              // onLoadError={this.handleLoadError}
            >
           
              <Page
                pageNumber={
                  !this.props.pos || this.props.pos === 0
                    ? pageNumber
                    : Math.floor(this.props.pos) + 1
                }
                // {pageNumber}
                width={this.state.pdfWidth ? this.state.pdfWidth : 700}
                // scale={}
                onLoadSuccess={this.onPageLoadSuccess}
                onRenderSuccess={this.onPageRenderSuccess}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                // topMaskPercent={topMaskPercent}
                // bottomMaskPercent={bottomMaskPercent}
                // topMaskOpacity={config.pdfTopMaskOpacity}
                // bottomMaskOpacity={config.pdfTopMaskOpacity}
                ref={(r) => {
                  this.pdfPageR = r;
                }}
              >
                {/* {this.props.overlay && this.props.overlay.elt} */}
              </Page>
              
            </Document>
          </PerfectScrollbar>
        </div>
        {this.renderControls()}
      </div>
    );
  };
}
const PdfViewerMemo = React.memo(PdfViewer);
const PdfMaskingDiv = ({ maxPos }) => {
  // const [top,setTop] = useState(null
  const convertToPercentage = (value) => {
    //function is used to set the value for masking of incourse quiz
    const percentage = value * 100;
    const roundedPercentage = Math.round(percentage * 10) / 10;
    const parts = value?.toString().split(".");

    if (roundedPercentage >= 100) {
      return parts?.[1] / 10 + "%";
    } else if (roundedPercentage < 100) {
      return roundedPercentage + "%";
    }
  };
  const topPosition = convertToPercentage(maxPos);
  // setTop(topPosition)
  return (
    <div
      className="masking-div"
      style={{
        'top': `${topPosition}`, // Convert maxPos to a percentage
      }}
    ></div>
  );
};

class Pdf extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }
  static propTypes = {
    className: PropTypes.string,
    pdfData: PropTypes.string,
    pdfUrl: PropTypes.string,
    pos: PropTypes.number,
    minPos: PropTypes.number,
    maxPos: PropTypes.number,
    onLoaded: PropTypes.func,
    onError: PropTypes.func,
    onPositionSet: PropTypes.func,
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.pdfData !== this.props.pdfData ||
      nextProps.pdfUrl !== this.props.pdfUrl
    ) {
      this.setState(this.initState);
    }
  };

  initState = { pdf: null };

  handleSourceError = (error) => {
    if (this.props.onError) {
      this.props.onError({ ...error, message: "Invalid URL" });
    }
  };

  handleLoadError = (error) => {
    if (this.props.onError) {
      this.props.onError({ ...error, message: "Missing PDF file!" });
    }
  };

  makeProxyUrl = (url) => `/proxy/${url}`;
  render = () => {
    const { pdfUrl } = this.props;
    let file = null;
    if (this.props.pdfData != null) {
      file = { data: this.props.pdfData };
    } else if (this.props.pdfUrl != null) {
      const url = pdfUrl
      ? pdfUrl.includes("public") || pdfUrl.includes("blob")
        ? pdfUrl
        : this.makeProxyUrl(pdfUrl)
      : null;

      const httpHeaders = { "x-access-token": util.getAuth() };
      file = { url, httpHeaders };
    }

    return (
      <>
        {this.props.sectionPdf ? (
          this.props.pdfUrl !== undefined && (
            <>
              <PdfViewerMemo
                file={file}
                pos={this.props.pos}
                {...this.props}
              />
              <PdfMaskingDiv maxPos={this.props.maxPos} />
            </>
          )
        ) : (
          <>
            <PdfViewerMemo
              file={file}
              pos={this.props.pos}
              {...this.props}
            />
            <PdfMaskingDiv maxPos={this.props.maxPos} />
          </>
        )}
      </>
    );
    
  };
}

export default Pdf;
