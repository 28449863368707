import { Button } from "components";
import { IconLogout } from "Icons";
import styled from "styled-components";
import { withUserContext, withAccessContext, withWindowContext, withLabelsContext} from "context";
import * as messages from "messages";
import * as util from "services/util";
import * as config from "config";
import Cookies from "js-cookie";

const StyledLogout = styled(Button)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  margin: 0;
  border-radius: 0;

  body[dir="rtl"] & {
    text-align: right;
  }

  svg {
    margin-right: 1rem;
  }
`;

const StyledLogoutIcon = styled(Button)`
  display: block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  margin: 0;
  border-radius: 0;

  body[dir="rtl"] & {
    text-align: center;
  }

  svg {
    margin-right: 1rem;
  }
`;


const callShibbolethLogout = () => {
  const sessionKey = Object.keys(Cookies.get()).find((k) =>
    k.match(/^_shibsession_.*$/)
  );

  const sessionValue = Cookies.get(sessionKey);
  return new Promise((_resolve, _reject) => {
    let sessionHeaders = new Headers();
    sessionHeaders.append("Cookie", `${sessionKey}=${sessionValue}`);

    let sessionOptions = {
      method: "GET",
      headers: sessionHeaders,
      redirect: "follow",
    };
    fetch(
      config.insLinks.logout,
      //"https://in2vate.ilearningengines.com/Shibboleth.sso/Logout?return=https://auth2.in2vate.com/idp/profile/Logout",
      sessionOptions
    ).then((data) => {
      console.log(data);
    });
  });
};

const LogoutButton = (props) => {
  const handleLogoutResponse = (allow) => {
    if (allow) {
      if (props.ctx_user.user.analytics.isAnalyticsTracking) {
        props
          .onLogout()
          .then(() => {
            finalizeLogout();
          })
          .catch((err) => {
            console.log(err);
            finalizeLogout();
          });
      } else {
        finalizeLogout();
      }
    }
  };

  const finalizeLogout = () => {
    props.ctx_access.logOut();
    props.ctx_user.user.loginType === "sso" && callShibbolethLogout();
    props.routerHistory.push(
      props.ctx_user.user.loginType === "sso"
        ? config.insLinks.signout
        : util.getHomeUrl(null)
    );
  };

  const handleLogOut = () => {
    props.ctx_window.simpleConfirm(
      messages.mkLogoutConfirm(
        props.ctx_labels.singular.logoutConfirm
      ),
      handleLogoutResponse
    );
  };
  const StyledLogoutButton = props.fromSidebarIcons ? StyledLogoutIcon : StyledLogout
  return (
    <StyledLogoutButton onClick={handleLogOut}>
      <IconLogout width="16" height="16" />
      {!props.fromSidebarIcons && <span>{props.ctx_labels.singular.logOut}</span>}
      {/* <span>{props.ctx_labels.singular.logOut}</span> */}
    </StyledLogoutButton>
  );
};

export default withUserContext(
  withWindowContext(withAccessContext(util.withRouterHistory(withLabelsContext(LogoutButton))))
);
