import React from 'react';

import './Seek.css';


export default ({
   currTimePercent,
   minSeekPercent,
   maxSeekPercent,
   maxMaxSeekPercent,
   highlightSeekable,
   onChange,
   onMouseDown,
   onMouseUp,
   hidden,
   ariaLabel,
   noValidateFormId,
}) => (
  <div
    className="video-seek"
    style={hidden ? { visibility: 'hidden' } : null}
  >
    {!isNaN(currTimePercent) && (
      <div className="video-seek-track">
        <div
          className="video-seek-max-max"
          style={{ width: `${maxMaxSeekPercent}%` }}
        />
        <div
          className="video-seek-prefill"
          style={{ width: `${minSeekPercent}%` }}
        />
        <div
          className="video-seek-fill"
          style={{
            left:  `${minSeekPercent}%`,
            width: `${currTimePercent - minSeekPercent}%`,
          }}
        />
        {highlightSeekable && (
          <div
            className="video-seek-highlight"
            style={{
              left:  `${minSeekPercent}%`,
              width: `${maxSeekPercent - minSeekPercent}%`,
            }}
          />
        )}
        {!noValidateFormId && (
          <form id="novalidation-form" noValidate />
        )}
        <input
          min={minSeekPercent}
          step={1}
          max={maxSeekPercent}
          type="range"
          required
          form={noValidateFormId || 'novalidation-form'}
          orient="horizontal"
          onChange={onChange}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchStart={onMouseDown}
          onTouchEnd={onMouseUp}
          aria-label={ariaLabel}
          value={currTimePercent}
          disabled={hidden}
          style={{
            left:  `calc(${minSeekPercent}% - 10px)`,
            width: `calc(${maxSeekPercent - minSeekPercent}% + 20px)`,
            padding: 0
          }}
        />
      </div>
    )}
  </div>);
