import classNames from "classnames";
import { IconClose } from "Icons";
import MainContext from "pages/Main/MainContext";
import React from "react";
import styled from "styled-components";
import LogoutButton from "./LogoutButton";
import css from "./scss/sidebar.module.scss";
import Sidenav from "./Sidenav";
import SubjectsMenu from "./SubjectsMenu";
import ShelfMenu from "./ShelfMenu";
import UserProfile from "./UserProfile";
import { withUserContext, withApiContext } from "context";
import ParentSubjectsMenu from "./ParentSubjectsMenu";
import SlideBack from "components/SlideBack/SlideBack";
import BotMenu from "./BotMenu";
import OldCoursesMenu from "./OldCoursesMenu";
const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.color2};
`;

class Sidebar extends React.Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);

    this.toggleShelfMenu = this.toggleShelfMenu.bind(this);
    this.toggleSubjectsMenu = this.toggleSubjectsMenu.bind(this);
    this.toggleBotMenu = this.toggleBotMenu.bind(this);
    this.toggleOldCoursesMenu = this.toggleOldCoursesMenu.bind(this);
    this.state = {
      hasTriggeredSubject:false,
      hasTriggeredOldCourse:false,
      hasTriggeredShelf:false,
      isSubjectsMenuOpen: false,
      subjects: [],
      // oldSubjects:[],
      isViewShelfMenuOpen: false,
      shelf: [],
      completed: false,
      shelfComplete: false,
      showButton: false,
      openParentSubjectsMenu: false,
      isBotMenuOpen: false,
      bot: [],
      bot1:[],
      isOldCoursesMenuOpen: false,
      oldcourses:[],
    };
  }

  componentDidMount = () => {
    // if (this.props.ctx_user.user.type === 1 && this.props.ctx_user.user.impersonator_id) {
    //   this.props.getStudentSubjectList();
    //   this.getShelfList();
    //   this.props.getStudentOldSubjectList()
    // }
    // if(this.props.ctx_user.user.type === 1 && !this.props.ctx_user.user.impersonator_id) {
    //   this.getShelfList();
    // }
    if (this.state.hasTriggeredShelf == false && Object.keys(this.props.shelfData).length !== 0)
      {
        this.setState({ shelf: this.props.shelfData, shelfComplete: true })
      }

    if (this.props.ctx_user.user.type === 4) {
      this.props.getStudentSubjectList();
    }

    // conditionally calling  server ai bot api
    if (this.props.ctx_user.user.type === 6) {
      this.getBotList();
      this.getAiServer();
    }
  };
  // getStudentSubjectList = () => {
  //   return new Promise((resolve, reject) => {
  //     let args = {
  //       memberId:
  //         this.props.ctx_user.user.type === 4
  //           ? this.props.ctx_user.user.dependantId
  //           : this.props.ctx_user.user.id,
  //       acadYearId: this.props.ctx_user.user.acadYearId,
  //       // sidebar: 1,
  //     };
  //     this.props.ctx_api
  //       .privateRequest({
  //         cmd: "get_dashboard_trainee_course_publishings",
  //         args: args,
  //       })
  //       .then(({ rsp }) => {
  //         this.setState({ subjects: rsp, completed: true });
  //         resolve();
  //       })
  //       .catch(() => {
  //         reject();
  //       });
  //   });
  // };

  

  getShelfList = () => {
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_published_shelves",
          args: {},
        })
        .then(({ rsp }) => {
          this.props.handleShelfDataLoader({
          shelf: rsp,
          shelfComplete: true
        })
          this.setState({ shelf: rsp, shelfComplete: true });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

    getAiServer= (accountId,serverId)  => {
      return new Promise((resolve, reject) => {
        this.props.ctx_api
          .privateRequest({
            cmd: "get_aiservers",
            // args: {accountId:55,
            //   serverId:1
            
            // },
          })
          .then(({ rsp }) => {
            this.setState({ bot1 : rsp});
            resolve();
            // console.log(this.state.bot1)
            console.log(rsp);
          })
          .catch(() => {
            reject();
          });
      }
      )
      
    }
    
    
  getBotList = (accountId,serverId) => {
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_aibots",
          args: {
            accountId:55
          },
        })
        .then(({ rsp }) => {
          this.setState({ bot : JSON.parse(rsp)});
          resolve();
          console.log(JSON.parse(rsp));
        })
        
        .catch(() => {
          reject();
        });
    });
  };

  componentDidUpdate(prevProps) {
    // if (prevProps.loadSubjects !== this.props.loadSubjects) {
    //   this.props.getStudentSubjectList();
    //   this.props.getStudentOldSubjectList();
    // }

    if ( this.state.isSubjectsMenuOpen && this.state.hasTriggeredSubject ==false && this.props.apicallrestrictionsubject == false) {
      this.props.handlesubjectmenuclick()
      this.props.getStudentSubjectList();
      this.setState({hasTriggeredSubject:true})
    }
   else if (this.state.isOldCoursesMenuOpen && this.state.hasTriggeredOldCourse ==false && this.props.apicallrestrictionoldcourse == false) {
    this.props.handleoldcoursemenuclick()
    this.props.getStudentOldSubjectList();
    this.setState({ hasTriggeredOldCourse: true });
  }
  else if(this.state.isViewShelfMenuOpen && this.state.hasTriggeredShelf == false && Object.keys(this.props.shelfData).length === 0) {
    this.getShelfList();
    this.setState({ hasTriggeredShelf: true });
  }    
    if (prevProps.isMobileOpen !== this.props.isMobileOpen) {
      // close subject menu if sidebar is closed on mobile
      if (!this.props.isMobileOpen) {
        this.setState({
          isSubjectsMenuOpen: false,
          isViewShelfMenuOpen: false,
          isBotMenuOpen: false,
          isOldCoursesMenuOpen: false,
        });
      }
    }
  }

  toggleSubjectsMenu() {
    if (this.props.ctx_user.user.type === 4) {
      const open = !this.state.openParentSubjectsMenu;
      this.setState({
        openParentSubjectsMenu: open,
      });
      return open;
    } else {
      const open = !this.state.isSubjectsMenuOpen;
      this.setState({
        isSubjectsMenuOpen: open,
      });
      this.props.menuChange(this.state.isSubjectsMenuOpen);
      return open;
    }
  }
  handlehoversubjectenter=(value)=>{
    console.log(value,"subject");
    this.setState({isSubjectsMenuOpen:value})
    this.props.hoversidebarsubject(value)
  }
  handleMouseOldcourseEnter=(value)=>{
    this.props.handleoldcourse(false)
  }
  handleprofileclick1=(value)=>{
   
    this.props.profileclick1(value)
    console.log(value);
  }
  toggleShelfMenu() {
    const open = !this.state.isViewShelfMenuOpen;
    this.setState({
      isViewShelfMenuOpen: open,
    });

    return open;
  }

  toggleBotMenu() {
    const open = !this.state.isBotMenuOpen;
    this.setState({
      isBotMenuOpen: open,
    });

    return open;
  }

  toggleOldCoursesMenu() {
    
      const open = !this.state.isOldCoursesMenuOpen;
      this.setState({
        isOldCoursesMenuOpen: open,
      });
      return open;
  }

  handleSlidebackClick = (open) => {
    this.props.handleSidebarToggle(open);

    this.setState({
      isSubjectsMenuOpen: false,
      isViewShelfMenuOpen: false,
      isBotMenuOpen: false,
      isOldCoursesMenuOpen: false,
    });
  };

  // handleCloseSubjectsMenu = () => {
  // this.setState({ isSubjectsMenuOpen: false})
  // }
  handleSidebarHover=(value)=>{
    this.props.sidebarhover(true)
  }
  handlesidebarhoverLeave=(value)=>{
      this.props.sidebarhover(false)
  }
  handleMouseenter=(value)=>{
    console.log(value,"enter");
    this.props.handleviewshelfEnter(true)
  }
  handleMouseLeave=(value)=>{
    console.log(value,"false");
    this.props.handleviewshelfLeave(false)
  }
  handlehover=()=>{
    console.log("hi");
    this.props.hover(true)
  }
  handleshelfMenu1=()=>{
    this.props.handleshelf1(true)
    const open = !this.state.isViewShelfMenuOpen;
    this.setState({
      isViewShelfMenuOpen: open,
    });

    return open;
  }
  handlehandlebot1=()=>{
    this.props.handlebotmenu(true)
    const open = !this.state.isBotMenuOpen;
    this.setState({
      isBotMenuOpen: open,
    });

    return open;
  }
  handleBotmenuHover=(value)=>{
    this.props.handlebothover(false)
  }
  render() {
    return (
      <StyledWrapper className={css.wrapper}
        onMouseEnter={this.handleSidebarHover}
        onMouseLeave={this.handlesidebarhoverLeave}
      >
        <div className={css.main}>
          {/* user profile */}
          <UserProfile />
          {/* user menu */}
          {!this.props.ctx_user.user.passwordExpiry &&
          <Sidenav
           profileclick1={this.handleprofileclick1}
            onMenuSelect={this.props.onMenuSelect}
            getMenu={this.props.getMenu}
            isSubjectsMenuOpen={this.state.isSubjectsMenuOpen}
            isParentSubjectsMenuOpen={this.state.openParentSubjectsMenu}
            toggleSubjectsMenu={this.toggleSubjectsMenu}
            sidenavData={this.props.sidenavData}
            sidenavLoading={this.props.sidenavLoading}
            isViewShelfMenuOpen={this.state.isViewShelfMenuOpen}
            toggleShelfMenu={this.toggleShelfMenu}
            loader={this.props.showItems}
            isBotMenuOpen={this.state.isBotMenuOpen}
            toggleBotMenu={this.toggleBotMenu}
            isOldCoursesMenuOpen={this.state.isOldCoursesMenuOpen}
            toggleOldCoursesMenu={this.toggleOldCoursesMenu}
            hover={this.handlehover}
          />}
        </div>
        {/* ./main */}

        <div>
          {/* logout link */}
          {this.props.showItems && (
            <LogoutButton onLogout={this.props.onLogout} />
          )}
        </div>

        {/* subject menu */}
        {this.state.isSubjectsMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button
                className="btn btn-link"
                onClick={this.toggleSubjectsMenu}
              >
                <IconClose width="16" height="16" />
              </button>
            </div>

            <SubjectsMenu
              hoversubjectenter={this.handlehoversubjectenter}
              toggleSubjectsMenu={this.toggleSubjectsMenu}
              studentSubjects={this.props.subjects}
              completed={this.state.completed}
              subListCompleted={this.props.subListCompleted}
            />
          </div>
        )}

         {/* old courses menu */}
         {this.state.isOldCoursesMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}>
            <div className="text-right my-2 d-block d-md-none">
              <button
                className="btn btn-link"
                onClick={this.toggleOldCoursesMenu}
              >
                <IconClose width="16" height="16" />
              </button>
            </div>

            <OldCoursesMenu
              toggleOldCoursesMenu={this.toggleOldCoursesMenu}
              studentSubjects={this.props.oldSubjects}
              completed={this.state.completed}
            />
          </div>
        )}

        {/* subject menu */}

        {this.state.openParentSubjectsMenu && (
          <div className={classNames(css.subjects_menu_wrapper)}
          onMouseEnter={this.handleMouseOldcourseEnter}
          >
            <div className="text-right my-2 d-block d-md-none">
              <button
                className="btn btn-link"
                onClick={this.toggleSubjectsMenu}
              >
                <IconClose width="16" height="16" />
              </button>
            </div>
            <ParentSubjectsMenu
              toggleSubjectsMenu={this.toggleSubjectsMenu}
              studentSubjects={this.props.subjects}
              completed={this.state.completed}
            />
          </div>
        )} 
        {this.state.isViewShelfMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}
          onMouseEnter={this.handleMouseenter}
          onMouseLeave={this.handleMouseLeave}
          >
            <div className="text-right my-2 d-block d-md-none">
              <button className="btn btn-link" onClick={this.toggleShelfMenu}>
                <IconClose width="16" height="16" />
              </button>
            </div>

            <ShelfMenu
             handleshelfMenu={this.handleshelfMenu1}
              toggleShelfMenu={this.toggleShelfMenu}
              shelfList={this.state.shelf}
              shelfComplete={this.state.shelfComplete}
            />
          </div>
        )}

        {this.state.isBotMenuOpen && (
          <div className={classNames(css.subjects_menu_wrapper)}
          onMouseEnter={this.handleBotmenuHover}
          >
            <div className="text-right my-2 d-block d-md-none">
              <button className="btn btn-link" onClick={this.toggleBotMenu}>
                <IconClose width="16" height="16" />
              </button>
            </div>

            <BotMenu
               handlebot1={this.handlehandlebot1}
              toggleBotMenu={this.toggleBotMenu}
              botList={this.state.bot}
              bot1={this.state.bot1}
            
            />
          </div>
        )}
       {/* <SlideBack handleSidebarToggle={this.handleSlidebackClick} /> */}
      </StyledWrapper>
      // ./wrapper
    );
  }
}

export default withApiContext(withUserContext(Sidebar));
