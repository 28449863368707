import { Button, JustifyBetween, PageTitle, RadioButton } from "components";
import React from "react";
import { Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";
import { useState } from 'react';
import './TableSummary.css';

const columns = [
  {
    name: "No.:",
    selector: row => row.no,
    sortable: true,
    width: "10%",
  },
  {
    name: "Question",
    selector: row => row.question,
    sortable: true,
    wrap: true,
    width: "25%",
    center: false,
  },
  {
    name: "Answers",
    selector: row => row.answers,
    sortable: true,
    minWidth: "25%x",
    wrap: true,
  },
  {
    name: "Status",
    selector: row => row.status,
    sortable: true,
    width: "20%x",
  },
  {
    name: "Marks",
    selector: row => row.marks,
    sortable: false,
    width: "20%",
  },
];

const AnswerOptions = (props) => {
  return (
    <div className="pt-1">
      {props.data.map((value, index) => (
        <div className="mb-2" key={index}>
          {/* FIXME: is it should be readonly ? */}
          <RadioButton
            name={props.id}
            className={value.isAnswer ? "fw-500" : ""}
            checked={value.isAnswer}
            readOnly={true}
          >
            {value.text}
            {value.isCorrect && (
              <span className="d-inline-block text-success ml-1 mr-0 fw-600">
                &nbsp;Correct Answer
              </span>
            )}
            {value.isWrong && (
              <span className="d-inline-block text-danger ml-1 mr-0 fw-600">
                &nbsp;Wrong Answer
              </span>
            )}
          </RadioButton>
        </div>
      ))}
    </div>
  );
};


export default function TableSummary(props) {

  // console.log("props",props)
  const validateData = props.contents ? props.contents.find(s => s.evaluated == 0) : ""
  // console.log("validaaa",validateData);

  const [show, setShow] = useState(false);
  const handleClick = () => {

    setShow(!show);
    // console.log(!show)
  };
  // const data=props.contents
  const theme = useTheme();
  const tableStyles = getTableStyles(theme);
  tableStyles.cells.style.alignItems = "flex-start";
  return (
    <div style={{ paddingLeft: "10px", fontSize: "18px" }}>
      <JustifyBetween breakpoint="sm">
        <div style={{ paddingLeft: "20px", fontSize: "18px" }} sub>Summary</div>
      </JustifyBetween>


      {
        !validateData &&
        <div style={{ width: "97%", marginLeft: "1%" }}>
          <DataTable
            noHeader={true}
            columns={columns}
            data={props.contents}
            customStyles={tableStyles}
            pagination
            striped
            defaultSortField="no"
            style={{ position: "absolute" }}
          />
        </div>

      }
      {validateData &&
        <div style={{ alignItems: "center", justifyContent: "center", paddingLeft: "20px" }}>
          <p >
            Summary cannot be displayed as evaluation is pending
          </p>
        </div>}

    </div>
  );
}
