import React from "react";
// import "./InfoCard.css";

import classNames from "classnames";
import css from "../StudentAssessments/Section/section.module.scss";


const InfoCard = (props) => {
  const { color, caption, other, solid, bodyColor } = props;

  const colorStyle3 = { borderTop: "0px solid",padding:"10px" + color };
  const colorStyle = {
    borderBottom: "0px solid ",paddingTop:"10px",paddingLeft:"10px",backgroundColor:"#f1f1f1" + color,
  };

  const colorStyle1 = {
    color: "white",
    backgroundColor: color,
    borderRadius: "5px 5px 0px 0px",
  };

  const bodyStyle = {
    backgroundColor: bodyColor,
  };

  return (
    <div
      className="card"
      style={(!solid ? colorStyle3:null)}
    >
      <div style={solid ? colorStyle1 : colorStyle}>
        <h3 className={`${css.title} text-color2`}>{caption}</h3>

        <div className="card-tools">{other ? other() : null}</div>
      </div>

      <div className="card-body" style={bodyStyle}>
        {props.children}
      </div>
    </div>
  );
};

export default InfoCard;
