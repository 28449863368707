import * as config from 'config';
import { withUserContext } from 'context';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';




const PrivateRoute = ({
  component,
  isLoading,
  ctx_user,
  ...rest
}) => (
  <Route {...rest} render={props =>
    ctx_user.user ?  React.createElement(component, props, isLoading) : (
      <Redirect to={{
        pathname: config.loginUrl,
        state:    { from: props.location },
      }} />
    )
  } />
);


export default
withUserContext(
  PrivateRoute
)
