import React, { Component } from "react";
import CardSingle from "./CardSingle/CardSingle";
import PropTypes from "prop-types";
import * as config from "config";
import { withWindowContext } from "context";
import styled from "styled-components";
import cmCss from "./CourseMedia/course-media.module.scss";


const StyledWrapper = styled.div`
  &&.${cmCss.card_module} {
    border-bottom: 1px solid ${({ theme }) => (theme.primary)};
  }
`
class Modules extends Component {
  static propTypes = {
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string,
        summary: PropTypes.string,
        url: PropTypes.string,
        type: PropTypes.string.isRequired,
        contentId: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        contentLength: PropTypes.number.isRequired,
        lastViewedPos: PropTypes.number.isRequired,
        maxViewedPos: PropTypes.number.isRequired,
        assignmentDate: PropTypes.instanceOf(Date).isRequired,
        completionDate: PropTypes.instanceOf(Date),
        nonAcademic: PropTypes.bool.isRequired,
      })
    ),
    selectedKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    currentKey: PropTypes.string,
    onPlay: PropTypes.func.isRequired,
    noMaxHeight: PropTypes.bool.isRequired,
    preview: PropTypes.bool,
    chapterIndex:PropTypes.number,
    // cardIndex:PropTypes.number,
  };

  onSelectModule = (m) => (command) => (e) => {
    this.props.preview && this.props.onSelect(m);
    const status = m.status;
    if (status !== config.contentStatusE.UNAVAILABLE) {
      this.props.onPlay(m)(command)(e);
    }
    if (m.type === "html" && m.playExt) {
      this.props.ctx_window.confirm({
        message:
          "This action will open the requested page in a new tab. Would you like to continue?",
        yesText: "Proceed",
        noText: "Cancel",
        callback: this.handleNewTab(m),
      });
    }
  };

  handleNewTab = (m) => (ok) => {
    this.props.onExtPlayConfirm(ok);
    if (ok) {
      var win = window.open(m.fileUrl ? m.fileUrl : config.ileUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
      }
    }
  };

  render = () => {
    const { selectedKey, playerModule, endDate } = this.props;
    const index =this.props.chapterIndex?this.props.chapterIndex:0
    let allModuleActive
    if((this.props.viewType=="L" || this.props.viewType=="List") && this.props.playInOrder === 1){
       allModuleActive = this.props.chapterHier && this.props.chapterHier.slice(0,index).flatMap((item)=>{
        return item.modules.flatMap((module)=>{
          return (
            module.status === config.contentStatusE.COMPLETED ||
            module.status === config.contentStatusE.REVIEWING ||
            module.status === config.contentStatusE.IN_PROGRESS
            )
          })
        })
      }
    const today = new Date();
    let nextActive = this.props.chapterIndex == 0
    ? true:allModuleActive && allModuleActive.includes(false)
    ? false
    : true;
    let lastAcadMod = null;
    return this.props.modules.map((m, i) => {
      if (!m.nonAcademic) {
        lastAcadMod = m.status;
      }
      const isCurrent = m.contentId === selectedKey;
      const consumed = this.props.viewType=="C" ?
       this.props.data[0].course_chapter_details[0].l_is_consumed :true
      let moduleActive =
          endDate < today
          ? false
          : m && m.nonAcademic && consumed
          ? true
          : !consumed ? false
          : m.assignmentDate >= today
          ? false
          : i==0 && this.props.chapterIndex== 0
          ? true
          : nextActive
          ? true
          : m.status === config.contentStatusE.COMPLETED ||
            m.status === config.contentStatusE.REVIEWING ||
            m.status === config.contentStatusE.IN_PROGRESS
          ? true
          : false;

      if(endDate < today){
        nextActive = false
      }
      else if (this.props.playInOrder === 1) {
        if (m.nonAcademic) {
          if (lastAcadMod === null) {
            // nextActive = true;
          } else {
            nextActive =
              lastAcadMod === config.contentStatusE.COMPLETED ||
              lastAcadMod === config.contentStatusE.REVIEWING
                ? true
                : false;
          }
        } else {
          nextActive =
            m.status === config.contentStatusE.COMPLETED ||
            m.status === config.contentStatusE.REVIEWING
              ? true
              : false;
        }
      }
      const command =
        m.status === config.contentStatusE.UNAVAILABLE
          ? "non"
          : m.status === config.contentStatusE.COMPLETED
          ? "restart"
          : m.status === config.contentStatusE.REVIEWING
          ? "resume"
          : m.status === config.contentStatusE.IN_PROGRESS
          ? "resume"
          : "play";

      if(this.props.previewMulti && this.props.chapterIndex !== 0 ){
        moduleActive = false;
      }
      
      return (
       <StyledWrapper className={(this.props.viewType === "C" || this.props.viewType === "Card")&& cmCss.card_module}>       
        <CardSingle
          preview={this.props.preview}
          subscriptionPreview={this.props.subscriptionPreview}
          isCurrent={isCurrent}
          onClick={(e) =>
            moduleActive ? this.onSelectModule(m)(command)(e) : null
          }
          key={m.contentId}
          moduleActive={moduleActive}
          {...m}
          fromChapterInner={this.props.fromChapterInner}
          viewType={this.props.viewType}
          fromMultiPreview={this.props.fromMultiPreview}
        />
         </StyledWrapper>
      );
    });
  };
}

export default withWindowContext(Modules);
