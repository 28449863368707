import React, { PureComponent } from "react";
import "./Test.css";
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import styled from "styled-components";

const StyledPlayerMessage = styled.div`
  && {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
  }
`;
class Test extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePlayerChange = () => {
    const { onModuleProgress, onAllViewed, module } = this.props;
    // onModuleProgress({
    //   lastViewedPos: module.contentLength,
    //   maxViewedPos:  module.contentLength,
    // });
    onAllViewed(true);
  };

  setSelectRef = (r) => {
    this.selectRef = r;
  };
  renderControls = () => (
    <div className={`${cmCss.toolbar} bg-color2 text-white`}>
      <div className={cmCss.toolbar_left}></div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );
  renderControlsMob = () => (
    <div className="player-controls pdf-controls">
      {this.props.baseControls}
    </div>);
  render = () => {
    const {
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;

    const {
      preview,
      testView,
      module,
      lastViewedPos,
      minPos,
      maxPos,
      video,
      overlay,
      message,
      // className,
      isFullscreen,
      baseControls,
      onPlayPauseClick,
      ...restProps
    } = this.props;

    const startSecs = Math.floor(Math.max(0, lastViewedPos));
    // const active = minPos < maxPos;
    const { Form, publishId } = this.props;
    const fsClassName =
    this.props.isFullscreen ? 'assessment-fullscreen' : 'assessment-window';
  const className = `assessment ${fsClassName}`;
  const screenSize = window.innerWidth<768? false:true;
    return this.props.preview ? (
      <StyledPlayerMessage className="player-module-message player-Preview-test">
        Preview not avalible for Tests
      </StyledPlayerMessage>
    ) : (
      <>
    <div 
    className={className}
        >
        
        <div >
          <Form
            publishId={publishId}
            entityName="player"
            testView={testView}
            onAllViewed={this.props.onAllViewed}
            onBackToCourse={this.handlePlayerChange}
            playerState={this.props.playerState}

          />
          {message && <div className="player-overlay">{message}</div>}
        </div>
        {/* <div className="video-controls-hover-region">
          <div
            className="player-controls video-controls"
            style={{ float: "right" }}>
            {baseControls}
          </div>
        </div> */}
        
      </div>
      <div>
      {screenSize ? this.renderControls() : this.renderControlsMob()}
      </div>
      </>

      
    );

    
  };
}

export default Test;
