import React, { useContext, useEffect, useState } from "react";

const TestContext = React.createContext();

function TestContexProvider(props) {

  const courseData = {
    identifier: "",
    name: "",
    description: "",
    curriculum_id: "",
    category_id: "",
    credit: "",
    status: "",
    subject: "",
    standard: "",
    questions: [],
    course_id: "",
    aptitude_test: "",
  };

  const testModal = {
    name: "",
    short: "",
    curriculumId: "",
    categoryId: "",
    description: "",
    courseTag: [],
    totmrk: 0,
    questionType: {
      mcq: false,
      shortanswer: false,
      fillintheblank: false,
    },
    action: "",
    testId: "0",
  };
  const aiTestModal = {
    mcq: { noOfQuestions: 0, mark: 0 },
    shortanswer: { noOfQuestions: 0, mark: 0 },
    fillintheblanks: { noOfQuestions: 0, mark: 0 },
  };

  const inItfeedbacks = {
    max: 0,
    min: 0,
    feedbackdescription: "",
    feedbackcourseTag: "",
  };

  const inItQuestions = {
    id: 0,
    question: "",
    points: "0",
    order: null,
    negative_points: "0",
    question_content: "",
    content: "",
    content_source: "",
    content_format: null,
    math_symbol: 0,
    type: 0,
    section_order: 1,
    section_name: "",
    difficulty_level: "",
    is_record: 0,
    assignment_content: "",
    asgnmt_content_format: null,
    asgnmt_content_source: "",
    std_assignment_type: "",
    shortCodes: "",
    solution: "",
    media_link: "",
    answer: [],
    negativeCreditPercent: 0,
    origImage:"",

  };

  const inItSection = [
    {
      section_order: 1,
      section_name: "Section 1",
      content_format: null,
      content: null,
      is_av: 0,
      content_source: null,
      description: "",
      file_name: null,
      is_limit: 0,
      max_questions: "",
      media_link: null,
      questions: [],
      feedBacks: [],
      condition: true,
      origImage:"",
      is_concluding_analysis: 0,
      analysis_description: ""
    },
  ];

  const [question, setQuestion] = useState(inItQuestions);
  const [sections, setSections] = useState(inItSection);
  const [resData, setResData] = useState(inItSection);
  const [aiTestData, setAiTestData] = useState(aiTestModal);
  const [testData, setTestData] = useState(testModal);
  const [testFeedback, setTestFeedback] = useState(inItfeedbacks);
  const [updateEnable, setUpdateEnable] = useState(false);
  const [feedBackEdit, setFeedBackEdit] = useState({
    isEdit: false,
    index: "0",
  });
  const [sectionVisible, setSectionVisible ] = useState(true);

  const restQuestion = () => {
    setQuestion(inItQuestions);
  };
  useEffect(() => {
    const setTotalMark = () => {
      let totalMark = 0;
      for (let questionType in aiTestData) {
        let { noOfQuestions, mark } = aiTestData[questionType];
        totalMark += noOfQuestions * mark;
      }
      setTestData((prev) => {
        return {
          ...prev,
          totmrk: totalMark,
        };
      });
      return totalMark;
    };

    setTotalMark();
  }, [aiTestData]);

  const responseSetter = (data, subjectId, standardId) => {
    setTestData((prev) => {
      return {
        ...prev,
        name: data.name,
        short: data.identifier,
        subject: data.curriculum_id,
        standard: data.category_id,
        description: data.description,
        courseTag: data.course_id === null ? [] : data.course_id,
        totmrk: data.credit,
        categoryId: data.category_id,
        curriculumId: data.curriculum_id,
        aptitudeTest: data.aptitude_test,
        action: "update"

        // questions: questionResMaker(data.questions)
      };
    });
  };

  const sectionSetter = (data) => {
    const modifiedSections = data.questions[0].map((section, index) => {
      const { section_feedback,media_link, ...rest } = section;
      return {
        ...rest,
        feedBacks: section_feedback || [], // Transfer data from section_feedback to feedBacks
        condition: index === 0 ? true : false,
        origImage: media_link,
        media_link:media_link,
        questions: section.questions.map((question) => ({
          ...question,
          content_source: question.content_source || question.asgnmt_content_source,
          content_format: question.content_format ? question.content_format.toString() : question.content_format,
          media: question.content_format ? 1 : 0,
          negative_points: question.negative_points?.trim() === ".00" ? 0 : question.negative_points,
          answer: [],
        })),
      };
    });

    // Assuming setSections is a function to update the state
    setSections(modifiedSections);
  };


  const resSectionSetter = (data, id) => {
    const testDetails = {
      name: data.name,
      short: data.identifier,
      subject: data.curriculum_id,
      standard: data.category_id,
      description: data.description,
      courseTag: data.course_id,
      totmrk: data.credit,
      categoryId: data.category_id,
      curriculumId: data.curriculum_id,
      aptitudeTest: data.aptitude_test,
    }

    const modifiedSections = data.questions[0].map((section, index) => {
      const { section_feedback, media_link,...rest } = section;
      return {
        ...rest,
        feedBacks: section_feedback || [], // Transfer data from section_feedback to feedBacks
        condition: index === 0 ? true : false,
        origImage: media_link,
        media_link:media_link,
        questions: section.questions.map((question) => ({
          ...question,
          content_source: question.content_source || question.asgnmt_content_source,
          content_format: question.content_format ? question.content_format.toString() : question.content_format,
          media: question.content_format ? 1 : 0,
          negative_points: question.negative_points?.trim() === ".00" ? 0 : question.negative_points,
          answer: [],
        })),
      };
    });

    const newState = transformSection(modifiedSections);
    const updatedSection = newState.sections.filter((r) => r.questions.length > 0)
    const jsonData = {
      categoryId: testDetails.categoryId,
      courseTag: testDetails.courseTag,
      curriculumId: testDetails.curriculumId,
      description: testDetails.description,
      identifier: testDetails.short,
      isActive: 1,
      name: testDetails.name,
      questionIds: JSON.stringify(updatedSection),
      testId: id,
      totalMark: parseFloat(testDetails.totmrk)
    }
    const stringifiedDta = JSON.stringify(jsonData)
    setResData(stringifiedDta);
  };



  const resetTestData = () => {
    setTestData(testModal);
  };

  const resetAiTestData = () => {
    setAiTestData(aiTestData);
  };

  const restructureAssignmentQuestion = (question) => {
    const newStructure = {
      question_id: question.id,
      type: question.type,
      question_name: question.question,
      score: question.points,
      negative_score: question.negative_points,
      order: question.order,
      question_content: question.question_content,
      std_assignment_type: question.std_assignment_type,
      assignment_content: question.content,
      asgnmt_content_format: question.content_format,
      asgnmt_content_source: question.content_source,
      short_code: question.shortCodes,
      tags: [],
      solution: question.solution
    };
    return newStructure;
  };

  const restructureSection = (section) => {
    const newStructure = {
      section_order: section.section_order,
      section_name: section.section_name,
      description: section.description,
      is_limit: section.is_limit,
      is_av: section.is_av,
      content: section.content,
      content_format: section.content_format === "" ? null : section.content_format,
      content_source: section.content_source,
      file_name: section.file_name,
      max_questions: section.is_limit !== 0 ? section.max_questions : section.questions.length,
      section_feedback:section.feedBacks,
      is_concluding_analysis:section.is_concluding_analysis,
      analysis_description:section.analysis_description,
      origImage:section.origImage
    };
    return newStructure;
  };

  const restructureQuestion = (question) => {
    const newStructure = {
      question_id: question.id,
      type: question.type,
      question_name: question.question,
      score: question.points,
      negative_score: parseFloat(question.negative_points),
      order: question.order,
      question_content: question.question_content,
      assignment_content: question.content,
      asgnmt_content_format: question.content_format,
      short_code: question.shortCodes,
      tags: [],
      solution: question.solution,
      origImage:question.origImage
    };
    return newStructure;
  };
  const transformSection = (state) => {
    return {
      sections: state.map(section => ({
        ...restructureSection(section),
        questions: section.questions.map(question => {
          if (question.type === 4) {
            return restructureAssignmentQuestion(question);
          } else {
            return restructureQuestion(question);
          }
        }),
      })),
    };
  };

  const enableUpload = (data) => {
    const newState = transformSection(data);
    const updatedSection = newState.sections.filter((r) => r.questions.length > 0)
    const jsonData = {
      categoryId: testData.categoryId,
      courseTag: testData.courseTag,
      curriculumId: testData.curriculumId,
      description: testData.description,
      identifier: testData.short,
      isActive: 1,
      name: testData.name,
      questionIds: JSON.stringify(updatedSection),
      testId: testData.testId,
      totalMark: parseFloat(testData.totmrk)
    }
    const stringifiedData = JSON.stringify(jsonData);
    if(resData && stringifiedData === resData){   
      setUpdateEnable(false)
    }
    else {
      setUpdateEnable(true)
    }
  }

  const reFactorData = async (data, ctx_api, isContentBuilder) => {
    const newState = transformSection(data);
    const updatedSection = newState.sections.filter((r) => r.questions.length > 0);

    try {
      const { rsp } = await ctx_api.privateRequest({
        cmd: "insert_update_test",
        args: {
          categoryId: testData.categoryId,
          courseTag: testData.courseTag,
          curriculumId: testData.curriculumId,
          description: testData.description,
          identifier: testData.short,
          isActive: 1,
          name: testData.name,
          questionIds: JSON.stringify(updatedSection),
          testId: testData.testId,
          totalMark: parseFloat(testData.totmrk),
        },
      });

      if (rsp[0]?.test_id) {
        const jsonData = {
          categoryId: testData.categoryId,
          courseTag: testData.courseTag,
          curriculumId: testData.curriculumId,
          description: testData.description,
          identifier: testData.short,
          isActive: 1,
          name: testData.name,
          questionIds: JSON.stringify(updatedSection),
          testId: testData.testId,
          totalMark: parseFloat(testData.totmrk),
        };

        const stringifiedData = JSON.stringify(jsonData);
        setResData(stringifiedData);
        setUpdateEnable(false);
        setTestData((prev) => ({
          ...prev,
          testId: rsp[0].test_id,
        }));
      }
      if (!isContentBuilder) {
        // Call insert_audit_trail API after insert_update_test succeeds
        await ctx_api.privateRequestRow({
          cmd: "insert_audit_trail",
          args: {
            moduleId: "1",
            memberId: "1",
            auditDate: new Date(),
            auditAction: testData.testId === "0" ? "add" : "update",
            auditRemarks: "test",
            auditDetails: "1",
            createdOn: new Date(),
            createdBy: "1",
          },
        });
      } 
      return rsp;
    } catch (e) {
      console.error("Issue with test updation:", e);
    }
  };


  const values = {
    testData,
    setTestData,
    aiTestData,
    setAiTestData,
    resetTestData,
    resetAiTestData,
    testModal,
    testFeedback,
    setTestFeedback,
    question,
    setQuestion,
    sections,
    setSections,
    responseSetter,
    sectionSetter,
    restQuestion,
    inItQuestions,
    feedBackEdit,
    setFeedBackEdit,
    reFactorData,
    resData,
    resSectionSetter,
    enableUpload,
    updateEnable,
    sectionVisible,
    setSectionVisible
  };

  return (
    <TestContext.Provider value={values}>{props.children}</TestContext.Provider>
  );
}

const withTestContext = (Component) => (props) =>
(
  <TestContexProvider>
    <Component {...props} />
  </TestContexProvider>
);

export { TestContexProvider, TestContext, withTestContext };
