
// extend progress bar of bootstrap
import React from "react";
import { ProgressBar as BsProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import { IconCircleCheckGreen, IconCircleProgress } from "Icons";
import css from "./progress-bar.module.scss";
import classNames from "classnames";
import {withLabelsContext} from "context";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

 function ProgressBar({
  progress,
  showLabel,
  label,
  labelPosition,
  children,
  small,
  fromChapterInner,
  fromSmallCard,
  ...props 
 }) {
  return (
    <>
    {fromChapterInner || fromSmallCard?
    <div
    className={classNames(
      css.wrapper,
      labelPosition === "top" && css.label_top,
      small && css.sm
    )}
  > 
   {progress < 100 &&(
  <div style={{ width: '30px' }}>
   <CircularProgressbar value={progress} text={`${progress == null ? 0 : progress}%`} strokeWidth={5} styles={{ text: { fontSize: '35px', fill: "black"} }}/></div>
   )}
    {showLabel && (
      <div style={{borderRadius:"50%", backgroundColor:"#fff", borderColor:"#06B128"}}>
    {progress >= 100 && (
              <IconCircleProgress width="25" height="25" className= {window.screen.width ? css.progress : "mr-1 ml-0"} />
            )}
      </div>
    )}
    {children}
  </div>
     :
     <div
      className={classNames(
        css.wrapper,
        labelPosition === "top" && css.label_top,
        small && css.sm
      )}
    >
      <BsProgressBar now={progress}  {...props} />
      {showLabel && (
        <div className={css.label_wrapper}>
          <div className={css.label}>{label}</div>
          <div className={css.label}> 
            {progress >= 100 && (
              <IconCircleCheckGreen width="14" height="14" className="mr-1 ml-0" />
            )}
            {progress == null ? 0 : progress}% {props.ctx_labels.singular.completed}
          </div>
        </div>
      )}
      {children}
    </div>
    }
    </>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  showLabel: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["top", "bottom"]),
  small: PropTypes.bool
};

ProgressBar.defaultProps = {
  progress: 0,
  showLabel: true,
  labelPosition: "bottom",
  small: false
};


export default withLabelsContext(ProgressBar)