import React, { Component } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { withUserContext, withApiContext } from "context";
import "./Recorder.css";
import { Button } from "components";
import {Col} from "react-bootstrap";
// import { Next } from "react-bootstrap/esm/PageItem";


const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      isRecordingStp: false,
      isUpload: false
    };

    this.start = this.start.bind(this);

    this.stop = this.stop.bind(this);

    this.reset = this.reset.bind(this);
  }
  
  componentDidUpdate = (_prevProps, prevState) => {
    if(_prevProps.fileValue !== this.props.fileValue) {
      this.setState({isUpload: false})
    }
    
    
  };

  componentDidMount() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.getUserMedia(
      { audio: true },

      () => {
        console.log("Permission Granted");

        this.setState({ isBlocked: false });
      },

      () => {
        console.log("Permission Denied");

        this.setState({ isBlocked: true });
      }
    );
  }

  start() {
    if (this.state.isBlocked) {
      alert("Permission Denied");
    } else {
      Mp3Recorder.start()

        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch((e) => console.error(e));
    }
  }

  stop() {
    Mp3Recorder.stop()

      .getMp3()

      .then(([buffer, blob]) => {
        const file = new File(buffer, "newmp3.mp3", {
          type: blob.type,
          lastModified: Date.now()
        })
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blobURL, isRecording: false });
        this.setState({ isRecordingStp: true, isUpload: false });
        this.props.onRecordingStop(file, blobURL);

      })
      .catch((e) => console.log(e));
  }

  reset() {
    // document.getElementsByTagName("audio")[0].src = "";

    this.setState({ isRecordingStp: false, blobURL: "" });
    this.props.onReset();
  }

  handleUpload = () => {
    this.props.uploadingFile()
    this.setState({isUpload: true});
  }

  renderAudio = (blob) => {
    if (this.props.isNext && this.state.blobURL) {
      this.setState({ blobURL: "" });
    }
    return (
      <audio
      className="audbtn"
        src={this.props.value}
        controls="controls"
        mediaId="recorder-audio"
      />
    );
  };

  render() {
    const disable = !this.props.value || this.props.fileName || this.props.disabled
    // const disable = this.props.fileName || !this.state.blobURL || (!this.props.value && !this.props.fileName);
    return (
      <div > 
        <Button
          className="btnstart"
          variant="success"
          size="sm"
          onClick={this.start}
          disabled={this.state.isRecording || this.props.value || this.props.ctx_user.user.type === 2 || this.props.publishPreview ===1|| this.props.disabled}
        >
          Record
        </Button>

        <Button
          className="btnstop"
          variant="danger"
          size="sm"
          onClick={this.stop}
          disabled={!this.state.isRecording || this.props.disabled}
        >
          Stop
        </Button>

        <Button
            className="btnwarning"
            variant="success"
            // style={{marginLeft: "15px"}}
            size="sm"
          onClick={this.reset}
          disabled={!this.props.value || this.props.disabled}
        >
          Reset
        </Button>
      
        
        {this.renderAudio()}

        <Button
            className="btnupload"
            variant="danger"
          onClick={this.handleUpload}
          disabled={disable}
        >
          Upload
        </Button>
        {/* {this.props.blobUrl(this.state.blobURL)} */}
      </div>
    );
  }
}
export default withUserContext(withApiContext(Recorder));
