import React, { useState } from "react";
import css from "../../pages/StudentAssessments/Question/question.module.scss";
import { Col, Row } from "react-bootstrap";
import Select from "components/Select/Select";
import './MultipleChoiceDropdown.css'
import { useEffect } from "react";
import FormField from "../../components/FormField";
// import './MultipleChoiceDropDown.css'
import { InputField, Textarea } from "components";
const MultipleChoiceScale = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [comment, setComment] = useState("")

  useEffect(() => {
    props.survey &&
      props.answers &&
      props.displayType === 1 &&
      setSelectedOption(props.answers[0]);
      if(props.response&& props.userSelection )
      {setComment(props.userSelection.comment);
      setSelectedOption(props.userSelection.selected_id)}
      
  }, [props.answers]);

  const handleUserSelection = (option, updatedComment) => {
    if (option) {
      const updatedSurveyDetails = {
        ...props.surveyDetails,
        questions: props.surveyDetails.questions.map(sec => {
          if (sec.question_order === props.questionOrder) {
            return {
              ...sec,
              user_selection: 
                { selected_id: option, comment: updatedComment }
              
            };
          }
          return sec;
        })
      };
      props.onAnswered(updatedSurveyDetails);
      setSelectedOption(option);
      setComment(updatedComment)
  };
}

  const options = props.choices.map((r) => {
    return { value: r.choice_id.toString(), label: r.choice };
  });

  const selctedValue = selectedOption ?? props.answers[0];

  const renderDropdown = () => {
    return (
      <>
        <Row>
          <Col md={6} style={{padding:"0px 0px 16px 0px"}}>
            <Col md={8} lg={6}>
            <Select
              name="selectedOption"
              value={selctedValue}
              onChange={(option) => handleUserSelection(option, comment)}
              options={options}
              placeholder={"Select Options"}
              required
            />
            </Col>
          </Col>
          {props.from !== "preview" && 
          <Col md={6}>
          <div style={props.isAttended ? {pointerEvents:"auto"} : null }>
          <FormField
            type={Textarea}
            name="comment"
            onChange={(e) => handleUserSelection(selectedOption, e.value)}
            value={comment}
            maxLength={180}
            style={{ marginBottom : "-25px"}}
            placeholder="Why did you choose this option...?"
          />
          </div>
          <div className="test-comment-box-footer">
            <span className="footer-note">
              {`(maximum of 180 characters)`}
            </span>
          </div>
          </Col>}
        </Row>
      </>
    );
  };

  return (
    <div>
      {/* <div className={`${css.title} text-color4`}>Survey</div> */}
      <div className="test-question">{props.questionOrder}. {props.question}</div>
      <div>{renderDropdown()}</div>
    </div>
  );
};

export default MultipleChoiceScale;
