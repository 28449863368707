import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


class NumberInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: null };
  }

  static propTypes = {
    defaultValue: PropTypes.number,
    onSubmit:     PropTypes.func.isRequired,
  }

  handleKeyPress = (e) => {
    if (e.which === 13) {
      this.handleBlur();
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }

  handleBlur = () => {
    this.props.onSubmit(parseInt(this.state.value, 10));
    this.setState({ value: null });
  }

  render = () => {
    const { defaultValue, onSubmit, ...restProps } = this.props;
    let value = this.state.value;
    if (value === null) {
      value = defaultValue !== null ? defaultValue : '';
    }
    return (
      <input
        {...restProps}
        type="text"
        value={value}
        onKeyPress={this.handleKeyPress}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />);
  }
}


export default NumberInput
