import { Button, InputField, Select } from "components";
import FormField from "components/FormField";
import TaggedCourseSelect from "components/Select/TaggedCourseSelect";
import { withApiContext, withLabelsContext, withUserContext, withWindowContext } from "context";
import { IconTagClose } from "Icons";
import JoditEditor from "jodit-react";
import { rgba } from "polished";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTheme } from "styled-components";
import { TestContext } from "./Context/TestContex";
import CourseTagSelect from "./CourseTagSelect";
import css from "./testCreation.module.scss";
import * as messages from "messages";

function TestEdit(props) {
  const editor = useRef(null);
  const theme = useTheme();
  const subjects = props.ctx_user.user.campusSubj;
  const standards = props.ctx_user.user.campusCategories;
  const { testData, setTestData, sectionVisible, setSectionVisible } = useContext(TestContext);
  const [testDetails, setTestDetails] = useState({
    testName: "",
    shortName: "",
    standard: "",
    subject: "",
    courseTag: [],
    description: "",
    testId: "",
  });
  const [selectedTaggedCourses, setSelectedTaggedCourses] = useState([]);
  const configs = useMemo(
    () => ({
      height: 300,
      allowResizeY: false,
      hidePoweredByJodit: true,
      readonly: false,
      buttons: "bold,italic,eraser,fontsize,\n,brush,paragraph,left,copyformat",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      toolbarButtonSize: "small",
      maxHeight: 300,
      useSearch: false,
      removeButtons: [
        "source",
        "superscript",
        "subscript",
        "outdent",
        "indent",
        "image",
        "file",
        "video",
        "table",
        "link",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "copyformat",
        "selectall",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
        "preview",
        "find",
        "replace",
        "inserthorizontalrule",
      ],
    }),
    []
  );
  const inlineBackground = {
    "--bg-color-1": rgba(theme.primary, 0.5),
  };
  useEffect(() => {
    const subjectId = subjects.find((item) => item.value === testData.curriculumId);
    const standardId = standards.find(
      (item) => item.value === testData.categoryId
    );
    setTestDetails((prev) => {
      return {
        ...prev,
        testName: testData.name,
        shortName: testData.short,
        standard: standardId.value,
        subject: subjectId.value,
        courseTag: testData.courseTag,
        description: testData.description,
        testId: testData.testId,
      };
    });
  }, []);

  useEffect(() => {
    setTestDetails((prev) => {
      return {
        ...prev,
        courseTag: selectedTaggedCourses,
      };
    });
  }, [selectedTaggedCourses]);

  const handleTestDetailChange = ({ name, value }) => {
    if (name === "testName" || name === "shortName") {
      setTestDetails((prev) => {
        const tstData = { ...prev, [name]: value.target.value };
        return tstData;
      });
      if (name === "testName") {
        setTestData((prev) => {
          return {
            ...prev,
            name: value.target.value
          };
        });
      }
    } else {
      setTestDetails((prev) => {
        const tstData = { ...prev, [name]: value };
        return tstData;
      });
    }
  };
  const isSaveDisabled = testDetails.testName.trim() == '' || testDetails.shortName.trim() == '';
  const handleSave = () => {
    setTestData((prev) => {
      return {
        ...prev,
        name: testDetails.testName,
        short: testDetails.shortName,
        description: testDetails.description,
        courseTag: testDetails.courseTag,
      };
    });
    setSectionVisible(true);
    props.ctx_window.alert(
      messages.mkTestAddSuccess(
        props.ctx_labels.singular.testDetails + " " + "updated"
      )
    );
    props.handleReset();
  };
  const handleCancel = () => {
    props.handleReset();
  };
  return (
    <div>
      <Row>
        <Col md={7}>
          <div>
            <label className={css.testLables}>
              Assessment Name
              <span>*</span>
            </label>
            <FormField
              type={InputField}
              name="testName"
              // options={typeOptions}
              onChange={handleTestDetailChange}
              value={testDetails.testName}
              placeholder="Assessment Name"
              required
            />
          </div>
        </Col>
        <Col md={5}>
          <div>
            <label className={css.testLables}>
              Short Name
              <span>*</span>
            </label>
            <FormField
              type={InputField}
              name="shortName"
              // options={typeOptions}
              onChange={handleTestDetailChange}
              value={testDetails.shortName}
              maxLength={100}
              placeholder="Short Name"
              required
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <div>
            <label className={css.testLables}>
              Subject
              <span>*</span>
            </label>
            <FormField
              type={Select}
              name="subject"
              options={subjects}
              onChange={handleTestDetailChange}
              value={testDetails.subject}
              placeholder="Subject"
              required
              disabled={props.editdivSub}
            />
          </div>
        </Col>
        <Col md={5}>
          <div>
            <label className={css.testLables}>
              Standard
              <span>*</span>
            </label>
            <FormField
              type={Select}
              name="standard"
              options={standards}
              onChange={handleTestDetailChange}
              value={testDetails.standard}
              placeholder="Standard"
              required
              disabled={props.editdivSub}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormField
            componentClass="textarea"
            className="input-full"
            type={JoditEditor}
            name="description"
            value={testDetails.description}
            config={configs}
            editorRef={editor}
            onChange={handleTestDetailChange}
            placeholder="Assessment Description"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <CourseTagSelect
            subject={testData.curriculumId}
            standard={testData.categoryId}
            Id={testData.testId}
            value={testData.courseTag}
            selectedTags={testData.courseTag}
            setSelectedTaggedCourses={setSelectedTaggedCourses}
            isEdit
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className={css.questionButton}>
          <Button
            className={css.rectangleButton}
            style={{
              backgroundColor: theme.primary,
              borderColor: theme.primary,
            }}
            disabled={isSaveDisabled}
            onClick={handleSave}
          >
            {props.testModify ? "Update" : "Save"}
          </Button>
          <Button
            className={css.rectangleButton}
            variant="btn-outline-cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default withWindowContext(withLabelsContext(withApiContext(withUserContext(TestEdit))));
