import * as config from 'config';


class Api {
  constructor() {
    this.nextReqId = 0;
    this.submittedCmds = {};
    this.pendingReqs = [];
    this.version = config.apiVersion
  }

  retireSubmittedCommand = (id, cmd) => {
    if (this.submittedCmds[cmd] === id) {
      // This request is the latest instance of cmd
      delete this.submittedCmds[cmd];
      if (Object.keys(this.submittedCmds).length === 0) {
        this.nextReqId = 0; // Avoid any potential wrap-around
      }
    }
  }

  setSuccessHandler = (req) => {
    const { id, cmd, resolve } = req;
    if (!resolve) {
      return;
    }
    req.onSuccess = (rsp, headers) => {
      resolve({ rsp, headers });
      this.retireSubmittedCommand(id, cmd);
      const prevPendingReqs = this.pendingReqs;
      this.pendingReqs = [];
      prevPendingReqs.forEach((pendingReq) => {
        const i = pendingReq.depIds.indexOf(req.id);
        if (i === -1) {
          this.pendingReqs.push(pendingReq);
        } else if (pendingReq.depIds.length === 1) {
          this.issueRequest(pendingReq);
        } else {
          pendingReq.depIds.splice(i, 1);
          this.pendingReqs.push(pendingReq);
        }
      });
    };
  }

  setFailureHandler = (req) => {
    const { id, cmd, reject } = req;
    req.onFailure = (err) => {
      if (reject) {
        reject(err);
      }
      this.retireSubmittedCommand(id, cmd);
      let prevPendingReqs;
      const failedReqIds = [id];
      while (0 < failedReqIds.length) {
        const failedReqId = failedReqIds.pop();
        prevPendingReqs = this.pendingReqs;
        this.pendingReqs = [];
        prevPendingReqs.forEach((pendingReq) => {
          const i = pendingReq.depIds.indexOf(failedReqId);
          if (i === -1) {
            this.pendingReqs.push(pendingReq);
          } else {
            failedReqIds.push(pendingReq.id);
          }
        });
      }
    }
  }

  parseFetchResponse = (rsp) => {
    if (rsp.ok) {
      return rsp.json();
    }
    return rsp.text()
      .then(msg => {
        const err = new Error(`fetch error (${rsp.status}): ${msg}`);
        err.code = rsp.status;
        throw err;
      });
  }

  checkExpectedRowCount = (n) => (rsp) => {
    if (!n || rsp.length === n) {
      return rsp;
    }
    throw new Error(`fetch error: expected ${n} rows`);
  }

  setVersion = (version) => {
    this.version = version
  }

  issueRequest = ({
    auth = null,
    cmd,
    args,
    rowCount = null,
    onSuccess,
    onFailure,
    isFileUpload = false,
    noVersion
  }) => {
    const url = noVersion ? `${config.apiUrlBase}${cmd}` : `${config.apiUrlBase}v${this.version}/${cmd}`;
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    if (!isFileUpload) {
      headers.append('Content-Type', 'application/json');
    }
    if (auth) {
      headers.append('x-access-token', auth);
    }
    const input = {
      method: 'POST',
      headers: headers,
      body: isFileUpload ? args : JSON.stringify(args),
    };
    fetch(url, input)
      .then((rsp) => {
        headers = rsp.headers;
        return this.parseFetchResponse(rsp);
      })
      .then(this.checkExpectedRowCount(rowCount))
      .then(rsp => onSuccess(rowCount === 1 ? rsp[0] : rsp, headers))
      .catch(err => {
        console.log(err.message);
        onFailure(err);
      });
  }

  submitRequest = ({
    deps,
    ...req
  }) => {
    req.id = this.nextReqId;
    this.nextReqId = this.nextReqId + 1;

    this.setSuccessHandler(req);
    this.setFailureHandler(req);

    req.depIds = [];
    if (deps) {
      // If deps is true, make req dependent on all previously submitted
      // requests
      if (typeof (deps) === 'boolean') {
        Object.values(this.submittedCmds).forEach((id) => req.depIds.push(id));
      } else {
        deps.forEach((cmd) => {
          const id = this.submittedCmds[cmd];
          if (id != null) {
            req.depIds.push(id);
          }
        });
      }
    }
    if (0 < req.depIds.length) {
      this.pendingReqs.push(req);
    } else {
      this.issueRequest(req);
    }

    // Update last to avoid creating a false circular dependency
    this.submittedCmds[req.cmd] = req.id;
  }
}


export default Api
