import React from "react";
import { Card } from "react-bootstrap";
import classNames from "classnames";
import "./card-content.scss";
import styled from "styled-components";
import { Link } from "react-router-dom";

// theme
const StyledTop = styled.div`
  && {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
  }
`;

const Top = (props) => {
  return (
    <StyledTop
      className={classNames(
        "card-top",
        props.size === "sm" && "card-top__sm",
        props.className
      )}
    >
      {props.image && (
        <img src={props.image} className="card-top__image" />
      )}
      {props.label && <TopLabel>{props.label}</TopLabel>}
      {props.title && (
        <div className="card-top__title text-ellipsis">{props.title}</div>
      )}
      {props.icon && (
        <div className="card-top__icon">{props.icon}</div>
      )}
      {props.time && (
        <div className="card-top__time">{props.time}</div>
      )}
      {props.children}
    </StyledTop>
  );
};

const TopIcon = ({ icon, ...props }) => {
  return <div {...props} className="card-top-icon bg-primary" >{icon}</div>;
};

const TopLabel = (props) => {
  return <div className="card-top-label bg-primary">{props.children}</div>;
};

const Category = (props) => {
  return (
    <div className={classNames(props.className, "text-primary card-category")}>
      {props.children}
    </div>
  );
};

const CardContent = ({ className, linkTo, ...props }) => {
  return (
    <Card {...props} className={classNames("card-content", className)}>
      {props.children}
      {linkTo && <Link to={linkTo} className="stretched-link"></Link>}
    </Card>
  );
};

CardContent.Top = Top;
CardContent.Category = Category;
CardContent.TopIcon = TopIcon;
CardContent.TopLabel = TopLabel;

export default CardContent;
