import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import css from "./overview-card.module.scss";

function OverviewCard(props) {
  return (
    <div className={classNames(css.card, props.className, "bg-color2")}>
      <div className={css.content}>
        <div className={css.title}>{props.title}</div>

        {/* number */}
        {props.number === 0 ? <div className={css.number}>{props.number}</div> :
        props.number &&   <div className={css.number}>{props.number}</div>
        }
        {/* subtitle */}
        {props.subtitle && <div className={css.subtitle}>{props.subtitle}</div>}

        {props.children}
      </div>
      <div className={`${css.icon}`}>{props.icon}</div>
    </div>
  );
}

OverviewCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  number: PropTypes.any,
  subtitle: PropTypes.any,
};

export default OverviewCard;
