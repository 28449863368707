import {
  AccessContext,
  AIBotContext,
  ApiContext,
  LabelsContext,
  SiteContext,
  UserContext,
  WindowContext,
  withLabelsContext,
} from "context";
import React, { PureComponent, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "themes/GlobalStyle";
import themes from "themes/schema.json";
import ThemeContext from "themes/theme-context";
import PrivateRoute from "components/PrivateRoute";
import * as config from "config";
import Api from "data/api";
import { initBot, startChatBot, stopChatBot } from "./services/chatBot";
import * as util from "services/util";
import * as messages from "messages";
import * as label from "label";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import  { msalConfig }  from "./authConfig";
import ErrorBoundary from "./ErrorBoundary"
import LoginShibboleth from "pages/Login/LoginShibboleth";
import LogoutShibboleth from "pages/Login/LogoutShibboleth";
import Loader from "components/Spinners/Loader";
import RemoveUnwantedIframes from "RemoveUnwantedIframes";
import PlayerHome from "pages/ChapterInner/PlayerHome";
import { Value } from "sass";

const Main = lazy(() => import("pages/Main").then(module => ({ default: module.Main })))
const ConfirmModal = lazy(() => import("components/Modals/Confirm"));
const InfoModal = lazy(() => import("components/Modals/Info"));
const CustomCallback = lazy(() => import("components/Modals/CustomCallback"));
const Login = lazy(() => import("pages/Login"));
const Contact = lazy(() => import("pages/Login/Contact"));
const TermsOfUse = lazy(() => import("pages/Login/TermsOfUse"));
const ForgotPassword = lazy(() => import("pages/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/Login/ResetPassword"));
// const PlayerHome = lazy(() => import("pages/ChapterInner/PlayerHome"));
const ChapterCourses = lazy(() => import("pages/ChapterInner/chapterCourses"));
const ContentBuilder = lazy(()=> import ("pages/ContentBuilder/ContentBuilder"));
const msalInstance = new PublicClientApplication(msalConfig);

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      visible: false,
      store: this.initStore,
      labelCache: this.mkLabelCache(this.initStore),
      confirmDesc: {},
      confirmCallback: null,
      customConfirmCallback: null,
      alertMessage: null,
      alertCallback: null,
      logoutPending: false,
      cms: {},
      loading: false,
      // theme: "default",
      theme: themes[localStorage.getItem("groupTheme")] ? localStorage.getItem("groupTheme") : themes[localStorage.getItem("siteTheme")] ?  localStorage.getItem("siteTheme") :"default",
      currStdSidebarvisibility: true,
      chatBot:false,
      botSetting:{},
      botParams:{},
    };
    this.authRefreshRequired = false;
    this.authRefreshTimeout = null;

    this.activityState = "active";
    this.idleTimeout = null;

    this.bot = null;

    // this.setRootElementStyle();
  }

  initStore = {
    user: null,
    theme: {
      logo: null,
      icon: null,
      siteColors: {},
      goiColors: {},
      userColors: {},
      preview: {},
      siteLabels: {},
      goiLabels: {},
    },
    bot: null,
  };

  componentDidMount = () => {
    // window.encryptedData && this.decrypt();
    const dir = localStorage.getItem("dir");
    document.body.setAttribute("dir", dir ? dir : "ltr");
    this.requestSessionInfo();
    window.addEventListener("storage", this.onStorage, false);
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.logoutPending) {
      this.handlePendingLogout(prevState);
    }
    const { user, theme } = this.state.store;
    const prevUser = prevState.store.user;
    if (user && !prevUser) {
      if (!this.state.visible) {
        this.setState({ visible: true });
        this.requestAuthRefresh();
      } else {
        this.schedAuthRefresh();
      }
    }
    const prevTheme = prevState.store.theme;
    if (
      theme.siteColors !== prevTheme.siteColors ||
      theme.goiColors !== prevTheme.goiColors ||
      theme.userColors !== prevTheme.userColors ||
      theme.preview !== prevTheme.preview
    ) {
    }
    if (
      theme.siteLabels !== prevTheme.siteLabels ||
      theme.goiLabels !== prevTheme.goiLabels ||
      user !== prevUser
    ) {
      this.setLabelCache();
    }
    if(this.state.chatBot){
      initBot(this.state.botSetting, this.state.botParams).then(
        (startChatBot().then((bot) => this.bot = bot))
      );
      this.state.chatBot && this.setState({chatBot:false});
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("storage", this.onStorage);
  };
  updateStore = (name) => (f) => {
    this.setState((s0) => {
      const store = {
        ...s0.store,
        [name]: f(s0.store[name]),
      };
      return { store };
    });
  };

  resetStore = () => {
    this.setState({ store: this.initStore });
  };

  publicApiRequest = (req) =>
    new Promise((resolve, reject) => {
      this.api.submitRequest({
        ...req,
        resolve,
        reject,
        auth: null,
      });
    });

  privateApiRequest = (req) =>
    new Promise((resolve, reject0) => {
      const auth = util.getAuth();
      if (!auth) {
        if (reject0) {
          // FIXME restore this once we handle multi-tab logouts properly
          // reject0(new Error('user not logged in'));
          console.log("user not logged in");
        }
        return;
      }
      const reject = (err) => {
        if (err.code === 403) {
          this.logOutOnAuthError();
        }
        if (reject0) {
          reject0(err);
        }
      };
      this.api.submitRequest({
        ...req,
        resolve,
        reject,
        auth,
      });
    });

  publicApiRequestRow = (req) => this.publicApiRequest({ ...req, rowCount: 1 });

  privateApiRequestRow = (req) =>
    this.privateApiRequest({ ...req, rowCount: 1 });

  logOutOnAuthError = () => {
    this.logOutOnClient(messages.mkAuthErrorAlert());
  };

  logIn = (auth, r, stayLoggedIn) => {
    util.setSession({
      sessionId: r.session_id,
      auth,
      stayLoggedIn,
    });
    // this.userLoggedInStatus();
    if(r.statusvalue !== 6){
      r.analytics[0].isAnalyticsTracking &&
      this.setLoginEntry(r) 
      };
    this.finalizeLogin(r, stayLoggedIn);
  };

  // userLoggedInStatus = (r) => {
  //   const sessionId = localStorage.getItem("sessionId")
  //   this.privateApiRequest({
  //     cmd: "check_user_logged_in_status",
  //     args:{
  //       sessionId: sessionId
  //     }
  //   })
  //   .then(this.handleResponse)
  //   .catch(this.handleResponseError)
  // }

  handleResponse = ({rsp}) => {
    if(rsp[0].statusvalue === 1){
     window.alert("You are already logged on to another device")
    }
  }

  setLoginEntry = (r) => {
    const names = {
      goi: "",
      org: "",
      school: "",
    };
    r.campus_list.forEach((s) => {
      if (!names.goi && s.goi_id == r.goi_id) {
        names.goi = s.goi_name;
      }
      if (!names.org && s.organization_id == r.last_active_campus) {
        names.org = s.organization_name;
      }
      if (!names.school && s.school_id == r.school_id) {
        names.school = s.school_name;
      }
    });

    this.privateApiRequest({
      cmd: "graphql",
      args: {
        query: `mutation{
          addMemberlogin(
            member_id:${r.member_id},
                acad_year_id:${r.acad_year_id},
                member_type_id:${r.member_type},
                member_type_name:"${r.member_type === 1
            ? "student"
            : r.member_type === 2
              ? "trainer"
              : r.member_type === 4
              ? "parent"
              : r.member_type === 3
                ? "group admin"
                : r.member_type === -1
                  ? "admin"
                  : ""
          }",
                acad_year_name:"${r.acad_year_name.replace(/("+)/g,'""')}",
                org_id:${r.last_active_campus},
                goi_id:${r.goi_id},
                goi_name:"${names.goi}",
                school_id:${r.school_id},
                school_name:"${names.school}",
                org_name:"${names.org}",
                category_id:${r.standard_id},
                category_name:"${r.standard_name}",
                division_id:${r.division_id},
                division:"${r.division_name}",
                class:"${r.class_name}",
                job_profile_name:"${r.job_profile}",
                job_profile_id:${r.profile_id},
                loggedin_at:"${new Date().toISOString()}",
                loggedout_at:"${new Date().toISOString()}",
                duration:${0},
                active_study_time: ${0},
                
          ){
            id
            loginDateTime: loggedin_at
          }
      }`,
      },
    })
      .then(({ rsp }) => {
        this.handleLoginEntry(rsp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleLoginEntry = (data) => {
    const { addMemberlogin } = data;
    util.setLoginEntry({
      loginDetails: addMemberlogin,
    });
  };

  setLogoutEntry = (r) => {
    this.privateApiRequest({
      cmd: "graphql",
      args: {
        query: `mutation{
          updateMemberlogin(
          id:"${r.id}",
          loggedin_at:"${new Date(r.loginDate).toISOString()}"
          loggedout_at:"${new Date().toISOString()}"){
            id
            logoutDateTime: loggedout_at
            duration
            }
          }`,
      },
    })
      .then(({ rsp }) => {
        console.log("logout entry success");
        this.handleLogoutEntry(rsp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleLogoutEntry = () => {
    util.setLoginEntry({
      loginDetails: {},
    });
  };

  finalizeLogin = (r, stayLoggedIn) => {
    // We cannot use util.getStayLoggedIn, as the new value will not be set yet
    // when this function is called by this.logIn
    if (!stayLoggedIn) {
      this.restartIdleTimeout();
      r.impersonization === 0 && this.activityEvents.forEach((event) => {
        window.addEventListener(event, this.onThisWindowActivity, false);
      });
    }
    this.setTheme(r);
    this.setUser(r);
  };

  setTheme = (r) => {
    this.updateStore("theme")((theme) => {
      return {
        ...theme,
        siteLabels: r.site_labels || {},
        goiLabels: r.org_labels || {},
        goiColors: r.group_colors || {},
        userColors: r.colors || {},
      };
    });
  };

  fetchSponsorLogo = async (logoId) => {
    const { rsp } = await this.privateApiRequestRow({
      cmd: "get_organisation_logo",
      args: { logoId },
    });
    if (rsp.skin_logo && rsp.skin_logo_format) {
      this.updateStore("theme")((theme) => ({
        ...theme,
        logo: rsp.skin_logo,
        logoFormat: rsp.skin_logo_format,
      }));
    }
  };

  fetchOrganizationTheme = async (
    orgId,
    userType,
    userId,
    groupId,
    schoolId,
    classId,
    acadYearId,
    activeLanguageId
  ) => {
    // this.setState({ loading: true })
    const { rsp } = await this.privateApiRequestRow({
      cmd: "get_org_theme_details",
      args: { orgId: orgId.toString(), activeLanguageId: activeLanguageId.toString() },
    });
    const ifEmpty = (obj) => {
      if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).length === 0 && obj.constructor === Object
          ? null
          : obj;
      } else {
        return null;
      }
    };
    this.updateStore("user")((user) => ({
      ...user,
      chatEnabled: rsp.chat_bot_enabled,
      logo: rsp.logo,
      coBrand: {
        image: rsp.co_brand_image,
        url: rsp.co_brand_url,
      },
    }))
    this.setState({
      theme: themes[rsp.group_colors]
        ? rsp.group_colors
        : themes[localStorage.getItem("siteTheme")]
        ? localStorage.getItem("siteTheme")
        : "default",
    });
    localStorage.setItem("groupTheme", rsp.group_colors);
    this.updateStore("theme")((theme) => ({
      ...theme,
      organization: {
        name: rsp.organization,
        shortName: rsp.short_name,
        chatEnabled: rsp.chat_bot_enabled,
      },
      theme: rsp.group_colors || "default",
      logo: rsp.logo,
      icon: rsp.icon,
      goiLabels: ifEmpty(rsp.group_labels) || theme.goiLabels || {},
      siteColors: ifEmpty(rsp.site_colors) || theme.siteColors || {},
      goiColors: ifEmpty(rsp.group_colors) || theme.goiColors || {},
      coBrand: {
        image: rsp.co_brand_image,
        url: rsp.co_brand_url,
      },
    }));

    const botSetting = {
      serverIp: rsp.server_ip,
      serverPort: rsp.server_port,
      serverUrl: rsp.server_url,
      urlExtension: rsp.url_extension,
      botId: rsp.bot_id,
      accountId: rsp.account_id,
      params: rsp.params,
    };
   

    const botParams = {
      category: rsp.category,
      userType: userType,
      userId: userId,
      groupId: groupId,
      schoolId: schoolId,
      classId: classId,
      acadYearId: acadYearId,
      region: rsp.region,
      trainer: rsp.trainer,
      trainee: rsp.trainee,
      accountId: rsp.account_id,
    };
    this.setState({botSetting,botParams});
    this.updateStore("bot")(() => ({
      botParams,
    }));

    if (rsp.chat_bot_enabled === 1 && (userType === 1 || userType === 2)) {
      //this.bot = startChatBot(userId, userType, botSetting);
      initBot(botSetting, botParams).then(
        (startChatBot().then((bot) => this.bot = bot))
      );
    }
    // this.setState({ loading: false })
  };

  setLabelCache = () => {
    const labelCache = this.mkLabelCache(this.state.store);
    this.setState({ labelCache });
  };

  mkLabelCache = (store) => {
    const userType = store.user && store.user.type;
    const role =
      userType === -1
        ? "admin"
        : userType === 3
          ? "admin"
          : userType === 2
            ? "trainer"
            : userType === 1
              ? "member"
              : null;
    const { siteLabels, goiLabels } = store.theme;
    const defaults = util.mergeLabelRoleSpecs(
      label.configurableLabels.defaults,
      siteLabels.defaults,
      goiLabels.defaults
    );
    const exceptions = util.mergeLabelRoleExceptions(
      label.configurableLabels.exceptions[role],
      (siteLabels.exceptions || {})[role],
      (goiLabels.exceptions || {})[role]
    );
    return util.generateLabels(defaults, exceptions);
  };

  setUser = (r) => {
    const campusList = r.campus_list;
    const last_active_campus = parseInt(r.last_active_campus, 10) || null;
    const last_active_role = parseInt(r.role_id, 10) || null;

    // On user setting - set goi,school,organization ids if campus_list is single row
    let goiId = r.goi_id;
    let schoolId = r.school_id;
    let organizationId = r.organization_id;
    let acadYearId = r.acad_year_id ? r.acad_year_id.toString() : null;
    let acadYearName = r.acad_year_name;
    let enterprise = r.enterprise;
    let chatBotEnabled = r.config?.chat_bot_enabled === "1" || false;
    let roleId = r.role_id;
    let type = r.member_type;
    let credits = util.toInt(r.total_points) || 0;
    let campusCategories = r.campus_categories || [];
    let campusSubj = r.campus_subj || [];
    let campus;
    if (campusList && last_active_campus) {
      campus = campusList.find(
        (c) =>
          c.organization_id === last_active_campus &&
          c.role_id === last_active_role
      );
      if (campus) {
        goiId = campus.goi_id;
        schoolId = campus.school_id;
        organizationId = campus.organization_id;
        acadYearId = campus.acad_year_id
          ? campus.acad_year_id.toString()
          : null;
        acadYearName = campus.acad_year_name;
        enterprise = campus.enterprise;
        chatBotEnabled = campus.ai_integrated;
        roleId = campus.role_id;
        type = campus.member_type;
        credits = util.toInt(campus.total_points) || 0;
        campusCategories = campus.campus_categories || [];
        campusSubj = campus.campus_subj || [];
      }
    }

    this.updateStore("user")(() => ({
      id: r.member_id,
      uid: r.u_member_id,
      type: type,
      firstName: r.first_name,
      lastName: r.last_name,
      registrationNo: r.registration_no,
      photo: r.photo,
      email: r.email,
      credits: credits,
      level: r.member_level_name,
      sponsorCode: r.caregiver_id,
      roleId: roleId ? roleId.toString() : roleId,
      goiId: goiId ? goiId.toString() : goiId,
      schoolId: schoolId ? schoolId.toString() : schoolId,
      organizationId: organizationId
        ? organizationId.toString()
        : organizationId,
      impersonization: r.impersonization === 1 ? r.impersonization : 0,
      impersonator_id: r.impersonator_id ? r.impersonator_id : null,
      chatBotEnabled: chatBotEnabled,
      standardId: r.standard_id,
      standardName: r.standard_name,
      divisionId: r.division_id,
      divisionName: r.division_name,
      classId: r.class_id,
      className: r.class_name,
      campusList: campusList || [],
      categoryLevels: r.category_levels || [],
      categoryLevelId: r.category_level_id,
      acadYearId: acadYearId,
      acadYearName: acadYearName,
      enterprise: enterprise,
      campusCategories: campusCategories,
      campusSubj: campusSubj,
      highlight: r.highlight || 0,
      highlightIntro: r.config?.enable_intro === "1" || false,
      scormContent: r.config?.enable_scorm === "1" || false,
      analytics: {
        graphqlCallInterval: r.config?.graphql_interval || null,
        idleTimeoutInterval: r.config?.idle_timeout_interval || null,
        isAnalyticsTracking: r.config?.enable_analytics === "1" || false,
        studyMapTriggerInterval: r.config?.studymap_trigger_interval || null,
      },
      acceptedTermsAndConditions: r.terms_conditions_accepted === 1,
      termsAndConditions: r.terms_conditions,
      playerPollingInterval: +r.player_polling_interval,
      videoPlayerConfig: r.video_player_config,
      maxPdfSize: r.config?.maxPdfSize || null,
      defaultScorm: r.config?.default_scorm === "1" || false,
      reportsTrainer: r.config?.reports_trainer === "1" || false,
      reportsTrainee: r.config?.reports_trainee === "1" || false,
      graphUrlPath: r.config?.graphurl_path || null,
      vimeoUploadTranscodePollInterval:
        r.config?.vimeoUploadTranscodePollInterval || null,
      maxVideoSize: r.config?.maxVideoSize || null,
      maxConvertSize: r.config?.maxConvertSize || null,
      maxAudioSize: r.config?.maxAudioSize || null,
      jitsiDomain: r.config?.jitsi_meet || null,
      maxImageSize: r.config?.maxImageSize || null,
      coursePubType: r.coursePubType,
      maxImageWidth: r.config?.maxImageWidth || null,
      maxImageHeight: r.config?.maxImageHeight || null,
      chapterScheduleGap: +r.config?.module_schedule_gap || null,
      rtl: true,
      coursePublishGrouping: r.config?.course_publish_grouping == "1",
      memberClasses: r.member_classes || [],
      newUser: r.statusvalue === 6,
      dependantId : r.dependant_id || null,
      dependantName: r.dependant_name || "",
      dependantPhoto: r.dependant_photo || "",
      dependantClassId: r.dependent_class_id || null,
      dependantClassName: r.dependent_class_name || "",
      embeddedEnabling: r.config?.Embedded_Enabling == "1",
      loginType: r.loginType,
      gradeEnabled: r.config?.grade === "1" || false,
      newMessagePolling: r.config?.New_Message_Polling === "1" || false,
      showCredits : r.show_credits,
      activeLanguageId: r.active_language_id,
      coursePublishRefresh: r.config?.course_publish_refresh === "1" || false,
      enableOldCourses: r.enable_old_courses,
      jobProfile: r.job_profile || null,
      skills: r.job_interest || [],
      enableSkills: r.config?.Enable_Skills === "1" || false,
      enableCourseRating: r.config?.Enable_Course_Rating === "1" || false,
      enablePpt: r.config?.Enable_Ppt === "1" || false,
      passwordExpiry: r.password_expiry || false,
      enableCourseShare: r.config?.Enable_Course_Share === "1"  || false,
      enableAiTestGeneration: r.config?.Enable_Ai_Test_Generation === "1"  || false,
      groupAdmin: r.is_group_admin,
      reportType:r.config?.Select_Report_Type,
      enableAchievements:r.config?.Enable_Achievements === "1" || false,
      enablePoll:r.config?.Enable_Poll === "1" || false,
      pollUrl: r.poll_url || "",
      pollImage:r.poll_image || ""
    }));

    if (organizationId) {
      this.fetchOrganizationTheme(
        organizationId.toString(),
        r.member_type,
        r.member_id,
        goiId.toString(),
        schoolId.toString(),
        r.class_id,
        r.acad_year_id,
        r.active_language_id
      );
    }
  };

  logOut = () => {
    // We do not want to log out until MemberHome has unmounted (to ensure
    // all updated state has been written back to the db).
    this.setState({ logoutPending: true });
  };

  handlePendingLogout = (r) => {
    this.setState({ logoutPending: false });
    this.privateApiRequest({
      cmd: "member_logout",
      args: {},
      deps: true, // Ensure all pending db requests have completed
    }).then(
      r.store.user.analytics.isAnalyticsTracking &&
      this.setLogoutEntry(util.getLoginDetails()),
      this.logOutOnClient(messages.mkLogoutAlert())
    );
  };

  logOutOnClient = (msg) => {
    util.clearSession();
    this.finalizeLogout(msg);
  };

  finalizeLogout = (msg = null) => {
    localStorage.removeItem("selectedMenuId");
    this.resetStore();
    if (this.authRefreshTimeout) {
      clearTimeout(this.authRefreshTimeout);
      this.authRefreshTimeout = null;
    }
    if (!util.getStayLoggedIn()) {
      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = null;
      }
      this.activityEvents.forEach((event) => {
        window.removeEventListener(event, this.onThisWindowActivity);
      });
    }
    if (msg) {
      this.alert(msg);
    } else {
      this.clearAlert();
    }
    this.bot && stopChatBot(this.bot);
    localStorage.setItem('logAttempt', 0);
  };

  schedAuthRefresh = (fixedIntervalSecs = null) => {
    const callback = () => {
      this.authRefreshTimeout = null;
      if (util.getStayLoggedIn() || fixedIntervalSecs) {
        this.requestAuthRefresh();
      } else {
        this.authRefreshRequired = true;
      }
    };
    const secs = this.state.cms.refreshTime * 60//fixedIntervalSecs || config.refreshAuthTokenIntervalSecs;
    this.authRefreshTimeout = setTimeout(callback, secs * 1000);
  };

  requestSessionInfo = () => {
    const sessionId = util.getSessionId();
    if (sessionId) {
      this.privateApiRequestRow({
        cmd: "member_session_info",
        args: { sessionId },
      })
        .then(({ rsp }) => {
          if (rsp.statusvalue === 1|| rsp.statusvalue===6) {
            this.fetchSiteDetails();
            // this.userLoggedInStatus();
            this.finalizeLogin(rsp, util.getStayLoggedIn());
          } else {
            util.clearSession();
            throw new Error(rsp.statustext);
          }
        })
        .catch((err) => {
          this.fetchSiteDetails();
        });
    } else {
      if (util.objectEmpty(this.state.cms)) {
        this.fetchSiteDetails();
      }
    }
  };

  fetchSiteDetails = () => {
    console.log("getSiteDetails")
    this.publicApiRequestRow({
      cmd: "getSiteDetails",
      noVersion: true
    })
      .then(({ rsp }) => {
        this.api.setVersion(rsp.api_version)
        const cms = {
          siteName: rsp.name || null,
          logo: rsp.logo || null,
          favIcon: rsp.favicon || null,
          websiteUrl: rsp.website || null,
          homeWebBanner: rsp.home_web_banner || null,
          homeMobBanner: rsp.home_mob_banner || null,
          homePageContent: rsp.home_page_content || null,
          contactAddress: rsp.contact_address || null,
          contactEmail: rsp.contact_email || null,
          contactPhone: rsp.contact_phone || null,
          facebookUrl: rsp.facebook_url || null,
          twitterUrl: rsp.twitter_url || null,
          linkedInUrl: rsp.linkedin_url || null,
          language: rsp.language || null,
          theme: rsp.theme || null,
          saveFile: rsp.save_file,
          title: rsp.title || null,
          subTitle: rsp.sub_title || null,
          termsOfUse: rsp.terms_conditions || null,
          privacyPolicy: rsp.privacy_policy || null,
          coBrandImage: rsp.co_brand_image || null,
          siteKeyCaptcha: rsp.recaptcha || '',
          captchaEnabled: rsp.captcha_enabled == 1,
          externalLoginEnabled: rsp.google_hidden == 0,
          mediaConversion: rsp.media_conversion,
          refreshTime: rsp.refresh_time,
          campusLogo: rsp.campus_logo,
          icon: rsp.icon
        };
        this.setState({ cms, visible: true, theme: themes[localStorage.getItem("groupTheme")] ? localStorage.getItem("groupTheme"):  themes[rsp.theme] ? rsp.theme : "default" });
        localStorage.setItem("siteTheme", themes[rsp.theme] ? rsp.theme : "default");


        if (rsp.favicon) {
          // function change_favicon(img) {
          let favicon = document.querySelector('link[rel="shortcut icon"]');
          if (!favicon) {
            favicon = document.createElement("link");
            favicon.setAttribute("rel", "shortcut icon");
            const head = document.querySelector("head");
            head.appendChild(favicon);
          }
          document.querySelector(
            "link[rel='shortcut icon']"
          ).href = `${rsp.favicon}`;
          document.querySelector(
            "link[rel*='icon']"
          ).href = `${rsp.favicon}`;
          document.title = rsp.name;
          // favicon.setAttribute("type", "image/png");
          // favicon.setAttribute("href", "data:image/png;base64," + rsp.favicon);
        }
      })
      .catch((err) => {
        this.setState({ visible: true });
      });
  };

  requestAuthRefresh = () => {
    const sessionId = util.getSessionId();
    // Check sessionId in case timeout fired immediately before logout
    if (sessionId) {
      this.privateApiRequestRow({
        cmd: "refresh_auth_token",
        args: { sessionId },
      })
        .then(this.handleAuthRefreshResponse)
        .catch(this.handleAuthRefreshError);
    }
  };

  handleAuthRefreshResponse = ({ rsp, headers }) => {
    switch (rsp.statusvalue) {
      case 1:
        const auth = headers.get("x-access-token");
        util.setAuth(auth);
        this.schedAuthRefresh();
        break;
      case 0:
        // We might have issued an auth refresh request to the server
        // while we were logging out
        if (util.getSessionId()) {
          this.logOutOnAuthError();
        }
        break;
      default:
        break;
    }
  };

  handleAuthRefreshError = (err) => {
    console.log(err)
    if (500 <= err.code) {
      this.schedAuthRefresh(config.authTokenRetryIntervalSecs);
    }
  };

  activityEvents = [
    "mousemove",
    "mousedown",
    "touchmove",
    "touchstart",
    "keypress",
    "wheel",
  ];

  onOtherWindowActivity = () => {
    if (this.activityState === "idle") {
      this.handleDismissAlert();
    }
    this.restartIdleTimeout();
  };

  onThisWindowActivity = () => {
    if (this.activityState === "active") {
      this.restartIdleTimeout();
      this.markWindowActive();
    }
  };

  markWindowActive = () => {
    const ts = Date.now();
    const prevTs = this.state.activityTimestamp;
    const tsDelta = prevTs && ts - prevTs;
    // Check for negative delta in case local time has been set
    if (!tsDelta || tsDelta < 0 || config.idleDebounceMillis < tsDelta) {
      this.setState({ activityTimestamp: ts });
      util.setActivityTimestamp(ts);
    }
  };

  restartIdleTimeout = () => {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = null;
    }
    const minutes = config.maxIdleMinutes - config.idleWarningMinutes;
    this.idleTimeout = setTimeout(this.issueIdleWarning, minutes * 60000);
    this.activityState = "active";
    if (this.authRefreshRequired) {
      this.authRefreshRequired = false;
      this.requestAuthRefresh();
    }
  };

  issueIdleWarning = () => {
    const elapsed = config.maxIdleMinutes - config.idleWarningMinutes;
    const remaining = config.idleWarningMinutes;
    const msg = messages.mkUserIdleAlert(elapsed, remaining);
    this.alert(msg, () => {
      this.markWindowActive();
      this.restartIdleTimeout();
    });
    const callback = () => {
      this.idleTimeout = null;
      this.logOut();
    };
    this.idleTimeout = setTimeout(callback, remaining * 60000);
    this.activityState = "idle";
  };

  onStorage = (e) => {
    switch (e.key) {
      case "sessionId":
        const oldVal = e.oldValue;
        const newVal = e.newValue;
        if (!oldVal && newVal) {
          this.requestSessionInfo();
        } else if (oldVal && !newVal) {
          this.finalizeLogout();
        }
        break;
      case "activity":
        this.onOtherWindowActivity();
        break;
      default:
        break;
    }
  };

  switchTheme = (theme) => {
    this.setState({ theme: themes[theme] ? theme : "default" });
  };

  confirm = ({ message, callback, yesText = "Yes", noText = "No" }) => {
    const confirmDesc = { message, yesText, noText };
    this.setState({
      confirmDesc,
      confirmCallback: callback,
    });
  };

  customConfirm = ({ message, callback, yesText = "Yes", noText = "No" }) => {
    const confirmDesc = { message, yesText, noText };
    this.setState({
      confirmDesc,
      customConfirmCallback: callback,
    });
  };

  simpleConfirm = (message, callback) => {
    this.confirm({ message, callback });
  };

  handleConfirmResponse = (allow) => () => {
    const cb = this.state.confirmCallback;
    this.setState({ confirmCallback: null, customConfirmCallback: null });
    cb(allow);
  };

  handleCustomConfirmResponse = (allow) => () => {
    const cb = this.state.customConfirmCallback;
    this.setState({ customConfirmCallback: null });
    cb(allow);
  };

  alert = (message, callback) => {
    const noCallback = () => { }; // a null callback indicates alert cleared
    this.setState({
      alertMessage: message,
      alertCallback: callback || noCallback,
    });
  };

  handleDismissAlert = () => {
    const cb = this.state.alertCallback;
    this.clearAlert();
    if (cb) {
      cb();
    }
  };

  clearAlert = () => {
    this.setState({ alertCallback: null });
  };

  handleSidebarVisibility = (visibility) => {
    this.setState(() => ({ currStdSidebarvisibility: visibility }));
  };

  handleChatBot = (value) => {
    this.setState({chatBot:value})
  };
  renderContent = () => {
    const r = config.ROUTES;
    return (
      this.state.visible && (
        <div className="App">
          <ErrorBoundary>
          <Router>
            <ConfirmModal
              visible={!!this.state.confirmCallback}
              yesText={this.state.confirmDesc.yesText}
              noText={this.state.confirmDesc.noText}
              callback={this.handleConfirmResponse}
            >
              {this.state.confirmDesc.message}
            </ConfirmModal>
            <InfoModal
              className="alert-modal"
              visible={!!this.state.alertCallback}
              callback={this.handleDismissAlert}
            >
              {this.state.alertMessage}
            </InfoModal>
            <CustomCallback
              visible={!!this.state.customConfirmCallback}
              yesText={this.state.confirmDesc.yesText}
              noText={this.state.confirmDesc.noText}
              callback={this.handleCustomConfirmResponse}
            >
              {this.state.confirmDesc.message}
            </CustomCallback>
            {/* Router */} 
            <Switch>
            <Route
                exact
                path={"/saml2/secure"}
                render={(props) => <LoginShibboleth {...props} full/>}
                isLoading={this.state.loading}
              />



            <Route
                exact
                path={"/saml2/signout"}
                render={(props) => <LogoutShibboleth {...props} full/>}
                isLoading={this.state.loading}
              />
              <Route
                exact
                path={`/login`}
                component={(props) => <Login {...props} full />}
              />

              <Route
                exact
                path={"/player/:userId/:courseId"}
                render={(props) => <PlayerHome {...props} />}
                isLoading={this.state.loading}
              />
              <Route
                exact
                path={`/share/course/:id`}
                render={(props) => <ChapterCourses courseOnly {...props}/>}
                isLoading={this.state.loading}
              />
              <Route
                exact
                path={r.contact}
                render={(props) => <Contact {...props} />}
              />
              <Route
                exact
                path={r.termsOfUse}
                component={(props) => <TermsOfUse terms {...props} />}
              />
              <Route
                exact
                path={config.forgotPasswordUrl}
                render={(props) => <ForgotPassword {...props} />} />
              <Route
                exact
                path="/resetpassword/:id/:token"
                // path="/resetpassword"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path={r.privacyPolicy}
                component={(props) => <TermsOfUse privacy {...props} />}
              />

              {/* <Route
                exact
                path={r.traineeCurriculum}
                render={() => (
                  <Main
                    noSidebar={this.state.currStdSidebarvisibility}
                    hideSidebarVisibility={this.handleSidebarVisibility}
                  />
                )}
                isLoading={this.state.loading}
              /> */}
              {/* <Route
                exact
                path={r.traineeCardViewInner}
                render={() => <Main noSidebar={true} />}
                isLoading={this.state.loading}
              /> */}
              <PrivateRoute
                exact
                path={r.traineeCurriculum}
                component={Main}
                isLoading={this.state.loading}
              />
                <PrivateRoute
                exact
                path={r.subjectList}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.traineeCardViewInner}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.assessments}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.traineeSubscriptionSubject}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.assessmentsLanding}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.assessmentsAttend}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.evaluateForm}
                component={Main}
                isLoading={this.state.loading}
              />
               <PrivateRoute
                exact
                path={r.subscriptionPreview}
                component={Main}
                isLoading={this.state.loading}
              />
              {/* <Route
                exact
                path={r.assessmentsAttend}
              // noSidebar={true}
              // <Main noSidebar={true} />

              // noSidebar={this.props.noSidebar}
              // component={Main}
              // isLoading={this.state.loading}
              > */}
                {/* <Route exact
                  path={r.evaluateForm}>
                  <EvaluateForm />
                </Route> */}
                {/* <Main noSidebar={true} /> */}
              {/* </Route> */}

                <PrivateRoute
                  exact
                  path={r.subjects}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.playback}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.join}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.trainingZone}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.parentZonePage}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.trainingZoneContent}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.externalChannelsContent}
                  component={Main}
                  isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.viewShelfContent}
                  component={Main}
                  isLoading={this.state.loading}
                />

                <PrivateRoute
                exact
                path={r.difficultyLevel}
                component={Main}
                isLoading={this.state.loading}
              />
              {/* <Route
                exact
                path={r.trainingZoneContent}
                isLoading={this.state.loading}
              >
                <Main noSidebar={true} />
              </Route> */}
              {/* <Route
                exact
                path={r.externalChannelsContent}
                isLoading={this.state.loading}
              >
                <Main noSidebar={true} />
              </Route> */}
              {/* <Route
                exact
                path={r.viewShelfContent}
                isLoading={this.state.loading}
              >
                <Main noSidebar={true} />
              </Route> */}
              <PrivateRoute
                exact
                path={r.calendar}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.home}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.chapters}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.curriculum}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.coursePublish}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.courses}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.assessmentList}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.sessions}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.templates}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.modules}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.campuses}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.createTest}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.publishTest}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.rooms}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.questionBank}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.schools}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.knowledgeShelf}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.trainees}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.classes}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.trainers}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.admins}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.roles}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.classLists}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.academicYears}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.grade}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.divisions}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.class}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.syllabus}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.chat}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.profileSettings}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.goi}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.aiConfig}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.aiBot}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.subjectProgress}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.externalChannels}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.viewShelf}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.createShelf}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.attendance}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.menu}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.role}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.courseClassList}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.categories}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.reports}
                component={Main}
                isLoading={this.state.loading}
              />
              {/* <Route
                exact
                path={r.reports}
                isLoading={this.state.loading}
              >
                <Main noSidebar={true} />
              </Route> */}
              <PrivateRoute
                exact
                path={r.configuration}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.chapterallocation}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.promotion}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.zone}
                component={Main}
                isLoading={this.state.loading}
              />
              {/* <PrivateRoute
                  exact
                  path={r.questionAnswer}
                  component={Main}
                  isLoading={this.state.loading}
                /> */}
              <PrivateRoute
                exact
                path={r.language}
              />
                <PrivateRoute
                exact
                path={r.botCanvas}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.canvasDocuments}
                component={Main}
                isLoading={this.state.loading}
              />
                <PrivateRoute
                exact
                path={r.documentQuestions}
                component={Main}
                isLoading={this.state.loading}
              />
                <PrivateRoute
                exact
                path={r.questionDetails}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.tracker}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.attendanceTracker}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                  exact
                  path={r.oldcourses}
                  component={Main}
                  isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.viewerForm}
                component={Main}
                isLoading={this.state.loading}
                />
                <PrivateRoute
                  exact
                  path={r.coursePublishStatus}
                  component={Main}
                  isLoading={this.state.loading}
              />
               <PrivateRoute
                exact
                path={r.surveyGeneral}
                component={Main}
                isLoading={this.state.loading}
              />
               <PrivateRoute
                exact
                path={r.assessmentSummaryDetails}
                component={Main}
                isLoading={this.state.loading}
              />
              <PrivateRoute
                exact
                path={r.publishSurvey}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.surveyCard}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.ContentBuilder}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.achievementConfiguration}
                component={Main}
                isLoading={this.state.loading}
              />

              <PrivateRoute
                exact
                path={r.trainerEvaluate}
                component={Main}
                isLoading={this.state.loading}
              />
               <PrivateRoute
                exact
                path={r.workdayLogs}
                component={Main}
                isLoading={this.state.loading}
              />
            </Switch>
          </Router>
          </ErrorBoundary>
        </div>
      )
    );
  };
  render = () => {
    const context = {
      window: {
        confirm: this.confirm,
        simpleConfirm: this.simpleConfirm,
        customConfirm: this.customConfirm,
        alert: this.alert,
      },
      api: {
        publicRequest: this.publicApiRequest,
        privateRequest: this.privateApiRequest,
        publicRequestRow: this.publicApiRequestRow,
        privateRequestRow: this.privateApiRequestRow,
      },
      access: {
        logIn: this.logIn,
        logOut: this.logOut,
      },
      site: {
        site: this.state.cms,
        fetch: this.fetchSiteDetails,
      },
      user: {
        user: this.state.store.user,
        update: this.updateStore("user"),
        refreshOrgTheme: this.fetchOrganizationTheme,
        handleChatBot: this.handleChatBot
      },
      theme: {
        theme: this.state.theme,
        switchTheme: this.switchTheme,//this.updateStore("theme"),
      },
      labels: this.state.labelCache,
      AIBot: this.state.store.bot,
    };
    return (
      <RemoveUnwantedIframes>
      <Suspense fallback={<Loader width={25} padding="2em"></Loader>}>
        <MsalProvider instance={msalInstance}>
          <WindowContext.Provider value={context.window}>
            <ApiContext.Provider value={context.api}>
              <AccessContext.Provider value={context.access}>
                <SiteContext.Provider value={context.site}>
                  <UserContext.Provider value={context.user}>
                    <ThemeContext.Provider value={context.theme}>
                      <LabelsContext.Provider value={context.labels}>
                        <AIBotContext.Provider value={context.AIBot}>
                          <ThemeProvider theme={themes[this.state.theme]}>
                            <GlobalStyle />
                            {this.renderContent()}
                          </ThemeProvider>
                        </AIBotContext.Provider>
                      </LabelsContext.Provider>
                    </ThemeContext.Provider>
                  </UserContext.Provider>
                </SiteContext.Provider>
              </AccessContext.Provider>
            </ApiContext.Provider>
          </WindowContext.Provider>
        </MsalProvider>
      </Suspense>
      </RemoveUnwantedIframes>
    );
  };
}

export default withLabelsContext(App);
