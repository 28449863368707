import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Row, Col, Badge } from "react-bootstrap";
// , Glyphicon
// import Reorder from "react-reorder";
import "./MatchTheFollowing.css";
import MathLive, {convertLatexToMarkup} from "mathlive";
import  Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
// import * as config from "config";

class MatchTheFollowing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    questions: PropTypes.arrayOf(PropTypes.string).isRequired,
    comments: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered: PropTypes.func.isRequired,
    onReview: PropTypes.func.isRequired,
    onAttempted: PropTypes.func.isRequired,
  };

  initState = {
    userAnswer: [],
  };

  componentDidMount = () => {
    const { match_selection, answers, onAnswered } = this.props;
    // // const randomisedAnswers = [...answers].sort(() => Math.random() - 0.5);
    this.setState({
      userAnswer: match_selection ? match_selection : answers,
    });
    // onAnswered(answers)
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.question !== this.props.question) {
      this.setState(this.initState);
      if (newProps.match_selection) {
        this.setState({ userAnswer: newProps.match_selection });
      } else {
        this.setState({
          userAnswer: newProps.answers,
        });
      }
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.submit !== prevState.submit) {
      const { onAttempted, onAnswered } = this.props;
      const response = this.state.userAnswer;
      onAttempted(true);
      onAnswered(response);
    }
  };

  handleSubmit = (e) => {
    this.setState((s0) => ({ submit: !s0.submit }));
  };

  handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    const value = [...this.state.userAnswer];
    const srcIdx = previousIndex; //value.indexOf(srcId);
    const dstIdx = nextIndex; //value.indexOf(dstId);
    const srcId = this.state.userAnswer[srcIdx];
    if (srcIdx !== -1 && dstIdx !== -1) {
      value.splice(srcIdx, 1);
      value.splice(dstIdx, 0, srcId);
      this.setState({ userAnswer: value });
      this.handleSubmit();
    }
  };

  renderQuestions = () => {
    return (
      <React.Fragment>
        <h3>Questions</h3>
        {this.props.questions.map((item, i) => (
          <div>
            <div className="question-item-container">
              <div key={i} className="question-item">
              {this.props.math_symbol === 1 ? <div dangerouslySetInnerHTML={{ __html: convertLatexToMarkup(item.question)}}/> : item.question}
                {/* {item.question} */}
                <div className="question-code-badge">{item.questionCode}</div>
              </div>
              {/* <Glyphicon className="glyph-container" glyph="arrow-right" /> */}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderAnswers = () => {
    const { userAnswer } = this.state;
    return (
      <div>
        <h3>Choices</h3>
        <>
        {/* <Reorder
          reorderId="my-list"
          reorderGroup="reorder-group"
          component="div"
          placeholderClassName="placeholder-item"
          draggedClassName="dragged-item"
          lock="horizontal"
          holdTime={0}
          touchHoldTime={200}
          mouseHoldTime={100}
          onReorder={this.handleReorder}
          autoScroll={true}
          disabled={this.props.disabled}
          disableContextMenus={true}
          placeholder={<div className="placeholder-item"></div>}
        > */}
          {userAnswer.map((item, i) => {
            return (
              <div>
                <div className="answer-code-badge">{item.answerCode}</div>
                <div key={i} className="answer-item">
                {this.props.math_symbol === 1 ? <div dangerouslySetInnerHTML={{ __html: convertLatexToMarkup(item.answer)}}/> : item.answer ? item.answer : " "}
                  {/* {item.answer ? item.answer : " "} */}
                </div>
              </div>
            );
          })}
        </>
      </div>
    );
  };

  // renderInput = () => (
  //   <div>
  //     <form id="test-answer-form">
  //       <div className="test-answer-input">
  //         <div className="test-answer-line">
  //           <input
  //             // disabled={this.state.matchIdx !== null}
  //             value={this.state.userAnswer}
  //             maxLength={config.maxTestAnswerLength}
  //             placeholder={config.testAnswerPlaceholder}
  //             onChange={this.handleChange}
  //             onBlur={this.handleSubmit}
  //             onKeyPress={this.handleKeyPress}
  //             form="test-answer-form"
  //             autoFocus
  //           />
  //         </div>
  //       </div>
  //     </form>
  //     {/* {this.state.typoFound && (
  //       <div className="test-answer-line-footer">
  //         Please try again...perhaps you misspelled your answer
  //       </div>
  //     )} */}
  //   </div>
  // );
  renderImage = () => {
    return <img src={`data:image/png;base64,${this.props.content}`}/>
  };
renderMedia = () => {
    let currLink =this.props.media_link;
  const currType = this.props.content ? this.props.content_format :"" ;
  let MediaPlayer;
  switch(currType){
    case 1: 
      MediaPlayer = Video;
      break;
    case 0:
      MediaPlayer = Audio;
      break;
   case 2:
        MediaPlayer = Pdf;
        break;
    default:
      MediaPlayer = React.Fragment;
      break;
  
  }
    return(
          <>
             <MediaPlayer 
                className="ov"
                url={currLink}
                mediaId="match-the-following-media-player" 
             />
{this.props.description &&    <p className="paragraph"><div dangerouslySetInnerHTML={{__html: this.props.description}}/></p>}
 </>)
  };
  render = () => (
    <div>
      {this.props.disabled  && <div style={{color: "red"}}>
        {this.props.disabledText}
    </div>}
      <div className="test-question-assesment-title">
        Question {this.props.count}
      </div>
      <div className="test-question-text">
      {this.props.math_symbol === 1 ? <div dangerouslySetInnerHTML={{ __html: convertLatexToMarkup(this.props.question)}}/> : this.props.question}
        {/* {this.props.question} */}
        <div className="question-instructions">
          Drag and rearrange the tiles in Answers column to match the questions
          in the Questions column
        </div>
      </div>
      {/* {this.props.image &&
        <div className="test-question-image">
          <img src={`data:image/png;base64,${this.props.image}`} alt="" />
        </div>
      } */}
      {/* {!this.state.matchFound && this.state.userAnswer && this.handleSubmit()} */}
      <Row>
        <Col md={6} className="question-col">
          {this.renderQuestions()}
        </Col>
        <Col md={6} className="answer-col">
          {this.renderAnswers()}

        </Col>
        <div  className="pdfcss">{this.renderMedia()}</div>
      <div>{(this.props.content_format == 3 && this.props.content) && <img src={`data:image/png;base64,${this.props.content}`}/>}</div>
      </Row>
    </div>
  );
}

export default MatchTheFollowing;
