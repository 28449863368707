import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InputField from 'components/InputField/InputField';
import * as util from "services/util";




class BoundedNumberInput extends PureComponent {
  static propTypes = {
    value:    PropTypes.number,
    min:      PropTypes.number.isRequired,
    max:      PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    min: 0,
  };

  handleChange = (e) => {
    const { min, max, numberType } = this.props;
    const n = numberType && numberType === 'float' ? parseFloat(e.target.value) : util.toInt(e.target.value);
    this.props.onChange(
      isNaN(n)               ? null :
      n < min                ? min  :
      max != null && max < n ? max  :
                               n
    );
  }

  render = () => {
    const {
      value,
      onChange,
      required,
      ...restProps
    } = this.props;
    return (
      <InputField
        type="number"
        value={value != null ? value : ''}
        min={this.props.min}
        max={this.props.max}
        onChange={this.handleChange}
        { ...restProps }
      />
    );
  }
}


export default BoundedNumberInput
