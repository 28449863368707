import * as config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import * as util from 'services/util';
import './TestQuestion.css';
import Comment from './TestQuestion/Comment';
import FillInTheBlank from './TestQuestion/FillInTheBlank';
import MultipleChoice from './TestQuestion/MultipleChoice';





const TestQuestion = ({
  bgColor,
  hidden,
  width,
  ...restProps
}) => {
  const l = util.rgbLightness(bgColor);
  const theme = l < config.minLightThemeBgLightness ? 'dark' : 'light';
  const className = `intest test-${theme}`;

  const style = {};
  if (hidden) {
    style.display = 'none';
  }
  if (width != null) {
    style.fontSize = width > 800 ? `20px` : `15px`; //`${Math.floor(width / 40)}px`;
  }

  const typeE = config.testQuestionTypeE;
  const questionClass =
    restProps.type === typeE.MULTIPLE_CHOICE        ? MultipleChoice :
    restProps.type === typeE.FILL_IN_THE_BLANK      ? FillInTheBlank :
    restProps.type === typeE.SURVEY_MULTIPLE_CHOICE ? MultipleChoice :
    restProps.type === typeE.COMMENT                ? Comment        :
                                                      null;
  return questionClass && (
    <div className={className} style={style}>
      <div className="question-wrapper">
        {React.createElement(questionClass, restProps)}
      </div>
    </div>
  );
}


TestQuestion.propTypes = {
  bgColor: PropTypes.string.isRequired,
  hidden:  PropTypes.bool.isRequired,
  width:   PropTypes.number,
  type:    PropTypes.oneOf([
             config.testQuestionTypeE.MULTIPLE_CHOICE,
             config.testQuestionTypeE.FILL_IN_THE_BLANK,
             config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE,
             config.testQuestionTypeE.COMMENT,
           ]).isRequired,
};


export default TestQuestion
