import React, { PureComponent } from 'react';

import { PlayArrow as PlayArrowIcon } from '../PlayerNew/icons/PlayArrow';
import { Spin      as SpinIcon      } from '../PlayerNew/icons/Spin';
import { Report    as ReportIcon    } from '../PlayerNew/icons/Report';

import './Overlay.css';


export default class Overlay extends PureComponent {
  renderContent = () => {
    const iconProps = {
      className: 'video-overlay-icon',
      width:     40,
      fill:      '#fff',
    };
    const type =
      this.props.error    ? ReportIcon    :
      this.props.loading  ? SpinIcon      :
      this.props.paused && this.props.playArrowVisible
                          ? PlayArrowIcon :
                            null;
    return !type ? null : (
      <span className="video-overlay-inner">
         {React.createElement(type, iconProps)}
      </span>);
  }

  render = () => {
    const className = [
      'video-overlay',
      this.props.paused && this.props.playArrowVisible === 'onHover' ?
        'video-overlay-on-hover' : null,
      this.props.className,
    ].join(' ');
    return (
      <div
        className={className}
        onClick={this.props.onClick}
      >
        {this.renderContent()}
      </div>);
  }
}
