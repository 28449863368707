/**
 * Extend react-responsive-tabs component
 */

import Tabs from "react-responsive-tabs";
import React from "react";
import "./responsive-tabs.scss";
import "react-responsive-tabs/styles.css";
import { rgba } from "polished";
import { IconMenuVertical } from "Icons";
import styled from "styled-components";
import TabTails from "./TabTails";

// Theme
const StyledTabs = styled.div`
  .RRT__container {
    .RRT__tab--selected {
      color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
    }
     .RRT__showmore--selected {
       border-bottom-color: ${({ theme }) => theme.primary};
     }
    .RRT__showmore-list {
      .RRT__tab--selected {
        border-left-color: ${({ theme }) => theme.primary};
      }
    }
  }
`;
const StyledTabsContent = styled.div`
  .RRT__container {
    .RRT__tab--selected {
      color: black;
      border-color: ${({ theme }) => rgba(theme.primary, 0.8)};
      background-color:${({ theme }) => rgba(theme.primary, 0.2)};
      margin-top: 5px;
      width: 17rem;
      display: flex;
      justify-content: center;
      border-bottom: 4px solid ${({ theme }) => rgba(theme.primary, 0.8)};
      padding: 10px;
      gap: 7px;
    }
    .RRT__tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;
      
      }
     .RRT__showmore--selected {
       border-bottom-color: ${({ theme }) => theme.primary};
     }
    .RRT__showmore-list {
      .RRT__tab--selected {
        border-left-color: ${({ theme }) => theme.primary};
      }
    }
  }
`;

const StyledPreviewDescriptionTab = styled.div`
  .class {
    .RRT__container {
      .RRT__tab--selected {
        color: ${({ theme }) => theme.primary};
        border-color: ${({ theme }) => theme.primary};
        
       
      }
       .RRT__showmore--selected {
         border-bottom-color: ${({ theme }) => theme.primary};
       }
       .RRT__showmore-list {
         .RRT__tab--selected {
           border-left-color: ${({ theme }) => theme.primary};
         }
      }
    }
  }
`;

const StyledCourseTabs = styled.div`
  .RRT__container {
    .RRT__tab--selected {
      color: ${({ theme }) => theme.primary};
      // border-color: ${({ theme }) => theme.primary};   //disabled fro ai course
      display: flex;
      justify-content: center;
    }
     .RRT__showmore--selected {
       border-bottom-color: ${({ theme }) => theme.primary};
     }
    .RRT__showmore-list {
      .RRT__tab--selected {
        border-left-color: ${({ theme }) => theme.primary};
      }
    }
  }
`;

export default function ResponsiveTabs(props) {
  const scrollContainer = document.querySelector(".RRT__tabs");
  const StyledTab =  StyledTabs
  const StyledDescriptionTab =  StyledPreviewDescriptionTab
  if (scrollContainer) {
    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });
  }
  if (props.fromMultiPreview) {
    return (
     <StyledDescriptionTab className={props.tight && "tab-size-tight"}> 
        <Tabs
          transform={false}
          showMoreLabel={<IconMenuVertical width="16" height="16" />}
          {...props}
        /> 
    </StyledDescriptionTab>
    );
  }
  else if (props.fromModuleForm){
    return (
      <StyledTabsContent className={props.tight && "tab-size-tight"}> 
         <TabTails
        isQuestionSelectModal={props.isQuestionSelectModal}
        />
        <Tabs
          transform={false}
          showMore={false}
          showMoreLabel={<IconMenuVertical width="16" height="16" />}
          {...props}
        />
     </StyledTabsContent>
     );
  }
  else if(props.fromZonePage ){
    return(
      <StyledTabs className={props.tight && "tab-size-tight"}>
        {window.screen.width > 768 &&
          <TabTails
            fromZonePage={props.fromZonePage}
            fromVieerForm={props.fromViewerForm}
          />}
        <Tabs
          transform={false}
          showMore={false}
          showTails={true}
          {...props}
        />
       </StyledTabs>
    );
  }
  else if(props.fromsubscribtionPreview || props.fromChapterInner ||props.fromZonePage|| props.fromViewerForm ){
    return(
      <StyledTab className={props.tight && "tab-size-tight"}>
      {(props.fromZonePage || props.fromViewerForm ) && 
          <TabTails
            fromZonePage={props.fromZonePage}
            fromViewerForm={props.fromViewerForm}
          />}
        <Tabs
          transform={false}
          showMore={false}
          showTails={true}
          {...props}
        />
       </StyledTab>
    );
  }
  else if(props.isCourse) {
    return (
      <StyledCourseTabs className={"tab-size-tight2"}>
      <TabTails
      isQuestionSelectModal={props.isQuestionSelectModal}
      />
      <Tabs
        transform={false}
        showMore={false}
        showMoreLabel={<IconMenuVertical width="16" height="16" />}
        {...props}
        onChange={props.handleTabChange}
      />
     </StyledCourseTabs>
    )
  }
  else {
    return (
       <StyledTab className={props.tight && "tab-size-tight" }>
        <TabTails
        isQuestionSelectModal={props.isQuestionSelectModal}
        />
        <Tabs
          transform={false}
          showMore={false}
          showMoreLabel={<IconMenuVertical width="16" height="16" />}
          {...props}
        />
       </StyledTab>
    );
  }
}
