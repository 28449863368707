import React from "react";
import classNames from 'classnames';

export default function MainContainer(props) {
	return (
		<div  className={classNames(props.fromChapterInner || props.fromMultiPreview ? (`container-main-mob ${props.className}`) :  (`container-main ${props.className}`)) }>
			{props.children}
		</div>
	);
}
