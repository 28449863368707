import classNames from "classnames";
import { IconChevronRight, IconElective, IconSettings, IconOldCourses } from "Icons";
import * as Icons from "Icons";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import css from "./scss/sidenavicons.module.scss";
import { withApiContext, withLabelsContext, withUserContext } from "context";
import * as util from "services/util";
import Loader from "components/Spinner/Loader";
import * as config from "config";
import { Collapse } from "react-bootstrap";
import { OverlayTooltip } from "components";
import { IconBotMenu } from "Icons";

const StyledSidenav = styled.nav`
  .${css.menu_link} {
    svg {
      fill: ${({ theme }) => theme.primary};
    }

    &:hover,
    &.${css.active} {
      background-color: ${({ theme }) => theme.primary};
      svg {
        fill: #ffffff;
      }
    }
  }
  .${css.menu_link_1} {
    svg {
      fill: ${({ theme }) => theme.primary};
    }

    &:hover,
    &.${css.active} {
      background-color: ${({ theme }) => theme.primary};
      svg {
        fill: #ffffff;
      }
    }
  }
`;

const MenuItem = (props) => {
  const makeMenuLabel = (k) => util.capitalizeWord(props.labelsCtx[k] || k);
  const icon = props.menuItem.logo;
  const Icon = Icons[icon];
  const tipPlacement = document.body.dir === "rtl" ? "left" : "right"
  return (
    <li id={props.menuItem.menu_name}>
      <OverlayTooltip
        placement={tipPlacement}
        id="delete"
        tip={makeMenuLabel(props.menuItem.menu_name)}
      >
        <span
          className={
            props.menuItem.open
              ? classNames(css.submenu_open, css.menu_link)
              : css.menu_link
          }
          onClick={() => {
            props.onMenuItemClick(props.menuItem.menu_id);
          }}
          onMouseEnter={()=>{
            props.onMenuItemClick(props.menuItem.menu_id)
          }}
        >
          {/* {Icon && <Icon className={css.menu_icon} />}{" "} */}
          {Icon ? (
            <div>
              <Icon className={css.menu_icon} />{" "}
              <IconChevronRight className={css.submenu_arrow} width="14" />{" "}
            </div>
          ) : (
            <div>{makeMenuLabel(props.menuItem.menu_name).charAt(0)}</div>
          )}
          {/* {Icon && <Icon className={css.menu_icon} />}{" "} */}
          {/* {props.isMobileOpen && makeMenuLabel(props.menuItem.menu_name)} */}
          <IconChevronRight className={css.submenu_arrow} width="14" />
        </span>
      </OverlayTooltip>
      <Collapse in={props.menuItem.open}>
        <ul className={classNames(css.submenu_open, css.submenu)}>
          {props.children
            .filter((child) => child.hidden !== 1)
            .map((subMenuItem) => {
              const subMenuIcons = subMenuItem.logo;
              const SubIcon = Icons[subMenuIcons];
              // if (!SubIcon) {
              //   console.log(subMenuItem.menu_name, subMenuItem.screen_id);
              // }
              return (
                <li key={subMenuItem.menu_id} id={subMenuItem.menu_name}>
                  <NavLink
                    exact
                    to={String(subMenuItem.route)}
                    // to="/chapters"
                    className={css.menu_link_1}
                    onClick={() =>
                      props.onMenuSelect(
                        subMenuItem.menu_id,
                        subMenuItem.pb_report_id,
                        subMenuItem.report_type
                      )
                    }
                  >
                    <>
                      <OverlayTooltip
                        placement={tipPlacement}
                        id="delete"
                        tip={makeMenuLabel(subMenuItem.menu_name)}
                      >
                        {SubIcon ? (
                          <SubIcon />
                        ) : (
                          <div>
                            {makeMenuLabel(subMenuItem.menu_name).charAt(0)}
                          </div>
                        )}
                      </OverlayTooltip>
                    </>
                    {/* {makeMenuLabel(subMenuItem.menu_name)} */}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </Collapse>
    </li>
  );
};

class SidenavIcons extends React.Component {
  constructor(props) {
    super(props);

    this.subMenu = React.createRef();

    this.state = {
      isSubjectsMenuOpen: false,
      isShown:false,
      sidebarOpen:false,
      isViewShelfMenuOpen: false,
      loading: true,
      menu: [],
      subMenuOpen: false,
      sideDataUpdate: false,
      isOldCoursesMenuOpen: false,
    };
  }

  componentDidUpdate = (_prevProps) => {
    if (_prevProps.sidenavData.length !== this.props.sidenavData.length) {
      this.trackLoadingStatus();
    }
  };
  componentDidMount = () => {
    this.trackLoadingStatus();
  };

  trackLoadingStatus = () => {
    if (this.props.sidenavData.length !== 0 || !this.state.sideDataUpdate) {
      this.setState({
        menu: this.props.sidenavData,
        loading: this.props.sidenavLoading,
        sideDataUpdate: true,
      });
    }
  };

  handleMenuItemClick = (menuId) => {
    const menu = this.state.menu.map((menuItem) =>
      menuItem.has_children === 1
        ? {
            ...menuItem,
            open: menuItem.menu_id === menuId ? !menuItem.open : false,
          }
        : menuItem
    );
    this.setState({ menu });
  };
  sidebarhover =() =>{
   
    this.setState({sidebarOpen:true})
    this.props.burgerMenuAction(true)
    
   }
   handleshow=()=>{
     this.setState({isShown:true})
   }
   handleshow=()=>{
     this.setState({isShown:false})
   }
  render = () => {
    const makeMenuLabel = (k) =>
      util.capitalizeWord(this.props.ctx_labels.singular[k] || k);
    const parentMenuItems = this.state.menu.filter(
      (mn) => mn.parent_id === 0 && mn.hidden !== 1
    );
    const childMenuItems = this.state.menu.filter(
      (test) => test.parent_id !== 0
    );
    const tipPlacement = document.body.dir === "rtl" ? "left" : "right"
    return this.state.loading ? (
      this.props.ctx_user.user.newUser ? (
        <div style={{ textAlign: "center", paddingTop: "150px" }}>
          <p>No menu items are available.</p>
        </div>
      ) : (
        <Loader active />
      )
    ) : (
      <StyledSidenav className={css.sidenav}>
        <ul
         onMouseEnter={()=>{
          this.sidebarhover()
          }}
        >
          {parentMenuItems.map((menuItem) => {
            const icon = menuItem.logo;
            const Icon = Icons[icon];
            return menuItem.has_children === 0 ? (
              <li key={menuItem.menu_id} id={menuItem.menu_name}>
                {menuItem.screen_id === config.memberSubmenuE.SUBJECT ? (
                  <span
                    className={classNames(
                      css.menu_link,
                      this.props.isSubjectsMenuOpen && css.active
                    )}
                    onClick={this.props.toggleSubjectsMenu}
                  >
                    {<IconElective className={css.menu_icon} />}{" "}
                    {/* {makeMenuLabel(menuItem.menu_name)} */}
                    <IconChevronRight
                      className={css.submenu_arrow}
                      width="14"
                    />
                  </span>
                ) : menuItem.screen_id === config.memberSubmenuE.BOTS ? (
                  <span
                    className={classNames(
                      css.menu_link,
                      this.props.isViewBotMenuOpen && css.active
                    )}
                    onClick={this.props.toggleBotMenu}
                  >
                    {<IconBotMenu className={css.menu_icon} />}{" "}
                    {/* {makeMenuLabel(menuItem.menu_name)} */}
                    <IconChevronRight
                      className={css.submenu_arrow}
                      width="14"
                    />
                  </span>
                ) : menuItem.screen_id === config.memberSubmenuE.OLD_COURSES ||
                  this.props.ctx_user.user.enableOldCourses ? (
                  <span
                    className={classNames(
                      css.menu_link,
                      this.props.isViewOldCoursesMenuOpen && css.active
                    )}
                    onClick={this.props.toggleOldCoursesMenu}
                  >
                    {<IconOldCourses className={css.menu_icon} />}{" "}
                    {/* {makeMenuLabel(menuItem.menu_name)}	 */}
                    <IconChevronRight
                      className={css.submenu_arrow}
                      width="14"
                    />
                  </span>
                ) : menuItem.screen_id === config.memberSubmenuE.BOTS ? (
                  <span
                    className={classNames(
                      css.menu_link,
                      this.props.isViewBotMenuOpen && css.active
                    )}
                    onClick={this.props.toggleBotMenu}
                  >
                    {<IconBotMenu className={css.menu_icon} />}{" "}
                    {makeMenuLabel(menuItem.menu_name)}
                    <IconChevronRight
                      className={css.submenu_arrow}
                      width="14"
                    />
                  </span>
                ) : menuItem.screen_id ===
                  config.memberSubmenuE.STUDENT_VIEWSHELF ? (
                  <span
                    className={classNames(
                      css.menu_link,
                      this.props.isViewShelfMenuOpen && css.active
                    )}
                    onClick={this.props.toggleShelfMenu}
                  >
                    {<IconElective className={css.menu_icon} />}{" "}
                    {/* {makeMenuLabel(menuItem.menu_name)} */}
                    <IconChevronRight
                      className={css.submenu_arrow}
                      width="14"
                    />
                  </span>
                ) : (
                  <NavLink
                    exact
                    to={String(menuItem.route?.trim())}
                    onClick={() => this.props.onMenuSelect(menuItem.menu_id)}
                    className={css.menu_link}
                    activeClassName={css.active}
                  >
                    {/* {Icon && <Icon className={css.menu_icon} />} */}
                    {/* {this.props.isMobileOpen && makeMenuLabel(menuItem.menu_name)} */}
                    <>
                      <OverlayTooltip
                        placement={tipPlacement}
                        id="delete"
                        tip={makeMenuLabel(menuItem.menu_name)}
                      >
                        {Icon ? (
                          <Icon className={css.menu_icon} />
                        ) : (
                          <div>
                            {makeMenuLabel(menuItem.menu_name).charAt(0)}
                          </div>
                        )}
                      </OverlayTooltip>
                    </>
                  </NavLink>
                )}
              </li>
            ) : (
              <MenuItem
                onMenuItemClick={this.handleMenuItemClick}
                labelsCtx={this.props.ctx_labels.singular}
                key={menuItem.menu_id}
                menuItem={menuItem}
                onMenuSelect={this.props.onMenuSelect}
                children={childMenuItems.filter(
                  (subMenuItem) => menuItem.menu_id === subMenuItem.parent_id
                )}
                isMobileOpen={this.props.isMobileOpen}
              />
            );
          })}
          <li>
            <OverlayTooltip
              placement={tipPlacement}
              id="delete"
              tip="Profile Settings"
            >
              <NavLink
                exact
                to={config.ROUTES.profileSettings}
                // onClick={() => this.props.onMenuSelect(menuItem.menu_id)}
                className={css.menu_link}
                activeClassName={css.active}
              >
                <IconSettings className={css.menu_icon} />
                {this.props.isMobileOpen && `${"Profile Settings"}`}
              </NavLink>
            </OverlayTooltip>
          </li>
        </ul>
      </StyledSidenav>
    );

  };
}

export default withLabelsContext(withApiContext(withUserContext(SidenavIcons)));
