// import OverlayTrigger from 'components/OverlayTrigger';
import * as config from 'config';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';





const OverlayTooltip = ({
  tip,
  id,
  fromTestSec,
  disable,
  placement = 'bottom',
  trigger = ['hover', 'click'],
  disabled = fromTestSec ? disable : false,
  children,
}) => {
  const container =
    document.fullscreenElement    ||
    document.msFullscreenElement  ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement;
  return (
    
    <OverlayTrigger
      placement={placement}
      overlay={!disabled ? <Tooltip id={id}>{tip}</Tooltip> : <span></span>}
      overlayHidden={disabled}
      trigger={trigger}
      delayShow={config.tooltipDelayMsecs}
      container={container}
    >
      {children}
    </OverlayTrigger>
  );
}


export default OverlayTooltip
