import AsyncSelect from 'components/Select/AsyncSelect';
import { withApiContext, withLabelsContext } from "context";
import React, {PureComponent} from "react";

class TaggedCourseSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
          refresh: false,
        };
      }

      componentDidMount = () => {
        if (this.props.categoryId !== "" && this.props.curriculumId !== null){
          this.setState({ refresh: true })
        }
      }
      componentDidUpdate = (prevProps) => {


        if (this.props.categoryId !== "" && this.props.curriculumId !== null && this.props.Id !== null && (prevProps.categoryId !== this.props.categoryId ||  prevProps.curriculumId !== this.props.curriculumId)){
          console.log("enter")
          this.setState({ refresh: true });
        }
       };

    fetch = async () => {
        if((this.state.refresh)){
        const { rsp } = await this.props.ctx_api.privateRequest({
          cmd: "get_tagged_courses",
          args: {
            curriculumId: this.props.curriculumId,
            categoryId: this.props.categoryId,
            Id:this.props.Id
          },
        });
        this.setState({ refresh: false })
        return rsp.map(({ course_id, course_name }) => ({
          label: course_name,
          value: course_id,
        }));
    }};


    render = () => (
        <AsyncSelect
            {...this.props}
            optionName={this.props.ctx_labels.singular.course}
            fetch={this.fetch}
            unselectedValue=""
            refresh={this.state.refresh}
            multi
        />
    )
} 


export default withApiContext(withLabelsContext(TaggedCourseSelect));