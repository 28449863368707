import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import InputField from 'components/FormField';
import './MultipleChoice.css';

function AnswerList({ answers, handleEdit, handleAdd, props, handleRemove }) {
  const choices = answers.map((answer, index) => (
    <Row key={index} style={{ display: "flex", flexDirection: "row" }}>
      <Col>
        <div className="input-field-container">
          <InputField
            name={`multipleChoice${index}`}
            placeholder="Enter the Choice"
            onChange={(event) => handleEdit(event, index)}
            value={answer.choice}
          // className={answer.choice ? "with-close-icon" : ""}
          />
          {answer && (
            <span
              className="close-icon"
              onClick={() => handleRemove(index)}
            >
              &#x2716; {/* This is a Unicode character for the "X" symbol */}
            </span>
          )}
        </div>
      </Col>
      {/* <Col>
        <Button onClick={() => handleRemove(index)}>Remove</Button>
      </Col> */}
    </Row>
  ));

  return (
    props.isAnswerType == 3 &&
    <div>
      {choices}
      <Row>
        <Col>
          <Button onClick={handleAdd}>
            Add Choice
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function MultipleChoiceAnswers(props) {
  const [addedAnswers, setAddedAnswers] = useState([]);
  const [highestNegativeAnswerId, setHighestNegativeAnswerId] = useState(
    props.isAnswerType !== undefined ? -2 : -1
  );
  const [modified, setModified] = useState(props.modified)

  useEffect(() => {
    if (modified) {
      let highestChoiceId = 0;
      for (const item of props.value) {
        if (item.choice_id > highestChoiceId) {
          highestChoiceId = item.choice_id;
        }
      }
      setAddedAnswers(props.value);
      setHighestNegativeAnswerId(highestChoiceId);
      setModified(false)
    }
    else if (props.isAnswerType == 3 && !modified && !props.status) {
      const newAnswers = [
        { choice_id: 0, choice: "" }
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(0);
    }
    else if (props.isAnswerType == 3 && !modified && props.status) {
      let highestChoiceId = 0;
      for (const item of props.value) {
        if (item.choice_id > highestChoiceId) {
          highestChoiceId = item.choice_id;
        }
      }
      setAddedAnswers(props.value);
      setHighestNegativeAnswerId(highestChoiceId);
    }

    else if (props.isAnswerType == "" && !modified) {
      const newAnswers = [
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(1);
    }
    else if (props.isAnswerType == "0" && !modified) {
      const newAnswers = [
        { choice_id: 0, choice: "Yes" },
        { choice_id: 1, choice: "No" }
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(1);
    }
    else if (props.isAnswerType == "1" && !modified) {
      const newAnswers = [
        { choice_id: 0, choice: "Agree" },
        { choice_id: 1, choice: "Disagree" }
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(1);
    }
    else if (props.isAnswerType == "2" && !modified) {
      const newAnswers = [
        { choice_id: 0, choice: "True" },
        { choice_id: 1, choice: "False" }
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(1);
    }



  }, [props.isAnswerType]);

  const handleRemove = (indexToRemove) => {
    const updatedAnswers = addedAnswers.filter((_, index) => index !== indexToRemove);
    updatedAnswers.forEach((answer, index) => {
      answer.choice_id = index;
    });
    setAddedAnswers(updatedAnswers);
    // Find the highest choice_id after removal
    let highestChoiceId = -1;
    updatedAnswers.forEach((answer) => {
      if (answer.choice_id > highestChoiceId) {
        highestChoiceId = answer.choice_id;
      }
    });
    setHighestNegativeAnswerId(highestChoiceId);
    props.onChange(updatedAnswers);
  };

  const handleEdit = (event, index) => {
    const updatedAnswers = [...addedAnswers];
    updatedAnswers[index].choice = event.value;
    setAddedAnswers(updatedAnswers);
    props.onChange(updatedAnswers);
  };

  const handleAdd = () => {
    const newAnswerId = highestNegativeAnswerId + 1;
    const updatedAnswers = [...addedAnswers, { choice_id: newAnswerId, choice: "" }];
    setAddedAnswers(updatedAnswers);
    setHighestNegativeAnswerId(newAnswerId);
    props.onChange(updatedAnswers);
  };

  return (
    <div>
      <AnswerList
        answers={addedAnswers}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        props={props}
        handleRemove={handleRemove}
      />
    </div>
  );
}

export default MultipleChoiceAnswers;
