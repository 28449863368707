import { Button,  SearchField, Select } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import css from "./scss/subjects-menu.module.scss";
import { withUserContext, withApiContext } from "context";
import * as config from "config";
import classNames from "classnames";
import MainContext from "pages/Main/MainContext";
import { options } from "pages/Components/Meet/config/jitsiIframe";
import FormField from "components/FormField";
import { Value } from "react-select";

const StyledBotMenu = styled.div`
  .${css.subject_section_title} {
    color: ${({ theme }) => theme.primary};
  }
  .${css.featured_item}, .${css.subject_section_list} {
    color: ${({ theme }) => theme.color2};
  }
  .${css.image}, .${css.image_placeholder} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );

    > .${css.placeholder_text} {
      color: ${({ theme }) => theme.button.primary.text};
    }
  }
`;

class BotMenu extends React.Component {
  static contextType = MainContext;
    constructor(props) {
      super(props)

      this.state = {
        filteredList: props.botList.data,
        showMore: false,
        botservers:[],
        accId: null,
        details:'',
      };

      this.showMore = this.showMore.bind(this);
      this.setMenuRef = this.setMenuRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);

    }

  componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = (_prevProps) => {

    if(_prevProps.botList !== null || _prevProps.botList !== []){
    if (
      _prevProps.botList.length !==
      this.props.botList.length
    ) {
      
      this.setState(() => ({
        filteredList: this.props.botList.data ,
      }));
    }}
    
    
  };

  setMenuRef(node) {
    this.menuRef = node;
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      if (this.state.showMore && event.clientX > 707) {
        this.props.toggleBotMenu();
      }
      if (!this.state.showMore) {
        this.props.toggleBotMenu();
      }
    }
  }

  filterList(text) {
    if (text !== "" ) {
      let dataList = [];
      let allList = this.props.botList.data;
      allList.forEach((botdata) => {
        if (
          botdata.name
            .toLowerCase()
            .indexOf(text.toLowerCase()) !== -1
        ) {
          dataList.push(botdata);
        }
      });
      this.setState({
        filteredList: dataList,
      });
    } 
    
    else {
      this.setState({
        filteredList: this.props.botList.data,
      });
    }
  }


  showMore() {
    this.setState({
      showMore: true,
    });
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    });
    //  this.setState({accId: Value})
    this.getAiServerdata(value)
    // console.log(this.state.accId)
  }
  

  getAiServerdata = (accountId) => {
    this.setState({accId:this.state.details})
    // console.log(this.state.accId);
    return new Promise((resolve, reject) => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_aibots_servers",
          args: {
            accountId: accountId,
         
          },
        })
        .then(({ rsp }) => {
          this.setState({ botservers : JSON.parse(rsp)});
          resolve();
          // console.log(JSON.parse(rsp));
        })
        
        .catch(() => {
          reject();
        });
    });
  };
  handlebot=()=>{
    this.props.handlebot1(true)
  }

  render(){
   
    const botData = this.state.botservers.length !== 0 ? this.state.botservers.data : this.state.filteredList 
    const botDetails = this.props.botList.data === null ? [] : botData
    const list = botDetails?.map((botdata)=>(
      <Link
        to={{
          pathname: config.ROUTES.botCanvas,
          data:
            botdata.status === "active"
              ?{...botdata,  accountId:this.state.details ? this.state.details : 55
              }

              : {
                  bId: botdata.bid,
                  name: botdata.name,
                  canvasId: botdata.canvas.canvas_id,
                  accountId:this.state.details ? this.state.details : 55
                },
        }}
        onClick={this.props.toggleBotMenu}
      >
        <div className={css.subject_section}>
          <div className={classNames(css.subject_section_list)}>
            {botdata.name}
           
          </div>
        </div>
       
      </Link>
    ));
    // if(botDetails.length === 0 ) {
    //   return(
    //     <div className={classNames(css.shelf_Completed)}>
    //       Sorry, there are no bots allocated to you
    //     </div>
    //     )
    // }
        
      // const botname = this.props.bot1.map(obj=>(obj.name))
      // const botnames = [];
      // this.props.bot1.forEach(obj => {
      //   botnames.push(obj.name);
      // });
     
   
    return(
        <StyledBotMenu
        onMouseEnter={this.handlebot}
        ref={this.setMenuRef}
        id="subject_more"
        className={!this.state.showMore ? css.wrapper : css.wrapper_more}
      >
        
        {!this.state.showMore && (
          <div className={css.featured}>
            {botDetails && botDetails.slice(0, 7).map((botdata) => {
              return (
                <Link
                  to={{
                    pathname: config.ROUTES.botCanvas,
                    data:
                      botdata.status === "active"
                        ? botdata
                        : {
                            bId: botdata.bid,
                            name: botdata.name,
                            canvasId: botdata.canvas.canvas_id,
                           
                  
                          },
                  }}
                  className={classNames(css.featured_item)}
                  onClick={this.props.toggleBotMenu}
                >
                  {botdata.bot_logo && (
                      <img
                        className={css.image}
                        src={
                          botdata.bot_logo 
                          ? botdata.bot_logo.match(/^data:image\/\w+;base64,/) !== null 
                          : botdata.bot_logo  
                        ? `data:image/png;base64,${botdata.bot_logo}`
                        : ""
                        }
                        alt=""
                      />
                    )}
                  {!botdata.bot_logo && (
                    <div className={css.image_placeholder}>
                      <div className={css.placeholder_text}>
                        {botdata.name.charAt(0).toUpperCase()}
                      </div>
                    </div>
                  )}
                  <div>{botdata.name}</div>
                </Link>
              );
            })}
            {botDetails && 7 < botDetails.length && (
              <div className="text-center">
                <Button variant="link" size="sm" onClick={this.showMore}>
                  Show More
                </Button>
              </div>
            )}
          </div>
        )}
        {this.state.showMore && (
          <div className={css.all}>
            <div className={css.search_field}>
              <SearchField
                placeholder={`Search for ${this.props.ctx_labels.plural.aibot}`}
                onKeyUp={(e) => this.filterList(e.currentTarget.value)}
                overLightBg
              />
            </div>
            <div>
          
              <FormField
              type={Select}
              name="details"
              value={this.state.details}
              onChange={this.handleChange}
              options={this.props.bot1}
             
              ></FormField>
            </div>

            <div className={css.subject_list}>{list}</div>
          </div> 
        )}
      </StyledBotMenu>
    )
   }
}

export default
withApiContext(
  withUserContext(
  withLabelsContext(BotMenu)
))