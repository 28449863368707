import FormField from "components/FormField";
import HelpfulButton from "components/Button/HelpfulButton";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  withFrameContext,
  withLabelsContext,
  withWindowContext,
  withUserContext,
  withAnalyticsContext
} from "context";
import {
  ResponsiveTabs,
  Breadcrumb,
  JustifyBetween,
  PageTitle,
  Button,
} from "components";
import * as messages from "messages";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { ButtonToolbar, Card } from "react-bootstrap";
import ReactDOM from "react-dom";
import * as util from "services/util";
import * as config from "config";
import "./Form.css";
import { CircleAddNew, IconSaveNew,IconCancelNew, IconCircleCancelRed, IconDownArrow, IconGenerate, IconPublishTestNew, IconPublishNew, IconStar} from "Icons";
import MediaQuery from "react-responsive";

class EntityForm extends PureComponent {
  /* Assumes the entity prop does not change during the add/update */

  constructor(props) {
   
    super(props);
    this.state = this.mkInitState(props);
    this.pendingValidationCount = 0;
    this.submitPending = false;
    this.exitPending = false;

    this.formElt = null;
    this.inputRefs = {};
    // this.state = {
    //   isOpen:false
    // }
  }

  static propTypes = {
    className: PropTypes.string,
    entity: PropTypes.object,
    name: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    actionNoun: PropTypes.string.isRequired,
    fields: PropTypes.objectOf(
      PropTypes.shape({
        init: PropTypes.any,
        optional: PropTypes.bool,
        validators: PropTypes.shape({
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          asyncOnBlur: PropTypes.func,
        }),
      })
    ).isRequired,
    fetch: PropTypes.func,
    submit: PropTypes.func.isRequired,
    nonSubmitable: PropTypes.bool,
    headerRenderer: PropTypes.func,
    fieldsRenderer: PropTypes.func,
    tabRenderers: PropTypes.objectOf(PropTypes.func),
    onTabChange: PropTypes.func,
    onClear: PropTypes.func,
    onActionDone: PropTypes.func.isRequired,
    reset: PropTypes.bool.isRequired,
    clearOnSubmitError: PropTypes.bool.isRequired,
    exitOnException: PropTypes.bool.isRequired,
    tipsDisabled: PropTypes.bool.isRequired,
    forceModified: PropTypes.bool.isRequired,
    isTeacherForm: PropTypes.bool,
    altButton: PropTypes.func,
    isTestForm: PropTypes.bool,
    menu: PropTypes.array,
  };

  static defaultProps = {
    reset: false,
    clearOnSubmitError: false,
    exitOnException: false,
    tipsDisabled: false,
    forceModified: false,
    menu: [],
  };

  componentDidMount = () => {
    const { highlight, highlightIntro } = this.props.ctx_user.user;
    this.props.ctx_frame.setSideMenuSelectGate(this.allowSideMenuSelect);
    if (this.updating()) {
      this.doFetch();
    }
    if (highlight && highlightIntro) {
      this.setState({ stepsEnabled: true });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      (this.props.entity &&
        nextProps.entity &&
        nextProps.entity.id !== this.props.entity.id) ||
      (nextProps.reset && !this.props.reset)
    ) {
      if (this.updating(nextProps)) {
        this.doFetch(nextProps);
      } else {
        this.clearForm(nextProps);
      }
    }
  };

  componentWillUnmount = () => {
    this.props.ctx_frame.setSideMenuSelectGate(null);
  };

  getPermission = (props) => {
    const { menu, selectedMenuId, action } = this.props;
    if (menu.length === 0 && !selectedMenuId) {
      return true;
    }
    const allowedAction =
      action === "add" || action === "switch" || action === "change" || action === "update";
    const menuPermission = selectedMenuId
      ? menu[selectedMenuId].permissions
      : null;
      
    const permission = menuPermission ? menuPermission[props] : null;
    return !permission && !allowedAction ? false : true;
  };

  allowSideMenuSelect = (key) => new Promise(this.confirmCancelIfModified(key));

  adding = (props = this.props) => props.action === "add";

  updating = (props = this.props) => props.action === "update";

  getNameLabel = (props = this.props) =>
    props.ctx_labels.singular[props.name] || props.name;

  getAllFieldNames = (props = this.props) =>
    Object.getOwnPropertyNames(props.fields);

  allFieldsOptional = (props = this.props) =>
    this.getAllFieldNames(props).every((name) => props.fields[name].optional);

  fieldBlank = (name, values, props) => {
    const v = values[name];
    const { isBlank } = props.fields[name];
    if (isBlank) {
      return isBlank(v);
    }
    return v == null || v === "" || (v.constructor === Array && v.length === 0);
  };

  fieldsValid = () =>
    Object.values(this.state.validations).every((v) => v === true);

  updateValidation =
    (name, validation, hidden = false) =>
    (s) => {
      const validations = {
        ...s.validations,
        [name]: validation,
      };
      return hidden
        ? { validations, hiddenValidation: name }
        : s.hiddenValidation === name
        ? { validations, hiddenValidation: null }
        : { validations };
    };

  mkInitValues = (props) => {
    const values = {};
    this.getAllFieldNames(props).forEach((name) => {
      values[name] = props.fields[name].init;
    });
    return values;
  };

  mkValidations = (values, props) => {
    const validations = {};
    Object.getOwnPropertyNames(values).forEach((name) => {
      const { validation } = this.syncValidate(name, values, props);
      validations[name] = validation;
    });
    return validations;
  };

  mkStateFromValues = (values, props) => {
    const validations = this.mkValidations(values, props);
    return {
      values,
      validations,
      hiddenValidation: null,
      submitting: false,
      modified: props.forceModified,
      activeTabKey: 0,
      isOpen:false,
      l_save:"",
      stepsEnabled: false,
      initialStep: 0,
    };
  };

  mkInitState = (props) =>
    this.mkStateFromValues(this.mkInitValues(props), props);

  clearForm = (props = this.props, valuesOnly = false) => {
    const s = valuesOnly
      ? { values: this.mkInitValues(props) }
      : this.mkInitState(props);
    this.setState(s, () => {
      // Ensure tags are cleared after initial state set
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          tags: []
        }
      }));
  
      if (this.formElt) {
        this.formElt.scrollTop = 0;
      }
  
      if (props.onClear) {
        props.onClear();
      }
    });
  };

  clearFormValues = (props = this.props) => {
    const valuesOnly = true;
    this.clearForm(props, valuesOnly);
  };

  doFetch = (props = this.props) => {
    // Not every update requires a fetch (e.g., when updating user preferences)
    if (props.fetch) {
      props.fetch(props.entity).then(this.handleFetchResponse);
      //.catch(this.handleApiFailure);
    }
  };

  handleFetchResponse = (values) => {
    this.setState(this.mkStateFromValues(values, this.props));
  };

  handleApiFailure = (err) => {
    const { exitOnException, onActionDone } = this.props;
    const callback = exitOnException ? () => onActionDone("canceled") : null;
    this.props.ctx_window.alert(messages.mkEntityFormErrorAlert(), callback);
  };

  syncValidate = (name, values, props) => {
    if (this.fieldBlank(name, values, props)) {
      return { validation: props.fields[name].optional || null };
    }
    const { validators } = props.fields[name];
    if (validators) {
      if (validators.onChange) {
        return { validation: validators.onChange(values) };
      }
      if (validators.onBlur) {
        return { validation: validators.onBlur(values), hidden: true };
      }
    }
    return { validation: true };
  };

  syncDepValidate = (name, values, props) => {
    const { depValidators } = props.fields[name];
    const validations = {};

    if (depValidators) {
      if (depValidators.onBlur && !util.objectEmpty(depValidators.onBlur)) {
        Object.getOwnPropertyNames(depValidators.onBlur).forEach((name) => {
          validations[name] = {
            depValidation: depValidators.onBlur[name](values),
            depHidden: true,
          };
        });

        // return { validation: validators.onBlur(values), hidden: true };
      }
    }

    return validations;
  };

  asyncValidate = (name, values, props) => {
    const { validators } = props.fields[name];
    if (!validators || !validators.asyncOnBlur) {
      return;
    }
    this.pendingValidationCount = this.pendingValidationCount + 1;
    validators
      .asyncOnBlur(values)
      .then(this.handleAsyncValidateResponse(name))
      .catch(this.handleApiFailure)
      .finally(this.finalizeAsyncValidate);
  };

  handleAsyncValidateResponse = (name) => (validation) => {
    this.setState(this.updateValidation(name, validation));
  };

  finalizeAsyncValidate = () => {
    this.pendingValidationCount = this.pendingValidationCount - 1;
    if (this.pendingValidationCount === 0 && this.submitPending) {
      this.submitPending = false;
      this.doSubmit();
    }
  };

  handleChange = ({ name, value, updater, metadataOnly }) => {
    this.setState((s0, props) => {
      const deltaVs = updater ? updater(s0.values, props) : { [name]: value };
      const values = {
        ...s0.values,
        ...deltaVs,
      };
      const { validation, hidden } = this.syncValidate(name, values, props);
      let updatedValidation = this.updateValidation(
        name,
        validation,
        hidden
      )(s0);

      const depValidations = this.syncDepValidate(name, values, props);
      if (!util.objectEmpty(depValidations)) {
        Object.getOwnPropertyNames(depValidations).forEach((fieldName) => {
          const { depValidation, depHidden } = depValidations[fieldName];
          updatedValidation = this.updateValidation(
            fieldName,
            depValidation,
            depHidden
          )(updatedValidation);
        });
      }

      return {
        values,
        modified: s0.modified || !metadataOnly,
        ...updatedValidation,
      };
    });
  };

  handleClick = ({ name, updater }) => {
    if (updater) {
      updater(this.state.values).then((value) =>
        this.mkStateFromValue(name, value)
      );
    }
  };
  openDropdown =()=>{
    this.setState({isOpen:true})
  }

  mkStateFromValue = (name, value) => {
    this.setState((s0, props) => {
      const deltaVs = value;
      const values = {
        ...s0.values,
        ...deltaVs,
      };
      const { validation, hidden } = this.syncValidate(name, values, props);
      return {
        values,
        modified: s0.modified,
        ...this.updateValidation(name, validation, hidden)(s0),
      };
    });
  };

  handleBlur = (name) => (e) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    this.setState((s0, props) => {
      if (!s0.submitting && s0.validations[name] === true) {
        this.asyncValidate(name, s0.values, props);
      }
      return s0.hiddenValidation === name ? { hiddenValidation: null } : null;
    });
  };

  handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.ctx_window.simpleConfirm(
      messages.mkFormClearConfirm(),
      this.handleClearResponse
    );
  };

  handleClearResponse = (allow) => {
    if (allow) {
      this.clearForm();
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.confirmCancelIfModified()();
    
  };

  confirmCancelIfModified = (key) => (andThen) => {
    
    const handleResponse = this.handleConfirmCancelResponse(key, andThen);
    if (!this.state.modified) {
      handleResponse(true);
    } else {
      this.props.ctx_window.simpleConfirm(
        !this.props.isTestGeneration ? 
        messages.mkEntityFormCancelConfirm(
          this.getNameLabel(),
          this.props.actionNoun
        ) : messages.mkChatCancelConfirm(),
        handleResponse
      );
    }
  };

  handleConfirmCancelResponse = (key, andThen) => (allow) => {
    if (allow) {
      const reason = !key
        ? "canceled"
        : String(key).indexOf(".") === -1
        ? "entityChanged"
        : "actionChanged";
      this.props.onActionDone(reason);
    }
    if (andThen) {
      andThen(allow);
    }
  };

  handleUnallocate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.confirmUnallocateIfModified()();
  };

  confirmUnallocateIfModified = (key) => (andThen) => {
    
    const handleResponse = this.handleConfirmUnallocateResponse(key, andThen);
    this.props.ctx_window.simpleConfirm(
      "Are you sure you want to unallocate chapter?",
      handleResponse
    );
  };

  handleConfirmUnallocateResponse = (key, andThen) => (allow) => {
    if (allow) {
      // const reason = 'unallocate';
      // this.props.onActionDone(reason);
      this.doUnallocate();
    }
    if (andThen) {
      andThen(allow);
    }
  };

  doUnallocate = () => {
    this.setState({ submitting: true });
    this.props
      .unallocate(this.state.values, this.props.entity, this.props.classId)
      .then(this.handleUnallocateResponse)
      .catch(this.handleApiFailure)
      .finally(this.finalizeSubmit("Un-allocat"));
  };

  handleUnallocateResponse = () => {
    this.exitPending = true;
  };
  handlePublish = ()=>{
    this.handleSubmit()
  }
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.pendingValidationCount === 0) {
      this.doSubmit();
    } else {
      this.submitPending = true;
    }
  };
  handleSavesubmit = async (isSave, e) => {
    console.log("Event object:", e);
    if (e && typeof e.preventDefault === "function") {
        e.preventDefault();
        e.stopPropagation();
    }
    try {
        await this.props.sendDataToCreateShelf(isSave);
        this.handleSubmit(e);
    } catch (error) {
       
        console.error("Error in sendDataToCreateShelf:", error);
    }
};
  
//   handleSubmitKShelf = () => {
//     return (e)=>{
//     e.preventDefault();
//     e.stopPropagation();
 
//     if (this.pendingValidationCount === 0) {
//       this.doSubmit();
//     } else {
//       this.submitPending = true;
//     }
// }
//   };

  updateTrainerMilestones = (id, name) => {
    const mileStone = {
      event_type: this.props.action,
      event_subject: this.props.name,
      event_id: +id,
      event_name: name,
      event_date: new Date().toISOString(),
    };
    this.props.ctx_analytics.update("trainerMilestones")(mileStone);
  };

  doSubmit = () => {
    let actionNoun = this.props.actionNoun;
    if (this.props.allocateFeature && this.props.entity.allocated === "No") {
      actionNoun = "Allocat";
    }

    this.setState({ submitting: true });
    this.props
      .submit(this.state.values)
      .then(this.handleSubmitResponse)
      .catch(this.handleApiFailure)
      .finally(this.finalizeSubmit(actionNoun));
  };

  handleSubmitResponse = ({id, entityName, validations}) => {
    if (!util.objectEmpty(validations)) {
      this.setState({
        validations,
        hiddenValidation: null,
      });
      const firstInvalidFieldName = this.getAllFieldNames().find(
        (name) => name in validations
      );
      this.inputRefs[firstInvalidFieldName] &&
        this.inputRefs[firstInvalidFieldName].focus();
      if (this.props.clearOnSubmitError) {
        this.clearFormValues();
      }
      
    } else {
      this.exitPending = true;
    }
    if(this.props.hasTrainerMilestone) {
      if(this.props.screenId === config.memberSubmenuE.COURSE_PUBLISH){
        return ;
       
      }else{
        this.updateTrainerMilestones(id, entityName);
      }
    }
  };

  finalizeSubmit = (actionNoun) => async () => {
    const arg = {
      moduleId: "1",
      memberId: "1",
      auditDate: new Date(),
      auditAction: this.props.action,
      auditRemarks: this.props.name,
      auditDetails: "1",
      createdOn: new Date(),
      createdBy: "1",
    };
    await this.props.ctx_api.privateRequestRow({
      cmd: "insert_audit_trail",
      args: arg,
    });
    this.setState({ submitting: false });
    if (this.exitPending) {
      this.exitPending = false;
      this.showSuccessPopupAndThen(actionNoun, () =>
        this.props.stayOnPage || this.props.stayOnCondition
          ? this.onActionstayOnPage()
          : this.props.onActionDone("saved")
      );
    }
  };

  onActionstayOnPage = () => {
    const {
      alternateRedirection,
      onActionDone,
      stayOnPage,
      menu,
      selectedMenuId,
      action,
      stayOnCondition,
    } = this.props;
    if (
      alternateRedirection &&
      selectedMenuId &&
      (stayOnCondition || (stayOnPage !== null && action === "add"))
    ) {
      const screenId = menu[selectedMenuId].screen_id;
      alternateRedirection(stayOnPage, "update", screenId);
    } else {
      onActionDone("saved");
    }
  };

  showSuccessPopupAndThen = (actionNoun = this.props.actionNoun, callback) => {
    this.props.ctx_window.alert(
      messages.mkEntityActionSuccessAlert(this.getNameLabel(), actionNoun),
      callback
    );
  };

  handleTabSelect = (activeTabKey) => {
    this.setState({ activeTabKey });
    const { tabRenderers, onTabChange } = this.props;
    if (tabRenderers && onTabChange) {
      onTabChange(
        Object.getOwnPropertyNames(tabRenderers)[activeTabKey],
        this.state.values
      );
    }
  };

  setFormRef = (r) => {
    this.formElt = ReactDOM.findDOMNode(r);
  };

  mkFieldLabel = (name) => {
    const { label } = this.props.fields[name];
    return util.capitalizeWord(
      label !== undefined ? label : util.splitCamelCase(name).join(" ")
    );
  };

  mkHelpTipLabel = (name) => {
    const { tipLabel, label } = this.props.fields[name];
    return tipLabel !== undefined
      ? tipLabel
      : label != null
      ? label
      : util.splitCamelCase(name).join(" ");
  };

  introOnBefore = (nextStepIndex, nextElement) => {
    const { menu, selectedMenuId } = this.props;
    const steps = config.screenSteps[menu[selectedMenuId].screen_id];
    if (steps.length !== 0 && steps[1].length !== 0) {
      const tabs = steps[1];
      const indRes = tabs.findIndex((s1, i) => nextStepIndex <= s1);
      if (indRes !== -1 && indRes !== this.state.activeTabKey) {
        this.setState({ activeTabKey: indRes });
      }
    }
  };

  renderValidatedField = ({ inputRef, cancelBlur, ...restProps }) => {
    const { values, validations, hiddenValidation } = this.state;
    const { componentClass, name } = restProps;
    let Field = FormField;
    if (typeof componentClass === "function") {
      Field = componentClass;
      delete restProps.componentClass;
    }
    const validation = hiddenValidation === name ? null : validations[name];
    const required = !this.props.fields[name].optional;
    const setInputRef = (r) => {
      if (inputRef) {
        inputRef(r);
      }
      this.inputRefs[name] = r;
    };

    return (
      <Field
        onChange={this.handleChange}
        label={this.mkFieldLabel(name)}
        {...restProps}
        value={values[name]}
        validation={validation}
        // onBlur={this.handleBlur(name)}
        onBlur={!cancelBlur && this.handleBlur(name)}
        required={required}
        inputRef={setInputRef}
      />
    );
  };

  renderFields = () => {
    const props = {
      s: this.state.values,
      onChange: this.handleChange,
      ValidatedField: this.renderValidatedField,
      onClick: this.handleClick,
      makeTabChange: this.handleTabSelect,
    };
    const { fieldsRenderer, tabRenderers, menu, selectedMenuId } = this.props;
    const steps = selectedMenuId
      ? config.screenSteps[menu[selectedMenuId].screen_id]
      : null;
    // const { stepsEnabled, initialStep, activeTabKey } = this.state;
    // const intro = steps && steps.length >= activeTabKey ?
    //   <Steps
    //     enabled={stepsEnabled}
    //     steps={steps[0]}
    //     initialStep={initialStep}
    //     onExit={() => { this.setState({ stepsEnabled: false }) }}
    //     onBeforeChange={this.introOnBefore}
    //   /> : null;
    const permission = this.getPermission("Modify");
    if (fieldsRenderer) {
      return (
        this.props.FromKF ? <>{fieldsRenderer(props)} </>  : <Card body className="border-0">
        {fieldsRenderer(props)}
      </Card>
       
      );
    }
    if (tabRenderers) {
      return (
        <ResponsiveTabs showMore={false}
          unmountOnExit={this.props.unmountOnExit ?? true} //To unmount contents of inactive tabs.
          tight
          items={Object.getOwnPropertyNames(tabRenderers)
            .filter((name) => !tabRenderers[name](props).hidden)
            .map((name, i) => {
              const { disabled, element, tip, label, hidden } =
                tabRenderers[name](props);
              if (hidden) {
                return null;
              }
              return {
                title: util.capitalizeWord(label ? label : name),
                disabled: disabled,
                content: (
                  <>
                    <div className="mb-3"></div>
                  {  this.props.FromKF || this.props.fromPublishTest? <>{element}</> :
                    <Card body className="border-0">
                      {element}
                    </Card>
              }
                  </>
                ),
              };
            })}
        />
      );
    }
    return null;
  };

  renderInfo = () => {
    const { menu, selectedMenuId } = this.props;
    const steps = config.screenSteps[menu[selectedMenuId].screen_id];
    if (steps == undefined || steps.length <= this.state.activeTabKey) {
      return null;
    }
    return (
      <div
        style={{
          margin: "5px",
          position: "absolute",
          top: "-36px",
          right: "0",
          color: "white",
        }}
      >
        {/* <OverlayTooltip
          tip="Help"
          id="help"
          placement="top"
        >
          <Glyphicon className='btn glyphicon-lamp'
            // variant="primary pull-right"
            onClick={() => { this.setState({ stepsEnabled: true }) }} />
        </OverlayTooltip> */}
      </div>
    );
  };

  renderForm = () => this.renderFields();


  handleModalClose =() => {
    this.setState({isModal: true})
  }

  mkInvalidFieldsHelpTip = () => {
    const msgs = [];
    Object.getOwnPropertyNames(this.props.fields).forEach((name) => {
      const v = this.state.validations[name];
      if (v !== undefined && v !== true) {
        const modifier = this.fieldBlank(name, this.state.values, this.props)
          ? this.props.ctx_labels.singular.incomplete
          : "invalid";
        const label = this.mkHelpTipLabel(name);
        const fn = this.props.fields[name][`${modifier}TipFn`];
        const m = fn
          ? fn(this.state.values)
          : label
          ? `${label} is ${modifier}`
          : null;
        if (m) {
          msgs.push(m);
        }
      }
    });
    if (msgs.length === 0) {
      return "One or more fields are invalid"; // Fail gracefully
    }
    return (
      <div style={{ textAlign: "left" }}>
        {this.props.ctx_labels.singular.correctTheFollowing }
        <ul style={{ marginLeft: "1.5em", paddingLeft: "0em" }}>
          {msgs.map((m, i) => (
            <li style={{ marginTop: " -5px"
            }} key={i}>{m}</li>
          ))}
        </ul>
      </div>
    );
  };

  renderFooter = () => {
    const {
      actionNoun,
      tipsDisabled,
      submitBtnText,
      isTeacherForm,
      actionBtnText,
      altButton,
      isTestForm,
      customTip,
    } = this.props;
    const { modified, submitting,values } = this.state;
    const name = this.getNameLabel();
    const adding = this.adding();
    const isTeacher = isTeacherForm ? true : false;
    const isSingleton = !adding && !this.props.entity;
    const unclearable = !modified;
    const uncancelable = !modified && isSingleton;
    const enableCancel = !modified && modified;
    const crossPath = this.props.crossPath ? true : false;
    const permission = this.getPermission("Modify");
    // const unsubmittable = !modified || !this.fieldsValid();
    const unsubmittable = permission
      ? isTestForm
        ? !this.props.forceModified || !this.fieldsValid()
        : !modified || !this.fieldsValid()
      : true;
      const testgenvaluesub = values.mcqNoqs === '0' && values.fillNoqs === '0' && values.shortNoqs === '0'

    const capName = util.capitalizeWord(name);
    const exitTip = isSingleton
      ? ""
      : this.props.ctx_labels.singular.returnTo + (isTeacher ? "subjects" : this.props.ctx_labels.singular.searchPage);
    const andExitTip = !crossPath && !isSingleton ? ` and ${exitTip}` : "";
    const allocated = this.props.entity
      ? this.props.entity.__sortAllocation
      : false;
    const saveBtnTxt = !allocated && submitBtnText ? submitBtnText : "";
    const updateBtnTxt = !allocated && submitBtnText ? submitBtnText : "";
    const submitTip = tipsDisabled
      ? "Update not possible"
      : customTip
      ? customTip
      : !permission
      ? "No permission to update"
      : submitting
      ? `${capName} ${actionNoun} in progress`
      : !modified && !adding
      ? this.props.ctx_labels.singular.noFieldMod
      : unsubmittable
      ? this.mkInvalidFieldsHelpTip()
      : adding
      ? `Save ${saveBtnTxt} ${name}${andExitTip}`
      : isTeacher && name === "Class"
      ? `Confirm ${name}${andExitTip}`
      : this.props.isTestGeneration
      ? this.props.ctx_labels.singular.generateAiTest
      : `${updateBtnTxt} Update ${name}${andExitTip}`;
      const Publish="Publish"
    const clearTip = tipsDisabled
      ? null
      : submitting
      ? `No changes permitted during ${name} ${actionNoun}`
      : unclearable
      ? this.props.ctx_labels.singular.noFieldsModified
      : this.props.ctx_labels.singular.clearAllFields;
    const cancelTip = tipsDisabled
      ? "Cancel"
      : submitting
      ? `No changes permitted during ${name} ${actionNoun}`
      : modified
      ? `${this.props.ctx_labels.singular.discardChanges} ${andExitTip}`
      : !isSingleton
      ? util.capitalizeWord(exitTip)
      : "Cancel";
      const shelfSaveDisable = this.props.memberAllocation?.length ==[] ||this.props.memberAllocation == null
      ||  this.state.values.name ==""
    return (
      this.props.FromKF ? (<>
      <ButtonToolbar 
        className="entity-form-buttons" 
        style={
          this.props.FromKF 
          ? { flexDirection: "row-reverse" , marginTop:"-4em",marginRight:"6px" } 
          : this.props.isTestGeneration 
            ? { justifyContent: "flex-end" } 
            : null
        }
      >
                {allocated && (
                  <HelpfulButton
                    variant="danger"
                    onClick={this.handleUnallocate}
                    tip={`Un-allocate this ${name}`}
                    tipId="entity-unallocate-tip"
                  >
                    Unallocate
                  </HelpfulButton>
                )} 
                <MediaQuery minDeviceWidth={768}> 
              {  this.props.isPublish && this.props.FromKF ?  
                  (<Button
                    style={{ minWidth: "max-content" ,marginRight:"160px",marginTop:"10px"}}
                    className="btn-rectangle"
                    disabled={shelfSaveDisable}>
                    {/* <div id="create"> */}
           
                    {/* <CircleAddNew className="circlenew" /> */}
                    {this.props.ctx_labels.singular.save}
                    {/* </div> */}
                    {/* <div className="verti-sep"></div> */}
                    {/* <div> */}
                   
                
                    <Dropdown className="drpdown" >
                      <Dropdown.Toggle className="drpdown"></Dropdown.Toggle>
                      <Dropdown.Menu className="drpdown-menu"  >
                        <Dropdown.Item className="btn-multi border-primary-multi"  onClick={(e) => this.handleSavesubmit("save",e)}>Save</Dropdown.Item>
                        <Dropdown.Item className="btn-multi border-primary-multi" onClick={(e) => this.handleSavesubmit("publish",e)}>Publish</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                 
                    {/* </div> */}
                  </Button>) 
                  : 

              ( 
              <HelpfulButton
                form="entity-form"
                id={
                  adding
                    ? `Save-${this.props.keyName}`
                    : `Update-${this.props.keyName}`
                }
                variant={unsubmittable ? "default" : "primary"}
                disabled={unsubmittable || submitting || testgenvaluesub}
                onClick={this.handleSubmit}
                tip={submitTip}
                tipId="entity-submit-tip"
                placement="bottom"
              >
                {actionBtnText
                  ? actionBtnText
                  : adding
                  ? `${this.props.ctx_labels.singular.save} ${saveBtnTxt}`
                  : isTeacher && name === "Class"
                  ? `Confirm ${name}`
                  : this.props.isTestGeneration
                  ? <span><IconGenerate/>Generate</span>
                  : `${updateBtnTxt} ${this.props.ctx_labels.singular.update}`}
                {/* {`${util.capitalizeWord(action)} ${name}`} */}
                {/* {alert('1'+saveBtnTxt +'2'+name)} */}
              </HelpfulButton>
              )
  }
  </MediaQuery> 
  <MediaQuery maxDeviceWidth={767}>

  {  this.props.isPublish && this.props.FromKF ?  
                       (
                        <> 
                        <HelpfulButton className="btn-rectangle" style={{marginTop:"3em"}}  onClick={(e) => this.handleSavesubmit("save",e)}><IconSaveNew/></HelpfulButton>
                        <HelpfulButton className="btn-rectangle" style={{marginTop:"3em",backgroundColor:"#001B4A"}}   onClick={(e) => this.handleSavesubmit("publish",e)}><IconPublishNew/></HelpfulButton>
                        </> )  
                  : 

              ( 
              <HelpfulButton
                form="entity-form"
                id={
                  adding
                    ? `Save-${this.props.keyName}`
                    : `Update-${this.props.keyName}`
                }
                variant={unsubmittable ? "default" : "primary"}
                disabled={unsubmittable || submitting || testgenvaluesub}
                onClick={this.handleSubmit}
                tip={submitTip}
                tipId="entity-submit-tip"
                placement="bottom"
              >
                {actionBtnText
                  ? actionBtnText
                  : adding
                  ? `${this.props.ctx_labels.singular.save} ${saveBtnTxt}`
                  : isTeacher && name === "Class"
                  ? `Confirm ${name}`
                  : this.props.isTestGeneration
                  ? <span><IconGenerate/>Generate</span>
                  : `${updateBtnTxt} ${this.props.ctx_labels.singular.update}`}
                {/* {`${util.capitalizeWord(action)} ${name}`} */}
                {/* {alert('1'+saveBtnTxt +'2'+name)} */}
              </HelpfulButton>
              )
  }
  </MediaQuery>
                {altButton && altButton()}
                {adding && !this.props.FromKF&&(
                  <HelpfulButton
                    // pill
                    variant={unclearable ? "default" : "danger"}
                    disabled={unclearable || submitting || this.props.preview==="prev"}
                    onClick={this.handleClear}
                    tip={clearTip}
                    id={`clear-${this.props.keyName}`}
                    tipId="entity-clear-tip"
                  >
                    {this.props.ctx_labels.singular.clear}
                  </HelpfulButton>
                )}
             
                {!this.props.hideCancel && !this.props.FromKF ?
                (
                  <HelpfulButton
                    // pill
                    // style={{marginRight:this.props.isPublish ? "-14em":""}}
                    variant={"danger"}
                    disabled={enableCancel ? uncancelable : submitting}
                    onClick={this.handleCancel}
                    tip={cancelTip}
                    id={`cancel-${this.props.keyName}`}
                    tipId="entity-cancel-tip"
                    
                  >
                    <span>{this.props.isTestGeneration && <IconCircleCancelRed/>}{this.props.ctx_labels.singular.cancel}</span>
                  </HelpfulButton>
                

                )
              :<>
              <MediaQuery minDeviceWidth={768}>
              <Button
                    style={{ backgroundColor:"red", minWidth: "max-content" ,marginRight:"-14em",marginTop:"10px"}}
                    className="btn-rectangle"
                    onClick={this.handleCancel}
                    tip={cancelTip}
                    variant={"danger"}
                    id={`cancel-${this.props.keyName}`}
                    tipId="entity-cancel-tip"
                  >
                     <span>{this.props.ctx_labels.singular.cancel}</span>
                  </Button>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={767}>
                       <Button
                    style={{ backgroundColor:"red", minWidth: "max-content" ,marginTop:"3em"}}
                    className="btn-rectangle"
                    onClick={this.handleCancel}
                    tip={cancelTip}
                    variant={"danger"}
                    id={`cancel-${this.props.keyName}`}
                    tipId="entity-cancel-tip"
                  >
                   <IconCancelNew/>
                  </Button>
                  </MediaQuery>
                  </>
              }
              </ButtonToolbar></>) :(
              <div className="entity-form-footer">
     
        {/* {this.allFieldsOptional() ? <div /> : <TextField.RequiredMessage />} */}
       
        <ButtonToolbar 
  className="entity-form-buttons" 
  style={
    this.props.FromKF 
    ? { flexDirection: "row-reverse" } 
    : this.props.isTestGeneration 
      ? { justifyContent: "flex-end" } 
      : null
  }
>
          {allocated && (
              <HelpfulButton
                variant="danger"
                onClick={this.handleUnallocate}
                tip={`Un-allocate this ${name}`}
                tipId="entity-unallocate-tip"
              >
                Unallocate
              </HelpfulButton>
            )}
            {this.props.fromPublishTest ?

              <HelpfulButton
                // pill
                form="entity-form"
                id={
                  adding
                    ? `Save-${this.props.keyName}`
                    : `Update-${this.props.keyName}`
                }
                variant={unsubmittable ? "default" : "primary"}
                disabled={unsubmittable || submitting || testgenvaluesub}
                onClick={this.handleSubmit}
                tip={submitTip}
                tipId="entity-submit-tip"
                placement="bottom"
                className={this.props.fromPublishTest? "btn-publish" : ""}
              >
                <span><IconPublishTestNew />Publish</span>
              </HelpfulButton> :

              <HelpfulButton
                // pill
                form="entity-form"
                id={
                  adding
                    ? `Save-${this.props.keyName}`
                    : `Update-${this.props.keyName}`
                }
                variant={unsubmittable ? "default" : "primary"}
                disabled={unsubmittable || submitting || testgenvaluesub}
                onClick={this.handleSubmit}
                tip={submitTip}
                tipId="entity-submit-tip"
                placement="bottom"
              >
                {actionBtnText
                  ? actionBtnText
                  : adding
                    ? `${this.props.ctx_labels.singular.save} ${saveBtnTxt}`
                    : isTeacher && name === "Class"
                      ? `Confirm ${name}`
                      : this.props.isTestGeneration
                        ? <span><IconGenerate/>Generate</span>
                        : `${updateBtnTxt} ${this.props.ctx_labels.singular.update}`}
                {/* {`${util.capitalizeWord(action)} ${name}`} */}
                {/* {alert('1'+saveBtnTxt +'2'+name)} */}
              </HelpfulButton>}
          {altButton && altButton()}
          {adding && (
            <HelpfulButton
              // pill
              variant={unclearable ? "default" : "danger"}
              disabled={unclearable || submitting || this.props.preview==="prev"}
              onClick={this.handleClear}
              tip={clearTip}
              id={`clear-${this.props.keyName}`}
              tipId="entity-clear-tip"
              className={this.props.fromPublishTest? "btn-publish" : ""}
            >
              {this.props.ctx_labels.singular.clear}
            </HelpfulButton>
          )}
          {!this.props.hideCancel && (
            <HelpfulButton
              // pill
              variant={"danger"}
              disabled={enableCancel ? uncancelable : submitting}
              onClick={this.handleCancel}
              tip={cancelTip}
              id={`cancel-${this.props.keyName}`}
              tipId="entity-cancel-tip"
              className={this.props.fromPublishTest? "btn-publish" : ""}
              
            >
              <span>{this.props.isTestGeneration && <IconCircleCancelRed/>}{this.props.ctx_labels.singular.cancel}</span>
            </HelpfulButton>
          )}
        </ButtonToolbar>
      </div>)
    );
  };

  render = () => {
    const adding = this.adding();
    const action = adding ? this.props.ctx_labels.singular.create : this.props.ctx_labels.singular.update;
    const { menu, selectedMenuId } = this.props;
    const parentMenu =
      menu[selectedMenuId] && menu[menu[selectedMenuId].parent_id];
    const parentEntityRoute =
      menu[selectedMenuId] && menu[selectedMenuId].route;
    const parentMenuName =
      menu[selectedMenuId] &&
      menu[selectedMenuId].parent_id &&
      util.capitalizeWord(
        this.getNameLabel({
          name: parentMenu.menu_name,
          ctx_labels: this.props.ctx_labels,
        })
      );
    const entity = util.capitalizeWord(this.getNameLabel());
    const isList = this.props.isMobileView
      ? [
          { title: parentMenuName, current: true },
          {
            title: entity,
            link: parentEntityRoute,
            onLinkClick: this.handleCancel,
          },
        ]
      : [
          { title: parentMenuName, current: true },
          {
            title: entity,
            link: parentEntityRoute,
            onLinkClick: this.handleCancel,
          },
          { title: `${action} ${entity}`, current: true },
        ];
        const screenSize = window.screen.width

    return (
      <>
      <MediaQuery minDeviceWidth={1024}>
    {this.props.location && this.props.location.state && (this.props.location.state.adminHome == true) ? (
      <Breadcrumb
        list={[
          {
            title: util.capitalizeWord(this.props.ctx_labels.singular.user),
            current: true,
          },
          {
            title: util.capitalizeWord(this.props.ctx_labels.singular.student),
            current: true,
          },
          {
            title: adding ? util.capitalizeWord(this.props.ctx_labels.singular.create) : util.capitalizeWord(this.props.ctx_labels.singular.update),
            current: true,
          },

        ]}
      />
    ) : (
      !this.props.hideBreadcrumb && <Breadcrumb list={isList} />)}
      </MediaQuery>
        {this.props.customBreadcrumb ? this.props.customBreadcrumb : null}

        {this.props.fromPasswordChange === "true" && screenSize < 768 ? (
          <div className="passwordChange">
            {this.props.customHeader ? (
              <div className="fw-500">{this.props.customHeader}</div>
            ) : (
              <PageTitle>{`${action} ${entity}`}</PageTitle>
            )}
            {this.props.submit &&
              !this.props.nonSubmitable &&
              this.renderFooter()}
          </div>
        ) : (
          this.props.FromKF ? <>  <div className="fw-500"/> {this.props.submit &&
            !this.props.nonSubmitable && !this.props.isTestGeneration &&
            this.renderFooter()}</> : (
       
       <JustifyBetween>
            {this.props.customHeader ? (
              this.props.customHeader
            ) : 
            this.props.fromKF ? <></>
            :
            (
              <PageTitle>{`${action} ${entity}`}</PageTitle>
            )}
            {this.props.submit &&
              !this.props.nonSubmitable && !this.props.isTestGeneration &&
              this.renderFooter()}
          </JustifyBetween>
          )
        )}
        {/* <div className="mb-3"></div> */}
        {/* {this.props.headerRenderer && this.props.headerRenderer()} */}
        { this.renderForm()}
        <> {this.props.submit &&
            !this.props.nonSubmitable && this.props.isTestGeneration &&
            this.renderFooter()}</>
      </>
    );
  };
}

export default withWindowContext(
  withFrameContext(withLabelsContext(withUserContext(withAnalyticsContext(EntityForm))))
);
