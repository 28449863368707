import { Loader } from "components";
import React, { useEffect } from "react";
import css from "./login.module.scss";
import * as config from "config";
import { withSiteContext, withApiContext, withAccessContext } from "context";
import * as util from "services/util";
import Cookies from "js-cookie";

function Login(props) {
  document.title = props.ctx_site.site.siteName || config.companyName;
  const sessionKey = Object.keys(Cookies.get()).find((k) =>
    k.match(/^_shibsession_.*$/)
  );

  const sessionValue = Cookies.get(sessionKey);

  const callShibboleth = () => {
    return new Promise((resolve, reject) => {
      let sessionHeaders = new Headers();
      sessionHeaders.append("Cookie", `${sessionKey}=${sessionValue}`);

      let sessionOptions = {
        method: "GET",
        headers: sessionHeaders,
        redirect: "follow",
      };
      fetch(
        config.insLinks.login,
        //"https://in2vate.ilearningengines.com/Shibboleth.sso/Session",
        sessionOptions
      )
        .then((data) => {
          return data.text();
        })
        .then((data) => {
          const user = data.substring(
            data.search("userlogin") + 20,
            data.search("</pre>")
          );
          let loginHeaders = new Headers();
          loginHeaders.append("Content-Type", "application/json");
          const loginOptions = {
            method: "POST",
            headers: loginHeaders,
            body: JSON.stringify({ user: user }),
            redirect: "follow",
          };
          return fetch("/api/Shibboleth", loginOptions)
            .then((rsp) => resolve(rsp))
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        });
    });
  };

  const handleShibbolethLogin = (rsp, headers) => {
    const stayLoggedIn = config.defaultStayLoggedIn;
    const auth = headers.get("x-access-token") || null;
    const success = rsp[0].statusvalue === 1 && auth;
    if (success) {
      const userUrl = config.memberUrl;
      props.ctx_access.logIn(auth, rsp[0], stayLoggedIn);
      props.routerHistory.push(userUrl);
    } else props.routerHistory.push("/login");
  };

  useEffect(() => {
    let headers = new Headers();
    callShibboleth()
      .then((rsp) => {
        headers = rsp.headers;
        return rsp.json();
      })
      .then((rsp) => handleShibbolethLogin(rsp, headers))
      .catch((err) => {
        console.log(err);
        props.routerHistory.push("/login");
      });
  }, []);
  return (
    <>
      <Loader active />
    </>
  );
}

export default withSiteContext(
  withAccessContext(withApiContext(util.withRouterHistory(Login)))
);
