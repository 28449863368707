import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as util from 'services/util';
import './ThreadMessages.css';
import ThreadTimestamp from './ThreadTimestamp';





class ThreadMessages extends Component {
  static propTypes = {
    className:            PropTypes.string,
    threads:              PropTypes.object.isRequired,
    activeTid:            PropTypes.string,
    now:                  PropTypes.instanceOf(Date).isRequired,
    messagePaddingBottom: PropTypes.number.isRequired,
    setMessageRef:        PropTypes.func,
  };

  shouldComponentUpdate = (nextProps) =>
    nextProps.threads   !== this.props.threads   ||
    nextProps.activeTid !== this.props.activeTid ||
    !util.sameDates(nextProps.now, this.props.now);

  render = () => {
    if (this.props.activeTid === null) {
      return null;
    }
    const tableClassName = [
      'chat-thread-messages-table',
      this.props.className,
    ].join(' ');
    const cellStyle =
      { paddingBottom: `${this.props.messagePaddingBottom}px` };
    return (
      <div className={tableClassName}>
        {this.props.threads[this.props.activeTid].messages.map((msg, i) => {
          const key = `msg${i}`;
          const senderTextClassName =
            msg.sender ?
            'chat-thread-messages-reply' :
            'chat-thread-messages-query';
          return (
            <div
              className="chat-msg-item"
              key={key}
              ref={this.props.setMessageRef(msg)}
            >
              <div className="chat-user-ico-wrap">
                <span className="chat-user-ico"></span>
              </div>
              <div className="chat-msg-details" style={cellStyle}>
                <div
                  className="chat-msg-item-title"
                >
                  { msg.sender && (
                    <span className="chat-thread-messages-sender">
                      {msg.sender}
                    </span>
                  )}
                  {
                    !msg.sender && (
                      <span className="chat-thread-messages-you">
                        You
                      </span>
                    )
                  }
                  <div
                    className="chat-thread-messages-timestamp"
                  >
                    <ThreadTimestamp
                      timestamp={msg.timestamp}
                      now={this.props.now}
                      id={i}
                    />
                  </div>
                </div>
                <div
                  className={senderTextClassName}
                  style={cellStyle}
                >                  
                  <span className="chat-thread-messages-text">
                    {msg.value}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}


export default ThreadMessages
