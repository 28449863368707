import React from "react";
import classNames from "classnames";
import css from "./toolbar.module.scss";

const ToolbarItem = (props) => {
  return (
    <div {...props} className={classNames(css.item, props.className)}>
      {props.children}
    </div>
  );
};

const ToolbarLabel = (props) => {
  return (
    <div className={classNames(css.label, props.className)}>
      {props.children}
    </div>
  );
};

const Toolbar = (props) => {
  return (
    <div className={classNames(props.isQuestionSelectModal ? css.questionwrapper : css.wrapper, props.className)}>
      {props.children}
    </div>
  );
};

Toolbar.Item = ToolbarItem;
Toolbar.Label = ToolbarLabel;

export default Toolbar;
