import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './DragAndDrop.scss'
function DragAndDrop({ items, render, onUpdate ,customId,chapterIndex,className="drag"}) {


  const handleOnDrag = (e) => {
    
    if (!e.destination) return;

    const copyArray = Array.from(items);
    const [removed] = copyArray.splice(e.source.index, 1);
    copyArray.splice(e.destination.index, 0, removed);
    if (copyArray.length > 0) {
   
      onUpdate(copyArray);
    }
  };


  
 

  return (
    <DragDropContext onDragEnd={handleOnDrag}>
      <Droppable droppableId={`items${customId} ${className}`} >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items?.map((item, index) => (
              <Draggable
                key={index + 20}
                draggableId={(customId+index + 20).toString()}
                index={index}
                // isDragDisabled={dragItem==="itemsmodule"&&customId==="chapter"}
              >
                {(provided) => (
                  <ul
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {render ? render(item, index,chapterIndex) : item}
                  </ul>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDrop;
