import React from 'react';
import SurveyForm from './Form';
import SurveySearch from './Search';
import Entity from 'pages/Entity';

const Survey = (props) => (
  <Entity
    Search={SurveySearch}
    Form={SurveyForm}
    {...props}
  />
);
export default Survey
