import {OverlayTooltip} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { IconExternalLink } from 'Icons';




const ReviewLink = ({
  id,
  onClick,
}) => (
  <OverlayTooltip
    tip="Review this material"
    id={id}
  >
    <Button
      className="test-answer-review-button"
      bsStyle="primary"
      onClick={onClick}
    >
      <IconExternalLink />
    </Button>
  </OverlayTooltip>);

ReviewLink.propTypes = {
  id:      PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default ReviewLink
