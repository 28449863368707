import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import './ElasticTextArea.css';



class ElasticTextArea extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refreshPending: false,
    };

    this.divElt = null;
    this.textAreaElt = null;
  }

  componentDidMount = () => {
    this.updateTextAreaHeight();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.value !== prevProps.value) {
      this.handleValueChange();
    } else if (this.state.refreshPending) {
      this.refresh();
    }
  }

  focus = () => {
    if (this.textAreaElt) {
      this.textAreaElt.focus();
    }
  }

  isFocused = () =>
    this.textAreaElt !== null && document.activeElement === this.textAreaElt;

  setSelectionRange = (begin, end) => {
    if (this.textAreaElt) {
      this.textAreaElt.setSelectionRange(begin, end);
    }
  }

  setDivContent = () => {
    if (this.divElt) {
      const c = this.props.value;
      // Add a space at the end of the string if the last line is empty
      // (otherwise, that line will not occupy any space)
      this.divElt.textContent = (c || '').replace(/(\r?\n|\r|^)$/, '$& ');
    }
  }

  updateTextAreaHeight = () => {
    if (this.divElt.current && this.textAreaElt.current) {
      const h = this.divElt.current.clientHeight;
      const newHeight = h === 0 ? 23 : h;
      this.textAreaElt.current.style.height = `${newHeight}px`;
      return newHeight;
    }
    return null;
  }

  requestRefresh = () => {
    this.setState({ refreshPending: true });
  }

  refresh = () => {
    this.setDivContent();
    this.updateTextAreaHeight();
    this.setState({ refreshPending: false });
  }

  setDivRef = (r) => {
    this.divElt = ReactDOM.findDOMNode(r);
    this.setDivContent();
  }

  setTextAreaRef = (r) => {
    this.textAreaElt = ReactDOM.findDOMNode(r);
  }

  handleValueChange = () => {
    const h0 = this.divElt.clientHeight;
    this.setDivContent();
    const h = this.updateTextAreaHeight();
    if (h !== h0 && this.props.onResize) {
      this.props.onResize({
        oldHeight: h0,
        newHeight: h,
        cursorPos: this.textAreaElt.selectionEnd,
        text:      this.props.value,
      });
    }
  }

  render = () => {
    const {
      className,
      ...restProps
    } = this.props;
    delete restProps.onResize;
    return (
      <div
        className={[className, 'elastic-text-area'].join(' ')}
      >
        <div
          ref={this.setDivRef}
        />
        <textarea
          {...restProps}
          value={this.props.value}
          onChange={this.props.onChange}
          ref={this.setTextAreaRef}
        />
      </div>
    );
  }
}


export default ElasticTextArea
