import AsyncSelect from "./AsyncSelect";
import { withApiContext, withLabelsContext, withUserContext } from "context";
import React, { PureComponent } from "react";



class CoursePublishSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: true,
    };
  }


  fetch = async () => {
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "get_course_publish_name",
      args: {
       // classId: this.props.ctx_user.user.classId,
       classId: null,
        orgId: this.props.ctx_user.user.organizationId ? this.props.ctx_user.user.organizationId.toString() : "0"

      },
    });
    
    return rsp.map(({ course_publish_id , name }) => ({
      label: name,
      value: course_publish_id ,
      
    }
    
    ));
  };

  render = () => (
    <AsyncSelect
      {...this.props}
      optionName={this.props.ctx_labels.singular.coursePublish}
      fetch={this.fetch}
      unselectedValue=""
      refresh={this.state.refresh}
    />
  );
}

export default withApiContext(
  withLabelsContext(withUserContext(CoursePublishSelect))
);
