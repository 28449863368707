import React from "react";
import TestQuestion from "./TestQuestion";
import css from "../Question/question.module.scss";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import MediaQuery from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Pdf from "components/PlayerNew/Pdf";

const Question = (props) => {
  const {
    question,
    answers,
    onAnswered,
    onReview,
    onAttempted,
    answerFooter,
    Ids,
    disabled,
    pdfComment,
  } = props;
  const screenSize = window.screen.width
  return (
    <div>
      <div className={`${css.title} text-color4`}>
        {" "}
        {`Section ${question.section_order}`} - Question {question.order + 1}{" "}
        <div
          style={
            screenSize > 821
              ? { fontSize: 14, position: "absolute", top: 25, right: 28 }
              : { fontSize: 14, top: 25, right: 28 }
          }
        >
          {question.shortCodes}
        </div>
      </div>
      <TestQuestion
        {...question}
        {...answers}
        // mathSymbol={question.math_symbol}
        disabled={disabled}
        onAnswered={onAnswered}
        onReview={onReview}
        onAttempted={onAttempted}
        disabledText="" //"Maximum question limit for this section reached"
        bgColor="#fff"
        count={Ids.indexOf(question.id) + 1}
        pdfComment={pdfComment}
      />
    </div>
  );
};

export default Question;
