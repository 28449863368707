import classNames from "classnames";
import {
  IconCircleCheckGreen,
  IconTime,
  IconPDF,
  IconHTML,
  IconTEXTEDITOR,
  IconLock,
  IconAudioWave,
  IconNonAcademic,
  IconAssessment,
  IconPPT,
  IconSurveys,
  IconEmbedded,
} from "Icons";
import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { ProgressBar } from "components";
import css from "./card-single.module.scss";
import * as util from "services/util";
import styled from "styled-components";
import {withLabelsContext} from "context";


const StyledCardBody = styled.div`
  && {
    .${css.img_placeholder} {
      background: linear-gradient(
        135deg,
        ${({ theme }) => theme.color3} 2%,
        ${({ theme }) => theme.color4} 84%
      );

      > .${css.placeholder_text} {
        color: ${({ theme }) => theme.button.primary.text};
      }
    }
  }
`;

const StyledCardBodyMob = styled.div`
  && {
    .${css.img_placeholder_mob} {
      background: linear-gradient(
        135deg,
        ${({ theme }) => theme.color3} 2%,
        ${({ theme }) => theme.color4} 84%
      );

      > .${css.placeholder_text} {
        color: ${({ theme }) => theme.button.primary.text};
      }
    }
  }
`;

const StyledCard =  styled(Card)`
    &&.${css.current_mob} {
      border: solid 1px ${({ theme }) => theme.primary} ;
    }
`


 function CardSingle(props) {
  const contentLengthString = (type, len) => {
    const hours = Math.floor(len / 3600);
    const minutes = Math.floor((len % 3600) / 60);
    const seconds = Math.floor(len % 60);
  
    const formattedTime = [];
    if (hours > 0) {
      formattedTime.push(hours + 'hr');
    }
    if (minutes > 0) {
      formattedTime.push(minutes + 'm');
    }
    if (seconds > 0) {
      formattedTime.push(seconds + 's');
    }
  
    return type === "video"
      ? formattedTime.join(' ')
      : type === "pdf"
      ? `${len} pages`  //`${len} page${len < 1 || 1 < len ? 's' : ''}` 
      : "";
  }
  const contentLengthStr = contentLengthString(props.type, props.contentLength);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const current_module  = props.isCurrent && props.viewType !=="C" 
  return (
    <>
    {props.fromChapterInner || props.fromMultiPreview || props.subscriptionPreview ?  
    <StyledCard 
    className={classNames(
        props.moduleActive ? css.card_new : css.card_inactive_new,
        props.isCompleted && css.completed,
        current_module &&  css.current_mob,
        // current_module &&  `${css.current_mob} text-primary`,
        // props.viewType=== "C" || props.viewType=== "Card" &&
        props.subscriptionPreview ? css.card_subscribe : ""
      )}
      onClick={props.onClick}
    >
      
      <StyledCardBodyMob className={css.body_mob}>
        <div className={classNames(css.img_placeholder_mob)} >
          {props.subscriptionPreview && (
            <div className={css.img_placeholder_mob}>
              <div className={css.placeholder_text}>
                <IconLock width="23" height="23" />
              </div>
            </div>
          )}
          {props.image && !props.subscriptionPreview && (
            <div className={classNames(css.img_placeholder_mob)}>
           <img  className={classNames(css.img_mob_img)} src={props.image} alt="" />
           </div>
          )}
          {!props.image && !props.subscriptionPreview  && (
            <div className={classNames(css.img_placeholder_mob)}>
              <div className={css.placeholder_text}>
                {props.name && props.name.trim().charAt(0)}
              </div>
            </div>
          )}

          {props.isCompleted && <IconCircleCheckGreen width="16" height="16" />}
        </div>
        {/* <div className={css.body}> */}
        <div className={css.content}>
          <div className={`${css.credit} text-primary`}>{props.credit}</div>
        
          <div className={`${css.title_mob}`}>{props.name && props.name.length > 17 ?<span>{props.name.slice(0,17) + "..."}</span>: <span>{props.name}</span>}</div>
          
          {!props.subscriptionPreview && (
           <div>
            <div className={classNames(css.time_mob)}>
          {props.type === "video" ? (
            <IconTime width="14" height="14" />
          ) : props.type === "audio" ? (
            <IconAudioWave width="14" height="14" />
          ) : props.type === "pdf" ? (
            <IconPDF width="14" height="14" />
          ) : props.type === "html" ? (
            <IconHTML width="14" height="14" />
            ) : props.type === "HTMLEditor" ? (
              <IconTEXTEDITOR width="14" height="14" />
            ): props.type === "PPTEditor" ? (
              <IconPPT width="14" height="14" />
            ): props.type === "test" ? (
              <IconAssessment width="14" height="14" />
          ) :props.type === "survey" ? (
            <  IconSurveys width="14" height="14" />):props.type === "embedded" ? (
              <IconEmbedded width="14" height="14" />): null}
          {contentLengthStr}
        </div>
        {props.completed && !props.fromMultiPreview ?
          <div className={classNames(css.assigned)}>
            {/* {props.ctx_labels.singular.completed}:{" "} */}
            {props.completionDate.toLocaleDateString("en-GB", options)}
          </div>:
          <div className={classNames(css.assigned)}>
          {/* {props.ctx_labels.singular.assigned}:{" "} */}
          {props.assignmentDate.toLocaleDateString("en-GB", options)}
        </div>
        }
            </div>
            
          )}
        </div>
        {/* </div> */}
        {props.nonAcademic && (
        <div className={css.non_academic}>
            {window.screen.width > 822 ? "Non Academic" : <IconNonAcademic width="30" height="30"/>}
        </div>
      )}
        
        {!props.preview && !props.subscriptionPreview  && (
          <div className={css.progressbar}>
        <ProgressBar
          progress={props.moduleProgress}
          fromChapterInner={props.fromChapterInner}
        ></ProgressBar>
        </div>
      )} 
      
      </StyledCardBodyMob>     
    </StyledCard> 
    :
  <StyledCard
  className={classNames(
    props.moduleActive ? css.card : css.card_inactive,
    props.isCompleted && css.completed,
    props.isCurrent && css.current,
    props.subscriptionPreview ? css.card_subscribe : ""
  )}
  onClick={props.onClick}
>
  {!props.subscriptionPreview  &&(
    <div className={classNames(css.time)}>
      {props.type === "video" ? (
        <IconTime width="14" height="14" />
      ) : props.type === "audio" ? (
        <IconAudioWave width="14" height="14" />
      ) : props.type === "pdf" ? (
        <IconPDF width="14" height="14" />
      ) : props.type === "html" ? (
        <IconHTML width="14" height="14" />
        ) : props.type === "HTMLEditor" ? (
          <IconTEXTEDITOR width="14" height="14" />
        ): props.type === "PPTEditor" ? (
          <IconPPT width="14" height="14" />
        ) : props.type === "test" ? (
          <IconAssessment width="14" height="14" />
      ) :props.type === "survey" ? (
        <  IconSurveys width="14" height="14" />): props.type === "embedded" ? (
          <IconEmbedded width="14" height="14" />): null}
      {contentLengthStr}
    </div>
  )}
  {props.nonAcademic && (
    <div className={css.non_academic}>
        Non Academic
    </div>
  )}
  <StyledCardBody className={css.body}>
    <div className={classNames(css.image)} >
      {props.subscriptionPreview && (
        <div className={classNames(css.img_placeholder)}>
          <div className={css.placeholder_text}>
            <IconLock width="23" height="23" />
          </div>
        </div>
      )}
      {props.image && !props.subscriptionPreview && (
       <img src={props.image} alt="" />
      )}
       {!props.image && !props.subscriptionPreview  && (
        <div className={classNames(css.img_placeholder)}>
          <div className={css.placeholder_text}>
            {props.name && props.name.trim().charAt(0)}
          </div>
        </div>
      )}

      {props.isCompleted && <IconCircleCheckGreen width="16" height="16" />}
    </div>
    <div className={css.content}>
      <div className={`${css.credit} text-primary`}>{props.credit}</div>
      <div className={`${css.title} text-color3`}>{props.name && props.name.length > 17 ?<span>{props.name.slice(0,17) + "..."}</span>: <span>{props.name}</span>}</div>
      {!props.subscriptionPreview &&  (
         <div>
          {props.ctx_labels.singular.assigned}:{" "}
          {props.assignmentDate.toLocaleDateString("en-GB", options)}
        </div>  
      )}
    </div>
    <div>
    </div>
  </StyledCardBody>
   {!props.preview && !props.subscriptionPreview && (
    <ProgressBar
      progress={props.moduleProgress}
      label={
        props.completed &&
        `${props.ctx_labels.singular.completed}: ${props.completionDate.toLocaleDateString(
          "en-GB",
          options
        )}`
      }
    ></ProgressBar>
  )} 
    
</StyledCard>
        }
    </>
  );
}

CardSingle.propTypes = {
  isCompleted: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func,
  progress: PropTypes.number,
};

CardSingle.defaultProps = {
  isCompleted: false,
  isCurrent: false,
  progress: 0,
  onClick: () => {},
};

export default withLabelsContext(CardSingle)