import React from "react";
import SurveyListForm from './Form';
import SurveyListSearch from "./Search";
import Entity from "pages/Entity";

const SurveyList = (props) => (
  <Entity
    Search={SurveyListSearch}
    Form={SurveyListForm}
    {...props}
  />
);

export default SurveyList;