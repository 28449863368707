import React from 'react';
import PropTypes from 'prop-types';

import DelayedFade from 'components/DelayedFade';

import * as config from 'config';


const AnswerExplanation = ({ visible, children }) => (
  <DelayedFade
    in={visible}
    inDelay={config.testExplanationFadeInDelay}
    inInterval={config.testExplanationFadeInInterval}
    outDelay={config.testExplanationFadeOutDelay}
    outInterval={config.testExplanationFadeOutInterval}
  >
    <div className="test-answer-explanation">
       {children}
    </div>
  </DelayedFade>
);

AnswerExplanation.propTypes = {
  visible:  PropTypes.bool.isRequired,
  children: PropTypes.any,
}


export default AnswerExplanation
