import * as config from 'config';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { IconThumbsDown, IconThumbsUp } from 'Icons';
import ReactDOM from 'react-dom';
import AnswerExplanation from 'pages/Assessments/InternalItems/TestQuestion/AnswerExplanation';
import Emph from 'components/Player/TestQuestion/Emph';
import css from "../StudentAssessments/Question/question.module.scss";
import './StudentSurvey.css'
import FormField from "../../components/FormField";
import {Textarea } from "components";


class MultipleChoice extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  static propTypes = {
    question: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
    answers: PropTypes.arrayOf(PropTypes.string).isRequired,
    comments: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewStarts: PropTypes.arrayOf(PropTypes.number).isRequired,
    reviewEnds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onAnswered: PropTypes.func.isRequired,
    onReview: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      config.testQuestionTypeE.MULTIPLE_CHOICE,
      config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE,
    ]).isRequired,
  };

  testR = null;

  initState = {
    selected: [],
    submitted: false,
    comment:""
  };

  componentDidMount = () => {
    // this.acquireFocus();
    if(this.props.response && this.props.userSelection){
      this.setState({selected :this.props.userSelection.selected_id,
        comment:this.props.userSelection.comment})
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.question !== this.props.question) {
      this.setState(
        this.initState,
        this.acquireFocus
      );
    }
  }

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.submitted && !prevState.submitted) {
      const response = {
        correct: [],
        incorrect: [],
        missed: [],
      };
      this.props.choices.forEach((choice, i) => {
        const isAnswer = this.props.answers.indexOf(choice.choice) !== -1;
        if (this.state.selected.indexOf(i) !== -1) {
          const field =
            this.isSurveyQuestion() || isAnswer ? 'correct' : 'incorrect';
          response[field].push(choice.choice);
        } else if (isAnswer) {
          response.missed.push(choice.choice);
        }
      });
      // If this is a survey question, no explanation will be displayed,
      // and so there will be no need for the user to click a second button
      const continueToNext = this.isSurveyQuestion();
      this.props.onAnswered(response, continueToNext);
    }
  }

  acquireFocus = () => {
    const elt = ReactDOM.findDOMNode(this.testR);
    if (elt) {
      elt.focus();
    }
  }


  handleChoiceClick = (i) => (e) => {
    // e.stopPropagation()
    this.setState((s0) => {
      let idx = s0.selected.indexOf(i);
      let selected = [...s0.selected];
      if (idx !== -1) {
        selected.splice(idx, 1);
      } else if (
        this.props.type === config.testQuestionTypeE.MULTIPLE_CHOICE &&
        1 < this.props.answers.length
      ) {
        selected.push(i);
        selected.sort((a, b) => a - b);
      } else {
        selected = [i];
      }
      // if(this.props.survey){
        const updatedSurveyDetails = {
          ...this.props.surveyDetails,
          questions: this.props.surveyDetails.questions.map(sec => {
            if (sec.question_order === this.props.questionOrder) {
              return {
                ...sec,
                user_selection: 
                  { selected_id: selected, comment: this.state.comment }
                
              };
            }
            return sec;
          })
        };
        // this.props.survey && this.props.onAnswered([e.target.value]);
        this.props.onAnswered(updatedSurveyDetails);
      // }
      // this.props.survey && this.props.onAnswered(selected);
      return { selected };
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
  }

  isSurveyQuestion = () =>
    this.props.type === config.testQuestionTypeE.SURVEY_MULTIPLE_CHOICE;

  indexToLetter = (i) => String.fromCharCode(65 + i);

  renderChoices = () => {
    const highestLength = this.props.choices.reduce(
      (highest, choice) => highest > choice.choice.length ? highest : choice.choice.length,
      0
    );
    const widthClassName = highestLength < 8 ? 'small' :
      highestLength < 30 ? 'medium' :
        highestLength < 50 ? 'big' : 'large';
    return (
      // <div className="test-question-choices-wrap">
      //   <div className="test-question-choices-items">
      
      <div>
        <div className={css.options}>
          {this.props.choices.map((choice, i) => {
            const letter = this.indexToLetter(i);
            const isSelected = this.state.selected.indexOf(i) !== -1;
            const isAnswer = this.props.answers.indexOf(choice.choice) !== -1;
            // const result =
            //   this.isSurveyQuestion() ? null :
            //     !this.state.submitted ? null :
            //       !isSelected ? null :
            //         isSelected && isAnswer ? <AnswerStatus.Correct /> :
            //           isSelected && !isAnswer ? <AnswerStatus.Incorrect /> :
            //             null;
            const className = isSelected ? ' selected ' : '';
            const resultClass =
              !this.state.submitted ? '' :
                isAnswer ? ' selected choice-correct-item' :
                  isSelected && !isAnswer ? ' selected choice-incorrect-item' : '';
            return (

              // <div className={`tq-choices-item-wrap tq-choice-width-${widthClassName}`} key={i} >
                // <div className="tq-choices-item-outer">
                  <div
                    // className={'qs-choice-item ' + className + resultClass}
                    // onClick={this.state.submitted ? null : this.handleChoiceClick(i)}
                    // key={`answer-choice-${i}`}
                  >
                    {
                      this.state.submitted && (isAnswer || isSelected) &&
                      <div className="qci-on-submit">
                        <div className="answer-indication">
                          {
                            isAnswer ?
                              <div className="answer-correct">
                                <IconThumbsUp />
                              </div>
                              :
                              <div className="answer-correct">
                                <IconThumbsDown />
                              </div>

                          }
                        </div>
                      </div>
                    }

                    <div >
                      {
                        choice.answerImage &&
                        <div className={this.props.isPlayerTest ? "player-image":"qci-image"}>
                          <img src={choice.answerImage} alt="" />
                        </div>
                      }

                      <div 
                      className={css.option}
                      // className="qci-text"
                      key={`answer-choice-${i}`}
                      >
                        {/* <div className="qci-text-letter-wrap">
                          <div className="qci-text-letter-d">
                            <span className="qci-text-letter">{letter}</span>
                          </div>
                        </div>
                        <div className="qci-text-wrap">
                          <div className="qci-text-wrap-val">{choice.answerText}</div>
                        </div> */}
                        <label className={css.checkbox} >
                            <input
                            onClick={this.state.submitted ? null : this.handleChoiceClick(i)} 
                            type="radio" 
                            id={i} 
                            checked={isSelected} />
                            <span>&nbsp;&nbsp;</span>
                            {choice.choice}
                          </label>
                      </div>
                    </div>
                  </div>
                // </div>
              // </div>
            );
          })}


        </div>
      </div>
    );
  };

  renderIndex = (i, addReviewLink = false) => (
    <span>
      <Emph>{this.indexToLetter(i)}</Emph>
      {
        addReviewLink &&
        0 <= this.props.reviewStarts[i] &&
        this.props.reviewStarts[i] < this.props.reviewEnds[i] && (
          <span>
            {' '}
            {/* <ReviewLink
              id={`review-answer-${i}`}
              onClick={this.props.onReview(
                this.props.reviewStarts[i],
                this.props.reviewEnds[i],
              )}
            /> */}
          </span>
        )
      }
    </span>);

  renderIndexList = (indexList, addReviewLinks = false) => {
    switch (indexList.length) {
      case 0:
        return null;
      case 1:
        return this.renderIndex(indexList[0], addReviewLinks);
      case 2:
        return (
          <span>
            {this.renderIndex(indexList[0], addReviewLinks)}
            {' and '}
            {this.renderIndex(indexList[1], addReviewLinks)}
          </span>);
      default:
        const init = [...indexList];
        const last = init.pop();
        return (
          <span>
            {init.map((i) => (
              <span key={`indexList-${i}`}>
                {this.renderIndex(i, addReviewLinks)}
                {', '}
              </span>))}
            {'and '}
            {this.renderIndex(last, addReviewLinks)}
          </span>);
    }
  }

  renderUserAnswer = () => (
    this.props.from !== "preview" &&
    <div>
      <div style={this.props.isAttended ? {pointerEvents:"auto"} : null }>
      <FormField
            style={{ marginBottom : "-25px"}}
            type={Textarea}
            name="comment"
            onChange={this.handleChange}
            value={this.state.comment}
            maxLength={180}
            placeholder="Why did you choose this option...?"
          />
          </div>
          <div className="test-comment-box-footer">
            <span className="footer-note">
              {`(maximum of 180 characters)`}
            </span>
          </div>
    </div>);

  renderExplanationDetails = (intro, indexList, descrip, key) => {
    const n = indexList.length;
    if (n === 0) {
      return null;
    }
    return (
      <div className="child" key={key}>
        <span>
          {intro && <span>{intro} {' '}</span>}
          {this.renderIndexList(indexList, true)}
          {1 < n ? ' are ' : ' is '}
          {descrip}
          {'.'}
        </span>
        {indexList.map((i) => this.props.comments[i] && (
          <div className="child" key={`${descrip}-${i}`}>
            <Emph>{this.props.choices[i].choice}</Emph>
            {' : '}
            {this.props.comments[i]}
          </div>))}
      </div>);
  }

  renderExplanation = () => {
    if (this.isSurveyQuestion()) {
      return;
    }

    const isCorrect = (i) =>
      this.props.answers.indexOf(this.props.choices[i].choice) !== -1;

    const correct = this.state.selected.filter(isCorrect);
    const incorrect = this.state.selected.filter((i) => !isCorrect(i));
    const choiceTexts = this.props.choices
      .map((choice) => {
        return choice.choice;
      });
    const missed = this.props.answers
      .map((choice) => {
        const i = choiceTexts.indexOf(choice);
        return this.state.selected.indexOf(i) === -1 ? i : -1;
      })
      .filter((i) => i !== -1);

    const allMissedDescrip =
      `the correct answer${1 < missed.length ? 's' : ''}`;
    const detailsArgs = 0 < correct.length ? [
      ['Well done, ', correct, 'correct'],
      [null, missed, 'also correct'],
      ['Unfortunately, ', incorrect, 'incorrect'],
    ] : [
        ['Unfortunately, ', incorrect, 'incorrect'],
        [null, missed, allMissedDescrip],
      ];

    return (
      <AnswerExplanation visible={this.state.submitted}>
        {detailsArgs.map((args, i) =>
          this.renderExplanationDetails.apply(this, [...args, i]))}
      </AnswerExplanation>
    );
  }
  handleChange =(e) =>{
    // e.stopPropagation();
    if(!this.state.selected == []){
      this.setState ({comment:e.value}); 
    const updatedSurveyDetails = {
      ...this.props.surveyDetails,
      questions: this.props.surveyDetails.questions.map(sec => {
        if (sec.question_order === this.props.questionOrder) {
          return {
            ...sec,
            user_selection: 
              { selected_id: this.state.selected, comment: e.value }
            
          };
        }
        return sec;
      })
    };
    // this.props.survey && this.props.onAnswered([e.target.value]);
    this.props.onAnswered(updatedSurveyDetails);
    }
  }

  render = () => (
    <div
      tabIndex="-1"
      style={{ outline: 'none' }}
      // onKeyDown={this.handleKeyDown}
      ref={(r) => { this.testR = r; }}
    >
      {/* <div className={`${css.title} text-color4`}> */}
        {/* Question */}
        {/* {this.isSurveyQuestion() ? "Survey" : "Question" + this.props.count} */}
        {/* {this.isSurveyQuestion() ? "Survey" : null} */}
      {/* </div> */}
      <div className="test-question">
      {/* <div  className={this.props.survey ? `test-question` :`${css.question} text-color2`}> */}
      {this.props.questionOrder}. {this.props.question}
      </div>
      <div>
      <Row >
        <Col md={6} >
        {this.renderChoices()}
        </Col>
        <Col md={6} >
        {this.renderUserAnswer()}
        </Col>
      </Row>
      </div>
      {this.renderExplanation()}
    </div>);
}


export default MultipleChoice
