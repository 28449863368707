import classNames from "classnames";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";
import css from "./circle-info.module.scss";

const InfoTime = ({ hour, minute }) => {
  return (
    <div className={css.time}>
      <div>
        {hour}
        <span>H</span>
      </div>
      <div>:</div>
      <div>
        {minute}
        <span>M</span>
      </div>
    </div>
  );
};

// const Doughnut = () => {
//   const theme = useTheme();

//   const perc = 15;
//   let deg = (perc / 100) * 360;

//   let bgBase = rgba(theme.color4, 0.1);
//   let bgCircle = theme.color4;

//   let deg1 = 90;
//   let deg2 = deg;

//   // If percentage is less than 50%
//   if (perc < 50) {
//     bgBase = theme.color4;
//     bgCircle = lighten(0.3, theme.color4);
//     deg1 = (perc / 100) * 360 + 90;
//     deg2 = 0;
//   }

//   const StyledDoughnut = styled.div`
//     background-color: ${bgBase};

//     .${css.slice} {
//       background-color: ${bgCircle};
//     }
//     .${css.slice1} {
//       transform: rotate(${deg1}deg);
//     }
//     .${css.slice2} {
//       transform: rotate(${deg2}deg);
//     }
//   `;

//   return (
//     <StyledDoughnut className={css.doughnut}>
//       <span className={classNames(css.slice, css.slice1)}></span>
//       <span className={classNames(css.slice, css.slice2)}></span>
//       <span className={classNames(css.center)}></span>
//     </StyledDoughnut>
//   );
// };

const StyledCircle = styled.div`
  && {
    background-color: ${({ theme }) => rgba(theme.primary, 0.1)};
  }
`;

function CircleInfo(props) {
  return (
    <div className={classNames(css.wrapper)}>
      <StyledCircle className={classNames(css.circle, "text-color4")}>
        {props.value}
      </StyledCircle>
      {props.title && (
        <div className={classNames(css.title, "text-color3")}>
          {props.title}
        </div>
      )}
    </div>
  );
}

CircleInfo.Time = InfoTime;

export default CircleInfo;
