import * as config from 'config';
import * as messages from 'messages';
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
// import * as theme from 'theme';




export const searchSorting = (a,b) => {
  const monPicker = (month) => {
    const monObj = {
      Jan: "01" ,
      Feb: "02" ,
      Mar: "03" ,
      Apr: "04" ,
      May: "05" ,
      Jun: "06" ,
      Jul: "07" ,
      Aug: "08" ,
      Sep: "09" ,
      Oct: "10" ,
      Nov: "11" ,
      Dec: "12" ,
    };
    const monNum = monObj[month]
    return monNum
  };
  const dateA = a.replace(",", "").split(" ");
  const dateB = b.replace(",", "").split(" ");
  const newDateB = new Date(
    dateB[2] + "-" + monPicker(dateB[0]) + "-" + dateB[1]
  );
  const newDateA = new Date(
    dateA[2] + "-" + monPicker(dateA[0]) + "-" + dateA[1]
  );
  return newDateA > newDateB ? 1 : newDateA < newDateB ? -1 : 0;
}


export const runningIOS = () => /iP(hone|od|ad)/.test(navigator.platform);

export const getIOSVersion = () => {
  const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
  return [
    parseInt(v[1], 10),
    parseInt(v[2], 10),
    parseInt(v[3] || 0, 10),
  ];
}

export const objectEmpty = (obj) =>
  Object.getOwnPropertyNames(obj).length === 0;

// Like delete(), except that it returns a new object
export const objectDelete = (o, key) => {
  // const { [key]: _x, ...rest } = o; FIXME why does this not work?
  const rest = { ...o };
  delete rest[key];
  return rest;
}

export const objectMap = (f) => (o0) => {
  const o = {};
  Object.getOwnPropertyNames(o0).forEach((name) => {
    o[name] = f(o0[name]);
  });
  return o;
}

export const objectFilter = (p) => (o0) => {
  const o = {};
  Object.getOwnPropertyNames(o0).forEach((name) => {
    const v = o0[name];
    if (p(v)) {
      o[name] = v;
    }
  });
  return o;
}

export const objectMergeWith = (f, o1, o2) => {
  const o = { ...o1 };
  Object.getOwnPropertyNames(o2).forEach((name) => {
    o[name] = name in o1 ? f(o1[name], o2[name]) : o2[name];
  });
  return o;
}

export const sleep = (secs) => new Promise((resolve) => {
  setTimeout(resolve, secs * 1000);
});

export const capitalizeWord = (word) =>
  !word ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

export const splitCamelCase = (ident) =>
  ident
    .split(/([A-Z][a-z0-9]*)/)
    .filter((s) => 0 < s.length)
    .map((s) => s.toLowerCase());

export const camelCaseToKebabCase = (ident) =>
  splitCamelCase(ident).join('-');

export const toInt = (str) => parseInt(str, 10);

export const randomInRange = (a, b) => a + (b - a) * Math.random();

export const mean = (xs) =>
  0 < xs.length ? xs.reduce((a, b) => a + b) / xs.length : null;

export const median = (xs) => {
  if (xs.length === 0) {
    return null;
  }
  const ys = [...xs];
  ys.sort((a, b) => a - b);
  return (ys[(ys.length - 1) >> 1] + ys[ys.length >> 1]) / 2;
}

export const fracToPercent = (n, d) =>
  0 < d ? Math.round(1000 * n / d) / 10 : null;

export const currentYear = () => new Date().getFullYear();

// export const getConfigurableColorCssName = (name) => (
//   theme.configurableColors[name].css || `--${camelCaseToKebabCase(name)}`
// );

export const getHomeUrl = (userType = null) => {
  switch (userType) {
    case 1: return config.memberUrl;
    case 2: return config.memberUrl;
    case 3: return config.memberUrl;
    case -1: return config.memberUrl;
    default: return '/';
  }
}

export const toValidationState = (
  ok,
  colorSuccess = true,
  colorError = true,
) => {
  if (ok && colorSuccess) {
    return 'success';
  }
  if (!ok && colorError) {
    return 'error';
  }
  return null;
}

export const validateEmail = (email) => {
  const re =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  return re.test(email) ? [] : [messages.mkEmailMalformedError()];
}

export const validatePhone = (phoneNumber) => {
  const re = /^\+?(?:[0-9] ?){6,11}[0-9]$/;
  return re.test(phoneNumber) ? [] : [messages.mkPhoneInvalidError()];
}

export const validateZoomPin = (zoomPin) => {
  const re = /^(\d{10})$/;
  return re.test(zoomPin) ? [] : [messages.mkZoomPinInvalidError()];
}

export const validateZoomPwd = (zoomPwd) => {
  const re = /^([a-zA-Z0-9_-]){10,50}$/;
  return re.test(zoomPwd) ? [] : [messages.mkZoomPwdInvalidError()];
}

export const validateZipCode = (zipCode, label) => {
  const re = /^(\d{4}|\d{5}|\d{6})$/;
  return re.test(zipCode) ? [] : [messages.mkZipCodeInvalidError(label)];
}

export const validateMaxStudent = (zoomPwd) => {
  const re = /^([0-9]|[1-9][0-9]|100)$/;
  return re.test(zoomPwd) ? [] : [messages.mkMaxStudInvalidError()];
}

export const validatePassword = (password) => {
  const msgs = [];
  if (password.length < config.minPasswordLength) {
    msgs.push(messages.mkPasswordShortError(config.minPasswordLength));
  }
  if (!(/[a-z]/.test(password))) {
    msgs.push(messages.mkPasswordNoLcError());
  }
  if (!(/[A-Z]/.test(password))) {
    msgs.push(messages.mkPasswordNoUcError());
  }
  if (!(/\d/.test(password))) {
    msgs.push(messages.mkPasswordNoNumError());
  }
  if (!(/[^0-9a-zA-Z]/.test(password))) {
    msgs.push(messages.mkPasswordNoSymError());
  }
  return msgs;
}

export const validateName = (name, label) => {
  const re = /^[a-zA-Z]{1}/;
  return re.test(name) ? [] : [`${capitalizeWord(label) ?? "Field"} should begin with an alphabet`];
}

export const validateUrl = (url) => {
  const re= /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return re.test(url) ? [] : ["Invalid URL"];
}

const setLocalStorage = (key, value) => {
  // getItem returns null if the key is not in local storage, so it
  // is unnecessary to store null values
  if (value == null) { // undefined or null
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}

// Since local storage only stores strings, we only store a boolean
// if it is true
const setLocalStorageBoolean = (key, value) => {
  setLocalStorage(key, value || null);
}

const getLocalStorageBoolean = (key) => {
  return localStorage.getItem(key) ? true : false;
}

export const setAuth = (token) => {
  setLocalStorage('auth', token);
}

export const getAuth = () => localStorage.getItem('auth');

export const getStayLoggedIn = () => getLocalStorageBoolean('stayLoggedIn');

export const setLoginEntry = ({ loginDetails }) => {

  setLocalStorage('loginId', loginDetails.id)
  setLocalStorage('loginDateTime', loginDetails.loginDateTime)
}

export const setSession = ({
  sessionId,
  auth,
  stayLoggedIn,
}) => {
  setAuth(auth);
  setLocalStorageBoolean('stayLoggedIn', stayLoggedIn);
  // sessionId must be set last, as we act on changes to it
  setLocalStorage('sessionId', sessionId);
}

export const clearSession = () => {
  setSession({});
}

export const getLoginDetails = () => {
  var loginDetails = {
    id: localStorage.getItem('loginId'),
    loginDate: localStorage.getItem('loginDateTime')
  }
  return loginDetails
}

export const getSessionId = () => localStorage.getItem('sessionId');

export const setActivityTimestamp = (ts) => localStorage.setItem('activity', ts);

export const cancelTimeout = (t) => {
  if (t) {
    clearTimeout(t);
  }
}

export const parseModuleStatus = (statusText, prevStatus) => (
  statusText === 'new' ? config.contentStatusE.NEW :
    statusText === 'pending' ? config.contentStatusE.UNVIEWED :
      statusText === 'in progress' ? config.contentStatusE.IN_PROGRESS :
        statusText === 'completed' ? config.contentStatusE.COMPLETED :
          statusText === 'scheduled' && prevStatus === config.contentStatusE.COMPLETED
            ? config.contentStatusE.UNVIEWED :
            config.contentStatusE.UNAVAILABLE);

export const getCurrentModule = (modules) => {
  const currentModule = {};
  const currentIdx = {};
  modules.forEach((m, i) => {
    const status = m.status === config.contentStatusE.NEW ?
      config.contentStatusE.UNVIEWED : m.status;
    const current = currentModule[status];
    if (!current || current.lastViewedTimeSecs < m.lastViewedTimeSecs) {
      currentModule[status] = m;
      currentIdx[status] = i;
    }
  });
  return (
    currentModule[config.contentStatusE.IN_PROGRESS] ||
    currentModule[config.contentStatusE.UNVIEWED] ||
    currentModule[config.contentStatusE.REVIEWING] ||
    modules[1 + currentIdx[config.contentStatusE.COMPLETED]] ||
    null
  );
}

export const getCurrentChapter = (chapters) => {
  let currentChapter = null;
  chapters.forEach((ch, i) => {
    if (ch.lastViewed) {
      currentChapter = ch;
    }
  });
  if (currentChapter === null && chapters) {
    // When no module is last viewed, first time coming to subject
    currentChapter = chapters[0];
  }
  return currentChapter;
}

export const scrollComponentToTop = (r) => {
  if (r) {
    const node = ReactDOM.findDOMNode(r);
    const parent = node.parentNode;
    parent.scrollTop = node.offsetTop - parent.offsetTop;
  }
}

export const scrollToElementBottom = (r) => {
  if (r) {
    const node = ReactDOM.findDOMNode(r);
    node.scrollTop = node.scrollHeight - node.clientHeight;
  }
}

export const getFileExtension = (path) => path.slice(((path.lastIndexOf('.') - 1) >>> 0) + 2);

export const addFullscreenChangeHandler = (fn) => {
  if ('onfullscreenchange' in document) {
    document.addEventListener('fullscreenchange', fn);
  } else if ('onmozfullscreenchange' in document) {
    document.addEventListener('mozfullscreenchange', fn);
  } else if ('onwebkitfullscreenchange' in document) {
    document.addEventListener('webkitfullscreenchange', fn);
  } else if ('onmsfullscreenchange' in document) {
    document.addEventListener('msfullscreenchange', fn);
  }
}

export const removeFullscreenChangeHandler = (fn) => {
  if ('onfullscreenchange' in document) {
    document.removeEventListener('fullscreenchange', fn);
  } else if ('onmozfullscreenchange' in document) {
    document.removeEventListener('mozfullscreenchange', fn);
  } else if ('onwebkitfullscreenchange' in document) {
    document.removeEventListener('webkitfullscreenchange', fn);
  } else if ('onmsfullscreenchange' in document) {
    document.removeEventListener('msfullscreenchange', fn);
  }
}

export const toHHMMSS = (totalSecs, fracDigits = 0) => {
  const hrs = Math.floor((totalSecs / 60) / 60);
  const mins = Math.floor((totalSecs / 60) % 60);
  let rawSecs = totalSecs % 60;

  // Deal with roundoff error
  const secs = Math.floor(rawSecs + Math.pow(0.1, 1 + fracDigits));
  rawSecs = Math.max(secs, rawSecs);

  const as2 = (x) => x < 10 ? `0${x}` : x;
  const baseStr =
    hrs === 0 ? `${mins}:${as2(secs)}` : `${hrs}:${as2(mins)}:${as2(secs)}`;
  if (fracDigits <= 0) {
    return baseStr;
  }

  const fracSecs =
    Math.floor(0.1 + (rawSecs - secs) * Math.pow(10, fracDigits));
  const fracStr = String(fracSecs).padStart(fracDigits, '0');
  return `${baseStr}.${fracStr}`;
}

export const toDateString = (date, longMonth = false, timeStamp = false) => {
  if(timeStamp){
    return date.toLocaleString(
      'en-us',
      {
        month: longMonth ? 'long' : 'short',
        day: 'numeric',
        year: 'numeric',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      }
    )
  } else{
    return date.toLocaleString(
      'en-us',
      {
        month: longMonth ? 'long' : 'short',
        day: 'numeric',
        year: 'numeric',
      }
    )
  }
};

export const getDateTimeToday = (date) => {
  var currentdate = new Date(date);
  var dateTime = currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " "
    + currentdate.getHours() + ":"
    + currentdate.getMinutes() + ":"
    + currentdate.getSeconds();
  return dateTime
}

export const sameDates = (ts1, ts2) =>
  Math.abs(ts1 - ts2) < 24 * 3600 * 1000 && ts1.getDay() === ts2.getDay();

// Return a new string obtained by inserting str2 before the idx'th character
// of str1; if the 0-based idx is negative, it is treated as str1.length + idx
export const insertString = (str1, str2, idx) =>
  `${str1.slice(0, idx)}${str2}${str1.slice(idx)}`;

export const rgbLightness = (rgbStr) => {
  let byteMax = 255;
  let byteLen = 2;
  if (rgbStr.length === 4) {
    byteMax = 15;
    byteLen = 1;
  }
  const parseHexByte = (i) =>
    parseInt(rgbStr.substring(1 + i * byteLen, 1 + (i + 1) * byteLen), 16);

  const r = parseHexByte(0);
  const g = parseHexByte(1);
  const b = parseHexByte(2);
  return 100 * (Math.min(r, g, b) + Math.max(r, g, b)) / (2 * byteMax);
}

const setLabelRoleSpecPlurals = objectMap((o) =>
  o && o.singular && !o.plural ? { ...o, plural: `${o.singular}s` } : o
);

export const mergeLabelRoleSpecs = (...specs) => {
  const f = (o1, o2) => ({ ...o1, ...o2 });
  return specs.reduce(
    (acc, s) => objectMergeWith(f, acc, setLabelRoleSpecPlurals(s || {})),
    {}
  );
}

export const mergeLabelRoleExceptions = (...specs) => {
  const f = (o1, o2) => ({ ...o1, ...o2 });
  return specs.reduce(
    (acc, s) => {
      const { additions, deletions } = s || {};
      Object.getOwnPropertyNames(deletions || {}).forEach((objName) => {
        delete acc[objName];
      });
      const removeBlanks = (o0) => {
        let o = o0;
        if (o) {
          o = { ...o0 };
          if (o.singular === '') {
            delete o.singular;
          }
          if (o.plural === '') {
            delete o.plural;
          }
        }
        return o;
      };
      const nonBlanks = objectMap(removeBlanks)(additions || {});
      return objectMergeWith(f, acc, setLabelRoleSpecPlurals(nonBlanks));
    },
    {}
  );
}

export const generateLabels = (defaults, exceptions) => {
  const spec = mergeLabelRoleSpecs(defaults, exceptions);
  const singular = {};
  const plural = {};
  const visible = {};
  Object.getOwnPropertyNames(spec).forEach((key) => {
    const objSpec = spec[key];
    if (objSpec) {
      const singularLabel = objSpec.singular || key;
      singular[key] = singularLabel;
      plural[key] = objSpec.plural || `${singularLabel}s`;
      visible[key] = objSpec.visible || 1;
    }
  });
  return { singular, plural, visible };
}

export const mkMemberActivityString = ({ type, name }, singularLabels) => {
  const curriculumLabel = singularLabels.curriculum;
  const chapterLabel = singularLabels.chapter;
  const moduleLabel = singularLabels.module;
  const action =
    type === 'Curriculum' ? `Completed ${curriculumLabel}` :
      type === 'Chapter' ? `Completed ${chapterLabel}` :
        type === 'Module' ? `Completed ${moduleLabel}` :
          type === 'Promotion' ? 'Received promotion' :
            type === 'Reward' ? 'Redeemed reward' :
              type === 'Message' ? 'Message ' :
                null;
  return action ? `${action} "${name}"` : 'Other';
}

export const mkTrainerActivityString = ({ type, name }, singularLabels) => {
  const curriculumLabel = singularLabels.curriculum;
  const capCurriculumLabel = capitalizeWord(curriculumLabel);
  const chapterLabel = singularLabels.chapter;
  const moduleLabel = singularLabels.module;
  return (
    type === 0 ? `Created ${curriculumLabel} "${name}"` :
      type === 1 ? `Created ${moduleLabel} "${name}"` :
        type === 2 ? `Created question(In Course) "${name}"` :
          type === 3 ? `Assigned own ${curriculumLabel} "${name}"` :
            type === 4 ? `Assigned ${curriculumLabel} "${name}"` :
              type === 5 ? `${capCurriculumLabel} "${name}" was assigned` :
                type === 6 ? `Created ${chapterLabel} "${name}"` :
                  type === 7 ? `Allocated ${chapterLabel} "${name}"` :
                    type === 8 ? `Message "${name}"` :
                      type === 9 ? `Created question(QB) "${name}"` :
                        'Other'
  );
}

export const withRouterHistory = (c) => withRouter(({
  match,
  location,
  history,
  ...restProps
}) => {

  const props = {
    ...restProps,
    match,
    location,
    routerHistory: history
  };
  return React.createElement(c, props);
});


export const trimString = (str, length = 30, ending = '...') => {
  if (!str) {
    return null;
  }
  if (!ending) {
    ending = '';
  }
  if (str.length > length) {
    return str.substring(0, length) + ending;
  } else {
    return str;
  }
}

export const apiDomainUrl = (url) => {
  const pjson = require('../../package.json');
  const domainUrl = pjson.proxy ? pjson.proxy : config.apiUrlDomain;
  return `${domainUrl}/${url}`;
  //return `${url}`;
}

export const getFileNameFromURL = (fileUrl) => {

  let fileName = null;
  fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  fileName = fileName.substring(fileUrl.lastIndexOf('\\') + 1);
  fileName = fileName.split('?')[0];
  fileName = fileName.replace(/[<>:"/\\|?*]+/g, '');
  return fileName;
}