import { InputField, OverlayTooltip, RadioButton } from "components";
import Video from "components/PlayerNew/Video";
import Audio from "components/PlayerNew/Audio";
import Pdf from "components/PlayerNew/Pdf";
import { withApiContext, withLabelsContext } from "context";
import {
  IconAssignmentType,
  IconChevronDown,
  IconChevronDownNew,
  IconChevronUp,
  IconChevronUpNew,
  IconEmptySection,
  IconFillUpType,
  IconMCQType,
  IconQuestionDelete,
  IconQuestionEdit,
  IconShortAnswerType,
  IconVoiceRecorder,
} from "Icons";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { styled, useTheme } from "styled-components";
import { TestContext, withTestContext } from "./Context/TestContex";
import css from "./testCreation.module.scss";
import Loader from "components/Spinners/Loader";
import * as config from "config";
import MathInput from "components/MathInput/MathInput";
import { convertLatexToMarkup } from "mathlive";

const EditButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  svg {
    fill: #4f4f4f;
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
  &:disabled {
    cursor: not-allowed;
    svg {
      fill: lightgray;
    }
  }
`;
const DeleteButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  svg {
    stroke: #4f4f4f;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.primary};
    }
  }
  &:disabled {
    cursor: not-allowed;
    svg {
      stroke: lightgray;
    }
  }
`;
function SectionCard(props) {
  const {
    testData,
    setTestData,
    resetTestData,
    question,
    setQuestion,
    sections,
    setSections,
  } = useContext(TestContext);
  const theme = useTheme();
  const { sectionOrder } = props;
  const [loader, setLoader] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [quesData, setQuesData] = useState({});
  
  const typeOptions = [
    {
      label: props.ctx_labels.singular.multipleChoice,
      value: config.testQuestionTypeQBE.MULTIPLE_CHOICE,
    },
    {
      label: props.ctx_labels.singular.fillInTheBlanks,
      value: config.testQuestionTypeQBE.FILL_IN_THE_BLANK,
    },
    {
      label: props.ctx_labels.singular.assignment,
      value: config.testQuestionTypeQBE.ASSIGNMENT,
    },
    {
      label: props.ctx_labels.singular.shortAnswer,
      value: config.testQuestionTypeQBE.COMMENT,
    },
    {
      label: props.ctx_labels.singular.multipleChoiceSingleOption,
      value: config.testQuestionTypeQBE.MULTIPLE_CHOICE_SINGLE,
    },
  ];
  useEffect(() => {
    setOpenAccordion(null);
  }, [sectionOrder]);

  const handleToggle = async (questions, index) => {
  if(questions.type !== 4){
  await fetchQuestionDtl(questions.id,"toggle");
  await fetchAnswers(questions.id);
  }
  else if(+questions.id < 0){
    setQuesData((prev) => {
      return{
        ...prev,
          question_id: questions.id,
          question_name: questions.question,
          category_id: testData.categoryId,
          curriculum_id: testData.curriculumId,
          question_credit: questions.points,
          question_type: questions.type,
          question_image: "",
          negative_credit: 0,
          neg_credit_percent:0,
          math_symbol: questions.math_symbol,
          content_format: questions.content_format,
          content: questions.content,
          file_name: questions.content,
          description: questions.question_content,
          content_source: questions.content_source,
          is_record: questions.is_record,
          tags: [],
          short_code: questions.shortCodes,
          solution: questions.solution,
      }
    })
  }
  else{
  if(+questions.id > 0 ){
  await fetchQuestionAssignmentDtl(questions.id,"toggle");
  }}
    if (!openAccordion) {
      setSections((prevSections) =>
        prevSections.map((section) => {
          if (section.section_order === sectionOrder) {
            return {
              ...section,
              questions: section.questions.map((que) => ({
                ...que,
                answer: que.answer??[],
              })),
            };
          }
          return section;
        })
      );
    }
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const fetchAnswers = async (selectedItemId) => {
    setLoader(true);
    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_question_answers",
        args: {
          questionId: selectedItemId.toString(),
        },
      });

      // const renameAnswerOption = rsp.map((obj) => {
      //   const { answer: option, ...rest } = obj; // Destructure answer key and rename to option
      //   return { ...rest, option }; // Spread remaining keys and add new option key
      // });
      setLoader(false);
      await handlePreviewAnswers(selectedItemId, rsp);
      return rsp; // Return the response to be used in the next .then
    } catch (error) {
      setLoader(false);
      console.error("Error fetching questions:", error);
      throw error; // Propagate the error to be handled later if needed
    }
  };

  const getMediaPlayable = async (contentFormat, content) => {
    const contentType = 
      contentFormat === "0" ? "audio" :
      contentFormat === "1" ? "video" :
      contentFormat === "2" ? "pdf" : 
      "video";
  
    try {
      const {rsp} = await props.ctx_api.privateRequest({
        cmd: "get_module_url",
        args: {
          contentType,
          fileName: content,
        },
      });
      if(rsp.success === true){
        setQuesData((prev)=> {
          return{
            ...prev,
            media_link:rsp.url
          }
        })
      }
      return rsp;
    } catch (error) {
      console.error("Error fetching media URL:", error);
      throw error; // Propagate the error to be handled later if needed
    }
  };
  
  const fetchQuestionDtl = async (selectedItemId,action) => {
    try {
      const { rsp } = await props.ctx_api.privateRequest({
        cmd: "get_question_dtl",
        args: {
          questionId: selectedItemId.toString(),
        },
      });
      const qData = rsp[0];
      setQuesData(qData)
      if(action==="edit"){
      setQuestion((prev) => {
        return {
          ...prev,
          content: qData.content,
          content_source: qData.content_source,
          content_format: qData.content_format,
          question:qData.question_name,
          points: qData.question_credit,
          shortCodes: qData.short_code,
          question_content:qData.description,
          solution:qData.solution,
          difficulty_level:qData.difficulty_level ? qData.difficulty_level : null,
          negative_points: qData.negative_credit?.trim()==="0.00" ? "0" : qData.negative_credit,
          negativeCreditPercent:qData.neg_credit_percent,
          origImage:qData.content,

        };
      });
    }
    return rsp; // Return the response to be used in the next .then
  } 
  catch (error) {
    console.error("Error fetching questions:", error);
    throw error; // Propagate the error to be handled later if needed
  }
};
const fetchQuestionAssignmentDtl = async (selectedItemId, action) => {
  try {
    const { rsp } = await props.ctx_api.privateRequest({
      cmd: "get_assignment_dtl",
      args: {
        questionId: selectedItemId.toString(),
      },
    });
    
    const aData = rsp[0];
    setQuesData((prev) => {
      return{
        ...prev,
        question_id: aData.test_question_id,
        question_name: aData.question_name,
        category_id: aData.category_id?.toString(),
        curriculum_id: aData.curriculum_id?.toString(),
        question_credit: aData.credit,
        question_type: 4,
        question_image: "",
        negative_credit: aData.negative_credit,
        neg_credit_percent: 0,
        math_symbol: aData.math_symbol,
        content_format: aData.asgnmt_content_format?.toString(),
        content: aData.assignment_content,
        file_name: aData.assignment_content,
        description: aData.question_content,
        content_source: aData.asgnmt_content_source,
        is_record: null,
        tags: aData.tags,
        short_code: aData.short_code,
        solution: aData.solution,
      }
    })
    if (action === "edit") {
      setQuestion((prev) => {
        return {
          ...prev,
          content_format: aData.asgnmt_content_format?.toString(),
          content_source: aData.asgnmt_content_source,
          content: aData.assignment_content,
          points: aData.credit,
          shortCodes: aData.short_code,
          solution: aData.solution === null ? "" : aData.solution,
          negativeCreditPercent: aData.neg_credit_percent,
          negative_points: aData.negative_credit?.trim() === "0.00" ? "0" : aData.negative_credit,
          question: aData.question_name,
          difficulty_level: aData.difficulty_level ? aData.difficulty_level : null,
          solution:aData.solution
        };
      });
    }
    
    return rsp; // Return the response to be used in the next .then
  } catch (error) {
    console.error("Error fetching questions:", error);
    throw error; // Propagate the error to be handled later if needed
  }
};


  const handlePreviewAnswers = async (selectedItemId, rsp) => {
    return new Promise((resolve) => {
      setSections((prevSections) => {
        const newSections = prevSections.map((section) => ({
          ...section,
          questions: section.questions.map((question) => {
            if (question.id === selectedItemId) {
              return {
                ...question,
                answer: rsp,
              };
            }
            return question;
          }),
        }));
        resolve(newSections);
        return newSections;
      });
    });
  };

  const inlineBackground = {
    "--bg-color-1": theme.primary,
  };

  const findQuestionArray = (selected) => {
    return sections.find((obj) => obj.section_order === selected);
  };
  const questionArray = findQuestionArray(sectionOrder);

  useEffect(() => {
    questionCards();
  }, [questionArray]);

  useEffect(() => {
    if (
      quesData.content !== "" &&
      quesData.content !== null &&
      quesData.content_format !== "" &&
      quesData.content_format !== null &&
      quesData.content_format !== undefined &&
      quesData.content_source === "aws"
    ) {
      getMediaPlayable(quesData.content_format, quesData.content);
    }
  }, [openAccordion]);

  const handleMark = (e, card) => {
    const value = e.target.value;
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.section_order === sectionOrder) {
          return {
            ...section,
            questions: section.questions.map((question) =>
              question.id === card.id
                ? { ...question, points: value }
                : question
            ),
          };
        }
        return section;
      })
    );
  };
  const modifyKeys = (array) => {
    return array.map(({ answer, ...rest }) => ({ option: answer, ...rest }));
  };

  const handleEditQuestion = async (questions) => {
    setOpenAccordion(null);
    props.setContentButton((prev)=>{
      return{
        createQuestion: true,
        addLibrary: false,
        sectionEdit: false,
        feedback: false,
        questionReorder: false,
        sectionReorder: false,
        testEdit: false,
      }
    })
      
    try {
      // if (questions.type !== 4){
      // // Always call fetchQuestionDtl
      // }
     
        if(+questions.id > 0 && questions.type === 4 ){
        await fetchQuestionAssignmentDtl(questions.id,"edit");
      }
      if (questions.type !== 4) {
        await fetchQuestionDtl(questions.id,"edit"); 
        const response = await fetchAnswers(questions.id); // Call fetchAnswers if type is not 3 or 4
        setQuestion((prev) => ({
          ...prev,
          ...questions,
          order: questions.order,
          difficulty_level: questions.difficulty_level?.toString(),
          answer: response,
          action: "update",
        }));

        const modifiedArray = modifyKeys(response);
        props.setModfiedAnswer(modifiedArray);
      } else {
        setQuestion((prev) => ({
          ...questions,
          order: questions.order,
          action: "update",
        }));
      }
    } catch (error) {
      console.error("Error handling edit question:", error);
    }
  };

  const handleDeleteQuestion = (questions) => {
    const sectionIndex = sections.findIndex((section) =>
      section.questions.some((question) => question.id === questions.id)
    );

    if (sectionIndex !== -1) {
      const questionIndex = sections[sectionIndex].questions.findIndex(
        (question) => question.id === questions.id
      );

      if (questionIndex !== -1) {
        const updatedQuestions = [...sections[sectionIndex].questions];
        updatedQuestions.splice(questionIndex, 1);

        updatedQuestions.forEach((question, index) => {
          question.order = index + 1;
        });

        const updatedSections = [...sections];
        updatedSections[sectionIndex] = {
          ...updatedSections[sectionIndex],
          questions: updatedQuestions,
        };

        setSections(updatedSections);
      }
    }
  };

  const getIconComponent = (questionType) => {
    switch (questionType) {
      case 4:
        return <IconAssignmentType height = "18px" />;
      case 1:
        return <IconFillUpType height = "18px"/>;
      case 0:
      case 6:
        return <IconMCQType height = "18px"/>;
      case 3:
        return <IconShortAnswerType height = "18px"/>;
      default:
        return <IconAssignmentType height = "18px"/>;
    }
  };
  const questionCards = () => {
    return (
      <>
        {questionArray?.questions?.length > 0 ? (
          questionArray?.questions?.map((questions, index) => {
            const isOpen = openAccordion === index;
            const mediaVisible =
              questions.content_format !== ""  &&
              questions.content_format !== null &&
              questions.content_format !== undefined 
              const label = typeOptions.filter((lbl) => lbl.value === questions.type);
              const difficultylvl = questions.difficulty_level !== null ? true : false
              const diffLevel = difficultylvl && props.difficultyLevels.filter((itm) => itm.value === (questions.difficulty_level?.toString()));
               const hasValidImage = questions.answer.some(item => item.image && item.image !== "" && item.image !== null && item.image !== undefined);
               const quesName =  questions.question
            return (
              <Accordion key={index} activeKey={isOpen ? "0" : ""}>
                <Card className={css.questionCard}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className={css.quecardHeader}
                    onClick={() => handleToggle(questions, index)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className={css.quesrow}>
                      <span className={css.queNo}>
                        Qn.{questions.order} &nbsp;
                      </span>
                      <span className={css.queNo} style={{ fontWeight: 500 }}>
                        {questions.math_symbol === 1 ?  
                        // <span dangerouslySetInnerHTML={{ __html: quesName}} />
                        <MathInput id = "crtTest" disabled={true} value={quesName} />
                        : quesName}
                      </span>
                    </div>
                    {isOpen ? <IconChevronUpNew /> : <IconChevronDownNew />}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className={css.queCardBody}>
                      {quesData.question_type !== 3 &&
                      quesData.question_type !== 4 ? (
                        <Row style={hasValidImage ? {display:"flex",flexDirection:"column",gap:"30px"} : {} }>
                          {hasValidImage ? (
                            <Col
                            md={12}
                            className={ css.optionDivNoMedia}
                            >
                              {loader ? (
                                <Loader width={35}  />
                              ) : (
                                questions.answer.map((item, index) => {
                                  const trimOpt =
                                    item.answer !== "" &&
                                    (item.answer?.length > 35
                                      ? item.answer.slice(0, 50) + "..."
                                      : item.answer);
                                  return (
                                    <div
                                      className={css.optionRadio}
                                      key={index}
                                    >
                                      <div>
                                      <OverlayTooltip
                                        placement="top"
                                        tip={item.answer}
                                        fromTestSec
                                        disabled={item.answer?.length < 50}
                                      >
                                        <RadioButton
                                          checked={
                                            questions.type === 1
                                              ? true
                                              : item.is_correct_ans === 1
                                          }
                                          onChange={() => {}}
                                        >
                                          {questions.math_symbol === 1 ? 
                                          <MathInput id = "crtTest" disabled={true} value={trimOpt} />
                                          : trimOpt}
                                        </RadioButton>
                                      </OverlayTooltip>
                                      </div>
                                      {item.image!=="" && 
                                      <img
                                    src={item.image}
                                    style={{ height: "130px", width: "130px"}}
                                  />}
                                    </div>
                                  );
                                })
                              )}
                            </Col>
                          ) : (
                            <Col
                              md={mediaVisible ? 6 : 12}
                              className={
                                mediaVisible === true
                                  ? css.optionDiv
                                  : css.optionDivNoMedia
                              }
                            >
                              {loader ? (
                                <Loader width={35} active />
                              ) : (
                                questions.answer.map((item, index) => {
                                  const trimOpt =
                                    item.answer !== "" &&
                                    (item.answer?.length > 35
                                      ? item.answer.slice(0, 50) + "..."
                                      : item.answer);
                                  return (
                                    <div
                                      className={css.optionRadio}
                                      key={index}
                                    >
                                      <OverlayTooltip
                                        placement="top"
                                        tip={item.answer}
                                        fromTestSec
                                        disabled={item.answer?.length < 50}
                                      >
                                        <RadioButton
                                          checked={
                                            questions.type === 1
                                              ? true
                                              : item.is_correct_ans === 1
                                          }
                                          onChange={() => {}}
                                        >
                                          {questions.math_symbol === 1 ? 
                                          <MathInput id = "crtTest" disabled={true} value={trimOpt} />
                                          : trimOpt}
                                        </RadioButton>
                                      </OverlayTooltip>
                                    </div>
                                  );
                                })
                              )}
                            </Col>
                          )}

                          {mediaVisible === true && (
                            <Col
                              md={(hasValidImage && mediaVisible) ? 12 :6}
                              style={
                                hasValidImage ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems:"center"
                                }
                                :
                                quesData.content_format === "3"
                                  ? {
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }
                                  : {}
                              }
                            >
                              <div style={hasValidImage ? {width:"30rem"} : {}}>
                              {(quesData.content_format === "0" ||
                                quesData.content_format === "1") &&
                                quesData.content !== "" && (
                                  <Video
                                    url={quesData.content_source==="aws" ? quesData.media_link : quesData.content}
                                    // videoUrl={enteredUrl}
                                    onLoaded={() => {}}
                                    onError={() => {}}
                                    className={"video-player"}
                                  />
                                )}
                              {quesData.content_format === "2" &&
                                quesData.content !== "" && quesData.media_link !== undefined &&(
                                  <Pdf
                                    pdfUrl={quesData.content_source==="aws" ? quesData.media_link : quesData.content}
                                    onLoaded={() => {}}
                                    onError={() => {}}
                                    fromCreateTest
                                    sectionPdf
                                  />
                                )}
                              {quesData.content_format === "3" &&
                                quesData.content !== "" && (
                                  <img
                                    src={quesData.content}
                                    // style={{ height: "15.8rem", width: "auto" }}
                                  />
                                )}
                                </div>
                            </Col>
                          )}
                        </Row>
                      ) : (
                        <Row>
                          {quesData.is_record === 1 && (
                            <Col md={12}>
                              <div
                                className={css.center}
                                style={{ flexDirection: "column" }}
                              >
                                <IconVoiceRecorder
                                  fill={theme.primary}
                                  width="40px"
                                />
                                The answer can also be submitted in an audio
                                format.
                              </div>
                            </Col>
                          )}

                          <Col md={12}>
                            {(quesData.content_format === "0" ||
                              quesData.content_format === "1") &&
                              quesData.content !== "" && (
                                <Video
                                  url={quesData.content_source==="aws" ? quesData.media_link : quesData.content}
                                  // videoUrl={enteredUrl}
                                  onLoaded={() => {}}
                                  onError={() => {}}
                                  className={"video-player"}
                                />
                              )}
                            {quesData.content_format === "2" &&
                              quesData.content !== "" && (
                                <Pdf
                                pdfUrl={quesData.content_source==="aws" ? quesData.media_link : quesData.content}
                                  onLoaded={() => {}}
                                  onError={() => {}}
                                  fromCreateTest
                                  sectionPdf
                                />
                              )}
                            {quesData.content_format === "3" &&
                              quesData.content !== "" && (
                                <img
                                  src={quesData.content}
                                  style={{ height: "15rem", width: "auto" }}
                                />
                              )}
                          </Col>
                        </Row>
                      )}
                      {quesData.solution !== null &&
                        quesData.solution !== "" && (
                          <Row>
                            <Col md={12} className={css.solutionRow}>
                              <div
                                className={css.queCardSolution}
                                style={inlineBackground}
                              >
                                {quesData.solution}
                              </div>
                            </Col>
                          </Row>
                        )}
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Footer className={css.questionCardFooter}>
                    <div className={css.queFooterQType}>
                      <div className={css.footerType}>
                        {getIconComponent(questions.type)} &nbsp;{" "}
                        {label[0].label}
                      </div>
                      {questions.type !== 4 &&
                        questions.difficulty_level &&
                        questions.difficulty_level !== null && (
                          <div className={css.footerDifflvl} style={inlineBackground}>
                            {diffLevel[0]?.label}
                          </div>
                        )}
                    </div>
                    <div className={css.queFooterButtons}>
                      <input
                        className={`${css.queMark} ${css.noArrows}`}
                        style={inlineBackground}
                        type="number"
                        value={parseFloat(questions.points)}
                        onChange={(e) => handleMark(e, questions)}
                        disabled={
                          props.contentButton.sectionEdit === true ||
                          question.id === questions.id
                        }
                      />
                      <EditButton
                        onClick={() => handleEditQuestion(questions)}
                        disabled={
                          props.contentButton.sectionEdit === true ||
                          question.id === questions.id
                        }
                      >
                        <IconQuestionEdit />
                      </EditButton>
                      <DeleteButton
                        onClick={() => handleDeleteQuestion(questions)}
                        disabled={
                          props.contentButton.sectionEdit === true ||
                          question.id === questions.id
                        }
                      >
                        <IconQuestionDelete />
                      </DeleteButton>
                    </div>
                  </Card.Footer>
                </Card>
              </Accordion>
            );
          })
        ) : (
          <div className={css.sectionEmpty}>
            <IconEmptySection />
            No questions have been created, they will be displayed here once
            available.
          </div>
        )}
      </>
    );
  };
  return questionCards();
}

export default withLabelsContext(withApiContext(SectionCard));
