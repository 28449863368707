import Select from "components/Select/Select";
import {
  withApiContext,
  withLabelsContext,
  withUserContext,
  withWindowContext,
} from "context";
import * as messages from "messages";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Row, Button } from "react-bootstrap";
import EntityForm from "pages/Entity/Form";
import * as util from "services/util";
import JoditEditor from "jodit-react";
import BlockUpload from 'components/BlockUpload/BlockUpload';
import QuestionForm from "./QuestionForm";
import './QuestionForm.css';
import StudentSurveys from "pages/StudentSurvey/StudentSurveys";


class SurveyForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sectionName: "Section",
      publishName: "Publish",
      publishSections: null,
      testId: "",
      testFlag: false,
      isModified: false,
      preview: [],
      questionIds: [],
      questionImages: [],
      isAnswerEmpty: true,
      allClearState: false,
      joditDescription: "",
      answerField: "",
      fromUpdate: false,
      name: "",
      modified: false,
      status: false
      // section: [this.questionSection()]
    };
    this.fields = this.mkFields(props);
  }

  static propTypes = {
    entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    action: PropTypes.string.isRequired,
  };

  mkFields = (props) => {
    const questionValidators = {
      onBlur: this.validateQuestion,
    };
    const fields = {
      name: { init: "" },
      identifier: { init: "", label: "short Name" ,optional: true, },
      curriculumId: { init: "0", optional: false, label: "Subject" }, //todo: make non optional
      // from: { init: null, label: "From", optional: true },
      // to: { init: null, label: "To", optional: true },
      channelOptions: { init: null, optional: true, label: "Channel" },
      surveyImage: { init: null, optional: true, label: this.labelNames('Image') },
      description: { init: "", optional: true },
      image: { init: "", optional: true },
      standardId: { init: null, optional: true, label: "Standard" },
      standardOptions:{init: null, optional: true, label: "Standard"},
      // publish: { init: false },
      mark: { init: "", label: "Total Mark", optional: true },
      courseTag: { init: "", optional: true, label: this.labelNames("courseTag") },
      // question: { init: "" },
      // questionType: { init: config.surveyQuestionOptions[0], optional: true },
      answerType: { init: "0", optional: true },
      questionShowList: { init: [], optional: true },
      ratingOptionType: { init: "", optional: true },
      answerField: { init: "", optional: true },
      answerOptions: { init: "", optional: true },
      questionSection: {
        init: [this.questionSection()],
        optional: false,
        // label:  props.ctx_labels.singular.question,
        validators: questionValidators,
        // invalidTipFn: questionTipFn,
        // depValidators: questDepValidators,
      },
    };
    return fields;
  };

  validateQuestion = ({ questionSection }) => {
    let msgs = [];
    let question = false;
    let choices = false;
    let questiontype = false;
    let choiceType = false;
    let ratingType = false;
    let emptyChoice = false;
    questionSection.forEach((qu, i) => {
      qu.questions &&
        qu.questions.forEach((s0) => {
          question = question || !s0.question;
          if (s0.questionType == 0 || s0.questionType == 2) {
            if (s0.choices.length === 0) {
              choices = choices || true;
            }
          }
          if (s0.questionType == 0 &&  s0.choiceType == "3") {
            if ((s0.choices.length > 0 && s0.choices.some(choice => choice.choice === ""))) {
              emptyChoice = emptyChoice || true;
            }
          }
          if (s0.questionType == null) {
            questiontype = questiontype || true;
          }
          if (s0.questionType == 0 && s0.choiceType == null) {
            choiceType = choiceType || true;
          }
          if (s0.questionType == 2 && s0.ratingOptionType == "") {
            ratingType = ratingType || true;
          }
        });
    });
    if (question || choices || questiontype || choiceType || ratingType || emptyChoice) {
      msgs = [""];
    }
    return msgs.length === 0 || msgs[0];
  };
  

  questionSection = () => ({
    sectionName : "section",
    sectionOrder : 1,
    questions : [this.initSection()],
    openSection : true
  })

  initSection = (s = 1, pid="Section") => ({    
    question: "",
    choices:[],
    question_order: s,
    questionType:"0",
    multipleChoiceType : "scale",
    choiceType: "",
    ratingOptionType: "",
    open: true,
  });

  labelNames = (k) => {
    const label = this.props.ctx_labels.singular[k];
    return label;
  };

  fetch = async (entity) => {
    const { rsp } = await this.props.ctx_api.privateRequestRow({
      cmd: "get_survey_dtl",
      args: { testId: entity.id ? entity.id : this.state.testId },
    });
    const updatedSection = rsp.questionlist.map((sectionItem) => {
      const updatedQuestions = sectionItem.questions.map((questionItem) => ({
        ...questionItem,
        isModified: true,
        open: false,
      }));
      return {
        ...sectionItem,
        questions: updatedQuestions,
      };
    });
//change modified to false to enable question type -31-07-24 
    this.setState({
      joditDescription: rsp.description,
      fromUpdate: true,
      section: updatedSection,
      modified : false
    });
    return {
      name: rsp.name,
      identifier: rsp.identifier,
      description: rsp.description,
      curriculumId: rsp.curriculum_id || "0",
      standardOptions: rsp.standard,
      mark: parseFloat(rsp.credit),
      channelOptions: rsp.channel,
      courseTag: rsp.course_id || [],
      surveyImage: rsp.image,
      questionSection : updatedSection
    };
  };

   submit = async (s) => {
    const testId = this.props.entity
      ? this.props.entity.id
      : this.state.testId
        ? this.state.testId
        : "0";
    let args = {
      testId,
    name: s.name,
    // identifier: s.identifier,
    description: s.description,
    isActive: 1,
    image: s.surveyImage,
    channelOptions: s.channelOptions,
    // standard: s.standardOptions,
    questionAnswers : JSON.stringify(s.questionSection.map((sec, i) => {
      return {
        sectionName : sec.sectionName,
        sectionOrder: sec.sectionOrder,
        questions: sec.questions.map((s,i) => {
          return {
            question: s.question,
            question_order: s.question_order,
            questionType: s.questionType,
            multipleChoiceType: s.multipleChoiceType,
            choiceType: s.choiceType,
            ratingOptionType: s.ratingOptionType,
            choices: s.choices
          };
        }),
      };
    })
    )
    };
    const { rsp } = await this.props.ctx_api.privateRequestRow({
      cmd: "insert_update_survey_2",
      args,
    });
    if (rsp.statusValue === 0) {
      throw new Error(`System error (${rsp.StatusText})`);
    }
    return this.validateUpdateTestResponse(rsp, s);
  };

  validateUpdateTestResponse = (rsp, s) => {
    console.log(rsp)
    const validations = {};

    if (rsp.StatusValue !== 1) {
      if (rsp.StatusValue === 2) {
        validations.name = messages.mkTestExistError();
        this.props.ctx_window.alert(
          `${util.capitalizeWord(
            this.props.ctx_labels.singular.survey
          )} already exist`
        );
      } else {
        throw new Error("System error");
      }
    }
    return { StatusValue: rsp.statusvalue, id: rsp.test_id, entityName: s.name, validations };
  };

  handleClear = () => {
    this.setState({ preview: [], allClearState: true })

  }

  renderLeftBox = ({ ValidatedField, onChange, onClick, s }) => {
    const options = [
      { label: "Module", value: 0 },
      { label: "Course", value: 1 },
      { label: "Assessment", value: 2 },
      { label: "Virtual Class", value: 3 },

    ]

    const handleChange = ({ name, value }) => {
      const updater = (s0) => {
        const deltaS = { [name]: value };
        return deltaS;
      };
      onChange({ name, updater });
      // this.setState({ isModified: true });
    };

    return (
      <>
        <Col sm={6}>
          <Row>
            <Col sm={6}>
              <ValidatedField name="name" onChange={handleChange}/>
            </Col>
            {/* <Col sm={6}>
              <ValidatedField name="identifier" />
            </Col> */}
            <Col sm={6}>
              <ValidatedField
                name="channelOptions"
                label={"Channel"}
                type={Select}
                id="channel_options"
                options={options}
                placeholder={`Select Channel`}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col sm={6}>
           <ValidatedField
                name="standardOptions"
                label={"Standard"}
                type={Select}
                id="standard"
                options={this.props.ctx_user.user.campusCategories}
                //options={options}
                placeholder={`Select standard`}
                onChange={handleChange}
              />
            </Col>  */}
            {/* <Col sm={6}>
              {<ValidatedField
                type={BlockUpload}
                uploadType="image"
                name="surveyImage"
                imageName="logo"
                isRemove
              />}
            </Col> */}
          </Row>
        </Col>
      </>
    );
  };


  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  joditConfig = {
    readonly: false,
    height: "235px",
    allowResizeY: false,
    hidePoweredByJodit: true,
    removeButtons:
      "about,class-span,copy-format,clipboard,color,font,fullsize,indent,hr,justify,limit,link,ordered-list,search,select,resize-handler,symbols,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table,print,preview,sticky,align,size,redo-undo,clean-html,powered-by-jodit,source",
  };

  renderRightBox = ({ ValidatedField, onChange }) => {

    const handleChange = ({ name, value }) => {
      const { joditDescription } = this.state;
      const metadataOnly = joditDescription !== null && joditDescription === value;
      const updater = (s0) => {
        const deltaS = { [name]: value };
        if (value !== s0.description) {
          deltaS.description = value;
        }
        return deltaS;
      };
      onChange({ name, updater, metadataOnly });
    }

    return (
      <Col md={6}>
        <Row>
          <Col md={12}>
            <ValidatedField
              type={JoditEditor}
              name="description"
              onChange={handleChange}
              config={this.joditConfig}
              editorRef={this.setRef}
            />

          </Col>
        </Row>
      </Col>
    );
  };

  handleChangeSection = (question,s) => {
    this.setState({ section: question , isModified: s});
  }
  handleValueChange =()=>{
    this.setState({ status: true});
  }

  renderGeneralTab = (props) => ({
    element: (
      <Row>
        {this.renderLeftBox(props)}
        {this.renderRightBox(props)}
      </Row>
    ),
  });

  renderQuestionTab = ({s, onChange, ValidatedField}) => {
    const handleChange = ({ name, value }) => {
      const updater = (s0) => {
        const deltaS = { [name]: value };
        return deltaS;
      };
      onChange({ name, value, updater });
  
    }
    return {
      element:
      <ValidatedField 
      name="questionSection"
      type={QuestionForm}
      onChange={handleChange}
      value = {s.questionSection}
      answers = {this.state.surveyAnswers}
      questionSection = {this.handleChangeSection}
      modified={this.state.modified}
      label={null}
      status={this.state.status}
    />
    };
  };

  renderPreviewTab = ({s}) => {
    
    return {
      element:
      <StudentSurveys 
      name="preview"
      // type={QuestionForm}
      onValueChange={this.handleValueChange}
      value = {s.questionSection[0]}
      preview={true}
      // answers = {this.state.surveyAnswers}
      // questionSection = {this.handleChangeSection}
      modified={true}
    />
    };
  }


  render = () => {
    const { ...restProps } = this.props;
    const tabRenderers = {
      general: this.renderGeneralTab,
      questions: this.renderQuestionTab,
      preview: this.renderPreviewTab
      //publishings: this.renderPublishTab,
    };
    return (
      <EntityForm
        {...restProps}
        className="test-form"
        fields={this.fields}
        fetch={this.fetch}
        submit={this.submit}
        tabRenderers={tabRenderers}
        onClear={this.handleClear}
      />
    );
  };
}

export default withApiContext(
  withUserContext(withLabelsContext(withWindowContext(SurveyForm)))
);
