import React, { Component } from "react";
import { Header } from "components";
import { BrowserRouter as Router } from "react-router-dom";
import { withUserContext } from "context";
import { getSessionId } from "services/util";
import { Col, Container, Row } from "react-bootstrap";
import css from "./error-boundary.module.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    // console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log("error--", error);
    console.log("errorInfo--", errorInfo);
  }
  goBack = () => {
    window.location.assign("/");
  };

  render() {
    if (this.state.hasError) {
      const sessionId = getSessionId();
      // You can render any custom fallback UI
      return (
        <Router>
          <Header loggedIn={sessionId ? true : false} noSidebar={false} />

          <Container>
            <div className={css.error_page}>
              <div className={css.container}>
                <Row>
                  <Col md={12} mdOffset={1}>
                    <div className={css.error_page_title}>
                      Something went wrong.
                    </div>
                    <div className={css.error_page_description}>
                      {/* <Col md={12} mdOffset={3}>
                        Please go back to dashboard
                      </Col> */}

                      <Col>
                        <div
                          type="button"
                          value="Back"
                          onClick={this.goBack}
                          className={css.back_button}
                        >
                          Back to home
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </Router>
      );
    }

    return this.props.children;
  }
}

export default withUserContext(ErrorBoundary);
