import React from 'react';


export const Spin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="uil-ring-alt" {...props}>
    <circle cx="50" cy="50" r="45" stroke="#757575" fill="none" strokeWidth="7" />
    <circle cx="50" cy="50" r="45" stroke="#ffffff" fill="none" strokeWidth="10" strokeDasharray="282.74">
      <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="287.74" to="-287.74" />
    </circle>
  </svg>
  // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" cx="0px" cy="0px"
  //    width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;">
  //   <rect cx="0" cy="10" width="4" height="10" fill="#333" opacity="0.2">
  //     <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
  //   </rect>
  //   <rect cx="8" cy="10" width="4" height="10" fill="#333"  opacity="0.2">
  //     <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
  //   </rect>
  //   <rect cx="16" cy="10" width="4" height="10" fill="#333"  opacity="0.2">
  //     <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
  //     <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
  //   </rect>
  // </svg> 
  );
