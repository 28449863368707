import "./index.css";
import Pagination from "react-bootstrap/Pagination";
import { React } from "react";

function SurveyPagination(props) {
  const { onChange, pageIndex, pageSize, totalCount } = props;
  const finalPage = Math.ceil(totalCount / pageSize);
  const startRange = (pageIndex - 1) * pageSize + 1;
  const endRange = Math.min(pageIndex * pageSize, totalCount);
  const handleChange = (action) => {
    let navigateTo = 1;
    switch (action) {
      case "first":
        navigateTo = 1;
        break;
      case "prev":
        navigateTo = pageIndex !== 1 ? pageIndex - 1 : pageIndex;
        break;
      case "next":
        navigateTo = pageIndex !== finalPage ? pageIndex + 1 : pageIndex;
        break;
      case "last":
        navigateTo = finalPage;
        break;
      default:
        break;
    }
    props.onChangePage(navigateTo, action);
  };

  const pageNumbers = `showing ${startRange}-${endRange} of ${totalCount} results`;
  return (
    <Pagination className="pagination_button">
      <div className="pagination_number">
        {pageNumbers}
      </div>
      {props.totalCount > 8 ? (
        <Pagination.First style={{ position: "relative" }} onClick={() => handleChange("first")} />
      ) : (
        <Pagination.First />
      )}
      {props.totalCount > 8 ? (
        <Pagination.Prev style={{ position: "relative" }} onClick={() => handleChange("prev")} />
      ) : (
        <Pagination.Prev />
      )}
      {props.totalCount > 8 ? (
        <Pagination.Next style={{ position: "relative" }} onClick={() => handleChange("next")} />
      ) : (
        <Pagination.Next />
      )}
      {props.totalCount > 8 ? (
        <Pagination.Last style={{ position: "relative" }} onClick={() => handleChange("last")} />
      ) : (
        <Pagination.Last />
      )}
    </Pagination>
  );


}

export default SurveyPagination;
