import * as util     from 'services/util';
import Api                 from 'data/api';

const api = new Api();

const convertedVapidKey = urlBase64ToUint8Array("BLp2yGpGrW78yQX5hcHo1M4JctyRmrqVPzXirwaJYhroxgw4J29R8kNSbe0yZsCJlH6UlTuf11ZUpeY2ZWPdUNU")

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const privateApiRequest = (req) =>
    new Promise((resolve, reject0) => {
      const auth = util.getAuth();
      if (!auth) {
        if (reject0) {
          // FIXME restore this once we handle multi-tab logouts properly
          // reject0(new Error('user not logged in'));
          console.log('user not logged in');
        }
        return;
      }
      const reject = (err) => {
        if (err.code === 403) {
          this.logOutOnAuthError();
        }
        if (reject0) {
          reject0(err);
        }
      }
      api.submitRequest({
        ...req,
        resolve,
        reject,
        auth,
      });
    });


  // const privateApiRequestRow = (req) =>
  //   privateApiRequest({ ...req, rowCount: 1 });

export async function  sendSubscription(subscription)  {

  return await privateApiRequest({
    cmd: "subscribe_notification",
    args:{
    subscription: JSON.stringify(subscription)}

  });

  

  // return fetch('http://localhost:3000/api/v0.1/subscribe_notification', {
  //   method: 'POST',
  //   body: JSON.stringify(subscription),
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // })
}

export function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if (!registration.pushManager) {
        console.log('Push manager unavailable.')
        return
      }

      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          console.log('No subscription detected, make a request.')
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            console.log('New subscription added.')
            sendSubscription(newSubscription)
          }).catch(function(e) {
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          console.log('Existed subscription detected.')
          //sendSubscription(existedSubscription)
        }
      })
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  }
}