import React from "react";
import { ToggleButton } from "components";
import { ButtonGroup } from "react-bootstrap";

const ToggleButtonGroup = ({
  options,
  value,
  onChange,
  disabled,
  FromKF,
  ...restProps
}) => {
  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };
  return (
   <>
   {  FromKF ?
    <ButtonGroup style={{width:"314px" ,borderRadius:"4px"}}>
         {options.map((o, i) => {
        const btndisabled = disabled && o.value !== value
        return <ToggleButton
          type="radio"
        //   name="radio"
          key={`${restProps.name}-${i}`}
          disabled = {btndisabled}
          checked={o.value === value}
          value={o.value}
          onChange={handleChange}
          {...restProps}
          >
          {o.icon ? o.icon : null}{o.label}
        </ToggleButton>
})}
    </ButtonGroup>
    
     
    :
     <ButtonGroup > 
      {options.map((o, i) => {
        const btndisabled = disabled && o.value !== value
        return <ToggleButton
          type="radio"
        //   name="radio"
          key={`${restProps.name}-${i}`}
          disabled = {btndisabled}
          checked={o.value === value}
          value={o.value}
          onChange={handleChange}
          {...restProps}
          >
          {o.icon ? o.icon : null}{o.label}
        </ToggleButton>
})}
    </ButtonGroup>
    }
    </>
  );
};

export default ToggleButtonGroup;
