import {
    Button,
    // FilterDropdown,
    Select,
    JustifyBetween,
    SearchField,
    Toolbar,
    OverlayTooltip
  } from "components";
  import { IconDownload } from "Icons";
  import React, { useState, useEffect, useRef,forwardRef } from "react";
  import DataTable from "react-data-table-component";
  import { Link } from "react-router-dom";
  import { useTheme } from "styled-components";
  import getTableStyles from "themes/table-styles";
  //import "./EmployeeWiseResults.css";
  import { CSVLink } from "react-csv";
  import { withApiContext, withLabelsContext, withUserContext,withWindowContext } from "context";
  import {  Col, Row} from "react-bootstrap";
  import * as config from "config";
  import { withRouter } from "react-router";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import styled from "styled-components";
  import { EyeIcon,IconTime,IconCalendar } from "Icons";
  import * as util from "services/util";
  import { FALSE } from "sass";
  import  "./employeewise-results.module.scss";
  
  
  const Styles = styled.div`
   .react-datepicker-wrapper
   .react-datepicker__input-container input {
    width: 120px;
    height:30px;
     border-radius: 6px;
    border: none;
    outline : 1px solid ${({ theme }) => theme.primary};
     &:focus { 
     outline: 2px solid ${({ theme }) => theme.primary};
    }
  }
  `;
  const StyledIconWrapper = styled.div`{
  
  .iconstyle{
    width : 100%
  }
  
  }`;
  
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  
  function EmployeeWiseResults(props) {
    console.log("😁😈",props);
    const theme = useTheme();
    const tableStyles = getTableStyles(theme);
    tableStyles.table.style.borderRadius = "0";
    const [studentResultList, setStudentResultList] = useState(
      props.studentResultList
    );
    const [filteredList, setFilteredList] = useState(props.studentResultList);
    const [filter, setFilter] = useState("");
    const [searchText, setSearchText] = useState("");
    let today = new Date();
  
  const handleReassign=(row,index)=>{
    const myNewList = filteredList.map((item,i)=>{
      return row.traineeId == item.traineeId ? 
      {...item,can_reattempt: true,reassign : 0 , is_cancel : 0} : item
      })
      setFilteredList(myNewList);
  }
  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
   <div  class="btn-group btn-group-xs"> 
   <Button class="btn btn-primary"
   style ={{ padding: "7px 7px 5px 13px" }}
   onClick={onClick} ref={ref}>
      <IconCalendar/>
    </Button>
     </div> 
    
  ));
  
  const handleConfirmation = (date,row,index) => (ok) => {
    if (ok) {
      handleAlternateButton({...row,can_reattempt: false, reassign : 1,is_cancel: 0,reassign_date : date}); 
    }
    else{
      const myNewList = filteredList.map((item,i)=>{
        if(row.traineeId == item.traineeId){
          return {
                 ...item,
                 can_reattempt : true,
                 is_cancel : 1,
                 reassign_date : row.reassign_date? row.reassign_date : null,
                 reassignDate : item.reassignDate ? item.reassignDate:  null,
                 reassign : 0
  
          }
        }
        else return {
          ...item,
          can_reattempt : false,
          reassign : 0,
        };
      })
      setFilteredList(myNewList); 
    }
  }
  
  
    const handleDateChange=(date,row,index)=>{
     
    filteredList.forEach((item,i)=>
    {if(row.traineeId == item.traineeId){
      if (date !== null ){
        props.ctx_window.confirm({
          message:
            "This action will confirm the reassign date. Would you like to continue?",
          yesText: "Proceed",
          noText: "Cancel",
           callback: handleConfirmation(date,row,index),
        });
      }
    }
    
    })
    }
  
      const handleAlternateButton = async (row) => {
        let args = {
          studentId: row.traineeId,
          reAssign: 1,
          reassignDate: row.reassign_date,
          publishId : props.entityData.id,
          assessmentId : '0',
        };
        const { rsp } = await props.ctx_api.privateRequestRow({
          cmd: "update_assessment_evaluation",
          args,
        })
  
        if (rsp.StatusValue === 1) {
          handleUpdateAssessmentEvaluationResponse(rsp,row);
        }
        else{
          props.ctx_window.alert(rsp.StatusText || "failed");
        }
    
      };
  
      const handleUpdateAssessmentEvaluationResponse =  (rsp,row) => {
        if (rsp.StatusValue === 1) {
         
          props.ctx_window.alert(rsp.StatusText);
          const myNewList = filteredList.map((item,i)=>{
            if(row.traineeId == item.traineeId){
              return {
                     ...item,
                     can_reattempt : true,
                    reassign_date : rsp.reassign_date ?rsp.reassign_date : null,
                    reassignDate : rsp.reassign_date ?util.toDateString(new Date(rsp.reassign_date),false,true) : null,
                    reassign : 1
  
              }
            }
            else return {
              ...item,
              can_reattempt : false,
              reassign : 0,
            };
          })
          setFilteredList(myNewList)
          props.handleReassignResults(myNewList)
        }
       
      }
  
    const columns = props.ctx_user.user.gradeEnabled ? 

    [
      { name: "Student Name", selector: "name", sortable: true ,width: "260px"},
      { name: "Class/Batch", selector: "category", sortable: true,width: "270px" },
      
      { name: "Status", selector: "statusText", sortable: true, width: "340px" },
      
      {
          name: "Attended Date",
          selector: "attendedDate",
          sortable: true,
          width: "220px",
        },
  
    
  
    ]

    
    :[
      { name: "Student Name", selector: "name", sortable: true ,width: "260px"},
      { name: "Class/Batch", selector: "category", sortable: true,width: "270px" },
      { name: "Status", selector: "statusText", sortable: true, width: "340px" },
      
      {
          name: "Attended Date",
          selector: "attendedDate",
          sortable: true,
          width: "220px",
        },
  
    
  
    ];
  
    useEffect(() => {
      setStudentResultList(props.studentResultList)
      let data = studentResultList;
      data = filterList(data);
      data = filterStatus(data);
      setFilteredList(data);
    }, [searchText, filter, props.studentResultList, studentResultList]);
  
    const filterList = (array) => {
      if (searchText !== "") {
        setFilteredList([]);
        let dataList = [];
        let allList = array;
  
        allList.forEach((student) => {
          // let dataList = [];
          if (
            student.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          ) {
            dataList.push(student);
          }
        });
  
        return dataList;
      } else {
        // set all list
  
        return studentResultList;
      }
    };
  
    const filterStatus = (array) => {
      let itemStatus = "";
      switch (filter) {
        case "attended":
          itemStatus = "Attended";
          break;
        case "yettostart":
          itemStatus = "Yet to Start";
          break;
        case "inprogress":
          itemStatus = "In Progress";
          break;
        default:
          itemStatus = "";
          break;
      }
      const newFilteredList =
        itemStatus !== ""
          ? array.filter((item) => item.statusText === itemStatus)
          : array;
      return newFilteredList;
    };
  
    const filterChange = (selectedOption) => {
      setFilter(selectedOption);
    };
    const getCSVheaders = () => {
      return columns.map((field) => {
        return {
          label: field.name.toUpperCase(),
          key: field.selector,
        };
      });
    };

    return (
      <>
        <JustifyBetween className="align-items-center px-3 pt-3">
          <div className="text-color4">{filteredList.length} results found</div>
          <Toolbar>
            <Toolbar.Item>
              <SearchField
                placeholder="Search by keyword"
                onChange={(e) => setSearchText(e.currentTarget.value)}
                value={searchText}
              />
            </Toolbar.Item>
            <Toolbar.Item style={{ minWidth: "130px" }}>
              <Select
                options={config.assessmentFilterList.filter(
                  (item) => item.value !== "closed"
                )}
                placeholder={`Filter`}
                value={filter}
                onChange={filterChange}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              
               <OverlayTooltip
                tip="Download as CSV"
                id="download-as-csv"
                placement="top"
              >
                <CSVLink
                  headers={getCSVheaders()}
                  filename={"StudentResult.csv"}
                  // data={props.downloadData}
                  data={props.downloadData.filter((s) => {
                    return filteredList.map(itm => itm.id).includes(s.id)
                  })}
                >
                  <Button variant="link" className="p-0 mb-2" style={{marginTop: "7px"}}>
                    Download CSV <IconDownload className="mr-0 ml-1" />
                  </Button>
                </CSVLink>
              </OverlayTooltip>
            </Toolbar.Item>
          </Toolbar>
        </JustifyBetween>
        <div className="mb-3"></div>
        <div className="custom-scrollbar" style={{maxHeight: "600px",
        //overflowY: "auto",
        borderRadius: "8px"}}>
        <DataTable 
          noHeader={true}
          columns={columns}
          data={filteredList}
          customStyles={tableStyles}
          className='employee_detail_tble'
          pagination
          striped
          defaultSortField="no" 
          paginationPerPage={10} 
        /></div>
      </>
    );
  }
  export default withLabelsContext(
    withApiContext(withUserContext(withWindowContext(withRouter(EmployeeWiseResults))))
  );
  