import { IconChevronDown, IconClose, IconEnvelope, IconHamburger } from "Icons";
import MainContext from "pages/Main/MainContext";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import css from "./scss/header.module.scss";
import logo from "./images/logo.svg";
import compLogo from "./images/comp-logo.png";
import CreditHistory from "./CreditHistory/CreditHistory";
import CampusRoleSwitch from "./CampusRoleSwitch/CampusRoleSwitch";
import { useMediaQuery } from "react-responsive";

export const Logo = ({ logo, Expiredate }) => (
  
  <Link to="/" className={css.logo} style={Expiredate ? { cursor: "not-allowed" } : { cursor: "default" }}>
    <img src={logo} alt="" />
  </Link>
);

export const Title = ({ title }) => {
  // dynamic title.
  return <div className={css.title}>{title}</div>;
};

export const CompLogo = ({ logo }) => {
  // dynamic logo.
  return (
    <div className={css.comp_logo}>
      <img src={logo} alt="" />
    </div>
  );
};

export const Notification = () => {
  return (
    <div className={css.notification}>
      <div className={css.notification_button}>
        <IconEnvelope
          className={css.notification_icon}
          width="31"
          height="31"
        />
        <span className={css.notification_badge}>0</span>
      </div>
    </div>
  );
};

// Component Name ?
// TODO: dropdown
export const CreditsDropdown = ({
  credits,
  memberLevel,
  memberType
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(min-width: 767px)' })
  return (
    !isTabletOrMobile ? (
      <>
        <div className={css.credits_mobile}>
          <div className={css.credits__number}>{credits}</div>
          <span className={css.credits__badge}>{memberLevel}</span>
        </div>
        <CreditHistory />
      </>
    ) : (
      <div className={css.credits}>
        <div className={css.credits__number}>{credits}</div>

        <div className={`text-center ml-3`}>
          <span className={css.credits__badge}>{memberLevel}</span>
          <br />
          {isTabletOrMobile && `${[1, 4].includes(memberType) ? "PLE" : "SME"} Credits`}
        </div>
        <CreditHistory />
      </div>
    )
  );
};

// user role switch
export const TraineeDropdown = ({ acadYearName, orgName, onOrgSwitch }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(min-width: 768px)' })
  return (
    <div className={css.trainee}>
      <div>
        <strong>{orgName}</strong>
        {isTabletOrMobile &&
          <>
            <br />
            {acadYearName}
          </>
        }
      </div>
      { orgName &&
        <CampusRoleSwitch onOrganizationSwitch={onOrgSwitch} />
      }
    </div>
  );
};

// sidebar toggle with main context
export default class SidebarToggle extends Component {
  render() {
    const isTabletOrMobile = window.innerWidth < 768;
    return (
      <button
        className={css.sidebar_toggle}
        onClick={this.context.toggleMobileSidebar}
      >
        {!isTabletOrMobile ? (
          <IconHamburger icon="hamburger" className="fill-primary" />
        ) : (
          this.context.isMobileSidebarOpen ? (
            <IconClose icon="close" className="fill-primary" />
          ) : (
            <IconHamburger icon="hamburger" className="fill-primary" />
          )
        )}
      </button>
    );
  }
}

SidebarToggle.contextType = MainContext;
