import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ItemMiniCard from '../InternalItems/ItemMiniCard';
import InfoCard from './InfoCard';
import "./Questions.css";
import { Card, Col, Row } from "react-bootstrap";



const Questions = (props) => {
    const {question, selected, selectedItemId} = props;  
    let count = 0;
    // return question;
    return  <PerfectScrollbar className="inner-mini-ques-card-wrap">
      {question&& question.map((section, i ) => (
      //(count=i),
      <InfoCard
      className="info-card-quest-wrap"
      caption="section"   
      color="cadetblue"
      // height="25vh"
      >
      {Object.values(section.questions).sort((a, b) => a.sequence-b.sequence).map((itm, j) => 
      { count= count + 1;
      return(
        <div className="carausel-question-item">
          <ItemMiniCard
            sequence= {count}
            key={itm.id}
            item={itm}
            renderedOn="form"
            selectable
            // selected={ false }
            selected={ selectedItemId === itm.id ? true : false }
            onSelect={()=>  selected(itm.id)}
          />
          {/* item cadrd was here thst is hided */}
        </div>
      )}
      )
}

        </InfoCard>
        
      
      //   <div className="test-section-itm">
      //     <div className="section-itm-header">
      //       <div className="sec-title">section {i + 1}</div>
      //     </div>
      //     <Collapse in={true}>
      //       <div className="section-questions">
      
      //       </div>
      //     </Collapse>
      //   </div>
      ))
      }
      </PerfectScrollbar>
      // </div>
}
 
export default Questions;