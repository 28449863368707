import { IconChevronLeft, IconBack } from "Icons";
import { Link } from "react-router-dom";
import { withLabelsContext} from "context";

const BackLink = (props) => {
  // const subjectData = props.to?.subjectData;
  const screenSize = window.screen.width;
  return (
    props.fromChapterInner ?
    <Link to={props.to ?? "#"} className="btn btn-link px-0">
      <IconBack width="25" height="25" className="mr-2 ml-0" />
      {screenSize > 821 ? props.ctx_labels.singular.back : ""}
    </Link> 
:
<Link to={props.to ?? "#"} className="btn btn-link px-0">
<IconChevronLeft width="22" height="22" className="mr-2 ml-0" />
{props.ctx_labels.singular.back}
</Link>
  );
};

BackLink.defaultProps = {
  children: "Back",
}

export default withLabelsContext(BackLink);
