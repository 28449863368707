import * as config from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Form } from "react-bootstrap";
import css from "../../../pages/StudentAssessments/Question/question.module.scss";
import "./FillInTheBlank.css"

class Comment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }
  

  static propTypes = {
    question: PropTypes.string.isRequired,
    onAnswered: PropTypes.func.isRequired,
  };

  initState = {
    comment: "",
    submitted: false,
  };

  componentWillReceiveProps = (newProps) => {

    if (newProps.question !== this.props.question) {
      this.setState(this.initState);
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.submitted && !prevState.submitted) {
      this.props.onAnswered({ correct: [this.state.comment] });
    }
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
  };
  
  render = () => (
    <div>
        <div className={`${"question-title-fill"} text-color4`}>
      <div>
      Question     
      {this.isSurveyQuestion() ? "Survey" : null}  
      </div>
      <div>
      {!this.state.submitted && (
    <Button
      bsStyle="primary"
      disabled={this.state.comment.length === 0}
      onClick={this.handleSubmit}
    >
      Submit
    </Button>
  )}</div></div>
      <div className="test-question-text">{this.props.question}</div>
      {this.props.questionImage && (
        <div className={this.props.isPlayerTest ? "player-image" :"test-question-image" }>
          <img
            src={this.props.questionImage}
            alt=""
          />
        </div>
      )}
      <form id="test-answer-form">
        <Form.Group>
          <Form.Control
            as="textarea"
            rows="7"
            value={this.state.comment}
            maxLength={config.maxTestCommentLength}
            placeholder={config.testCommentPlaceholder}
            disabled={this.state.submitted}
            onChange={this.handleChange}
            form="test-answer-form"
            autoFocus
          />
        </Form.Group>
        <div className="test-comment-box-footer">
          <span className="footer-note">
            {`(maximum of ${config.maxTestCommentLength} characters)`}
          </span>
        </div>
        {this.state.submitted && (
          <div className="test-comment-response">
            Thank you for your comment.
          </div>
        )}
      </form>
    </div>
  );
}

export default Comment;
