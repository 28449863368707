import DashboardPanel from "components/DashboardPanel/DashboardPanel";
import { withLabelsContext, withUserContext } from "context";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";
import * as util from 'services/util';
import { OverlayTooltip } from "components";
import * as config from "config";
import { Redirect } from "react-router-dom";

function SubjectStatus(props) {
  const theme = useTheme();
  const [redirState, setRedirState] = useState(false);
  const [data, setData] = useState({});
  const tableStyles = getTableStyles(theme);
  tableStyles.pagination.style.borderTopWidth = "1px !important";
  tableStyles.pagination.style.borderTopStyle = "solid !important";

  const sorting = (a, b) =>{
    let sortValue;
      if(+a > +b){
        sortValue = 1
      }else if(+a < +b){
        sortValue = -1
      }else sortValue = 0
    return sortValue
  }
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    const monthMap = {
      JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
      JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
    };
    return new Date(year, monthMap[month], day);
  };

  const sortDate = (rowA, rowB) => {
    const dateA = parseDate(rowA.date.split(' - ')[0]);
    const dateB = parseDate(rowB.date.split(' - ')[0]);
    return dateA - dateB;
  };

  const columns = [
    {
      name: `${props.ctx_labels.singular.course}`,
      selector: row => row.course_name,
      sortable: true,
      cell: (e) => {
        return (
          <OverlayTooltip placement="top" tip={e.course_name}>
            <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {e.course_name}
            </span>
          </OverlayTooltip>
        );
      },
    },
    {
      name: `${props.ctx_labels.singular.curriculum}`,
      selector: row => row.subject,
      sortable: true,
      center: true,
    },
    {
      name: `${props.ctx_labels.singular.createdBy}`,
      selector: row => row.created_by,
      sortable: true,
      center: true,
      
    },
    {
      name: `${props.ctx_labels.singular.assignedBy}`,
      selector: row => row.published_by,
      sortable: true,
      center: true,
      
    },
    {
      name: `${props.ctx_labels.singular.classnBatch}`,
      selector: row => row.class_batch_name,
      sortable: true,
      center: true, 
    },
    {
        name: `${props.ctx_labels.singular.course} ${props.ctx_labels.plural.duration}`,
        selector:row => row.date,
        sortable: true,
        sortFunction: sortDate,
        center: true,
        width:"20%",
        cell: (e) => {
          return (
            <OverlayTooltip placement="top" tip={e.date}>
              <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {e.date}
              </span>
            </OverlayTooltip>
          );
        },
      },
      {
        name:`${props.ctx_labels.singular.module} ${props.ctx_labels.singular.count}`,
        selector: row => row.module_count,
        sortable: true,
        center: true,
        
      },
      {
        name: `${props.ctx_labels.singular.progress} %`,
        selector: row => row.course_completion_perc,
        sortable: true,
        center: true,
        sortFunction: (a, b) => {
          return a.course_completion_perc - b.course_completion_perc;
        }
        
      },
      {
        name: `${props.ctx_labels.singular.completionStatus}`,
        selector: row => row.status,
        sortable: true,
        sortFunction: (a, b) => {
          const statusA = a.status;
          const statusB = b.status;     
          return statusA.localeCompare(statusB); 
        },
        center: true,
        
      }, 
  ];
  
  let redirecting = (redirState && props.ctx_user.user.type===4) ? (
    <Redirect to={{ pathname: config.ROUTES.subjectProgress, data: data}} />
  ) : null;
  return redirecting ? (
    redirecting
  ) : (
    <DashboardPanel
      title={`${props.ctx_labels.singular.curriculum} ${props.ctx_labels.singular.status}`}
      fullScreen
    >
      <Card className="pb-25 border-0">
        <DataTable
         onRowClicked={(rowData) => {
          const entityData = {
            class_id: rowData.class_id,
            course_publish_id: rowData.course_publish_id,
            course_id: rowData.course_id,
            progress: rowData.course_completion_perc,
            coursepub_name: rowData.course_publish_name,
            coursename: rowData.course_name,
            currname: rowData.subject,
            classname: rowData.class_batch_name,
            curriculum_id: rowData.curriculum_id,
            is_multi_course: rowData.is_multicourse,
            child:props.child,
          };
          setRedirState(true);
          setData(entityData);
        }}
          noHeader={true}
          columns={columns}
          data={props.data}
          customStyles={tableStyles}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          pointerOnHover={true}
          striped
        />
      </Card>
    </DashboardPanel>
  );
}

export default withLabelsContext(withUserContext(SubjectStatus));
