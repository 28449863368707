import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import RichTextEditor, { createEmptyValue, createValueFromString } from 'react-rte';
import './RichTextArea.css';




class RichTextArea extends PureComponent {
  static propTypes = {
    onClick:           PropTypes.func,
    setToolbarElement: PropTypes.func,
  };

  editor = null;

  focus = () => {
    this.editor._focus();
  }

  setToolbarRef = (r) => {
    const { setToolbarElement } = this.props;
    if (!setToolbarElement) {
      return;
    }
    let elt = ReactDOM.findDOMNode(r);
    while (
      elt && (
        !elt.className ||
        elt.className.indexOf('rich-text-area-toolbar') === -1
      )
    ) {
      elt = elt.parentNode;
    }
    if (elt) {
      setToolbarElement(elt);
    }
  }

  render = () => {
    const toolbarConfig = {
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold',      style: 'BOLD'      },
        { label: 'Italic',    style: 'ITALIC'    },
        { label: 'Underline', style: 'UNDERLINE' },
      ],
      BLOCK_TYPE_BUTTONS: [
        {label: 'List',          style: 'unordered-list-item' },
        {label: 'Numbered list', style: 'ordered-list-item'   },
      ],
      // BLOCK_TYPE_DROPDOWN: [
      //   { label: 'Normal',         style: 'unstyled'     },
      //   { label: 'Small heading',  style: 'header-three' },
      //   { label: 'Medium heading', style: 'header-two'   },
      //   { label: 'Large heading',  style: 'header-one'   },
      // ],
    };
    const customControls = [(
      <div key="dummy-control" ref={this.setToolbarRef} />
    )];
    const { onClick, ...rest } = this.props;
    const editorStyle = {
      cursor: this.props.readOnly ? 'pointer' : 'text',
    };
    const editorProps = {
      ...rest,
      toolbarConfig,
      customControls,
      editorStyle,
      className:        'rich-text-area-root',
      toolbarClassName: 'rich-text-area-toolbar',
      editorClassName:  'rich-text-area-editor',
      ref:              (r) => { this.editor = r; },
    };
    console.log(editorProps);
    return (
      <div className="rich-text-area" onClick={onClick}>
        {React.createElement(RichTextEditor, editorProps)}
      </div>
    );
  }
}

RichTextArea.empty = createEmptyValue;
RichTextArea.fromString = createValueFromString;


export default RichTextArea
