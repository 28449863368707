import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components';
import "./SwitchButton.css";
const SwitchButton = (props) => {
  const [isOn, setIsOn] = useState(false);
  const theme = useTheme();
  const inlineBackground = {
    "--bg-color-1": theme.primary,
  };
  useEffect(() => {
    const value = props.value === 1 ? true : false
    setIsOn (value)
  }, [props.value]);
  const toggleSwitch = () => {
    setIsOn(!isOn);
    props.onChange(!isOn)
  };

  return (
    <div className={`toggleswitch ${isOn ? 'on' : 'off'}`} style={inlineBackground} onClick={toggleSwitch}>
      <div className="toggleswitch-handle"></div>
    </div>
  );
};

export default SwitchButton;