import * as config from 'config';
import { withApiContext } from 'context';
import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
// import { Document, Page } from 'react-pdf/build/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import ResizeHandler from 'services/ResizeHandler';
import * as util from 'services/util';
import { FirstPage as FirstPageIcon } from './icons/FirstPage';
import { LastPage as LastPageIcon } from './icons/LastPage';
import { NextPage as NextPageIcon } from './icons/NextPage';
import { PreviousPage as PreviousPageIcon } from './icons/PreviousPage';
import { IconCircleMinus, IconCircleAdd } from 'Icons';
import labels from './labels';
import './Pdf.css';
import Button from '../../components/PlayerNew/Pdf/Button';
import NumberInput from './Pdf/NumberInput';
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import somefile from './somefile.pdf'



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



class PdfPage extends Component {
  static propTypes = {
    pdf:                 PropTypes.object.isRequired,
    pageNumber:          PropTypes.number.isRequired,
    overlay:             PropTypes.shape({
      id:                  PropTypes.string.isRequired,
      elt:                 PropTypes.element,
                         }),
    width:               PropTypes.number,
    topMaskPercent:      PropTypes.number.isRequired,
    bottomMaskPercent:   PropTypes.number.isRequired,
    topMaskOpacity:      PropTypes.number.isRequired,
    bottomMaskOpacity:   PropTypes.number.isRequired,
    cropPaddingPercent:  PropTypes.number.isRequired,
    modifyPageScrollTop: PropTypes.func.isRequired,
    onLoadSuccess:       PropTypes.func,
    onRenderSuccess:     PropTypes.func,
    storeRef:            PropTypes.func,
  };

  shouldComponentUpdate = (nextProps) =>
    nextProps.pageNumber !== this.props.pageNumber ||
    nextProps.overlay    !== this.props.overlay    ||
    nextProps.width      !== this.props.width;

  componentDidUpdate = (prevProps, props) => {
    if(props !== prevProps) {
      this.props.onPdfLoaded && this.props.onPdfLoaded(false);
    }
    if (prevProps.width && this.props.width !== prevProps.width) {
      const factor = this.props.width / prevProps.width;
      if (factor !== 1) {
        this.props.modifyPageScrollTop((t) => factor * t);
      }
    }
    if (this.props.pageNumber !== prevProps.pageNumber) {
      if( document.getElementsByClassName("perfectScrollbar")[0]!== undefined) {
        document.getElementsByClassName("perfectScrollbar")[0].scrollTop=0
      }
    }
  }
  render = () => {
    return !this.props.width ? null : this.props.playerState !== "advancing" ? (
      <PerfectScrollbar className= "perfectScrollbar">
        {this.props.overlay && this.props.overlay ? (
          this.props.overlay.elt
        ) : (
          <Document
            file={this.props.file}
            onLoadSuccess={this.props.onLoadSuccess}
            onLoadProgress={this.props.onLoadProgress}
          >
            <Page
              className="pdf-page"
              // pdf={this.props.pdf}
              pageNumber={this.props.pageNumber}
              // pageNumber={this.props.pageNumber}
              renderTextLayer={false}
              width={this.props.width}
              // topMaskPercent={this.props.topMaskPercent}
              // bottomMaskPercent={this.props.bottomMaskPercent}
              // topMaskOpacity={this.props.topMaskOpacity}
              // bottomMaskOpacity={this.props.bottomMaskOpacity}
              // cropPaddingPercent={this.props.cropPaddingPercent}
              // onLoadSuccess={this.props.onLoadSuccess}
              onRenderSuccess={this.props.onRenderSuccess}
              renderAnnotationLayer={false}
            ></Page>
          </Document>
        )}
      </PerfectScrollbar>
    ) : (
      <>
        {this.props.overlay && this.props.overlay ? (
          this.props.overlay.elt
        ) : (
          <Document
            file={this.props.file}
            onLoadSuccess={this.props.onLoadSuccess}
            onLoadProgress={this.props.onLoadProgress}
          >
            <Page
              className="pdf-page"
              // pdf={this.props.pdf}
              pageNumber={this.props.pageNumber}
              // pageNumber={this.props.pageNumber}
              renderTextLayer={false}
              width={this.props.width}
              // topMaskPercent={this.props.topMaskPercent}
              // bottomMaskPercent={this.props.bottomMaskPercent}
              // topMaskOpacity={this.props.topMaskOpacity}
              // bottomMaskOpacity={this.props.bottomMaskOpacity}
              // cropPaddingPercent={this.props.cropPaddingPercent}
              // onLoadSuccess={this.props.onLoadSuccess}
              onRenderSuccess={this.props.onRenderSuccess}
              renderAnnotationLayer={false}
            ></Page>
          </Document>
        )}
      </>
    );
    }
}


class PdfViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initState,
      pdfWidth:           null,
      setPdfWidthPending: false, // hack to force a render before setting width
    }
    this.maxViewedPos = props.maxViewedPos;

    this.pdfR = null;
    this.pdfPageR = null;
    this.pdfPageElement = null;

    // Maintain our own version since an element's scrollTop is not always
    // valid immediately after a fullscreen change; the tradeoff is that we
    // occasionally use an outdated value when we modify it
    this.pageScrollTop = null;
    this.bboxBottomPos = null;

    this.resizeHandler = null;

    this.pendingZoomFactorChange = null;
  }

  initState = {
    pageNumber:1,
    numPages:null,
    fracPos:         null,
    maxViewablePage: null,
    zoomFactor:      0,
    renderPDF:{},
    numPages:null
  };

  static propTypes = {
    pdf:              PropTypes.shape({
      numPages:         PropTypes.number,
                      }),
    lastViewedPos:    PropTypes.number.isRequired,
    maxViewedPos:     PropTypes.number.isRequired,
    minPos:           PropTypes.number.isRequired,
    maxPos:           PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed:      PropTypes.func.isRequired,
    onUnmounted:      PropTypes.func,
    overlay:          PropTypes.shape({
      id:               PropTypes.string.isRequired,
      elt:              PropTypes.element,
                      }),
    message:          PropTypes.element,
    isFullscreen:     PropTypes.bool.isRequired,
    onWidthChange:    PropTypes.func,
    baseControls:     PropTypes.element.isRequired,
  };

  componentDidMount = () => {
    // Ensure the containing div is rendered before we set the width
    this.requestPdfWidthUpdate();
    this.addResizeHandler();
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.isFullscreen !== this.props.isFullscreen) {
      // Force a re-render to ensure the width has been updated by the
      // fullscreen change
      this.requestPdfWidthUpdate();
    }
    if (!newProps.pdf && this.props.pdf) {
      this.setState(this.initState);
      this.pdfPageElement = null;
      return;
    }
    if (
      newProps.pdf          !== this.props.pdf ||
      newProps.maxViewedPos !== this.props.maxViewedPos
    ) {
      this.setMaxViewablePage(newProps);
      this.maxViewedPos = newProps.maxViewedPos;
    }
    // The only way to distinguish a new overlay from a re-rendered one
    const currOverlayId = this.props.overlay ? this.props.overlay.id : null;
    const newOverlayId  = newProps.overlay   ? newProps.overlay.id   : null;
    if (
      newProps.pdf           !== this.props.pdf           ||
      newProps.lastViewedPos !== this.props.lastViewedPos ||
      newProps.minPos        !== this.props.minPos        ||
      newProps.maxPos        !== this.props.maxPos        ||
      newOverlayId           !== currOverlayId
    ) {
      let lastViewedPos = newProps.lastViewedPos;
      // If we are displaying a test question, minPos and maxPos will be equal,
      // in which case we want to scroll to the top of the page
      if (newProps.minPos === newProps.maxPos) {
        lastViewedPos = Math.floor(lastViewedPos);
      }
      this.setPdfPosition(lastViewedPos, newProps);
    }
  }

  componentDidUpdate = (_prevProps, prevState) => {
    if (this.state.zoomFactor !== prevState.zoomFactor) {
      this.setPdfWidth();
      if (this.state.zoomFactor === 0) {
        // Since the horizontal scrollbar is not visible, we must ensure
        // the left edge of the page is visible (otherwise, it might be
        // partly hidden if the page had been scrolled to the right before
        // we zoomed out)
        const elt = this.pdfPageElement;
        if (elt) {
          elt.scrollLeft = 0;
        }
      }
    } else if (this.state.setPdfWidthPending) {
      this.setPdfWidth();
    }
  }

  componentWillUnmount = () => {
    this.removeResizeHandler();
    if (this.props.message === null) {            // this will stop unwanted module updation
      this.reportModuleProgress();
    }
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
  }

  addResizeHandler = () => {
    this.resizeHandler = new ResizeHandler(this.requestPdfWidthUpdate);
  }

  removeResizeHandler = () => {
    this.resizeHandler.cleanup();
  }

  requestPdfWidthUpdate = () => {
    this.setState({ setPdfWidthPending: true });
  }

  setPdfWidth = () => {
    const elt = ReactDOM.findDOMNode(this.pdfR);
    if (elt) {
      const scale = Math.pow(config.playerZoomStep, this.state.zoomFactor);
      const pdfWidth = scale * elt.clientWidth;
      this.setState({
        pdfWidth,
        setPdfWidthPending: false,
      });
      if (this.props.onWidthChange) {
        this.props.onWidthChange(pdfWidth);
      }
    }
  }

  topPosToPage = (pos, props = this.props) => {
    const page = Math.floor(pos) + 1;
    return props.pdf ? Math.min(props.pdf.numPages, page) : page;
  }

  bottomPosToPage = (pos) => Math.ceil(pos);

  topPosToPdfPos = (pos, props) => {
    const pageNumber = this.topPosToPage(pos, props);
    // Handle the case of a test at the end of the document
    if (pos === pageNumber) {
      return { pageNumber, fracPos: 0 };
    }

    const minPos = props.minPos;
    const maxPos = props.maxPos;
    const paddingFrac = config.pdfCropPaddingPercent / 100;

    const minPaddedPos = Math.max(minPos - paddingFrac, Math.floor(minPos));
    const maxPaddedPos = Math.min(maxPos + paddingFrac, Math.ceil(maxPos));
    const minPagePos = config.pdfTopMaskOpacity == null ?
      Math.max(pageNumber - 1, minPaddedPos)            :
               pageNumber - 1;
    const maxPagePos = config.pdfBottomMaskOpacity == null ?
      Math.min(pageNumber, maxPaddedPos)                   :
               pageNumber;

    const fracPos =
      pos === maxPagePos ? 1 : (pos - minPagePos) / (maxPagePos - minPagePos);
    return { pageNumber, fracPos };
  }

  setPdfPosition = (topPos, props = this.props) => {
    const newPdfPos = this.topPosToPdfPos(topPos, props);
    // If the page number isn't changing, we must call onPageLoadSuccess
    // explicitly
    const cb = newPdfPos.pageNumber === this.state.pageNumber ?
      this.onPageLoadSuccess : null;
    this.setState(newPdfPos, cb);
  }

  setPageNumber = (n) => {
    const minPage = this.topPosToPage(this.props.minPos);
    const topPos = n === minPage ? this.props.minPos : n - 1;
    this.setPdfPosition(topPos);
  }

  setMaxViewablePage = (props) => {
    let page = this.bottomPosToPage(props.maxViewedPos);
    if (
      page === props.maxViewedPos && props.pdf && page < props.pdf.numPages
    ) {
      page = page + 1;
    }
    this.setState({ maxViewablePage: page });
  }

  onPageLoadSuccess = (page) => {
    let elt = this.pdfPageElement;
    if (!elt) {
      elt = ReactDOM.findDOMNode(this.pdfPageR);
      this.pdfPageElement = elt;
      elt.onscroll = this.updateMaxViewedPos;

      // Shrink the page to fit the window if only a small fraction of the
      // page would otherwise be hidden.  Doing so ensures the vertical
      // scroll bar is only shown when a sizeable fraction of the page is
      // not visible.
      // const fracVisible = elt.parentNode.clientHeight / page.height;
      // if (
      //   config.maxPdfInitVisibleHeightFrac < fracVisible && fracVisible < 1
      // ) {
      //   const delta = Math.log(fracVisible) / Math.log(config.playerZoomStep);
      //   this.pendingZoomFactorChange = delta;
      // }
    }

    // Reset the bounding box so setting scrollTop doesn't update maxViewedPos
    // (the update will be done once the page is rendered)
    this.bboxBottomPos = null;

    elt.scrollTop = Math.floor(this.state.fracPos * elt.scrollHeight);
  }

  unsafeGetLastViewedPos = () => {
    const height = this.pdfPageElement.scrollHeight;
    return this.state.pageNumber - 1 + this.pageScrollTop / height;
  }

  getLastViewedPos = () =>
    this.pdfPageElement   !== null &&
    this.pageScrollTop    !== null &&
    this.state.pageNumber !== null ? this.unsafeGetLastViewedPos() : null;

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: this.getLastViewedPos(),
        maxViewedPos:  this.maxViewedPos,
      });
    }
  }

  updateMaxViewedPos = () => {
    const elt = this.pdfPageElement;
    this.pageScrollTop = elt.scrollTop;
    if (this.bboxBottomPos === null) {
      return;
    }
    const topPos = this.unsafeGetLastViewedPos();
    let botPos = topPos + elt.clientHeight / elt.scrollHeight;
    if (this.bboxBottomPos <= botPos) {
      botPos = this.state.pageNumber; // Allow advancement to next page
    }
    botPos = Math.min(botPos, this.props.maxPos);

    if (topPos <= this.maxViewedPos && this.maxViewedPos < botPos) {
      this.maxViewedPos = botPos;
      if (botPos === this.state.maxViewablePage &&
          this.props.pdf                        &&
          botPos < this.props.pdf.numPages) {
        this.setState({ maxViewablePage: botPos + 1 });
      }
      // if (botPos === this.props.maxPos) {
      //   this.props.onAllViewed();
      // }
    }
  }

  modifyPageScrollTop = (g) => {
    const elt = this.pdfPageElement;
    if (elt) {
      elt.scrollTop = Math.floor(g(this.pageScrollTop));
    }
  }

  onPageRenderSuccess = (context) => {
    const canvas = context.canvas;
    const w = canvas.width;
    const h = canvas.height;
    const data = context.getImageData(0, 0, w, h).data;

    let maxY = null;
    let i = data.length - 4;
    const borderR = data[i];
    const borderG = data[i+1];
    const borderB = data[i+2];
    for (i -= 4; 0 <= i; i -= 4) {
      if (data[i] !== borderR || data[i+1] !== borderG || data[i+2] !== borderB) {
        maxY = Math.floor(i / (4 * w));
        break;
      }
    }

    const backgroundNode = canvas.parentNode;
    const backgroundNodeHeight = backgroundNode.scrollHeight;
    const canvasNodeHeight = canvas.scrollHeight;
    const canvasNodeOffset = canvas.offsetTop;
    const maxYRelPos = maxY === null ? 0 :
      (canvasNodeOffset + (maxY / h) * canvasNodeHeight) / backgroundNodeHeight;

    this.bboxBottomPos = this.state.pageNumber - 1 + maxYRelPos;
    this.updateMaxViewedPos();

    if (this.pendingZoomFactorChange !== null) {
      const delta = this.pendingZoomFactorChange;
      this.pendingZoomFactorChange = null;
      this.changeZoomFactor(delta)();
    }
  }

  handlePageNumberChange = (n) => () => {
    this.setPageNumber(n);
  }

  handlePageNumberInput = (minPage, maxPage) => (n) => {
    if (minPage <= n && n <= maxPage) {
      this.setPageNumber(n);
    }
  }

  

  renderPageControls = () => {
    const { renderPDF } = this.state
    const pageNumber = this.state.pageNumber;
    const numPages = this.props.file ? this.props.maxPos : 1;
    const minPage = this.topPosToPage(this.props.minPos);
    const maxPage = numPages;
    const hidden = numPages === 1 || this.props.minPos === this.props.maxPos;
    const pageLast = maxPage === Math.floor(maxPage) ? maxPage : (Math.floor(maxPage)+1)
    return hidden ? null : (
      <div className="pdf-page-controls">
        <Button
          iconType={FirstPageIcon}
          disabled={
            (Object.keys(renderPDF).length <= 0 &&
              pageNumber <= minPage) ||
            (this.props.module.moduleProgress < 100 &&
              this.props.seekable === false) ||
            pageNumber <= minPage
          }
          onClick={this.handlePageNumberChange(minPage)}
          aria-label={labels.firstPage}
          key="pdf-first-pg"
        />
        <Button
          iconType={PreviousPageIcon}
          disabled={
            (Object.keys(renderPDF).length <= 0 &&
              pageNumber <= minPage) ||
            pageNumber <= minPage || (this.props.module.moduleProgress < 100 && Math.floor(this.props.maxPos)+1 === pageNumber)
          }
          onClick={this.handlePageNumberChange(pageNumber - 1)}
          aria-label={labels.previousPage}
          key="pdf-prev-pg"
        />
        <NumberInput
          className="page-number-input"
          defaultValue={pageNumber}
          disabled={maxPage <= minPage}
          onSubmit={this.handlePageNumberInput(minPage, maxPage)}
        />
        <Button
          iconType={NextPageIcon}
          disabled={
            (Object.keys(renderPDF).length <= 0 &&
              maxPage <= pageNumber) ||
            Object.keys(renderPDF).length <= 0 || (Math.floor(maxPage)+1 === pageNumber)
          }
          onClick={this.handlePageNumberChange(pageNumber + 1)}
          label={labels.nextPage}
          key="pdf-next-pg"
        />
        <Button
          iconType={LastPageIcon}
          disabled={
            Object.keys(renderPDF).length <= 0 ||
            (this.props.module.moduleProgress < 100 &&
              this.props.seekable === false) ||
            maxPage <= pageNumber
          }
          onClick={this.handlePageNumberChange(pageLast)}
          aria-label={labels.lastPage}
          key="pdf-last-pg"
        />
      </div>
    );
  }

  changeZoomFactor = (delta) => () => {
    this.setState((s0) => ({ zoomFactor: s0.zoomFactor + delta }));
  }

  renderZoomControls = () => {
    const zoomFactor = this.state.zoomFactor;
    return (
      <div className="pdf-zoom-controls">
        <Button
          iconType={IconCircleMinus}
          disabled={zoomFactor <= config.minPlayerZoomFactor}
          onClick={this.changeZoomFactor(-1)}
          aria-label={labels.zoomOut}
          key="pdf-zoom-out"
        />
        <Button
          iconType={IconCircleAdd}
          disabled={config.maxPlayerZoomFactor <= zoomFactor}
          onClick={this.changeZoomFactor(1)}
          aria-label={labels.zoomIn}
          key="pdf-zoom-in"
        />
      </div>);
  }

  renderControls = () => (
    // <div className="player-controls pdf-controls">
    <div className="player-controls pdf-controls" style={{alignItems:"center"}}>
      <div >
        {this.props.overlay === null  && 
        this.props.playerState === "playing" && 
        (Object.keys(this.state.renderPDF).length > 0 || this.state.renderPDF === 0 ) && 
        this.renderZoomControls()}
      </div>
      <div >
        {this.props.overlay === null && 
        this.props.playerState === "playing" && 
        this.renderPageControls()}
      </div>
      <div style={{ height: this.props.fromCourse === true ? "" : "40px" }}>
        {this.props.baseControls}
      </div>
    </div>);

  handlePosition = (maxpos,pagenumber) => {  // function is used to display the "Continue ,Resume module,Mark as complete" buttons on time 
    const {renderPDF} = this.state
    const maxPosition = Math.floor(maxpos)
    if ((maxPosition + 1) === pagenumber && this.props.overlay === null && (Object.keys(renderPDF).length > 0)) {
      return this.props.onAllViewed()
    }
    // else if (this.props.overlay !== null && this.props.icqSubmit){
    //   console.log("elseif",this.props.overlay , this.props.icqSubmit);
    //   this.props.handleIcq()
    //   return this.props.onAllViewed()  
      
    // }
    else if(this.props.maxPos === pagenumber && (Object.keys(renderPDF).length > 0)){
      this.setState({numPages:null,renderPDF:0})
      return this.props.onAllViewed()
    }
    }

    handleLoadSuccess = (numPages) => { // function is used to get the total length of the pdf
      this.setState({numPages:numPages._pdfInfo.numPages})
      this.props.onPdfLoaded(true);
    }

    HandleRenderSuccess = (data) => {  //function is used to know the pdf is loaded or not
      this.setState({renderPDF:data})
    }

  render = () => {
    const { pageNumber, zoomFactor } = this.state;
    const fsClassName =
      this.props.isFullscreen ? 'pdf-fullscreen' :  this.props.isShowSidebar ? 'pdf-window' : 'pdf-windowbg';
    const zoomClassName = zoomFactor > 0 ? 'pdf-zoomed-in' : 'pdf-unzoomed';
    const className = `pdf ${fsClassName} ${zoomClassName}`;

    const minPage = this.topPosToPage(this.props.minPos);
    // Handle the case where maxPos === minPos === <some integer>
    const maxPage = Math.max(minPage, this.bottomPosToPage(this.props.maxPos));

    const topMaskPercent = pageNumber !== minPage ? 0 :
        100 * (1 + this.props.minPos - minPage);
    const bottomMaskPercent = pageNumber !== maxPage ? 0 :
        100 * (maxPage - this.props.maxPos);

    const hideAll = this.props.minPos === this.props.maxPos;
    const topMaskOpacity    = hideAll ? 1.0 : config.pdfTopMaskOpacity;
    const bottomMaskOpacity = hideAll ? 1.0 : config.pdfBottomMaskOpacity;

    return (
      <div
        className={className}
        ref={(r) => { this.pdfR = r; }}
      >
        <div className="pdf-frame">
         
            <PdfPage
              file={this.props.file}
              pdf={this.props.pdf}
              overlay={this.props.overlay}
              pageNumber={pageNumber}
              width={this.state.pdfWidth}
              topMaskPercent={topMaskPercent}
              bottomMaskPercent={bottomMaskPercent}
              topMaskOpacity={topMaskOpacity}
              bottomMaskOpacity={bottomMaskOpacity}
              cropPaddingPercent={config.pdfCropPaddingPercent}
              modifyPageScrollTop={this.modifyPageScrollTop}
              onLoadSuccess={this.handleLoadSuccess}
              onLoadProgress={this.handleLoadProgress}
              onRenderSuccess={this.HandleRenderSuccess}
              ref={(r) => { this.pdfPageR = r; }}
              playerState = {this.props.playerState}
            />
         
          {this.props.message}
        </div>
        {this.renderControls()}
        {this.handlePosition (this.props.maxPos ,pageNumber)}
      </div>
    );
  }
}


class Pdf extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  initState = {
    pdf:     null,
    pdfData: null,
  };

  static propTypes = {
    module:         PropTypes.shape({
      url:            PropTypes.string,
      pdfContentId:   PropTypes.string,
                    }).isRequired,
    lastViewedPos:  PropTypes.number.isRequired,
    maxViewedPos:   PropTypes.number.isRequired,
    minPos:         PropTypes.number.isRequired,
    maxPos:         PropTypes.number.isRequired,
    onAllViewed:    PropTypes.func.isRequired,
    onError:        PropTypes.func,
    overlay:        PropTypes.shape({
      id:             PropTypes.string.isRequired,
      elt:            PropTypes.element,
                    }),
    isFullscreen:   PropTypes.bool.isRequired,
    baseControls:   PropTypes.element.isRequired,
  };

  componentDidMount = () => {
    this.getPdfContent();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.module !== this.props.module) {
      this.setState(this.initState);
      this.getPdfContent(nextProps);
    }
  }

  getPdfContent = (props = this.props) => {
    const contentId = props.module.pdfContentId;
    if (contentId) {
      props.ctx_api.privateRequest({
        cmd:  'get_video_unit_content',
        args: { contentId },
      })
        .then(this.handleGetModuleContentResponse);
    }
  }

  handleGetModuleContentResponse = ({ rsp }) => {
    if (0 < rsp.length) {
      this.setState({ pdfData: window.atob(rsp[0].content) });
    }
  }

  handleLoadSuccess = (numPages) => {
    this.setState({numPages:numPages})
   this.props.onPdfLoaded(true);
  }

  handleSourceError = (error) => {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  handleLoadError = (error) => {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  makeProxyUrl = (url) => `/proxy/${url}`;


  render = () => {
    const { module } = this.props;
    let file = null;
    if (module.url !== null) {
      const url = module.url.includes('public') ? module.url : this.makeProxyUrl(module.url); // HACK - 'public' string checking is a temporary hack 
      const httpHeaders = { "x-access-token": util.getAuth() };
      file = { url, httpHeaders };
    }
    else if (this.state.pdfData !== null) {
      file = { data: this.state.pdfData };
    }else{
      file={}
    }
    return (
      
      // <Document
      //   onContextMenu={(e)=> e.preventDefault()} //To prevent right-click. Right click makes downloading pdf page as image possible.
      //   file={file}
      //   loading={null}
      //   onLoadSuccess={this.handleLoadSuccess}
      //   onSourceError={this.handleSourceError}
      //   onLoadError={this.handleLoadError}
      // >
        <PdfViewer
          file={file}
          pdf={this.state.pdf}
          {...this.props}
          seekable = {this.props.seekable}
          moduleProgress = {this.props.module.moduleProgress}
        />
      
    );
  }
}


export default
withApiContext(
  Pdf
)
