import { withApiContext, withLabelsContext, withUserContext } from "context";
import React, { PureComponent } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { IconChevronDown } from "Icons";
import CreditHistoryTable from "./CreditHistoryTable";
import "./CreditHistory.css";

class CreditHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: this.props.showHistory,
    };
  }

  mkCreditsLabel = () => {
    return `${[1, 4].includes(this.props.ctx_user.user.type) ? "PLE" : "SME"} Credits`;
  };

  handleHideModal = () => {
    this.setState({ visibleModal: null });
  };

  renderModalHeader = () => {
    const m = this.state.visibleModal;
    const text = `${this.mkCreditsLabel()} History`;
    return <span className="page-title">{text}</span>;
  };

  renderModalBody = () => {
    return <CreditHistoryTable />;
  };

  renderModal = () => {
    const { visibleModal } = this.state;
    return (
      this.props.ctx_user.user && (
        <Modal
          size="xl"
          backdrop="static"
          show={!!this.state.visibleModal}
          onHide={this.handleHideModal}
          scrollable={true}
        >
          <Modal.Header closeButton={true}>
            {this.renderModalHeader()}
          </Modal.Header>
          <Modal.Body>{this.renderModalBody()}</Modal.Body>
        </Modal>
      )
    );
  };

  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        this.setState({ visibleModal: true });
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  render = () => {
    const { visibleModal } = this.state;
    return (
      <span className="private-header-credit-icon">
        <Dropdown>
          <Dropdown.Toggle
            as={this.CustomToggle}
            id="dropdown-custom-components"
          >
            <IconChevronDown width="12" height="12" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#" eventKey="history" active>
              View History
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {visibleModal && this.renderModal()}
      </span>
    );
  };
}

export default withApiContext(
  withLabelsContext(withUserContext(CreditHistory))
);
