import { Button, Loader, OverlayTooltip, SearchField } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import css from "./scss/subjects-menu.module.scss";
import * as config from "config";
import classNames from "classnames";

const StyledSubjectMenu = styled.div`
.Badge{
  background-color: ${({ theme }) => theme.primary};
}
  .${css.subject_section_title} {
    color: ${({ theme }) => theme.primary};
  }
  .${css.featured_item}, .${css.subject_section_list} {
    color: ${({ theme }) => theme.color2};
  }
  .${css.image}, .${css.image_placeholder} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );

    > .${css.placeholder_text} {
      color: ${({ theme }) => theme.button.primary.text};
    }
  }
`;
class ParentSubjectsMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredList: props.studentSubjects,
            showButton: props.showButton,
            showMore: false,
        };
        this.showMore = this.showMore.bind(this);
        this.setMenuRef = this.setMenuRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate = (_prevProps) => {
        if (
            _prevProps.studentSubjects.length !== this.props.studentSubjects.length
        ) {
            this.setState(() => ({ filteredList: this.props.studentSubjects }));
        }
    };

    setMenuRef(node) {
        this.menuRef = node;
    }

    handleClickOutside(event) {
        if (this.menuRef && !this.menuRef.contains(event.target)) {
            if (this.state.showMore && event.clientX > 707) {
                this.props.toggleSubjectsMenu();
            }
            if (!this.state.showMore) {
                this.props.toggleSubjectsMenu();
            }
        }
    }

    filterList(text) {
        if (text !== "") {
            let dataList = [];
            let allList = this.props.studentSubjects;

            allList.forEach((subject) => {

                if (
                    subject.course_name.toLowerCase().indexOf(text.toLowerCase()) !== -1
                ) {
                    dataList.push(subject);
                }

            });
            this.setState({
                filteredList: dataList,
            });
        } else {
            // set all list
            this.setState({
                filteredList: this.props.studentSubjects,
            });
        }
    }

    showMore() {
        this.setState({
            showMore: true,
        });
        this.props.toggleSubjectsMenu();
    }

    render() {
        let addDate = new Date();
        let days = config.subjectFlyoutLimitValue;
        const list = this.state.filteredList.sort((a, b) =>
            new Date(b.start_date) - new Date(a.start_date)).map((subject, index) => (
                <OverlayTooltip
                    tip={`One or more ${this.props.ctx_labels.plural.assessment.toLowerCase()} pending`}
                    id={index}
                    placement="top"
                    disabled={subject.pending_test === 0}
                >

                    <Link
                        key={index}
                        to={
                            {
                                pathname: config.ROUTES.subjectProgress,
                                data:
                                    subject.type === "L"
                                        ? subject
                                        : {
                                            coursePublishId: subject.course_publish_id,
                                            courseName: subject.course_name,
                                            curriculumId: subject.curriculum_id,
                                            courseId: subject.course_id,
                                            type: subject.type,
                                        },
                            }}
                        onClick={
                            subject.pending_test === 1 ? (e) => e.preventDefault() : this.props.toggleSubjectsMenu
                        }
                    >
                        <div className={css.subject_section} key={index}>
                            <div
                                className={classNames(
                                    css.subject_section_list,
                                    subject.pending_test === 1 && css.disabled
                                )}
                            >
                                {subject.course_name}
                                {(new Date(new Date(subject.start_date).setDate(new Date(subject.start_date).getDate() + days)) > addDate) && (subject.course_progress == null) ?
                                    <div class="Badge" style={{ marginLeft: "70px", position: "absolute", fontSize: "10px", marginTop: "-16px" }}>
                                        {this.props.ctx_labels.singular.new}
                                    </div> : null}
                            </div>

                        </div>
                    </Link>
                </OverlayTooltip>
            ));
        if (this.props.studentSubjects.length === 0 && !this.props.completed) {
            return <Loader active />;
        }
        if (this.props.studentSubjects.length === 0 && this.props.completed) {
            return (
                <div className={classNames(css.subjectComplete)}
                    onMouseDown={this.props.toggleSubjectsMenu}
                >
                    {this.props.ctx_labels.singular.notassociatedMsg}{" "}
                    {this.props.ctx_labels.singular.curriculum.toLowerCase()}. {this.props.ctx_labels.singular.pleaseContact} {this.props.ctx_labels.singular.trainer.toLowerCase()}.{" "}
                </div>
            );
        }

        return (
            <StyledSubjectMenu
                ref={this.setMenuRef}
                id="subject_more"
                className={!this.state.showMore ? css.wrapper : css.wrapper_more}
            >
                {/* featured subjects */}
                {!this.state.showMore && (
                    <div className={css.featured}>
                        {this.state.filteredList.slice(0, 7).map((subject, i) => {
                            const isNotSubscribed = subject.is_multicourse === 1 && typeof (subject.last_active_course) !== "string";
                            const styles = isNotSubscribed ? { background: "#dddddd", pointerEvents: "none" } : {};
                            return (
                                <OverlayTooltip
                                    tip={`Not yet subscribed`}
                                    id={i}
                                    placement="top"
                                    disabled={!isNotSubscribed}
                                >
                                    <Link
                                        key={i}
                                        to={
                                            subject.is_multicourse === 1
                                                ? {
                                                    pathname: config.ROUTES.subjectProgress,
                                                    data: subject,
                                                    from: "subMenu"
                                                }
                                                : {
                                                    pathname: config.ROUTES.subjectProgress,
                                                    data:
                                                        subject.type === "L"
                                                            ? subject
                                                            : {
                                                                coursePublishId: subject.course_publish_id,
                                                                courseName: subject.course_name,
                                                                curriculumId: subject.curriculum_id,
                                                                courseId: subject.course_id,
                                                                type: subject.type,
                                                                className: subject.classname,
                                                            },
                                                }}
                                        className={classNames(
                                            css.featured_item,
                                            subject.pending_test === 1 && css.disabled
                                        )}
                                        onClick={
                                            subject.pending_test === 1
                                                ? (e) => e.preventDefault()
                                                : this.props.toggleSubjectsMenu
                                        }
                                        style={styles}
                                    >
                                        {subject.image && (
                                            <img
                                                className={css.image}
                                                src={subject.image ?? null}
                                                alt=""
                                            />
                                        )}
                                        {!subject.image && (
                                            <div className={css.image_placeholder}>
                                                <div className={css.placeholder_text}>
                                                    {subject.course_name.charAt(0).toUpperCase()}
                                                </div>
                                            </div>
                                        )}
                                        <div>{subject.course_name}</div>
                                        {(new Date(new Date(subject.start_date).setDate(new Date(subject.start_date).getDate() + days)) > addDate) && (subject.course_progress == null) ?
                                            <div style={{ marginLeft: "180px", position: "absolute", fontSize: "10px", marginBottom: "30px" }}>
                                                {this.props.ctx_labels.singular.new}
                                            </div> : null}
                                    </Link>
                                </OverlayTooltip>
                            );
                        })}
                        {7 < this.state.filteredList.length && (
                            <div className="text-center">
                                <Link
                                    onClick={(e) => {
                                        this.showMore();
                                    }}
                                    to={{
                                        pathname: config.ROUTES.parentZonePage,
                                        subjectData: this.props.studentSubjects,


                                    }} >
                                    <Button variant="link" size="sm" onClick={this.showMore}>
                                        Show More
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </div>
                )}

                {/* all subjects */}
                {this.state.showMore && (
                    <div className={css.all}>
                        <div className={css.search_field}>
                            <SearchField
                                placeholder={`Search for ${this.props.ctx_labels.plural.course}`}
                                onKeyUp={(e) => this.filterList(e.currentTarget.value)}
                                overLightBg
                            />
                        </div>
                        <div className={css.subject_list}>{list}</div>
                    </div>
                )}
            </StyledSubjectMenu>
        );
    }
}

export default withLabelsContext(ParentSubjectsMenu);
