import Tabs from "react-responsive-tabs";
import React, { useRef } from "react";
import "./responsive-tabs-assessment.scss";
import "react-responsive-tabs/styles.css";
import { IconMenuVertical } from "Icons";
import styled from "styled-components";
import TabTails from "./TabTails";

// Shared styles for tabs
const StyledTabsCommon = styled.div`
  .RRT__container {
    .RRT__tab--selected {
      color: ${({ theme }) => theme.color2};
      border-color: ${({ theme }) => theme.color2};
      border-bottom-color: ${({ theme }) => theme.color2};
    }
    .RRT__showmore--selected {
      border-bottom-color: ${({ theme }) => theme.primary};
    }
    .RRT__showmore-list {
      .RRT__tab--selected {
        border-left-color: ${({ theme }) => theme.primary};
      }
    }
  }
`;

export default function ResponsiveTabsAssessment(props) {
  const scrollContainer = document.querySelector(".RRT__tabs");

  if (scrollContainer) {
    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });
  }

  const renderTabs = () => {
    if (props.fromStudentAssessment||props.fromtestPreview) {
      return (
        <StyledTabsCommon className={props.tight && "tab-size-tight"}>
          {/* {window.screen.width > 822 && */}
          {/* <TabTails
          fromStudentAssessment = {props.fromStudentAssessment}
        /> */}
        {/* } */}
          {/* {window.screen.width > 822 && <TabTails fromZonePage={props.fromZonePage} fromViewerForm={props.fromViewerForm} fromStudentAssessment={props.fromStudentAssessment} />} */}
          <Tabs
            transform={false}
            showMore={false}
            showTails={true}
            {...props}
          />
        </StyledTabsCommon>
      );
    } 
  };

  return renderTabs();
}
