
import React, { Component } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Card, Col, Row } from "react-bootstrap";
import "./StarRating.css";
import MediaQuery from 'react-responsive';
import styled from "styled-components";
import css from "./StarRating.css";
import "./Multistatics.css";


const divStyles = {
  width: 'auto',
  height: '400px',
  backgroundColor: 'white',
};

const cardStyles = {
  width: 'auto',
  height: '300px',
  position: 'relative',
  top: '70px',
  left: '20px',
  marginLeft: '-220px'
};

const cardStylesRsp = {
  width: '370px',
  marginLeft: '-15px',
  border: "none",
  height:'auto'
};

const cardStylesRsp2 = {
  width: "400px",
  marginLeft: '-15px',
  border: "none",
  height:'auto'
};

const questionStyle = {
  display: "inline-block",
  marginLeft: "14px",
  color: "#3DAEDA",
  fontWeight: "700",
  fontSize: "14px",
  fontFamily: "Poppins"
};

const questionDataStyle = {
  display: "inline-block",
  marginLeft: "14px",
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "400",
  color: "#000000"
};

const StyledWrapper = styled.h5`
  color: ${({ theme }) => theme.primary};
 
  }
`;

const StyledPublish = styled.div`
    && {
        .${css.surveyheader1} {
            color: ${({ theme }) => theme.primary};
        }
        .${css.surveyheader2}{
          color: ${({ theme }) => theme.color2};
        }
        .custom-progress-color .progress-bar {
          background-color: ${({ theme }) => theme.color1};
      }

        .custom-progress-color1 .progress-bar {
          background-color: ${({ theme }) => theme.color2};
      }

        .custom-progress-color2 .progress-bar {
          background-color: ${({ theme }) => theme.color3};
      }

        .custom-progress-color3 .progress-bar {
          background-color: ${({ theme }) => theme.color4}; 
      }
    }
`;



export default class Multistatics extends Component {
  render() {
    const { surveyData } = this.props;
    const currentQuestion = surveyData;
    const currentQuestionId = currentQuestion
      ? currentQuestion.survey_question_id
      : null;
    const surveyPoints = surveyData.answers && surveyData.answers.map(
      (answer) => answer.points)


    return (
      <>
        <MediaQuery maxWidth={575}>
          <div >
            <Card style={cardStylesRsp}>
              <React.Fragment>
                <div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5
                      style={{
                        ...questionStyle,
                        marginLeft: "-2px",
                        marginBottom: "8px",
                        marginTop: "0px",
                        paddingLeft: "10px",
                        border: "none",
                        flex: "0 0 auto",
                        
                      }}
                    >
                       <StyledWrapper>
                      QN{" "}{currentQuestionId}
                      </StyledWrapper>
                      </h5>
                    <h5
                      style={{
                        ...questionDataStyle,
                        marginLeft: "15px",
                        maxWidth: "80%",
                        flex: "1", 
                      }}
                    >
                      {surveyData && surveyData.survey_question_name}
                    </h5>
                  </div>





                  {/* <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                    <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>Excellent</div>
                    <ProgressBar now={surveyData.answers[0].excellent} className="custom-progressRsp excellent-progress" />
                    <div className="progress-valueRsp" style={{ position: 'absolute', top: -16, right: 10 }}>{surveyData.answers[0].excellent}%</div>
                  </div> */}


                  <Col className="column-multistaticsRsp">
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[0].survey_multiple_choice}</div>
                        <ProgressBar now={surveyData.answers && surveyData.answers[0].points} 
                        //className="custom-progressRsp custom-progress-color" 
                        className={
                          surveyData.answers[0].points >= 75
                            ? 'custom-progress-main custom-progress-color'
                            : surveyData.answers[0].points >= 50
                              ? 'custom-progress-main custom-progress-color1'
                              : surveyData.answers[0].points >= 25
                                ? 'custom-progress-main custom-progress-color2'
                                : 'custom-progress-main custom-progress-color3'
                        }
                        style={{ width: '250px', height: '25px', marginBottom: '20px' }} />
                      </div>
                      <div className="progress-multivalueRsp" style={{ position: 'relative', top: 5, left: -33, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[0].points.toFixed(2)}%<div style={{ marginTop: "-15px", marginRight: "-16px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[0].option_count})</div></div>
                    </div>
                    </StyledPublish>
                    {surveyData.answers && surveyData.answers.length > 1 ?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers[1] && surveyData.answers[1].survey_multiple_choice}</div>
                        <ProgressBar now={surveyData.answers[1] && surveyData.answers[1].points} 
                        //className="custom-progress custom-progress-color1" 
                        className={
                          surveyData.answers[1].points >= 75
                            ? 'custom-progress-main custom-progress-color'
                            : surveyData.answers[1].points >= 50
                              ? 'custom-progress-main custom-progress-color1'
                              : surveyData.answers[1].points >= 25
                                ? 'custom-progress-main custom-progress-color2'
                                : 'custom-progress-main custom-progress-color3'
                        }
                        style={{ width: '250px', height: '25px', marginBottom: '20px' }} />
                      </div>
                      <div className="progress-multivalueRsp" style={{ position: 'relative', top: 5, left: -33, fontWeight: 500 }}>{surveyData.answers[1] && surveyData.answers[1].points.toFixed(2)}%<div style={{ marginTop: "-15px", marginRight: "-16px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[1].option_count})</div></div>
                    </div>
                    </StyledPublish>
                    :null}

                    {surveyData.answers && surveyData.answers.length === 3 ?
                    <StyledPublish>
                      <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0', position: 'relative', marginLeft: "-75px" }}>
                        <div className="progress-labelRsp" style={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers.length === 3 ? surveyData.answers[2].survey_multiple_choice : ""}</div>
                          <ProgressBar now={surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points : 0} 
                          //className="custom-progress custom-progress-color2" 
                          className={
                            surveyData.answers[2].points >= 75
                              ? 'custom-progress-main custom-progress-color'
                              : surveyData.answers[2].points >= 50
                                ? 'custom-progress-main custom-progress-color1'
                                : surveyData.answers[2].points >= 25
                                  ? 'custom-progress-main custom-progress-color2'
                                  : 'custom-progress-main custom-progress-color3'
                          }
                          style={{ width: '250px', height: '25px', marginBottom: '20px' }} />
                        </div>
                        <div className="progress-multivalueRsp" style={{ position: 'relative', top: -22, left: -33, fontWeight: 500 }}>{surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points.toFixed(2) : 0}%<div style={{ marginTop: "-15px", marginRight: "-16px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[2].option_count})</div></div>
                      </div>
                      </StyledPublish> : null}

                  </Col>

                </div>
              </React.Fragment>
            </Card>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={576} maxWidth={820}>
        <div>
            <Card style={cardStylesRsp2}>
              <React.Fragment>
                <div style={{width: 'auto'}}>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5
                      style={{
                        ...questionStyle,
                        marginLeft: "5px",
                        marginBottom: "8px",
                        marginTop: "19px",
                        paddingLeft: "10px",
                        border: "none",
                        flex: "0 0 auto", 
                      }}
                    >
                       <StyledWrapper>
                      QN{" "}{currentQuestionId}
                      </StyledWrapper>
                    </h5>
                    <h5
                      style={{
                        ...questionDataStyle,
                        marginLeft: "15px",
                        flex: "1", 
                        marginTop: "20px",
                        maxWidth: "74%"
                      }}
                    >
                      {surveyData && surveyData.survey_question_name}
                    </h5>
                  </div>





                  {/* <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                    <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>Excellent</div>
                    <ProgressBar now={surveyData.answers[0].excellent} className="custom-progressRsp excellent-progress" />
                    <div className="progress-valueRsp" style={{ position: 'absolute', top: -16, right: 10 }}>{surveyData.answers[0].excellent}%</div>
                  </div> */}

                 
                  <Col className="column-multistaticsRsp">
                  
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[0].survey_multiple_choice}</div>
                        <ProgressBar now={surveyData.answers && surveyData.answers[0].points} 
                        //className="custom-progressRsp custom-progress-color" 
                        className={
                          surveyData.answers[0].points >= 75
                            ? 'custom-progress-main custom-progress-color'
                            : surveyData.answers[0].points >= 50
                              ? 'custom-progress-main custom-progress-color1'
                              : surveyData.answers[0].points >= 25
                                ? 'custom-progress-main custom-progress-color2'
                                : 'custom-progress-main custom-progress-color3'
                        }
                        style={{ width: '390px', height: '25px', marginBottom: '20px' }} />
                      </div>
                      <div className="progress-multivalueRsp" style={{ position: 'absolute', top: 5, left: 390, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[0].points.toFixed(2)}%<div style={{ marginTop: "-15px", marginRight: "-18px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[0].option_count})</div></div>
                    </div>
                    </StyledPublish>
                    

                    {surveyData.answers && surveyData.answers.length > 1 ?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[1].survey_multiple_choice}</div>
                        <ProgressBar now={surveyData.answers && surveyData.answers[1].points} 
                        //className="custom-progress custom-progress-color1" 
                        className={
                          surveyData.answers[1].points >= 75
                            ? 'custom-progress-main custom-progress-color'
                            : surveyData.answers[1].points >= 50
                              ? 'custom-progress-main custom-progress-color1'
                              : surveyData.answers[1].points >= 25
                                ? 'custom-progress-main custom-progress-color2'
                                : 'custom-progress-main custom-progress-color3'
                        }
                        style={{ width: '390px', height: '25px', marginBottom: '20px' }} />
                      </div>
                      <div className="progress-multivalueRsp" style={{ position: 'absolute', top: 5, left: 390, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[1].points.toFixed(2)}%<div style={{ marginTop: "-15px", marginRight: "-18px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[1].option_count})</div></div>
                    </div>
                    </StyledPublish>
                    :null}

                    {surveyData.answers && surveyData.answers.length === 3 ?
                    <StyledPublish>
                      <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0', position: 'relative', marginLeft: "-75px" }}>
                        <div className="progress-labelRsp" style={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers.length === 3 ? surveyData.answers[2].survey_multiple_choice : ""}</div>
                          <ProgressBar now={surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points : 0} 
                          //className="custom-progress custom-progress-color2" 
                          className={
                            surveyData.answers[2].points >= 75
                              ? 'custom-progress-main custom-progress-color'
                              : surveyData.answers[2].points >= 50
                                ? 'custom-progress-main custom-progress-color1'
                                : surveyData.answers[2].points >= 25
                                  ? 'custom-progress-main custom-progress-color2'
                                  : 'custom-progress-main custom-progress-color3'
                          }
                          style={{ width: '390px', height: '25px', marginBottom: '20px' }} />
                        </div>
                        <div className="progress-multivalueRsp" style={{ position: 'absolute', top: -22, left: 390, fontWeight: 500 }}>{surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points.toFixed(2) : 0}%<div style={{ marginTop: "-15px", marginRight: "-18px", fontSize: "10px" }}>({surveyData.answers && surveyData.answers[2].option_count})</div></div>
                      </div>
                      </StyledPublish> : null}

                  </Col>

                </div>
              </React.Fragment>
            </Card>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={821}>
          {/* <div style={divStyles}> */}
          <React.Fragment>
            <div className='question-multi'>
              <h5
                style={{
                  ...questionStyle,
                  display: "inline-block",
                  marginLeft: "15px",
                  marginBottom: "8px",
                  paddingLeft: "10px",
                  alignItems: "flex-start"
                }}
              >
                 <StyledWrapper>
                  QN&nbsp;{currentQuestionId}
                 </StyledWrapper>
              </h5>
              <h5
                style={{
                  ...questionDataStyle,
                  display: "inline-block",
                  marginLeft: "15px",
                }}
              >
                {surveyData && surveyData.survey_question_name}
              </h5>
            </div>
            <div style={{

              marginLeft: "-40px",
              paddingTop: "20px"
            }}>
              <Row>

                <Col className="column-multistatics">
                  <StyledPublish>
                  <div className="progress-wrapper" style={{ textAlign: 'left', }}>
                    <div className="progress-label" style={{ position: 'relative' }}>
                      <div className={css.surveyheader1} style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers && surveyData.answers[0].survey_multiple_choice}</div>
                      <ProgressBar now={surveyData.answers && surveyData.answers[0].points} 
                      //className="custom-progress-main custom-progress-color" 
                      className={
                        surveyData.answers[0].points >= 75
                          ? 'custom-progress-main custom-progress-color'
                          : surveyData.answers[0].points >= 50
                            ? 'custom-progress-main custom-progress-color1'
                            : surveyData.answers[0].points >= 25
                              ? 'custom-progress-main custom-progress-color2'
                              : 'custom-progress-main custom-progress-color3'
                      }
                      style={{  height: '25px', marginBottom: '20px', }} />
                      </div>
                    <div className="progress-multivalue">{surveyData.answers && surveyData.answers[0].points.toFixed(2)}%<div style={{ marginTop: "-17px", marginLeft: "50px", fontSize: "12px" }}>({surveyData.answers && surveyData.answers[0].option_count})</div></div>
                  </div>
                  </StyledPublish>

                  {surveyData.answers && surveyData.answers.length > 1 ? 
                  <StyledPublish>
                  <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0' }}>
                    <div className="progress-label" style={{ position: 'relative' }}>
                      <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers[1].survey_multiple_choice && surveyData.answers[1].survey_multiple_choice}</div>
                      <ProgressBar now={surveyData.answers && surveyData.answers[1].points} 
                      //className="custom-progress-main custom-progress-color1" 
                      className={
                        surveyData.answers[1].points >= 75
                          ? 'custom-progress-main custom-progress-color'
                          : surveyData.answers[1].points >= 50
                            ? 'custom-progress-main custom-progress-color1'
                            : surveyData.answers[1].points >= 25
                              ? 'custom-progress-main custom-progress-color2'
                              : 'custom-progress-main custom-progress-color3'
                      }
                      style={{  height: '25px', marginBottom: '20px' }} />
                    </div>
                    <div className="progress-multivalue">{surveyData.answers && surveyData.answers[1].points.toFixed(2)}%<div style={{ marginTop: "-18px", marginLeft: "50px", fontSize: "12px" }}>({surveyData.answers && surveyData.answers[1].option_count})</div></div>
                  </div>
                  </StyledPublish>
                  :null}

                  {surveyData.answers && surveyData.answers.length === 3 ? 
                  <StyledPublish>
                  <div className="progress-wrapper" style={{ textAlign: 'left', margin: '20px 0' }}>
                    <div className="progress-label" style={{ position: 'relative' }}>
                      <div style={{ position: 'absolute', top: '-20px', left: 0, fontWeight: 500 }}>{surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points : 0}</div>
                      <ProgressBar now={surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points : 0} 
                      //className="custom-progress-main custom-progress-color2" 
                      className={
                        surveyData.answers[2].points >= 75
                          ? 'custom-progress-main custom-progress-color'
                          : surveyData.answers[2].points >= 50
                            ? 'custom-progress-main custom-progress-color1'
                            : surveyData.answers[2].points >= 25
                              ? 'custom-progress-main custom-progress-color2'
                              : 'custom-progress-main custom-progress-color3'
                      }
                      style={{  height: '25px', marginBottom: '20px' }} />
                    </div>
                    <div className="progress-multivalue">{surveyData.answers && surveyData.answers.length === 3 ? surveyData.answers[2].points.toFixed(2) : 0}%<div style={{ marginTop: "-16px", marginLeft: "50px", fontSize: "12px" }}>({surveyData.answers && surveyData.answers[2].option_count})</div></div>
                  </div>
                  </StyledPublish>
                    : null}
                </Col>


              </Row>


            </div>
          </React.Fragment>

          {/* </div> */}

        </MediaQuery>
      </>
    )
  }
}
