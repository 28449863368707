import React, { Component } from "react";
import { withApiContext, withUserContext, withAccessContext } from "context";
import ChapterInner from 'pages/ChapterInner/ChapterInner';
import * as config from "config";

class PlayerHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      subject: [],
      classId: "",
      accadamicYearId: "",
    };
  }

  componentDidMount() {
    // console.log("here");
    this.getPlayerLogin();
  }

  // getPlayerLogin = () => {
  getPlayerLogin = (prop = this.props.match) => {
    const { userId, courseId } = prop.params;
   // console.log("player Home reached", new Date());
  //  console.log("flag", this.state.flag);
    this.state.flag && this.setState({ flag: false });
    this.props.ctx_api
      .publicRequestRow({
        cmd: "player_login",
        args: {
          // userId: "554b5843-7d5a-4fba-8b4b-46f83d1e4e13",
          // courseId: "b4342814-9f77-4ade-878f-43f8cc4cdbe7"
          // userId: "3",
          // courseId: "1121",
          userId: userId,
          courseId: courseId,
        },
      })
      .then(this.handleGetPlayerLogin)
      .catch(this.handlePlayerLoginFailure);
  };

  handleGetPlayerLogin = ({ rsp, headers }) => {
    const stayLoggedIn = config.defaultStayLoggedIn;
    // this.resetForm();
    const auth = headers.get("x-access-token");
    const success = rsp.statusvalue === 1 && auth;
    // console.log("auth---",auth);
    if (success) {
      // console.log("rsp---",rsp);
      // console.log("rsp.curriculum---",rsp.curriculum);
      // console.log("rsp.curriculum[0]---",rsp.curriculum[0]);
      const subject = rsp.curriculum;
      // console.log("subject---",subject);
      const curriculum = {
        ...subject,
        curriculumId: subject.curriculum_id.toString(),
      };
      this.setState({
        status: rsp.statusvalue ? true : false,
        subject: curriculum,
        memberId:rsp.member_id,
        classId: rsp.class_id,
        accadamicYearId: rsp.acad_year_id,
      });
      this.props.ctx_access.logIn(auth, rsp, stayLoggedIn);
    }
  };

  handlePlayerLoginFailure = () => {};

  render() {
    const { subject,memberId,classId,accadamicYearId } = this.state;
    if (!this.state.status) {
      return null;
    }

    return (
      <div>
        <ChapterInner
          memberId={memberId}
          accadamicYearId={accadamicYearId}
          classId={classId}
          coursePublishId={subject.course_publish_id.toString()}
          subject={subject}
          chapterId={null}
          playerOnly={true}
          viewType={subject.type}
          selectedChapterId={null}
          onSelectModule={() => {}}
          refreshChapter={() => {}}
          refreshModules={() => {}}
          onCreditsChange={() => {}}
          listCurriculums={() => {}}
          // refreshCurriculumList={this.refreshChapterList}
          refreshCurriculumList={() => {}}
          backToChapter={() => {}}
          grouped={true}
        />
      </div>
    );
  }
}
export default withApiContext(withUserContext(withAccessContext(PlayerHome)));
