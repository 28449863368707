import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Button } from "react-bootstrap";
import InputField from 'components/FormField';
// import { Rating } from "react-simple-star-rating";
import StarRatings from "react-star-ratings";

function AnswerList({handleAdd, handleEdit, value ,ratingType}) {
  const choices = value.map((answer, index) => (
    
    <Row key={index} style={{ display: "flex", flexDirection: "row" }}>
      <Col>
        <InputField
          name={`rating${index}`}
          placeholder="Enter the Answer"
          onChange={(event) => handleEdit(event, index)}
          value={answer.choice}
        />
      </Col>
    </Row>
  ));
    
  return (
    <div className="rating-inputs">
      <Row style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
        <Col md={12}>
          <StarRatings
            starDimension={"28px"}
            starRatedColor="#ffdf00"
            emptyColor="#ddd"
            numberOfStars={ratingType === "five" ? 5 : 3}
            onClick={handleAdd}
          />
        </Col>
      </Row>
      <Row style={{ display: "flex", flexDirection: "row" }}>
        <Col md={12}>{choices}</Col>
      </Row>
    </div>
  );
}


function RatingQuestionAnswers(props) {
  const [addedAnswers, setAddedAnswers] = useState([]);
  const [highestNegativeAnswerId, setHighestNegativeAnswerId] =
    useState(props.isAnswerType !== undefined ? -2 : -1);
  const [modified, setModified] = useState(props.modified)

  useEffect(() => {
    // if (props.name !== "choices") {
    //   setAddedAnswers(props.value);
    //   setHighestNegativeAnswerId(-2);
    // }
    if (modified && props.questionType === "2") {
      setAddedAnswers(
        props.value
      );
      setHighestNegativeAnswerId(-2);
      setModified(false)
    }
    else if (props.ratingType === "five" && !modified) {
      const newAnswers = [
        { choice_id: 1, choice: "Very Bad"},
        { choice_id: 2, choice: "Bad"},
        { choice_id: 3, choice: "Average"},
        { choice_id: 4, choice: "Good"},
        { choice_id: 5, choice: "Very Good"},
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(-2);
    } else if (props.ratingType === "three" && !modified) {
      const newAnswers = [
        { choice_id: 1, choice: "Bad"},
        { choice_id: 2, choice: "Average"},
        { choice_id: 3, choice: "Good"},
      ];
      setAddedAnswers(newAnswers);
      props.onChange(newAnswers);
      setHighestNegativeAnswerId(-2);
    } 
  }, [props.ratingType]);

  const handleEdit = (event, index) => {
    const updatedAnswers = [...addedAnswers];
    const truncatedValue = event.value.slice(0, 19); 
    updatedAnswers[index].choice = truncatedValue; // Ensure event.target is defined
    setAddedAnswers(updatedAnswers);
    props.onChange(updatedAnswers);
  };

  const handleAdd = () => {
    const newAnswerId = highestNegativeAnswerId + 1;
    const updatedAnswers = [...addedAnswers, { choice_id: newAnswerId, choice: "" }];
    setAddedAnswers(updatedAnswers);
    setHighestNegativeAnswerId(newAnswerId);
    props.onChange(updatedAnswers);
  };


  return (
    <div>
      <AnswerList
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        // handleChange={handleChange}
        value={addedAnswers}
        ratingType={props.ratingType}
      />
    </div>
  );
}

export default RatingQuestionAnswers;
