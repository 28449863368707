import DashboardPanel from "components/DashboardPanel/DashboardPanel";
import { withLabelsContext } from "context";
import React from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import getTableStyles from "themes/table-styles";
import * as util from 'services/util';
import { OverlayTooltip } from "components";

function VCStatus(props) {
    const theme = useTheme();
    const tableStyles = getTableStyles(theme);
    tableStyles.pagination.style.borderTopWidth = "1px !important";
    tableStyles.pagination.style.borderTopStyle = "solid !important";
  
    const parseDate = (dateStr) => {
      if (!dateStr) {
          console.error("Date string is undefined or empty:", dateStr);
          return new Date(0); // return a default date if undefined
      }
      const [day, month, year] = dateStr.split('-');
      const monthMap = {
          JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
          JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
      };
      return new Date(year, monthMap[month], day);
  };

  const sortDate = (rowA, rowB) => {
      const dateA = parseDate(rowA.start_date);
      const dateB = parseDate(rowB.start_date);
      return dateA - dateB;
  };
    
    const columns = [
      {
        name: `${props.ctx_labels.singular.session} ${props.ctx_labels.singular.name}`,
        selector: row => row.live_session_name,
        sortable: true,
        cell: (e) =>{
          return(
            <OverlayTooltip placement="top" tipId={e.id} tip={e.live_session_name}>
              <span style={{display:"block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}> 
                {e.live_session_name}
              </span>
            </OverlayTooltip>
          )
        }
      },
      {
        name: `${props.ctx_labels.singular.conductedBy}`,
        selector: row => row.created_by,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.classnBatch}`,
        selector: row => row.class_batch_name,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.date}`,
        selector: row => row.start_date,
        sortable: true,
        sortFunction: sortDate,
      },
      {
        name: `${props.ctx_labels.singular.session} ${props.ctx_labels.singular.start} ${props.ctx_labels.singular.time}`,
        selector: row => row.start_time,
        sortable: true,
        center: true,
      },
      {
        name: `${props.ctx_labels.singular.session} ${props.ctx_labels.singular.end} ${props.ctx_labels.singular.time}`,
        selector: row => row.end_time,
        sortable: true,
        center: true,
      },
      {
          name: `${props.ctx_labels.singular.attended} ${props.ctx_labels.singular.time}`,
          selector: row => row.duration,
          sortable: true,
          center: true,
        },
    ];
    
    return  (
      <DashboardPanel
        title={`${props.ctx_labels.singular.liveSessions} ${props.ctx_labels.singular.status}`}
        fullScreen
      >
        <Card className="pb-25 border-0">
          <DataTable
            noHeader={true}
            columns={columns}
            data={props.data}
            customStyles={tableStyles}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            pointerOnHover={true}
            striped
          />
        </Card>
      </DashboardPanel>
    );
  }
  
  export default withLabelsContext(VCStatus);
  