//import FormRadioGroup from '../../../components/RadioButton';
import Select from "components/Select/Select";
import { withApiContext, withUserContext } from 'context';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Row } from "react-bootstrap";
import EntitySearch from '../Entity/Search';
import * as util from 'services/util';
import './Search.css';
import { withRouter } from "react-router-dom";
import CreatedBySelect from "components/Select/CreatedBySelect";
import { OverlayTooltip } from "components";

class SurveySearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { typeId: 1, totalRows: 0 }
    this.initFieldState = {
      categoryId: "",
      curriculumId: "",
      coursePublishId: "",
      courseId: "",
      name: "",
      credit: "",
      status: "",
      subject: "",
      standard: "",
      createdBy:"",
      createdOn:"",
      viewType: "mine",
      // validFrom: '',
      // validTo:  '',
    };
    if (props.location.data) {
      this.initFieldState.categoryId = props.location.data.course_category_id || "";
      this.initFieldState.curriculumId = props.location.data.master_curriculum_id || "";
      this.initFieldState.coursePublishId = props.location.data.course_publish_id || "";
      this.initFieldState.courseId = props.location.data.course_id || "";
    }

    this.tableColumns = this.mkTableColumns(props);
  }

  static propTypes = {
    action: PropTypes.string.isRequired,
  };

  // isAdmin = (props = this.props) => props.ctx_user.user.type === -1;
  // toPdfLength = (n) => `${n} page${n === 1 ? '' : 's'}`;
  isTeacher = (props = this.props) => props.ctx_user.user.type === 2;

  search = async (s) => {
    const viewable = s.viewType === "all" ? 0 : 1;
    const args = {
            viewable: viewable,
            pageSize: s.newPerPage || 10,
            pageIndex: s.page || 1,
            sortColumn: s.column || 'default',
            sortOrder: s.sortDirection || 'asc',
            export: s.isExport
    };
    if (s.categoryId) {
      args.categoryId = s.categoryId.toString();
    }
    if (s.curriculumId) {
      args.curriculumId = s.curriculumId;
    }
     
    if (s.name) {
      args.name = s.name;
    }
      
    if (s.createdBy) {
      args.createdBy = s.createdBy;
    }
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "get_surveys_with_pagination",
      args: args,
    });
    this.setState({ totalRows: rsp[0]?.total_count || 0 });
    return rsp.length === 0
      ? []
      : rsp[0].selection.map((r) => {
      return {
        id: r.survey_id,
        name: r.name,
        shortName: r.short_name,
        credit: r.credit,
        // validFrom:    r.valid_from,
        // validTo:      r.valid_to,
        status: r.status === 1 ? "Yes" : "No",
        subject: r.subject,
        standard: r.standard,
        testId: r.survey_id,
        standardId: r.standard_id,
        subjectId: r.subject_id,
        questions: r.questions,
        createdBy: r.created_by,
        channel: r.channel,
        standard: r.categorization,
        createdOn: util.toDateString(new Date(r.created_on)),
        coursePublishId: this.props.location.data ? this.props.location.data.course_publish_id : null,
        courseId: this.props.location.data ? this.props.location.data.course_id : null,
        fromClassView: this.props.location.data ? 1 : 0,
        audience: this.props.location.data ? true : false,
        allocIds: this.props.location.data ? this.props.location.data.alloc_ids : [],
        pubAlloc: this.props.location.data ? this.props.location.data.pub_alloc : null,
        can_delete: r.can_delete
        // __sortName: r.name.toLowerCase(),
      };
    });
  };

  mkTableColumns = (props) => {
    let tableCols = [
      {
        name: this.props.ctx_labels.singular.name,
        selector: row =>row.name,
        key: "audience",
        sortable: true,
        cell: (e)=>{
          return(
            <OverlayTooltip placement="top" tipId={e.id} tip={e.name}>
              <span style={{display:"block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {e.name}
              </span>
            </OverlayTooltip>
          )
        }
      },
      // {
      //   name: this.props.ctx_labels.singular.standard,
      //   selector: row =>row.standard,
      //   sortable: true,
      // },
      // {
      //   name: this.props.ctx_labels.singular.shortName,
      //   selector: row =>row.internalId,
      //   sortable: true,
      // },
      {
        //name: this.props.ctx_labels.singular.channel,
        name: "channel",
        selector: row =>row.channel,
        key: "channel",
        sortable: true,
      },
      {
        name: this.props.ctx_labels.singular.createdBy,
        selector: row =>row.createdBy,
        key: "createdBy",
        sortable: true,
      },
      {
        name: this.props.ctx_labels.singular.createdOn,
        selector: row =>row.createdOn,
        key: "createdOn",
        sortable: true,
      },
    ];
    if (props.classId) {
      tableCols.allocated = {
        label: 'Allocated',
        sortPropName: '__sortAllocation',
        alignment: 'center',
      };
    }
    return tableCols;
  };

  renderFields = ({ SearchField, SearchButton, onChange }) => {
  const campusSubj = [... this.props.ctx_user.user.campusSubj];
    const options = [
      { label: this.props.ctx_labels.singular.showMyTest, value: "mine" },
      { label: this.props.ctx_labels.singular.showAllTest, value: "all" },
    ];
    return (
      <React.Fragment>
        <div className="search-wrapper">
          <Row>
            <Col md={3}>

              <SearchField
                className="test-search-form-name"
                name="name"
                label="Name"
                placeholder="Name"
              />
            </Col>
            {/* <Col md={3}> */}
              {/* <SearchField
                className="test-search-form-standard"
                type={Select}
                label={this.props.ctx_labels.singular.category}
                name="categoryId"
                options={this.props.ctx_user.user.campusCategories}
                placeholder={`Select ${util.capitalizeWord(
                  this.props.ctx_labels.singular.standard
                )}`}
              /> */}
            {/* </Col> */}
            {/* <Col md={3}> */}
              {/* <SearchField
                className="test-search-form-curriculum"
                label={this.props.ctx_labels.singular.curriculum}
                name="curriculumId"
                type={Select}
                options={campusSubj}
                placeholder={`Select ${util.capitalizeWord(
                  this.props.ctx_labels.singular.curriculum
                )}`}
              /> */}
            {/* </Col> */}
            <Col md={3}>
              <SearchField
                className="test-search-form-createdBy"
                label={this.props.ctx_labels.singular.createdBy}
                name="createdBy"
                type={CreatedBySelect}
                options={this.props.ctx_user.user.created_by}
                placeholder={`Select ${util.capitalizeWord(
                  this.props.ctx_labels.singular.created_by
                )}`}
              />
            </Col>
            <Col md={3}>  
        
        </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  render = () => (
    <EntitySearch
      {...this.props}
      initFieldState={this.initFieldState}
      fieldsRenderer={this.renderFields}
      search={this.search}
      tableColumns={this.tableColumns}
      addButtonLabel={`Publish ${this.props.ctx_labels.singular.test}`}
      showToggle={this.isTeacher()}
      totalRows={this.state.totalRows}
      paginationServer
      sortServer
    />
  );
}

export default withApiContext(withUserContext(withRouter(SurveySearch)));