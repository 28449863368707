import avatar from "./images/avatar.png";
import styled from "styled-components";
import {
  withAccessContext,
  withLabelsContext,
  withUserContext,
  withSiteContext,
} from "context";
import css from "./scss/user-profile.module.scss";
import { useMediaQuery } from "react-responsive";
import * as config from "config";
import { Link } from "react-router-dom";

const StyledUserProfile = styled.div`
  padding: 1.25rem 1rem;
  background-color: rgba(0, 59, 161, 0.54);
  background-color: ${({ theme }) => theme.color3}8a;
  text-align: center;
  color: #ffffff;
`;

const UserProfile = (props) => {
  // const photo = props.ctx_user.user.type === 4
  //      ? props.ctx_user.user.dependantPhoto
  //       ? props.ctx_user.user.dependantPhoto.replace(/^data:image\/\w+;base64,/, "")
  //       : avatar



  const photo =
    // props.ctx_user.user.type === 4
    //   ? props.ctx_user.user.dependantPhoto
    //     ? !props.ctx_user.user.dependantPhoto.match(/^data:image\/\w+;base64,/) !== null ?
    //     props.ctx_user.user.dependantPhoto :
    //     `data:image/png;base64,${props.ctx_user.user.dependantPhoto}`
    //     : avatar
    props.ctx_user.user.photo
      ? props.ctx_user.user.photo.match(/^data:image\/\w+;base64,/) !== null
        ? props.ctx_user.user.photo
        : `data:image/png;base64,${props.ctx_user.user.photo}`
      : avatar;
  const campusList = props.ctx_user.user.campusList;
  const organizationId = props.ctx_user.user.organizationId;

  const roleId = props.ctx_user.user.roleId;
  const campus = campusList.find(
    (d) => d.campus_role_id == `${organizationId} : ${roleId}`
  );
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 767px)" });
  return (
    <StyledUserProfile>
      {/* photo */}
      <Link to={config.ROUTES.profileSettings}>
        <div className="mb-2">
          {props.fromSidebarIcons ?
            <img className={css.profile_pic_shrink} src={photo} alt="" />
            : <img className={css.profile_pic} src={photo} alt="" />
          }
          {/* <img className={css.profile_pic} src={photo} alt="" /> */}
        </div>
      </Link>
      {!props.fromSidebarIcons &&
        <div>
          {props.ctx_user.user.firstName}
        </div>
        }
      {!props.fromSidebarIcons &&
        <div className="text-primary">
       {props.ctx_user.user.className}
      </div>
      } 
      {!isTabletOrMobile && (
        <div>
          <span>{campus ? `${campus.organization_name} - ${campus.role_name}` : null}</span>
          <br />
          {campus?.acad_year_name || ""}
        </div>
      )}
    </StyledUserProfile>
  );
};

export default withUserContext(
  withLabelsContext(withAccessContext(withSiteContext(UserProfile)))
);
