import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import FormField from "../../components/FormField";
import css from "../../pages/StudentAssessments/Question/question.module.scss";
import { Button, Textarea } from "components";
import './StudentSurvey.css'

const StarRating = (props) => {
  const [rating1, setRating1] = useState(0);
  const [label1, setLabel1] = useState("");
  const [comment, setComment] = useState("")

  useEffect(() => {
    if (props.starRatings && props.starRatings.length > 0) {
      const defaultRating = rating1;
      setLabel1(props.starRatings.find(item => item.choice_id === defaultRating)?.choice || "");
    }
    if(props.response&& props.userSelection){
      setComment(props.userSelection.comment);
      setRating1(props.userSelection.selected_id)
      setLabel1(props.starRatings.find(item => item.choice_id === props.userSelection.selected_id)?.choice || "");
    }
  }, [props.starRatings, props.questionId]);

  const handleUserSelection = (answerId, updatedComment) => {
    if (answerId) {
      const selectedLabel = props.starRatings.find(item => item.choice_id === answerId)?.choice || "";
      setRating1(answerId);
      setLabel1(selectedLabel);
      // props.survey && props.onAnswered([answer_id, selectedLabel]);
      const updatedSurveyDetails = {
        ...props.surveyDetails,
        questions: props.surveyDetails.questions.map(sec => {
          if (sec.question_order === props.questionOrder) {
            return {
              ...sec,
              user_selection: 
                { selected_id: answerId, comment: updatedComment}
              
            };
          }
          return sec;
        })
      };
      props.onAnswered(updatedSurveyDetails);
      setComment(updatedComment)
    }
   
  };
  return (
    <div>
      {/* <div className={`${css.title} text-color4`}>Survey</div> */}
      <div className="test-question">{props.questionOrder}. {props.question}</div>
      <div>
      <Row>
        <Col md={6} style={{paddingBottom:"16px"}}>
          <Row>
            <div style={{paddingLeft:"13px"}}>
        <StarRatings
          // style={{padding:"0px"}}
          rating={rating1}
          starDimension={"28px"}
          starRatedColor="#ffdf00"
          starHoverColor="#ffdf00"
          changeRating={(answerId) => handleUserSelection(answerId, comment)}
          // changeRating={handleRating}
          starSpacing="3px"
          numberOfStars={props.displayType === 4 ? 5 : 3}
          name="rating"
        />
        </div>
        <Col style={{ marginBlock: "auto"}}>
          <div>
            {label1 && (
              <Card
                className={`border-1`}
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  backgroundColor: "#001B4A",
                  padding:"2px 10px",
                  color:"#fff",
                  borderRadius:"15px",
                  lineHeight:"14px"
                }}
              >
                {label1}
              </Card>
            )}
          </div>
        </Col>
        </Row>
        </Col>
        {props.from !== "preview" &&
          <Col md={6} >
          <div style={props.isAttended ? {pointerEvents:"auto"} : null }>
          <FormField
            style={{ marginBottom : "-25px"}}
            type={Textarea}
            name="comment"
            onChange={(e) => handleUserSelection(rating1, e.value)}
            value={comment}
            maxLength={180}
            // autoFocus
            placeholder="Why did you choose this option...?"
          />
          </div>
          <div className="test-comment-box-footer">
            <span className="footer-note">
              {`(maximum of 180 characters)`}
            </span>
          </div>
        </Col>}
      </Row>
      </div>
    </div>
  );
};
export default StarRating;
