import { Button, Checkbox, InputField, OverlayTooltip } from "components";
import FormField from "components/FormField";
import Pdf from "components/PlayerNew/Pdf";
import Video from "components/PlayerNew/Video";
import { withApiContext, withLabelsContext, withWindowContext } from "context";
import {
  IconAudioCircle,
  IconImageCircle,
  IconPdfCircle,
  IconPreviewEye,
  IconSaveNew,
  IconVideoCircle,
} from "Icons";
import JoditEditor from "jodit-react";
import VideoInput from "pages/ContentBuilder/VideoInput";
import { rgba } from "polished";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row, } from "react-bootstrap";
import { styled, useTheme } from "styled-components";
import { TestContext } from "./Context/TestContex";
import css from "./testCreation.module.scss";
import * as messages from "messages";

const BtnIconGrp = styled.div`
  margin: 5px;
  cursor: pointer;
  svg {
    fill: darkgrey;
  }

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => rgba(theme.primary, 0.5)};
    color: ${({ theme }) => rgba(theme.primary, 0.5)};

    svg {
      fill: ${({ theme }) => rgba(theme.primary, 0.5)};
    }
  }

  &:focus,
  &.focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primaryDarken};
    }
  }
`;
const ActiveBtnIcon = styled.div`
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};

    svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;

function SectionEdit(props) {
  const editor = useRef(null);
  const theme = useTheme();
  const { sectionData } = props;
  const { sections, setSections } = useContext(TestContext);
  const [sectionDetails, setSectionDetails] = useState({
    section_order: "",
    section_name: "",
    content_format: "",
    content: "",
    is_av: 0,
    content_source: "",
    description: "",
    file_name: "",
    is_limit: 0,
    max_questions: "",
    media_link: "",
    is_concluding_analysis: 0,
    analysis_description:"",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisable] = useState(false);
  const [previewDisable, setPreviewDisable] = useState(false);
  const [tooltipMessages, setTooltipMessages] = useState([]);
  const [inValidURL, setInValidURL] = useState(false);
  const configs = useMemo(
    () => ({
      height: 300,
      allowResizeY: false,
      hidePoweredByJodit: true,
      readonly: false,
      buttons: "bold,italic,eraser,fontsize,\n,brush,paragraph,left,copyformat",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      toolbarButtonSize: "small",
      maxHeight: 300,
      useSearch: false,
      removeButtons: [
        "source",
        "superscript",
        "subscript",
        "outdent",
        "indent",
        "image",
        "file",
        "video",
        "table",
        "link",
        "align",
        "undo",
        "redo",
        "cut",
        "copy",
        "paste",
        "copyformat",
        "selectall",
        "hr",
        "symbol",
        "fullsize",
        "print",
        "about",
        "preview",
        "find",
        "replace",
        "inserthorizontalrule",
      ],
    }),
    []
  );

  useEffect(() => {
    setSectionDetails({
      section_order: sectionData.section_order,
      section_name: sectionData.section_name,
      content_format:
        sectionData.content_format !== null ? sectionData.content_format : "",
      content: sectionData.content,
      is_av: sectionData.is_av,
      content_source: sectionData.content_source,
      description: sectionData.description,
      file_name: sectionData.file_name,
      is_limit: sectionData.is_limit,
      max_questions: sectionData.max_questions,
      media_link: sectionData.media_link,
      is_concluding_analysis: sectionData.is_concluding_analysis ?? 0,
      analysis_description:sectionData.analysis_description ?? "",
    });
  }, [props.sectionOrder]);

  useEffect(() => {
    const validate = () => {
      let messages = [];
      if (sectionDetails.section_name.trim() === "" ) messages.push("Section name is Required");
      if (inValidURL === true) messages.push('Invalid media URL');
      
      return messages;
    };
    const messages = validate();
    setIsSaveDisable(messages.length > 0);
    setTooltipMessages(messages);
  }, [sectionDetails]);

  useEffect(() => {
    if (
      sectionDetails.content !== "" &&
      sectionDetails.content !== null &&
      sectionDetails.content_format !== "" &&
      sectionDetails.content_format !== null &&
      sectionDetails.content_format !== undefined &&
      sectionDetails.content_source === "aws"
    ) {
      getMediaPlayable(sectionDetails.content_format, sectionDetails.content);
    }
  }, [sectionDetails.content]);
  const inlineBackground = {
    "--bg-color-1": theme.primary,
  };

  const section = sections.find(
    (section) => section.section_order === props.sectionOrder
  );

  const areAllPointsEqual = (section) => {
    if (!section || !section.questions || section.questions.length === 0) {
      return false;
    }
    const points = section.questions.map((question) =>
      parseFloat(question.points.trim())
    );
    return points.every((point) => point === points[0]);
  };

  const allPointsEqual = areAllPointsEqual(section);

  const getMediaPlayable = async (contentFormat, content) => {
    const contentType = 
      contentFormat === "0" ? "audio" :
      contentFormat === "1" ? "video" :
      contentFormat === "2" ? "pdf" : 
      "video";
  
    try {
      const {rsp} = await props.ctx_api.privateRequest({
        cmd: "get_module_url",
        args: {
          contentType,
          fileName: content,
        },
      });
      if(rsp.success === true){
        setSectionDetails((prev)=> {
          return{
            ...prev,
            media_link:rsp.url
          }
        })
      }
      return rsp;
    } catch (error) {
      console.error("Error fetching media URL:", error);
      throw error; // Propagate the error to be handled later if needed
    }
  };


  const handleSectionChange = ({ name, value }) => {
    if (name === "section_name") {
      setSectionDetails((prev) => {
        const section = { ...prev, [name]: value.target.value };
        return section;
      });
    } else {
      setSectionDetails((prev) => {
        const section = { ...prev, [name]: value };
        return section;
      });
    }
  };

  const handleMediaSelect = (data) => {
    const media =
      data === "audio" ? 0 : data === "video" ? 1 : data === "pdf" ? 2 : 3;
    setSectionDetails((prevState) => ({
      ...prevState,
      content_format: media.toString(),
    }));
  };

  const handleChecked = (e, data) => {
    if (e.target.checked === false) {
      setSectionDetails((prevState) => ({
        ...prevState,
        is_limit: data === "limit" ? 0 : sectionDetails.is_limit,
        is_av: data === "av" ? 0 : sectionDetails.is_av,
        is_concluding_analysis: data === "analysis" ? 0 : sectionDetails.is_concluding_analysis,
        content_format:"",
        content:null,
        content_source:null,
        media_link:null,
        file_name:null
      }));
    }
    if (e.target.checked === true && data === "limit") {
      setSectionDetails((prev) => ({
        ...prev,
        is_limit: 1,
      }));
    } else if (e.target.checked === true && data === "av") {
      setSectionDetails((prev) => ({
        ...prev,
        is_av: 1,
      }));
    }
    else if (e.target.checked === true && data === "analysis") {
      setSectionDetails((prev) => ({
        ...prev,
        is_concluding_analysis: 1,
      }));
    }
  };

  const handleSectionFile = (fileData) => {
    if (fileData.isInvalidUrl === true) {
      setInValidURL(true);
    } 
    else{
      setInValidURL(false);
    }
    // if (fileData.storageType === "aws") {
    //   setSectionDetails((prevState) => ({
    //     ...prevState,
    //     content: fileData.contentName,
    //     content_source: fileData.storageType,
    //     file_name: fileData.sourceName,
    //     media_link: fileData.contentUrl,
    //   }));
    // } else if (fileData.contentSource === "external") {
      if (sectionDetails.content_format === "3"){
        setSectionDetails((prevState) => ({
          ...prevState,
          content: fileData.url,
          content_source: fileData.contentSource,
          file_name: fileData.contentUrl,
          media_link: "",
        }));
      }
      else{
        setSectionDetails((prevState) => ({
          ...prevState,
          content: fileData.url,
          content_source: fileData.contentSource,
          file_name: fileData.contentUrl,
          media_link: fileData.contentUrl,
        }));
      }
      if (sectionDetails.content_format === "3"){
        setSectionDetails((prevState) => ({
          ...prevState,
          content: fileData.url,
          content_source: fileData.contentSource,
          file_name: fileData.contentUrl,
          media_link: "",
        }));
      }
      else{
        setSectionDetails((prevState) => ({
          ...prevState,
          content: fileData.url,
          content_source: fileData.contentSource,
          file_name: fileData.contentUrl,
          media_link: fileData.contentUrl,
        }));
      }
    // }
  };

  const handleCancel = () => {
    props.handleReset();
  };

  const handleLimit = (e) => {
    const limit = e.target.value;
    if (
      /^\d*$/.test(limit) &&
      (limit === "" || parseInt(limit) <= sectionData.questions.length)
    ) {
      setSectionDetails((prev) => {
        return {
          ...prev,
          max_questions: limit.toString(),
        };
      });
    }
  };

  const handleSave = () => {
    const isAv =
      sectionDetails.is_av === 1 &&
      sectionDetails.content_format !== "" &&
      sectionDetails.content !== ""
        ? 1
        : 0;
    const updatedSections = sections.map((section) => {
      if (section.section_order === props.sectionOrder) {
        return {
          ...section,
          section_name: sectionDetails.section_name,
          content_format: sectionDetails.content_format,
          content: sectionDetails.content,
          is_av: isAv,
          content_source: sectionDetails.content_source,
          description: sectionDetails.description,
          file_name: "",
          is_limit: sectionDetails.is_limit,
          max_questions: sectionDetails.max_questions,
          media_link: sectionDetails.file_name,
          is_concluding_analysis: sectionDetails.is_concluding_analysis ,
          analysis_description:sectionDetails.analysis_description,

        };
      }
      return section;
    });

    setSections(updatedSections);
    props.ctx_window.alert(
      messages.mkTestAddSuccess(
        props.ctx_labels.singular.section + " " + "updated"
      )
    );
    props.handleReset();
  };

  const contentFormat =
    sectionDetails.content_format === "0"
      ? "AUDIO"
      : sectionDetails.content_format === "1"
      ? "VIDEO"
      : sectionDetails.content_format === "2"
      ? "PDF"
      : "IMAGE";

    const secImage = () => {
      let secImg ;
      if(sectionDetails.media_link=== "" || sectionDetails.media_link=== null){
        secImg = sectionDetails.content;
      }
      else {
        secImg = sectionDetails.media_link;
      }
      return secImg
    }
  return (
    <>
      <Modal
        size="lg"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        centered
      >
        <ModalBody>
          {(sectionDetails.content_format === "0" ||
            sectionDetails.content_format === "1") && (
            <Video
              url={sectionDetails.media_link}
              // videoUrl={enteredUrl}
              onLoaded={() => {}}
              onError={() => {}}
              className={"video-player"}
            />
          )}
          {sectionDetails.content_format === "2" && (
            <Pdf
              pdfUrl={sectionDetails.media_link}
              onLoaded={() => {}}
              onError={() => {}}
              // fromCreateTest
            />
          )}
          {sectionDetails.content_format === "3" && (
            <img
              src={secImage()}
              style={{ width: "50rem" }}
            />
          )}
        </ModalBody>
      </Modal>
      <div className={css.sectionInputs}>
        <div>
          <FormField
            type={InputField}
            name="section_name"
            onChange={handleSectionChange}
            value={sectionDetails.section_name}
            placeholder={props.ctx_labels.singular.sectionCard}
            className={css.sectionName}
          />
        </div>
        <div>
          <FormField
            componentClass="textarea"
            className="input-full"
            type={JoditEditor}
            name="description"
            value={sectionDetails.description}
            config={configs}
            editorRef={editor}
            onChange={handleSectionChange}
            placeholder={props.ctx_labels.singular.sectionDescription}
          />
        </div>
        <Row
          style={{
            flexDirection: "column",
            gap: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col md={12}>
            <Checkbox
              checked={
                sectionDetails.is_concluding_analysis === 1 ? true : false
              }
              fromTest
              onChange={(e) => {
                handleChecked(e, "analysis");
              }}
            >
              {props.ctx_labels.singular.concludingAnalysis}
            </Checkbox>
          </Col>

          {sectionDetails.is_concluding_analysis === 1 && (
            <Col md={12}>
              <FormField
                componentClass="textarea"
                className="input-full"
                type={JoditEditor}
                name="analysis_description"
                value={sectionDetails.analysis_description}
                config={configs}
                editorRef={editor}
                onChange={handleSectionChange}
                placeholder={props.ctx_labels.singular.concludingDescription}
              />
            </Col>
          )}
        </Row>
        <div className={css.sectionLimit}>
          <div>
            {allPointsEqual ? (
              <Checkbox
                checked={sectionDetails.is_limit === 1 ? true : false}
                onChange={(e) => handleChecked(e, "limit")}
              >
                {props.ctx_labels.singular.limit}
              </Checkbox>
            ) : (
              <OverlayTooltip tip="Unequal mark in questions" placement="top">
                <Checkbox checked={false}> {props.ctx_labels.singular.limit}</Checkbox>
              </OverlayTooltip>
            )}
          </div>
          <div className={css.limitRow}>
            {props.ctx_labels.singular.attempt}
            <input
              className={css.sectLimitCount}
              style={inlineBackground}
              value={
                sectionDetails.is_limit === 0
                  ? sectionData.questions.length
                  : +sectionDetails.max_questions
              }
              onChange={handleLimit}
              disabled={sectionDetails.is_limit === 1 ? false : true}
            />
            of {sectionData.questions.length} questions
          </div>
        </div>
        <div
          className={css.sectionMedia}
          style={
            sectionDetails.content_format === ""
              ? { paddingBottom: "15px" }
              : {}
          }
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ paddingTop: "10px" }}>
              <Checkbox
                checked={sectionDetails.is_av === 1 ? true : false}
                onChange={(e) => handleChecked(e, "av")}
              >
                AV
              </Checkbox>
            </div>

            {sectionDetails.is_av === 1 && (
              <div className={css.btnIconGroup}>
                <BtnIconGrp onClick={() => handleMediaSelect("audio")}>
                  {sectionDetails.content_format === "0" ? (
                    <ActiveBtnIcon>
                      {" "}
                      <IconAudioCircle />{" "}
                    </ActiveBtnIcon>
                  ) : (
                    <IconAudioCircle />
                  )}
                </BtnIconGrp>
                <BtnIconGrp onClick={() => handleMediaSelect("video")}>
                  {sectionDetails.content_format === "1" ? (
                    <ActiveBtnIcon>
                      {" "}
                      <IconVideoCircle />{" "}
                    </ActiveBtnIcon>
                  ) : (
                    <IconVideoCircle />
                  )}
                </BtnIconGrp>
                <BtnIconGrp onClick={() => handleMediaSelect("pdf")}>
                  {sectionDetails.content_format === "2" ? (
                    <ActiveBtnIcon>
                      {" "}
                      <IconPdfCircle />{" "}
                    </ActiveBtnIcon>
                  ) : (
                    <IconPdfCircle />
                  )}
                </BtnIconGrp>
                <BtnIconGrp onClick={() => handleMediaSelect("image")}>
                  {sectionDetails.content_format === "3" ? (
                    <ActiveBtnIcon>
                      {" "}
                      <IconImageCircle />{" "}
                    </ActiveBtnIcon>
                  ) : (
                    <IconImageCircle />
                  )}
                </BtnIconGrp>
              </div>
            )}
          </div>

          <div style={{ paddingTop: "10px" }}>
            <Button
              className={css.previewButton}
              onClick={() => setIsModalOpen(!isModalOpen)}
              disabled={
                sectionDetails.content === "" ||
                sectionDetails.content === null ||
                previewDisable
              }
            >
              <IconPreviewEye />  {props.ctx_labels.singular.preview}.
            </Button>
          </div>
        </div>
        {sectionDetails.is_av === 1 &&
          sectionDetails.content_format &&
          sectionDetails.content_format !== "" && (
            <div style={{ paddingTop: "20px" }}>
              <VideoInput
                onChange={handleSectionFile}
                format={contentFormat}
                setPreviewDisable={setPreviewDisable}
                fromTestCreation
              />
            </div>
          )}
        <div className={css.questionButton}>
          <OverlayTooltip
            tip={
              <div>
                <ul style={{ textAlign: "left" }}>
                  {tooltipMessages.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            }
            placement="top"
            fromTestSec
            disable={!isSaveDisabled}
          >
            <Button
              className={css.rectangleButton}
              style={{
                backgroundColor: theme.primary,
                borderColor: theme.primary,
              }}
              disabled={isSaveDisabled}
              onClick={handleSave}
            >
              <IconSaveNew /> {props.ctx_labels.singular.save}
            </Button>
          </OverlayTooltip>
          <Button
            className={css.rectangleButton}
            variant="btn-outline-cancel"
            onClick={handleCancel}
          >
            {props.ctx_labels.singular.cancel}
          </Button>
        </div>
      </div>
    </>
  );
}

export default withLabelsContext(withWindowContext(withApiContext(SectionEdit)));
