import React, { Component } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import "./StarRating.css";
import StarRatings from "react-star-ratings";
import ProgressBar from 'react-bootstrap/ProgressBar';
import MediaQuery from 'react-responsive';
import styled from "styled-components";
import css from "./StarRating.css";


const isMobile = window.innerWidth <= 575;

const cardStyles = {
  width: isMobile ? '500px' : '1190px',
  height: '300px',
  position: 'relative',
  top: '70px',
  left: '90px',
  marginLeft: '-270px',
  marginTop: isMobile ? '500px' : '0px'
};

const cardStylesRsp = {
  width: '370px',
  marginTop: '0px',
  height: 'auto',
  marginLeft: '-15px',
  border: "none"
};


const divStyles = {
  width: isMobile ? '60px' : '300px',
  height: '400px',
  backgroundColor: 'white',
  marginTop: isMobile ? '-500px' : '0px'
};

const divStylesRsp = {
  width: isMobile ? '60px' : '300px',
  height: '400px',
  backgroundColor: 'white',
  marginTop: isMobile ? '-500px' : '0px'
};

const ResponseStyle = {
  display: "inline-block",
  marginLeft: "62px",
  color: "#D9E6EE",
  marginTop: "2px",
  marginBottom: "20px",
  fontSize: "15px",
};

const questionStyle = {
  color: "#3DAEDA",
  fontWeight: "700",
  fontSize: "15px",
  fontFamily: "Poppins",
  whiteSpace: "nowrap",
  marginRight: "10px",
};

const questionDataStyle = {
  color: "black",
  fontSize: "14px",
  fontFamily: "Poppins",
  display: "inline-block",
  maxWidth: "90%",
  fontWeight: "400"
};

const StyledWrappers = styled.h5`
  color: ${({ theme }) => theme.primary};
 
  }
`;

const StyledRatings = styled.h5`
  color: ${({ theme }) => theme.color2};
 
  }
`;

const StyledPublish = styled.div`
    && {
        .${css.surveyheader1} {
            color: ${({ theme }) => theme.primary};
        }
        .${css.surveyheader2}{
          color: ${({ theme }) => theme.color2};
        }
        .custom-progress-color .progress-bar {
          background-color: ${({ theme }) => theme.color1}; 
      }

        .custom-progress-color1 .progress-bar {
          background-color: ${({ theme }) => theme.color2}; 
      }

        .custom-progress-color2 .progress-bar {
          background-color: ${({ theme }) => theme.color3}; 
      }

      .custom-progress-color3 .progress-bar {
        background-color: ${({ theme }) => theme.color4}; 
    }

    .custom-progress-color4 .progress-bar {
      background-color: ${({ theme }) => theme.color4}; 
  }
    }
`;


class RatingAndReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 4.5,
      reviews: [],
      newReview: '',
      threeStar: false
    };
 
  }

  render() {
    
    const { rating, reviews, newReview } = this.state;
    const { surveyData } = this.props;
    const currentQuestion = surveyData;
    const currentQuestionId = currentQuestion
      ? currentQuestion.survey_question_id
      : null;
    const rating1 = 4.5
  //   let threeStarSet = false;
  //  surveyData.answers[0].starlabel4.forEach((itm, i) => {
  //   console.log("456",itm.points)
  //   //itm.points===null? this.setState({ threeStar: true }) : ""
  //     if (itm.points === null) {
  //       this.setState({ threeStar: true });
  //     }
  //   });


    return (
      <>
        <MediaQuery maxWidth={575}>
          <div >
            <Card style={cardStylesRsp}>
              <React.Fragment>
                <div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5
                      style={{
                        ...questionStyle,
                        marginLeft: "-10px",
                        marginBottom: "8px",
                        marginTop: "0px",
                        paddingLeft: "10px",
                        border: "none",
                        flex: "0 0 auto", // This ensures the first element stays on the same line
                      }}
                    >
                      <StyledWrappers>
                        QN{" "}{currentQuestionId}
                      </StyledWrappers>
                    </h5>
                    <h5
                      style={{
                        ...questionDataStyle,
                        maxWidth: "82%",
                        marginLeft: "7px",
                        flex: "1", // This allows the second element to take up remaining space
                      }}
                    >
                      {surveyData && surveyData.survey_question_name}
                    </h5>
                  </div>

                  <Col>
                  {surveyData.answers[0].starlabel4=== null ? "" :surveyData.answers[0].starlabel4.map((itm, i)=>(
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: "5px", marginTop: "0px" }}>
                      <h5 style={{ fontWeight: 'bold', fontSize: '25px', marginRight: '10px' }}>
                        {surveyData.answers[0].avg_star_rating === null ? "" : surveyData.answers[0].avg_star_rating.toFixed(1)}
                      </h5>
                      <StyledRatings>
                        <div className="star-ratings-container" style={{ marginTop: "15px" }}>
                          <StarRatings
                            rating={surveyData.answers[0].avg_star_rating=== null ? 0 : surveyData.answers[0].avg_star_rating}
                            starDimension="12px"
                            starSpacing="2px"
                            numberOfStars={itm.points === null ? 3 : 5}
                          />
                        </div>
                      </StyledRatings>
                      <h5
                        style={{
                          ...ResponseStyle,
                          fontSize: "11px",
                          marginTop: "20px",
                          marginLeft: "220px",
                          color: "grey",
                          position: "absolute"
                        }}
                      >
                        {surveyData &&
                          surveyData.answers[0] &&
                          surveyData.answers[0].answers === null ? "" : surveyData.answers[0].answers[0].response_count}{" "}
                        Responses
                      </h5>
                    </div>
                  ))}
                  </Col>


                  <Col className="column-starRsp">
                  {surveyData.answers[0].starlabel1== null ? "" : surveyData.answers[0].starlabel1.map((itm, i)=>(
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      } 
                      />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                     ))}

                    {surveyData.answers[0].starlabel2== null ? "" : surveyData.answers[0].starlabel2.map((itm, i)=>(
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>
                        {/* <span className="very">Very</span>
                        <span className="good">Good</span> */}
                        {itm.label}
                      </div>
                      <ProgressBar now={itm.points} 
                      //className="custom-progressRsp excellent-progress"
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                       />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                     ))}

                    {surveyData.answers[0].starlabel3== null ? "" : surveyData.answers[0].starlabel3.map((itm, i)=>(
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                       />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                     ))}

                    {surveyData.answers[0].starlabel4== null ? "" : surveyData.answers[0].starlabel4.map((itm, i)=>(itm.points!==null?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>
                        {/* <span className="not">Not</span>
                        <span className="bad">Bad</span> */}
                        {itm.label}
                      </div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                       />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    :""
                    ))}

                    {surveyData.answers[0].starlabel5== null ? "" : surveyData.answers[0].starlabel5.map((itm, i)=>(itm.points!==null?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -20, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                      />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    :""
                     ))}
                  </Col>

                </div>
              </React.Fragment>
            </Card>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={576} maxWidth={1074}>
          <div >
            <Card style={cardStylesRsp}>
              <React.Fragment>
                <div>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5
                      style={{
                        ...questionStyle,
                        marginLeft: "5px",
                        marginBottom: "8px",
                        marginTop: "15px",
                        paddingLeft: "10px",
                        border: "none",
                        flex: "0 0 auto", // This ensures the first element stays on the same line
                      }}
                    >
                      <StyledWrappers>
                        QN{" "}{currentQuestionId}
                      </StyledWrappers>
                    </h5>
                    <div
                      style={{
                        ...questionDataStyle,
                        marginLeft: "15px",
                        flex: "1", // This allows the second element to take up remaining space
                        marginTop: "15px",
                        width: "71%"
                      }}
                    >
                      {surveyData && surveyData.survey_question_name}
                    </div>
                  </div>

                  <Col>
                  {surveyData.answers[0].starlabel4=== null ? "" :surveyData.answers[0].starlabel4.map((itm, i)=>(
                    <StyledRatings>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: "5px", marginTop: "0px" }}>
                        <h5 style={{ fontWeight: 'bold', fontSize: '25px', marginRight: '10px' }}>
                          {surveyData.answers[0].avg_star_rating === null ? "" : surveyData.answers[0].avg_star_rating.toFixed(1)}
                        </h5>
                        <div className="star-ratings-container" style={{ marginTop: "12px" }}>
                          <StarRatings
                            rating={surveyData.answers[0].avg_star_rating === null ? 0 : surveyData.answers[0].avg_star_rating}
                            
                            starDimension="12px"
                            starSpacing="2px"
                            numberOfStars={itm.points === null ? 3 : 5}
                          />
                        </div>
                        <h5
                          style={{
                            ...ResponseStyle,
                            fontSize: "11px",
                            marginTop: "20px",
                            marginLeft: "250px",
                            position:"absolute"
                          }}
                        >
                          {surveyData &&
                            surveyData.answers[0] &&
                            surveyData.answers[0].answers === null ? "" : surveyData.answers[0].answers[0].response_count}{" "}
                          Responses
                        </h5>
                      </div>
                    </StyledRatings>
                     ))}
                  </Col>


                  <Col className="column-starRsp">
                  {surveyData.answers[0].starlabel1== null ? "" : surveyData.answers[0].starlabel1.map((itm, i)=>(
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', position: 'relative', marginLeft: "-75px" }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                      //className="custom-progressRsp excellent-progress" 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                      />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    ))}

                    {surveyData.answers[0].starlabel2== null ? "" : surveyData.answers[0].starlabel2.map((itm, i)=>(
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>
                        {/* <span className="very">Very</span>
                        <span className="good">Good</span> */}
                        {itm.label}
                      </div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      } 
                      />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    ))}

                    {surveyData.answers[0].starlabel3== null ? "" : surveyData.answers[0].starlabel3.map((itm, i)=>(
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                      className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                       />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                     ))}

                    {surveyData.answers[0].starlabel4== null ? "" : surveyData.answers[0].starlabel4.map((itm, i)=>(itm.points!==null?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -16, left: 0 }}>
                        {itm.label}
                        {/* <span className="not">Not</span>
                        <span className="bad">Bad</span> */}
                      </div>
                      <ProgressBar now={itm.points}
                       className={
                        itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                       />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    :""
                     ))}

                    {surveyData.answers[0].starlabel5== null ? "" : surveyData.answers[0].starlabel5.map((itm, i)=>(itm.points!==null?
                    <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '25px 0', marginLeft: '-75px', position: 'relative' }}>
                      <div className="progress-labelRsp" style={{ position: 'absolute', top: -20, left: 0 }}>{itm.label}</div>
                      <ProgressBar now={itm.points} 
                     className={
                      itm.points >= 75
                          ? 'custom-progressRsp custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progressRsp custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progressRsp custom-progress-color2'
                              : 'custom-progressRsp custom-progress-color3'
                      }
                      />
                      <div className="progress-valueRsp" style={{ position: 'absolute', top: -5, right: 45 }}>{itm.points.toFixed(0)}%<div style={{ marginTop: "-14px", marginRight: "-16px", fontSize: "10px" }}>({itm.count})</div></div>
                    </div>
                    </StyledPublish>
                    :""
                    ))}
                  </Col>

                </div>
              </React.Fragment>
            </Card>
          </div>
        </MediaQuery>
        {/* {surveyData.answers[0].starlabel1.map((itm, i)=>(
          <div> { console.log("surveyData11",itm.label)}</div>
        ))} */}
        <MediaQuery minWidth={1073}>
          {/* <div style={divStyles}> */}
          {/* <Card style={cardStyles}> */}
          <React.Fragment>
            <div>
            <div style={{ display: "flex", alignItems: "flex-start",
            //minWidth: "max-content" 
            width:"224%"
            }}>
              <h5
                style={{
                  ...questionStyle,
                  fontSize: "15px",
                  marginBottom: "0px",
                  marginTop: "13px",
                  paddingLeft: "10px",
                  flexShrink: 0, // Ensure that this element doesn't shrink
                }}
              >
                <StyledWrappers>
                  QN {currentQuestionId}
                </StyledWrappers>
              </h5>
              <h5
                style={{
                  ...questionDataStyle,
                  fontSize: "14px",
                  marginLeft: "10px",
                  marginTop: "14px",
                  flex: 1, // Allow this element to grow and take up remaining space
                }}
              >
                {surveyData && surveyData.survey_question_name}
              </h5>
            </div>

            <div >
              <Row>
                <Col>
                {surveyData.answers[0].starlabel4=== null ? "" :surveyData.answers[0].starlabel4.map((itm, i)=>(
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: "65px", marginTop: "90px" }}>
                    <h5 style={{ fontWeight: 'bold', fontSize: '30px', marginRight: '10px' }}>
                      {surveyData.answers[0].avg_star_rating === null ? "" : surveyData.answers[0].avg_star_rating.toFixed(1)}
                    </h5>
                    <StyledRatings>
                      <div className="star-ratings-container">
                        <StarRatings
                          rating={surveyData.answers[0].avg_star_rating === null ? 0 : surveyData.answers[0].avg_star_rating}
                          starDimension="12px"
                          starSpacing="2px"
                          numberOfStars={itm.points === null ? 3 : 5}
                        />
                      </div>
                    </StyledRatings>
                    <h5
                      style={{
                        ...ResponseStyle,
                        fontSize: "11px",
                        marginTop: "25px",
                        marginLeft: itm.points === null ? "49px" : "56px",
                        color: "grey", 
                        position: "absolute"
                      }}
                    >
                      {surveyData &&
                        surveyData.answers[0] &&
                        surveyData.answers[0].answers === null ? "" : surveyData.answers[0].answers[0].response_count}{" "}
                      Responses
                    </h5>

                  </div>
                ))}

                </Col>
                {surveyData.answers[0].starlabel4 === null ? "" : surveyData.answers[0].starlabel4.map((itm, i)=>(
                <Col 
                //className="column-star"
                className={itm.points===null ? "column-star2" : "column-star"}
                >
                {surveyData.answers[0].starlabel1 === null ? "" : surveyData.answers[0].starlabel1.map((itm, i)=>(
                  <div className="progress-wrapper" style={{ textAlign: 'left', marginLeft: "-70px", }}>
                    <div className="progress-label" style={{minWidth: '99px', maxHeight:'10px'}}>{itm.label}</div>
                    <StyledPublish>
                      <div style={{ display: 'flex', alignItems: 'center',
                      //width:"100%"  
                      }}>
                        <ProgressBar
                          now={itm.points}
                          className={
                            itm.points >= 75
                              ? 'custom-progress custom-progress-color'
                              : itm.points >= 50
                                ? 'custom-progress custom-progress-color1'
                                : itm.points >= 25
                                  ? 'custom-progress custom-progress-color2'
                                  : 'custom-progress custom-progress-color3'
                          }
                          //style={{ marginLeft: '12px' }}
                        />
                        <div className="progress-value">{itm.points.toFixed(0)}% <div style={{  marginTop: "-17px", marginRight: "-20px", fontSize: "11px" }}>({itm.count})</div></div>
                      </div>
                    </StyledPublish>
                  </div>
                  ))}

               {surveyData.answers[0].starlabel2=== null ? "" : surveyData.answers[0].starlabel2.map((itm, i)=>(
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '27px 0', marginLeft: '-70px', }}>
                      <div className="progress-label"style={{ minWidth: '99px',maxHeight:'10px'  }}>
                        {itm.label}
                        {/* <span className="very">Very</span>
                        <span className="good">Good</span> */}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', 
                      //width:"100%" 
                      }}>
                        <ProgressBar now={itm.points} 
                       className={
                        itm.points >= 75
                          ? 'custom-progress custom-progress-color'
                          : itm.points >= 50
                            ? 'custom-progress custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progress custom-progress-color2'
                              : 'custom-progress custom-progress-color3'
                      }
                        />
                        <div className="progress-value">{itm.points.toFixed(0)}%<div style={{ marginTop: "-17px", marginRight: "-20px", fontSize: "11px", }}>({itm.count})</div></div>
                      </div>
                    </div>
                  </StyledPublish>
                   ))}

                 {surveyData.answers[0].starlabel3=== null ? "" :surveyData.answers[0].starlabel3.map((itm, i)=>(
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '27px 0', marginLeft: '-70px', }}>
                      <div className="progress-label"style={{ minWidth: '99px',maxHeight:'10px' }}>
                      {itm.label}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center',
                      //width:"100%"  
                      }}>
                        <ProgressBar now={itm.points} 
                        className={
                          itm.points >= 75
                            ? 'custom-progress custom-progress-color'
                            : itm.points >= 50
                              ? 'custom-progress custom-progress-color1'
                              : itm.points >= 25
                                ? 'custom-progress custom-progress-color2'
                                : 'custom-progress custom-progress-color3'
                        }
                        //style={{ marginLeft: '33px' }} 
                        />
                        <div className="progress-value">{itm.points.toFixed(0)}%<div style={{ marginTop: "-17px", marginRight: "-20px", fontSize: "11px" }}>({itm.count})</div></div>
                      </div>
                    </div>
                  </StyledPublish>
                  ))}

                {surveyData.answers[0].starlabel4 === null ? "" : surveyData.answers[0].starlabel4.map((itm, i)=>(itm.points!==null?
                  <StyledPublish>
                    <div className="progress-wrapper" style={{ textAlign: 'left', margin: '27px 0', marginLeft: '-70px',  }}>
                      <div className="progress-label" style={{ minWidth: '99px',maxHeight:'10px' }}>
                        {itm.label}
                        {/* <span className="not">Not</span>
                        <span className="bad">Bad</span> */}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center',
                      //width:"100%"  
                      }}>
                        <ProgressBar now={itm.points} 
                        className={
                          itm.points >= 75
                            ? 'custom-progress custom-progress-color'
                            : itm.points >= 50
                              ? 'custom-progress custom-progress-color1'
                              : itm.points >= 25
                                ? 'custom-progress custom-progress-color2'
                                : 'custom-progress custom-progress-color3'
                        }
                         //style={{ marginLeft: '16px' }} 
                         />
                        <div className="progress-value">{itm.points.toFixed(0)}%<div style={{ marginTop: "-17px", marginRight: "-20px", fontSize: "11px" }}>({itm.count})</div></div>
                      </div>
                    </div>
                  </StyledPublish>
                  : ""))}

              {surveyData.answers[0].starlabel5== null ? "" : surveyData.answers[0].starlabel5.map((itm, i)=>(itm.points!==null?
                  <StyledPublish>
                    <div 
                    className="progress-wrapper" style={{ textAlign: 'left', margin: '27px 0', marginLeft: '-70px',  }}>
                      <div className="progress-label" style={{ minWidth: '99px',maxHeight:'10px'  }}>
                        {itm.label}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center',
                      //width:"100%"
                        }}>
                        <ProgressBar now={itm.points} 
                       className={
                        itm.points >= 75
                          ? 'custom-progress custom-progress-color'
                          : itm.pointst >= 50
                            ? 'custom-progress custom-progress-color1'
                            : itm.points >= 25
                              ? 'custom-progress custom-progress-color2'
                              : 'custom-progress custom-progress-color3'
                      }
                        //style={{ marginLeft: '32px' }} 
                        />
                        <div className="progress-value">{itm.points.toFixed(0)}%<div style={{ marginTop: "-17px", marginRight: "-20px", fontSize: "11px" }}>({itm.count})</div></div>
                      </div>
                    </div>
                  </StyledPublish>
                 : "" ))}
                </Col>
                ))}
              </Row>
              {/* Other content */}
            </div>
            </div>
          </React.Fragment>
          {/* </Card> */}
          {/* </div> */}
        </MediaQuery>
      </>
    );
  }
}

export default RatingAndReviews;