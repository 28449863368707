import AsyncSelect from './AsyncSelect';
import { withApiContext, withLabelsContext } from 'context';
import React, { PureComponent } from 'react';

class CreatedBySelect extends PureComponent {
  fetch = async () => {
    let arg=this.props.isCP ? {isCP: 1} : {}
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: 'get_creator',
      args: arg,
    });

    return rsp.map(({ member_id, member_name }) => ({
      label: member_name,
      value: member_id,
    }));
  }

  render = () => (
    <AsyncSelect
      {...this.props}
      optionName={this.props.ctx_labels.singular.createdBy}
      fetch={this.fetch}
      unselectedValue=""
    />
  );
}


export default
  withApiContext(
    withLabelsContext(
      CreatedBySelect
    )
  )
