import * as config from "config";
import {
  withAnalyticsContext,
  withApiContext,
  withLabelsContext,
  withWindowContext,
  withUserContext,
} from "context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
// import Chat from "scenes/components/Chat";
// import Notes from "components/Notes/Notes";
import * as util from "services/util";
// import TestForm from '../MemberHome/StudentTest/Test';
// import TestForm from "../components/Player/PlayerTest/Test";
import "./Player.css";
import BaseControls from "./Player/BaseControls";
import Html from "./Player/Html";
import Embedded from "./Player/Embedded";
// import { Spin as SpinIcon } from "./Player/icons/Spin";
import Pdf from "./Player/Pdf";
import Scorm from "./Player/Scorm";
import Test from "./Player/Test";
import PreviewTest from "./Player/PreviewTest";
import StudentAssessments from "./Player/StudentAssessment"
import TestQuestion from "./Player/TestQuestion";
// import css from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import Loader from "components/Spinner/Loader";
import Video from "./Player/Video";
import LoomPlayer from "./LoomPlayer/LoomPlayer";
import PPT from "./Player/Ppt"
import { Link } from "react-router-dom";
import "./Player.css";
import styled from "styled-components";
import { rgba } from "polished";
// import StudentAssessment from "./Player/StudentAssessment";
import classNames from "classnames";
import HTMLEditor from "./ModuleInput/HTMLEditor";
import { withRouter } from "react-router";

const StyledPlayerMessage = styled.div`

  && {
    
    background: linear-gradient( 
      40deg,
      ${({ theme }) => theme.color2} 2%,
      ${({ theme }) => theme.color4} 84%
    );
    height: 100%
  }
`;
const StyledPlayerMessages = styled.div`

  && {

    background: #000

  }

`;

class Player extends PureComponent {
  constructor(props) {
    super(props);
    this.fullscreenEnabled =
      document.fullscreenEnabled ||
      document.msFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled;

    const moduleState = this.getNewModuleState(props);
    this.state = {
      ...moduleState,
      playerState: "playing",
      isFullscreen: this.isFullscreenNow(),
      multiplePanesSupported: this.multiplePanesSupportedNow(),
      primaryPane: "module",
      openedAuxPanes: [],
      unreadChatMessageCount: 0,
      moduleWidth: null,
      noTransitionDelay: false,
      extPlayConfirm: false,
      scormState: "",
      pdfLoaded: false,
      icqSubmit: false,
      htmlContent: false,
      isIphone: this.isIphone(),
      isLoom:false
    };
  }

  static propTypes = {
    memberId: PropTypes.string.isRequired,
    curriculum: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      trainerId: PropTypes.string,
    }).isRequired,
    command: PropTypes.oneOf(["play", "resume", "restart", "conclude"]),
    module: PropTypes.shape({
      type: PropTypes.string.isRequired,
      contentId: PropTypes.string.isRequired,
      contentLength: PropTypes.number.isRequired,
      lastViewedPos: PropTypes.number.isRequired,
      maxViewedPos: PropTypes.number.isRequired,
      nextTestQuestionId: PropTypes.string,
      lastAnsweredTestQuestionId: PropTypes.string,
      testQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          pos: PropTypes.number.isRequired,
        })
      ).isRequired,
    }),
    creditsEarned: PropTypes.string,
    onReady: PropTypes.func,
    onStateChange: PropTypes.func,
    onModuleProgress: PropTypes.func,
    onUnmounted: PropTypes.func,
    isFullscreenDefault: PropTypes.objectOf(PropTypes.bool),
  };

  static defaultProps = {
    isFullscreenDefault: {},
  };

  componentDidMount = () => {
    this.initFullscreenMode();
    if (this.props.onStateChange) {
      this.props.onStateChange(this.state.playerState);
    }
    if (this.props.onReady) {
      this.props.onReady();
    }
    this.addDocumentResizeListener();
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.extPlayConfirm !== this.props.extPlayConfirm && !this.state.htmlContent) {
      this.setState({ extPlayConfirm: newProps.extPlayConfirm });
    }
    if (newProps.command !== this.props.command) {
      this.setState((s0) => {
        // if (newProps.command === 'dismiss') {
        //   const playerState =
        //     newProps.module.type === 'scorm' ? 'dismissing' : 'dismissed';
        //   return {
        //     playerState,
        //     preDismissPlayerState: s0.playerState,
        //   };
        // }
        if (s0.playerState === "advancing") {
          switch (newProps.command) {
            case "play":
            case "resume":
            case "restart":
              return { sectionDone: true, playerState: "ready" };
            case "conclude":
              return { sectionDone: true, playerState: "concluding" };
            default:
              break;
          }
        }
        return {};
      });
    }
  };

  componentDidUpdate = (_prevProps, prevState) => {
    const { playerState, module } = this.state;
    if (prevState.module.contentId !== this.state.module.contentId) {
      // this.setStudyMap(this.state.module);
      if (module.type === "html") {
        if (module.playExt) {
          this.props.ctx_window.confirm({
            message:
              "This action will open the requested page in a new tab. Would you like to continue?",
            yesText: "Proceed",
            noText: "Cancel",
            callback: this.handleNewTab(module),
          });
        }
        this.setState({ htmlContent: true })
      }
      if (prevState.module.type === module.type) {
        this.setState({ pdfLoaded: false })
      }
    }
    if (playerState !== prevState.playerState) {
      // We report the progress before the 'playing' -> 'advancing' transition,
      // as we only send progress updates during the 'playing' state
      if (playerState === "advancing") {
        this.reportModuleProgress();
      }
      if (this.props.onStateChange) {

        this.props.onStateChange(playerState);
      }
    }
  };

  componentWillUnmount = () => {
    util.removeFullscreenChangeHandler(this.handleFullscreenChange);
    this.removeDocumentResizeListener();
  };

  addDocumentResizeListener = () => {
    window.addEventListener("resize", this.handleDocumentResize);
  };

  removeDocumentResizeListener = () => {
    window.removeEventListener("resize", this.handleDocumentResize);
  };

  handleNewTab = (m) => (ok) => {
    this.props.onExtPlayConfirm(ok);
    if (ok) {
      var win = window.open(m.fileUrl ? m.fileUrl : config.ileUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
      }
    }
  };

  getNewModuleState = (props = this.props) => {
    this.props.fromCourse
      ? this.getMasterTestAnswers(props)
      : this.getTestAnswers(props);

    const m = props.module;
    const lastViewedPos = props.command === "restart" ? 0 : m.lastViewedPos;
    const testQuestions = [];
    if (m.nextTestQuestionId !== null) {
      let i = 0;
      const n = m.testQuestions.length;
      while (i < n && m.testQuestions[i].id !== m.nextTestQuestionId) {
        i++;
      }
      while (i < n && m.testQuestions[i].pos === lastViewedPos) {
        testQuestions.push(m.testQuestions[i]);
        i++;
      }
    }

    let minPos;
    let maxPos;
    let sectionDone;
    if (0 < testQuestions.length) {
      minPos = testQuestions[0].pos;
      maxPos = minPos;
      sectionDone = false;
    }
    else if (m.type === "test" && m.moduleProgress >= 100) {
      sectionDone = true
    }
    else {
      minPos = 0;
      maxPos = this.findSectionMaxPos(m, lastViewedPos);
      sectionDone = m.type == "test" ? m.completed : maxPos <= m.maxViewedPos;
    }

    return {
      lastViewedPos,
      minPos,
      maxPos,
      sectionDone,
      testQuestions,
      lastAnsweredTestQuestionId: m.lastAnsweredTestQuestionId,
      testAnswers: {},
      maxViewedPos: m.maxViewedPos,
      reviewing: false,
      module: m,
    };
  };

  getVisibleAuxPanes = (s = this.state) =>
    this.primaryPaneOnly(s) ? [] : s.openedAuxPanes;

  getVisiblePanes = () =>
    [this.state.primaryPane].concat(this.getVisibleAuxPanes());

  findSectionMaxPos = (m, startPos) => {
    let nextQuestionPos = null;
    m.testQuestions.forEach((q) => {
      if (nextQuestionPos === null && startPos < q.pos) {
        nextQuestionPos = q.pos;
      }
    });
    return nextQuestionPos !== null ? nextQuestionPos : m.contentLength;
  };
  getTestAnswers = (props) => {
    if (0 < props.module.testQuestions.length) {
      const videoUnitId = props.module.contentId;
      const coursePublishId = props.coursePublishId;
      const courseId = props.courseId;
      props.ctx_api
        .privateRequest({
          cmd: "get_test_answer",
          args: { videoUnitId: videoUnitId, coursePublishId: coursePublishId, courseId: courseId ? courseId.toString() : this.props.subjectData.courseId, },
          //args: { videoUnitId: videoUnitId, coursePublishId: coursePublishId, courseId: courseId.toString()},
        })
        .then(this.handleGetTestAnswersResponse(props))
        .catch(this.handlePlayerSystemFailure);
    }
  };
  getMasterTestAnswers = (props) => {
    if (0 < props.module.testQuestions.length) {
      const videoUnitId = props.module.contentId;
      props.ctx_api
        .privateRequest({
          cmd: "get_master_test_answer",
          args: { videoUnitId: videoUnitId },
        })
        .then(this.handleGetTestAnswersResponse(props))
        .catch(this.handlePlayerSystemFailure);
    }
  };

  handleGetTestAnswersResponse = (props) => ({ rsp }) => {
    const testQuestions = {};
    const testAnswers = {};

    props.module.testQuestions.forEach((q) => {
      testQuestions[q.id] = q;
      testAnswers[q.id] = {
        choices: [],
        answers: [],
        comments: [],
        reviewStarts: [],
        reviewEnds: [],
      };
    });

    rsp.forEach((r) => {
      const q = testQuestions[r.test_que_id];
      if (q) {
        const a = testAnswers[r.test_que_id];
        const typeE = config.testQuestionTypeE;
        if (
          q.type === typeE.MULTIPLE_CHOICE ||
          q.type === typeE.SURVEY_MULTIPLE_CHOICE
        ) {
          a.choices.push({ answerText: r.test_answer, answerImage: r.image });
        }
        if (
          q.type === typeE.FILL_IN_THE_BLANK ||
          (q.type === typeE.MULTIPLE_CHOICE && r.is_correct_ans)
        ) {
          a.answers.push(r.test_answer);
        }
        a.comments.push(r.answer_comment);
        a.reviewStarts.push(r.review_start_pos);
        a.reviewEnds.push(r.review_end_pos);
      }
    });

    this.setState({ testAnswers });
  };

  handlePlayerSystemFailure = () => {
    this.setState({ playerState: "error" });
  };

  reportModuleProgress = (positions = {}, isEnd = false) => {
    if (
      !this.props.onModuleProgress ||
      (positions.unmount && this.state.sectionDone)
    ) {
      return;
    }
    const cleanPos = (key) => {
      positions[key] = Math.min(
        positions[key] == null ? this.state[key] : positions[key],
        this.state.module.contentLength
      );
    };
    cleanPos("lastViewedPos");
    cleanPos("maxViewedPos");
    const qs = this.state.testQuestions;
    if (0 < qs.length) {
      positions.lastViewedPos = qs[0].pos;
      if (!this.state.reviewing && !this.state.sectionDone) {
        positions.nextTestQuestionId = qs[0].id;
      } else if (1 < qs.length) {
        positions.nextTestQuestionId = qs[1].id;
      }
    }
    const lastQuestionId = this.state?.lastAnsweredTestQuestionId;
    if (lastQuestionId !== null && lastQuestionId !== undefined) {
      positions.lastAnsweredTestQuestionId = lastQuestionId.toString();
    }
    this.props.onModuleProgress(positions, isEnd);
  };

  handleModuleProgress = (positions, isEnd = false) => {
    if (this.state.playerState === "playing") {
      this.reportModuleProgress(positions, isEnd);
    }
  };

  isFullscreenNow = () =>
    !!(
      document.fullscreenElement ||
      document.msFullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    );

  initFullscreenMode = () => {
    util.addFullscreenChangeHandler(this.handleFullscreenChange);
    /* Broken on iOS
    const t = this.state.module.type;
    const isFullscreenDefault =
      this.fullscreenEnabled && (this.props.isFullscreenDefault[t] || false);
    if (this.isFullscreenNow() !== isFullscreenDefault) {
      this.toggleFullscreen('module')();
    }
    */
  };

  multiplePanesSupportedNow = () =>
    config.minMultiPanePlayerWidth <= window.innerWidth;

  primaryPaneOnly = (s = this.state) =>
    s.isFullscreen || !s.multiplePanesSupported;

  handleDocumentResize = () => {
    const multiplePanesSupported = this.multiplePanesSupportedNow();
    if (multiplePanesSupported !== this.state.multiplePanesSupported) {
      this.setState((s0) => {
        const deltaS = { multiplePanesSupported };
        if (!s0.isFullscreen) {
          deltaS.primaryPane = "module";
        }
        return deltaS;
      });
    }
  };

  toggleFullscreen = (pane) => () => {
    const elt = ReactDOM.findDOMNode(this);
    if (!elt) {
      return;
    }
    if (this.isFullscreenNow()) {
      this.setState((s0) =>
        s0.multiplePanesSupported ? { primaryPane: "module" } : null
      );
      document.exitFullscreen =
        document.exitFullscreen ||
        document.msExitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen;
      document.exitFullscreen();
    } else {
      this.setState({ primaryPane: pane });
      elt.requestFullScreen =
        elt.requestFullscreen ||
        elt.msRequestFullscreen ||
        elt.mozRequestFullScreen ||
        elt.webkitRequestFullscreen;
      elt.requestFullScreen();
    }
  };

  handleFullscreenChange = () => {
    this.setState((s0) => ({
      isFullscreen: !s0.isFullscreen,
      noTransitionDelay: true,
    }));
  };

  handleModuleWidthChange = (width) => {
    this.setState({ moduleWidth: width });
  };

  handlePaneToggle = (pane) => {
    this.setState((s0) => {
      if (this.primaryPaneOnly(s0)) {
        return { primaryPane: pane };
      }
      const openedAuxPanes = [...s0.openedAuxPanes];
      const idx = openedAuxPanes.indexOf(pane);
      if (idx === -1) {
        openedAuxPanes.push(pane);
      } else {
        openedAuxPanes.splice(idx, 1);
      }
      return {
        openedAuxPanes,
        noTransitionDelay: false,
      };
    });
  };

  handleUnreadChatMessageChange = (count) => {
    this.setState({ unreadChatMessageCount: count });
  };

  handleScormClose = (scormState) => {
    this.setState({ scormState: scormState });
  };

  handlePdfLoaded = (data) => {
    this.setState({ pdfLoaded: data });
  };

  handleSectionDone = (continueToNext = false) => {
    if (continueToNext) {

      this.handleContinue();
    }

    else {
      this.setState({ sectionDone: true });
    }
  };

  handleIcq = () => {
    this.setState({ icqSubmit: false })
  }

  handleContinue = () => {
    this.setState((s0) => {
      const m = this.state.module;
      let deltaS = {};

      if (s0.playerState === "concluding") {
        // Exit the player, as there are no more modules to play
        deltaS = { playerState: "concluded" };
        this.props.viewType === "card" ? (
          this.props.curriculumList()
        ) : (
          <Link to="/" />
        );

      } else if (s0.playerState === "ready") {
        // Play the next module
        const moduleState = this.getNewModuleState();
        deltaS = {
          playerState: "playing",
          ...moduleState,
        };
      } else if (s0.reviewing) {
        // Return to the current test question
        const maxPos = s0.testQuestions[0].pos;
        deltaS = {
          maxPos,
          minPos: maxPos,
          lastViewedPos: maxPos,
          reviewing: false,
          sectionDone: true,
        };
      } else if (1 < s0.testQuestions.length) {
        // Advance to the next test question
        const qs = [...s0.testQuestions];
        qs.shift();
        deltaS = {
          testQuestions: qs,
          sectionDone: false,
        };
      } else if (s0.testQuestions.length === 1) {
        if (s0.maxPos < m.contentLength) {
          // Advance to the next section
          const maxPos = this.findSectionMaxPos(m, s0.maxPos);
          deltaS = {
            maxPos,
            minPos: 0,
            testQuestions: [],
            sectionDone: maxPos <= m.maxViewedPos,
          };
        } else {
          // Advance to the next module
          deltaS = {
            testQuestions: [],
            sectionDone: false,
            playerState: "advancing",
          };
        }
      } else {
        // If it exists, advance to the first test question for the section;
        // otherwise, go to the next module
        const testQuestions = [];
        m.testQuestions.forEach((q) => {
          if (q.pos === s0.maxPos) {
            testQuestions.push(q);
          }
        });
        let minPos;
        let playerState;
        if (0 < testQuestions.length) {
          playerState = s0.playerState;
          minPos = testQuestions[0].pos;
        } else {
          playerState = "advancing";
          minPos = m.contentLength;
        }
        deltaS = {
          playerState,
          testQuestions,
          minPos,
          lastViewedPos: minPos,
          maxViewedPos: Math.max(minPos, s0.maxViewedPos),
          sectionDone: false,
        };
      }

      return deltaS;
    });
  };

  handleReview = (minPos, maxPos) => () => {
    this.setState({
      minPos,
      maxPos,
      lastViewedPos: minPos,
      sectionDone: true,
      reviewing: true,
    });
  };

  mkTestQuestionOverlay = () => {
    const question = this.state.testQuestions[0];
    const answers = this.state.testAnswers[question.id];
    return {
      id: `tq-${question.id}`,
      elt: answers && (
        <TestQuestion
          {...question}
          {...answers}
          onAnswered={
            this.props.preview ? () => { } : this.handleQuestionAnswered
          }
          onReview={this.handleReview}
          hidden={!!this.state.reviewing}
          bgColor="#fff" //{this.state.module.type === 'pdf' ? '#fff' : '#000'}
          width={this.state.moduleWidth}
          isPlayerTest
        />
      ),
    };
  };
  handleQuestionAnswered = (response, continueToNext = false) => {
    const courseId = this.props.courseId;
    const q = this.state.testQuestions[0];
    const csListCurriculumID = this.props.curriculum.course_list?.every(obj => obj.hasOwnProperty('curriculum_id'))
    const multiCourseList =
      csListCurriculumID &&
      this.props.curriculum.course_list.find(
        (course) => course.course_id == courseId
      );
    const multiCurriculumId = multiCourseList?.curriculum_id;
    this.props.ctx_api.privateRequest({
      cmd: "insert_test_results",
      args: {
        memberId: this.props.memberId,
        curriculumId: csListCurriculumID ? multiCurriculumId.toString() : this.props.curriculum.curriculum_id.toString(),
        coursePublishId: this.props.coursePublishId,
        courseId: courseId.toString(),
        videoUnitId: this.props.module.contentId,
        testQuestionId: q.id.toString(),
        testQuestion: q.question,
        questionType: q.type,
        testPoints: q.points,
        correctAnswers: response.correct || [],
        incorrectAnswers: response.incorrect || [],
        missedAnswers: response.missed || [],
      },
    }).then(this.setState({ icqSubmit: true }));
    this.setState({ lastAnsweredTestQuestionId: q.id });
    this.handleSectionDone(continueToNext);
  };

  mkModuleOverlay = () =>
    0 < this.state.testQuestions.length ? this.mkTestQuestionOverlay() : null;
  renderAdvancingMessage = () => {
    return (
      <div className="player-blank">
        <Loader active width={40} />
      </div>
    );
  };

  renderNextModuleMessage = () => {
    const StyledPlayer = this.props.module.image ? StyledPlayerMessages : StyledPlayerMessage
    const screenSize = window.screen.width
    const style = (this.props.module?.type === "HTMLEditor" || this.props.prevModule?.type === "HTMLEditor") && !this.state.isFullscreen && (this.state.playerState === "ready" || this.state.playerState === "spawned")
      ? { height: "100%", filter: "grayscale(50%)" }
      : this.props.prevModule?.type === "html" || this.props.prevModule?.type === "HTMLEditor" && !this.state.isFullscreen
        ? { height: "60vh", filter: "grayscale(50%)" }
        : this.props.module.type === "html" && this.props.prevModule?.type === "embedded" && !this.state.isFullscreen
          ? { height: "100%", filter: "grayscale(50%)" }
          : this.props.module.type === "html" || this.props.module.type === "HTMLEditor" && !this.state.isFullscreen
            ? { height: "75vh", filter: "grayscale(50%)" }
            : this.props.prevModule.type === "test" && this.state.playerState === "ready" && !this.state.isFullscreen
              ? { height: "60vh", filter: "grayscale(50%)" }
              : { height: "100%", filter: "grayscale(50%)" }
    const playerClassname = ((this.props.prevModule?.type === "test"
      || this.props.prevModule?.type === "html" || this.props.prevModule?.type === "HTMLEditor" || this.props.module?.type === "test"
      || this.props.module?.type === "html" || this.props.module?.type === "HTMLEditor") && (this.state.playerState === "ready"
        || this.state.playerState === "concluding") && screenSize > 822)
      ? "player-module-message-mob-v1"
      : this.props.fromChapterInner
        ? "player-module-message-mob"
        : "player-module-message"


    return (
      <StyledPlayer
        className={classNames(playerClassname)}
        style={screenSize > 822 ? style : { height: "100%", filter: "grayscale(50%)" }}>
        <div className="background"
          style={{ padding: "15.25%", height: "100%", backgroundImage: `url(${this.props.module.image})` }}>
          {this.props.creditsEarned > 0 ? (`Congratulations on completing`) : !this.props.fromPreview ? (`Congratulations on completing`) : (`End of the`)}
          {` ${this.props.prevModule.type === "test"
              ? this.props.ctx_labels.singular.test.toLowerCase()
              : this.props.ctx_labels.singular.module.toLowerCase()
            }`}
          {this.props.creditsEarned > 0 ?
            <div className="player-module-message-emph" >
              {this.props.prevModule.name}
            </div> :
            !this.props.fromPreview && <div className="player-module-message-emph">
              {this.props.prevModule.name}
            </div>}
          {this.props.creditsEarned > 0 && `You have earned`}
          {this.props.creditsEarned > 0 && <div className="player-module-message-emph">
            {`${this.props.creditsEarned} credits`}
          </div>}
          {this.props.creditsEarned > 0 ? `Continue to the next ${this.props.module.type === "test"
              ? this.props.ctx_labels.singular.test.toLowerCase()
              : this.props.ctx_labels.singular.module.toLowerCase()
            }...` : !this.props.fromPreview && (`Continue to the next ${this.props.module.type === "test"
              ? this.props.ctx_labels.singular.test.toLowerCase()
              : this.props.ctx_labels.singular.module.toLowerCase()
              }...`)}
          {this.props.creditsEarned > 0 ?
            <div className="player-module-message-emph">
              {this.props.module.name}
            </div> : !this.props.fromPreview && <div className="player-module-message-emph">
              {this.props.module.name}
            </div>}
        </div>
      </StyledPlayer>
    )
  }


  renderChapterCompletedMessage = () =>
    this.props.subjectData ? (
      this.props.chapterCompleted ? (
        this.props.creditsEarned > 0 ? (
          <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
            {this.props.ctx_labels.singular.congratsOnComplte}
            {` ${this.props.module.type === "test"
                ? this.props.ctx_labels.singular.test.toLowerCase()
                : this.props.ctx_labels.singular.module.toLowerCase()
              }`}
            <div className="player-module-message-emph">
              {this.props.module.name}
            </div>
            {this.props.ctx_labels.singular.youHaveEarned}
            <div className="player-module-message-emph">
              {`${this.props.creditsEarned} credits`}
            </div>
            {this.props.ctx_labels.singular.youHaveReached}
            {/* {this.props.ctx_labels.singular.chapter.toLowerCase()} */}
            <div className="player-module-message-emph">
              {this.props.chapter.name}
            </div>
            {/* {this.props.courseCertificate === 1 && this.props.courseProgress === 100 &&
          <div className="player-module-message-emph">
            You can download the course certificate from below
          </div>
            } */}
          </StyledPlayerMessage>
        ) : (
          <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
            {this.props.ctx_labels.singular.youHaveReached}
            <div className="player-module-message-emph">
              {this.props.chapter.name}
            </div>
            {/* {this.props.courseCertificate === 1 && this.props.courseProgress === 100 &&
          <div className="player-module-message-emph">
            You can download the course certificate from below
          </div>
            } */}
          </StyledPlayerMessage>
        )
      ) : null
    ) : (
      <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
        {this.props.ctx_labels.singular.youHaveReached}
      </StyledPlayerMessage>
    );

  renderModuleMessage = () => {
    const { playerState, extPlayConfirm, scormState } = this.state;
    const { type, playExt } = this.state.module;
    return playerState === "advancing"
      ? this.renderAdvancingMessage()
      : playerState === "ready"
        ? this.renderNextModuleMessage()
        : playerState === "concluding"
          ? this.renderChapterCompletedMessage()
          : type === "html" && playExt && extPlayConfirm && this.state.testQuestions.length === 0
            ? this.renderExtHtmlMessage()
            // : type === "HTMLEditor" && this.state.testQuestions.length === 0 
            // ? this.renderExtHtmlMessage()
            : type === "embedded" && playExt && extPlayConfirm
              ? this.renderExtHtmlMessage()
              : type === "scorm" && scormState === "close"
                ? this.renderScormCloseMessage()
                : null;
  };

  renderToc = (baseControls) => (
    <div>
      <p>TOC</p>
      <div className="player-controls aux-controls">{baseControls}</div>
    </div>
  );

  renderExtHtmlMessage = () => (
    <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
      {`This ${this.props.ctx_labels.singular.module.toLowerCase()} is open in
      another tab`}
    </StyledPlayerMessage>
  );

  // renderExtHtmlEditorMessage = () => (
  //   <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
  //     {`This ${this.props.ctx_labels.singular.module.toLowerCase()} is open in
  //     another tab`}
  //   </StyledPlayerMessage>
  // );

  isIphone = () => {
    return /iPhone/i.test(navigator.userAgent);
  };

  renderScormCloseMessage = () => (
    <StyledPlayerMessage className={classNames(this.props.fromChapterInner ? "player-module-message-mob" : "player-module-message")}>
      Course update in progress. Please wait...
    </StyledPlayerMessage>
  );

  renderNotes = (baseControls) => {
    if (this.props.preview) {
      return null;
    }
    return (
      <div className="player-notes-frame">
        {/* <Notes
          memberId={this.props.memberId}
          curriculumId={this.props.curriculum.curriculumId}
          contentId={this.state.module.contentId}
          coursePublishId={this.props.coursePublishId}
          // onNoteChange={this.props.onPlayerNoteChange}
          // copyNotes={true}
        /> */}

        {this.primaryPaneOnly() && (
          <div className="player-controls">{baseControls}</div>
        )}
      </div>
    );
  };

  renderSlides = (baseControls) => (
    <div>
      <p>SLIDES</p>
      <div className="player-controls aux-controls">{baseControls}</div>
    </div>
  );

  renderChat = (baseControls, visible) => {
    if (this.props.preview) {
      return null;
    }
    return (
      <div className="player-chat-frame">
        {/* <Chat
          singleThreaded={true}
          memberId={this.props.memberId}
          curriculumId={this.props.curriculum.curriculumId}
          chapterId={this.props.chapter.id}
          contentId={this.state.module.contentId}
          coursePublishId={this.props.coursePublishId}
          hidden={!visible}
          onUnreadMessageChange={this.handleUnreadChatMessageChange}
          isFullscreen={true} //{this.state.isFullscreen}
        /> */}
        {this.primaryPaneOnly() && (
          <div className="player-controls">{baseControls}</div>
        )}
      </div>
    );
  };

  renderModule = (baseControls, visible) => {
    const { module } = this.state;
    let moduleClass;
    let controlsSpec = null;
    const isLoomUrl = (url) => {
      try {
        const urlObj = new URL(url);
        return urlObj.hostname === 'www.loom.com' && urlObj.pathname.startsWith('/share/');
      } catch (e) {
        return false;
      }
    };

    switch (module.type) {
      case "video":
        if (isLoomUrl(this.state.module.fileUrl))  {
          moduleClass = LoomPlayer;
          this.setState({ isLoom: true });
        } else {
          moduleClass = Video;
        }
        controlsSpec = ["PlayPause", "Seek", "Time", "Volume", "PlaybackSpeedButton", "QualitySelect"];
        break;
      case "pdf":
        moduleClass = Pdf;
        break;
      case "html":
        moduleClass = Html;
        break;
      case "test":
        moduleClass = this.props.fromRenderPreview ? PreviewTest : Test;
        break;

      case "survey":
        moduleClass = this.props.fromRenderPreview ? PreviewTest : Test;
        break;
      case "scorm":
        moduleClass = Scorm;
        break;
      case "embedded":
        moduleClass = Embedded;
        break;
      case "audio":
        moduleClass = Video;
        controlsSpec = ["PlayPause", "Seek", "Time", "Volume", "QualitySelect"];
        break;
      case "HTMLEditor":
        moduleClass = Embedded;
        break;
      case "PPTEditor":
        moduleClass = PPT;
        break;
      default:
        break;
    }
    if (!moduleClass) {
      return null;
    }
    const {
      lastViewedPos,
      maxViewedPos,
      minPos,
      maxPos,
      reviewing,
      isFullscreen,
    } = this.state;

    const moduleProps = {
      testModule: this.props.testModule,
      fromCourse: this.props.fromCourse,
      preview: this.props.preview,
      testView: this.props.testView,
      coursePublishId: this.props.coursePublishId || null,
      module,
      lastViewedPos,
      maxViewedPos,
      minPos,
      maxPos,
      reviewing,
      isFullscreen,
      baseControls,
      controlsSpec,
      hidden: !visible,
      overlay: this.mkModuleOverlay(),
      message: this.renderModuleMessage(),
      onWidthChange: this.handleModuleWidthChange,
      onModuleProgress: this.handleModuleProgress,
      onAllViewed: this.handleSectionDone,
      onUnmounted: this.props.onUnmounted,
      onError: this.handlePlayerSystemFailure,
      onPause: this.props.onPause,
      // seekable: this.props.seekable,
      seekable: this.props.module.nonAcademic ? true : this.props.seekable,
      playerVisible: this.props.playerVisible,
      isShowSidebar: this.props.isShowSidebar,
      onScormClosed: this.handleScormClose,
      onPdfLoaded: this.handlePdfLoaded,
      playerState: this.state.playerState,
      // inCourseQuiz: this.props.inCourseQuiz
      icqSubmit: this.state.icqSubmit ?? null,
      handleIcq: this.handleIcq,
      frmRndrprvw: this.props.frmRndrprvw,
      frmChprprvw: this.props.frmChprprvw,
      crsePrev: this.props.crsePrev
    };


    if (module.type === "test") {
      moduleProps.publishId = module.contentId;
      moduleProps.Form = this.props.renderTest;
      moduleProps.playerState = this.state.playerState;
      moduleProps.handleTestHeight = this.props.handleTestHeight;
      moduleProps.handleOpenTestPreview = this.props.handleOpenTestPreview;

    }

    else if (module.type === "survey") {
      moduleProps.publishId = module.contentId;
      moduleProps.Form = this.props.renderSurvey;
      moduleProps.playerState = this.state.playerState;
      moduleProps.handleTestHeight = this.props.handleTestHeight;
      moduleProps.handleOpenTestPreview = this.props.handleOpenTestPreview;


    }


    return React.createElement(moduleClass, moduleProps);
  };

  renderPrimaryBaseControls = (pane) => {
    let paneMenuEntries = {
      // toc:    'Contents',
      // slides: 'Slides',
      notes: "Notes",
    };
    if (!this.props.preview && this.props.curriculum.sharedBy) {
      const n = this.state.unreadChatMessageCount;
      const trainerLabel = this.props.ctx_labels.singular.trainer;
      paneMenuEntries.chat = `Ask the ${trainerLabel}${0 < n ? ` (${n})` : ""}`;
    }
    if (this.primaryPaneOnly()) {
      paneMenuEntries = {
        module: util.capitalizeWord(this.props.ctx_labels.singular.module),
        ...paneMenuEntries,
      };
    }
    const visiblePanes = this.getVisiblePanes();
    const unreadChatMessages =
      0 < this.state.unreadChatMessageCount &&
      visiblePanes.indexOf("chat") === -1;

    const toggleFullscreen =
      (this.fullscreenEnabled && this.toggleFullscreen(pane)) || null;
    const testQuestionCount = this.state.testQuestions.length;
    const arr = ["slide", "html", "scorm", "pdf", "embedded", "HTMLEditor", "PPTEditor"];
    const validContentType = arr.includes(this.state.module.type)
    const sectionHasTestQuestion =
      this.state.minPos < this.state.maxPos &&
      this.state.module.testQuestions.some((q) => q.pos === this.state.maxPos);
    const continueVisible = this.state.module.type === "survey" ? true : this.state.module.type === "pdf" && !this.state.pdfLoaded ? true : this.state.module.type === "html" && this.state.htmlContent ? true :
      (pane === "module") && (this.state.sectionDone || this.state.module.nonAcademic || this.props.inCourseQuiz);
    const continueLabel = this.props.ctx_user.user.type === 1 ? (this.state.reviewing
      ? (this.props.ctx_labels.singular.done)
      : sectionHasTestQuestion
        ? (this.props.ctx_labels.singular.continue)
        : 1 < testQuestionCount
          ? "NEXT QUESTION"
          : 1 === testQuestionCount
            ? (this.props.ctx_labels.singular.resumeMod)
            : this.state.playerState === "concluding"
              ? !this.props.playerOnly ? `BACK TO ${this.props.viewType === "card"
                  ? this.props.ctx_labels.singular.chapter.toUpperCase()
                  : "HOME"
                }` : (this.props.ctx_labels.singular.courseComp)
              : validContentType ?
                (this.props.ctx_labels.singular.markAsComp) :
                (this.props.ctx_labels.singular.continue)) :
      this.props.ctx_user.user.type !== 1 && this.state.module.type === "pdf" ?
        (this.props.ctx_labels.singular.continue) :
        this.state.testQuestions && this.state.module.type !== "pdf" && 1 === testQuestionCount &&
        (this.props.ctx_labels.singular.resumeMod)
    const TrainerContinuveVisible = this.props.ctx_user.user.type !== 1 && this.state.testQuestions && 1 === testQuestionCount
      || this.props.ctx_user.user.type !== 1 && this.state.module.type === "pdf" ? true : false
    return (
      <BaseControls
        isFullscreen={this.state.isFullscreen}
        continueVisible={this.props.ctx_user.user.type === 1 ? continueVisible : TrainerContinuveVisible}
        continueLabel={continueLabel}
        onContinue={this.handleContinue}
        toggleFullscreen={toggleFullscreen}
        paneMenuEntries={paneMenuEntries}
        visiblePanes={visiblePanes}
        onPaneToggle={this.handlePaneToggle}
        unreadChatMessages={unreadChatMessages}
        preview={this.props.preview}
        playerState={this.state.playerState}
        viewType={this.props.viewType}
        subjectData={this.props.subjectData}
        playerOnly={this.props.playerOnly}
        downloadable={this.props.module.downloadable}
        handleDownload={() => this.props.handleDownload(this.props.module.contentId)}
        inCourseQuiz={this.props.inCourseQuiz}
        fromChapterInner={this.props.fromChapterInner}
        pdfLoaded={this.state.pdfLoaded}
        type={this.state.module.type}
        moduleProgress={this.state.module.moduleProgress}
        isIphone={this.state.isIphone}
        isLoom = {this.state.isLoom}
      />
    );
  };

  renderAuxBaseControls = (pane) => {
    const toggleFullscreen =
      (this.fullscreenEnabled && this.toggleFullscreen(pane)) || null;
    return (
      // <div></div>
      <BaseControls
        isFullscreen={this.state.isFullscreen}
        continueVisible={false}
        toggleFullscreen={toggleFullscreen}
        isIphone={this.state.isIphone}
      />
    );
  };

  renderPane = (pane, renderFn) => {

    const { isFullscreen } = this.state;
    const paneIsPrimary = pane === this.state.primaryPane;
    const type = this.props.module.type
    const baseControls = paneIsPrimary
      ? this.renderPrimaryBaseControls(pane)
      : this.renderAuxBaseControls(pane);
    const className = [
      `player-${pane}`,
      paneIsPrimary ? "player-primary-pane" : "player-pane",
      pane !== "module" ? "player-aux-pane" : null,
    ].join(" ");
    const visible = this.getVisiblePanes().indexOf(pane) !== -1;
    const screenSize = window.screen.width
    return (
      <div className={className}>
        {(this.props.module.type === 'html' && this.isFullscreenNow && screenSize > 768) ?
          <div
            style={(isFullscreen && type === "html") ? { marginTop: "-80px" } : {}}
            className={`player-pane-content player-${pane}-content`}>
            {renderFn(baseControls, visible)}
          </div> :
          <div className={`player-pane-content player-${pane}-content`}>
            {renderFn(baseControls, visible)}
          </div>
        }
      </div>
    );
  };

  render = () => {
    const auxPanes = this.getVisibleAuxPanes();
    const className = auxPanes
      .map((p) => `player-${p}-visible`)
      .concat(["player", this.state.isFullscreen ? "player-fullscreen" : null])
      .join(" ");
    const style = this.state.noTransitionDelay
      ? { "--player-pane-delay": "0" }
      : null;
    return (
      <div className={className} style={style}>
        {/* {this.renderPane("toc", this.renderToc)} */}
        {/* {this.renderPane("notes", this.renderNotes)} */}
        {/* {this.renderPane("chat", this.renderChat)} */}
        {/* {this.renderPane("slides", this.renderSlides)} */}
        {this.renderPane("module", this.renderModule)}
      </div>
    );
  };
}

export default withWindowContext(
  withApiContext(withLabelsContext(withUserContext(withAnalyticsContext(withRouter(Player))))
  ));
