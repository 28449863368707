import { Header } from "components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import css from "./login.module.scss";
import classNames from "classnames";
import * as config from "config";
import { withSiteContext, withApiContext, withAccessContext } from "context";
import * as util from "services/util";

const BannerContent = ({ title, subTitle }) => {
  return (
    <div className={css.banner_content}>
      <h2 className={css.banner_title}>{title}</h2>
      <div className={css.banner_desc}>{subTitle}</div>
    </div>
  );
};

function Login(props) {
  const { title, subTitle, homeWebBanner, homePageContent } =
    props.ctx_site.site;
  document.title = props.ctx_site.site.siteName || config.companyName;

  return (
    <>
      <Header />

      <div
        className={classNames([css.banner, props.full && css.full])}
        style={{
          backgroundImage: `url(data:image/png;base64,${homeWebBanner})`,
        }}
      >
        <Container className={props.full && css.fullContainer}>
          <Row
            className={props.full ? "align-items-center" : "align-items-end"}
          >
            <Col md={7}>
              <BannerContent title={title} subTitle={subTitle} />
            </Col>
            <Col md={5}>
              <iframe
                height={"400px"}
                style={{ backgroundColor: "#cecfd1" }}
                srcdoc={`<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
                  <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <link rel="stylesheet" type="text/css" href="/shibboleth-sp/main.css" />
                    <title>Local Logout</title>
                  </head>
                  <body>
                  <h1>Local Logout</h1>
                  <strong>Status of Local Logout:</strong> Logout completed successfully.
                  <p/>
                  You <strong>MUST</strong> close your browser to complete the logout process.
                  </body>
                  </html>`}
                title={"Single Signon"}
                allowFullScreen={false}
              />
            </Col>
          </Row>
          {/* footer */}
        </Container>
        {props.full && (
          <footer className={css.footer}>
            <Container className="text-center">
              <div className={css.footer__links}>
                <Link to="/contact">Contact Us</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-of-use">Terms of Use</Link>
              </div>
            </Container>
          </footer>
        )}
      </div>

      {!props.full && (
        <>
          <Container>
            <div
              className={css.content}
              dangerouslySetInnerHTML={{ __html: homePageContent }}
            ></div>
          </Container>

          {/* footer */}
          <footer className={css.footer}>
            <Container className="text-center">
              <div className={css.footer__links}>
                <Link to="/contact">Contact Us</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-of-use">Terms of Use</Link>
              </div>
            </Container>
          </footer>
        </>
      )}
    </>
  );
}

export default withSiteContext(
  withAccessContext(withApiContext(util.withRouterHistory(Login)))
);
