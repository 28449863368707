import { IconHome } from "Icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import css from "./breadcrumb.module.scss";

const StyledBreadcrumb = styled.div`
	&& {
		.${css.item} {
			color: ${({ theme }) => theme.primary};
			svg {
				fill: ${({ theme }) => theme.color2};
			}
			a {
				color: ${({ theme }) => theme.primary};
			}
		}
	}
`;

export default function Breadcrumb(props) {
	let list;
	if (props.list) {
		list = props.list.map((value, index) => (
			<div className={css.item} key={index}>
				{value.current ?
				 (
					value.title
				) : (
					<Link to={{pathname: value.link ?? "/", data: value.data,filterData:value.filterData,state:value.state}} onClick={value.onLinkClick ?? null}>{value.title}</Link>
				)}
			</div>
		));
	}
	return (
		<StyledBreadcrumb className={css.wrapper}>
			<div className={css.item}>
				<Link to="/">
					<IconHome width="22" height="22" 
						style = {{marginTop:"-4px"}}
					/>
				</Link>
			</div>
			{list}
		</StyledBreadcrumb>
	);
}
