// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import * as config from 'config';
import { withApiContext } from 'context';
import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import './Ppt.css'
import cmCss from "pages/ChapterInner/CourseMedia/course-media.module.scss";
import classNames from "classnames";
import { DocumentViewer } from 'react-documents';



class Ppt extends PureComponent {

  static propTypes = {
    className: PropTypes.string,
    module: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    minPos: PropTypes.number.isRequired,
    maxPos: PropTypes.number.isRequired,
    onModuleProgress: PropTypes.func,
    onAllViewed: PropTypes.func.isRequired,
    onUnmounted: PropTypes.func,
    overlay: PropTypes.shape({
      elt: PropTypes.element,
    }),
    message: PropTypes.element,
    isFullscreen: PropTypes.bool.isRequired,
    baseControls: PropTypes.element.isRequired,
  };

  componentWillReceiveProps = () => {
    this.props.onAllViewed()
  }

  componentDidMount = () => {
    // Allow the user to indicate when he is done with the content
    this.props.onAllViewed();
  };


  componentWillUnmount = () => {
    // this.reportModuleProgress();
    if (this.props.onUnmounted) {
      this.props.onUnmounted();
    }
  };

  reportModuleProgress = () => {
    if (this.props.onModuleProgress) {
      this.props.onModuleProgress({
        lastViewedPos: 0,
        maxViewedPos: 0,
      });
    }
  };

  renderControls = () => (
    <div className={classNames(`${cmCss.toolbar_mob} bg-color2 text-white`)}>
      <div className={cmCss.toolbar_left}>&nbsp;</div>
      <div className={cmCss.toolbar_center}></div>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div>
    </div>
  );
  renderControlsMob = () => (
    <div className={classNames(`${cmCss.toolbar_mob} bg-color2 text-white`)}>
      <div className={cmCss.toolbar_right}>{this.props.baseControls}</div></div>
  );

  render = () => {

    const file = this.props.module

    const blanked = this.props.minPos === this.props.maxPos;
    const screenSize = window.innerWidth < 768 ? false : true;
    const fullscreenSize = window.innerWidth < 950 ? false : true;

    return (
      <>
        <div className={fullscreenSize ? 'docviewer' : (this.props.isFullscreen === false ? 'docviewer' : 'docviewerFulscrn')}>
          {file.fileUrl &&
            <div className="docWrapper">
              <div className="hide"></div>
              <DocumentViewer
                viewerUrl={'https://docs.google.com/gview?url=%URL%&embedded=true'}
                url={`${file.url}`}
                viewer="google"
                googleCheckContentLoaded={true}
              >
              </DocumentViewer>
            </div>
          }
          {blanked && <div className="player-blank" />}
          {this.props.overlay && (
            <div className="player-overlay">{this.props.overlay.elt}</div>
          )}
          {this.props.message}

        </div>
        <div className={this.props.fromCourse ? (fullscreenSize ? 'bottom-base-controls-crs' : (this.props.isFullscreen === false ? 'bottom-base-controls-crs' : 'bottom-base-controlsFulscrn')) : fullscreenSize ? 'bottom-base-controls' : (this.props.isFullscreen === false ? 'bottom-base-controls' : 'bottom-base-controlsFulscrn')}>
          {screenSize ? this.renderControls() : this.renderControlsMob()}
        </div>
      </>

    );
  };

}

export default
  withApiContext(
    Ppt
  )