// import { Button, RadioButton, ToggleButton } from "components";
// import { IconAudioWave, IconCirclePlay, IconLowercase } from "Icons";
import React from "react";
// import { ButtonGroup, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const StyledContent = styled.div`
  color: #747474;
  ${'' /* font-size: 12px; */}

  h4 {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
  }
`;

function FilterDropdownForm(props) {
  return (
    <StyledContent>
      {props.children}
    </StyledContent>
  );
}

export default FilterDropdownForm
