import React from "react";
import { Spin as SpinIcon } from "./Spin";

const Loader = props => {
  let innerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: props.padding ? props.padding : "2em"
  };
  let textStyle = {
    marginLeft: "1em",
    fontSize: "0.9em",
    fontWeight: "bold",
    color: "var(--adm-primary-color)"
  };

  if (props.hide) {
    innerStyle.display = "none";
  }

  return (
    <div className="content-loader" style={innerStyle}>
      <SpinIcon {...props} />
      { props.children && <span className="loader-text" style={textStyle}> {props.children} </span>}
    </div>
  );
};

export default Loader;
