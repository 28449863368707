import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { ToggleButton as BsToggleButton } from "react-bootstrap";
import "./toggle-button.scss";

export default function ToggleButton({ iconOnly, ...props }) {
	return (
		<BsToggleButton
			{...props}
			className={classNames(
				"btn-toggle",
				props.className,
				iconOnly && "btn--icon",
				props.cb && "btn-icon-grp"
			)}
		>
			{props.children}
		</BsToggleButton>
	);
}

ToggleButton.propTypes = {
	type: PropTypes.oneOf(["checkbox", "radio"]),
	checked: PropTypes.bool,
	iconOnly: PropTypes.bool,
};

ToggleButton.defaultProps = {
	type: "checkbox",
	variant: "white",
	checked: false,
	iconOnly: false,
	className: "",
};
