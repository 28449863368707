import {
  BackLink,
  Button,
  JustifyBetween,
  Loader,
  ResponsiveTabs,
  InputField,
  Breadcrumb,
  OverlayTooltip,
} from "components";
import {
  IconChevronDownNew,
  IconChevronUpNew,
  IconStarFill,
  IconRating,
  IconShare,
  IconContentHide,
  IconDownloadNew,
  // IconCourseCertificate,
  IconAssessmentPlayer,
  IconPlayerMark,
} from "Icons";
import {
  faExclamationCircle,
  faPlay,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContainer } from "pages/Main";
import React, { Component } from "react";
import css from "./chapter-inner.module.scss";
import {
  withApiContext,
  withLabelsContext,
  withUserContext,
  withAnalyticsContext,
  withWindowContext,
} from "context";
import * as util from "services/util";
import * as config from "config";
import ChapterList from "./ChapterList";
import classNames from "classnames";
import { Redirect, withRouter } from "react-router";
import { Col, Row, Card, Modal, Badge } from "react-bootstrap";
import Player from "components/Player";
import cmCss from "./CourseMedia/course-media.module.scss";
import { ProgressBar } from "components";
import ModuleNotes from "components/ModuleNotes/ModuleNotes";
import Modules from "./Modules";
import Chat from "pages/Messages/Chat/Chat";
import styled from "styled-components";
import { borderRadius, rgba } from "polished";
import moment from "moment";
import "./ChapterInner.css";
import MediaQuery from "react-responsive";
import Rate from "pages/CourseRating/Rate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTheme } from "styled-components";


const StyledPlayerMessage = styled.div`
  && {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
    filter: grayscale(50%);
  }

  .${css.playererrormessage} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
  }

  .${css.playernomico} {
    color: ${({ theme }) => rgba(theme.primary, 1)};
  }
`;

const StyledPlayerMessages = styled.div`
  && {
    background: #000;
  }

  .${css.playererrormessage} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );
  }

  .${css.playernomico} {
    color: ${({ theme }) => rgba(theme.primary, 1)};
  }
`;

const StyledWrapper = styled.div`
  &&.${css.text_load} {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledTestPlayer = styled.div.attrs({
  id: "fullScreenSurveySet",
})`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  border: 2px solid ${({ theme }) => theme.color3};
  border-radius: 3px;
  height: 55vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
`;
const StyledTestPlayerMob = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 2px solid ${({ theme }) => theme.color3};
  border-radius: 3px;
  height: 180px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
`;
const StyledTotalMark = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.color2};
`;
const StyledTestMark = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.color2};
`;
const StyledTotalMarkMob = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.color2};
`;
const StyledTestMarkMob = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.color2};
`;

const TabSubject = ({
  label,
  courseProgress,
  description,
  trainerName,
  skills,
  courseDescription,
  chapterDescription,
  modDescription,
  type,
  playerModule,
}) => {
  return (
    <>
      <div className="mb-3"></div>
      <Col md={10}>
        {/* <Card body> */}
        <div className="py-1">
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "600",
            }}
            //  className="text-color3 fw-600 fs-18 mb-2"
          >
            {label.trainerDetails}
          </p>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "12px",
              color: "#000000",
              fontWeight: "400",
              marginTop: "-10px",
            }}
          >
            {trainerName}
          </p>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "600",
            }}
          >
            {label.description}
          </p>
          {courseDescription && (
            <>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "600",
                  marginTop: "-10px",
                }}
              >
                {label.course}
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "400",
                }}
              >
                {courseDescription}
              </p>
            </>
          )}
          {chapterDescription && (
            <>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "600",
                }}
              >
                {label.chapter}
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "-10px",
                }}
              >
                {chapterDescription}
              </p>
              <h6 className="text-color3"></h6>
              <p></p>
            </>
          )}
          {modDescription && (
            <>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: "600",
                }}
              >
                {type === "test"
                  ? label.assessment
                  : playerModule !== null
                  ? label.module
                  : ""}
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "-10px",
                }}
              >
                {modDescription}
              </p>
            </>
          )}
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "600",
            }}
          >
            {skills !== null ? label.skills : null}
          </p>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "12px",
              color: "#000000",
              fontWeight: "400",
              marginTop: "-10px",
            }}
          >
            {skills}
          </p>
        </div>
        <br></br>
      </Col>
    </>
  );
};

const TabNotes = ({ studentId, subjectId, moduleId }) => {
  return (
    <>
      <div className="mb-3"></div>
      <Col md={12}>
        <Card body className="border-0">
          <ModuleNotes
            memberId={studentId}
            curriculumId={subjectId}
            // configId={null}
            contentId={moduleId}
          />
        </Card>
        <div className="mb-3"></div>
      </Col>
    </>
  );
};

const TabChat = ({
  studentId,
  subjectId,
  chapterId,
  contentId,
  coursePublishId,
  courseId,
  // visible,
  onUnreadMessageChange,
}) => {
  return (
    <>
      <Col md={12}>
        <Chat
          singleThreaded={true}
          memberId={studentId}
          curriculumId={subjectId}
          chapterId={chapterId}
          contentId={contentId}
          coursePublishId={coursePublishId}
          courseId={courseId}
          onUnreadMessageChange={onUnreadMessageChange}
        />
        <div className="mb-3"></div>
      </Col>
    </>
  );
};

class ChapterInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventStartat: "",
      isShowSidebar: true,
      isShowCopy: false,
      courseUId: "",
      modules: [],
      selectedModuleKey: "",
      currentModuleKey: "",
      currentChapterId: "",
      playerStatus: "dismissed",
      prevPlayerModule: null,
      playerCommand: null,
      playerState: null,
      playerModule: null,
      nextPlayerCommand: null,
      nextPlayerModule: null,
      playerCreditsEarned: null,
      notesAvailable: false, //props.chapter.notesAvailable,
      notesVisible: false,
      completedModules: 0,
      sequential: 1,
      contentHier: [],
      name: "",
      pointsAvaliable: null,
      pointsRewarded: null,
      totalModuleCompletedCount: null,
      totalModuleCount: null,
      description: "",
      chapterImage: "",
      loading: false,
      activeSectionTab: 1,
      isIdle: false,
      isPaused: false,
      notes: null,
      extPlayConfirm: false,
      selectedModule: null,
      coursePublishId: null,
      unreadChatMessageCount: 0,
      courseProgress: 0,
      modalShow: false,
      endDate: "",
      loader: false,
      chapterIDTrainee: "",
      revisit: false,
      viewMoreOpen: false,
      readMore: {
        module: false,
        chap: false,
        course: false,
        subject: false,
      },
      courseRate: null,
      loadingText: "",
      showPopup: true,
      progress: 0,
      modType: "",
      completionDate: "",
      isCertificate: false,
      certificateUrl: null,
    };
    this.initialTime = new Date();
    this.idleTrigger = this.idleTimeout();
    this.activityEvents = [
      "mousemove",
      "mousedown",
      "touchmove",
      "touchstart",
      "keypress",
      "wheel",
    ];
    this.containerRef = React.createRef();
    this.targetRef = React.createRef();
  }

  idleTimeout = () => {
    if (!this.props.playerOnly) {
      const { idleTimeoutInterval } = this.props.ctx_user.user.analytics;
      setTimeout(this.handleIdleStateChange, parseInt(idleTimeoutInterval));
    }
  };

  componentDidMount = () => {
    this.setState(
      {
        coursePublishId: !this.props.playerOnly
          ? this.props.location.subjectData &&
            this.props.location.subjectData.course_publish_id
          : this.props.subject &&
            this.props.subject.course_publish_id.toString(),
        eventStartat: new Date(),
      },
      this.getSubjectPublishHier
    );
    // const { studyMapTriggerInterval } = this.props.playerOnly ? null : this.props.ctx_user.user.analytics;
    this.summaryInterval = this.props.playerOnly
      ? null
      : setInterval(
          this.handleSummary,
          parseInt(this.props.ctx_user.user.analytics.studyMapTriggerInterval)
        );
    this.addActivityEvents();
    this.updateScrollPadding();
    window.addEventListener("resize", this.updateScrollPadding);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectedModuleKey !== this.state.selectedModuleKey) {
      this.setState({ viewMoreOpen: false });
    }

    //Trainee milestone call when switching module and chapter after consumption
    if (prevState.playerModule !== null && this.state.playerModule !== null) {
      this.state.chapterIDTrainee = this.state.playerModule.chapterId;
      if (
        prevState.playerModule.contentId !==
          this.state.playerModule.contentId &&
        this.state.revisit === false
      ) {
        this.updateTraineeMilestones(prevState);
      }

      if (
        prevState.playerModule.chapterId !==
          this.state.playerModule.chapterId &&
        this.state.revisit === false
      ) {
        this.updateTraineeMilestonesChapter(prevState);
      }
    }

    //Trainee milestone case for course revisit after completion
    if (
      this.state.courseProgress === 100 &&
      this.state.playerState === "playing"
    ) {
      this.state.revisit = true;
    }
    Object.values(this.state.modules).forEach((s) => {
      if (this.state.playerModule !== null) {
        if (s.moduleProgress === 100 && this.state.playerState === "playing") {
          s.revisit = true;
        }
      }
    });

    if (this.state.playerModule && !this.props.playerOnly) {
      // if (this.state.playerModule) {
      if (
        (prevState.playerModule === null && this.state.playerModule !== null) ||
        prevState.playerModule.contentId !== this.state.playerModule.contentId
      ) {
        this.handleSummary({ ...prevState.playerModule });
        this.setState({ readMore: false });
      }
    }
    if (!this.props.playerOnly) {
      if (
        prevProps.location.subjectData.course_publish_id !==
        this.props.location.subjectData.course_publish_id
      ) {
        this.setState(
          {
            coursePublishId:
              this.props.location.subjectData &&
              this.props.location.subjectData.course_publish_id,
          },

          this.getSubjectPublishHier
        );
      }
    } else {
      if (
        prevProps.subject.course_publish_id !==
        this.props.subject.course_publish_id
      ) {
        this.setState(
          {
            coursePublishId:
              this.props.subject && this.props.subject.course_publish_id,
          },
          this.getSubjectPublishHier
        );
      }
    }
    if (prevState.playerCommand && !this.state.playerCommand) {
      this.initModuleProgress(prevState.playerCommand);
    }
    if (this.isPlayerActive()) {
      if (prevState.modules !== this.state.modules) {
        if (this.state.playerState !== "playing") {
          this.getCurrentModule();
          // this.setPlayerModule(this.state.modules);
        }
      }
    }
    if (
      (prevState.playerStatus === "dismissed" ||
        prevState.playerStatus === "respawning") &&
      this.state.playerStatus === "initializing"
    ) {
      this.setState({ playerStatus: "spawned", playerState: null }); //entering
    } else if (this.state.playerStatus === "respawning") {
      this.setState((s0) => ({
        playerStatus: "initializing",
        prevPlayerModule: null,
        playerCommand: s0.nextPlayerCommand,
        playerModule: s0.nextPlayerModule,
        nextPlayerCommand: null,
        nextPlayerModule: null,
        playerState: null,
      }));
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.summaryInterval);
    this.removeActivityEvents();
    // clearInterval(this.timerId);

    //Trainee milstone call for module, chapter and course when navigating back to home after consumption
    if (this.state.playerModule !== null && this.state.revisit === false) {
      this.updateTraineeMilestones();
      this.updateTraineeMilestonesChapter();
      this.updateTraineeMilestonesCourse();
    }
    window.removeEventListener("resize", this.updateScrollPadding);
  };

  updateScrollPadding = () => {
    const container = this.containerRef.current;
    if (!container) return;

    const contentHeight = container.scrollHeight;
    const containerHeight = container.clientHeight;

    if (contentHeight <= containerHeight) {
      container.classList.add(css["no-scroll-padding"]);
    } else {
      container.classList.remove(css["no-scroll-padding"]);
    }
  };
  getCurrentModule = () => {
    const multiCurriculumId = !this.props.playerOnly
      ? this.props.location.subjectData.is_multicourse === 1
        ? this.props.location.subjectData.course_list.filter(
            (item) =>
              String(item.course_id) ===
              String(this.props.location.subjectData.course_id)
          )
        : []
      : [];
    let args = {
      curriculumId: !this.props.playerOnly
        ? this.props.location.subjectData.is_multicourse === 1
          ? String(multiCurriculumId[0].curriculum_id)
          : this.props.location.subjectData.curriculum_id
        : this.props.subject.curriculum_id.toString(),
      // chapterId: this.state.currentChapterId || null,
      coursePublishId: this.state.coursePublishId,
      courseId: !this.props.playerOnly
        ? String(this.props.location.subjectData.course_id)
        : this.props.subject.course_id.toString(),
    };
    if (this.state.currentChapterId) {
      args.chapterId = this.state.currentChapterId;
    }
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_current_module",
        args,
      })
      .then(({ rsp }) => {
        const { StatusValue, module_id, StatusText } = rsp[0];
        StatusValue ? this.setPlayerModule(module_id) : console.log(StatusText);
      })
      .catch(this.handleGetCurrentModuleFailure);
  };
  handleGetCurrentModuleFailure = (msg) => {};

  handlePlayerDefaultModuleClick = (sModule) => (command) => (e) => {
    // this.props.onSelectModule(sModule.contentId);
    this.handleSpawnPlayer(sModule)(command)(e);
  };

  handleUnreadChatMessageChange = (count) => {
    this.setState({ unreadChatMessageCount: count });
  };

  setPlayerModule = (moduleId) => {
    this.setState((s0) => {
      if (s0.completedModules == s0.modules.length) {
        return { playerCommand: "conclude" };
      }
      const prevplayerModule = this.state.playerModule;

      // const nextModuleId = this.getCurrentModule();
      const nextModule = s0.modules.filter((s1) => s1.contentId === moduleId);
      const playerModule =
        typeof nextModule !== "undefined" && nextModule.length > 0
          ? nextModule[0]
          : null;

      if (playerModule === null) {
        // All modules have been completed, and we just finished
        // replaying the last one
        return {
          playerCommand: "conclude",
          completedModules: s0.modules.length,
        };
      }
      const playerCommand =
        playerModule.status === config.contentStatusE.COMPLETED
          ? "restart"
          : playerModule.status === config.contentStatusE.REVIEWING
          ? "resume"
          : playerModule.status === config.contentStatusE.IN_PROGRESS
          ? "resume"
          : "play";
      return {
        playerCommand,
        playerModule,
        prevplayerModule,
        currentChapterId: playerModule.chapterId,
      };
    });
  };

  initModuleProgress = (command) => {
    if (this.state.playerModule.type === "survey") {
      return true;
    }

    switch (command) {
      case "play":
        this.persistModuleProgress({ timeId: "0" });
        break;
      case "restart":
        this.persistModuleProgress({ lastViewedPos: 0 });
        break;
      default:
        break;
    }
  };

  isPlayerActive = () => {
    const activeStatusList = ["initializing", "spawned"];
    return activeStatusList.indexOf(this.state.playerStatus) !== -1;
  };

  getSubjectPublishHier = (selectCurrent = false) => {
    console.log("completed");
    const courseId = !this.props.playerOnly
      ? this.props.location.subjectData.courseId ||
        this.props.location.subjectData.course_id
      : this.props.subject.course_id;
    const coursePublishId = !this.props.playerOnly
      ? this.state.coursePublishId ||
        this.props.location.subjectData.coursePublishId
      : this.props.subject.course_publish_id;
    this.setState({ loading: true });
    let args = {
      courseId: courseId.toString(),
      coursePublishId: coursePublishId.toString(),
    };
    // subject type names comes with "C" or "Card" / same for List also .
    if (
      !this.props.playerOnly
        ? this.props.location.subjectData.type === "C" ||
          this.props.location.subjectData.type === "Card"
        : this.props.subject.type === "C" || this.props.subject.type === "Card"
    ) {
      args.chapterId = !this.props.playerOnly
        ? this.props.location.subjectData.chapterId
        : this.props.subject.chapterId || this.state.currentChapterId;
    }
    this.props.ctx_api
      .privateRequest({
        cmd: "get_trainee_course_publish_hier_v2",

        deps:
          this.state?.playerModule?.type === "survey"
            ? []
            : [
                "insert_update_trainee_module_time",
                "insert_update_trainee_points",
              ],
        args,
      })
      .then(({ rsp }) =>
        this.handleGetSubjectChapterListResponse(rsp, selectCurrent)
      );
    // .catch(this.handlegetSubjectChapterListFailure)
  };

  handleGetSubjectChapterListResponse = (rsp, selectCurrent) => {
    this.setState({ data: rsp });
    const course = rsp[0];
    const certificate = course.certificate;
    const sequential = course.sequential;
    const description = course.description;
    const trainerName = course.trainer_name;
    const skills = course.skills_covered;
    const seekable = course.seekable;
    const courseProgress = course.course_progress;
    const endDate = new Date(course.end_date);
    const startDate =
      course.course_module_details.length > 0
        ? new Date(course.course_module_details[0].scheduled_on_timestamp)
        : "";
    const courseDescription = course.courses[0].description;
    console.log("dates..",new Date(course.course_module_details[0].scheduled_on_timestamp),new Date(course.end_date))
    // const downloadable = course.course_module_details[0].download_content;
    // const modules = this.mkModuleList(course.course_module_details)
    // const currentModuleKey = modules[0].contentId;
    // const selectedModuleKey = currentModuleKey;
    this.setState((s0) => {
      // const modules = { ...s0.modules };
      const ml = this.mkModuleList(course.course_module_details);
      let modules = [];
      const contentHier = course.course_chapter_details.map((ch) => {
        const modFilter = ml.filter((mo) => mo.chapterId == ch.chapter_id);
        modules.push(...modFilter);
        return {
          id: ch.chapter_id.toString(),
          name: ch.name,
          modules: modFilter,
          sequence: ch.sequence,
          description: ch.description,
        };
      });
      // subject type names comes with "C" or "Card" / same for List also .
      if (
        !this.props.playerOnly
          ? this.props.location.subjectData.type === "C" ||
            this.props.location.subjectData.type === "Card"
          : this.props.subject.type === "C" || this.props.subject.type === "C"
      ) {
        modules = [...ml];
      }

      let currentModuleKey = s0.currentModuleKey;
      let selectedModuleKey = s0.selectedModuleKey;
      let currentChapterId = s0.currentChapterId;
      const nextModule = modules.filter(
        (s1) => s1.contentId === course.current_module_id
      );

      const currentModule =
        typeof nextModule !== "undefined" && nextModule.length > 0
          ? nextModule[0]
          : null;
      // const currentModule = util.getCurrentModule(modules);
      // const currentModule = this.state.playerModule;

      if (currentModule) {
        const currentContentId = currentModule ? currentModule.contentId : null;
        currentModuleKey = currentContentId;
        const selectedContentId = selectedModuleKey;
        currentChapterId = currentModule.chapterId;

        if (
          selectCurrent ||
          !selectedContentId ||
          !modules.some((m) => selectedContentId === m.contentId)
        ) {
          selectedModuleKey = currentContentId || this.noContentId;
        }
      }
      return {
        contentHier,
        modules,
        currentModuleKey,
        selectedModuleKey,
        currentChapterId,
        certificate: certificate,
        sequential,
        trainerName: trainerName,
        skills: skills,
        name: course.name,
        courseNonAccad: course.iscourse_non_acad,
        pointsAvaliable: course.points_avaliable,
        pointsRewarded: course.ponits_rewarded,
        totalModuleCompletedCount: course.total_module_completed_count,
        totalModuleCount: course.total_module_count,
        description: course.description,
        chapterImage: course.image,
        loading: false,
        seekable: seekable === 1,
        notes: course.module_notes,
        courseProgress,
        endDate,
        startDate,
        courseDescription,
        courseUId: course.courses[0].u_course_pub_id,
        completionDate: course.completion_date,
        // downloadable: downloadable,
      };
    });
  };

  mkModuleList = (rsp) => {
    let prevStatus = null;
    return rsp.map((r, i) => {
      let contentId = r.video_unit_id.toString();
      let type = "unknown";
      let url = r.content_url;
      let fileUrl = null;
      let status = util.parseModuleStatus(
        r.type
          ? r.type === "survey" && r.sataus === "Completed"
            ? "Completed"
            : r.status.toLowerCase()
          : r.status.toLowerCase(),
        prevStatus
      );
      let pdfContentId = null;
      let subContentId = null;
      let videoAvailabeResolutions = null;
      let availableVideoLinks = null;
      const isLocal = r.is_local; // HACK

      const contentLength = r.content_length || 1; // HACK
      const lastViewedPos = r.last_viewed_pos || 0; // HACK
      const completed = status === config.contentStatusE.COMPLETED;
      const moduleProgress =
        (r.format === "test" && r.status === "Completed") ||
        (r.type === "survey" && r.status === "Completed")
          ? 100
          : r.module_progress;
      const assignmentDate = new Date(r.scheduled_on_timestamp);
      this.setState({ modType: r.format });
      const completionDate = completed
        ? new Date(r.completed_on_timestamp)
        : null;
      // if (1==0) {
      //   contentId = `T:${r.video_unit_id}`
      // }
      if (
        completed &&
        (lastViewedPos < contentLength || r.next_test_question_id !== null)
      ) {
        status = config.contentStatusE.REVIEWING;
      }
      prevStatus = status;
      if (r.format == "test") {
        type = "test";
        // contentId = `T:${r.video_unit_id}`
      } else if (r.format == "survey") {
        type = "survey";
      } else if (r.is_url_only === 1) {
        if (r.browse_url === "__SCORM__") {
          type = "scorm";
          subContentId = r.content_id ? r.content_id.toString() : null;
        } else if (r.browse_url && r.format === "embedded") {
          type = "embedded";
          fileUrl = r.browse_url;
        } else if (r.browse_url && r.format === "html") {
          type = "html";
          fileUrl = r.browse_url;
        } else if(r.video_url && r.format === "html"){
          type = "html";
          url = r.video_url;
        }

        // else if (r.is_html_only === 1 && r.format === "HTMLEditor") {
        //   type = "HTMLEditor";
        //   fileUrl = r.browse_url;
        // }
      } else if (r.is_text_only === 1) {
        if (r.browse_url && r.format === "pdf") {
          type = "pdf";
          fileUrl = r.browse_url;
        } else if (r.content_id !== null) {
          type = "pdf";
          pdfContentId = r.content_id;
        }
      } else if (r.is_ppt === 1 && r.browse_url && r.format === "PPTEditor") {
        type = "PPTEditor";
        fileUrl = r.browse_url;
      } else if (r.is_audio === 1) {
        if (r.video_url && 0 < r.video_url.length) {
          type = "audio";
          fileUrl = r.video_url;
        }
      } else if (r.video_url && 0 < r.video_url.length) {
        type = "video";
        fileUrl = r.video_url;
        videoAvailabeResolutions = r.available_resolutions;
        availableVideoLinks = r.resolution_links;
      } else if (r.is_html_only === 1 && r.format === "HTMLEditor") {
        type = "HTMLEditor";
        fileUrl = r.content_url;
        url = r.content_url;
      }
      const aspectRatio = r.aspect_ratio
        ? r.aspect_ratio
        : type === "audio"
        ? 16 / 9
        : type === "video"
        ? 16 / 9
        : type === "pdf"
        ? 17 / 22
        : type === "PPTEditor"
        ? 17 / 22
        : type === "html"
        ? 16 / 9
        : type === "scorm"
        ? 16 / 9
        : type === "embedded"
        ? 16 / 9
        : type === "HTMLEditor"
        ? 16 / 9
        : 1;

      const testQuestionIds = [...r.test_question_ids];
      const lastAnsweredTestQuestionId = r.last_answered_test_question_id;
      const skipCount = 1 + testQuestionIds.indexOf(lastAnsweredTestQuestionId);
      testQuestionIds.splice(0, skipCount);
      const testQuestions = testQuestionIds.map((id, j) => ({
        id: id.toString(),
        pos: r.test_question_positions[skipCount + j],
        type: r.test_question_types[skipCount + j],
        points: r.test_question_points[skipCount + j],
        question: r.test_questions[skipCount + j],
        questionImage: r.test_question_images[skipCount + j],
      }));
      return {
        contentId,
        status,
        completed,
        assignmentDate,
        completionDate,
        subContentId,
        type,
        url,
        fileUrl,
        isLocal,
        contentSource: r.content_source || "external",
        aspectRatio,
        pdfContentId,
        contentLength,
        lastViewedPos,
        testQuestions,
        lastAnsweredTestQuestionId,
        name: r.module,
        summary: r.description,
        chapterId: r.chapter_id.toString(),
        image: r.still_image,
        maxViewedPos: r.max_viewed_pos || (r.completed_on ? contentLength : 0), // HACK
        nextTestQuestionId: r.next_test_question_id,
        lastViewedTimeSecs: r.last_view_time_secs, // string
        released: r.released,
        sequence: r.sequence,
        playExt: r.new_tab === 1,
        downloadable: r.download_content === 1,
        moduleProgress,
        streamType: r.stream_type,
        nonAcademic: r.is_non_acad === 1,
        video360: r.v360d === 1,
        moduleCompletionStatus: false,
        revisit: false,
        videoAvailabeResolutions,
        availableVideoLinks,
        tot_test_mark: r.total_marks,
      };
    });
  };

  handleSpawnPlayer = (module) => (command) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState((s0) =>
      s0.playerStatus === "dismissed"
        ? {
            playerStatus: "initializing",
            prevPlayerModule: null,
            playerCommand: command,
            playerModule: module,
            selectedModuleKey: module.contentId,
            currentModuleKey: module.contentId,
          }
        : s0.playerModule.contentId !== module.contentId
        ? {
            playerStatus: "respawning",
            prevPlayerModule: s0.playerModule,
            nextPlayerCommand: command,
            nextPlayerModule: module,
            selectedModuleKey: module.contentId,
            currentModuleKey: module.contentId,
          }
        : null
    );

    if (module.type === "html" && module.playExt) {
      if (module.assignmentDate >= new Date()) {
        return null;
      }
      this.props.ctx_window.confirm({
        message: this.props.ctx_labels.singular.thisActionNewTab,
        yesText: this.props.ctx_labels.singular.proceed,
        noText: this.props.ctx_labels.singular.cancel,
        callback: this.handleNewTab(module),
      });
    }
  };

  handleNewTab = (m) => (ok) => {
    // this.props.onExtPlayConfirm(ok);
    if (ok) {
      var win = window.open(m.fileUrl ? m.fileUrl : config.ileUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
      }
    }
  };

  handlePlayerStateChange = (playerState) => {
    this.setState({ playerState });
    switch (playerState) {
      case "playing":
        this.setState((s0) => ({
          playerCommand: null,
          playerCreditsEarned: null,
        }));
        break;
      case "advancing":
        this.setState({ prevPlayerModule: this.state.playerModule });
        this.getSubjectPublishHier(true);
        // this.setPlayerModule(this.state.modules)

        break;
      case "concluded":
        this.setState({ playerStatus: "dismissed" });
        break;
      case "error":
        this.handlePlayerSystemFailure();
        break;
      default:
        break;
    }
  };

  // Trainee milestone call for module
  updateTraineeMilestones = (prevState) => {
    let contentId = prevState
      ? prevState.playerModule.contentId
      : this.state.playerModule.contentId;
    let name = prevState
      ? prevState.playerModule.name
      : this.state.playerModule.name; // To handle cases - navigating back after consumption, lastmodule status
    let progress;
    let revisit = prevState
      ? prevState.playerModule.revisit
      : this.state.playerModule.revisit;
    Object.values(this.state.modules).forEach((s) => {
      if (s.contentId === contentId) {
        progress = s.moduleProgress === 100 ? "completed" : "InProgress";
      }
    });
    if (revisit === false) {
      const mileStone = {
        event_id: parseInt(contentId),
        event_subject: "Module",
        event_status: progress,
        event_name: name,
        event_date: new Date().toISOString(),
        ple_score: +this.props.ctx_user.user.credits,
      };
      (!this.props.playerOnly && this.props.ctx_analytics.update("traineeMilestones")(mileStone));
    }
  };

  // Trainee milestone call for Course
  updateTraineeMilestonesCourse = () => {
    const data = this.state.data;
    Object.values(data).forEach((s) => {
      Object.values(s.courses).forEach((o) => {
        let statusCount = this.state.modules.filter(
          (s) => s.revisit === true
        ).length; // to handle case: revisiting the course after consumption
        if (statusCount !== this.state.totalModuleCount) {
          const mileStone = {
            event_id: parseInt(o.course_id),
            event_subject: "Course",
            event_status:
              this.state.totalModuleCount ===
              this.state.totalModuleCompletedCount
                ? "Completed"
                : "inProgress",
            event_name: o.course_name,
            event_date: new Date().toISOString(),
            ple_score: +this.props.ctx_user.user.credits,
          };
          (!this.props.playerOnly && this.props.ctx_analytics.update("traineeMilestones")(mileStone));
        }
      });
    });
  };

  // Trainee milestone call for Chapter
  updateTraineeMilestonesChapter = (prevState) => {
    let chapterId = prevState
      ? prevState.playerModule.chapterId
      : this.state.playerModule.chapterId;
    Object.values(this.state.contentHier).forEach((s) => {
      if (s.id === chapterId) {
        let chapterName = s.name;
        let chapterStatus;
        let totalModuleCount = s.modules.length;
        let moduleProgressCount = s.modules.filter(
          (s) => s.moduleProgress === 100
        ).length;
        let statusCount = s.modules.filter((s) => s.revisit === true).length;
        if (totalModuleCount === moduleProgressCount) {
          chapterStatus = "completed";
        } else {
          chapterStatus = "Inprogress";
        }
        if (statusCount !== totalModuleCount) {
          // to handle case: revisiting after consuming chapter
          const mileStone = {
            event_id: parseInt(chapterId),
            event_subject: "Chapter",
            event_status: chapterStatus,
            event_name: chapterName,
            event_date: new Date().toISOString(),
            ple_score: +this.props.ctx_user.user.credits,
          };
          (!this.props.playerOnly && this.props.ctx_analytics.update("traineeMilestones")(mileStone));
        }
      }
    });
  };

  updateModuleProgress = (positions, isEnd = false) => {
    const args = { ...positions };
    const moduleDone =
      args.nextTestQuestionId == null &&
      (args.lastViewedPos === this.state.playerModule.contentLength || isEnd);
    // if (moduleDone) {
    //   args.inProgress = 0;
    //   delete args.lastAnsweredTestQuestionId;
    // }
    if (moduleDone) {
      args.inProgress = 0;
      delete args.lastAnsweredTestQuestionId;
    }
    this.persistModuleProgress(args);
    if (moduleDone) {
      this.recordModuleCompletion();
    }
  };
  persistModuleProgress = ({ timeId = "1", inProgress = 1, ...restArgs }) => {
    if (this.state.playerModule?.type === "survey") {
      return true;
    }

    const csListCurriculumID = !this.props.playerOnly
      ? this.props.location.subjectData.course_list?.every((obj) =>
          obj.hasOwnProperty("curriculum_id")
        )
      : [];
    const cmd =
      this.state.playerModule.type === "test"
        ? "insert_update_trainee_test_time"
        : "insert_update_trainee_module_time";

    const multiCurriculumId = !this.props.playerOnly
      ? this.props.location.subjectData.is_multicourse === 1 ||
        csListCurriculumID
        ? this.props.location.subjectData.course_list.filter(
            (item) =>
              String(item.course_id) ===
              String(this.props.location.subjectData.course_id)
          )
        : []
      : [];
    this.props.ctx_api
      .privateRequestRow({
        cmd: cmd,
        args: {
          timeId,
          inProgress,
          status: 1,
          courseId: !this.props.playerOnly
            ? String(
                this.props.location.subjectData.course_id ||
                  this.props.location.subjectData.courseId
              )
            : this.props.subject.course_id.toString(), //in2vate courseId need to handdle
          memberId: !this.props.playerOnly
            ? this.props.ctx_user.user.id
            : this.props.memberId,
          videoUnitId: this.state.playerModule.contentId,
          curriculumId: !this.props.playerOnly
            ? this.props.location.subjectData.is_multicourse === 1 ||
              csListCurriculumID
              ? String(multiCurriculumId[0].curriculum_id)
              : this.props.location.subjectData.curriculum_id.toString() ||
                this.props.location.subjectData.curriculumId.toString()
            : this.props.subject.curriculum_id.toString(),
          coursePublishId: !this.props.playerOnly
            ? this.props.location.subjectData.coursePublishId ||
              this.state.coursePublishId.toString()
            : this.props.subject.course_publish_id.toString(),
          ...restArgs,
        },
      })
      .then(this.handleUpdateModuleProgressResponse)
      .catch(this.handlePlayerSystemFailure);
  };

  handleUpdateModuleProgressResponse = ({ rsp }) => {
    if (rsp.StatusValue !== 1) {
      this.handlePlayerSystemFailure();
    }
  };

  addActivityEvents = () => {
    this.activityEvents.forEach((event) => {
      window.addEventListener(event, this.handleTimerRestart, false);
    });
  };

  removeActivityEvents = () => {
    this.activityEvents.forEach((event) => {
      window.removeEventListener(event, this.handleTimerRestart, false);
    });
  };
  handleTimerRestart = () => {
    if (this.state.isIdle) {
      this.setState({ isIdle: false });
    }

    clearTimeout(this.idleTrigger);

    this.idleTrigger = this.idleTimeout();
  };
  handleIdleStateChange = () => {
    this.setState({ isIdle: true });
    // this.handleSummary();
  };

  handlePause = (isPaused) => {
    this.setState({ isPaused: isPaused });
  };

  handleSummary = (prevModule = null) => {
    const { playerModule, playerState, isIdle, isPaused } = this.state;
    const deltaModule = prevModule || { ...playerModule };
    var current = new Date();
    let chapter = {
      id: null,
      name: "",
    };
    let moduleEmpty = Object.keys(deltaModule).length === 0;

    if (
      this.props.location.subjectData &&
      this.props.location.subjectData.type === "L" &&
      !moduleEmpty
    ) {
      let listChapter = this.state.contentHier.filter(
        (s1) => deltaModule.chapterId === s1.id
      );
      chapter.name = listChapter[0].name;
      chapter.id = parseInt(listChapter[0].id);
    } else {
      chapter.name = this.state.name;
      chapter.id = parseInt(this.state.currentChapterId);
    }
    const classId = parseInt(
      (this.props.location.subjectData.class_id &&
        this.props.location.subjectData.class_id) ||
        this.props.subjectData?.classId
    );
    const summaryData = {
      event_id: !moduleEmpty ? parseInt(deltaModule.contentId) : null,
      event_name: !moduleEmpty ? deltaModule.name : "",
      eventactive_time:
        ((deltaModule.type === "video" || deltaModule.type === "audio") &&
          playerState === "playing" &&
          !isPaused) ||
        ((deltaModule.type === "pdf" ||
          deltaModule.type === "html" ||
          deltaModule.type === "embedded" ||
          deltaModule.type === "HTMLEditor") &&
          playerState === "playing" &&
          !isIdle) ||
        (deltaModule.type === "scorm" && playerState === "playing") ||
        (deltaModule.type === "test" && playerState === "playing")
          ? Math.round((current.getTime() - this.initialTime.getTime()) / 1000)
          : 0,
      eventstart_at: this.state.eventStartat,
      eventend_at: new Date(),
      intent: "study",
      details: {
        course_id: parseInt(
          !this.props.playerOnly
            ? this.props.location.subjectData.course_id
            : this.props.subject.course_id
        ),
        course_name: !this.props.playerOnly
          ? this.props.location.subjectData.course_name ||
            this.props.location.subjectData.name
          : this.props.subject.name || "",
        course_publish_id: parseInt(this.state.coursePublishId),
        course_publish_name: !this.props.playerOnly
          ? this.props.location.subjectData.course_name ||
            this.props.location.subjectData.name
          : this.props.subject.course_name || "",
        topic_id: chapter.id,
        topic_name: chapter.name,
        class_id: classId,
      },
    };
    this.initialTime = current;
    this.updateAnalytics(summaryData);
    // this.setState({
    //   summary:[summaryData]
    // })
  };

  updateAnalytics = (r) => {
    this.props.ctx_analytics.update("player")({ ...r });
    // var d = new Date();
  };

  handleCsListCurriculumID = () => {
    return (
      this.props.location.subjectData.curriculum_id !== undefined ||
      this.props.location.subjectData.curriculum_id !== null
    );
  };
  recordModuleCompletion = async () => {
    if (this.state.playerModule.completed) {
      return;
    }
    if (this.state.playerModule.nonAcademic) {
      return;
    }
    const csListCurriculumID = this.handleCsListCurriculumID();
    const multiCurriculumId = !this.props.playerOnly
      ? this.props.location.subjectData.is_multicourse === 1
        ? this.props.location.subjectData.course_list.filter(
            (item) =>
              String(item.course_id) ===
              String(this.props.location.subjectData.course_id)
          )
        : []
      : [];
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: "insert_update_trainee_points",
      args: {
        courseId: !this.props.playerOnly
          ? String(this.props.location.subjectData.course_id)
          : this.props.subject.course_id.toString(), //in2vate courseId need to handdle
        memberId: !this.props.playerOnly
          ? this.props.ctx_user.user.id
          : this.props.memberId,
        curriculumId: !this.props.playerOnly
          ? this.props.location.subjectData.is_multicourse === 1 ||
            !csListCurriculumID
            ? String(multiCurriculumId[0].curriculum_id)
            : this.props.location.subjectData.curriculum_id ||
              this.props.location.subjectData.curriculumId
          : this.props.subject.curriculum_id.toString(),
        coursePublishId: this.state.coursePublishId,
        // configId: this.props.chapter.configId,
        type: this.state.playerModule.type === "test" ? "T" : "M",
        videoUnitId: this.state.playerModule.contentId,
        classId: this.props.playerOnly
          ? this.props.classId
          : this.props.ctx_user.user.classId,
        chapterId:
          this.state.currentChapterId || this.state.playerModule.chapterId,
        acadYearId: this.props.playerOnly
          ? this.props.accadamicYearId
          : this.props.ctx_user.user.acadYearId,
      },
    });

    if (rsp.statusvalue === 1) {
      this.handleRecordModuleCompletionResponse(rsp);
    } else {
      this.handlePlayerSystemFailure();
    }
    // .then(this.handleRecordModuleCompletionResponse)
    // .catch(() => this.handlePlayerSystemFailure('insert_update_trainee_points'));
  };

  handleRecordModuleCompletionResponse = (rsp) => {
    if (rsp.statusvalue !== 1) {
      this.handlePlayerSystemFailure();
    } else {
      this.setState({
        playerCreditsEarned: rsp.credits_earned,
        completedModules: rsp.modules,
      });
      // const balanceStr = rsp.credits_balance;
      // const userLevel = rsp.reward_level;
      // if (balanceStr) {
      //   // this.props.onCreditsChange(util.toInt(balanceStr), userLevel);
      // }
      if (!this.props.playerOnly) {
        this.props.location.subjectData.course_publish_id &&
          this.props.refreshCurriculumList(this.state.coursePublishId);
      } else {
        this.props.subject.course_publish_id &&
          this.props.refreshCurriculumList(this.state.coursePublishId);
      }
      const campusList = this.props.ctx_user.user.campusList;
      const organizationId = this.props.ctx_user.user.organizationId;
      const roleId = this.props.ctx_user.user.roleId;
      const campus = campusList.find(
        (d) => d.campus_role_id == `${organizationId} : ${roleId}`
      );
      campus.total_points = rsp.credits_balance;
      this.props.ctx_user.update((user) => ({
        ...user,
        credits: rsp.credits_balance,
      }));
    }
  };

  handlePlayerSystemFailure = () => {
    this.setState({ playerStatus: "dismissed" });
  };

  handleExtPlayConfirm = (confirmation) => {
    this.setState({ extPlayConfirm: confirmation });
  };

  handlePlayerUnmounted = () => {
    this.getSubjectPublishHier();
  };

  getModuleByContentId = (contentId) => {
    let match = null;
    this.state.modules.forEach((m) => {
      if (!match && m.contentId === contentId) {
        match = m;
      }
    });
    return match;
  };
  // handleCertificateDownload = (e) => {
  //   e.stopPropagation();
  //   this.props.ctx_api
  //     .privateRequest({
  //       cmd: "get_report_course_certificate",
  //       args: {
  //         studentid: this.props.ctx_user.user.id,
  //         coursepublishid:this.state.coursePublishId,
  //       },
  //     })
  //     .then(( {rsp} ) => {
  //       let downloadUrl = this.renderReportLink(rsp);
  //     })
  //     .catch(this.handleFetchFailure);
  //   // .finally(this.finalizeExport);
  // };
  //  renderReportLink = async (rsp) => {
  //   const link = document.createElement("a");
  //   link.id = "render-link";
  //   link.href = `data:application/octet-stream;base64,${rsp.data}`;
  //   link.download = rsp.name + "." + "pdf";
  //   link.click();
  // };

  renderNextModulePlayer = (sModule) => {
    if (!sModule) {
      if (this.state.modules[0]) {
        sModule = this.state.modules[0];
      } else {
        return null;
      }
    }
    const expired = this.state.endDate < new Date();
    const status = sModule.status;
    const command = expired
      ? "expired"
      : status === config.contentStatusE.UNAVAILABLE
      ? "non"
      : status === config.contentStatusE.COMPLETED
      ? "restart"
      : status === config.contentStatusE.REVIEWING
      ? "resume"
      : status === config.contentStatusE.IN_PROGRESS
      ? "resume"
      : "play";
    let subTitle;
    switch (command) {
      case "expired":
        subTitle = "";
        break;
      case "play":
        subTitle = `${this.props.ctx_labels.singular.start} ${this.props.ctx_labels.singular.module}...`;
        break;
      case "resume":
        subTitle = `${this.props.ctx_labels.singular.resume} ${this.props.ctx_labels.singular.module}...`;
        break;
      case "restart":
      default:
        subTitle = `${this.props.ctx_labels.singular.restart} ${this.props.ctx_labels.singular.module}...`;
        break;
    }
    const title = expired
      ? `${
          this.props.ctx_labels.singular.this
        } ${this.props.ctx_labels.singular.course.toLowerCase()} ${
          this.props.ctx_labels.singular.hasExp
        }`
      : null;
    const buttonIcon = command === "restart" ? faRedoAlt : faPlay;
    let isConsumed = [];
    let notConsumed = [];
    if (!this.props.playerOnly) {
      if (["C", "Card"].includes(this.props.location.subjectData.type)) {
        notConsumed = `${this.props.ctx_labels.singular.toContinueCompletemod}`;
        isConsumed = this.state.data
          ? this.state.data[0].course_chapter_details[0].l_is_consumed
          : true;
      }
    }
    const screenSize = window.screen.width;
    const mobconsumed = isConsumed ? "player_mob" : "isplayer_mob";
    const tabConsumed = isConsumed ? "player" : "isplayer";
    const StyledPlayer = sModule.image
      ? StyledPlayerMessages
      : StyledPlayerMessage;

    return (
      <>
        <StyledPlayer
          className={screenSize <= 821 ? mobconsumed : tabConsumed}
          style={{ height: screenSize > 822 ? "528px" : "15rem" }}
        >
          <div
            className="background"
            style={{
              padding: "15.25%",
              height: "100%",
              backgroundImage: `url(${sModule.image})`,
            }}
          >
            <div className={css.playernomodulemessagesubtitle}>
              {isConsumed ? subTitle : ""}
            </div>
            <div
              className={classNames(
                screenSize > 822
                  ? css.playernomodulemessageemph
                  : css.playernomodulemessageemph_mob
              )}
            >
              {!isConsumed ? notConsumed : title}
            </div>
            <div className={css.playernomodulebutton}>
              {!expired && (
                <div
                  className={css.playernomico}
                  onClick={this.handlePlayerDefaultModuleClick(sModule)(
                    command
                  )}
                >
                  <FontAwesomeIcon icon={buttonIcon} />
                </div>
              )}
            </div>
          </div>
        </StyledPlayer>
      </>
    );
  };

  renderPlayerUnavailable = (m) => {
    const moment = require("moment");

    const inputDate = m.assignmentDate;
    const formattedDate = moment(inputDate).format("DD MMM, YYYY hh:mm A");

    const title =
      `${this.props.ctx_labels.singular.module} will be available on ` +
      `${formattedDate}`;
    const screenSize = window.screen.width;
    // const subTitle = 'Please try again at a later date';
    return (
      <StyledPlayerMessage
        className={classNames(
          screenSize > 822
            ? "playernomodulemessage_full"
            : css.playernomodulemessage_mob
        )}
      >
        <div
          className={classNames(
            screenSize > 822
              ? css.playernomodulemessageemph
              : css.playernomodulemessageemph_mob
          )}
        >
          {m.name}
        </div>
        <div className={css.playererrordetails}>
          <div>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className={css.playererrormessage}>
            <div className={css.playererrormsgtitle}>{title}</div>
            {/* <div className="player-error-msg-subtitle">{subTitle}</div> */}
          </div>
        </div>
      </StyledPlayerMessage>
    );
  };

  renderPlayerError = (m, source) => {
    const title =
      source === "progressError"
        ? `${this.props.ctx_labels.singular.progressUpdtn}` //"Progress updation failed"
        : `${this.props.ctx_labels.singular.moduleCntntNotAvlbl}!`; //content not available!;
    const subTitle =
      source === "progressError"
        ? `${this.props.ctx_labels.singular.pleaseTryAgn}` //"Please try again"
        : `${this.props.ctx_labels.singular.failedToFetch}.`; //"Failed to fetch content, please try again later.";\
    const screenSize = window.screen.width;
    return (
      <StyledPlayerMessage
        className={classNames(
          screenSize > 822
            ? css.playernomodulemessage_full
            : css.playernomodulemessage_mob
        )}
      >
        <div
          className={classNames(
            screenSize > 822
              ? css.playernomodulemessageemph
              : css.playernomodulemessageemph_mob
          )}
        >
          {m.name}
        </div>
        <div className={css.playererrordetails}>
          <div className={css.playererroricon}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className={css.playererrormessage}>
            <div className={css.playererrormsgtitle}>{title}</div>
            <div className={css.playernomodulemessagesubtitle}>{subTitle}</div>
          </div>
        </div>
      </StyledPlayerMessage>
    );
  };

  renderCardViewContent = () => {
    const screenSize = window.screen.width;
    return (
      <>
        <Modules
          modules={this.state.modules}
          loading={this.state.loading}
          selectedKey={this.state.selectedModuleKey}
          onSelect={this.props.onSelectModule}
          currentKey={this.state.currentModuleKey}
          onPlay={this.handleSpawnPlayer}
          playerModule={this.state.playerModule}
          playInOrder={this.state.sequential}
          onExtPlayConfirm={this.handleExtPlayConfirm}
          endDate={this.state.endDate}
          // cardIndex={this.props.location.subjectData.cardIndex+1}
          viewType={
            !this.props.playerOnly
              ? this.props.location.subjectData.type
              : this.props.subject.type
          }
          data={this.state.data}
          fromChapterInner
        />
      </>
    );
  };

  renderListViewContent = () => {
    const screenSize = window.screen.width;
    return (
      <>
        <div className={screenSize > 822 && cmCss.title_full_list}>
          <ChapterList
            eventStart={this.state.eventStartat}
            chapterHier={this.state.contentHier}
            chapterModules={this.state.modules}
            loading={this.state.loading}
            selectedKey={this.state.selectedModuleKey}
            onSelect={this.props.onSelectModule}
            currentKey={this.state.currentModuleKey}
            onPlay={this.handleSpawnPlayer}
            playerModule={this.state.playerModule}
            playInOrder={this.state.sequential}
            onExtPlayConfirm={this.handleExtPlayConfirm}
            endDate={this.state.endDate}
            viewType={
              !this.props.playerOnly
                ? this.props.location.subjectData.type
                : this.props.subject.type
            }
            fromChapterInner
          />
        </div>
      </>
    );
  };

  renderContentList = () => {
    const screenSize = window.screen.width;
    return (
      this.state.isShowSidebar && (
        <div className={classNames(css.sidebar, css.playercards)}>
          {/* <div className={css['scroll-content']}> */}
          {!this.props.grouped ? (
            <div className={screenSize > 822 && cmCss.title_full}>
              {this.renderCardViewContent()}
            </div>
          ) : (
            this.renderListViewContent()
          )}
        </div>
      )
    );
  };

  handleViewMore = () => {
    this.setState({ viewMoreOpen: !this.state.viewMoreOpen }, () => {
      if (this.state.viewMoreOpen && this.targetRef.current) {
        this.targetRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });

        // Focus on the target div
        this.targetRef.current.focus();
      }
    });
  };

  renderBackLink = () => {
    const { type, course_publish_id, course_name, curriculum_id, course_id } =
      this.props.location.subjectData;
    const updatedSubjectData = { ...this.props.location.subjectData };
    updatedSubjectData.course_list = updatedSubjectData.course_list?.map(
      (course) => {
        if (course.course_id === updatedSubjectData.course_id) {
          return { ...course, confirm: 1 };
        }
        return course;
      }
    );
    return this.props.location.subjectData.is_multicourse === 1 ||
      this.props.location.subjectData.fromMultiCourse === true ? (
      <BackLink
        fromChapterInner
        to={{
          pathname: "/variants",
          subjectData: {
            class_id: this.props.location.subjectData.class_id,
            classname: this.props.location.subjectData.classname,
            classshort_name: this.props.location.subjectData.classshort_name,
            course_id: this.props.location.subjectData.course_id,
            course_name: this.props.location.subjectData.course_name,
            course_publish_id:
              this.props.location.subjectData.course_publish_id,
            course_short_name:
              this.props.location.subjectData.course_short_name,
            curriculum_id: this.props.location.subjectData.curriculum_id,
            image: this.props.location.subjectData.image,
            master_curriculum_id:
              this.props.location.subjectData.master_curriculum_id,
            name: this.props.location.subjectData.name,
            shared_by: this.props.location.subjectData.shared_by,
            standard_id: this.props.location.subjectData.standard_id,
            type: this.props.location.subjectData.type,
            u_course_publish_id:
              this.props.location.subjectData.u_course_publish_id,
            course_list: updatedSubjectData.course_list,
            isOpen: this.props.location.subjectData.isOpen,
            course_publish_id:
              this.props.location.subjectData.course_publish_id,
          },
        }}
      />
    ) : (
      <BackLink
        fromChapterInner
        to={{
          pathname:
            type === "L" || type === "List"
              ? this.props.location.subjectData.fromSubjectList === true
                ? config.ROUTES.subjectList
                : "/"
              : config.ROUTES.traineeCurriculum,
          subjectData: (type === "C" || type === "Card") && {
            coursePublishId: course_publish_id,
            courseName: course_name,
            curriculumId: curriculum_id,
            courseId: course_id,
            type: type,
            fromSubjectList:
              this.props.location.subjectData.fromSubjectList === true
                ? true
                : false,
            class_id: this.props.location.subjectData.class_id,
          },
        }}
      />
    );
  };

  renderHeader = () => {
    let cardChapterDetails = [];
    cardChapterDetails = !this.props.playerOnly
      ? this.props.location.subjectData.type === "C"
        ? Array.isArray(this.props.ChapterProgress.course_chapter_details) &&
          this.props.ChapterProgress.course_chapter_details.filter((item) => {
            if (item.id == this.props.location.subjectData.chapterId) {
              return item;
            }
          })
        : []
      : this.props.subject.type === "C"
      ? Array.isArray(this.props.ChapterProgress.course_chapter_details) &&
        this.props.ChapterProgress.course_chapter_details.filter((item) => {
          if (item.id == this.props.subject.chapterId) {
            return item;
          }
        })
      : [];

    const progress = !this.props.playerOnly
      ? this.props.location.subjectData.type === "C"
        ? cardChapterDetails.length > 0
          ? cardChapterDetails[0].chapter_progress
          : this.props.location.subjectData.progress
        : this.state.courseProgress
      : this.props.subject.type === "C"
      ? this.props.subject.progress
      : this.state.courseProgress;
    // Math.round(
    //   (this.state.totalModuleCompletedCount / this.state.totalModuleCount) * 100
    // );
    const sModule = this.state.selectedModuleKey
      ? this.getModuleByContentId(this.state.selectedModuleKey)
      : this.state.modules[0];
    const course = !this.props.playerOnly
      ? this.props.location.subjectData.type === "C"
        ? this.props.location.subjectData.name
        : this.props.location.subjectData.course_name
      : this.props.subject.type === "L"
      ? this.props.subject.course_name
      : this.props.subject.name;
    const icons =
      this.props.ctx_user.user.enableCourseRating ||
      this.props.ctx_user.user.enableCourseShare ||
      this.state.playerModule?.downloadable;
    const scormHeaderTab =
      (this.state.prevPlayerModule?.type === "html" &&
        this.state.playerState === "ready") ||
      (this.state.prevPlayerModule?.type === "test" &&
        this.state.playerState === "ready") ||
      (this.state.playerModule?.type === "embedded" &&
        this.props.location.subjectData.is_multicourse === 1 &&
        this.state.playerState === "ready")
        ? cmCss.header_message_variant
        : this.state.playerModule?.type === "scorm" &&
          this.state.playerState === "playing"
        ? cmCss.header_scorm_tab
        : (this.state.playerModule?.type === "html" ||
            this.state.playerModule?.type === "embedded" ||
            this.state.playerModule?.type === "test") &&
          this.state.playerState === "ready"
        ? cmCss.header_message
        : this.state.playerModule?.type === "test" &&
          (this.props.location.subjectData.type !== "C" ||
            this.props.location.subjectData.type !== "Card")
        ? cmCss.header_html
        : this.state.playerModule?.type === "html"
        ? cmCss.header_web
        : this.state.playerModule?.type === "survey" && this.state.playerModule?.completed === true
        ? cmCss.header_survey_cmplte
        : cmCss.header;
    const scormHeaderMob =
      this.state.playerModule?.type === "scorm"
        ? //
          cmCss.header_scorm_mob
        : this.state.playerModule?.type === "embedded"
        ? cmCss.header_mob1
        : (this.state.playerModule?.type === "html" &&
            this.state.playerState === "playing") ||
          (this.state.playerModule?.type === "html" &&
            this.state.playerState === "concluding") ||
          (this.state.playerModule?.type === "html" &&
            this.state.playerState === "ready") ||
          (this.state.playerModule?.type === "html" &&
            this.state.playerCommand === null) ||
          (this.state.prevPlayerModule?.type === "html" &&
            this.state.playerModule?.type === "html" &&
            this.state.playerState === "ready") ||
          (this.state.prevPlayerModule?.type === "html" &&
            this.state.playerModule?.type !== "html" &&
            this.state.playerState === "ready")
        ? cmCss.header_mob_html
        : (this.state.playerModule?.type === "HTMLEditor" &&
            this.state.playerState === "playing") ||
          (this.state.playerModule?.type === "HTMLEditor" &&
            this.state.playerState === "concluding") ||
          (this.state.playerModule?.type === "HTMLEditor" &&
            this.state.playerCommand === null) ||
          (this.state.prevPlayerModule?.type === "HTMLEditor" &&
            this.state.playerModule?.type === "HTMLEditor" &&
            this.state.playerState === "ready") ||
          (this.state.prevPlayerModule?.type === "HTMLEditor" &&
            this.state.playerModule?.type !== "HTMLEditor" &&
            this.state.playerState === "ready")
        ? cmCss.header_mob_text
        : (this.state.playerModule?.type === "PPTEditor" &&
            this.state.playerState === "playing") ||
          (this.state.playerModule?.type === "PPTEditor" &&
            this.state.playerState === "concluding") ||
          (this.state.playerModule?.type === "PPTEditor" &&
            this.state.playerCommand === null) ||
          (this.state.prevPlayerModule?.type === "PPTEditor" &&
            this.state.playerModule?.type === "PPTEditor" &&
            this.state.playerState === "ready") ||
          (this.state.prevPlayerModule?.type === "PPTEditor" &&
            this.state.playerModule?.type !== "PPTEditor" &&
            this.state.playerState === "ready")
        ? cmCss.header_mob_ppt
        : this.state.playerModule?.type === "test"
        ? this.state.playerState === "ready" ||
          this.state.playerModule?.completed
          ? cmCss.header_mob_test1
          : cmCss.header_mob_test
        : this.state.playerModule?.type === "HTMLEditor" &&
          this.state.playerState === "ready"
        ? cmCss.header_mob1
        : cmCss.header_mob;

    return (
      <>
        <MediaQuery maxDeviceWidth={821}>
          <div className={scormHeaderMob}>
            <Row>
              <Col lg={8}>
                <div
                  className={cmCss.title_mob}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  {!this.props.playerOnly && this.renderBackLink()}
                  {sModule ? sModule.name : ""}
                </div>
                {icons && (
                  <div className="icon-style-full">
                    {/* {
                    this.state.data[0].certificate === 1 && this.state.courseProgress === 100 &&
                    <OverlayTooltip tip={"Download Certificate"} placement="top">
                      <IconCourseCertificate
                        width="20"
                        height="20"
                        onClick={(e) => this.handleCertificateDownload(e)}
                        style={{ marginLeft: "8px", marginTop: "2px", cursor:"pointer" }}
                      />
                    </OverlayTooltip>
                  } */}
                    {this.props.ctx_user.user.enableCourseRating && (
                      <OverlayTooltip tip={"rate"} placement="top">
                        <Rate data={this.state.data[0]} />
                      </OverlayTooltip>
                    )}

                    {this.props.ctx_user.user.enableCourseShare && (
                      <OverlayTooltip tip={"Share"} placement="top">
                        <IconShare
                          width="20"
                          height="20"
                          onClick={() =>
                            this.setState((s0) => ({
                              isShowCopy: !s0.isShowCopy,
                            }))
                          }
                          className="share"
                        />
                      </OverlayTooltip>
                    )}

                    {this.state.playerModule?.type === "pdf" &&
                      this.state.playerModule.downloadable && (
                        <OverlayTooltip
                          tip={"download_content"}
                          placement="top"
                        >
                          <IconDownloadNew
                            width="18"
                            height="18"
                            style={{ marginLeft: "7px", marginTop: "3px" }}
                            onClick={() =>
                              this.handlePdfDownload(
                                this.state.playerModule.contentId
                              )
                            }
                          />
                        </OverlayTooltip>
                      )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={822}>
          <div className={scormHeaderTab}>
            <Row>
              <Col lg={8}>
                {/* <div className={`${cmCss.category} text-primary`}>{course}</div> */}
                <div className={`${cmCss.title} text-color2`}>
                  {sModule ? sModule.name : ""}
                </div>
              </Col>
              <Col lg={4}>
                <div
                  style={{
                    marginLeft: "10px",
                    paddingBlock: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* {
                    this.state.data[0].certificate === 1 && this.state.courseProgress === 100 &&
                    <OverlayTooltip tip={"Download Certificate"} placement="top">
                      <IconCourseCertificate
                        width="20"
                        height="20"
                        onClick={(e) => this.handleCertificateDownload(e)}
                        style={{ marginLeft: "8px", marginTop: "2px", cursor:"pointer" }}
                      />
                    </OverlayTooltip>
                  } */}
                  {this.props.ctx_user.user.enableCourseRating && (
                    <Rate data={this.state.data[0]} />
                  )}
                  {this.props.ctx_user.user.enableCourseShare && (
                    <OverlayTooltip tip={"Share the course"} placement="top">
                      <IconShare
                        width="20"
                        height="20"
                        stroke={({ theme }) => theme.color3}
                        fill={({ theme }) => theme.color3}
                        onClick={() =>
                          this.setState((s0) => {
                            return { isShowCopy: !s0.isShowCopy };
                          })
                        }
                        style={{
                          marginLeft: "8px",
                          marginTop: "3.5px",
                          cursor: "pointer",
                        }}
                      />
                    </OverlayTooltip>
                  )}
                  {this.state.playerModule?.type === "pdf" &&
                    this.state.playerModule.downloadable && (
                      <OverlayTooltip tip={"download content"} placement="top">
                        <IconDownloadNew
                          width="14"
                          height="14"
                          style={{
                            marginLeft: "10px",
                            marginTop: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.handlePdfDownload(
                              this.state.playerModule.contentId
                            )
                          }
                        />
                      </OverlayTooltip>
                    )}
                  {this.state.viewMoreOpen ? (
                    <IconChevronUpNew
                      width="13"
                      height="13"
                      color="black"
                      style={{
                        marginLeft: "10px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                      onClick={this.handleViewMore}
                    />
                  ) : (
                    <IconChevronDownNew
                      width="13"
                      height="13"
                      color="black"
                      style={{
                        marginLeft: "10px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                      onClick={this.handleViewMore}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </MediaQuery>
      </>
    );
  };

  renderName = (name) => {
    return (
      <div>
        <MediaQuery maxDeviceWidth={700}>
          {name.length > 25 ? (
            <div className="test-player-name-mob">
              <IconAssessmentPlayer width="60" height="71" />{" "}
              <OverlayTooltip placement="top" tip={name}>
                <span>{name.slice(0, 25) + "..."}</span>
              </OverlayTooltip>
            </div>
          ) : (
            <div className="test-player-name-mob">
              <IconAssessmentPlayer width="80" height="71" /> {name}
            </div>
          )}
        </MediaQuery>

        <MediaQuery minDeviceWidth={701} maxDeviceWidth={1023}>
          {name.length > 30 ? (
            <div className="test-player-name-mob">
              <IconAssessmentPlayer width="80" height="71" />{" "}
              <OverlayTooltip placement="top" tip={name}>
                <span>{name.slice(0, 30) + "..."}</span>
              </OverlayTooltip>
            </div>
          ) : (
            <div className="test-player-name-mob">
              <IconAssessmentPlayer width="80" height="71" /> {name}
            </div>
          )}
        </MediaQuery>

        <MediaQuery minDeviceWidth={1024}>
          {name.length > 30 && this.state.isShowSidebar ? (
            <div className="test-player-name">
              <IconAssessmentPlayer />{" "}
              <OverlayTooltip placement="top" tip={name}>
                <span>{name.slice(0, 30) + "..."}</span>
              </OverlayTooltip>
            </div>
          ) : (
            <div className="test-player-name">
              <IconAssessmentPlayer /> {name}
            </div>
          )}
        </MediaQuery>
      </div>
    );
  };
  renderTest = () => {
    const m = this.state.playerModule;
    const screenSize = window.screen.width;
    const navigateToAnotherComponent = () => {
      this.props.history.push({
        pathname: config.ROUTES.assessmentsLanding,
        state: {
          assessmentData: {
            id: m.contentId,
            name: m.name,
            fromChapterInner: true,
          },
          subjectData: {
            ...this.props.location.subjectData,
            chapterId:
              this.state.currentChapterId ||
              this.props.location.subjectData.chapterId,
            videoUnitId: this.state.playerModule.contentId,
            acadYearId: this.props.ctx_user.user.acadYearId,
            memberId: this.props.memberId || this.props.ctx_user.user.id,
            curriculum_id: this.state.data[0].curriculum_id,
          },
        },
      });
    };

    return (
      <>
        <MediaQuery maxDeviceWidth={1023}>
          <StyledTestPlayerMob id="fullScreenTest">
            <div className="test-player-mark-mob">
              <StyledTotalMarkMob>Total Mark : </StyledTotalMarkMob> &nbsp;
              <StyledTestMarkMob>{m.tot_test_mark}</StyledTestMarkMob>
            </div>
            {this.renderName(m.name)}
            <div className="test-player-button-mob">
              <div className="test-player-click">
                {" "}
                <IconPlayerMark width="15" height="23" /> &nbsp;&nbsp;{" "}
                {m.moduleProgress >= 100
                  ? "click here to view summary"
                  : "click here to start the assessment"}
              </div>

              <div className="test-start-button">
                <Button
                  multiButton
                  variant="none"
                  onClick={navigateToAnotherComponent}
                  style={{ marginTop: "8px" }}
                >
                  <span className={`${"txt_btn_plyr"} text-primary-multi`}>
                    {(m.moduleProgress >= 100 && m.status === 4) ||
                    m.moduleProgress >= 100
                      ? "Summary"
                      : m.status === 1
                      ? "Start"
                      : m.status === 3
                      ? "Resume"
                      : "Summary"}
                  </span>
                </Button>
              </div>
            </div>
          </StyledTestPlayerMob>
        </MediaQuery>

        <MediaQuery minDeviceWidth={1024}>
          <StyledTestPlayer id="fullScreenTest">
            <div className="test-player-mark">
              <StyledTotalMark>Total Mark : </StyledTotalMark> &nbsp;
              <StyledTestMark>{m.tot_test_mark}</StyledTestMark>
            </div>
            {this.renderName(m.name)}
            <div className="test-player-button">
              <div>
                {" "}
                <IconPlayerMark /> &nbsp;&nbsp;{" "}
                {m.moduleProgress >= 100
                  ? "click here to view summary"
                  : "click here to start the assessment"}
              </div>
              <div className="test-start-button">
                <Button
                  multiButton
                  variant="none"
                  onClick={navigateToAnotherComponent}
                  style={{ marginTop: "6px" }}
                >
                  <span className={`${"txt_btn_plyr"} text-primary-multi`}>
                    {(m.moduleProgress >= 100 && m.status === 4) ||
                    m.moduleProgress >= 100
                      ? "Summary"
                      : m.status === 1
                      ? "Start"
                      : m.status === 3
                      ? "Resume"
                      : "Summary"}
                  </span>
                </Button>
              </div>
            </div>
          </StyledTestPlayer>
        </MediaQuery>
      </>
    );
  };

  renderSurvey = () => {
    // const endDate = new Date(course.end_date);
    // const startDate =
    //   course.course_module_details.length > 0
    //     ? new Date(course.course_module_details[0].scheduled_on_timestamp)
    //     : "";

    

    const m = this.state.playerModule;
    const screenSize = window.screen.width;
    const navigateToAnotherComponent = () => {
      this.props.history.push({
        pathname: config.ROUTES.surveyRoute,
        ...this.props.location.state,
        state: {
          fromSurvey: true,
          assessmentData: {
            id: m.contentId,
            name: m.name,
            fromChapterInner: true,
          },
          subjectData: {
            ...this.props.location.subjectData,
            chapterId:
              this.state.currentChapterId ||
              this.props.location.subjectData.chapterId,
            videoUnitId: this.state.playerModule.contentId,
            acadYearId: this.props.ctx_user.user.acadYearId,
            memberId: this.props.memberId || this.props.ctx_user.user.id,
            curriculum_id: this.state.data[0].curriculum_id,
          },
          survey: {
            ...this.props.location.subjectData,
            chapterId:
              this.state.currentChapterId ||
              this.props.location.subjectData.chapterId,
            videoUnitId: this.state.playerModule.contentId,
            acadYearId: this.props.ctx_user.user.acadYearId,
            memberId: this.props.memberId || this.props.ctx_user.user.id,
            curriculum_id: this.state.data[0].curriculum_id,
            ...m,
            start_date:this.state.startDate,
            end_date:this.state.endDate,
            origin:this.props.location.pathname,
            
            
          },
        },
      });
    };

    return (
      <>
        <MediaQuery maxDeviceWidth={1023}>
          <StyledTestPlayerMob>
            <div className="test-player-mark-mob">
              {/* <StyledTotalMarkMob>Total Mark : </StyledTotalMarkMob> &nbsp;
              <StyledTestMarkMob>{m.tot_test_mark}</StyledTestMarkMob> */}
            </div>
            {this.renderName(m.name)}
            <div className="test-player-button-mob">
              <div className="test-player-click">
                {" "}
                <IconPlayerMark width="15" height="23" /> &nbsp;&nbsp;{" "}
                {m.moduleProgress >= 100
                  ? "click here to view summary"
                  : "click here to start the survey"}
              </div>

              <div className="test-start-button">
                <Button
                  multiButton
                  variant="none"
                  onClick={navigateToAnotherComponent}
                  style={{ marginTop: "8px" }}
                >
                  <span className={`${"txt_btn_plyr"} text-primary-multi`}>
                    {(m.moduleProgress >= 100 && m.status === 4) ||
                    m.moduleProgress >= 100
                      ? "Summary"
                      : m.status === 1
                      ? "Start"
                      : m.status === 3
                      ? "Resume"
                      : "Summary"}
                  </span>
                </Button>
              </div>
            </div>
          </StyledTestPlayerMob>
        </MediaQuery>

        <MediaQuery minDeviceWidth={1024}>
          <StyledTestPlayer>
            <div className="test-player-mark">
              {/* <StyledTotalMark>Total Mark : </StyledTotalMark> &nbsp;
              <StyledTestMark>{m.tot_test_mark}</StyledTestMark> */}
            </div>
            {this.renderName(m.name)}
            <div className="test-player-button">
              <div>
                {" "}
                <IconPlayerMark /> &nbsp;&nbsp;{" "}
                {m.moduleProgress >= 100
                  ? "click here to view summary"
                  : "click here to start the Survey"}
              </div>
              <div className="test-start-button">
                <Button
                  multiButton
                  variant="none"
                  onClick={navigateToAnotherComponent}
                  style={{ marginTop: "6px" }}
                >
                  <span className={`${"txt_btn_plyr"} text-primary-multi`}>
                    {(m.moduleProgress >= 100 && m.status === 4) ||
                    m.moduleProgress >= 100
                      ? "Summary"
                      : m.status === 1
                      ? "Start"
                      : m.status === 3
                      ? "Resume"
                      : "Summary"}
                  </span>
                </Button>
              </div>
            </div>
          </StyledTestPlayer>
        </MediaQuery>
      </>
    );
  };

  renderCourseMedia = () => {
    const playerVisible = this.state.playerStatus === "spawned";
    const m = this.state.playerModule;
    const today = new Date();
    const screenSize = window.screen.width;

    if (m && m.assignmentDate >= today) {
      return (
        <>
          <div
            className={window.screen.width > 821 && "player-no-module-wrapper"}
            style={{ borderRadius: "0px" }}
          >
            <div
              className={
                window.screen.width <= 821 ? cmCss.title_mob : "title_full"
              }
            >
              {this.renderPlayerUnavailable(m)}
              {this.renderHeader()}
            </div>
          </div>
        </>
      );
    }

    if (!m) {
      const sModule = this.state.selectedModuleKey
        ? this.getModuleByContentId(this.state.selectedModuleKey)
        : this.state.modules[0];
      return (
        <>
          <div
            className={window.screen.width > 821 && "player-no-module-wrapper"}
            style={{ borderRadius: "0px" }}
          >
            <div
              className={
                window.screen.width <= 821 ? cmCss.title_mob : "title_full"
              }
            >
              {this.renderNextModulePlayer(sModule)}
              {sModule && this.renderHeader()}
            </div>
          </div>
        </>
      );
    }

    if (this.state.playerState === "error") {
      return (
        <>
          <div
            className={window.screen.width > 821 && "player-no-module-wrapper"}
            style={{ borderRadius: "0px" }}
          >
            <div
              className={
                window.screen.width <= 821 ? cmCss.title_mob : "title_full"
              }
            >
              {this.renderPlayerError(m)}
              {this.renderHeader()}
            </div>
          </div>
        </>
      );
    }

    if (this.state.playerStatus === "dismissed") {
      return (
        <>
          <div
            className={window.screen.width > 821 && "player-no-module-wrapper"}
            style={{ borderRadius: "0px" }}
          >
            <div
              className={
                window.screen.width <= 821 ? cmCss.title_mob : "title_full"
              }
            >
              {this.renderPlayerError(m, "progressError")}
              {this.renderHeader()}
            </div>
          </div>
        </>
      );
    }
    const { course_id } = !this.props.playerOnly
      ? this.props.location.subjectData
      : this.props.subject;
    return (
      <div
        className={window.screen.width <= 821 ? cmCss.title_mob : "title_full"}
      >
        {playerVisible && (
          <Player
            testView={true}
            playerStatus={this.state.playerStatus}
            memberId={
              this.props.playerOnly
                ? this.props.memberId
                : this.props.ctx_user.user.id
            }
            curriculum={
              this.props.playerOnly
                ? this.props.subject
                : this.props.location.subjectData
            }
            courseId={course_id}
            coursePublishId={this.state.coursePublishId}
            chapter={{ id: this.state.playerModule.chapterId || null }}
            command={this.state.playerCommand}
            module={this.state.playerModule}
            creditsEarned={this.state.playerCreditsEarned}
            onStateChange={this.handlePlayerStateChange}
            onModuleProgress={this.updateModuleProgress}
            onUnmounted={this.handlePlayerUnmounted}
            chapterCompleted={
              this.state.completedModules == this.state.modules.length
            }
            curriculumList={(sId) => this.props.backToChapter(sId)}
            prevModule={this.state.prevPlayerModule}
            viewType={
              this.props.playerOnly
                ? this.props.viewType === "L"
                  ? "list"
                  : "card"
                : this.props.location.subjectData.type === "C"
                ? "card"
                : "list"
            }
            onPause={this.handlePause}
            seekable={this.state.seekable}
            extPlayConfirm={this.state.extPlayConfirm}
            onExtPlayConfirm={this.handleExtPlayConfirm}
            downloadable={this.state.downloadable}
            handleDownload={this.handleDownload}
            isShowSidebar={this.state.isShowSidebar}
            subjectData={this.props.location.subjectData}
            playerOnly={this.props.playerOnly}
            fromChapterInner
            courseCertificate={this.state.data[0].certificate}
            courseProgress={this.state.courseProgress}
            videoUnitId={this.state.playerModule.contentId}
            renderTest={this.renderTest}
            renderSurvey={this.renderSurvey}
            frmChprprvw
          />
        )}
        {this.renderHeader()}
        {/* </div> */}

        {/* Toolbar */}
      </div>
    );
  };

  renderLink = (rsp) => {
    return (
      <a
        id="render-link"
        download={"PDF" + "." + "pdf"}
        href="#"
        className="report-export-link"
        // target="_blank"
      />
    );
  };

  handleSurvey = async (id) => {
    try {
      const response = await this.props.ctx_api.privateRequest({
        cmd: "get_publish_survey_details_2",
        args: { publishSurveyId: id },
      });

      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  handlePdfDownload = async (contentId) => {
    this.setState({ searching: true }, () => {
      this.props.ctx_api
        .privateRequest({
          cmd: "get_module_content",
          args: { moduleId: contentId },
        })
        .then(({ rsp }) => {
          const link = document.getElementById("render-link");
          link.href = rsp; //"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
          link.click();
        })
        .catch(this.handleFetchFailure);
    });
  };

  handleFetchFailure = (err) => {
    // this.props.ctx_window.alert(messages.mkFailureErrorAlert());
    this.setState({ searching: false }, () => {
      console.log(`fetch error: ${err.message}`);
    });
  };

  getNameLabel = (props) =>
    this.props.ctx_labels.singular[props.name] || props.name;

  renderBreadCrumb = () => {
    return (
      <Breadcrumb
        list={[
          {
            title: util.capitalizeWord(
              this.getNameLabel({
                name: this.props.ctx_labels.singular.subject,
              })
            ),
            link: config.ROUTES.subjectList,
            current: false,
          },
          {
            title: util.capitalizeWord(
              this.getNameLabel({
                name: this.props.location.subjectData.name
                  ? this.props.location.subjectData.name
                  : this.props.location.subjectData.course_name,
              })
            ),
            current: true,
          },
        ]}
      />
    );
  };

  renderToggleSidebarButton = () => {
    const { curriculum, chapter } = this.props.ctx_labels.singular;
    const title = util.capitalizeWord(
      this.props.playerOnly
        ? this.props.viewType === "L"
          ? curriculum
          : chapter
        : this.props.location.subjectData.type === "L"
        ? curriculum
        : chapter
    );
    return (
      <div
        className={!this.props.grouped ? "icon_hide_card" : "icon_hide"}
        onClick={() =>
          this.setState((s0) => {
            return { isShowSidebar: !s0.isShowSidebar };
          })
        }
      >
        {/* <Button className="chInnerMbvw"
        // style={{backgroundColor: "gray"}}
       
      > */}
        <IconContentHide
          className={
            !this.state.isShowSidebar ? "ml-0 mr-0 " : "ml-0 mr-0 icon-rotate"
          }
        />
        {/* </Button> */}
      </div>
    );
  };

  handleReadMoreClick = (entity) => () => {
    this.setState((s0) => {
      const readMore = {
        ...s0.readMore,
        [entity]: !s0.readMore[entity],
      };
      return {
        readMore,
      };
    });
  };
  renderResponsiveTabs = () => {
    const {
      playerState,
      playerModule,
      modules,
      skills,
      trainerName,
      selectedModuleKey,
      currentChapterId,
      coursePublishId,
      viewMoreOpen,
    } = this.state;
    const { module, course, chap } = this.state.readMore;
    const { curriculum_id, type, course_id } = !this.props.playerOnly
      ? this.props.location.subjectData
      : this.props.subject;
    const { curriculum, chapter } = this.props.ctx_labels.singular;
    const title = util.capitalizeWord(type === "L" ? curriculum : chapter);
    const canStarted = this.state.startDate > new Date();
    const expired = this.state.endDate < new Date();
    const playerStateArr = ["playing", "ready", "advancing", "concluding"];
    let bottomBox = !this.props.playerOnly
      ? this.props.location.subjectData.type == "C" ||
        this.props.location.subjectData.type == "Card"
        ? this.state.data &&
          this.state.data[0].course_chapter_details[0].l_is_consumed
        : true
      : true;
    const courseDescription = this.state.courseDescription;
    const chapId = !playerModule ? currentChapterId : playerModule.chapterId;
    const modDescription = !playerModule ? "" : playerModule.summary;
    const chapterDescription =
      this.state.contentHier.filter((s) => s.id === chapId)[0]?.description ||
      "";
    const screenSize = window.screen.width;

    return (
      <div className={screenSize > 822 && viewMoreOpen && "title_full_tab"}>
        <div className={screenSize > 822 && "title_mob_full"}>
          <div
            className={
              playerStateArr.includes(playerState)
                ? playerModule.type === "pdf"
                  ? cmCss.tabWrapper_pdf_mob
                  : cmCss.tabWrapper_mob
                : ""
            }
          >
            <MediaQuery minDeviceWidth={822}>
              {viewMoreOpen && (
                <>
                  {0 < modules.length ? (
                    <div ref={this.targetRef}>
                      <ResponsiveTabs
                        tight
                        items={[
                          {
                            title: `${this.props.ctx_labels.singular.courseDetl}`,
                            content: (
                              <TabSubject
                                label={this.props.ctx_labels.singular}
                                courseProgress={this.state.courseProgress}
                                courseDescription={
                                  courseDescription && (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: course
                                            ? courseDescription
                                            : courseDescription.slice(0, 150),
                                        }}
                                      ></p>
                                      {courseDescription.length > 150 ? (
                                        <small
                                          className="readmore"
                                          onClick={this.handleReadMoreClick(
                                            "course"
                                          )}
                                        >
                                          {course
                                            ? "..show less"
                                            : "...read more"}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )
                                }
                                chapterDescription={
                                  chapterDescription && (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: chap
                                            ? chapterDescription
                                            : chapterDescription.slice(0, 150),
                                        }}
                                      ></p>
                                      {chapterDescription.length > 150 ? (
                                        <small
                                          className="readmore"
                                          onClick={this.handleReadMoreClick(
                                            "chap"
                                          )}
                                        >
                                          {chap
                                            ? "..show less"
                                            : "...read more"}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )
                                }
                                modDescription={
                                  modDescription && (
                                    <>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: module
                                            ? modDescription
                                            : modDescription.slice(0, 150),
                                        }}
                                      ></p>
                                      {modDescription.length > 150 ? (
                                        <small
                                          className="readmore"
                                          onClick={this.handleReadMoreClick(
                                            "module"
                                          )}
                                        >
                                          {module
                                            ? "..show less"
                                            : "...read more"}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )
                                }
                                skills={skills}
                                trainerName={trainerName}
                                type={playerModule ? playerModule.type : ""}
                                playerModule={playerModule}
                              />
                            ),
                          },
                          {
                            title: this.props.ctx_labels.singular.notes,
                            disabled:
                              selectedModuleKey === "" ||
                              canStarted ||
                              !bottomBox,
                            content: (
                              <TabNotes
                                studentId={this.props.ctx_user.user.id}
                                subjectId={curriculum_id}
                                moduleId={selectedModuleKey}
                              />
                            ),
                          },
                          {
                            title: this.props.ctx_labels.singular.askTheTrainer,
                            disabled:
                              selectedModuleKey === "" ||
                              expired ||
                              canStarted ||
                              !bottomBox ||
                              !this.state.playerModule,
                            content: (
                              <TabChat
                                studentId={this.props.ctx_user.user.id}
                                subjectId={curriculum_id}
                                chapterId={
                                  currentChapterId
                                    ? currentChapterId
                                    : playerModule
                                    ? playerModule.chapterId
                                    : null
                                }
                                contentId={selectedModuleKey}
                                coursePublishId={coursePublishId}
                                courseId={course_id}
                                onUnreadMessageChange={
                                  this.handleUnreadChatMessageChange
                                }
                              />
                            ),
                          },
                        ]}
                        selectedTabKey={0}
                      />
                    </div>
                  ) : (
                    <StyledPlayerMessage
                      style={{ filter: "grayscale(50%)" }}
                      className={css.playernomodulemessage}
                    >
                      <div className={css.playererrordetails}>
                        {!this.state.data ? (
                          <div className={css.playererrormessage}>
                            <div className={css.playernomodulemessagesubtitle}>
                              {this.renderLoader(css.chapter_loader)}
                            </div>
                          </div>
                        ) : (
                          <div className={css.playererrormessage}>
                            <div className={css.playernomodulemessagesubtitle}>
                              {this.props.ctx_labels.singular.contentNotAdd}
                            </div>
                          </div>
                        )}
                      </div>
                    </StyledPlayerMessage>
                  )}
                </>
              )}
            </MediaQuery>

            <MediaQuery maxDeviceWidth={821}>
              {0 < modules.length ? (
                <div>
                  <Card className="card_responsive_chap">
                    <ResponsiveTabs
                      tight
                      fromChapterInner
                      items={[
                        {
                          title: `${this.props.ctx_labels.singular.content}`,
                          content: this.renderContentList(),
                        },
                        {
                          title: `${this.props.ctx_labels.singular.courseDetl}`,
                          content: (
                            <TabSubject
                              label={this.props.ctx_labels.singular}
                              courseDescription={
                                courseDescription && (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: course
                                          ? courseDescription
                                          : courseDescription.slice(0, 150),
                                      }}
                                    ></p>
                                    {courseDescription.length > 150 ? (
                                      <small
                                        className="readmore"
                                        onClick={this.handleReadMoreClick(
                                          "course"
                                        )}
                                      >
                                        {course
                                          ? "..show less"
                                          : "...read more"}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              }
                              chapterDescription={
                                chapterDescription && (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: chap
                                          ? chapterDescription
                                          : chapterDescription.slice(0, 150),
                                      }}
                                    ></p>
                                    {chapterDescription.length > 150 ? (
                                      <small
                                        className="readmore"
                                        onClick={this.handleReadMoreClick(
                                          "chap"
                                        )}
                                      >
                                        {chap ? "..show less" : "...read more"}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              }
                              modDescription={
                                modDescription && (
                                  <>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: module
                                          ? modDescription
                                          : modDescription.slice(0, 150),
                                      }}
                                    ></p>
                                    {modDescription.length > 150 ? (
                                      <small
                                        className="readmore"
                                        onClick={this.handleReadMoreClick(
                                          "module"
                                        )}
                                      >
                                        {module
                                          ? "..show less"
                                          : "...read more"}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )
                              }
                              skills={skills}
                              trainerName={trainerName}
                              type={playerModule ? playerModule.type : ""}
                              playerModule={playerModule}
                            />
                          ),
                        },
                        {
                          title: this.props.ctx_labels.singular.notes,
                          disabled:
                            selectedModuleKey === "" ||
                            canStarted ||
                            !bottomBox,
                          content: (
                            <TabNotes
                              studentId={this.props.ctx_user.user.id}
                              subjectId={curriculum_id}
                              moduleId={selectedModuleKey}
                            />
                          ),
                        },
                        {
                          title: this.props.ctx_labels.singular.askTheTrainer,
                          disabled:
                            selectedModuleKey === "" ||
                            expired ||
                            canStarted ||
                            !bottomBox ||
                            !this.state.playerModule,
                          content: (
                            <TabChat
                              studentId={this.props.ctx_user.user.id}
                              subjectId={curriculum_id}
                              chapterId={
                                currentChapterId
                                  ? currentChapterId
                                  : playerModule
                                  ? playerModule.chapterId
                                  : null
                              }
                              contentId={selectedModuleKey}
                              coursePublishId={coursePublishId}
                              courseId={course_id}
                              onUnreadMessageChange={
                                this.handleUnreadChatMessageChange
                              }
                            />
                          ),
                        },
                      ]}
                      selectedTabKey={0}
                    />
                  </Card>
                </div>
              ) : (
                <StyledPlayerMessage className={css.playernomodulemessage_mob}>
                  <div className={css.playererrordetails}>
                    {!this.state.data ? (
                      <div className={css.playererrormessage}>
                        <div className={css.playernomodulemessagesubtitle}>
                          {this.renderLoader(css.chapter_loader_mob)}
                        </div>
                      </div>
                    ) : (
                      <div className={css.playererrormessage}>
                        <div className={css.playernomodulemessagesubtitle}>
                          {this.props.ctx_labels.singular.contentNotAdd}
                        </div>
                      </div>
                    )}
                  </div>
                </StyledPlayerMessage>
              )}
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  };
  handleHideModal = () => {
    this.setState({ modalShow: false });
  };

  handleCopy = () => {
    navigator.clipboard.writeText(this.state.courseUId);
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  renderLoader = (className) => {
    return (
      <div>
        <Loader active width={25} className={className}>
          {this.props.ctx_labels.singular.loadingContent}
        </Loader>
      </div>
    );
  };
  handleDownload = () => {
    // Fetch the PDF from the URL
    fetch(this.state.certificateUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        // Set the filename for the download
        link.setAttribute("download", "certificate.pdf");
        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  };

  handleClose = () => {
    this.setState({ isCertificate: false });
  };

  //   generateCertificate = async ( ) => {
  //     const {name, completionDate, } = this.state
  //     const studentName = this.props.ctx_user.user.firstName + " " + this.props.ctx_user.user.lastName

  //     try {
  //         const { rsp } = await this.props.ctx_api.privateRequest({
  //             cmd: "get_certificate",
  //             args: {
  //                 coursePublishId: this.state.coursePublishId ,
  //                 userId: this.props.ctx_user.user.id
  //             },
  //         });

  //         // Extracting data from rsp[0]

  //         const { completion_date
  //           , course_name, student_name } = rsp[0];

  //         // Constructing the URL with query parameters
  //         const Url = `http://localhost:3001/certificate-template/${student_name}/${course_name}/${completion_date}`;
  //         this.setState({isCertificate:true, certificateUrl : Url})
  //         // Redirecting to the certificate template page with parameters
  //         // window.location.href = `http://localhost:3000/certificate-template1/${completion_date}/${course_name}/${student_name}`;
  //         //

  //     } catch (error) {
  //         console.error("Error during certificate generation:", error.message);
  //     }
  // };

  render = () => {
    const { name, date } = !this.props.playerOnly ? this.props.location.subjectData : this.props.subject;
    /* if (this.props.location.subjectData === undefined && !this.props.subject) {
      return <Redirect to="/" />;
    } */
    const screenSize = window.screen.width;
    return (
      <>
        <MainContainer fromChapterInner>
          {screenSize <= 821 && (
            <div className="player_static">{this.renderCourseMedia()}</div>
          )}
          <Modal
            animation={false}
            size="md"
            show={this.state.isShowCopy}
            onHide={() => this.setState({ isShowCopy: false })}
            centered
          >
            <Modal.Body style={{ paddingRight: "2rem" }}>
              <div md={12}>
                <Row>
                  <Col md={10}>
                    <InputField value={`${this.state.courseUId}`} disabled />
                  </Col>
                  <Col md={2}>
                    <Button
                      style={screenSize < 822 ? { marginTop: "5px" } : {}}
                      onClick={this.handleCopy}
                    >
                      Copy
                    </Button>
                  </Col>
                  <div className="copy_msg">
                    {this.state.copied && <p>Link copied!</p>}
                  </div>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            animation={false}
            // size="xl"
            show={this.state.isCertificate}
            onHide={() => this.setState({ isCertificate: false })}
            centered
            // style={{ maxHeight: "100%", minHeight: "400px" }}
          >
            <Modal.Header closeButton={true}></Modal.Header>
            <Modal.Body style={{ paddingRight: "2rem" }}>
              <div md={12}>
                <iframe
                  height={"500px"}
                  width={"100%"}
                  src={this.state.certificateUrl}
                  frameborder="0"
                  className="player-frame"
                />
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.onDownload}>
            Download Pdf
          </Button>
        </Modal.Footer> */}
          </Modal>
          {screenSize <= 821 ? (
            <>
              <div>
                {!this.props.playerOnly && this.renderResponsiveTabs()}
                <div className={css.main}>
                  <div className="mb-4"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <JustifyBetween className={!this.props.grouped ? "" : "mb-4"}>
                {!this.props.playerOnly && this.renderBackLink()}

                {this.renderToggleSidebarButton()}
              </JustifyBetween>
              .
              {/* {this.state.courseProgress == 100 && <div style={{textAlign:"end"}}>
                <Button onClick={this.generateCertificate( )}>Get Certificate</Button>
                </div>} */}
              {this.state.modules.length > 0 && (
                <div className={css.row}>
                  {this.renderContentList()}
                  <div className={css.main}>
                    {this.renderCourseMedia()}
                    {/* <div className="mb-4"></div> */}
                    {!this.props.playerOnly && this.renderResponsiveTabs()}
                  </div>
                  {/* </div> */}
                </div>
              )}
            </>
          )}
          {this.renderLink()}
        </MainContainer>
      </>
    );
  };
}

export default withWindowContext(
  withAnalyticsContext(
    withUserContext(withLabelsContext(withApiContext(withRouter(ChapterInner))))
  )
);
