const getTableStyles = (theme) => {
    return {
        table: {
            style: {
                borderRadius: "10px",
                overflow: "hidden",
            },
        },
        header: {
            style: {
                minHeight: "56px",
            },
        },
        headRow: {
            style: {
                backgroundColor: theme?.color2 ?? "#001B4A",
            },
        },
        headCells: {
            style: {
                color: "#ffffff",
                "&:hover": {
                    color: "#ffffff",
                },
            },
            activeSortStyle: {
                color: theme?.color4 ?? "#20A6D2",
                "&:hover, &:hover:not(:focus)": {
                    color: theme.color4 ?? "#20A6D2",
                },
            },
            inactiveSortStyle: {
                "&:hover": {
                    color: theme?.color4 ?? "#20A6D2",
                },
            },
        },
        rows: {
            style: {
                borderBottom: "none",
                "&:not(:last-of-type)": {
                    borderBottom: "none",
                },
            },
            stripedStyle: {
                backgroundColor: theme?.color4 ? `${theme.color4}1a` : "rgba(32,166,210,0.1)",
            },
        },
        cells: {
            style: {
                paddingTop: "10px",
                paddingBottom: "10px",
                color: theme?.color2 ?? "#001B4A",
                border: "none",
            },
        },
        sortFocus: {
            style: {
                color: "#a0a",
            },
        },
        pagination: {
            style: {
                paddingTop: "20px",
                backgroundColor: "transparent",
                border: "none",
                borderColor: theme?.color4 ? `${theme?.color4}80` : `rgba(32,166,210,0.5)`,
            },
            pageButtonsStyle: {
                backgroundColor: "#ffffff",
                marginLeft: "6px",
                borderRadius: "10px",
                border: "1px solid rgba(32,166,210,0.5)",
                borderColor: theme?.color4 ? `${theme.color4}80` : `rgba(32,166,210,0.5)`,
                color: theme?.color4,
                fill: theme?.color4,
            },
        },
    }

};

export default getTableStyles;