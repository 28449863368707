import { Button, Loader, OverlayTooltip, SearchField } from "components";
import { withLabelsContext } from "context";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import css from "./scss/subjects-menu.module.scss";
import * as config from "config";
import classNames from "classnames";

const StyledSubjectMenu = styled.div`
.Badge{
  background-color: ${({ theme }) => theme.primary};
}
  .${css.subject_section_title} {
    color: ${({ theme }) => theme.primary};
  }
  .${css.featured_item}, .${css.subject_section_list} {
    color: ${({ theme }) => theme.color2};
  }
  .${css.image}, .${css.image_placeholder} {
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.color3} 2%,
      ${({ theme }) => theme.color4} 84%
    );

    > .${css.placeholder_text} {
      color: ${({ theme }) => theme.button.primary.text};
    }
  }
`;
const StyledNewText = styled.div`
  && {
    color: #fff;
    background: ${({ theme }) => theme.color3};
    border-radius: 3px;
    padding: 2px 6px;
    display: inline-block;
  }
`;
class SubjectsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredList: props.studentSubjects,
      showButton : props.showButton,
      showMore: false,
      mouseEnter:false,
    };
    this.showMore = this.showMore.bind(this);
    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = (_prevProps) => {
    if (
      _prevProps.studentSubjects.length !== this.props.studentSubjects.length
    ) {
      this.setState(() => ({ filteredList: this.props.studentSubjects }));
    }
  };

  setMenuRef(node) {
    this.menuRef = node;
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      if (this.state.showMore && event.clientX > 707) {
        this.props.toggleSubjectsMenu();
      }
      if (!this.state.showMore) {
        this.props.toggleSubjectsMenu();
      }
    }
  }

  filterList(text) {
    if (text !== "") {
      let dataList = [];
      let allList = this.props.studentSubjects;

      allList.forEach((subject) => {
     
        if (
          subject.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
        ) {
          dataList.push(subject);
        }
        
      });
      this.setState({
        filteredList: dataList,
      });
    } else {
      // set all list
      this.setState({
        filteredList: this.props.studentSubjects,
      });
    }
  }

  showMore() {
    this.setState({
      showMore: true,
     
    });
    this.props.toggleSubjectsMenu();
  }
  handlemouseEnter=()=>{
    console.log("enter");
  this.props.hoversubjectenter(true)
  }
  handlemouseLeave=()=>{
    console.log("leave");
    this.props.hoversubjectenter(false)
  }
  render() {
    let addDate = new Date();
    let days = config.subjectFlyoutLimitValue;
    const list = this.state.filteredList.length > 0 && this.state.filteredList.sort((a, b) => 
    new Date(b.start_date) - new Date(a.start_date)).map((subject, index) => (

      <OverlayTooltip
        tip={`One or more ${this.props.ctx_labels.plural.assessment.toLowerCase()} pending`}
        id={index}
        placement="top"
        disabled={subject.pending_test === 0}
      >
        
        <Link
          key={index}
          to={
            { 
            pathname: config.ROUTES.traineeCurriculum,
            subjectData:
              subject.type === "L"
                ? subject
                : {
                    coursePublishId: subject.course_publish_id,
                    courseName: subject.name,
                     curriculumId: subject.curriculum_id,
                    courseId: subject.course_id,
                    type: subject.type,
                    classId: this.props.studentSubjects.class_id,
                  },
          }}
          onClick={
            subject.pending_test === 1 ? (e) => e.preventDefault(): this.props.toggleSubjectsMenu
          }
        >
          <div className={css.subject_section} key={index}>
            <div
              className={classNames(
                css.subject_section_list,
                subject.pending_test === 1 && css.disabled
              )}
            >
              <div>{subject.name && subject.name.length > 25 
                    ?<span>{subject.name.slice(0,25) + "..."}</span>
                    : <span>{subject.name}</span>}</div>
              {(new Date(new Date(subject.start_date).setDate(new Date(subject.start_date).getDate() + days)) > addDate) && (subject.progress_perc == null) ? 
                    <div class ="Badge" style={{marginLeft : "70px", position:"absolute" , fontSize:"10px", marginTop: "-16px"}}>
                      {this.props.ctx_labels.singular.new}
                      </div>  : null }
            </div>
           
          </div>
        </Link>
      </OverlayTooltip>
    ));
    if (this.props.studentSubjects.length === 0  && this.props.completed ) {
      return <Loader active />;
    }
    if (this.props.studentSubjects.length === 0 && !this.props.completed === true) {
      return (
        <div className={classNames(css.subjectComplete)}
          onMouseDown={this.props.toggleSubjectsMenu}
        >
          {this.props.ctx_labels.singular.notassociatedMsg}{" "}
          {this.props.ctx_labels.singular.subject.toLowerCase()}. {this.props.ctx_labels.singular.pleaseContact} {this.props.ctx_labels.singular.trainer.toLowerCase()}.{" "}
        </div>
      );
    }

    const wrapText = (name,maxLength) => {
      const wrappedText=name.length > maxLength ? name.slice(0, maxLength)+ '...' : name;
      return(
        wrappedText
      )
    }
    const newTag = this.props.ctx_labels.singular.new
    return (
      <StyledSubjectMenu
      onMouseEnter={this.handlemouseEnter}
      onMouseLeave={this.handlemouseLeave}
        ref={this.setMenuRef}
        id="subject_more"
        className={!this.state.showMore ? css.wrapper : css.wrapper_more}
      >
        {/* featured subjects */}
        {!this.state.showMore && this.props.subListCompleted === true ? (
          <div className={css.featured}>
            {this.state.filteredList.length > 0 && this.state.filteredList.slice(0, 7).map((subject, i) => {
              console.log(subject);
              const course_subject_name = subject.course_name || subject.name
              return (
                <OverlayTooltip
                  tip={`One or more ${this.props.ctx_labels.plural.assessment.toLowerCase()} pending`}
                  id={i}
                  placement="top"
                  disabled={subject.pending_test === 0}
                >
                  <Link
                    key={i}
                    to={
                      subject.is_multicourse === 1
                        ? {
                            pathname: config.ROUTES.variants,
                            subjectData: subject,
                            from: "subMenu",
                          }
                        : {
                            pathname: config.ROUTES.traineeCurriculum,
                            subjectData:
                              subject.type === "L"
                                ? subject
                                : {
                                    coursePublishId: subject.course_publish_id,
                                    courseName: subject.name,
                                     curriculumId: subject.curriculum_id,
                                    courseId: subject.course_id,
                                    type: subject.type,
                                    className: subject.classname,
                                    classId: subject.class_id,
                                  },
                          }
                    }
                    className={classNames(
                      css.featured_item,
                      subject.pending_test === 1 && css.disabled
                    )}
                    onClick={
                      subject.pending_test === 1
                        ? (e) => e.preventDefault()
                        : this.props.toggleSubjectsMenu
                    }
                  >
                    {subject.image && (
                      <img
                        className={css.image}
                        src={subject.image ?? null}
                        alt=""
                      />
                    )}
                    {!subject.image && (
                      <div className={css.image_placeholder}>
                        <div className={css.placeholder_text}>
                          {subject.course_name && subject.course_name.charAt(0).toUpperCase() ||
                          subject.name && subject.name.charAt(0).toUpperCase()}
                        </div>
                      </div>
                    )}
                    <div>{course_subject_name && course_subject_name.length > 25 
                    ?<span>{course_subject_name.slice(0,25) + "..."}</span>
                    : <span>{course_subject_name}</span>}</div>
                    {new Date(
                      new Date(subject.start_date).setDate(
                        new Date(subject.start_date).getDate() + days
                      )
                    ) > addDate && subject.progress_perc == null ? (
                      (newTag.length > 3 ? 
                      <OverlayTooltip
                      placement="top"
                      tip = {newTag}
                      >
                      <StyledNewText
                        style={{
                          marginLeft: "190px",
                          position: "absolute",
                          fontSize: "8px",
                          marginBottom: "30px",
                          top:"2px",
                          right:"2px",
                        }}
                      >
                        {wrapText(newTag,3)}
                      </StyledNewText>
                      </OverlayTooltip>
                      : 
                      <StyledNewText
                        style={{
                          marginLeft: "190px",
                          position: "absolute",
                          fontSize: "8px",
                          marginBottom: "30px",
                          top:"2px",
                          right:"2px",
                        }}
                      >
                        {wrapText(newTag,3)}
                      </StyledNewText>)
                    ) : null}
                  </Link>
                </OverlayTooltip>
              );
            })}
            {/* {7 < this.state.filteredList.length && ( */}
              <div className="text-center">
                <Link 
                onClick={(e) => {
                  this.showMore();
                }}
                 to={{
                  pathname: config.ROUTES.subjectList, 
                  subjectData :  this.props.studentSubjects,
          

                }} >
                <Button variant="link" size="sm" onClick={this.showMore}>
                {this.props.ctx_labels.singular.viewAll}
                </Button>
                </Link>
              </div>
            {/* )} */}
          </div>
        ) : <Loader active/>}

        {/* all subjects */}
        {this.state.showMore && (
          <div className={css.all}>
            <div className={css.search_field}>
              <SearchField
                placeholder={`Search for ${this.props.ctx_labels.plural.course}`}
                onKeyUp={(e) => this.filterList(e.currentTarget.value)}
                overLightBg
              />
            </div>

            <div className={css.subject_list}>{list}</div>
          </div>
        )}
      </StyledSubjectMenu>
    );
  }
}

export default withLabelsContext(SubjectsMenu);
