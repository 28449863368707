import AsyncSelect from './AsyncSelect';
import { withApiContext, withLabelsContext, withUserContext } from 'context';
import React, { PureComponent } from 'react';




class RoleSelect extends PureComponent {
  fetch = async () => {
    const { rsp } = await this.props.ctx_api.privateRequest({
      cmd: 'get_all_roles',
      args: { campusId: this.props.ctx_user.user.organizationId },
    });
    return rsp.map(({ user_role_id, role_name }) => ({
      label: role_name,
      value: user_role_id,
    }));
  }

  render = () => (
    <AsyncSelect
      {...this.props}
      optionName={this.props.ctx_labels.singular.role}
      fetch={this.fetch}
      unselectedValue=""
    />
  );
}


export default
  withApiContext(
    withLabelsContext(
      withUserContext(
        RoleSelect
      )
    )
  )


